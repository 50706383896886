import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useInjectReducer } from "Utility/InjectReducer";
import { useInjectSaga } from "Utility/InjectSaga";

import { Form, Table, Button } from "antd";
import { FullLayout } from "Components/Layouts/Inner/Full.Layout";
import { HelmetHeader } from "Components/Helmet-Header/index";
import { ArchiveIcon } from "@heroicons/react/outline";
import { RequestLotteryGameTypeEquipmentFilterForm } from "Modules/Certificate/Lottery/ApplicationForm/GameTypeEquipment/Forms/RequestLotteryGameTypeEquipmentFilterForm";

import LotteryApplicationFormReducer from "Modules/Certificate/Lottery/ApplicationForm/Reducer";
import LotteryApplicationFormSaga from "Modules/Certificate/Lottery/ApplicationForm/Saga";
import { MakeRequestLotteryGameTypeListSelector } from "Modules/Certificate/Lottery/ApplicationForm/Selectors";
import {
    ListRequestLotteryGameTypeRequestAction,
    SetRequestLotteryGameTypeFilterValueAction
} from "Modules/Certificate/Lottery/ApplicationForm/Actions";
import { EntityType } from "Common/Constants/EntityType";
import { useEffect } from "react";

const key = "lotteryApplicationForm";
export const RequestLotteryGameTypeList = () => {
    useInjectReducer({ key, reducer: LotteryApplicationFormReducer });
    useInjectSaga({ key, saga: LotteryApplicationFormSaga });
    // fields
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const requestLotteryGameTypes = useSelector(MakeRequestLotteryGameTypeListSelector())

    const requestLotteryGameTypeColumns = [
        {
            key: "documentCode",
            title: "លេខតាមដានឯកសារ",
            render: (item: any) => <Link to={item.id} className="underline text-blue-500">{item.documentCode}</Link>
        },
        {
            key: "code",
            title: "កូដ",
            dataIndex: "code",
        },
        {
            key: "casinoName",
            title: "ល្បែងផ្សងសំណាង",
            render: (item: any) => <span>{item.casinoName} - {item.casinoNameInKhmer}</span>
        },
    ];

    useEffect(() => {
      dispatch(SetRequestLotteryGameTypeFilterValueAction({search: "" , formType: "G002",}));
      dispatch(ListRequestLotteryGameTypeRequestAction());
    }, []);

    //events
    const onFormProviderSubmit = (name: string, { forms, values }: any) => {

        switch (name) {
            case "requestLotteryGameTypeEquipmentFilterForm":
                dispatch(SetRequestLotteryGameTypeFilterValueAction({...values, formType: "G002",}));
                dispatch(ListRequestLotteryGameTypeRequestAction());
                break;
        }

    };

    const ActionButtons = () => (
        <>
            <Link
                to={`create?entityType=${EntityType.LOTTERY}`}
            >
                <Button type="primary">
                    បញ្ចូលថ្មី
                </Button>

            </Link>
        </>
    );

    return (
        <>
            <FullLayout
                icon={<ArchiveIcon />}
                title="បញ្ជីស្នើសុំចុះបញ្ជីប្រភេទល្បែង និឧបករណ៍ល្បែង សម្រាប់ល្បែងផ្សងសំណាង (L003)"
                descriptions=""
                actions={<ActionButtons />}
            >
                <HelmetHeader meta="បញ្ជីស្នើសុំចុះបញ្ជីប្រភេទល្បែង និងឿបករណ៍ល្បែង សម្រាប់ល្បែងផ្សងសំណាង" />
                <Form.Provider onFormFinish={onFormProviderSubmit}>
                    <RequestLotteryGameTypeEquipmentFilterForm form={form} />
                    <Table size="small" rowKey="id" columns={requestLotteryGameTypeColumns} dataSource={requestLotteryGameTypes || []} />
                </Form.Provider>

            </FullLayout>
        </>
    );
};
