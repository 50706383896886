import { useSelector } from "react-redux";
import { Modal, Form, Row, Col, Input, Select, InputNumber } from "antd";
import { useResetFormOnCloseModal } from "Utility/ResetFormOnCloseModal";

import { useInjectReducer } from "Utility/InjectReducer";
import Reducer from "Modules/Settings/GameRule/Reducer";

import { MakeGameRuleSelector } from "Modules/Settings/GameRule/Selectors";

type GameTypeProps = {
    visibility: boolean;
    onCancel: any;
};

const key = "gameRule";
export const CertificateCasinoGameEquipmentModal = ({
    visibility,
    onCancel,
}: GameTypeProps) => {
    useInjectReducer({ key, reducer: Reducer });
    // fields
    const [form] = Form.useForm();
    const gameRules = useSelector(MakeGameRuleSelector());

    // methods
    useResetFormOnCloseModal({
        form,
        visible: visibility,
    });

    const onSubmit = () => {
        form.submit();
        onCancel();
    };

    return (
        <Modal
            title="ឧបករណ៍ល្បែង"
            open={visibility}
            onOk={onSubmit}
            onCancel={onCancel}
            width={800}
            okText="យល់ព្រម"
            cancelText="បោះបង់"
            okButtonProps={{ type: "default" }}
        >
            <Form
                name="gameEquipmentModalForm"
                form={form}
                initialValues={{
                    gameRuleId: { key: "", value: "", label: "" },
                    tableQuantity: 0,
                    slotQuantity: 0,
                    machineQuantitySmallTable: 0,
                    playerQuantitySmallTable: 0,
                    machineQuantityBigTable: 0,
                    playerQuantityBigTable: 0,
                    other: "",
                }}
            >
                <Row>
                    <Col span={10}>វិធានល្បែង</Col>
                    <Col span={14}>
                        <Form.Item name="gameRuleId">
                            <Select
                                labelInValue
                                style={{ minWidth: 200 }}
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    {
                                        return (option?.children.toString() ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                }
                                showSearch
                            >
                                {gameRules &&
                                    gameRules.map((item) => (
                                        <Select.Option
                                            key={item["id"]}
                                            value={item["id"]}
                                        >
                                            {item["name"]}
                                        </Select.Option>
                                    ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={10}>ចំនួនតុល្បែង</Col>
                    <Col span={14}>
                        <Form.Item name="tableQuantity">
                            <InputNumber />
                        </Form.Item>
                    </Col>
                </Row>
                <fieldset className="border border-solid border-gray-300 p-3">
                    <legend>Machine</legend>
                    <Row>
                        <Col span={10}>ចំនួនស្លុត/Slot</Col>
                        <Col span={14}>
                            <Form.Item name="slotQuantity">
                                <InputNumber />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={10}>Station 2-8</Col>
                        <Col span={4}>
                            <Form.Item name="machineQuantitySmallTable">
                                <InputNumber />
                            </Form.Item>
                        </Col>
                        <Col span={6}>Player per machine</Col>
                        <Col span={4}>
                            <Form.Item name="playerQuantitySmallTable">
                                <InputNumber />
                            </Form.Item>
                        </Col>

                        <Col span={10}>Station over 8</Col>
                        <Col span={4}>
                            <Form.Item name="machineQuantityBigTable">
                                <InputNumber />
                            </Form.Item>
                        </Col>
                        <Col span={6}>Player per machine</Col>
                        <Col span={4}>
                            <Form.Item name="playerQuantityBigTable">
                                <InputNumber />
                            </Form.Item>
                        </Col>
                    </Row>
                </fieldset>

                <Row className="mt-2">
                    <Col span={10}>ផ្សេងៗ</Col>
                    <Col span={14}>
                        <Form.Item name="other">
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};
