import {
    Form,
    Input,
    Button
} from "antd";

export const InspectionCertificateMasterFilterForm = () => {
    return (<>
        <Form.Item name="search" label="លតាបត្រ">
            <Input autoComplete="off" />
        </Form.Item>
        <Form.Item>
            <Button type="default" htmlType="submit">ស្វែងរក</Button>
        </Form.Item>
    </>)
}