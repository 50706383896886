import {
    EquipmentBindingInitialState
} from "Modules/Equipments/Casino/Binding/Reducer";
import { createSelector } from "reselect";

const EquipmentBindingDomain = (state) => state.equipmentBinding || EquipmentBindingInitialState;

const MakeEquipmentBindingValueSelector = () => createSelector(EquipmentBindingDomain, substate => substate.equipmentValue);
const MakeEquipmentBindingStatusSelector = () => createSelector(EquipmentBindingDomain, substate => substate.status);
const MakeEquipmentBindingUploadValueSelector = () => createSelector(EquipmentBindingDomain, substate => substate.file);
const MakeUploadFileEquipmentBindingStatusSelector = () => createSelector(EquipmentBindingDomain, substate => substate.fileStatus);
const MakeVerifyEquipmentBindingValueSelector = () => createSelector(EquipmentBindingDomain, substate => substate.verifyEquipmentId);
const MakeEquipmentBindingSelector = () => createSelector(EquipmentBindingDomain, substate => substate.equipmentBinding);
const MakeVerifyEquipmentStatusSelector = () => createSelector(EquipmentBindingDomain, substate => substate.verifyEquipmentStatus);

export {
    MakeEquipmentBindingStatusSelector,
    MakeEquipmentBindingUploadValueSelector,
    MakeUploadFileEquipmentBindingStatusSelector,
    MakeEquipmentBindingValueSelector,
    MakeVerifyEquipmentBindingValueSelector,
    MakeEquipmentBindingSelector,
    MakeVerifyEquipmentStatusSelector
}