import { Form, Row, Col, Input, DatePicker, Select, Button } from "antd";

import { LicenseType } from "Common/Constants/LicenseType";

export const CertificateGameEquipmentFilterForm = ({ form }: any) => {
    return (
        <Row gutter={8}>
            <Col span={8}>
                <Form.Item name="code" label="ព័ត៌មានស្វែងរក">
                    <Input placeholder="លេខអាជ្ញាបណ្ណ / ឈ្មោះ " />
                </Form.Item>
            </Col>
            <Col span={4} className="flex align-middle items-center">
                <Button htmlType="submit" type="default">
                    ស្វែងរក
                </Button>
            </Col>
            <Col span={4} className="hidden">
                <Form.Item
                    name="expiredDate"
                    label="ស្វែងរកតាមសុពលភាពអាជ្ញាបណ្ណ"
                >
                    <DatePicker.RangePicker />
                </Form.Item>
            </Col>
            <Col span={4} className="hidden">
                <Form.Item name="formType" label="ប្រភេទអាជ្ញាបណ្ណ">
                    <Select>
                        {LicenseType &&
                            LicenseType.map((item) => (
                                <Select.Option
                                    key={`${item["entityName"]}`}
                                    value={item["formType"]}
                                >
                                    {item["label"]}
                                </Select.Option>
                            ))}
                    </Select>
                </Form.Item>
            </Col>
        </Row>
    );
};
