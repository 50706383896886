import { EquipmentBindingCreate } from "Modules/Equipments/Casino/Binding/Create";
import { EquipmentBindingVerify } from "Modules/Equipments/Casino/Binding/Verify";
import { EquipmentBindingDetail } from "./Detail";
import { EquipmentRebindingDetail } from "./Rebind";

export const EquipmentBindingRoute = [
    {
        path: "",
        children: [
            { path: "create/:id", element: <EquipmentBindingCreate /> },
            { path: "verify/:id", element: <EquipmentBindingVerify /> },
            { path: "detail/:id", element: <EquipmentBindingDetail /> },
            { path: "rebind/:id", element: <EquipmentRebindingDetail /> },
        ],
    },
];
