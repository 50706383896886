import produce from "immer";
// import {
//     EntityCasinoListConstant,
//     GET_ENTITY_CASINO_BY_ID_SUCCESS,
//     GET_CASINO_STATUS_COUNT_SUCCESS,
//     GET_LOTTERY_STATUS_COUNT_SUCCESS,
//     CREATE_CASINO_MODAL_SUCCESS,
//     SET_CASINO_ID,
// } from "Modules/Entity/Operator/Constants";

export const ReportInitialState = {
    template: null,
    dataSource: ["1", "2", "3"],
};

const ReportReducer = produce((draft, action) => {
    switch (action.type) {
        default: break;
    }
}, ReportInitialState);

export default ReportReducer;
