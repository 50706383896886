/*
 *
 * App actions
 *
 */

import {
    GET_PROFILE_ERROR,
    GET_PROFILE_REQUEST,
    GET_PROFILE_SUCCESS,
    IS_LOGGED,
    IS_LOGGED_ERROR,
    IS_LOGGED_SUCCESS,
    LOGGED_IN,
    LOGOUT,
    LOGOUT_ERROR,
    LOGOUT_SUCCESS,
    ASYNC_START,
    ASYNC_END,
    REFRESH_TOKEN,
    DASHBOARD_REQUEST_REDIRECT,
    DASHBOARD_REQUEST_REDIRECT_SUCCESS,
    SET_AUTHENTICATION_APP_REQUEST_ID,
    AUTHENTICATION_APP_REQUEST,
    AUTHENTICATION_APP_SUCCESS,
    SET_PASSWORD_VALUE,
    CHANGE_PASSWORD_REQUEST,
    SET_PERMISSION_VALUE,
    SET_FILE_VALUE,
    UPLOAD_FILE_REQUEST,
    SET_UPLOAD_STATUS,
    UPLOAD_FILE_SUCCESS,
    SET_CAPTCHA_TOKEN,
    VERIFY_CAPTCHA_TOKEN_REQUEST,
    VERIFY_CAPTCHA_TOKEN_SUCCESS,
    SET_PRINT_PARAMETERS,
} from "Modules/Application/Constants";

/**
 *
 * @param user
 * @returns {{type: string, user: *}}
 */
export function GetProfileSuccessAction(user: any) {
    return {
        type: GET_PROFILE_SUCCESS,
        user,
    };
}

/**
 *
 * @param error
 * @returns {{type: string, error: *}}
 */
export function GetProfileErrorAction(error: any) {
    return {
        type: GET_PROFILE_ERROR,
        error,
    };
}

/**
 *
 * @returns {{type: string}}
 */
export function GetProfileAction() {
    return {
        type: GET_PROFILE_REQUEST,
    };
}

/**
 * Check user is logged, this action starts the request saga
 *
 * @return {object} An action object with a type of IS_LOGGED
 */
export function IsLoggedAction() {
    return {
        type: IS_LOGGED,
    };
}

export const DashboardRequestRedirectAction = () => {
    return {
        type: DASHBOARD_REQUEST_REDIRECT,
    };
};

export const DashboardRequestRedirectSuccessAction = (requestId: string) => {
    return {
        type: DASHBOARD_REQUEST_REDIRECT_SUCCESS,
        requestId,
    };
};

/**
 * TODO
 * Check user is logged, this action starts the request saga
 *
 * @return {object} An action object with a type of IS_LOGGED
 */
export function IsLoggedSuccessAction() {
    return {
        type: IS_LOGGED_SUCCESS,
    };
}

/**
 * TODO
 * Check user is logged, this action starts the request saga
 *
 * @return {object} An action object with a type of IS_LOGGED
 */
export function IsLoggedErrorAction() {
    return {
        type: IS_LOGGED_ERROR,
    };
}

/**
  
   * User login to the application, this is the global action
   *
   * @return {object} An action object with a type of LOGGED_IN
   */
export function LoggedInAction() {
    return {
        type: LOGGED_IN,
    };
}

/**
 * Start the logout process, this action starts the request saga
 *
 * @return {object} An action object with a type of LOGOUT
 */
export function LogoutAction() {
    return {
        type: LOGOUT,
    };
}

/**
 * Dispatched when the logout process are loaded by the request saga
 *
 * @return {object} An action object with a type of LOGOUT_SUCCESS
 */
export function LogoutSuccessAction() {
    return {
        type: LOGOUT_SUCCESS,
    };
}

export function RefreshTokenAction() {
    return {
        type: REFRESH_TOKEN,
    };
}

export function LogoutErrorAction(error: any) {
    return {
        type: LOGOUT_ERROR,
        error,
    };
}

export function AsyncStartAction() {
    return {
        type: ASYNC_START,
    };
}

export function AsyncEndAction() {
    return {
        type: ASYNC_END,
    };
}

export const SetAuthenticationAppRequestIdAction = (id: string) => {
    return {
        type: SET_AUTHENTICATION_APP_REQUEST_ID,
        id,
    };
};

export const AuthenticationAppRequestAction = () => {
    return {
        type: AUTHENTICATION_APP_REQUEST,
    };
};

export const AuthenticationAppSuccessAction = (status: number) => {
    return {
        type: AUTHENTICATION_APP_SUCCESS,
        status
    };
};

export const SetPasswordValueAction = (values: any) => {
    return {
        type: SET_PASSWORD_VALUE,
        values
    }
}

export const ChangePasswordRequestAction = () => {
    return {
        type: CHANGE_PASSWORD_REQUEST
    }
}

export const SetPermissionValueAction = (values) => {
    return {
        type: SET_PERMISSION_VALUE,
        values
    }
}

export const SetFileValueAction = (values: any) => {
    return {
        type: SET_FILE_VALUE,
        values
    }
}

export const UploadFileRequestAction = () => {
    return {
        type: UPLOAD_FILE_REQUEST,

    }
}

export const SetUploadStatusAction = (status: number) => {
    return {
        type: SET_UPLOAD_STATUS,
        status
    }
}

export const UploadFileSuccessAction = (fileModel: any) => {
    return {
        type: UPLOAD_FILE_SUCCESS,
        fileModel,
    }
}

export const SetCaptchaTokenActon = (token: string) => {
    return {
        type: SET_CAPTCHA_TOKEN,
        token,
    }
}

export const VerifyCaptchaTokenRequestAction = () => {
    return {
        type: VERIFY_CAPTCHA_TOKEN_REQUEST,
    }
}

export const VerifyCaptchaTokenSuccessAction = (token: string) => {
    return {
        type: VERIFY_CAPTCHA_TOKEN_SUCCESS,
        token
    }
}

export const SetPrintParametersAction = (params: any) => {
    return {
        type: SET_PRINT_PARAMETERS,
        params,
    }
}