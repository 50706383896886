import {
    SELECT_CASINO_STATUS_REQUEST,
    SELECT_CASINO_STATUS_SUCCESS,
} from "Modules/Settings/CasinoStatus/Constants";

export const SelectCasinoStatusRequestAction = () => {
    return {
        type: SELECT_CASINO_STATUS_REQUEST,
    };
};

export const SelectCasinoStatusSuccessAction = (casinoStatuses: any) => {
    return { type: SELECT_CASINO_STATUS_SUCCESS, casinoStatuses };
};
