import { useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useInjectReducer } from "Utility/InjectReducer";
import { useInjectSaga } from "Utility/InjectSaga";
import Reducer from "Modules/Settings/GameRule/Reducer";
import Saga from "Modules/Settings/GameRule/Saga";

import { Form, Button } from "antd";
import { FullLayout } from "Components/Layouts/Inner/Full.Layout";
import { HelmetHeader } from "Components/Helmet-Header/index";
import { ArchiveIcon } from "@heroicons/react/outline";
import { SettingGameRuleForm } from "Modules/Settings/GameRule/Forms/SettingGameRuleForm";

import {
    CreateGameRuleRequestAction,
    SetFormValuesAction,
} from "Modules/Settings/GameRule/Actions";


const key = "gameRule";

export const Create = () => {
    useInjectReducer({ key: key, reducer: Reducer });
    useInjectSaga({ key: key, saga: Saga });

    // fields
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [searchParams] = useSearchParams();
    const entityType = searchParams.get("entityType");

    // events
    const onFormProviderSubmit = (values: any) => {
        const model = {
            entityType: entityType,
            ...values,
        }
        dispatch(SetFormValuesAction(model));
        dispatch(CreateGameRuleRequestAction());
    };

    const ActionButtons = () => (
        <>
            <Button
                type="primary"
                onClick={() => form.submit()}
            >
                រក្សាទុក
            </Button>
        </>
    );
    return (
        <>
            <FullLayout
                icon={<ArchiveIcon />}
                title="បញ្ជូលវិធានល្បែង"
                descriptions="ត្រលប់ទៅបញ្ជី"
                redirectUrl={`/settings/game-rule?entityType=${entityType}`}
                actions={<ActionButtons />}
            >
                <HelmetHeader meta="វិធានល្បែង" />
                <Form onFinish={onFormProviderSubmit}
                    name="settingGameRuleForm"
                    form={form}
                    className="p-8">
                    <SettingGameRuleForm form={form} />
                </Form>
            </FullLayout>
        </>
    );
};
