export const SET_REQUEST_GAME_RULE_FILTER_VALUE =
    "SET_REQUEST_GAME_RULE_FILTER_VALUE";

export const LIST_REQUEST_GAME_RULE_REQUEST = "LIST_REQUEST_GAME_RULE_REQUEST";
export const LIST_REQUEST_GAME_RULE_SUCCESS = "LIST_REQUEST_GAME_RULE_SUCCESS";

export const SET_REQUEST_GAME_RULE_VALUE = "SET_REQUEST_GAME_RULE_VALUE";
export const CREATE_REQUEST_GAME_RULE_REQUEST =
    "CREATE_REQUEST_GAME_RULE_REQUEST";

export const SET_REQUEST_GAME_RULE_ID = "SET_REQUEST_GAME_RULE_ID";
export const GET_REQUEST_GAME_RULE_REQUEST = "GET_REQUEST_GAME_RULE_REQUEST";
export const GET_REQUEST_GAME_RULE_SUCCESS = "GET_REQUEST_GAME_RULE_SUCCESS";
export const EDIT_REQUEST_GAME_RULE_REQUEST = "EDIT_REQUEST_GAME_RULE_REQUEST";
export const EDIT_REQUEST_GAME_RULE_SUCCESS = "EDIT_REQUEST_GAME_RULE_SUCCESS";

export const SET_CERTIFICATE_GAME_RULE_FILTER_VALUE =
    "SET_CERTIFICATE_GAME_RULE_FILTER_VALUE";
export const LIST_CERTIFICATE_GAME_RULE_REQUEST =
    "LIST_CERTIFICATE_GAME_RULE_REQUEST";
export const LIST_CERTIFICATE_GAME_RULE_SUCCESS =
    "LIST_CERTIFICATE_GAME_RULE_SUCCESS";
