import { Link } from "react-router-dom";
import { DotsVerticalIcon } from "@heroicons/react/solid";
import { classNames } from "Utility/ClassNames";

export interface CardLinkType {
    key?: string;
    initials?: string;
    bgColor?: string;
    name: string;
    href?: string;
}

export const CardLink = ({
    key = "",
    initials = "",
    bgColor = "",
    name,
    href = "",
}: CardLinkType) => {
    return (
        <div className="col-span-1 flex shadow-sm rounded-md">
            <div
                className={classNames(
                    bgColor,
                    "flex-shrink-0 flex items-center justify-center w-16 text-white text-sm font-medium rounded-l-md"
                )}
            >
                {initials}
            </div>
            <div className="flex-1 flex items-center justify-between border-t border-r border-b border-gray-200 bg-white rounded-r-md truncate">
                <div className="flex-1 px-4 py-2 text-sm truncate leading-8">
                    <Link
                        to={href}
                        className="text-gray-900 font-medium hover:text-gray-600"
                    >
                        {name}
                    </Link>
                </div>
                <div className="flex-shrink-0 pr-2">
                    <Link
                        to={href}
                        className="w-8 h-8 bg-white inline-flex items-center justify-center text-gray-400 rounded-full bg-transparent hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                        <DotsVerticalIcon
                            className="w-5 h-5"
                            aria-hidden="true"
                        />
                    </Link>
                </div>
            </div>
        </div>
    );
};
