import { ShowAlert, ShowMessage } from "Common/Saga";
import { AsyncEndAction, AsyncStartAction } from "Modules/Application/Actions";
import { GET, POST, PUT } from "Utility/Constant";
import ApiEndpoint from "Utility/Endpoint";
import Request from "Utility/Http";
import { v4 as uuid } from "uuid";

export const EditInspectionCertificateDetailService = (inspectionCertificate: any, dispatch: any) => {
    const requestUrl = `${ApiEndpoint.INSPECTION_CERTIFICATE_DETAIL_PATH}`;
    const httpPayload = ApiEndpoint.makeApiPayload(
        requestUrl,
        PUT,
        inspectionCertificate,
        null,
        false,
        true
    );

    try {
        const response:any = Request(httpPayload);
        return response;
    } catch (error: any) {
        dispatch(ShowAlert("error", error.data.message));
        return null;
    }
}

export const PrintInspectionCertificateDetailService = (id: string, quantity: number, dispatch: any) => {
    if (!id) {
        dispatch(ShowMessage({
            type: "error",
            message: "មានបញ្ហាពេលទាញយកទិន្នន័យ",
            id: uuid(),
        }))
        return  null;
    }
    const url = `${ApiEndpoint.INSPECTION_CERTIFICATE_DETAIL_PATH}/print/${id}/${quantity}`
    const payload = ApiEndpoint.makeApiPayload(url, GET, id);
    const response:any = Request(payload);
    return response;
}

export const GetInspectionCertificateSerialsService = (model: any, dispatch : any) => {
    
    dispatch(AsyncStartAction());
    try {
        const url = `${ApiEndpoint.INSPECTION_CERTIFICATE_DETAIL_PATH}/serials`
        const payload = ApiEndpoint.makeApiPayload(url, POST, model);
        const response: any = Request(payload);
        
        if (response && !response.status && !response.snack) {
            
            return response;
        }
    }
    catch {
        return null;
    }
    finally{
        dispatch(AsyncEndAction());
    }
}