/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import {
    Form,
    Row,
    Col,
    Input,
    Radio,
    DatePicker,
    Space,
    Tabs,
    Table,
    Button,
    Typography,
    Popconfirm,
    Select,
} from "antd";
import { EditableCell } from "Components/Toolbox/Editable/EditableCell";

import { useInjectReducer } from "Utility/InjectReducer";
import { useInjectSaga } from "Utility/InjectSaga";
import Reducer from "Modules/Entity/Company/Reducer";
import Saga from "Modules/Entity/Company/Saga";

import CasinoReducer from "Modules/License/Casino/Reducer";
import CasinoSaga from "Modules/License/Casino/Saga";

import {
    SetCompanyValueAction,
    GetEntityCompanyByIdRequestAction,
    GetEntityCompanyByIdSuccessAction,
    SelectCompanySuccessAction,
} from "Modules/Entity/Company/Actions";
import { DATE_FORMAT } from "Modules/Application/Constants";

import MakeSelectCasinoLicensePage from "Modules/License/Casino/Selectors";
import { MakeSelectEntityCompanyListPage } from "Modules/Entity/Company/Selectors";
import { ColumnTypes } from "Common/Constants/ColumnTypes";
import { GetCompanyService } from "Modules/Entity/Company/Service";
import { GetCasinoTypeService } from "../Service";
import { SelectableCasinoTypeSuccessAction } from "Modules/License/Casino/Actions";
import { useParams } from "react-router-dom";
import { GetEntityCasinoByIdRequestAction, GetEntityCasinoByIdSuccessAction, SetFormValuesAction } from "../Actions";

interface DataType {
    key: string;
    licenseNumber: string;
    startDate: any;
    endDate: any;
}

const key = "entityCompany";
const casinoKey = "licenseCasino";
export const EntityCasinoForm = ({ form, setCompanyVisibility }: any) => {
    // constructor
    useInjectReducer({ key: key, reducer: Reducer });
    useInjectSaga({ key: key, saga: Saga });
    useInjectReducer({ key: casinoKey, reducer: CasinoReducer });
    useInjectSaga({ key: casinoKey, saga: CasinoSaga });

    const dispatch = useDispatch();
    const { id } = useParams();

    const { companyOptions, company } = useSelector(
        MakeSelectEntityCompanyListPage()
    );
    const { casinoTypes, nationality } = useSelector(
        MakeSelectCasinoLicensePage()
    );
    const [licenseHistorySource, setLicenseHistorySource] = useState<
        DataType[]
    >([]);


    const [count, setCount] = useState(1);
    const [editingKey, setEditingKey] = useState("");
    const columns = [
        {
            title: "លេខអាជ្ញាបណ្ណ",
            dataIndex: "licenseNumber",
            width: "30%",
            type: "input",
            editable: true,
        },
        {
            title: "កាលបរិច្ឆេទចេញអាជ្ញាបណ្ណ",
            dataIndex: "startDate",
            type: "date",
            editable: true,
        },
        {
            title: "កាលបរិច្ឆេទផុតសុពលភាពអាជ្ញាបណ្ណ",
            dataIndex: "endDate",
            type: "date",
            editable: true,
        },
        {
            title: "",
            dataIndex: "operation",
            render: (_: any, record: DataType) => {
                const editable = isEditing(record);
                return editable ? (
                    <span className="text-center">
                        <Typography.Link
                            onClick={() => save(record)}
                            style={{ marginRight: 8 }}
                        >
                            រក្សាទុក
                        </Typography.Link>
                        <Popconfirm
                            title="តើអ្នកចង់បោះបង់ការកែប្រែ?"
                            onConfirm={cancel}
                        >
                            <a className="text-red-500">បោះបង់</a>
                        </Popconfirm>
                    </span>
                ) : (
                    <Typography.Link
                        disabled={editingKey !== ""}
                        onClick={() => edit(record)}
                    >
                        កែប្រែ
                    </Typography.Link>
                );
            },
        },
    ];

    const mergedColumns = columns.map((col) => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record: DataType) => ({
                record,
                // inputType: col.dataIndex === "age" ? "number" : "text",
                inputType: col.type,
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
            }),
        };
    });

    // useEffect(() => {
    //     const onLoad = async () => {
    //         const companies = GetCompanyService(dispatch);
    //         return companies;
    //     }
    //     onLoad()
    //     .then((companies: any) => {
    //         if(companies) {
    //             dispatch(SelectCompanySuccessAction(companies));
    //         }
    //         GetCasinoTypeService(dispatch)
    //         .then((casinoTypes: any) => {
    //             dispatch(SelectableCasinoTypeSuccessAction(casinoTypes));
    //         });
    //     })
        
    //     return () => {
    //         dispatch(SelectCompanySuccessAction([]));
    //         dispatch(SelectableCasinoTypeSuccessAction([]));
    //         dispatch(GetEntityCompanyByIdSuccessAction(null));
    //     };
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []);

     // contrucotr
     useEffect(() => {
        const onLoad = async () => {
            const companies = GetCompanyService(dispatch);
            return companies;
        }
        onLoad()
        .then((companies: any) => {
            if(companies) {
                dispatch(SelectCompanySuccessAction(companies));
            }
            GetCasinoTypeService(dispatch)
            .then((casinoTypes: any) => {
                dispatch(SelectableCasinoTypeSuccessAction(casinoTypes));
            });
            if (id) {
                dispatch(SetFormValuesAction(id));
                dispatch(GetEntityCasinoByIdRequestAction());
            }
        })
        
        
        
        // eslint-disable-next-line react-hooks/exhaustive-deps

    
        return () => {
            dispatch(SelectCompanySuccessAction([]));
            dispatch(SelectableCasinoTypeSuccessAction([]));
            dispatch(GetEntityCompanyByIdSuccessAction(null));
            dispatch(GetEntityCasinoByIdSuccessAction(null));
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    useEffect(() => {
        if (company && company !== null) {
            const companyModel = company?.company;
            const formData = {
                company: {
                    name: companyModel?.name,
                    latinName: companyModel?.latinName,
                    registrationNumber: companyModel?.registrationNumber,
                    taxIdentificationNumber:
                        companyModel?.taxIdentificationNumber,
                    nationalId: `${companyModel?.nationalId}`,
                    registrationOffice: companyModel?.registrationOffice,
                    address: companyModel?.address,
                    propertyStatus: companyModel?.propertyStatus,
                    mobilePhoneNumber: companyModel?.mobilePhoneNumber,
                    telephoneNumber: companyModel?.telephoneNumber,
                    emailAddress: companyModel?.emailAddress,
                    websiteAddress: companyModel?.websiteAddress,
                    gAPFirstname: companyModel.companyData?.gAPFirstname,
                    gAPLastname: companyModel.companyData?.gAPLastname,
                },
            };
            form.setFieldsValue({
                // companyId: companyModel?.id,
                formData: formData,
            });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [company]);


    // event on new row
    const isEditing = (record: DataType) => record.key === editingKey;
    const onAddLicenseHistory = () => {
        const newData: DataType = {
            key: count + "",
            licenseNumber: "",
            startDate: dayjs(),
            endDate: dayjs(),
        };
        form.setFieldsValue({
            licenseNumber: "",
            startDate: dayjs(),
            endDate: dayjs(),
            ...newData,
        });
        setLicenseHistorySource([...licenseHistorySource, newData]);
        setEditingKey(count + "");
        setCount(count + 1);
    };
    const save = (row: DataType) => {

        const newData = [...licenseHistorySource];
        const values = form.getFieldsValue([
            "licenseNumber",
            "startDate",
            "endDate",
        ]);

        values.startDate = dayjs(values.startDate).format(DATE_FORMAT);
        values.endDate = dayjs(values.endDate).format(DATE_FORMAT);

        const index = newData.findIndex((item) => row.key === item.key);
        const item = newData[index];
        newData[index] = { key: item.key, ...values };
        setLicenseHistorySource(newData);
        setEditingKey("");
        form.setFieldsValue({
            casinoData: {
                licenseHistory: newData,
            },
        });
    };

    const edit = (record: Partial<DataType> & { key: React.Key }) => {
        form.setFieldsValue({
            licenseNumber: "",
            startDate: "",
            endDate: "",
            ...record,
        });
        setEditingKey(record.key);
    };

    const cancel = () => {
        setEditingKey("");
    };

    const onSelectCompanyChange = (e: any) => {
        const companyId = form.getFieldValue("companyId");
        dispatch(SetCompanyValueAction(companyId));
        dispatch(GetEntityCompanyByIdRequestAction());
    };

    // render

    return (
        <Form
            name="entityCasinoForm"
            layout="vertical"
            className="min-w-[600px] p-4"
            form={form}
            initialValues={{ name: "", lastinName: "" }}
        >
            <Form.Item name={["casinoData", "licenseHistory"]} hidden={true}>
                <Input />
            </Form.Item>
            <Tabs defaultActiveKey="1"
                items={[
                    {
                        key: "1",
                        label: "ព័ត៌មានកាស៊ីណូ",
                        children: <>
                            <Row>
                                <Col span={6}>
                                    នាមករណ៍ក្រុមហ៊ុនជាអក្សរខ្មែរ <br />
                                    Company name in Khmer
                                </Col>
                                <Col span={18}>
                                    <Form.Item
                                        name="companyId"
                                        rules={[{ required: true }]}
                                    >

                                        <Select
                                            placeholder="នាមករណ៍ក្រុមហ៊ុនជាអក្សរខ្មែរ / Company name in Khmer"
                                            onChange={onSelectCompanyChange}
                                            filterOption={(input, option) =>
                                                option!.children
                                                    .join(" ")
                                                    .toLowerCase()
                                                    .includes(input.toLowerCase())
                                            }
                                            showSearch
                                        >
                                            {companyOptions &&
                                                companyOptions.map((item) => (
                                                    <Select.Option
                                                        key={item.value}
                                                        value={item.value}
                                                    >
                                                        {item.label}({item.placeholder})
                                                    </Select.Option>
                                                ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={6}>លេខកូដកាស៊ីណូ</Col>
                                <Col span={18}>
                                    <Form.Item name="code" rules={[{ required: true }]}>
                                        <Input placeholder="លេខកូដកាស៊ីណូ" />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={6}>
                                    នាមករណ៍កាស៊ីណូជាអក្សរខ្មែរ <br />
                                    Casino name in Khmer
                                </Col>
                                <Col span={18}>
                                    <Form.Item name="name" rules={[{ required: true }]}>
                                        <Input placeholder="នាមករណ៍កាស៊ីណូជាអក្សរខ្មែរ / Casino name in Khmer" />
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    នាមករណ៍កាស៊ីណូជាអក្សរបរទេស <br />
                                    Casino name in Foreign Language
                                </Col>
                                <Col span={18}>
                                    <Form.Item
                                        name="latinName"
                                        rules={[{ required: true }]}
                                    >
                                        <Input placeholder="នាមករណ៍កាស៊ីណូជាអក្សរឡាតាំង / Casino name in Latin" />
                                    </Form.Item>
                                </Col>

                                <Col span={6}>
                                    ប្រភេទកាស៊ីណូ <br />
                                    Casino Type
                                </Col>
                                <Col span={18}>
                                    <Form.Item name="casinoTypeId">
                                        <Radio.Group>
                                            <Space direction="vertical">
                                                {casinoTypes &&
                                                    casinoTypes.map(
                                                        (
                                                            casinoType: any,
                                                            index: number
                                                        ) => (
                                                            <Radio
                                                                key={casinoType.id}
                                                                value={casinoType.id}
                                                            >
                                                                {
                                                                    casinoType.descriptions
                                                                }
                                                            </Radio>
                                                        )
                                                    )}
                                            </Space>
                                        </Radio.Group>
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    ស្ថានភាពអាជ្ញាបណ្ណ <br />
                                    License Status
                                </Col>
                                <Col span={18}>
                                    <Form.Item name="casinoStatusId">
                                        <Select
                                            style={{ minWidth: 200 }}
                                            showSearch
                                        >
                                            <Select.Option key="79a726ad-8a2e-4ccb-afa8-04a0d0c398d6" value="79a726ad-8a2e-4ccb-afa8-04a0d0c398d6">
                                              គ្មានអាជ្ញាបណ្ណ
                                            </Select.Option>
                                            <Select.Option key="fecb16b5-e956-4a20-9d9e-a078bce3a47d" value="fecb16b5-e956-4a20-9d9e-a078bce3a47d">
                                                មានអាជ្ញាបណ្ណ
                                            </Select.Option>
                                            <Select.Option key="216beea0-afe9-4542-9dab-90fa6a094e20" value="216beea0-afe9-4542-9dab-90fa6a094e20">
                                                ផុតសុពលភាព
                                            </Select.Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    លេខអាជ្ញាបណ្ណកាស៊ីណូ
                                    <br />
                                    Casino License Number
                                </Col>
                                <Col span={18}>
                                    <Row gutter={16}>
                                        <Col span={8}>
                                            <Form.Item name="casinoLicenseNumber">
                                                <Input placeholder="លេខអាជ្ញាបណ្ណកាស៊ីណូ / Casino License Number" />
                                            </Form.Item>
                                        </Col>
                                        <Col span={8}>
                                            សុពលភាពអាជ្ញាបណ្ណ
                                            <br />
                                            Expired date of License
                                        </Col>
                                        <Col span={8}>
                                            <Form.Item name="licenseExpiredDate">
                                                <DatePicker placeholder="សុពលភាពអាជ្ញាបណ្ណ / Expired date of License" />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col span={6}>
                                    អាសយដ្ឋានធ្វើអាជីវកម្ម
                                    <br />
                                    Address of Business
                                </Col>
                                <Col span={18}>
                                    <Form.Item
                                        name="address"
                                        rules={[{ required: true }]}
                                    >
                                        <Input placeholder="អាសយដ្ឋានធ្វើអាជីវកម្ម / Address of Business" />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={6}>
                                    ទីតាំងនេះជាកម្មសិទ្ធិ ឬជួល?
                                    <br />
                                    Is this above Location an Owned or Rented Property?
                                </Col>
                                <Col span={18}>
                                    <Form.Item
                                        name={["casinoData", "propertyStatus"]}
                                        rules={[
                                            {
                                                required: true,
                                                message: "Please select",
                                            },
                                        ]}
                                    >
                                        <Radio.Group>
                                            <Space direction="horizontal">
                                                <Radio value={1}>
                                                    កម្មសិទ្ធិ / Owned
                                                </Radio>
                                                <Radio value={2}>ជួល / Rented</Radio>
                                            </Space>
                                        </Radio.Group>
                                    </Form.Item>
                                </Col>
                            </Row></>
                    },
                    {
                        key: "2",
                        label: "ព័ត៌មានលម្អិតពីក្រុមហ៊ុន",
                        children: <>
                            <Row>
                                <Col span={6}>
                                    នាមករណ៍ក្រុមហ៊ុនជាអក្សរខ្មែរ <br />
                                    Company name in Khmer
                                </Col>
                                <Col span={18}>
                                    <Form.Item
                                        name={["formData", "company", "name"]}
                                        rules={[{ required: true }]}
                                    >
                                        <Input
                                            placeholder="នាមករណ៍ក្រុមហ៊ុនជាអក្សរខ្មែរ / Company name in Khmer"
                                            readOnly
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    នាមករណ៍ក្រុមហ៊ុនជាអក្សរឡាតាំង <br />
                                    Company name in Latin
                                </Col>
                                <Col span={18}>
                                    <Form.Item
                                        name={["formData", "company", "latinName"]}
                                        rules={[{ required: true }]}
                                    >
                                        <Input
                                            placeholder="នាមករណ៍ក្រុមហ៊ុនជាអក្សរឡាតាំង / Company name in Latin"
                                            readOnly
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    នាមករណ៍បុគ្គលទទួលបាន ស.ជ.ណ <br />
                                    name of Person received government
                                    approval-in-Principle
                                </Col>
                                <Col span={18}>
                                    <Row>
                                        <Col span={8}>
                                            អក្សរខ្មែរ <br />
                                            in Khmer
                                        </Col>
                                        <Col span={16}>
                                            <Form.Item
                                                name={[
                                                    "formData",
                                                    "company",
                                                    "gAPFirstname",
                                                ]}
                                            >
                                                <Input readOnly />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={8}>
                                            អក្សរឡាតាំង <br />
                                            in Latin
                                        </Col>
                                        <Col span={16}>
                                            <Form.Item
                                                name={[
                                                    "formData",
                                                    "company",
                                                    "gAPLastname",
                                                ]}
                                            >
                                                <Input readOnly />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>

                            <Row>
                                <Col span={6}>
                                    នាមករណ៍ក្រុមហ៊ុនពីមុន(ប្រសិនបើមាន) <br />
                                    Former name(If Any)
                                </Col>
                                <Col span={16}>
                                    <Row gutter={16}>
                                        <Col span={8}>
                                            <Form.Item
                                                name={[
                                                    "formData",
                                                    "company",
                                                    "formerName",
                                                ]}
                                            >
                                                <Input
                                                    placeholder="នាមករណ៍ក្រុមហ៊ុនពីមុន(ប្រសិនបើមាន) / Former name(If Any)"
                                                    readOnly
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={8}>
                                            កាលបរិច្ឆេទនៃការផ្លាស់ប្តូរនាមករណ៍
                                            <br />
                                            Date of Changing the name
                                        </Col>
                                        <Col span={8}>
                                            <Form.Item
                                                name={[
                                                    "formData",
                                                    "company",
                                                    "dateOfChangingName",
                                                ]}
                                            >
                                                <DatePicker placeholder="កាលបរិច្ឆេទនៃការផ្លាស់ប្តូរនាមករណ៍ / Date of Changing the name" />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col span={6}>
                                    លេខចុះបញ្ជីពាណិជ្ជកម្ម <br />
                                    Registration Number
                                </Col>
                                <Col span={18}>
                                    <Form.Item
                                        name={[
                                            "formData",
                                            "company",
                                            "registrationNumber",
                                        ]}
                                    >
                                        <Input
                                            placeholder="លេខចុះបញ្ជីពាណិជ្ជកម្ម / Registration Number"
                                            readOnly
                                        />
                                    </Form.Item>
                                </Col>

                                <Col span={6}>
                                    លេខអត្តសញ្ញាណកម្មសារពើពន្ធ <br /> Tax Identificaiton
                                    Number (TIN)
                                </Col>
                                <Col span={18}>
                                    <Form.Item
                                        name={[
                                            "formData",
                                            "company",
                                            "taxIdentificationNumber",
                                        ]}
                                        rules={[{ required: true }]}
                                    >
                                        <Input
                                            placeholder="លេខអត្តសញ្ញាណកម្មសារពើពន្ធ / Tax Identificaiton Number (TIN)"
                                            readOnly
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    សញ្ជាតិក្រុមហ៊ុន <br /> Company Nationality
                                </Col>
                                <Col span={18}>
                                    <Form.Item
                                        name={["formData", "company", "nationalId"]}
                                        rules={[
                                            {
                                                required: true,
                                                message: "Please select",
                                            },
                                        ]}
                                    >
                                        <Radio.Group>
                                            <Space direction="horizontal">
                                                {nationality &&
                                                    nationality.map((nation: any) => (
                                                        <Radio
                                                            key={nation.value}
                                                            value={nation.value}
                                                        >
                                                            {nation.label}
                                                        </Radio>
                                                    ))}
                                            </Space>
                                        </Radio.Group>
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    ទីស្នាក់ការចុះបញ្ជីក្រុមហ៊ុន
                                    <br />
                                    Company Registration Office
                                </Col>
                                <Col span={18}>
                                    <Form.Item
                                        name={[
                                            "formData",
                                            "company",
                                            "registrationOffice",
                                        ]}
                                        rules={[{ required: true }]}
                                    >
                                        <Input
                                            placeholder="ទីស្នាក់ការចុះបញ្ជីក្រុមហ៊ុន / Company Registration Office"
                                            readOnly
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>

                            {/* <Row>
                        <Col span={6}>
                            ទីតាំងនេះជាកម្មសិទ្ធិ ឬជួល?
                            <br />
                            Is this above Location an Owned or Rented Property?
                        </Col>
                        <Col span={18}>
                            <Form.Item
                                name={["formData", "company", "propertyStatus"]}
                                rules={[
                                    {
                                        required: true,
                                        message: "Please select",
                                    },
                                ]}
                            >
                                <Radio.Group readOnly>
                                    <Space direction="horizontal">
                                        <Radio value={1}>
                                            កម្មសិទ្ធិ / Owned
                                        </Radio>
                                        <Radio value={2}>ជួល / Rented</Radio>
                                    </Space>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                    </Row> */}
                            <Row>
                                <Col span={6}>
                                    ទូរស័ព្ទច​ល័ត
                                    <br />
                                    Mobile Phone Number
                                </Col>
                                <Col span={18}>
                                    <Row gutter={16}>
                                        <Col span={8}>
                                            <Form.Item
                                                name={[
                                                    "formData",
                                                    "company",
                                                    "mobilePhoneNumber",
                                                ]}
                                                rules={[{ required: true }]}
                                            >
                                                <Input
                                                    placeholder="ទូរស័ព្ទច​ល័ត / Mobile Phone Number"
                                                    readOnly
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={8}>
                                            ទូរស័ព្ទលើតុ
                                            <br />
                                            Office Phone Number
                                        </Col>
                                        <Col span={8}>
                                            <Form.Item
                                                name={[
                                                    "formData",
                                                    "company",
                                                    "telephoneNumber",
                                                ]}
                                            >
                                                <Input
                                                    placeholder="ទូរស័ព្ទលើតុ / Office Phone Number"
                                                    readOnly
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={6}>
                                    សារអេឡិចត្រូនិច
                                    <br />
                                    Email Address
                                </Col>
                                <Col span={18}>
                                    <Form.Item
                                        name={["formData", "company", "emailAddress"]}
                                        rules={[{ required: true }]}
                                    >
                                        <Input
                                            placeholder="សារអេឡិចត្រូនិច / Email Address"
                                            readOnly
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={6}>
                                    គេហទំព័រ
                                    <br />
                                    Website
                                </Col>
                                <Col span={18}>
                                    <Form.Item
                                        name={["formData", "company", "websiteAddress"]}
                                        rules={[{ required: true }]}
                                    >
                                        <Input
                                            placeholder="គេហទំព័រ / Website"
                                            readOnly
                                        />
                                    </Form.Item>
                                </Col>
                            </Row></>
                    },
                    {
                        key: "3",
                        label: "ព័ត៌មាអាជ្ញាបណ្ណ",
                        children: <>
                            <Button
                                className="bg-blue-500"
                                onClick={onAddLicenseHistory}
                                type="primary"
                                style={{ marginBottom: 16 }}
                            >
                                បន្ថែមថ្មី
                            </Button>
                            <br />
                            <Table
                                size="small"
                                dataSource={licenseHistorySource}
                                rowKey="key"
                                components={{
                                    body: {
                                        // row: EditableRow,
                                        cell: EditableCell,
                                    },
                                }}
                                columns={mergedColumns as ColumnTypes}
                            /></>
                    }
                ]} />

        </Form>
    );
};
