import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { useInjectSaga } from "Utility/InjectSaga";
import { useInjectReducer } from "Utility/InjectReducer";

import { Link } from "react-router-dom";
import { Form, Table, Button, Space } from "antd";
import { FullLayout } from "Components/Layouts/";
import { HelmetHeader } from "Components/Helmet-Header";
import { RequestCasinoLicenseFilterForm } from "Modules/License/Casino/ApplicationForm/License/Forms/RequestCasinoLicenseFilterForm";

import Reducer from "Modules/License/Casino/Reducer";
import Saga from "Modules/License/Casino/Saga";
import {
    SetRequestCasinoLicenseFilterValueAction,
    ListRequestCasinoLicenseRequestAction,
    ListRequestCasinoLicenseSuccessAction,
    ExportRequestCasinoLicenseRequestAction,
} from "Modules/License/Casino/Actions";
import { MakeRequestCasinoLicenseListSelector } from "Modules/License/Casino/Selectors";
import { DATE_FORMAT } from "Modules/Application/Constants";

const key = "licenseCasino";
export const RequestCasinoLicenseList = () => {
    useInjectReducer({ key, reducer: Reducer });
    useInjectSaga({ key, saga: Saga });
    //fields
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const licenses = useSelector(MakeRequestCasinoLicenseListSelector());

    const licenseColumns = [
        {
            title: "លេខតាមដានឯកសារ",
            key: "code",
            render: (item: any) => {
                return (
                    <Link
                        to={`/license/casino/application-form/request-license/${item.id}`}
                        className="underline text-blue-500"
                    >
                        {item.documentCode}
                    </Link>
                );
            },
        },
        {
            title: "ឈ្មោះក្រុមហ៊ុន",
            key: "companyNameInKhmer",
            dataIndex: "companyNameInKhmer",
        },
        {
            title: "ឈ្មោះកាស៊ីណូ",
            key: "casinoNameInKhmer",
            dataIndex: "casinoNameInKhmer",
        },
        {
            title: "នាមករណ៍កាស៊ីណូជាអក្សរបរទេស",
            key: "casinoNameLatin",
            dataIndex: "casinoNameLatin",
        },
        {
            title: "ទីតាំងធ្វើអាជីវកម្ម",
            key: "address",

            render: (item: any) => {
                const address = JSON.parse(item.formData).casino.address;
                return <span>{address}</span>;
            },
        },
        {
            title: "ថ្ងៃស្នើសុំ",
            key: "licenseIssuedDate",

            render: (item: any) => (
                <span>
                    {item.receivedDate
                        ? dayjs(item.receivedDate).format(DATE_FORMAT)
                        : ""}
                </span>
            ),
        },
        // {
        //     title: "ស្ថានភាព",
        //     key: "licenseIssuedDate",

        //     render: (item: any) => (
        //         <span>
        //             {item.licenseStatus ===
        //                 "234f9073-4e93-4549-87fc-d9bb25bbb0cd"
        //                 ? "មិនទាន់គ្រប់គ្រាន់"
        //                 : "បានអាជ្ញាបណ្ណ"}
        //         </span>
        //     ),
        // },
    ];

    // constructors
    useEffect(() => {
        const model = {
            search: form.getFieldValue("search"),
            formType: "C001",
        };

        dispatch(SetRequestCasinoLicenseFilterValueAction(model));
        dispatch(ListRequestCasinoLicenseRequestAction());

        return () => {
            dispatch(ListRequestCasinoLicenseSuccessAction([]));
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if(licenses) {
            const data = [];
            licenses.forEach((element: any) => {
                const formData = JSON.parse(element.formData);
                console.log("FormData", formData);
                const obj = {
                    name: element.casinoName,
                    khName: element.casinoNameInKhmer,
                    applicantName: formData.applicant.fullname,
                    phone: formData.applicant.mobilePhoneNumber,
                    directors: formData.boardOfDirectors,
                    
                };    
                data.push(obj);
            });
            console.log("Data", data);
        }
    }, [licenses])

    // method
    const ActionButton = () => {
        return (
            <Space>
                <Button type="primary" onClick={onExportApplicationForm}>
                    ទាញយក
                </Button>
                <Link
                    to="create">
                    <Button type="primary">
                        បញ្ចូលថ្មី
                    </Button>
                </Link>
            </Space>
        );
    };
    // events
    const onFormProviderSubmit = (name: string, { forms, values }: any) => {
        switch (name) {
            case "licenseListForm": {
                onFilterFormSubmit(values);
                break;
            }
        }
    };

    const onExportApplicationForm = () => {
        const model = {
            search: form.getFieldValue("search"),
            formType: "C001",
        };

        dispatch(SetRequestCasinoLicenseFilterValueAction(model));
        dispatch(ExportRequestCasinoLicenseRequestAction());
    };

    const onFilterFormSubmit = (values: any) => {
        const model = {
            search: form.getFieldValue("search"),
            formType: "C001",
        };

        dispatch(SetRequestCasinoLicenseFilterValueAction(model));
        dispatch(ListRequestCasinoLicenseRequestAction());
    };

    return (
        <FullLayout
            icon={null}
            title="បញ្ជីពាក្យស្នើសុំអាជ្ញាបណ្ណ (C001)"
            descriptions=""
            actions={<ActionButton />}
        >
            <HelmetHeader meta="បញ្ជីពាក្យស្នើសុំអាជ្ញាបណ្ណ" />
            <Form.Provider onFormFinish={onFormProviderSubmit}>
                <RequestCasinoLicenseFilterForm form={form} />
                <Table
                    columns={licenseColumns}
                    dataSource={licenses}
                    rowKey="id"
                    size="small"
                />
            </Form.Provider>
        </FullLayout>
    );
};
