import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { saveState } from "Storage/Persist.Storage";
import { throttle } from "lodash";
import { store } from "Store";

import Bootstrap from "Components/Bootstrap";
import reportWebVitals from "./reportWebVitals";

import "Assets/global.scss";

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);

// config state storage
store.subscribe(
    throttle(() => {
        saveState({
            application: store.getState().application,
        });
    }, 1000)
);
root.render(
    <Provider store={store}>
        <Bootstrap />
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
if (process.env.NODE_ENV === "development") {
    // eslint-disable-next-line no-console
    reportWebVitals(console.log);
}
