import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useInjectReducer } from "Utility/InjectReducer";
import { useInjectSaga } from "Utility/InjectSaga";
import { GetGtinRunningNumber, GTINGenerator } from "Utility/StringHelper";
import { v4 as uuid } from "uuid";

import { HelmetHeader } from "Components/Helmet-Header";
import { ArchiveIcon } from "@heroicons/react/outline";
import {
    Form,
    Modal,
    Button,
} from "antd";
import { FullLayout } from "Components/Layouts/";
import { InspectionCertificateMasterForm } from "Modules/Settings/InspectionCertificateMaster/Forms/InspectionCertificateMasterForm";
import { InspectionCertificateMasterSerialForm } from "Modules/Settings/InspectionCertificateMaster/Forms/InspectionCertificateMasterSerialForm";

import Reducer from "Modules/Settings/InspectionCertificateMaster/Reducer";
import Saga from "Modules/Settings/InspectionCertificateMaster/Saga";
import InspectionCertificateTypeReducer from "Modules/Settings/InspectionCertificateType/Reducer";
import InspectionCertificateTypeSaga from "Modules/Settings/InspectionCertificateType/Saga";
import {
    SelectInspectionCertificateTypeRequestAction,
    SelectInspectionCertificateTypeSuccessAction
} from "Modules/Settings/InspectionCertificateType/Actions";
import { InspectionCertificateDetailForm } from "../Forms/InspectionCertificateDetailForm";
import { CreateInspectionCertificateMasterRequestAction, GetGtinNumberSuccessAction, SetInspectionCertificateMasterStatusAction, SetInspectionCertificateMasterValueAction, SetLocalGtinLatestValueAction } from "../Actions";
import { MakeGtinLocalSelector, MakeGtinSelector } from "../Selectors";

const key = "inspectionCertificateMaster";
const inspectionCertificateTypeKey = "inspectionCertificateType";
export const InspectionCertificateMasterCreate = () => {
    useInjectReducer({ key, reducer: Reducer });
    useInjectSaga({ key, saga: Saga });
    useInjectReducer({ key: inspectionCertificateTypeKey, reducer: InspectionCertificateTypeReducer });
    useInjectSaga({ key: inspectionCertificateTypeKey, saga: InspectionCertificateTypeSaga });

    // fields
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [serialForm] = Form.useForm();
    const [manualForm] = Form.useForm();
    const gtinLatest = useSelector(MakeGtinLocalSelector());
    const gtin = useSelector(MakeGtinSelector());
    const [serialVisible, setSerialVisible] = useState<boolean>(false);
    const [manualSerialVisible, setManualSerialVisible] = useState<boolean>(false);

    // constructor
    useEffect(() => {
        dispatch(SelectInspectionCertificateTypeRequestAction());

        return () => {
            dispatch(SelectInspectionCertificateTypeSuccessAction([]));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // methods
    const getGtinLatest = (id: string) => {
        const serials = form.getFieldValue("serials");
        if (serials && serials.length > 0) {
            const max = serials.reduce(function (prev, current) {
                return (prev.serialNumber > current.serialNumber) ? prev : current
            });
            const localLatestGtin = max ? max.serialNumber : "000000000100";
            dispatch(SetLocalGtinLatestValueAction(localLatestGtin));
        }
        else {
            dispatch(SetLocalGtinLatestValueAction("000000000100"));
        }
    }

    // events
    const onFormSubmit = (name: string, { forms, values }: any) => {

        switch (name) {
            case "inspectionCertificateMasterSerialForm":
              
                onGenerateSerial(values);
                break;
            case "inspectionCertificateDetailForm":
              
                onManualAddSerial(values);
                break;
            case "inspectionCertificateMasterForm":
                onInspectionCertificateMasterSubmit(values);
                break;
            default: break;
        }
    }

    const onManualAddSerial = (values: any) => {
        const serials = form.getFieldValue("serials") || [];
        const serial = serials.map(e => e.id).indexOf(values.id);

        if (serial > -1) {
            const model = {
                ...values,
                state: 1,
                inspectionCertificateStatusId: "a4dea9f0-4810-4fe3-b450-6c0ef6cbd877",
                equipmentTypeName: values.inspectionCertificateType.label,
            };
            serials[serial] = model;
            form.setFieldValue("serials", [...serials]);
        }
        else {
            const model = {
                id: uuid(),
                inspectionCertificateType: values.inspectionCertificateType.label,
                equipmentTypeName: values.inspectionCertificateType.label,
                inspectionCertificateTypeId: values.inspectionCertificateType.value,
                inspectionCertificateStatusId: "a4dea9f0-4810-4fe3-b450-6c0ef6cbd877",
                serialNumber: values.serialNumber,
                versionId: values.versionId,
                versionGuid: values.versionId,
                quantity: values.quantity,
                printDate: "",
                status: null,
                state: 1,
            };
            form.setFieldValue("serials", [model, ...serials]);
        }
        manualForm.resetFields();
        setManualSerialVisible(false);
    }
    const onGenerateSerial = (values: any) => {
        const localGtin = parseInt(gtinLatest ?? "1");
        const remoteGtin = parseInt(gtin ?? "1");
        
        let finalGtin = 0;
        if (localGtin > remoteGtin) {
            finalGtin = parseInt(GetGtinRunningNumber(gtinLatest));
        }
        else {
            finalGtin = parseInt(GetGtinRunningNumber(gtin));
        }
        const { quantity } = values;
        const serials = form.getFieldValue("serials") || [];
        for (let serialIndex = 0; serialIndex < quantity; serialIndex++) {
            const versionId = uuid();
            const model = {
                id: uuid(),
                inspectionCertificateStatusId: "DEFF22A1-848E-4322-0FE1-08DB0A438C6B",
                equipmentTypeId: values.inspectionCertificateType.key,
                equipmentTypeName: values.inspectionCertificateType.label,
                inspectionCertificateType: values.inspectionCertificateType.label,
                inspectionCertificateTypeId: values.inspectionCertificateType.value,
                serialNumber: GTINGenerator(finalGtin + serialIndex + 1),
                quantity: 0,
                validedDate: values.validUntil,
                versionId: versionId,
                versionGuid: versionId,
                printDate: null,
                status: null,
                state: 1,
            };
            serials.push(model);
        }
        form.setFieldValue("serials", [...serials]);
        serialForm.resetFields();
        setSerialVisible(false);
        dispatch(SetLocalGtinLatestValueAction(null));
        dispatch(GetGtinNumberSuccessAction(null));
        dispatch(SetInspectionCertificateMasterStatusAction(0));
    }

    const onDeleteSerial = (item: any) => {
        const serials = form.getFieldValue("serials");
        const serial = serials.indexOf(item);
        if (serial > -1) {
            serials.splice(serial, 1);
            form.setFieldValue("serials", [...serials]);
        }

    }

    const onEditSerial = (item: any) => {
        const model = {
            ...item,
        };
        manualForm.setFieldsValue(model);
        setManualSerialVisible(true);
    }

    const onNewInspectionCertificateDetail = () => {
        manualForm.setFieldValue("versionId", uuid());
        setManualSerialVisible(true);
    }

    const onInspectionCertificateMasterSubmit = (values: any) => {
        const model = {
            ...values,
        };
        model.inspectionCertificateDetails = form.getFieldValue("serials") || [];

        dispatch(SetInspectionCertificateMasterValueAction(model));
        dispatch(CreateInspectionCertificateMasterRequestAction());
    }

    // render
    const ActionButtons = () => (
        <>
            <Button
                type="primary"

                onClick={() => form.submit()}
            >
                រក្សាទុក
            </Button>
        </>
    );

    return (
        <>
            <FullLayout
                icon={<ArchiveIcon />}
                title="លតាបត្រ"
                descriptions=""
                actions={<ActionButtons />}
            >
                <HelmetHeader meta="លតាបត្រ" />
                <Form.Provider onFormFinish={onFormSubmit}>
                    <InspectionCertificateMasterForm form={form}
                        getGtinLatest={getGtinLatest}
                        setSerialVisible={setSerialVisible}
                        setDetailVisible={onNewInspectionCertificateDetail}
                        onDelete={onDeleteSerial}
                        onEdit={onEditSerial} />
                    <Modal
                        open={serialVisible}
                        onCancel={() => setSerialVisible(false)}
                        onOk={() => serialForm.submit()}>
                        <InspectionCertificateMasterSerialForm form={serialForm} />
                    </Modal>
                    <Modal
                        open={manualSerialVisible}
                        onCancel={() => setManualSerialVisible(false)}
                        onOk={() => manualForm.submit()}>
                        <InspectionCertificateDetailForm form={manualForm} />
                    </Modal>
                </Form.Provider>
            </FullLayout>
        </>
    );
};
