import {
    SET_INSPECTION_CERTIFICATE_STATUS_FILTER_VALUE,
    SET_INSPECTION_CERTIFICATE_STATUS_ID,
    SET_INSPECTION_CERTIFICATE_STATUS_VALUE,

    LIST_INSPECTION_CERTIFICATE_STATUS_REQUEST,
    LIST_INSPECTION_CERTIFICATE_STATUS_SUCCESS,
    GET_INSPECTION_CERTIFICATE_STATUS_REQUEST,
    GET_INSPECTION_CERTIFICATE_STATUS_SUCCESS,
    SELECT_INSPECTION_CERTIFICATE_STATUS_REQUEST,
    SELECT_INSPECTION_CERTIFICATE_STATUS_SUCCESS,

    SET_INSPECTION_CERTIFICATE_STATUS_STATUS,
    CREATE_INSPECTION_CERTIFICATE_STATUS_REQUEST,
    EDIT_INSPECTION_CERTIFICATE_STATUS_REQUEST,
    DELETE_INSPECTION_CERTIFICATE_STATUS_REQUEST,

} from "Modules/Settings/InspectionCertificateStatus/Constants";

export const SetInspectionCertificateStatusFilterValueAction = (values: any) => {
    return {
        type: SET_INSPECTION_CERTIFICATE_STATUS_FILTER_VALUE,
        values
    }
}

export const SetInspectionCertificateStatusValueAction = (values: any) => {
    return {
        type: SET_INSPECTION_CERTIFICATE_STATUS_VALUE,
        values
    }
}

export const SetInspectionCertificateStatusIdAction = (id: string) => {
    return {
        type: SET_INSPECTION_CERTIFICATE_STATUS_ID,
        id,
    }
}

export const ListInspectionCertificateStatusRequestAction = () => {
    return {
        type: LIST_INSPECTION_CERTIFICATE_STATUS_REQUEST,
    }
}

export const ListInspectionCertificateStatusSuccessAction = (entities: any[]) => {
    return {
        type: LIST_INSPECTION_CERTIFICATE_STATUS_SUCCESS,
        entities
    }
}

export const GetInspectionCertificateStatusRequestAction = () => {
    return {
        type: GET_INSPECTION_CERTIFICATE_STATUS_REQUEST,
    }
}

export const GetInspectionCertificateStatusSuccessAction = (entity: any) => {
    return {
        type: GET_INSPECTION_CERTIFICATE_STATUS_SUCCESS,
        entity
    }
}

export const SelectInspectionCertificateStatusRequestAction = () => {
    return {
        type: SELECT_INSPECTION_CERTIFICATE_STATUS_REQUEST,
    }
}

export const SelectInspectionCertificateStatusSuccessAction = (entities: any[]) => {
    return {
        type: SELECT_INSPECTION_CERTIFICATE_STATUS_SUCCESS,
        entities
    }
}

export const SetInspectionCertificateStatusStatusAction = (status: number) => {
    return {
        type: SET_INSPECTION_CERTIFICATE_STATUS_STATUS,
        status,
    }
}

export const CreateInspectionCertificateStatusRequestAction = () => {
    return {
        type: CREATE_INSPECTION_CERTIFICATE_STATUS_REQUEST
    }
}

export const EditInspectionCertificateStatusRequestAction = () => {
    return {
        type: EDIT_INSPECTION_CERTIFICATE_STATUS_REQUEST
    }
}

export const DeleteInspectionCertificateStatusRequestAction = () => {
    return {
        type: DELETE_INSPECTION_CERTIFICATE_STATUS_REQUEST
    }
}