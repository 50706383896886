import { ShowAlert } from "Common/Saga";
import { GET, POST } from "Utility/Constant";
import ApiEndpoint from "Utility/Endpoint";
import Request from "Utility/Http";

export const CreateGameRuleService = (gameRule: any, dispatch: any) => {
    const requestUrl = `${ApiEndpoint.GAME_RULE_CREATE_PATH}`;
    const httpPayload = ApiEndpoint.makeApiPayload(
        requestUrl,
        POST,
        gameRule,
        null,
        false,
        true
    );

    try {
        const response:any = Request(httpPayload);
        return response;
    } catch (error: any) {
        dispatch(ShowAlert("error", error.data.message));
        return null;
    }
}

export const GetGameRuleListService = (filter: any, dispatch: any) => {
    const requestUrl = `${ApiEndpoint.GAME_RULE_PATH}/lists`;
    const httpPayload = ApiEndpoint.makeApiPayload(
        requestUrl,
        POST,
        filter
    );

    try {
        const response = Request(httpPayload);
        return response;
    } catch (error: any) {
        
        if (error.data && error.data.statusCode === 422) {
        }
        dispatch(ShowAlert("error", error.data.message));
        return null;
    }
}

export const SelectGameRuleService = (entityType: number, dispatch: any) => {
    const requestUrl = `${ApiEndpoint.GAME_RULE_PATH}/select/${entityType}`;
    const httpPayload = ApiEndpoint.makeApiPayload(
        requestUrl,
        GET,
        {}
    );

    try {
        const response:any = Request(httpPayload);
        return response;
    } catch (error: any) {
        dispatch(ShowAlert("error", error.data.message));
        return null;
    }
}

export const GetGameRuleByIdService = async (id: string ,dispatch: any) => {
    const requestUrl = `${ApiEndpoint.GAME_RULE_PATH}/${id}`;
    const httpPayload = ApiEndpoint.makeApiPayload(
        requestUrl,
        GET,
        {}
    );

    try {
        const response:any = await Request(httpPayload);
        return response;
    } catch (error: any) {
        dispatch(ShowAlert("error", error.data.message));
        return null;
    }
}