
import { ReportInitialState } from "Modules/Reporting/Reducer";
import { createSelector } from "reselect";

const ReportDomain = (state: any) =>
    state.entityCasino || ReportInitialState;

const MakeDataSourceSelector = () => createSelector(ReportDomain, (substate) => substate.dataSource);

export {
    MakeDataSourceSelector
};
