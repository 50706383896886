
import { FullLayout } from "Components/Layouts/Inner/Full.Layout";
import { HelmetHeader } from "Components/Helmet-Header/index";

export const ReportList = (
    
) => {
    // fields
    
    
    return (
        <>
            <FullLayout
                icon={<></>}
                title="របាយការណ៍"
                descriptions=""
                actions={<></>}
            >
                <HelmetHeader meta="របាយការណ៍" />
                List
            </FullLayout>
        </>
    )


}