
import {
    SET_INSPECTION_CERTIFICATE_MASTER_FILTER_VALUE,
    SET_INSPECTION_CERTIFICATE_MASTER_ID,
    SET_LOCAL_GTIN_LATEST_VALUE,
    SET_INSPECTION_CERTIFICATE_MASTER_VALUE,
    SET_GTIN_EQUIPMENT_TYPE_ID,
    SET_INSPECTION_CERTIFICATE_MASTER_STATUS,

    GET_INSPECTION_CERTIFICATE_MASTER_REQUEST,
    GET_INSPECTION_CERTIFICATE_MASTER_SUCCESS,
    LIST_INSPECTION_CERTIFICATE_MASTER_REQUEST,
    LIST_INSPECTION_CERTIFICATE_MASTER_SUCCESS,
    GET_GTIN_NUMBER_REQUEST,
    GET_GTIN_NUMBER_SUCCESS,
    CREATE_INSPECTION_CERTIFICATE_MASTER_REQUEST,
    REQPORT_INSPECTION_CERTIFICATE_DETAIL_REQUEST,
    REQPORT_INSPECTION_CERTIFICATE_DETAIL_SUCCESS,
    SET_REQPORT_INSPECTION_CERTIFICATE_DETAIL_VALUE,

    // details
    SET_INSPECTION_CERTIFICATE_DETAIL_ID,
    DELETE_INSPECTION_CERTIFICATE_DETAIL_REQEUEST,
    SET_INSPECTION_CERTIFICATE_DETAIL_VALUE,
    EDIT_INSPECTION_CERTIFICATE_DETAIL_REQUEST,
    PRINT_INSPECTION_CERTIFICATE_DETAIL_REQUEST,
    PRINT_INSPECTION_CERTIFICATE_DETAIL_SUCCESS,
    PRINT_INSPECTION_CERTIFICATE_PRINT_DETAIL_SUCCESS,
    PRINT_INSPECTION_CERTIFICATE_PRINT_DETAIL_REQUEST,
    PRINT_INSPECTION_CERTIFICATE_PRINT_BATCH_REQUEST,
    PRINT_INSPECTION_CERTIFICATE_PRINT_BATCH_SUCCESS,
    SET_INSPECTION_CERTIFICATE_SERIALS,
    GET_INSPECTION_CERTIFICATE_SERIALS_REQUEST,
    GET_INSPECTION_CERTIFICATE_SERIALS_SUCCESSS,
} from "Modules/Settings/InspectionCertificateMaster/Constants";

export const SetInspectionCertificateMasterFilterValueAction = (values: any) => {
    return {
        type: SET_INSPECTION_CERTIFICATE_MASTER_FILTER_VALUE,
        values
    }
}

export const SetInspectionCertificateMasterIdAction = (id: string) => {
    return {
        type: SET_INSPECTION_CERTIFICATE_MASTER_ID,
        id
    }

}

export const SetLocalGtinLatestValueAction = (gtin: string) => {
    return {
        type: SET_LOCAL_GTIN_LATEST_VALUE,
        gtin,
    }
}

export const SetInspectionCertificateSerialsAction = (serials: string[]) => {
    return {
        type: SET_INSPECTION_CERTIFICATE_SERIALS,
        serials,
    }
}

export const SetGtinEquipmentTypeIdAction = (id: string) => {
    return {
        type: SET_GTIN_EQUIPMENT_TYPE_ID,
        id
    }
}

export const SetInspectionCertificateMasterStatusAction = (status: number) => {
    return {
        type: SET_INSPECTION_CERTIFICATE_MASTER_STATUS,
        status,
    }
}

export const SetInspectionCertificateMasterValueAction = (values: any) => {
    return {
        type: SET_INSPECTION_CERTIFICATE_MASTER_VALUE,
        values,
    }
}

export const GetInspectionCertificateMasterRequestAction = () => {
    return {
        type: GET_INSPECTION_CERTIFICATE_MASTER_REQUEST
    }
}

export const GetInspectionCertificateMasterSuccessAction = (inspectionCertificateMaster: any) => {
    return {
        type: GET_INSPECTION_CERTIFICATE_MASTER_SUCCESS,
        inspectionCertificateMaster,
    }
}

export const GetGtinNumberRequestAction = () => {
    return {
        type: GET_GTIN_NUMBER_REQUEST
    }
}

export const GetGtinNumberSuccessAction = (gtin: any) => {
    return {
        type: GET_GTIN_NUMBER_SUCCESS,
        gtin
    }
}

export const GetInspectionCertificateSerialRequestActon = () => {
    return {
        type: GET_INSPECTION_CERTIFICATE_SERIALS_REQUEST,
    }
}

export const GetInspectionCertificateSerialSuccessAction = (inspectionSerials: any) => {
    return {
        type: GET_INSPECTION_CERTIFICATE_SERIALS_SUCCESSS,
        inspectionSerials,
    }
}

export const ListInspectionCertificateMasterRequestAction = () => {
    return {
        type: LIST_INSPECTION_CERTIFICATE_MASTER_REQUEST,
    }
}

export const ListInspectionCertificateMasterSuccessAction = (inspectionCertificateMasters: any) => {
    return {
        type: LIST_INSPECTION_CERTIFICATE_MASTER_SUCCESS,
        inspectionCertificateMasters
    }
}

export const CreateInspectionCertificateMasterRequestAction = () => {
    return {
        type: CREATE_INSPECTION_CERTIFICATE_MASTER_REQUEST
    }
}

export const SetReportInspectionCertificateDetailValueAction = (values: any) => {
  return {
    type: SET_REQPORT_INSPECTION_CERTIFICATE_DETAIL_VALUE,
    values
  }
}

export const ReportInspectionCertificateDetailRequestAction  = () => {
  return {
    type: REQPORT_INSPECTION_CERTIFICATE_DETAIL_REQUEST
  }
}

export const ReportInspectionCertificateDetailSuccessAction  = (reports: any) => {
  return {
    type: REQPORT_INSPECTION_CERTIFICATE_DETAIL_SUCCESS,
    reports
  }
}

export const SetInspectionCertificateDetailIdAction = (id: string) => {
    return {
        type: SET_INSPECTION_CERTIFICATE_DETAIL_ID
        , id
    }
}

export const DeleteInspectionCertificateDetailRequestAction = () => {
    return {
        type: DELETE_INSPECTION_CERTIFICATE_DETAIL_REQEUEST
    }
}

export const SetInspectionCertificateDetailValueAction = (values: any) => {
    return {
        type: SET_INSPECTION_CERTIFICATE_DETAIL_VALUE,
        values
    }
}

export const EditInspectionCertificateDetailRequestAction = () => {
    return {
        type: EDIT_INSPECTION_CERTIFICATE_DETAIL_REQUEST
    }
}

export const PrintInspectionCertificateDetailRequestAction = () => {
    return {
        type: PRINT_INSPECTION_CERTIFICATE_DETAIL_REQUEST
    }
}

export const PrintInspectionCertificateDetailSuccessAction = () => {
    return {
        type: PRINT_INSPECTION_CERTIFICATE_DETAIL_SUCCESS,

    }
}


export const PrintInspectionCertificatePrintDetailRequestAction = () => {
    return {
        type: PRINT_INSPECTION_CERTIFICATE_PRINT_DETAIL_REQUEST
    }
}

export const PrintInspectionCertificatePrintDetailSuccessAction = (detail: any) => {
    return {
        type: PRINT_INSPECTION_CERTIFICATE_PRINT_DETAIL_SUCCESS,
        detail
    }
}

export const PrintInspectionCertificatePrintBatchRequestAction = () => {
    return {
        type: PRINT_INSPECTION_CERTIFICATE_PRINT_BATCH_REQUEST
    }
}

export const PrintInspectionCertificatePrintBatchSuccessAction = (details: any) => {
    return {
        type: PRINT_INSPECTION_CERTIFICATE_PRINT_BATCH_SUCCESS,
        details
    }
}
