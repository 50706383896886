import { initialState } from "Modules/Entity/Company/Reducer";
import { createSelector } from "reselect";

const selectEntityCompanyListDomain = (state: any) =>
    state.entityCompany || initialState;

const MakeselectFormValueSelector = () =>
    createSelector(
        selectEntityCompanyListDomain,
        (substate) => substate.formValues
    );

const MakeCompanyValueSelector = () =>
    createSelector(
        selectEntityCompanyListDomain,
        (substate) => substate.values
    );

const MakeSelectCompanySelector = () =>
    createSelector(
        selectEntityCompanyListDomain,
        (substate) => substate.companyOptions
    );

const MakeSelectCompanyDetailSelector = () =>
    createSelector(
        selectEntityCompanyListDomain,
        (substate) => substate.company
    );

const MakeSelectEntityCompanyListSelector = () =>
    createSelector(
        selectEntityCompanyListDomain,
        (substate) => substate.companies
    );

const MakeSelectEntityCompanyListPage = () =>
    createSelector(selectEntityCompanyListDomain, (substate) => substate);

const MakeSelectCompanyModalSelector = () =>
    createSelector(
        selectEntityCompanyListDomain,
        (substate) => substate.companyDetailModal
    );

export default MakeSelectEntityCompanyListPage;
export {
    MakeSelectEntityCompanyListPage,
    MakeselectFormValueSelector,
    MakeSelectEntityCompanyListSelector,
    MakeCompanyValueSelector,
    MakeSelectCompanySelector,
    MakeSelectCompanyDetailSelector,
    MakeSelectCompanyModalSelector,
};
