import {
    SET_EQUIPMENT_BINDING_VALUE,
    CREATE_EQUIPMENT_BINDING_REQUEST,
    CREATE_EQUIPMENT_BINDING_SUCCESS,
    SET_EQUIPMENT_BINDING_UPLOAD_VALUE,
    UPLOAD_EQUIPMENT_BINDING_REQUEST,
    UPLOAD_EQUIPMENT_BINDING_ERROR,
    UPLOAD_EQUIPMENT_BINDING_SUCCESS,
    SET_UPLOAD_EQUIPMENT_BINDING_STATUS,

    SET_EQUIPMENT_BINDING_VERIFY_VALUE,
    VERIFY_EQUIPMENT_BINDING_REQUEST,
    VERIFY_EQUIPMENT_BINDING_SUCCESS,
} from "Modules/Equipments/Casino/Binding/Constants";

export const SetEquipmentBindingValueAction = (values: any) => {
    return {
        type: SET_EQUIPMENT_BINDING_VALUE,
        values,
    }
}

export const CreateEquipmentBindingRequestAction = () => {
    return {
        type: CREATE_EQUIPMENT_BINDING_REQUEST
    }
}

export const CreateEquipmentBindingSuccessAction = () => {
    return {
        type: CREATE_EQUIPMENT_BINDING_SUCCESS
    }
}

export const SetEquipmentBindingUploadValueAction = (file: any) => {
    return {
        type: SET_EQUIPMENT_BINDING_UPLOAD_VALUE,
        file
    }
}

export const UploadEquipmentBindingRequestAction = () => {
    return {
        type: UPLOAD_EQUIPMENT_BINDING_REQUEST,
    }
}

export const UploadEquipmentBindingSuccessAction = (file: any) => {
    return {
        type: UPLOAD_EQUIPMENT_BINDING_SUCCESS,
        file
    }
}

export const UploadEquipmentBindingErrorAction = (file: any) => {
    return {
        type: UPLOAD_EQUIPMENT_BINDING_ERROR,
        file
    }
}

export const SetUploadEquipmentBindingStatusAction = (fileStatus: number) => {
    return {
        type: SET_UPLOAD_EQUIPMENT_BINDING_STATUS,
        fileStatus,
    }
}

export const SetEquipmentBindingVerifyValueAction = (values: any) => {
    return {
        type: SET_EQUIPMENT_BINDING_VERIFY_VALUE,
        values
    }
}

export const VerifyEquipmentBindingRequestAction = () => {
    return {
        type: VERIFY_EQUIPMENT_BINDING_REQUEST
    }
}

export const VerifyEquipmentBindingSuccessAction = (equipments: any) => {
    return {
        type: VERIFY_EQUIPMENT_BINDING_SUCCESS,
        equipments
    }
}