
import React from "react";
interface OperatorByAreaCodeProps {
    operatorData: any
}
export class OperatorFormat extends React.Component<OperatorByAreaCodeProps>{

     renderAreacode = () => {
        const areaCodeList = [];
        for (var key in this.props.operatorData) {
            areaCodeList.push (
                <tr key={key}>
                    <td className="bg-slate-100 border border-black" colSpan={10}>{ key}</td>
                </tr>
                
            );
            areaCodeList.push(this.renderOperatorList(this.props.operatorData[key]));
        }
        return areaCodeList;
    }

    renderOperatorList = (list: any) => {
        const operatorList = [];
        list.map( (item:any, index: number) => {
            operatorList.push(<tr key={item.code} className="bg-white">
                <td className="bg-white-100 border border-black">{index + 1}</td>
                <td className="bg-white-100 border border-black">{item.code}</td>
                <td className="bg-white-100 border border-black">{item.companyName}</td>
                <td className="bg-white-100 border border-black">{item.companyNameLatin}</td>
                <td className="bg-white-100 border border-black">{item.casinoName}</td>
                <td className="bg-white-100 border border-black">{item.casinoNameLatin}</td>
                <td className="bg-white-100 border border-black">{item.representativeName}</td>
                <td className="bg-white-100 border border-black">{item.representativeLatinName}</td>
                <td className="bg-white-100 border border-black">{item.entityTypeId}</td>
                <td className="bg-white-100 border border-black"></td>
            </tr>);
        });
        return operatorList;
    }

    render() {
        console.log("Props", this.props);
        return (<div>
        <div className="flex flex-col items-center">
            <div className="flex flex-row justify-between">
                <div className="flex flex-row">

                </div>
            </div>
            <h1 className="py-4 text-2xl">បញ្ជីឈ្មោះក្រុមហ៊ុនកាស៊ីណូ និងល្បែងផ្សងសំណាង តាមតំបន់</h1>
        </div>
        <table className="w-full text-center border border-black">
            {/* <thead>
            
                
            </thead> */}
            <tbody>
                
                <tr>
                    <td className="w-16 bg-blue-100 border border-black" rowSpan={2}>លរ:</td>
                    <td className="w-24 bg-blue-100 border border-black" rowSpan={2}>លេខកូដ:</td>
                    <td className="bg-blue-100 border border-black" colSpan={2}>នាមករណ៍ក្រុមហ៊ុន</td>
                    <td className="bg-blue-100 border border-black" colSpan={2}>នាមករណ៍កាស៊ីណូ</td>
                    <td className="bg-blue-100 border border-black" colSpan={2}>គោត្តនាម និងនាម</td>
                    <td className="bg-blue-100 border border-black">ទីតាំងធ្វើអាជីវកម្ម</td>
                    <td className="bg-blue-100 border border-black" rowSpan={2}>ផ្សេងៗ</td>
                    
                </tr>
                <tr>
                    <td className="bg-blue-100 border border-black">ភាសាខ្មែរ</td>
                    <td className="bg-blue-100 border border-black">ភាសាអង់គ្លេស</td>
                    <td className="bg-blue-100 border border-black">ភាសាខ្មែរ</td>
                    <td className="bg-blue-100 border border-black">ភាសាអង់គ្លេស</td>
                    <td className="bg-blue-100 border border-black">ភាសាខ្មែរ</td>
                    <td className="bg-blue-100 border border-black">ភាសាអង់គ្លេស</td>
                    <td className="bg-blue-100 border border-black">ភាសាខ្មែរ</td>
                </tr>
                {
                    this.renderAreacode()
                }
                
            </tbody>
        </table>
        </div>)
    }
}