/**
 *
 * Snack Message
 *
 */

import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { notification } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { ClearSnackMessageAction } from "Components/SnackMessage/Actions";
import { useInjectReducer } from "Utility/InjectReducer";
import reducer from "Components/SnackMessage/Reducer";
import { useInjectSaga } from "Utility/InjectSaga";
import saga from "Components/SnackMessage/Saga";
import { createStructuredSelector } from "reselect";
import {
    makeSnackMessageSelector,
    makeDurationSelector,
    makeSnackMessageTypeSelector,
    makeIdSelector,
    makeTranslateSelector,
    makeSnackMessageRedirectSelector,
} from "Components/SnackMessage/Selectors";

const key = "snackMessage";

const stateSelector = createStructuredSelector({
    content: makeSnackMessageSelector(),
    duration: makeDurationSelector(),
    type: makeSnackMessageTypeSelector(),
    redirectUrl: makeSnackMessageRedirectSelector(),
    translate: makeTranslateSelector(),
    id: makeIdSelector(),
});

export default function SnackMessage() {
    useInjectReducer({ key, reducer });
    useInjectSaga({ key, saga });

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id, content, duration, type, redirectUrl } =
        useSelector(stateSelector);

    useEffect(() => {
        if (id && id !== "") {
            const args = {
                message: "Notification",
                description: content,
                duration: duration,
                type: type,
            };
            notification.open(args);

            if (redirectUrl && redirectUrl !== "") {
                navigate(redirectUrl);
            }
            dispatch(ClearSnackMessageAction());
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    return <></>;
}
