import { call, put, all, select, takeLatest } from "redux-saga/effects";
import { POST, GET, PUT } from "Utility/Constant";
import ApiEndpoint from "Utility/Endpoint";
import Request from "Utility/Http";
import { v4 as uuid } from "uuid";

import { ShowAlert, ShowMessage } from "Common/Saga";
import { AsyncStartAction, AsyncEndAction } from "Modules/Application/Actions";
import {
    MakeRequestGameTypeFilterValueSelector,
    MakeRequestGameTypeIdSelector,
    MakeRequestGameTypeValueSelector,
    MakeCertificateGameTypeValueSelector,
    MakeCertificateFilterValueSelector,
} from "Modules/Certificate/Casino/ApplicationForm/GameType/Selectors";
import {
    LIST_REQUEST_GAME_TYPE_REQUEST,
    GET_REQUEST_GAME_TYPE_REQUEST,
    EDIT_REQUEST_GAME_TYPE_REQUEST,
    CREATE_REQUEST_GAME_TYPE_REQUEST,
    CREATE_CERTIFICATE_GAME_TYPE_REQUEST,
    LIST_CERTIFICATE_GAME_TYPE_REQUEST,
} from "Modules/Certificate/Casino/ApplicationForm/GameType/Constants";
import {
    ListRequestGameTypeSuccessAction,
    GetRequestGameTypeSuccessAction,
    ListCertificateGameTypeSuccessAction,
} from "Modules/Certificate/Casino/ApplicationForm/GameType/Actions";

export function* ListReqeustGameType(): any {
    yield put(AsyncStartAction());
    const formValues = yield select(MakeRequestGameTypeFilterValueSelector());

    const requestUrl = `${ApiEndpoint.REQUEST_LICENSE}/lists`;
    const httpPayload = ApiEndpoint.makeApiPayload(
        requestUrl,
        POST,
        formValues
    );
    try {
        const response = yield call(Request, httpPayload);
        yield put(ListRequestGameTypeSuccessAction(response));
        yield put(AsyncEndAction());
    } catch (error: any) {
        yield put(AsyncEndAction());
        if (error.data && error.data.statusCode === 422) {
        }
        return yield ShowAlert("error", error.data.message);
    }
}

export function* GetRequestGameType(): any {
    yield put(AsyncStartAction());
    const id = yield select(MakeRequestGameTypeIdSelector());
    const requestUrl = `${ApiEndpoint.REQUEST_LICENSE}/${id}`;
    const httpPayload = ApiEndpoint.makeApiPayload(requestUrl, GET, id);
    try {
        const response = yield call(Request, httpPayload);
        yield put(GetRequestGameTypeSuccessAction(response));
        yield put(AsyncEndAction());
    } catch (error: any) {
        yield put(AsyncEndAction());
        if (error.data && error.data.statusCode === 422) {
        }
        return yield ShowAlert("error", error.data.message);
    }
}

export function* CreateRequestGameType() {
    yield put(AsyncStartAction());
    const formValues = yield select(MakeRequestGameTypeValueSelector());

    const requestUrl = ApiEndpoint.REQUEST_LICENSE;
    const httpPayload = ApiEndpoint.makeApiPayload(
        requestUrl,
        POST,
        formValues
    );
    try {
        const response = yield call(Request, httpPayload);
        yield put(AsyncEndAction());
        if (response)
            yield ShowMessage({
                type: "success",
                message: "ទិន្នយបានរក្សាទុក",
                content: "ទិន្នយបានរក្សាទុក",
                id: uuid(),
                redirectUrl: `/certificate/casino/request-game-type/request/`,
            });
    } catch (error: any) {
        yield put(AsyncEndAction());
        if (error.data && error.data.statusCode === 422) {
        }
        return yield ShowAlert("error", error.data.message);
    }
}

export function* EditRequestGameType() {
    yield put(AsyncStartAction());
    const formValues = yield select(MakeRequestGameTypeValueSelector());

    const requestUrl = ApiEndpoint.REQUEST_LICENSE;
    const httpPayload = ApiEndpoint.makeApiPayload(requestUrl, PUT, formValues);
    try {
        const response = yield call(Request, httpPayload);
        yield put(AsyncEndAction());
        if (response)
            yield ShowMessage({
                type: "success",
                message: "ទិន្នយបានរក្សាទុក",
                content: "ទិន្នយបានរក្សាទុក",
                id: uuid(),
            });
    } catch (error: any) {
        yield put(AsyncEndAction());
        if (error.data && error.data.statusCode === 422) {
        }
        return yield ShowAlert("error", error.data.message);
    }
}

function* CreateCertificateGameType() {
    yield put(AsyncStartAction());
    const formValues = yield select(MakeCertificateGameTypeValueSelector());

    const requestUrl = ApiEndpoint.getCreateLicensePath();
    const httpPayload = ApiEndpoint.makeApiPayload(
        requestUrl,
        POST,
        formValues
    );
    try {
        const response = yield call(Request, httpPayload);
        yield put(AsyncEndAction());
        if (response)
            yield ShowMessage({
                type: "success",
                message: "ទិន្នយបានរក្សាទុក",
                content: "ទិន្នយបានរក្សាទុក",
                id: uuid(),
                redirectUrl: "/certificate/casino/request-game-type",
            });
    } catch (error: any) {
        yield put(AsyncEndAction());
        if (error.data && error.data.statusCode === 422) {
        }
        return yield ShowAlert("error", error.data.message);
    }
}

function* ListCertificateGameType() {
    yield put(AsyncStartAction());
    const formValues = yield select(MakeCertificateFilterValueSelector());

    const requestUrl = ApiEndpoint.LICENSE_LIST_PATH;
    const httpPayload = ApiEndpoint.makeApiPayload(
        requestUrl,
        POST,
        formValues
    );
    try {
        const response = yield call(Request, httpPayload);
        yield put(AsyncEndAction());
        if (response) {
            yield put(ListCertificateGameTypeSuccessAction(response));
        }
    } catch (error: any) {
        yield put(AsyncEndAction());
        if (error.data && error.data.statusCode === 422) {
        }
        return yield ShowAlert("error", error.data.message);
    }
}

export default function* requestCasinoLicenseSaga() {
    yield all([
        yield takeLatest(
            CREATE_REQUEST_GAME_TYPE_REQUEST,
            CreateRequestGameType
        ),
        yield takeLatest(LIST_REQUEST_GAME_TYPE_REQUEST, ListReqeustGameType),
        yield takeLatest(GET_REQUEST_GAME_TYPE_REQUEST, GetRequestGameType),
        yield takeLatest(EDIT_REQUEST_GAME_TYPE_REQUEST, EditRequestGameType),
        yield takeLatest(CREATE_CERTIFICATE_GAME_TYPE_REQUEST, CreateCertificateGameType),
        yield takeLatest(LIST_CERTIFICATE_GAME_TYPE_REQUEST, ListCertificateGameType),
    ]);
}
