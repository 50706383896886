import { ShowAlert } from "Common/Saga";
import { GET } from "Utility/Constant";
import ApiEndpoint from "Utility/Endpoint";
import Request from "Utility/Http";

export const GetEquipmentByVersionIdService = (id: string, dispatch: any) => {
    const requestUrl = `${ApiEndpoint.EQUIPMENT_CERTIFICATE_MAP_PATH}/versionId/${id}`;
    const httpPayload = ApiEndpoint.makeApiPayload(
        requestUrl,
        GET,
    );

    try {
        const response:any = Request(httpPayload);
        
        return response;
    } catch (error: any) {
        console.log("Test", error);
        dispatch(ShowAlert("error", "Problem occured!"));
    }
}

export const GetInspectionCertificateByVersionIdService = (id: string, dispatch: any) => {
    const requestUrl = `${ApiEndpoint.EQUIPMENT_CERTIFICATE_MAP_PATH}/inspection-certificate/${id}`;
    const httpPayload = ApiEndpoint.makeApiPayload(
        requestUrl,
        GET,
    );

    try {
        const response:any = Request(httpPayload);
        return response;
    } catch (error: any) {
        dispatch(ShowAlert("error", error.data.message));
        return null;
    }
}
