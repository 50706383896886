import {
    SET_EQUIPMENT_TYPE_FILTER_VALUE,
    SET_EQUIPMENT_TYPE_ID,
    SET_EQUIPMENT_TYPE_VALUE,

    LIST_EQUIPMENT_TYPE_REQUEST,
    LIST_EQUIPMENT_TYPE_SUCCESS,
    GET_EQUIPMENT_TYPE_REQUEST,
    GET_EQUIPMENT_TYPE_SUCCESS,
    SELECT_EQUIPMENT_TYPE_REQUEST,
    SELECT_EQUIPMENT_TYPE_SUCCESS,

    SET_EQUIPMENT_TYPE_STATUS,
    CREATE_EQUIPMENT_TYPE_REQUEST,
    EDIT_EQUIPMENT_TYPE_REQUEST,
    DELETE_EQUIPMENT_TYPE_REQUEST,

} from "Modules/Settings/EquipmentType/Constants";

export const SetEquipmentTypeFilterValueAction = (values: any) => {
    return {
        type: SET_EQUIPMENT_TYPE_FILTER_VALUE,
        values
    }
}

export const SetEquipmentTypeValueAction = (values: any) => {
    return {
        type: SET_EQUIPMENT_TYPE_VALUE,
        values
    }
}

export const SetEquipmentTypeIdAction = (id: string) => {
    return {
        type: SET_EQUIPMENT_TYPE_ID,
        id,
    }
}

export const ListEquipmentTypeRequestAction = () => {
    return {
        type: LIST_EQUIPMENT_TYPE_REQUEST,
    }
}

export const ListEquipmentTypeSuccessAction = (entities: any[]) => {
    return {
        type: LIST_EQUIPMENT_TYPE_SUCCESS,
        entities
    }
}

export const GetEquipmentTypeRequestAction = () => {
    return {
        type: GET_EQUIPMENT_TYPE_REQUEST,
    }
}

export const GetEquipmentTypeSuccessAction = (entity: any) => {
    return {
        type: GET_EQUIPMENT_TYPE_SUCCESS,
        entity
    }
}

export const SelectEquipmentTypeRequestAction = () => {
    return {
        type: SELECT_EQUIPMENT_TYPE_REQUEST,
    }
}

export const SelectEquipmentTypeSuccessAction = (entities: any[]) => {
    return {
        type: SELECT_EQUIPMENT_TYPE_SUCCESS,
        entities
    }
}

export const SetEquipmentTypeStatusAction = (status: number) => {
    return {
        type: SET_EQUIPMENT_TYPE_STATUS,
        status,
    }
}

export const CreateEquipmentTypeRequestAction = () => {
    return {
        type: CREATE_EQUIPMENT_TYPE_REQUEST
    }
}

export const EditEquipmentTypeRequestAction = () => {
    return {
        type: EDIT_EQUIPMENT_TYPE_REQUEST
    }
}

export const DeleteEquipmentTypeRequestAction = () => {
    return {
        type: DELETE_EQUIPMENT_TYPE_REQUEST
    }
}