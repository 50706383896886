import { SettingDashboard } from "Modules/Settings/Dashboard";
import { SettingGameTypeRoute } from "Modules/Settings/GameType";
import { SettingGameRuleRoute } from "Modules/Settings/GameRule";
import { SettingFormTypeRoute } from "Modules/Settings/FormType";
import { SettingInspectionCertificateMasterRoute } from "Modules/Settings/InspectionCertificateMaster"
import { SettingEquipmentTypeRoute } from "Modules/Settings/EquipmentType";
import { SettingInspectionCertificateTypeRoute } from "Modules/Settings/InspectionCertificateType";
import { SettingInspectionCertificateStastusRoute } from "Modules/Settings/InspectionCertificateStatus";

export const SettingRoute = [
    {
        path: "dashboard",
        element: <SettingDashboard />
    },
    {
        path: "",
        children: [
            SettingGameTypeRoute,
            SettingGameRuleRoute,
            SettingFormTypeRoute,
            SettingInspectionCertificateMasterRoute,
            SettingEquipmentTypeRoute,
            SettingInspectionCertificateTypeRoute,
            SettingInspectionCertificateStastusRoute
        ],
    },
];
