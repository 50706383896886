import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useInjectReducer } from "Utility/InjectReducer";
import { useInjectSaga } from "Utility/InjectSaga";
import dayjs from "dayjs";

import { ArchiveIcon } from "@heroicons/react/outline";
import { HelmetHeader } from "Components/Helmet-Header";
import { FullLayout } from "Components/Layouts";
import { Link } from "react-router-dom";
import { 
  Form,
  Select,
  Table,
  Button,
  Modal,
  DatePicker,
} from "antd"

import InspectionCertificateMasterReducer from "Modules/Settings/InspectionCertificateMaster/Reducer";
import InspectionCertificateMasterSaga from "Modules/Settings/InspectionCertificateMaster/Saga";
import CasinoReducer from "Modules/Entity/Operator/Reducer";
import CasinoSaga from "Modules/Entity/Operator/Saga";
import { 
  ReportInspectionCertificateDetailRequestAction,
  ReportInspectionCertificateDetailSuccessAction,
  SetReportInspectionCertificateDetailValueAction,
} from "Modules/Settings/InspectionCertificateMaster/Actions"
import { MakeInspectionCertificateDetailReportSelector } from "Modules/Settings/InspectionCertificateMaster/Selectors";
import { DATE_FORMAT, DATE_TIME_FORMAT } from "Modules/Application/Constants";
import { SelectCasinoSuccessAction } from "Modules/Entity/Operator/Actions";
import { MakeSelectCasinoSelector } from "Modules/Entity/Operator/Selectors";
import { GetOperatorOptionService } from "Modules/Entity/Operator/Service";
import { EditOutlined } from "@ant-design/icons";
import { useReactToPrint } from "react-to-print";

const key = "inspectionCertificateMaster";
const entityKey = "entityCasino";
export const CasinoInspectionCertificateReport = () => {
  useInjectReducer({key, reducer: InspectionCertificateMasterReducer});
  useInjectSaga({key, saga: InspectionCertificateMasterSaga});
  useInjectReducer({key: entityKey, reducer: CasinoReducer});
  useInjectSaga({key: entityKey, saga: CasinoSaga});
  // fields
  const dispatch = useDispatch();
  const reports = useSelector(MakeInspectionCertificateDetailReportSelector());
  const casinoOptions = useSelector(MakeSelectCasinoSelector());
  const [equipmentTypeReport, setEquipmentTypeReport] = useState<any>();
  const [gameRuleReport, setGameRuleReport] = useState<any>();
  const printTemplateRef = useRef();
  const columns = [
    {
      title: "លេខកូដកាស៊ីណូ",
      key: "code",
      dataIndex: "code",
    },
    {
      title: "ប្រតិបត្តិករកាស៊ីណូ",
      key: "casinoName",
      dataIndex: "casinoName",
    },
    {
      title: "លេខសម្គាល់",
      key: "serialNumber",
      dataIndex: "serialNumber",
    },
    {
      title: "លេខសម្គាល់ឧបករណ៍",
      key: "equipmentId",
      dataIndex: "equipmentId",
    },
    {
      title: "ប្រភេទឧបករណ៍",
      key: "equipmentType",
      dataIndex: "equipmentType",
    },
    
    {
      title: "ថ្ងៃភ្ជាប់",
      key: "createdDate",
      render: (item: any) => <>{item.createdDate ? dayjs(item.createdDate).format(DATE_TIME_FORMAT) : ""}</>
    },
    {
      title: "ថ្ងៃផុតកំណត់",
      key: "validedDate",
      render: (item: any) => <>{item.validedDate ? dayjs(item.validedDate).format(DATE_FORMAT) : ""}</>
    },
    {
        title: "",
        key: "action",
        render: (item: any) => <Link to={`/equipment/binding/detail/${item.id}`}>
            <Button type="link" icon={<EditOutlined />}>
                
            </Button>
        </Link>
    },
  ];

  const printColumns = [
    {
      title: "លេខកូដកាស៊ីណូ",
      key: "code",
      dataIndex: "code",
    },
    {
      title: "ប្រតិបត្តិករកាស៊ីណូ",
      key: "casinoName",
      dataIndex: "casinoName",
    },
    {
      title: "លេខសម្គាល់",
      key: "serialNumber",
      dataIndex: "serialNumber",
    },
    {
      title: "លេខសម្គាល់ឧបករណ៍",
      key: "equipmentId",
      dataIndex: "equipmentId",
    },
    {
      title: "ប្រភេទឧបករណ៍",
      key: "equipmentType",
      dataIndex: "equipmentType",
    },
    
    {
      title: "ថ្ងៃភ្ជាប់",
      key: "createdDate",
      render: (item: any) => <>{item.createdDate ? dayjs(item.createdDate).format(DATE_TIME_FORMAT) : ""}</>
    },
    {
      title: "ថ្ងៃផុតកំណត់",
      key: "validedDate",
      render: (item: any) => <>{item.validedDate ? dayjs(item.validedDate).format(DATE_FORMAT) : ""}</>
    },
  ];

  // constructo;r
  useEffect(() => {
    GetOperatorOptionService(dispatch)
    .then((casinoRs: any) => {
        if(casinoRs) {
            dispatch(SelectCasinoSuccessAction(casinoRs));
        }
        dispatch(ReportInspectionCertificateDetailRequestAction());
    })
    
    return() => {
      dispatch(SelectCasinoSuccessAction([]));
      dispatch(ReportInspectionCertificateDetailSuccessAction([]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if(reports && reports.length > 0) {
        onSummary();
    }
  },[reports])

  // event
  const onFilter = (e) => {
    
    const filter = {
        casinoId: e.casinoId,
        startDate: e.filterDate ? e.filterDate[0] : null,
        endDate: e.filterDate ? e.filterDate[1] : null,
    };
    console.log("FIlter", filter);
    dispatch(SetReportInspectionCertificateDetailValueAction(filter));
    dispatch(ReportInspectionCertificateDetailRequestAction());
  }

  const onSummary = () => {
    const result: any = [];
    const gameRuleResult: any = [];
    reports.map(function(value: any) {
        if(!gameRuleResult[value.gameRuleName]) {
            gameRuleResult[value.gameRuleName] = 0;
        }
        if(!result[value.equipmentType]) {
            result[value.equipmentType] = 0;
        }
        result[value.equipmentType] += 1;
        gameRuleResult[value.gameRuleName] += 1;
        return result;
    });
    
    setEquipmentTypeReport(result);
    setGameRuleReport(gameRuleResult);
  }

  const onPrintSerials = () => {
    Modal.info({
        title: 'Print Confirmation',
        content: (
          <div>
            <p>Do you want to print these serials?</p>
            
          </div>
        ),
        onOk() {
            onPrintSerialsContent();
        },
    });
    
    
}

  const onPrintSerialsContent = useReactToPrint({
        content: () => {
            return printTemplateRef.current;
        }
    })

  // render
  const ActionButton = () => {
    return <>
        <Button type="default" onClick={onPrintSerials}>
            Print
        </Button>
    </>
  }

  const gameRuleSummary = () => {
    const gameRuleData = []
    for (const key in gameRuleReport) {
        
        gameRuleData.push({
            key: key,
            value: gameRuleReport[key],
        });
    }
    return gameRuleData;
  }

  const equipmentTypeSummary = () => {
    const gameRuleData = []
    for (const key in equipmentTypeReport) {
        
        gameRuleData.push({
            key: key,
            value: equipmentTypeReport[key],
        });
    }
    return gameRuleData;
  }

  return (
    <FullLayout
      icon={<ArchiveIcon />}
      title="របាយការណ៍ សង្ខេបពីឧបករណ៍/កម្មវិធីល្បែង"
      descriptions=""
      redirectUrl=""
      actions={<ActionButton />}
  >
      <HelmetHeader meta="របាយការណ៍ សង្ខេបពីឧបករណ៍/កម្មវិធីល្បែង" />
      <Form 
        name="casinoInspectionCertificateReportForm" 
        layout="vertical"
        className="flex flex-row items-end p-4 m-0 gap-2"
        onFinish={onFilter}>
        <Form.Item name="casinoId">
          <Select 
            placeholder="កាស៊ីណូ" 
            style={{width: 310}} 
            filterOption={(
                input,
                  option
              ) =>
                  option!.children
                      .join(" ")
                      .toLowerCase()
                      .includes(
                          input.toLowerCase()
                      )
              }
            showSearch
            allowClear>
          {casinoOptions &&
            casinoOptions.map(
                (item) => (
                    <Select.Option
                        key={
                            item.value
                        }
                        value={
                            item.value
                        }
                    >
                        {item.label}
                        (
                        {
                            item.placeholder
                        }
                        )
                    </Select.Option>
                )
            )}
          </Select>
        </Form.Item>
        <Form.Item name="filterDate">
            <DatePicker.RangePicker />
        </Form.Item>
        <Form.Item >
            <Button htmlType="submit">ស្វែងរក</Button>
        </Form.Item>
      </Form>
      <div className="grid grid-cols-1 md:grid-cols-2 p-4 gap-4">
        {
            equipmentTypeReport && <Table 
                className="" 
                size="small" rowKey="key" 
                dataSource={equipmentTypeSummary()}
                pagination={false}
            >
                <Table.Column 
                    title="ប្រភេទឧបករណ៍ / Equipment Type"
                    dataIndex="key"
                />
                <Table.Column 
                    title="ចំនួន"
                    dataIndex="value"
                />
            </Table>
        }

        {
            gameRuleReport && <Table 
                className="" 
                size="small" rowKey="key" 
                dataSource={gameRuleSummary()} 
                pagination={false}
            >
                <Table.Column 
                    title="វិធានល្បែង / Game Rule"
                    dataIndex="key"
                />
                <Table.Column 
                    title="ចំនួន"
                    dataIndex="value"
                />
            </Table>
        }
        </div>
        <Table 
            className="" 
            size="small" rowKey="id" 
            dataSource={reports} columns={columns}
            summary={() => (
                <Table.Summary fixed>
                <Table.Summary.Row>
                    <Table.Summary.Cell index={0}>Total Terminal</Table.Summary.Cell>
                    <Table.Summary.Cell index={1}>{reports.length}</Table.Summary.Cell>
                </Table.Summary.Row>
                </Table.Summary>
            )}
        />
        
        <div className="w-full hidden">
            <div className="m-auto" ref={printTemplateRef}>
                {/* {
                    inspectionSerials && inspectionSerials.length > 0 && 
                        inspectionSerials.map((inspectionCertificate: any) => <PrintTemplate key={inspectionCertificate.id} inspectionCertificate={inspectionCertificate}/>)
                } */}
                {
                    equipmentTypeReport && <Table 
                        className="" 
                        size="small" rowKey="key" 
                        dataSource={equipmentTypeSummary()}
                        pagination={false}
                    >
                        <Table.Column 
                            title="ប្រភេទឧបករណ៍ / Equipment Type"
                            dataIndex="key"
                        />
                        <Table.Column 
                            title="ចំនួន"
                            dataIndex="value"
                        />
                    </Table>
                }

                <Table 
                    className="" 
                    size="small" rowKey="id" 
                    pagination={false} 
                    dataSource={reports} columns={printColumns}
                    summary={() => (
                        <Table.Summary fixed>
                        <Table.Summary.Row>
                            <Table.Summary.Cell index={0}>Total Terminal</Table.Summary.Cell>
                            <Table.Summary.Cell index={1}>{reports.length}</Table.Summary.Cell>
                        </Table.Summary.Row>
                        </Table.Summary>
                    )}
                />
            </div>
        </div>
  </FullLayout>
  )
}