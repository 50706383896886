import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import { useInjectSaga } from "Utility/InjectSaga";
import { useInjectReducer } from "Utility/InjectReducer";

import { Button, Form, Table } from "antd";
import { FullLayout } from "Components/Layouts/";
import { HelmetHeader } from "Components/Helmet-Header";
import { RequestGameRuleFilterForm } from "Modules/Certificate/Casino/ApplicationForm/GameRule/Forms/RequestGameRuleFilterForm";

import Reducer from "Modules/Certificate/Casino/ApplicationForm/GameRule/Reducer";
import Saga from "Modules/Certificate/Casino/ApplicationForm/GameRule/Saga";
import {
    SetRequestGameRuleFilterValueAction,
    ListRequestGameRuleRequestAction,
} from "Modules/Certificate/Casino/ApplicationForm/GameRule/Actions";
import { MakeListRequestGameRuleSelector } from "Modules/Certificate/Casino/ApplicationForm/GameRule/Selectors";
import { DATE_FORMAT } from "Modules/Application/Constants";
import { EntityType } from "Common/Constants/EntityType";

const key = "certificateGameRule";
export const RequestGameRuleList = () => {
    useInjectReducer({ key, reducer: Reducer });
    useInjectSaga({ key, saga: Saga });
    //fields
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const gameRuleList = useSelector(MakeListRequestGameRuleSelector());

    const licenseColumns = [
        {
            title: "លេខតាមដានឯកសារ",
            key: "code",
            render: (item: any) => {
                return (
                    <Link
                        to={`/certificate/casino/request-game-rule/request/${item.id}?entityType=${EntityType.CASINO}`}
                        className="underline text-blue-500"
                    >
                        {item.documentCode}
                    </Link>
                );
            },
        },
        {
            title: "ឈ្មោះក្រុមហ៊ុន",
            key: "companyNameInKhmer",
            dataIndex: "companyNameInKhmer",
        },
        {
            title: "ឈ្មោះកាស៊ីណូ",
            key: "casinoNameInKhmer",
            dataIndex: "casinoNameInKhmer",
        },
        {
            title: "ទីតាំងធ្វើអាជីវកម្ម",
            key: "address",

            render: (item: any) => {
                const address = JSON.parse(item.formData).casino.address;
                return <span>{address}</span>;
            },
        },
        {
            title: "ថ្ងៃស្នើសុំ",
            key: "licenseIssuedDate",

            render: (item: any) => (
                <span>
                    {item.receivedDate
                        ? dayjs(item.receivedDate).format(DATE_FORMAT)
                        : ""}
                </span>
            ),
        },
        // {
        //     title: "ស្ថានភាព",
        //     key: "licenseIssuedDate",

        //     render: (item: any) => (
        //         <span>
        //             {item.requestStatus ===
        //                 "234f9073-4e93-4549-87fc-d9bb25bbb0cd"
        //                 ? "មិនទាន់គ្រប់គ្រាន់"
        //                 : "បានអាជ្ញាបណ្ណ"}
        //         </span>
        //     ),
        // },
    ];

    // constructor
    useEffect(() => {
        const formValues = {
            search: form.getFieldValue("search"),
            formType: "C003",
        };

        dispatch(SetRequestGameRuleFilterValueAction(formValues));
        dispatch(ListRequestGameRuleRequestAction());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    // method
    const ActionButton = () => {
        return (
            <>
                <Link
                    to={`create?entityType=${EntityType.CASINO}`}>
                    <Button type="primary">
                        បញ្ចូលថ្មី
                    </Button>
                </Link>
            </>
        );
    };
    // events
    const onFilterFormSubmit = (values: any) => {
        const formValues = {
            search: form.getFieldValue("search"),
            formType: "C003",
        };

        dispatch(SetRequestGameRuleFilterValueAction(formValues));
        dispatch(ListRequestGameRuleRequestAction());
    };

    return (
        <FullLayout
            icon={null}
            title="បញ្ជីពាក្យស្នើសុំចុះវិធានល្បែង (C003)"
            descriptions=""
            actions={<ActionButton />}
        >
            <HelmetHeader meta="បញ្ជី" />
            <Form
                name="requestGameRuleList"
                onFinish={onFilterFormSubmit}
                layout="vertical"
                className="grid grid-cols-4 items-end gap-2 p-4"
                form={form}
                initialValues={{ search: "" }}
            >
                <RequestGameRuleFilterForm form={form} />
            </Form>
            <Table
                columns={licenseColumns}
                dataSource={gameRuleList}
                rowKey="id"
                className="p-4"
                size="small"
            />
        </FullLayout>
    );
};
