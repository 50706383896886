import produce from "immer";
import {
    SET_INSPECTION_CERTIFICATE_STATUS_FILTER_VALUE,
    SET_INSPECTION_CERTIFICATE_STATUS_ID,
    SET_INSPECTION_CERTIFICATE_STATUS_VALUE,

    LIST_INSPECTION_CERTIFICATE_STATUS_SUCCESS,
    GET_INSPECTION_CERTIFICATE_STATUS_SUCCESS,
    SELECT_INSPECTION_CERTIFICATE_STATUS_SUCCESS,
    SET_INSPECTION_CERTIFICATE_STATUS_STATUS,
} from "Modules/Settings/InspectionCertificateStatus/Constants";

export const InspectionCertificateStatusInitialState: any = {
    values: null,
    filterValues: null,
    id: null,
    entities: [],
    entity: null,
    options: [],
    status: 0,
}

const InspectionCertificateStatusReducer = produce((draft, action) => {
    switch (action.type) {
        case SET_INSPECTION_CERTIFICATE_STATUS_FILTER_VALUE:
            draft.filterValues = action.values;
            break;
        case SET_INSPECTION_CERTIFICATE_STATUS_ID:
            draft.id = action.id;
            break;
        case SET_INSPECTION_CERTIFICATE_STATUS_VALUE:
            draft.values = action.values;
            break;
        case LIST_INSPECTION_CERTIFICATE_STATUS_SUCCESS:
            draft.entities = action.entities;
            break;
        case GET_INSPECTION_CERTIFICATE_STATUS_SUCCESS:
            draft.entity = action.entity;
            break;
        case SELECT_INSPECTION_CERTIFICATE_STATUS_SUCCESS:
            draft.options = action.entities;
            break;
        case SET_INSPECTION_CERTIFICATE_STATUS_STATUS:
            draft.status = action.status;
            break;
        default: break;
    }
}, InspectionCertificateStatusInitialState);

export default InspectionCertificateStatusReducer;