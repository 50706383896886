import { MakeSelectGameRuleSelector } from "Modules/Settings/GameRule/Selectors";
import {
    Form,
    Input,
    Select,
} from "antd";
import { useSelector } from "react-redux";

export const EquipmentBindingForm = ({ form }: any) => {
    const gameRules = useSelector(MakeSelectGameRuleSelector());
    return (
        <Form
            name="equipmentBindingForm"
            layout="vertical"
            className="p-5 grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-4"
            form={form}
        >
            <Form.Item name="casinoId" hidden><Input /></Form.Item>
            <Form.Item name="equipmentId" label="Equipment Id">
                <Input />
            </Form.Item>
            <Form.Item name="zone" label="Zone">
                <Input />
            </Form.Item>
            <Form.Item name="gameRule" label="Game Rule">
                <Select
                    labelInValue
                    style={{ minWidth: 200 }}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                        {
                            return (option?.children.toString() ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                    }
                    showSearch
                >
                    {gameRules &&
                        gameRules.map((item) => (
                            <Select.Option
                                key={item["id"]}
                                value={item["id"]}
                            >
                                {item["name"]}
                            </Select.Option>
                        ))}
                </Select>
            </Form.Item>
        </Form>)
}