import axios from "axios";
import { isExpired, } from "react-jwt";
import { LogoutAction, SetPermissionValueAction } from "Modules/Application/Actions";
import { SHOW_SNACK_MESSAGE } from "Components/SnackMessage/Constants";
import { v4 as uuid } from "uuid";
import { store } from "Store";
import { StatusCodesList } from "Utility/Constant";
import { decodePermission } from "Utility/Permission";
import { refreshTokenService } from "./HttpService";

/**
 * Create an Axios Client with defaults
 */
const client = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URI,
    withCredentials: true,
});
let refreshingFunc: any = undefined;
client.interceptors.response.use(
    (res) => {
        return res;
    },
    async (err) => {
        const originalConfig = err.config;
        const token = localStorage.getItem("cgmcToken") || "";
        const isTokenExpired = isExpired(token);
        if (
            err?.response?.status === StatusCodesList.Unauthorize &&
            isTokenExpired === true
        ) {
            try{
                if(!refreshingFunc) {
                    refreshingFunc = refreshTokenService(token);
                }
                const newToken = await refreshingFunc;
                if (newToken.status === 200) {
                    localStorage.setItem(
                        "cgmcToken",
                        newToken.data.accessToken
                    );
                    const grants = decodePermission(newToken.data.accessToken);
                    store.dispatch(SetPermissionValueAction(grants));
                    originalConfig.headers.Authorization = `Bearer ${newToken.data.accessToken}`;
                }
                try {
                    return await axios.request(originalConfig);
                } catch(innerError) {
                    // if original req failed with 401 again - it means server returned not valid token for refresh request
                     
                }
            }
            catch(_error) {
                
                if(_error.response && _error.response.status === 400) {
                    
                    store.dispatch({
                        type: SHOW_SNACK_MESSAGE,
                        snack: {
                            type: "error",
                            message: "Session is disconnected!",
                            id: uuid(),
                        },
                    });
                    store.dispatch(LogoutAction());
                }
            }
            finally{
                refreshingFunc = undefined;
            }
            
            // return {
            //     ...originalConfig,
            //     cancelToken: new axios.CancelToken((cancel) =>
            //         cancel("Cancel repeated request")
            //     ),
            // };
        }
        else if (err?.response?.status === StatusCodesList.Unauthorize) {
            
            store.dispatch(
                {
                    type: SHOW_SNACK_MESSAGE,
                    snack: {
                        type: "error",
                        message: "មិនមានសិទ្ធិ",
                        id: uuid(),
                    },
                }
            );
        }
        
        return Promise.reject(err);
    }
);

/**
 * Request Wrapper with default success/error actions
 */
const Request = (options: any) => {
    const onSuccess = (response: any) => {
        if (options.isRaw === true) {
            return response;
        }
        return response.data;
    };
    const onError = (error: any) => {
        console.log("onError", error);
        if (error.response?.data) {
            // Error codes Array
            const errorCodesArray = [
                StatusCodesList.InvalidRefreshToken,
                StatusCodesList.RefreshTokenExpired,
                StatusCodesList.TokenExpired,
            ];
            
            // Check if otp required error code is in the array
            if (error.response.data.code === StatusCodesList.OtpRequired) {
                // return store.dispatch(otpUnVerifiedAction());
            }
            if (errorCodesArray.includes(error.response.data.code)) {
                return store.dispatch({
                    type: SHOW_SNACK_MESSAGE,
                    snack: {
                        type: "error",
                        message: error.response.data.message,
                        id: uuid(),
                    },
                });
            }
            else {
                
                store.dispatch({
                    type: SHOW_SNACK_MESSAGE,
                    snack: {
                        type: "error",
                        message: error.response.data,
                        id: uuid(),
                    },
                });
            }
        }

        return Promise.reject(error.response || error.message);
    };

    return client(options).then(onSuccess).catch(onError);
};

export default Request;
