import { ShowAlert } from "Common/Saga";
import { AsyncEndAction, AsyncStartAction } from "Modules/Application/Actions";
import { GET, POST } from "Utility/Constant";
import ApiEndpoint from "Utility/Endpoint";
import Request from "Utility/Http";

export const GetOperatorService = (dispatch: any) => {
    const requestUrl = `${ApiEndpoint.OPERATOR_PATH}`;
    const httpPayload = ApiEndpoint.makeApiPayload(
        requestUrl,
        GET        
    );
    try {
        const response:any = Request(httpPayload);
        return response;
    } catch (error: any) {
        dispatch(ShowAlert("error", error.data.message));
        return null;
    }
}

export const GetOperatorOptionService = (dispatch: any) => {
    dispatch(AsyncStartAction());
    const requestUrl = ApiEndpoint.CASINO_SELECT_PATH;
    const httpPayload = ApiEndpoint.makeApiPayload(requestUrl, POST);
    try {
        const response =  Request(httpPayload);
        return response;
    } catch (error: any) {
        
        if (error.data && error.data.statusCode === 422) {
            // return yield put(EnterValidationErrorAction(error.data.message));
        }
        dispatch(ShowAlert("error", error.data.message));
        return null;
    }
    finally{
        dispatch(AsyncEndAction());
    }
}

export const GetCasinoTypeService = (dispatch: any) => {
    const requestUrl = `${ApiEndpoint.SELECTABLE_CASINO_TYPE_PATH}`;
    const httpPayload = ApiEndpoint.makeApiPayload(
        requestUrl,
        POST        
    );
    try {
        const response:any = Request(httpPayload);
        return response;
    } catch (error: any) {
        dispatch(ShowAlert("error", error.data.message));
        return null;
    }
    finally{
        dispatch(AsyncEndAction());
    }
}

export const  GetOperatorByIdService = (id: string, dispatch: any) => {
    const requestUrl = `${ApiEndpoint.CASINO_BY_ID_PATH}detail/${id}`;
    const httpPayload = ApiEndpoint.makeApiPayload(
        requestUrl,
        GET,
        id,
        false,
        false
    );
    try {
        const response = Request(httpPayload);
        return response;
    } catch (error: any) {
        
        if (error.data && error.data.statusCode === 422) {
        }
        dispatch(ShowAlert("error", error.data.message));
        return null;
    }
}

export const GetNationalityOptionService = (dispatch: any) => {
    const requestUrl = `${ApiEndpoint.SELECTABLE_NATIONALITY_PATH}`;
    const httpPayload = ApiEndpoint.makeApiPayload(
        requestUrl,
        POST,
        {}        
    );
    try {
        const response:any = Request(httpPayload);
        return response;
    } catch (error: any) {
        dispatch(ShowAlert("error", error.data.message));
        return null;
    }   
}

export const GetIdentificationTypeOptionService = (dispatch: any) => {
    const requestUrl = `${ApiEndpoint.SELECTABLE_IDENTIFICATION_TYPE_PATH}`;
    const httpPayload = ApiEndpoint.makeApiPayload(
        requestUrl,
        POST,
        {}        
    );
    try {
        const response:any = Request(httpPayload);
        return response;
    } catch (error: any) {
        dispatch(ShowAlert("error", error.data.message));
        return null;
    }   
}
