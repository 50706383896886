import { createSelector } from "reselect";
import { initialState } from "Components/SignIn/Reducer";

const selectSignInDomain = (state: any) => state.signin || initialState;
const MakeFormValuesSelector = () =>
    createSelector(selectSignInDomain, (substate) => substate.formValues);

const MakeInitialValuesSelector = () =>
    createSelector(selectSignInDomain, (substate) => substate.initialValues);

const MakeErrorSelector = () =>
    createSelector(selectSignInDomain, (substate) => substate.errors);

const MakeIsLoadingSelector = () =>
    createSelector(selectSignInDomain, (substate) => substate.isLoading);

/**
 * Default selector used by LoginPage
 */

const MakeSelectLoginPage = () =>
    createSelector(selectSignInDomain, (substate) => substate);

export default MakeSelectLoginPage;
export {
    MakeFormValuesSelector,
    MakeInitialValuesSelector,
    MakeErrorSelector,
    MakeIsLoadingSelector,
};
