import { ShowAlert } from "Common/Saga";
import { GET, POST } from "Utility/Constant";
import ApiEndpoint from "Utility/Endpoint";
import Request from "Utility/Http";

export const CreateGameTypeService = (gameRule: any, dispatch: any) => {
    const requestUrl = `${ApiEndpoint.GAME_TYPE_PATH}`;
    const httpPayload = ApiEndpoint.makeApiPayload(
        requestUrl,
        POST,
        gameRule,
        null,
        false,
        true
    );

    try {
        const response:any = Request(httpPayload);
        return response;
    } catch (error: any) {
        dispatch(ShowAlert("error", error.data.message));
        return null;
    }
}

export const SelectGameTypeService = (entityType: number, dispatch: any) => {
    const requestUrl = `${ApiEndpoint.GAME_TYPE_PATH}?entityType=${entityType}`;
    const httpPayload = ApiEndpoint.makeApiPayload(
        requestUrl,
        GET,
        {}
    );

    try {
        const response:any = Request(httpPayload);
        return response;
    } catch (error: any) {
        dispatch(ShowAlert("error", error.data.message));
        return null;
    }
}

export const GetGameTypeByIdService = async (id: string ,dispatch: any) => {
    const requestUrl = `${ApiEndpoint.GAME_TYPE_PATH}/${id}`;
    const httpPayload = ApiEndpoint.makeApiPayload(
        requestUrl,
        GET,
        {}
    );

    try {
        const response:any = await Request(httpPayload);
        return response;
    } catch (error: any) {
        dispatch(ShowAlert("error", error.data.message));
        return null;
    }
}