import produce from "immer";
import {
    SET_EQUIPMENT_TYPE_FILTER_VALUE,
    SET_EQUIPMENT_TYPE_ID,
    SET_EQUIPMENT_TYPE_VALUE,

    LIST_EQUIPMENT_TYPE_SUCCESS,
    GET_EQUIPMENT_TYPE_SUCCESS,
    SELECT_EQUIPMENT_TYPE_SUCCESS,
    SET_EQUIPMENT_TYPE_STATUS,
} from "Modules/Settings/EquipmentType/Constants";

export const EquipmentTypeInitialState: any = {
    values: null,
    filterValues: null,
    id: null,
    entities: [],
    entity: null,
    options: [],
    status: 0,
}

const EquipmentTypeReducer = produce((draft, action) => {
    switch (action.type) {
        case SET_EQUIPMENT_TYPE_FILTER_VALUE:
            draft.filterValues = action.values;
            break;
        case SET_EQUIPMENT_TYPE_ID:
            draft.id = action.id;
            break;
        case SET_EQUIPMENT_TYPE_VALUE:
            draft.values = action.values;
            break;
        case LIST_EQUIPMENT_TYPE_SUCCESS:
            draft.entities = action.entities;
            break;
        case GET_EQUIPMENT_TYPE_SUCCESS:
            draft.entity = action.entity;
            break;
        case SELECT_EQUIPMENT_TYPE_SUCCESS:
            draft.options = action.entities;
            break;
        case SET_EQUIPMENT_TYPE_STATUS:
            draft.status = action.status;
            break;
        default: break;
    }
}, EquipmentTypeInitialState);

export default EquipmentTypeReducer;