import { EntityOperatorRoute } from "Modules/Entity/Operator";
import { EntityCompanyRoute } from "Modules/Entity/Company";


export const EntityRoute = [
    {
        path: "",
        children: [EntityOperatorRoute, EntityCompanyRoute,],
    },
];
