import { decodeToken } from "react-jwt";
export function checkPermissionForComponent(permissions, route, permissionConstant) {
    if (!permissions) return false;
    if (!permissionConstant) return false;
    if (route && route.defaultPermission) return true;
    
    return permissionConstant.every(el => permissions.includes(el));
}

export const decodePermission = (grants: string) => {
    const decodeTokenValue: any = decodeToken(grants);
    if (decodeTokenValue?.grants) {
        const permissionArray = decodeTokenValue?.grants.split(",");

        return permissionArray.map(function (item) {
            return parseInt(item);
        });
    }
    return [];
}

export const checkItemPermission = (permissions: any) => {
    const token = localStorage.getItem("cgmcToken");
    if(!token) {
        return ;
    }
    const grants = decodePermission(token);
    console.log("grans", grants)
    return checkPermissionForComponent(permissions, null, grants);
}