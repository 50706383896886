import React from "react";
import { put } from "redux-saga/effects";
import { FormattedMessage } from "react-intl";
import { EnqueueAlertAction } from "Components/AlertMessage/Actions";
import { EnqueueSnackMessageAction } from "Components/SnackMessage/Actions";

/**
 * show formatted error alert
 * @param type
 * @param message
 * @returns {IterableIterator<*>}
 */
export function* ShowFormattedAlert(type?: any, message?: any): any {
    return yield put(
        EnqueueAlertAction({
            message: <FormattedMessage {...message} />,
            type,
        })
    );
}

/**
 * show formatted snack message
 * @param payload: {type: string, message: string, translate: boolean, id: string}
 * @returns {IterableIterator<*>}
 */
export function* ShowMessage(payload: any): any {
    return yield put(EnqueueSnackMessageAction(payload));
}

/**
 * show error alert
 * @param type
 * @param message
 * @returns {IterableIterator<*>}
 */
export function* ShowAlert(type: any, message: string): any {
    return yield put(
        EnqueueAlertAction({
            message,
            type,
        })
    );
}
