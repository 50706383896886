import { ShowAlert } from "Common/Saga";
import { GET } from "Utility/Constant";
import ApiEndpoint from "Utility/Endpoint";
import Request from "Utility/Http";

export const GetCompanyService = (dispatch: any) => {
    const requestUrl = `${ApiEndpoint.COMPANY_PATH}/select`;
    const httpPayload = ApiEndpoint.makeApiPayload(
        requestUrl,
        GET        
    );
    try {
        const response:any = Request(httpPayload);
        return response;
    } catch (error: any) {
        dispatch(ShowAlert("error", error.data.message));
        return null;
    }
}

export const GetCompanyByIdService = (id: string, dispatch: any) => {
    const requestUrl = `${ApiEndpoint.COMPANY_PATH}/${id}`;
    const httpPayload = ApiEndpoint.makeApiPayload(
        requestUrl,
        GET        
    );
    try {
        const response:any = Request(httpPayload);
        return response;
    } catch (error: any) {
        dispatch(ShowAlert("error", error.data.message));
        return null;
    }
}
