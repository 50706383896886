import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { useInjectReducer } from "Utility/InjectReducer";
import { useInjectSaga } from "Utility/InjectSaga";
import ApplicationReducer from "Modules/Application/Reducer";
import ApplicationSaga from "Modules/Application/Saga";
import { SetAuthenticationAppRequestIdAction, AuthenticationAppRequestAction, AuthenticationAppSuccessAction, IsLoggedSuccessAction } from "Modules/Application/Actions";
import { MakeAuthenticationAppStatusSelector } from "Modules/Application/Selectors";

const key = "application";
export const AuthenticationRequest = () => {
    useInjectReducer({ key, reducer: ApplicationReducer });
    useInjectSaga({ key, saga: ApplicationSaga });
    // fields
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();
    const authenticationStatus = useSelector(MakeAuthenticationAppStatusSelector());

    useEffect(() => {
        if (id) {
            dispatch(SetAuthenticationAppRequestIdAction(id));
            dispatch(AuthenticationAppRequestAction());
        }
        return () => {
            dispatch(SetAuthenticationAppRequestIdAction(null));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    useEffect(() => {

        if (authenticationStatus === 1) {
            dispatch(IsLoggedSuccessAction());
            navigate("/");
        }
        else if (authenticationStatus === 2) {
            // window.location.href = "https://cgmc-core-dev.sgx.bz/";
        }
        return () => {
            dispatch(AuthenticationAppSuccessAction(0));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authenticationStatus])

    // return <Button onClick={login}>Login</Button>;
    return <span>Loadding...</span>
};
