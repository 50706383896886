export const LOGGED_IN = "containers/App/LOGGED_IN";
export const GET_PROFILE_REQUEST = "containers/App/GET_PROFILE_REQUEST";
export const GET_PROFILE_SUCCESS = "containers/App/GET_PROFILE_SUCCESS";
export const GET_PROFILE_ERROR = "containers/App/GET_PROFILE_ERROR";
export const IS_LOGGED = "containers/App/IS_LOGGED";
export const IS_LOGGED_SUCCESS = "containers/App/IS_LOGGED_SUCCESS";
export const IS_LOGGED_ERROR = "containers/App/IS_LOGGED_ERROR";
export const LOGOUT = "containers/App/LOGOUT";
export const LOGOUT_SUCCESS = "containers/App/LOGOUT_SUCCESS";
export const LOGOUT_ERROR = "containers/App/LOGOUT_ERROR";
export const REFRESH_TOKEN = "containers/App/REFRESH_TOKEN";
export const ASYNC_START = "containers/App/ASYNC_START";
export const ASYNC_END = "containers/App/ASYNC_END";

export const DASHBOARD_REQUEST_REDIRECT = "DASHBOARD_REQUEST_REDIRECT";
export const DASHBOARD_REQUEST_REDIRECT_SUCCESS =
    "DASHBOARD_REQUEST_REDIRECT_SUCCESS";

export const DATE_FORMAT = "YYYY-MM-DD";
export const DATE_TIME_FORMAT = "YYYY-MM-DD HH:mm:ss";

export const SET_AUTHENTICATION_APP_REQUEST_ID =
    "SET_AUTHENTICATION_APP_REQUEST_ID";
export const AUTHENTICATION_APP_REQUEST = "AUTHENTICATION_APP_REQUEST";
export const AUTHENTICATION_APP_SUCCESS = "AUTHENTICATION_APP_SUCCESS";

export const SET_PASSWORD_VALUE = "SET_PASSWORD_VALUE";
export const CHANGE_PASSWORD_REQUEST = "CHANGE_PASSWORD_REQUEST";
export const SET_PERMISSION_VALUE = "SET_PERMISSION_VALUE";

export const SET_FILE_VALUE = "SET_FILE_VALUE";
export const UPLOAD_FILE_REQUEST = "UPLOAD_FILE_REQUEST";
export const UPLOAD_FILE_SUCCESS = "UPLOAD_FILE_SUCCESS";
export const SET_UPLOAD_STATUS = "SET_UPLOAD_STATUS";
export const SET_CAPTCHA_TOKEN = "SET_CAPTCHA_TOKEN";
export const VERIFY_CAPTCHA_TOKEN_REQUEST = "VERIFY_CAPTCHA_TOKEN_REQUEST";
export const VERIFY_CAPTCHA_TOKEN_SUCCESS = "VERIFY_CAPTCHA_TOKEN_SUCCESS";

export const SET_PRINT_PARAMETERS = "SET_PRINT_PARAMETERS";

export const PermissionKeys = {
    CASINO : 30279,
    CASINO_EQUIPMENT : 30249,
    CASINO_EQUIPMENT__BINDING : 30250,
    CASINO_EQUIPMENT__EDIT : 30251,
    CASINO_EQUIPMENT__REPORT : 30252,
    COMPANY : 30285,
    COMPANY__CREATE : 30287,
    COMPANY__DELETE : 30290,
    COMPANY__EDIT : 30288,
    COMPANY__LIST : 30286,
    COMPANY__OPTIONS : 30291,
    COMPANY__VIEW : 30289,
    EQUIPMENT : 43,
    EQUIPMENT__ADD : 20054,
    EQUIPMENT__DETAIL : 20055,
    EQUIPMENT__EDIT : 20056,
    EQUIPMENT__LIST : 20053,
    EQUIPMENT__OPTIONS : 20057,
    EQUIPMENT_BINDING : 30270,
    EQUIPMENT_BINDING__BINDING : 30272,
    EQUIPMENT_BINDING__VIEW : 30271,
    EQUIPMENT_TYPE : 30264,
    EQUIPMENT_TYPE__CREATE : 30266,
    EQUIPMENT_TYPE__DELETE : 30268,
    EQUIPMENT_TYPE__EDIT : 30267,
    EQUIPMENT_TYPE__LIST : 30265,
    EQUIPMENT_TYPE__OPTIONS : 30269,
    FORM_TYPES : 30257,
    FORM_TYPES__CREATE : 30259,
    FORM_TYPES__DELETE : 30263,
    FORM_TYPES__EDIT : 30261,
    FORM_TYPES__LIST : 30258,
    FORM_TYPES__OPTIONS : 30262,
    FORM_TYPES__VIEW : 30260,
    GAME_RULE : 20042,
    GAME_RULE__ADD : 20049,
    GAME_RULE__DETAIL : 20050,
    GAME_RULE__EDIT : 20051,
    GAME_RULE__LIST : 20048,
    GAME_RULE__OPTIONS : 20052,
    GAME_TYPE : 20041,
    GAME_TYPE__ADD : 20043,
    GAME_TYPE__DETAIL : 20045,
    GAME_TYPE__EDIT : 20046,
    GAME_TYPE__LIST : 20044,
    GAME_TYPE__OPTIONS : 20047,
    INSPECTION_CERTIFICATE : 30246,
    INSPECTION_CERTIFICATE__BINDING : 30256,
    INSPECTION_CERTIFICATE__CREATE : 30248,
    INSPECTION_CERTIFICATE__EDIT : 30254,
    INSPECTION_CERTIFICATE__LIST : 30253,
    INSPECTION_CERTIFICATE__PRINT : 30255,
    INSPECTION_CERTIFICATE__VIEW : 30247,
    INSPECTION_CERTIFICATE_STATUS : 30277,
    INSPECTION_CERTIFICATE_STATUS__LIST : 30278,
    INSPECTION_CERTIFICATE_TYPE : 30273,
    INSPECTION_CERTIFICATE_TYPE__CREATE : 30275,
    INSPECTION_CERTIFICATE_TYPE__LIST : 30274,
    INSPECTION_CERTIFICATE_TYPE__OPTIONS : 30276,
    LOTTER : 30292,
    REQUEST_CASINO_LICENSE : 30280,
    REQUEST_CASINO_LICENSE__CREATE : 30282,
    REQUEST_CASINO_LICENSE__DELETE : 30284,
    REQUEST_CASINO_LICENSE__EDIT : 30283,
    REQUEST_CASINO_LICENSE__LIST : 30281,
    REQUEST_GAME_EQUIPMENT : 20060,
    REQUEST_GAME_EQUIPMENT__LIST : 20067,
    REQUEST_GAME_RULE : 20061,
    REQUEST_GAME_RULE__LIST : 20066,
    REQUEST_GAME_TYPE : 20058,
    REQUEST_GAME_TYPE__LIST : 20059,
};