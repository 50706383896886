import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";
import { HelmetHeader } from "Components/Helmet-Header";
import { ArchiveIcon } from "@heroicons/react/outline";
import { Form, Button } from "antd";
import { FullLayout } from "Components/Layouts/";

import { useInjectReducer } from "Utility/InjectReducer";
import { useInjectSaga } from "Utility/InjectSaga";
import Reducer from "Modules/Settings/GameType/Reducer";
import Saga from "Modules/Settings/GameType/Saga";

import {
    UpdateGameTypeRequestAction,
    SetGameTypeIdAction,
    SetFormValuesAction,
    GetGameTypeRequestAction,
    GetGameTypeSuccessAction,
} from "Modules/Settings/GameType/Actions";
import { MakeGameTypeDetailSelector } from "Modules/Settings/GameType/Selectors";
import { GameTypeDetailForm } from "Modules/Settings/GameType/Forms/GameTypeDetailForm";

const key = "gameType";
export const Detail = () => {
    useInjectReducer({ key, reducer: Reducer });
    useInjectSaga({ key, saga: Saga });
    // fields
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();
    const { id } = useParams();
    const gameTypeDetail = useSelector(MakeGameTypeDetailSelector());
    const entityType = searchParams.get("entityType");

    //constructor
    useEffect(() => {
        dispatch(SetGameTypeIdAction(id));
        dispatch(GetGameTypeRequestAction());

        return () => {
            dispatch(GetGameTypeSuccessAction(null));
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    // methods
    const onFormProviderSubmit = (
        name: string,
        { values, forms }: any
    ) => {
        const entityTypeId = entityType === "LOTTERY" ? 2 : 1;
        const model = {
            ...values,
            entityType: entityTypeId,
            entityTypeString: entityType,
        }
        dispatch(SetFormValuesAction(model));
        dispatch(UpdateGameTypeRequestAction());
    };

    const onAddNew = () => {
        form.submit();
    };

    // render
    const ActionButtons = () => (
        <>
            <Button
                type="primary"
                className="inline-flex items-center justify-center px-4 py-2 mx-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500"
                onClick={onAddNew}
            >
                រក្សាទុក
            </Button>
        </>
    );

    return (
        <>
            <FullLayout
                icon={<ArchiveIcon />}
                title={`កែប្រែប្រភេទល្បែង (${gameTypeDetail?.name})`}
                descriptions="ត្រលប់ទៅបញ្ជីល្បែង"
                actions={<ActionButtons />}
                redirectUrl={`/settings/game-type?entityType=${entityType}`}
            >
                <HelmetHeader meta="ប្រភេទល្បែង" />
                <Form.Provider onFormFinish={onFormProviderSubmit}>
                    {gameTypeDetail && (
                        <GameTypeDetailForm
                            form={form}
                            gameType={gameTypeDetail}
                        />
                    )}
                </Form.Provider>
            </FullLayout>
        </>
    );
};
