import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useInjectSaga } from "Utility/InjectSaga";
import { useInjectReducer } from "Utility/InjectReducer";
import Reducer from "Modules/Entity/Company/Reducer";
import Saga from "Modules/Entity/Company/Saga";
import { Form, Button } from "antd";
import { FullLayout } from "Components/Layouts/";
import { HelmetHeader } from "Components/Helmet-Header";
import { CompanyDetailForm } from "Modules/Entity/Company/Forms/CompanyDetailForm";

import {
    SetCompanyValueAction,
    GetEntityCompanyByIdRequestAction,
    EditEntityCompanyRequestAction,
    GetEntityCompanyByIdSuccessAction
} from "Modules/Entity/Company/Actions";
import { MakeSelectCompanyDetailSelector } from "Modules/Entity/Company/Selectors";
import { GetNationalityOptionService } from "Modules/Entity/Operator/Service";
import { SelectableNationalitySuccessAction } from "Modules/License/Casino/Actions";

const key = "entityCompany";
export const CompanyDetail = () => {
    useInjectReducer({ key, reducer: Reducer });
    useInjectSaga({ key, saga: Saga });
    //fields
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const { id } = useParams();
    const company = useSelector(MakeSelectCompanyDetailSelector());

    //constructors
    useEffect(() => {
        GetNationalityOptionService(dispatch).then((nationalityRs: any) => {
            if(nationalityRs){
                dispatch(SelectableNationalitySuccessAction(nationalityRs));
            }
            if (id) {
                dispatch(SetCompanyValueAction(id));
                dispatch(GetEntityCompanyByIdRequestAction());
            }
        })
        
        return () => {
            dispatch(SetCompanyValueAction(null));
            dispatch(GetEntityCompanyByIdSuccessAction(null));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    // method
    const ActionButton = () => {
        return (
            <Button
                onClick={() => form.submit()}
                type="primary"
            >
                រក្សាទុក
            </Button>
        );
    };
    // events
    const onFormProviderSubmit = (name: string, { forms, values }: any) => {
        switch (name) {
            case "entityCompanyListForm": {
                onFilterFormSubmit(values);
                break;
            }
        }
    };

    const onFilterFormSubmit = (values: any) => {
        const valuesData = {
            id: id,
            companyData: {
                boardOfDirectors: [],
            },
            address: values.registrationOffice,
            ...values,
        };

        valuesData.companyData.boardOfDirectors =
            form.getFieldValue("boardOfDirectors");

        dispatch(SetCompanyValueAction(valuesData));
        dispatch(EditEntityCompanyRequestAction());
    };

    return (
        <FullLayout
            icon={null}
            title={`ព័ត៌មានក្រុមហ៊ុន ${company?.company?.name}`}
            descriptions="ត្រលប់ទៅបញ្ជី"
            redirectUrl="/entity/company"
            actions={<ActionButton />}
        >
            <HelmetHeader meta="ព័ត៌មានកម្មសិទ្ធិករ" />
            <Form.Provider onFormFinish={onFormProviderSubmit}>
                {company?.company && (
                    <CompanyDetailForm form={form} company={company} />
                )}
            </Form.Provider>
        </FullLayout>
    );
};
