import { ReportList } from "Modules/Reporting/List";
import { ReportPreview } from "Modules/Reporting/Preview";

import { CasinoInspectionCertificateReport } from "./CasinoInspectionCertificate";
import { OepratorReport } from "./Operator";
import { CasinoEquipmentBindingReport } from "./CasinoEquipmentBinding";
import { InspectionCertificatePrintingReport } from "./InspectionCertificatePrintingReport";
import { CasinoEquipmentRebindingReport } from "./CasinoEquipmentReBinding";

export const ReportRoute = [
  {
    path: "",
    children: [
        { path: "", element: <ReportList /> },
        { path: "preview", element: <ReportPreview /> },
    ],
  },
  {
    path: "casino-inspection-certiciate-report",
    element: <CasinoInspectionCertificateReport />,
  },
  {
    path: "casino-equipment-binding-report",
    element: <CasinoEquipmentBindingReport />,
  },
  {
    path: "casino-equipment-binding-report",
    element: <CasinoEquipmentRebindingReport />,
  },
  {
    path: "certificate-printing-report",
    element: <InspectionCertificatePrintingReport />,
  },
  {
    path: "operator",
    children: [
        {path: "", element: <OepratorReport />}
    ]
  }
]