import React from "react";
import { CasinoList } from "Modules/Entity/Operator/List";
import { CasinoCreate } from "Modules/Entity/Operator/Create";
import { CasinoDetail } from "Modules/Entity/Operator/Detail";

export const EntityOperatorRoute = {
    path: "operator",
    children: [
        { path: "", element: <CasinoList /> },
        { path: "create", element: <CasinoCreate /> },
        { path: ":id", element: <CasinoDetail /> },
    ],
};
