import { useState } from "react";
import { Link } from "react-router-dom";

import { FullLayout } from "Components/Layouts";
import { HelmetHeader } from "Components/Helmet-Header";
import { ArchiveIcon } from "@heroicons/react/outline";

import { LotteryBranchApplicationFormCreate } from "../Forms/LotteryBranchApplicationFormCreate";
import { 
    Modal,
    Form,
    Button, 
    Space ,
} from "antd";
import { LotteryGameTypeForm } from "Modules/Certificate/Lottery/ApplicationForm/GameTypeEquipment/Forms/LotteryGameTypeForm";

export const Create = () => {
    // fields
    const[lotteryGameTypeForm]= Form.useForm();
    const [lotteryGameTypeVisible, setLotteryGameTypeVisible] = useState<boolean>(false);

    // render
    const ActionButtons = () => {
        return(
            <Space>
                <Button type="primary">បញ្ចូលថ្មី</Button>
            </Space>
        )
    }
    return (<FullLayout
            icon={<ArchiveIcon />}
            title="បញ្ជីពាក្យស្នើសុំចុះបញ្ជីអាជ្ញាបណ្ណផ្សងសំណាង"
            descriptions=""
            actions={<ActionButtons />}
        >
            <HelmetHeader meta="បញ្ជីពាក្យស្នើសុំចុះបញ្ជីអាជ្ញាបណ្ណសាខាល្បែងផ្សងសំណាង" />
            <Form.Provider>
                <LotteryBranchApplicationFormCreate
                    // form={form}
                    // onFinish={onFinish}
                />
                <Modal open={lotteryGameTypeVisible} onCancel={() => setLotteryGameTypeVisible(false)} onOk={() => lotteryGameTypeForm.submit()}>
                    <LotteryGameTypeForm form={lotteryGameTypeForm} />
                </Modal>
            </Form.Provider>
            
            
        </FullLayout>
    );
}