import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useInjectReducer } from "Utility/InjectReducer";
import { useInjectSaga } from "Utility/InjectSaga";

import {
    
    Space,
    Button,
} from "antd";

import Reducer from "Modules/Settings/InspectionCertificateMaster/Reducer";
import Saga from "Modules/Settings/InspectionCertificateMaster/Saga";
import { MakePrintParamsSelector } from "Modules/Application/Selectors";
import { SetPrintParametersAction } from "Modules/Application/Actions";
import { FullLayout, MainLayout } from "Components/Layouts";
import { ArchiveIcon } from "@heroicons/react/solid";
import { PrintInspectionCertificatePrintBatchRequestAction } from "../Actions";

const key = "inspectionCertificateMaster";
export const InspectionCertificatePrintSerials = () => {
    useInjectReducer({ key, reducer: Reducer });
    useInjectSaga({ key, saga: Saga });
    //fields
    const dispatch = useDispatch();
    const printParams = useSelector(MakePrintParamsSelector());

    // cosntructor
    useEffect(() => {
        if (printParams) {
            dispatch(PrintInspectionCertificatePrintBatchRequestAction());
        }
        return () => {
            dispatch(SetPrintParametersAction(null));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [printParams]);

    // renders
    const ActionButton = (
        <Space>
            <Button type="primary">Print</Button>
        </Space>
    )

    return (
        <MainLayout>
            <FullLayout
                icon={<ArchiveIcon />}
                title="លតាបត្រ"
                descriptions=""
                actions={ActionButton }>
                <div className="h-full py-4 bg-slate-100">
                    <div className="container h-full mx-auto  shadow-inner bg-white">
                        Print Preview
                    </div>
                    
                </div>
            </FullLayout >
        </MainLayout>
    );
}