import { call, put, all, select, takeLatest } from "redux-saga/effects";
import { POST, GET, PUT } from "Utility/Constant";
import ApiEndpoint from "Utility/Endpoint";
import Request from "Utility/Http";
import { v4 as uuid } from "uuid";

import { ShowAlert, ShowMessage } from "Common/Saga";

import { AsyncStartAction, AsyncEndAction } from "Modules/Application/Actions";

import {
    MakeRequestGameRuleFilterValueSelector,
    MakeRequestGameRuleValueSelector,
    MakeRequestGameRuleIdSelector,
} from "Modules/Certificate/Casino/ApplicationForm/GameRule/Selectors";
import {
    CREATE_REQUEST_GAME_RULE_REQUEST,
    LIST_REQUEST_GAME_RULE_REQUEST,
    GET_REQUEST_GAME_RULE_REQUEST,
    EDIT_REQUEST_GAME_RULE_REQUEST,
} from "Modules/Certificate/Casino/ApplicationForm/GameRule/Constants";
import {
    ListRequestGameRuleSuccessAction,
    GetRequestGameRuleSuccessAction,
} from "Modules/Certificate/Casino/ApplicationForm/GameRule/Actions";

export function* ListReqeustGameRule(): any {
    yield put(AsyncStartAction());
    const formValues = yield select(MakeRequestGameRuleFilterValueSelector());

    const requestUrl = `${ApiEndpoint.REQUEST_LICENSE}/lists`;
    const httpPayload = ApiEndpoint.makeApiPayload(
        requestUrl,
        POST,
        formValues
    );
    try {
        const response = yield call(Request, httpPayload);
        yield put(ListRequestGameRuleSuccessAction(response));
        yield put(AsyncEndAction());
    } catch (error: any) {
        yield put(AsyncEndAction());
        if (error.data && error.data.statusCode === 422) {
        }
        return yield ShowAlert("error", error.data.message);
    }
}

export function* GetRequestGameRule(): any {
    yield put(AsyncStartAction());
    const id = yield select(MakeRequestGameRuleIdSelector());

    const requestUrl = `${ApiEndpoint.REQUEST_LICENSE}/${id}`;
    const httpPayload = ApiEndpoint.makeApiPayload(requestUrl, GET, id);
    try {
        const response = yield call(Request, httpPayload);
        yield put(GetRequestGameRuleSuccessAction(response));
        yield put(AsyncEndAction());
    } catch (error: any) {
        yield put(AsyncEndAction());
        if (error.data && error.data.statusCode === 422) {
        }
        return yield ShowAlert("error", error.data.message);
    }
}

export function* CreateRequestGameRule() {
    yield put(AsyncStartAction());
    const formValues = yield select(MakeRequestGameRuleValueSelector());

    const requestUrl = ApiEndpoint.REQUEST_LICENSE;
    const httpPayload = ApiEndpoint.makeApiPayload(
        requestUrl,
        POST,
        formValues
    );
    try {
        const response = yield call(Request, httpPayload);
        yield put(AsyncEndAction());
        if (response)
            yield ShowMessage({
                type: "success",
                message: "ទិន្នយបានរក្សាទុក",
                content: "ទិន្នយបានរក្សាទុក",
                id: uuid(),
                redirectUrl: "/certificate/casino/request-game-rule/request",
            });
    } catch (error: any) {
        yield put(AsyncEndAction());
        if (error.data && error.data.statusCode === 422) {
        }
        return yield ShowAlert("error", error.data.message);
    }
}

export function* EditRequestGameRule() {
    yield put(AsyncStartAction());
    const formValues = yield select(MakeRequestGameRuleValueSelector());

    const requestUrl = ApiEndpoint.REQUEST_LICENSE;
    const httpPayload = ApiEndpoint.makeApiPayload(requestUrl, PUT, formValues);
    try {
        const response = yield call(Request, httpPayload);
        yield put(AsyncEndAction());
        if (response)
            yield ShowMessage({
                type: "success",
                message: "ទិន្នយបានរក្សាទុក",
                content: "ទិន្នយបានរក្សាទុក",
                id: uuid(),
                redirectUrl: "/certificate/casino/request-game-rule/request",
            });
    } catch (error: any) {
        yield put(AsyncEndAction());
        if (error.data && error.data.statusCode === 422) {
        }
        return yield ShowAlert("error", error.data.message);
    }
}

export default function* certificateGameRuleSaga() {
    yield all([
        yield takeLatest(
            CREATE_REQUEST_GAME_RULE_REQUEST,
            CreateRequestGameRule
        ),
        yield takeLatest(LIST_REQUEST_GAME_RULE_REQUEST, ListReqeustGameRule),
        yield takeLatest(GET_REQUEST_GAME_RULE_REQUEST, GetRequestGameRule),
        yield takeLatest(EDIT_REQUEST_GAME_RULE_REQUEST, EditRequestGameRule),
    ]);
}
