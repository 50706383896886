/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuid } from "uuid";
import {
    Form,
    Row,
    Col,
    Input,
    Radio,
    DatePicker,
    Space,
    Checkbox,
    Button,
    Table,
    Tabs,
    Popconfirm,
    Typography,
} from "antd";
import { useInjectReducer } from "Utility/InjectReducer";
import { useInjectSaga } from "Utility/InjectSaga";
import CasinoLicenseReducer from "Modules/License/Casino/Reducer";
import CasinoSaga from "Modules/License/Casino/Saga";

import { SelectableNationalityAction, SelectableNationalitySuccessAction } from "Modules/License/Casino/Actions";
import MakeSelectCasinoLicensePage from "Modules/License/Casino/Selectors";
import { NumberToKhCharacter, NumberToCharacter } from "Utility/StringHelper";
import { ColumnTypes } from "Common/Constants/ColumnTypes";
import { EditableCell } from "Components/Toolbox/Editable/EditableCell";
import { BoardOfDirectorType } from "Common/Constants/BoardOfDirector";
import { GetNationalityOptionService } from "Modules/Entity/Operator/Service";

const key = "licenseCasino";
export const CompanyDetailForm = ({
    form,
    setCapitalShareVisibility,
    company,
}: any) => {
    // constructor
    useInjectReducer({ key: key, reducer: CasinoLicenseReducer });
    useInjectSaga({ key: key, saga: CasinoSaga });

    const dispatch = useDispatch();
    const { nationality } = useSelector(MakeSelectCasinoLicensePage());
    const [shareTypeVisibility, setShareTypeVisibility] = useState(false);
    const [boardOfDirectors, setBoardOfDirectors] = useState([]);
    const [editingKey, setEditingKey] = useState("");
    const [count, setCount] = useState(1);

    const columns = [
        {
            title: " ល.រ / No.",
            key: "key",
            render: (value, item, index) => index + 1,
            editable: false,
        },
        {
            title: "គោត្តនាម និងនាម / Last name and First name",
            dataIndex: "name",
            key: "name",
            editable: true,
            name: "boardOfDirectorName",
            type: "input",
        },
        {
            title: "តួនាទី / Position",
            dataIndex: "position",
            key: "position",
            editable: true,
            name: "boardOfDirectorPosition",
            type: "input",
        },
        {
            title: "សញ្ជាតិ / Nationality",
            dataIndex: "nationality",
            key: "nationality",
            editable: true,
            name: "boardOfDirectorNationality",
            type: "input",
        },
        {
            title: "អាសយដ្ឋាន / Address",
            dataIndex: "address",
            key: "address",
            editable: true,
            name: "boardOfDirectorAddress",
            type: "input",
        },
        {
            title: "",
            dataIndex: "operation",
            render: (_: any, record: BoardOfDirectorType) => {
                const editable = isEditing(record);
                return editable ? (
                    <span className="text-center">
                        <Typography.Link
                            onClick={() => onSave(record)}
                            style={{ marginRight: 8 }}
                        >
                            រក្សាទុក
                        </Typography.Link>
                        <Popconfirm
                            title="តើអ្នកចង់បោះបង់ការកែប្រែ?"
                            onConfirm={onCancel}
                        >
                            <a className="text-red-500">បោះបង់</a>
                        </Popconfirm>
                    </span>
                ) : (
                    <Space>
                        <Typography.Link
                            disabled={editingKey !== ""}
                            onClick={() => onEdit(record)}
                        >
                            កែប្រែ
                        </Typography.Link><Popconfirm
                            title="តើអ្នកចង់លុប?"
                            onConfirm={() => onDelete(record)}
                        >
                            <Typography.Link
                            >
                                លុប
                            </Typography.Link>
                        </Popconfirm>
                    </Space>
                );
            },
        },
    ];

    const mergedColumns = columns.map((col) => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record: BoardOfDirectorType) => ({
                record,
                // inputType: col.dataIndex === "age" ? "number" : "text",
                inputType: col.type,
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
                name: col.name,
            }),
        };
    });

    // constructor
    useEffect(() => {
        if(!nationality) {
            GetNationalityOptionService(dispatch).then((nationalityOption: any) => {
                if(nationalityOption) {
                    dispatch(SelectableNationalitySuccessAction(nationalityOption));
                }
            });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // events
    const onShareTypeChange = (e: any) => {
        const shareType = e.target.value;
        // eslint-disable-next-line default-case
        switch (shareType) {
            case 1:
                setShareTypeVisibility(false);
                form.setFieldsValue({ singleShareTypeValue: "" });
                break;
            case 2:
                setShareTypeVisibility(true);
                form.setFieldsValue({ capitalShares: [] });
                break;
        }
    };

    useEffect(() => {
        setBoardOfDirectors(company?.company?.companyData?.boardOfDirectors || []);
        if (company) {
            form.setFieldsValue({ ...company?.company, nationalId: company?.company.nationalId?.toString() });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [company]);

    // methods

    const renderData = () => {
        const nationId = company.company.nationalId.toString();
        const model = {
            ...company.company,
            
            nationalId: nationId,
        };
        model.companyData.dateOfChangingName=null;
        return model;
    };

    const isEditing = (record: BoardOfDirectorType) =>
        record.key === editingKey;
    const onAddBoardOfDirector = () => {
        const id = uuid();
        const newData: BoardOfDirectorType = {
            key: id,
            name: "",
            position: "",
            nationality: "",
            address: "",
        };
        form.setFieldsValue({
            boardOfDirectorName: "",
            boardOfDirectorPosition: "",
            boardOfDirectorNationality: "",
            boardOfDirectorAddress: "",
        });
        setBoardOfDirectors([...boardOfDirectors, newData]);
        setEditingKey(id);
        setCount(count + 1);
    };
    const onSave = (row: BoardOfDirectorType) => {
        const newData = [...boardOfDirectors];
        const values = form.getFieldsValue([
            "boardOfDirectorName",
            "boardOfDirectorPosition",
            "boardOfDirectorNationality",
            "boardOfDirectorAddress",
        ]);

        const index = newData.findIndex((item) => row.key === item.key);
        const item = newData[index];
        newData[index] = {
            key: item.key,
            ...{
                name: values.boardOfDirectorName,
                position: values.boardOfDirectorPosition,
                address: values.boardOfDirectorAddress,
                nationality: values.boardOfDirectorNationality,
            },
        };
        setBoardOfDirectors(newData);
        setEditingKey("");
        form.setFieldsValue({
            boardOfDirectors: newData,
        });
    };

    const onEdit = (
        record: Partial<BoardOfDirectorType> & { key: React.Key }
    ) => {
        form.setFieldsValue({
            licenseNumber: "",
            startDate: "",
            endDate: "",
            ...record,
        });
        setEditingKey(record.key);
    };

    const onDelete = (record: BoardOfDirectorType) => {

        const board = boardOfDirectors.find(o => o.key === record.key);

        if (board) {
            setBoardOfDirectors(boardOfDirectors.filter(item => item.key !== record.key));
        }
    }

    const onCancel = () => {
        setEditingKey("");
    };

    return (
        <Form
            name="entityCompanyListForm"
            layout="vertical"
            className="p-4"
            form={form}
            initialValues={renderData()}
        >
            <Tabs
                defaultActiveKey="1"
                items={[
                    {
                        label: 'ព័ត៌មានក្រុមហ៊ុន',
                        key: '1',
                        children: <>
                            <Row>
                                <Col span={6}>
                                    នាមករណ៍ក្រុមហ៊ុនជាអក្សរខ្មែរ <br />
                                    Company name in Khmer
                                </Col>
                                <Col span={18}>
                                    <Form.Item name="name" rules={[{ required: true }]}>
                                        <Input placeholder="នាមករណ៍ក្រុមហ៊ុនជាអក្សរខ្មែរ / Company name in Khmer" />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={6}>
                                    នាមករណ៍ក្រុមហ៊ុនជាអក្សរឡាតាំង <br />
                                    Company name in Latin
                                </Col>
                                <Col span={18}>
                                    <Form.Item
                                        name="latinName"
                                        rules={[{ required: true }]}
                                    >
                                        <Input placeholder="នាមករណ៍ក្រុមហ៊ុនជាអក្សរឡាតាំង / Company name in Latin" />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row>
                                <Col span={6}>
                                    នាមករណ៍បុគ្គលទទួលបាន ស.ជ.ណ <br />
                                    name of Person received government
                                    approval-in-Principle
                                </Col>
                                <Col span={18}>
                                    <Row>
                                        <Col span={8}>
                                            អក្សរខ្មែរ <br />
                                            in Khmer
                                        </Col>
                                        <Col span={16}>
                                            <Form.Item
                                                name={["companyData", "gAPFirstname"]}
                                            >
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={8}>
                                            អក្សរឡាតាំង <br />
                                            in Latin
                                        </Col>
                                        <Col span={16}>
                                            <Form.Item
                                                name={["companyData", "gAPLastname"]}
                                            >
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={6}>
                                    នាមករណ៍ក្រុមហ៊ុនពីមុន(ប្រសិនបើមាន) <br />
                                    Former name(If Any)
                                </Col>
                                <Col span={16}>
                                    <Row gutter={16}>
                                        <Col span={8}>
                                            <Form.Item
                                                name={["companyData", "formerName"]}
                                            >
                                                <Input placeholder="នាមករណ៍ក្រុមហ៊ុនពីមុន(ប្រសិនបើមាន) / Former name(If Any)" />
                                            </Form.Item>
                                        </Col>
                                        <Col span={8}>
                                            កាលបរិច្ឆេទនៃការផ្លាស់ប្តូរនាមករណ៍
                                            <br />
                                            Date of Changing the name
                                        </Col>
                                        <Col span={8}>
                                            <Form.Item
                                                name={[
                                                    "companyData",
                                                    "dateOfChangingName",
                                                ]}
                                            >
                                                <DatePicker placeholder="កាលបរិច្ឆេទនៃការផ្លាស់ប្តូរនាមករណ៍ / Date of Changing the name" />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col span={6}>
                                    លេខចុះបញ្ជីពាណិជ្ជកម្ម <br />
                                    Registration Number
                                </Col>
                                <Col span={18}>
                                    <Form.Item name="registrationNumber">
                                        <Input placeholder="លេខចុះបញ្ជីពាណិជ្ជកម្ម / Registration Number" />
                                    </Form.Item>
                                </Col>

                                <Col span={6}>
                                    លេខអត្តសញ្ញាណកម្មសារពើពន្ធ <br /> Tax Identificaiton
                                    Number (TIN)
                                </Col>
                                <Col span={18}>
                                    <Form.Item
                                        name="taxIdentificationNumber"
                                        rules={[{ required: true }]}
                                    >
                                        <Input placeholder="លេខអត្តសញ្ញាណកម្មសារពើពន្ធ / Tax Identificaiton Number (TIN)" />
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    សញ្ជាតិក្រុមហ៊ុន <br /> Company Nationality
                                </Col>
                                <Col span={18}>
                                    <Form.Item
                                        name="nationalId"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Please select",
                                            },
                                        ]}
                                    >
                                        <Radio.Group>
                                            <Space direction="horizontal">
                                                {nationality &&
                                                    nationality.map((nation: any) => (
                                                        <Radio
                                                            key={nation.value}
                                                            value={nation.value}
                                                        >
                                                            {nation.label}
                                                        </Radio>
                                                    ))}
                                            </Space>
                                        </Radio.Group>
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    ទីស្នាក់ការចុះបញ្ជីក្រុមហ៊ុន
                                    <br />
                                    Company Registration Office
                                </Col>
                                <Col span={18}>
                                    <Form.Item
                                        name="registrationOffice"
                                        rules={[{ required: true }]}
                                    >
                                        <Input placeholder="ទីស្នាក់ការចុះបញ្ជីក្រុមហ៊ុន / Company Registration Office" />
                                    </Form.Item>
                                </Col>
                            </Row>
                            {/* <Row>
                                <Col span={6}>
                                    អាសយដ្ឋានធ្វើអាជីវកម្ម
                                    <br />
                                    Address of Business
                                </Col>
                                <Col span={18}>
                                    <Form.Item
                                        name="address"
                                        rules={[{ required: true }]}
                                    >
                                        <Input placeholder="អាសយដ្ឋានធ្វើអាជីវកម្ម / Address of Business" />
                                    </Form.Item>
                                </Col>
                            </Row> */}
                            {/* <Row>
                                <Col span={6}>
                                    ទីតាំងនេះជាកម្មសិទ្ធិ ឬជួល?
                                    <br />
                                    Is this above Location an Owned or Rented Property?
                                </Col>
                                <Col span={18}>
                                    <Form.Item
                                        name="propertyStatus"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Please select",
                                            },
                                        ]}
                                    >
                                        <Radio.Group>
                                            <Space direction="horizontal">
                                                <Radio value={1}>
                                                    កម្មសិទ្ធិ / Owned
                                                </Radio>
                                                <Radio value={2}>ជួល / Rented</Radio>
                                            </Space>
                                        </Radio.Group>
                                    </Form.Item>
                                </Col>
                            </Row> */}
                            <Row>
                                <Col span={6}>
                                    ទូរស័ព្ទច​ល័ត
                                    <br />
                                    Mobile Phone Number
                                </Col>
                                <Col span={18}>
                                    <Row gutter={16}>
                                        <Col span={8}>
                                            <Form.Item
                                                name="mobilePhoneNumber"
                                                rules={[{ required: true }]}
                                            >
                                                <Input placeholder="ទូរស័ព្ទច​ល័ត / Mobile Phone Number" />
                                            </Form.Item>
                                        </Col>
                                        <Col span={8}>
                                            ទូរស័ព្ទលើតុ
                                            <br />
                                            Office Phone Number
                                        </Col>
                                        <Col span={8}>
                                            <Form.Item
                                                name="telephoneNumber"

                                            >
                                                <Input placeholder="ទូរស័ព្ទលើតុ / Office Phone Number" />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={6}>
                                    សារអេឡិចត្រូនិច
                                    <br />
                                    Email Address
                                </Col>
                                <Col span={18}>
                                    <Form.Item
                                        name="emailAddress"
                                        rules={[{ required: true }]}
                                    >
                                        <Input placeholder="សារអេឡិចត្រូនិច / Email Address" />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={6}>
                                    គេហទំព័រ
                                    <br />
                                    Website
                                </Col>
                                <Col span={18}>
                                    <Form.Item
                                        name="websiteAddress"
                                        rules={[{ required: true }]}
                                    >
                                        <Input placeholder="គេហទំព័រ / Website" />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={6}>
                                    ប្រភេទភាគហ៊ុន
                                    <br />
                                    Type of share
                                </Col>
                                <Col span={18}>
                                    <Row>
                                        <Col span={24}>
                                            <Row>
                                                <Col span={24}>
                                                    <Form.Item
                                                        name={[
                                                            "companyData",
                                                            "singleShareType",
                                                        ]}
                                                    >
                                                        <Radio.Group
                                                            onChange={onShareTypeChange}
                                                        >
                                                            <Radio value={1}>
                                                                តែមួយប្រភេទ/Only one
                                                                type
                                                            </Radio>
                                                        </Radio.Group>
                                                    </Form.Item>
                                                </Col>
                                                {!shareTypeVisibility && (
                                                    <Col span={24}>
                                                        <Row>
                                                            <Col span={8}>
                                                                ចំនួនភាគហ៊ុន/Number of
                                                                share
                                                            </Col>
                                                            <Col span={16}>
                                                                <Form.Item
                                                                    name={[
                                                                        "companyData",
                                                                        "singleShareTypeValue",
                                                                    ]}
                                                                >
                                                                    <Input />
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                )}
                                            </Row>
                                        </Col>
                                        <Col span={24}>
                                            <Row>
                                                <Col span={24}>
                                                    <Form.Item
                                                        name={[
                                                            "companyData",
                                                            "singleShareType",
                                                        ]}
                                                    >
                                                        <Radio.Group
                                                            onChange={onShareTypeChange}
                                                        >
                                                            <Radio value={2}>
                                                                ច្រើនជាងមួយ/More than
                                                                one type
                                                            </Radio>
                                                        </Radio.Group>
                                                    </Form.Item>
                                                    {shareTypeVisibility && (
                                                        <Button
                                                            type="link"
                                                            onClick={() =>
                                                                setCapitalShareVisibility(
                                                                    true
                                                                )
                                                            }
                                                        >
                                                            បន្ថែមថ្មី
                                                        </Button>
                                                    )}
                                                </Col>
                                                <Col span={12}>
                                                    <Form.Item
                                                        shouldUpdate={(
                                                            preValues,
                                                            curValues
                                                        ) => preValues !== curValues}
                                                    >
                                                        {({ getFieldValue }: any) => {
                                                            const capitalShares =
                                                                getFieldValue(
                                                                    "capitalShares"
                                                                ) || [];

                                                            return (
                                                                <Row gutter={16}>
                                                                    {capitalShares.length >
                                                                        0 &&
                                                                        capitalShares.map(
                                                                            (
                                                                                capitalShare: any,
                                                                                index: number
                                                                            ) => (
                                                                                <Col
                                                                                    className="py-4"
                                                                                    key={
                                                                                        index
                                                                                    }
                                                                                    span={
                                                                                        12
                                                                                    }
                                                                                >{`${NumberToKhCharacter(
                                                                                    index +
                                                                                    1
                                                                                )} ចំនួន/ ${NumberToCharacter(
                                                                                    index +
                                                                                    1
                                                                                )} Number: ${capitalShare.value
                                                                                    }`}</Col>
                                                                            )
                                                                        )}
                                                                </Row>
                                                            );
                                                        }}
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col span={6}>
                                    ដើមទុនចុះបញ្ជី (ជាប្រាក់រៀល)
                                    <br />
                                    Registerd Capital (KHR)
                                </Col>
                                <Col span={18}>
                                    <Row>
                                        <Col span={6}>ជាលេខ / In Number</Col>
                                        <Col span={18}>
                                            <Form.Item
                                                name={["companyData", "capital"]}
                                            >
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                        <Col span={6}>ជាអក្សរ / In word</Col>
                                        <Col span={18}>
                                            <Form.Item
                                                name={["companyData", "capitalInWord"]}
                                            >
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col span={6}>
                                    តម្លៃចារឹកលើប័ណ្ណ (ជាប្រាក់រៀល)
                                    <br />
                                    Par Value (KHR/Share)
                                </Col>
                                <Col span={18}>
                                    <Form.Item name={["companyData", "parValue"]}>
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    សញ្ជាតិរបស់បណ្តាម្ចាស់ហ៊ុនដែលមានសិទ្ធិបោះឆ្នោតសរុបច្រើនជាងគេ
                                    <br />
                                    nationality of Total Majority Shareholder in voting
                                    right
                                </Col>
                                <Col span={18}>
                                    <Row>
                                        <Col span={14}>
                                            <Form.Item
                                                name={["companyData", "mostCapital"]}
                                            >
                                                <Radio.Group>
                                                    <Space direction="horizontal">
                                                        <Radio value="khmer">
                                                            ខ្មែរ / Khmer
                                                        </Radio>
                                                        <Radio value="foreign">
                                                            បរទេស / Foreign
                                                        </Radio>
                                                    </Space>
                                                </Radio.Group>
                                            </Form.Item>
                                        </Col>
                                        <Col span={10}>
                                            <Form.Item
                                                name={[
                                                    "companyData",
                                                    "mostCapitalValue",
                                                ]}
                                                label="ចំនួនភាគរយនៃហ៊ុនសរុប"
                                            >
                                                <Input addonAfter="%" />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>
                                {/* Components of business capital */}
                                <Col span={6}>
                                    សមាសធាតិដើមទុនធ្វើអាជីវកម្ម(ជាប្រាក់រៀល)
                                    <br />
                                    nationality of Total Majority Shareholder in voting
                                    right
                                </Col>
                                <Col span={18}>
                                    <Row>
                                        <Col span={14}>
                                            <Form.Item
                                                name={["companyData", "isCurrentAsset"]}
                                                valuePropName="checked"
                                            >
                                                <Checkbox>
                                                    ទ្រព្យសម្បត្តិចរន្ត / Current Assets
                                                </Checkbox>
                                            </Form.Item>
                                        </Col>
                                        <Col span={10}>
                                            <Form.Item
                                                name={[
                                                    "companyData",
                                                    "currentAssetValue",
                                                ]}
                                            >
                                                <Input
                                                    addonBefore="ចំនួន / Amount"
                                                    addonAfter="រៀល"
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={14}>
                                            <Form.Item
                                                name={["companyData", "isShareHolder"]}
                                                valuePropName="checked"
                                            >
                                                <Checkbox>
                                                    មូលនិធិភាគហ៊ុន / Shareholder Funds
                                                </Checkbox>
                                            </Form.Item>
                                        </Col>
                                        <Col span={10}>
                                            <Form.Item
                                                name={[
                                                    "companyData",
                                                    "shareHolderValue",
                                                ]}
                                            >
                                                <Input
                                                    addonBefore="ចំនួន / Amount"
                                                    addonAfter="រៀល"
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={14}>
                                            <Form.Item
                                                name={["companyData", "isRealEstate"]}
                                                valuePropName="checked"
                                            >
                                                <Checkbox>
                                                    អចលនទ្រព្យ / Real Estate
                                                </Checkbox>
                                            </Form.Item>
                                        </Col>
                                        <Col span={10}>
                                            <Form.Item
                                                name={[
                                                    "companyData",
                                                    "realEstateValue",
                                                ]}
                                            >
                                                <Input
                                                    addonBefore="ចំនួន / Amount"
                                                    addonAfter="រៀល"
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24} className="text-xl pb-2 pt-2">
                                    ក្រុមប្រឹក្សាភិបាល
                                </Col>
                                <Col span={24}>
                                    <Button
                                        className="bg-blue-500"
                                        onClick={onAddBoardOfDirector}
                                        type="primary"
                                        style={{ marginBottom: 16 }}
                                    >
                                        បន្ថែមថ្មី
                                    </Button>
                                    <Table
                                        className="w-full border"
                                        dataSource={boardOfDirectors}
                                        rowKey="key"
                                        size="small"
                                        components={{
                                            body: {
                                                // row: EditableRow,
                                                cell: EditableCell,
                                            },
                                        }}
                                        columns={mergedColumns as ColumnTypes}
                                    />

                                </Col>
                            </Row>
                        </>,
                    },
                    {
                        label: 'ព័ត៌មានប្រតិបត្តិករស្ថិតនៅក្រោមក្រុមហ៊ុន',
                        key: '2',
                        children: <Table rowKey="id" dataSource={company?.casinos || []} size="small">
                            <Table.Column title="កូដ" key="code" dataIndex="code" />
                            <Table.Column
                                title="លេខអាជ្ញាបណ្ណ"
                                key="casinoLicenseNumber"
                                dataIndex="casinoLicenseNumber"
                            />
                            <Table.Column
                                title="នាមករណ៍ប្រតិបត្តិករជាអក្សរខ្មែរ"
                                key="name"
                                dataIndex="name"
                            />
                            <Table.Column
                                title="នាមករណ៍ប្រតិបត្តិករជាអក្សរបរទេស"
                                key="latinName"
                                dataIndex="latinName"
                            />
                        </Table>
                        ,
                    },
                ]} 
            />
        </Form >
    );
};
