import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";

import { DeleteOutlined } from "@ant-design/icons";
import {
    Form,
    Row,
    Col,
    Table,
    Input,
    DatePicker,
    Radio,
    Button,
    Space,
    Checkbox,
    Tabs,
    Select,
} from "antd";
import type { ColumnsType } from "antd/lib/table";
import { CertificateCasinoGameEquipmentModal } from "Modules/Certificate/Casino/ApplicationForm/GameEquipment/RequestGameEquipment/Forms/CertificateCasinoGameEquipmentModal";
import { GameEquipmentListType } from "Modules/Certificate/Casino/ApplicationForm/GameEquipment/RequestGameEquipment/Constants";

import { useInjectSaga } from "Utility/InjectSaga";
import { useInjectReducer } from "Utility/InjectReducer";

import CasinoReducer from "Modules/Entity/Operator/Reducer";
import CasinoSaga from "Modules/Entity/Operator/Saga";
import LicenseReducer from "Modules/License/Casino/Reducer";
import LicenseSaga from "Modules/License/Casino/Saga";

import {
    GetCasinoModalDetailAction,
    SetCasinoIdAction,
    GetEntityCasinoByIdSuccessAction,
} from "Modules/Entity/Operator/Actions";
import MakeSelectCasinoLicensePage from "Modules/License/Casino/Selectors";
import {
    MakeSelectCasinoSelector,
} from "Modules/Entity/Operator/Selectors";
import { MakeCasinoEquipmentMasterSelector } from "Modules/Equipments/Casino/Selectors";
import { GetOperatorByIdService } from "Modules/Entity/Operator/Service";

interface RequestFormType {
    form: any;
    onDeleteEquipment: any;
    onEquipmentCreate: any;
}
const key = "entityCasino";
const licenseCasinoKey = "licenseCasino";
export const RequestGameEquipmentCreateForm = ({ form, onDeleteEquipment, onEquipmentCreate }: RequestFormType) => {
    useInjectReducer({ key, reducer: CasinoReducer });
    useInjectSaga({ key, saga: CasinoSaga });

    useInjectReducer({ key: licenseCasinoKey, reducer: LicenseReducer });
    useInjectSaga({ key: licenseCasinoKey, saga: LicenseSaga });
    //fields
    const dispatch = useDispatch();
    const casinoEquipmentMaster = useSelector(MakeCasinoEquipmentMasterSelector());
    const { identificationTypes } = useSelector(MakeSelectCasinoLicensePage());
    const [gameRuleVisibility, setGameRuleVisibility] = useState(false);
    const casinoOptions = useSelector(MakeSelectCasinoSelector());
    const { id } = useParams();

    const boardOfDirectorColumns: ColumnsType<GameEquipmentListType> = [
        {
            title: "វិធានល្បែង / Game Rule",
            key: "gameRule",
            width: 100,
            render: (item) => <>{item.gameRuleId.label}</>,
        },
        {
            title: "ចំនួនតុល្បែង / Table Qty.",
            dataIndex: "tableQuantity",
            key: "tableQuantity",
            width: 100,
        },
        {
            title: "ចំនួនម៉ាស៊ីនល្បែង / Machine Qty.",
            children: [
                {
                    title: "ស្លុត / Slot",
                    dataIndex: "slotQuantity",
                    key: "slotQuantity",
                    width: 100,
                },
                {
                    title: "២ ទៅ ៨នាក់ / 2-8 Players",
                    children: [
                        {
                            title: "Machine",
                            dataIndex: "machineQuantitySmallTable",
                            key: "street",
                            width: 150,
                        },
                        {
                            title: "Player per Machine",
                            dataIndex: "playerQuantitySmallTable",
                            key: "street",
                            width: 150,
                        },
                    ],
                },
                {
                    title: "លើសពី ៨នាក់ / Over 8 Players",
                    children: [
                        {
                            title: "Machine",
                            dataIndex: "machineQuantityBigTable",
                            key: "street",
                            width: 150,
                        },
                        {
                            title: "Player per Machine",
                            dataIndex: "playerQuantityBigTable",
                            key: "street",
                            width: 150,
                        },
                    ],
                },
            ],
        },

        {
            title: "ផ្សេងៗ / Other",
            dataIndex: "other",
            key: "other",
            width: 80,

        },
        {
            key: "action",
            fixed: "right",
            width: 25,
            render: (item: any) => <div>
                <Button type="link" className="p-1" onClick={() => onDeleteEquipment(item)}>
                    <DeleteOutlined />
                </Button>
                {/* <Button type="link" className="p-1" onClick={() => onEquipmentCreate(item)}>
                    <EditOutlined />
                </Button> */}
            </div>
        }
    ];

    useEffect(() => {
        return () => {
            dispatch(GetEntityCasinoByIdSuccessAction(null));
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // methods
    const onCasinoChange = (e: any) => {
        const id = e;
        form.setFieldValue("casinoId", id);
        dispatch(SetCasinoIdAction(id));
        dispatch(GetCasinoModalDetailAction(null));
        GetOperatorByIdService(id, dispatch).then((casinoRs: any) => {
            if (casinoRs) {
                let formData = {
                    company: { name: casinoRs.company.name },
                    casino: {
                        name: casinoRs.casino.name,
                        address: casinoRs.casino.address,
                    },
                };

                form.setFieldsValue({
                    formData: { ...formData },
                });
            }
        });
    };



    return (
        <>
            <Form
                name="requestCasinoGameEquipmentLicenseForm"
                form={form}
                initialValues={{
                    applicant: { id: "" },
                    representative: { id: "" },
                    gameEquipments: [],
                }}
                className="p-5"
            >
                <Form.Item name="casinoId" hidden={true}>
                    <Input />
                </Form.Item>
                <Form.Item name="code" hidden={true}>
                    <Input />
                </Form.Item>
                <Tabs
                    defaultActiveKey="1"
                    items={[
                        {
                            label: "សម្រាប់មន្ត្រី គ.ល.ក..",
                            key: "1",
                            forceRender: true,
                            children: (
                                <>
                                <Form.Item name="casinoId" hidden={true}>
                                  <Input />
                                </Form.Item>
                                    <Form.Item name="code" hidden={true}>
                                        <Input />
                                    </Form.Item>
                                    <Form.Item name={["formData", "casino", "name"]} hidden={true}>
                                        <Input />
                                    </Form.Item>
                                    <Row gutter={16}>
                                        <Col span={5}>
                                            លេខសំគាល់ពាក្យស្នើសុំ
                                        </Col>
                                        <Col span={7}>
                                            <Form.Item name="documentCode">
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                        <Col span={5}>ឈ្មោះអ្នកទទួល</Col>
                                        <Col span={7}>
                                            <Form.Item name="receiverName">
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                        <Col span={5}>
                                            លេខកូដចំណូលកាតព្វកិច្ចពីល្បែង
                                        </Col>
                                        <Col span={7}>
                                            <Form.Item name="gamblingTaxCode">
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                        <Col span={5}>កាលបរិច្ឆេទទទួល</Col>
                                        <Col span={7}>
                                            <Form.Item name="receivedDate">
                                                <DatePicker placeholder="កាលបរិច្ឆេទទទួល" />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </>
                            ),
                        },
                        {
                            label: "ព័ត៌មានអំពីក្រុមហ៊ុន",
                            key: "2",
                            forceRender: true,
                            children: (
                                <Row>
                                    <Col span={6}>
                                        ឈ្មោះក្រុមហ៊ុន / Company Name
                                    </Col>
                                    <Col span={18}>
                                        <Form.Item
                                            name={[
                                                "formData",
                                                "company",
                                                "name",
                                            ]}
                                            rules={[{ required: true }]}
                                        >
                                            <Input
                                                placeholder="ឈ្មោះក្រុមហ៊ុន / Company Name"
                                                status="warning"
                                                readOnly
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        ឈ្មោះកាស៊ីណូ / Casino Name
                                    </Col>
                                    <Col span={18}>
                                        <Form.Item
                                            name={[
                                                "formData",
                                                "casino",
                                                "name",
                                            ]}
                                            rules={[{ required: true }]}
                                        >
                                            {/* <Input
                                                    placeholder="ឈ្មោះកាស៊ីណូ / Casino Name"
                                                    status="warning"
                                                    readOnly
                                                /> */}
                                            <Select
                                                placeholder="ជ្រើសរើសកាស៊ីណូ / Choose Casino"
                                                onChange={onCasinoChange}
                                                filterOption={(input, option) =>
                                                    option!.children
                                                        .join(" ")
                                                        .toLowerCase()
                                                        .includes(
                                                            input.toLowerCase()
                                                        )
                                                }
                                                showSearch
                                            >
                                                {casinoOptions &&
                                                    casinoOptions.map(
                                                        (item) => (
                                                            <Select.Option
                                                                key={item.value}
                                                                value={
                                                                    item.value
                                                                }
                                                            >
                                                                {item.label}(
                                                                {
                                                                    item.placeholder
                                                                }
                                                                )
                                                            </Select.Option>
                                                        )
                                                    )}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        អាសយដ្ឋនាកាស៊ីណូ / Casino Address
                                    </Col>
                                    <Col span={18}>
                                        <Form.Item
                                            name={[
                                                "formData",
                                                "casino",
                                                "address",
                                            ]}
                                        >
                                            <Input
                                                placeholder="អាសយដ្ឋនាកាស៊ីណូ / Casino Address"
                                                status="warning"
                                                readOnly
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            ),
                        },
                        {
                            label: "ព័ត៌មានអ្នកដាក់ពាក្យស្នើសុំ",
                            key: "3",
                            forceRender: true,
                            children: (
                                <>
                                    <Row>
                                        <Col span={6}>
                                            តួនាទីអ្នកដាកពាក្យស្នើសុំ
                                            <br />
                                            Position of Applicant
                                        </Col>
                                        <Col span={18}>
                                            <Form.Item
                                                name={[
                                                    "formData",
                                                    "designatureId",
                                                ]}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message:
                                                            "Please select one",
                                                    },
                                                ]}
                                            >
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col span={6}>
                                            គោត្តនាម និងនាម
                                            <br />
                                            Last name and First name
                                        </Col>
                                        <Col span={18}>
                                            <Row>
                                                <Col span={6}>
                                                    អក្សរខ្មែរ
                                                    <br />
                                                    in Khmer
                                                </Col>
                                                <Col span={18}>
                                                    <Form.Item
                                                        name={[
                                                            "formData",
                                                            "applicant",
                                                            "fullname",
                                                        ]}
                                                        rules={[
                                                            { required: true },
                                                        ]}
                                                    >
                                                        <Input />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={6}>
                                                    អក្សរឡាតាំង
                                                    <br />
                                                    in Latin
                                                </Col>
                                                <Col span={18}>
                                                    <Form.Item
                                                        name={[
                                                            "formData",
                                                            "applicant",
                                                            "latinFullname",
                                                        ]}
                                                        rules={[
                                                            { required: true },
                                                        ]}
                                                    >
                                                        <Input />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={6}>
                                            ភេទ
                                            <br />
                                            Sex
                                        </Col>
                                        <Col span={18}>
                                            <Form.Item
                                                name={[
                                                    "formData",
                                                    "applicant",
                                                    "gender",
                                                ]}
                                                rules={[{ required: true }]}
                                            >
                                                <Radio.Group>
                                                    <Space direction="horizontal">
                                                        <Radio value={1}>
                                                            ប្រុស / Male
                                                        </Radio>
                                                        <Radio value={2}>
                                                            ស្រី / Female
                                                        </Radio>
                                                    </Space>
                                                </Radio.Group>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={6}>
                                            ថ្ងៃខែឆ្នាំកំណើត
                                            <br />
                                            Date of Birth
                                        </Col>
                                        <Col span={18}>
                                            <Form.Item
                                                name={[
                                                    "formData",
                                                    "applicant",
                                                    "dateOfBirth",
                                                ]}
                                                rules={[{ required: true }]}
                                            >
                                                <DatePicker placeholder="ថ្ងៃខែឆ្នាំកំណើត" />
                                            </Form.Item>
                                        </Col>
                                        <Col span={6}>
                                            សញ្ជាតិ
                                            <br />
                                            Nationality
                                        </Col>
                                        <Col span={18}>
                                            <Form.Item
                                                name={[
                                                    "formData",
                                                    "applicant",
                                                    "nationality",
                                                ]}
                                                rules={[{ required: true }]}
                                            >
                                                <Input placeholder="សញ្ជាតិ / Nationality" />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={8}>
                                            <Form.Item
                                                name={[
                                                    "formData",
                                                    "applicant",
                                                    "identityTypeId",
                                                ]}
                                                rules={[{ required: true }]}
                                            >
                                                <Radio.Group>
                                                    <Space direction="vertical">
                                                        {identificationTypes &&
                                                            identificationTypes.map(
                                                                (
                                                                    identificationType: any,
                                                                    index: number
                                                                ) => (
                                                                    <Radio
                                                                        key={
                                                                            identificationType.value
                                                                        }
                                                                        value={
                                                                            identificationType.value
                                                                        }
                                                                    >
                                                                        {
                                                                            identificationType.label
                                                                        }
                                                                    </Radio>
                                                                )
                                                            )}
                                                    </Space>
                                                </Radio.Group>
                                            </Form.Item>
                                        </Col>
                                        <Col span={16}>
                                            <Form.Item
                                                name={[
                                                    "formData",
                                                    "applicant",
                                                    "identityNumber",
                                                ]}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message:
                                                            "Identificaiton number is required",
                                                    },
                                                ]}
                                            >
                                                <Input placeholder="សូមបំពេញ" />
                                            </Form.Item>
                                        </Col>
                                        <Col span={6}>
                                            ថ្ងៃខែឆ្នាំផ្តល់ / Issued Date
                                        </Col>
                                        <Col span={18}>
                                            <Form.Item
                                                name={[
                                                    "formData",
                                                    "applicant",
                                                    "identityIssuedDate",
                                                ]}
                                                rules={[{ required: true }]}
                                            >
                                                <DatePicker />
                                            </Form.Item>
                                        </Col>
                                        <Col span={6}>
                                            ថ្ងៃខែឆ្នាំផុតកំណត / Date of Expiry
                                        </Col>
                                        <Col span={18}>
                                            <Form.Item
                                                name={[
                                                    "formData",
                                                    "applicant",
                                                    "expiredDate",
                                                ]}
                                                rules={[{ required: true }]}
                                            >
                                                <DatePicker />
                                            </Form.Item>
                                        </Col>

                                        <Col span={6}>អាសយដ្ឋាន / Address</Col>
                                        <Col span={18}>
                                            <Form.Item
                                                name={[
                                                    "formData",
                                                    "applicant",
                                                    "address",
                                                ]}
                                                rules={[{ required: true }]}
                                            >
                                                <Input placeholder="អាសយដ្ឋាន / Address" />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={6}>
                                            ទូរស័ព្ទចល័ត / Mobile Phone Number
                                        </Col>
                                        <Col span={18}>
                                            <Form.Item
                                                name={[
                                                    "formData",
                                                    "applicant",
                                                    "mobilePhoneNumber",
                                                ]}
                                                rules={[{ required: true }]}
                                            >
                                                <Input placeholder="ទូរស័ព្ទចល័ត / Mobile Phone Number" />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={6}>
                                            សារអេឡិចត្រូនិច / Email Address
                                        </Col>
                                        <Col span={18}>
                                            <Form.Item
                                                name={[
                                                    "formData",
                                                    "applicant",
                                                    "emailAddress",
                                                ]}
                                                rules={[{ required: true }]}
                                            >
                                                <Input placeholder="សារអេឡិចត្រូនិច / Email Address" />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </>
                            ),
                        },
                        {
                            label: "ព័ត៌មានអំពី ឧបករណ៍ល្បែង",
                            key: "4",
                            forceRender: true,
                            children: (
                                <div>
                                    <Form.Item
                                        shouldUpdate={(preValues, curValues) =>
                                            preValues.gameEquipments !==
                                            curValues.gameEquipments
                                        }
                                    >
                                        {({ getFieldValue }: any) => {
                                            const gameEquipments: any[] =
                                                getFieldValue(
                                                    "gameEquipments"
                                                ) || [];

                                            return (
                                                <Table
                                                    bordered
                                                    rowKey="id"
                                                    className="w-full"
                                                    pagination={{
                                                        // position: [],
                                                        pageSize: 100,
                                                    }}
                                                    columns={
                                                        boardOfDirectorColumns
                                                    }
                                                    dataSource={gameEquipments}
                                                    size="small"
                                                />
                                            );
                                        }}
                                    </Form.Item>
                                    <Space>
                                        <Button
                                            type="primary"
                                            onClick={() =>
                                                setGameRuleVisibility(true)
                                            }
                                        >
                                            បន្ថែមថ្មី
                                        </Button>
                                        {
                                            id && <Link
                                                to={casinoEquipmentMaster ? `/equipment/casino/detail/${id}` : `/equipment/casino/create/${id}`}
                                                className="text-blue-600"
                                            >
                                                ព័ត៌មានឧបករណ៍ល្បែងលម្អិត
                                            </Link>
                                        }
                                    </Space>
                                </div>
                            ),
                        },
                        {
                            label: "សំណុំឯកសារភ្ជាប់",
                            key: "5",
                            forceRender: true,
                            children: (
                                <Row>
                                    <Col span={24}>
                                        <Form.Item
                                            name={[
                                                "formData",
                                                "representative",
                                                "authorizationLetter",
                                            ]}
                                            valuePropName="checked"
                                        >
                                            <Checkbox>
                                                លិខិតប្រគល់សិទ្ធិ
                                                ចំពោះបុគ្កលដាក់ពាក្យស្នើសុំជាតំណាងស្របច្បាប់
                                                / Authorization letter for
                                                representative
                                            </Checkbox>
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <Form.Item
                                            name={[
                                                "formData",
                                                "representative",
                                                "authorizationLetter",
                                            ]}
                                            valuePropName="checked"
                                        >
                                            <Checkbox>
                                                ព័ត៌មានលម្អិតអំពីឧបករណ៍ល្បែង
                                                និង/ឬកម្មវិធីល្បែង ស្នើសុំ
                                                មានជាអាទ៏ះ ឆ្មោះ លេខសម្គាល់
                                                ម៉ូដែល
                                                ឆ្មៅះក្រុមហ៊ុនផលិតនិងរូបភាពពណ៌នាអពីឧបករណ៍ល្បែង
                                                / Cabinet details: name of
                                                machine, serial/identification
                                                number, model, name of
                                                manufacturer, manufacture date
                                                and image description of gaming
                                                equipment
                                            </Checkbox>
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <Form.Item
                                            name={[
                                                "formData",
                                                "representative",
                                                "authorizationLetter",
                                            ]}
                                            valuePropName="checked"
                                        >
                                            <Checkbox>
                                                ព័ត៌មានលម្អិតអំពីប្រព័ន្ធប្រតិបត្តិការ
                                                និង/រកម្មវិធីល្បែង / Software
                                                specification: operating system,
                                                os version, game software and
                                                game version.
                                            </Checkbox>
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <Form.Item
                                            name={[
                                                "formData",
                                                "representative",
                                                "authorizationLetter",
                                            ]}
                                            valuePropName="checked"
                                        >
                                            <Checkbox>
                                                ឯកសារណែនាំពីឧបករណ៍ល្បែង
                                                និង/ឬកម្មវិធីល្បែង / Gaming
                                                equipment and /or software
                                                manual.
                                            </Checkbox>
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <Form.Item
                                            name={[
                                                "formData",
                                                "representative",
                                                "authorizationLetter",
                                            ]}
                                            valuePropName="checked"
                                        >
                                            <Checkbox>
                                                ឯកសារកំណត់ពីរបៀបលេង
                                                និងវិធានល្បែងស្របតាមស្តង់ដាបច្ចេកទេស/
                                                Document of how to play and game
                                                rule complied with technical
                                                standard
                                            </Checkbox>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            ),
                        },
                    ]}
                />
            </Form>

            <CertificateCasinoGameEquipmentModal
                visibility={gameRuleVisibility}
                onCancel={() => setGameRuleVisibility(false)}
            />
        </>
    );
};
