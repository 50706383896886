export const SET_INSPECTION_CERTIFICATE_TYPE_FILTER_VALUE = "SET_INSPECTION_CERTIFICATE_TYPE_FILTER_VALUE";
export const SET_INSPECTION_CERTIFICATE_TYPE_VALUE = "SET_INSPECTION_CERTIFICATE_TYPE_VALUE";
export const SET_INSPECTION_CERTIFICATE_TYPE_ID = "SET_INSPECTION_CERTIFICATE_TYPE_ID";


export const LIST_INSPECTION_CERTIFICATE_TYPE_REQUEST = "LIST_INSPECTION_CERTIFICATE_TYPE_REQUEST";
export const LIST_INSPECTION_CERTIFICATE_TYPE_SUCCESS = "LIST_INSPECTION_CERTIFICATE_TYPE_SUCCESS";
export const GET_INSPECTION_CERTIFICATE_TYPE_REQUEST = "GET_INSPECTION_CERTIFICATE_TYPE_REQUEST";
export const GET_INSPECTION_CERTIFICATE_TYPE_SUCCESS = "GET_INSPECTION_CERTIFICATE_TYPE_SUCCESS";
export const SELECT_INSPECTION_CERTIFICATE_TYPE_REQUEST = "SELECT_INSPECTION_CERTIFICATE_TYPE_REQUEST";
export const SELECT_INSPECTION_CERTIFICATE_TYPE_SUCCESS = "SELECT_INSPECTION_CERTIFICATE_TYPE_SUCCESS";

export const SET_INSPECTION_CERTIFICATE_TYPE_STATUS = "SET_INSPECTION_CERTIFICATE_TYPE_STATUS";
export const CREATE_INSPECTION_CERTIFICATE_TYPE_REQUEST = "CREATE_INSPECTION_CERTIFICATE_TYPE_REQUEST";
export const EDIT_INSPECTION_CERTIFICATE_TYPE_REQUEST = "EDIT_INSPECTION_CERTIFICATE_TYPE_REQUEST";
export const DELETE_INSPECTION_CERTIFICATE_TYPE_REQUEST = "DELETE_INSPECTION_CERTIFICATE_TYPE_REQUEST";
export const TERMINATE_INSPECTION_CERTIFICATE_TYPE_REQUEST = "TERMINATE_INSPECTION_CERTIFICATE_TYPE_REQUEST";