import {
    EntityCasinoListConstant,
    GET_ENTITY_CASINO_BY_ID_REQUEST,
    GET_ENTITY_CASINO_BY_ID_SUCCESS,
    GET_CASINO_STATUS_COUNT_REQUEST,
    GET_CASINO_STATUS_COUNT_SUCCESS,
    GET_LOTTERY_STATUS_COUNT_REQUEST,
    GET_LOTTERY_STATUS_COUNT_SUCCESS,
    CREATE_CASINO_MODAL_REQUEST,
    CREATE_CASINO_MODAL_SUCCESS,
    GET_CASINO_MODAL_DETAIL,
    SET_CASINO_ID,
    EDIT_ENTITY_CASINO_REQUEST,
    EXPORT_ENTITY_CASINO_REQUEST,
    REPORT_OPERATOR_BY_AREACODE_REQUEST,
    REPORT_OPERATOR_BY_AREACODE_SUCCESS,
    GET_OEPRATOR_STATUS_COUNT_REQUEST,
} from "Modules/Entity/Operator/Constants";

export const SetFormValuesAction = (formValues: any) => {
    return {
        type: EntityCasinoListConstant.SET_FORM_VALUES,
        formValues,
    };
};

export const EntityCasinoListRequestAction = () => {
    return {
        type: EntityCasinoListConstant.ENTITY_CASINO_LIST_REQUEST,
    };
};

export const EntityCasinoListSuccessAction = (casinos: any) => {
    return {
        type: EntityCasinoListConstant.ENTITY_CASINO_LIST_SUCCESS,
        casinos,
    };
};

export const QueryCasinoWithCompanyAction = () => {
    return {
        type: EntityCasinoListConstant.QUERY_CASINO_WITH_COMPANY_REQUEST,
    };
};

export const QueryCasinoWithCompanySuccessAction = (casinos: any) => {
    return {
        type: EntityCasinoListConstant.QUERY_CASINO_WITH_COMPANY_SUCCESS,
        casinos,
    };
};

export const SelectCasinoRequestAction = () => {
    return {
        type: EntityCasinoListConstant.SELECT_CASINO_REQUEST,
    };
};

export const SelectCasinoSuccessAction = (casinoOptions: any) => {
    return {
        type: EntityCasinoListConstant.SELECT_CASINO_SUCCESS,
        casinoOptions,
    };
};

export const CreateCasinoRequestAction = () => {
    return {
        type: EntityCasinoListConstant.CREATE_CASINO_REQUEST,
    };
};

export const GetEntityCasinoByIdRequestAction = () => {
    return {
        type: GET_ENTITY_CASINO_BY_ID_REQUEST,
    };
};

export const GetEntityCasinoByIdSuccessAction = (casino: any) => {
    return {
        type: GET_ENTITY_CASINO_BY_ID_SUCCESS,
        casino,
    };
};

export const GetCasinoStatusCountRequestAction = () => {
    return {
        type: GET_CASINO_STATUS_COUNT_REQUEST
    }
}

export const GetCasinoStatusCountSuccessAction = (casinoStatusCount: any) => {
    return {
        type: GET_CASINO_STATUS_COUNT_SUCCESS,
        casinoStatusCount
    }
}

export const GetOperatorStatusCountRequestAction = () => {
    return {
        type: GET_OEPRATOR_STATUS_COUNT_REQUEST
    }
}



export const GetLotteryStatusCountRequestAction = () => {
    return {
        type: GET_LOTTERY_STATUS_COUNT_REQUEST
    }
}

export const GetLotteryStatusCountSuccessAction = (lotteryStatusCount: any) => {
    return {
        type: GET_LOTTERY_STATUS_COUNT_SUCCESS,
        lotteryStatusCount
    }
}

export const CreateCasinoModalRequestAction = () => {
    return {
        type: CREATE_CASINO_MODAL_REQUEST,
    };
};

export const CreateCasinoModalSuccessAction = (casinoDetailModal: any) => {
    return {
        type: CREATE_CASINO_MODAL_SUCCESS,
        casinoDetailModal,
    };
};

export const GetCasinoModalDetailAction = (casinoDetailModal: any) => {
    return {
        type: GET_CASINO_MODAL_DETAIL,
        casinoDetailModal,
    };
};

export const SetCasinoIdAction = (id: string) => {
    return {
        type: SET_CASINO_ID,
        id,
    };
};

export const EditEntityCasinoRequestAction = () => {
    return {
        type: EDIT_ENTITY_CASINO_REQUEST,
    };
};

// export const EditEntityCasinoSuccessAction = (casinoDetail) => {
//     return {
//         type:
//     }
// }

export const ExportEntityCasinoRequestAction = () => {
    return { type: EXPORT_ENTITY_CASINO_REQUEST };
};

export const ReportOperatorByAreaRequestAction = () => {
    return {
        type: REPORT_OPERATOR_BY_AREACODE_REQUEST,
    }
}

export const ReportOperatorByAreaSuccessAction = (operators: any) => {
    return {
        type: REPORT_OPERATOR_BY_AREACODE_SUCCESS,
        operators,
    }
}