import { RequestLotteryApplicationFormRoute } from "Modules/Certificate/Lottery/ApplicationForm"
import { CertifyLotteryRoute } from "Modules/Certificate/Lottery/Certify";
import { CerticiateLotteryVerifyPage } from "./Verify";
export const LotteryApplicationFormRoute = {
    path: "lottery",
    children: [
        RequestLotteryApplicationFormRoute,
        CertifyLotteryRoute,
        {
            path: "verify",
            element: <CerticiateLotteryVerifyPage />,
        },
    ],
};
