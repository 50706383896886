import { delay, put, takeLatest } from "redux-saga/effects";
import { AUTO_DISMISS_SNACK } from "Components/SnackMessage/Constants";
import { ClearSnackMessageAction } from "Components/SnackMessage/Actions";

export function* dismissSnackMessageAction() {
    yield delay(5000);
    yield put(ClearSnackMessageAction());
}

export default function* snackBarSaga() {
    yield takeLatest(AUTO_DISMISS_SNACK, dismissSnackMessageAction);
}
