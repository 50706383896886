export const GameTypeConstants = {
    SET_FORM_VALUES: "SET_FORM_VALUES",
    CREATE_GAME_TYPE_REQUEST: "CREATE_GAME_TYPE_REQUEST",
    CREATE_GAME_TYPE_SUCCESS: "CREATE_GAME_TYPE_SUCCESS",
    CREATE_GAME_TYPE_ERROR: "CREATE_GAME_TYPE_ERROR",
    QUERY_GAME_TYPE_REQUEST: "QUERY_GAME_TYPE_REQUEST",
    QUERY_GAME_TYPE_SUCCESS: "QUERY_GAME_TYPE_SUCCESS",
    QUERY_GAME_TYPE_ERROR: "QUERY_GAME_TYPE_ERROR",
};

export const GET_GAME_TYPE_REQUEST = "GET_GAME_TYPE_REQUEST";
export const GET_GAME_TYPE_SUCCESS = "GET_GAME_TYPE_SUCCESS";
export const UPDATE_GAME_TYPE_REQUEST = "UPDATE_GAME_TYPE_REQUEST";
export const SET_GAME_TYPE_ID = "SET_GAME_TYPE_ID";
export const SELECT_GAME_TYPE_REQUEST = "SELECT_GAME_TYPE_REQUEST";
export const SELECT_GAME_TYPE_SUCCESS = "SELECT_GAME_TYPE_SUCCESS";