import produce from "immer";
import {
    SET_REQUEST_LOTTERY_LICENSE_FILTER_VALUE,
    SET_REQUEST_LOTTERY_LICENSE_VALUE,
    SET_REQUEST_LOTTERY_ID,
    LIST_REQUEST_LOTTERY_LICENSE_SUCCESS,
    GET_REQUEST_LOTTERY_LICENSE_SUCCESS
} from "Modules/License/Lottery/ApplicationForm/Constants";


export const LotteryLicenseApplicationFormInitialState: any = {
    id: null,
    filterValues: null,
    values: null,
    requestLotteryLicense: null,
    requestLotteryLicenses: [],
}

const LotteryLicenseApplicationFormReducer = produce((draft, action) => {
    switch (action.type) {
        case SET_REQUEST_LOTTERY_LICENSE_FILTER_VALUE:
            draft.filterValues = action.values;
            break;
        case SET_REQUEST_LOTTERY_LICENSE_VALUE:
            draft.values = action.values;
            break;
        case SET_REQUEST_LOTTERY_ID:
            draft.id = action.id;
            break;
        case LIST_REQUEST_LOTTERY_LICENSE_SUCCESS:
            draft.requestLotteryLicenses = action.lotteryLicenses;
            break;
        case GET_REQUEST_LOTTERY_LICENSE_SUCCESS:
            draft.requestLotteryLicense = action.lotteryLicense;
            break;
        default: break;
    }
}, LotteryLicenseApplicationFormInitialState);

export default LotteryLicenseApplicationFormReducer;