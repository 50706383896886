import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useInjectReducer } from "Utility/InjectReducer";
import { useInjectSaga } from "Utility/InjectSaga";

import { HelmetHeader } from "Components/Helmet-Header";
import { ArchiveIcon } from "@heroicons/react/outline";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import {
    Form,
    Table,
    Modal,
    Space,
    Button,
    Popconfirm
} from "antd";
import { FullLayout } from "Components/Layouts/";
import { InspectionCertificateTypeFilterForm } from "Modules/Settings/InspectionCertificateType/Forms/InspectionCertificateTypeFilterForm";
import { InspectionCertificateTypeForm } from "Modules/Settings/InspectionCertificateType/Forms/InspectionCertificateTypeForm";

import Reducer from "Modules/Settings/InspectionCertificateType/Reducer";
import Saga from "Modules/Settings/InspectionCertificateType/Saga";
import { MakeInspectionCertificateTypeListSelector, MakeInspectionCertificateTypeStatusSelector } from "Modules/Settings/InspectionCertificateType/Selectors";
import {
    CreateInspectionCertificateTypeRequestAction,
    DeleteInspectionCertificateTypeRequestAction,
    EditInspectionCertificateTypeRequestAction,
    ListInspectionCertificateTypeRequestAction,
    ListInspectionCertificateTypeSuccessAction,
    SetInspectionCertificateTypeFilterValueAction,
    SetInspectionCertificateTypeIdAction,
    SetInspectionCertificateTypeStatusAction,
    SetInspectionCertificateTypeValueAction
} from "Modules/Settings/InspectionCertificateType/Actions";


const key = "inspectionCertificateType";
export const InspectionCertificateTypeList = () => {
    useInjectReducer({ key, reducer: Reducer });
    useInjectSaga({ key, saga: Saga });
    // fields
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const entities = useSelector(MakeInspectionCertificateTypeListSelector());
    const status = useSelector(MakeInspectionCertificateTypeStatusSelector());
    const [formVisible, setFormVisible] = useState<boolean>(false);
    const [certificateFile, setCertificateFile] = useState<any>(null);

    const equipmentTypeColumn = [
        {
            key: "code",
            title: "កូដ",
            dataIndex: "code",
        },
        {
            key: "name",
            title: "ប្រភេទលតាបត្រ",
            dataIndex: "name",
        },
        {
            key: "equipmentTypeName",
            title: "ប្រភេទឧបករណ៍",
            dataIndex: "equipmentTypeName",
        },
        {
            key: "descriptions",
            title: "បរិយាយ",
            dataIndex: "descriptions",
        },
        {
            key: "id",
            width: 100,
            render: (item: any) => <Space>
                <Popconfirm
                    title="Delete the item"
                    description="Are you sure to delete this item?"
                    onConfirm={() => onDeleteClicked(item)}
                    onCancel={() => console.log("Cancel")}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button type="link" className="p-2" >
                        <DeleteOutlined />
                    </Button>
                </Popconfirm>

                <Button type="link" className="p-2" onClick={() => onEditClicked(item)}>
                    <EditOutlined />
                </Button>
            </Space>
        }
    ]

    // constructor 
    useEffect(() => {
        onInspectionCertificateTypeFilterSubmit({ search: "" });
        return () => {
            dispatch(ListInspectionCertificateTypeSuccessAction([]));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {

        switch (status) {
            case 1:
                form.resetFields();
                setFormVisible(false);
                dispatch(SetInspectionCertificateTypeStatusAction(0));
                onInspectionCertificateTypeFilterSubmit({ search: "" });
                break;
            case 2:
                dispatch(SetInspectionCertificateTypeStatusAction(0));
                break;
            case 3:
                dispatch(SetInspectionCertificateTypeStatusAction(0));
                onInspectionCertificateTypeFilterSubmit({ search: "" });
                break;
            default: break;

        }

        return () => {
            dispatch(SetInspectionCertificateTypeStatusAction(0));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status])

    // methods
    const onFormProviderSubmit = (
        name: string,
        { values, forms }: any
    ) => {
        switch (name) {
            case "inspectionCertificateTypeFilterForm":
                onInspectionCertificateTypeFilterSubmit(values);
                break;
            case "inspectionCertificateTypeForm":
                onSave(values);
                break;
            default: break;
        }
    };

    const onInspectionCertificateTypeFilterSubmit = (values: any) => {
        const model = {
            filter: { ...values }
        }
        dispatch(SetInspectionCertificateTypeFilterValueAction(model));
        dispatch(ListInspectionCertificateTypeRequestAction());
    }

    const onSave = (values: any) => {
        const model = {
            ...values,
            equipmentTypeId: values.equipmentType.value,
            equipmentTypeName: values.equipmentType.label,
            descriptions: values.name,
        }

        dispatch(SetInspectionCertificateTypeValueAction(model));

        if (values.id) {
            dispatch(EditInspectionCertificateTypeRequestAction());
        }
        else {
            dispatch(CreateInspectionCertificateTypeRequestAction());
        }
    }

    const onNewClicked = (e: any) => {
        form.resetFields();
        form.setFieldValue("state", 1);
        setFormVisible(true);
    }

    const onEditClicked = (item: any) => {
        const model = {
            ...item
        };
        model.equipmentType = {
            key: item.equipmentTypeId,
            value: item.equipmentTypeId,
            label: item.equipmentType
        }
        form.setFieldsValue(model);
        setFormVisible(true);
    }

    const onDeleteClicked = (item: any) => {
        dispatch(SetInspectionCertificateTypeIdAction(item.id));
        dispatch(DeleteInspectionCertificateTypeRequestAction());
    }

    // render
    const ActionButtons = () => (
        <>
            <Button
                type="primary"
                onClick={onNewClicked}
            >
                បញ្ចូលថ្មី
            </Button>
        </>
    );

    return (
        <>
            <FullLayout
                icon={<ArchiveIcon />}
                title="បញ្ជីប្រភេទលតាបត្រ"
                descriptions=""
                actions={<ActionButtons />}
            >
                <HelmetHeader meta="ប្រភេទលតាបត្រ" />
                <Form.Provider onFormFinish={onFormProviderSubmit}>
                    <InspectionCertificateTypeFilterForm />
                    <Modal open={formVisible}
                        onOk={() => form.submit()}
                        onCancel={() => setFormVisible(false)}
                        width="100%">
                        <InspectionCertificateTypeForm form={form} setCertificateFile={setCertificateFile} />
                    </Modal>
                </Form.Provider>
                <Table rowKey={"id"} size="small" columns={equipmentTypeColumn} dataSource={entities || []} />
            </FullLayout>
        </>
    );
};
