import { List } from "Modules/Settings/GameRule/List";
import { Create } from "Modules/Settings/GameRule/Create";
import { Detail } from "Modules/Settings/GameRule/Detail";
export const SettingGameRuleRoute = {
    path: "game-rule",
    children: [
        { path: "", element: <List /> },
        { path: "create", element: <Create /> },
        { path: "detail/:id", element: <Detail /> },
    ],
};
