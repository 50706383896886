import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useInjectSaga } from "Utility/InjectSaga";
import { useInjectReducer } from "Utility/InjectReducer";

import type { MenuProps } from 'antd';
import {
    Form,
    Button,
    Dropdown
} from "antd";
import { FullLayout } from "Components/Layouts/";
import { HelmetHeader } from "Components/Helmet-Header";
import { EntityCasinoForm } from "Modules/Entity/Operator/Forms/EntityCasinoForm";
import { LotteryCreateForm } from "Modules/Entity/Operator/Forms/LotteryCreateForm";

import Reducer from "Modules/Entity/Operator/Reducer";
import Saga from "Modules/Entity/Operator/Saga";
import {
    SetFormValuesAction,
    EditEntityCasinoRequestAction

} from "Modules/Entity/Operator/Actions";
import { MakeCasinoSelector } from "Modules/Entity/Operator/Selectors";
import { EntityType } from "Common/Constants/EntityType";
import { GetEntityCompanyByIdRequestAction,  SetCompanyValueAction } from "Modules/Entity/Company/Actions";
import dayjs from "dayjs";
const key = "entityCasino";
export const CasinoDetail = () => {
    useInjectReducer({ key, reducer: Reducer });
    useInjectSaga({ key, saga: Saga });
    // fields
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [companyVisibility, setCompanyVisibility] = useState(false);
    const casino = useSelector(MakeCasinoSelector());
    const entityType = searchParams.get("entityType");

    const operationButtonGroup = [
        {
            key: "btn-1",
            label: "ភ្ជាប់ឧបករណ៍ នឹងលតាបត្រ",
        },
        {
            key: "btn-2",
            label: "ស្កេនលតាបត្រ"
        }
    ];

   

    useEffect(() => {
      if (casino) {
        const model = {
            ...casino.casino,
            licenseExpiredDate: casino.casinoData.licenseExpiredDate ? dayjs(casino.casinoData.licenseExpiredDate) : null,
            companyId: casino.casino.companyId.toUpperCase(),
            casinoData: casino.casinoData
        };
        console.log("casino.casinoData.licenseExpiredDate,", casino.casinoData.licenseExpiredDate);
        if (entityType === EntityType.LOTTERY) {

        }
        else {

        }
          form.setFieldsValue(model);
          dispatch(SetCompanyValueAction(model.companyId));
          dispatch(GetEntityCompanyByIdRequestAction());
        }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [casino])

    // method
    const ActionButton = () => {
        return (
            <div className="flex flex-row gap-2">
                <Button
                    type="primary"
                    onClick={() => form.submit()}
                >
                    រក្សាទុក
                </Button>
                {
                    entityType !== EntityType.LOTTERY && <Dropdown.Button menu={{ items: operationButtonGroup, onClick: onMenuClick }}>ប្រតិបត្តិការណ៍</Dropdown.Button>
                }

            </div>
        );
    };
    // events
    const onFormProviderSubmit = (name: string, { forms, values }: any) => {
        switch (name) {
            case "entityCasinoForm": {
                onCasinoSubmit({ code: values.casinoLicenseNumber, ...values });
                break;
            }
        }
    };

    const onCasinoSubmit = async (values: any) => {
        const model = {
            ...values,
            gPAName: "",
            gAPLatinName: "",
            licenseExpiredDate: null,
            casinoLicenseNumber: values.casinoLicenseNumber || " ",
            entityTypeId: 1,
            id: casino.casino.id,
        };

        if (entityType === EntityType.LOTTERY) {
            model.entityTypeId = 2;
        }
        if (values.licenseExpiredDate) {
            // model.licenseExpiredDate = values.licenseExpiredDate.format("YYYY-MM-DD");
            model.casinoData = {
                ...model.casinoData,
                licenseExpiredDate: values.licenseExpiredDate.format("YYYY-MM-DD"),
            }
        }
        dispatch(SetFormValuesAction(model));
        dispatch(EditEntityCasinoRequestAction());
    };
    const onMenuClick: MenuProps['onClick'] = (e) => {

        switch (e.key) {
            case "btn-1":
                navigate(`/equipment/binding/create/${casino.casino.id}`);
                break;
            case "btn-2":
                navigate(`/equipment/binding/verify/${casino.casino.id}`);
                break;
            default: break;
        }
    };

    return (
        <>
            <FullLayout
                icon={null}
                title={`ព័ត៌មាន${entityType === EntityType.LOTTERY ? "ប្រតិបត្តិករ" : "កាស៊ីណូ"} - ${casino?.casino?.name}`}
                redirectUrl={`/entity/operator?entityType=${entityType}`}
                descriptions="ត្រលប់ទៅបញ្ជី"
                actions={<ActionButton />}
            >
                <HelmetHeader meta="បញ្ជូលព័ត៌មានកាស៊ីណូ" />
                <Form.Provider onFormFinish={onFormProviderSubmit}>
                    {
                        entityType === EntityType.LOTTERY ? <LotteryCreateForm form={form} setCompanyVisibility={setCompanyVisibility} />
                            : <EntityCasinoForm form={form} setCompanyVisibility={setCompanyVisibility} />
                    }
                </Form.Provider>
            </FullLayout>
        </>
    );
};
