import { all, call, put, select, takeLatest } from "redux-saga/effects";
import Request from "Utility/Http";
import ApiEndpoint from "Utility/Endpoint";
import { POST, GET, PUT } from "Utility/Constant";
import { v4 as uuid } from "uuid";

import { AsyncEndAction, AsyncStartAction } from "Modules/Application/Actions";

import {
    MakeRequestLotteryGameTypeFilterValueSelector,
    MakeRequestLotteryGameTypeValueSelector,
    MakeRequestLotteryGameTypeIdSelector
} from "Modules/Certificate/Lottery/ApplicationForm/Selectors";
import { ListRequestLotteryGameTypeSuccessAction, GetRequestLotteryGameTypeSuccessAction } from "Modules/Certificate/Lottery/ApplicationForm/Actions";
import { CREATE_REQUEST_LOTTERY_GAME_TYPE_REQUEST, EDIT_REQUEST_LOTTERY_GAME_TYPE_REQUEST, GET_REQUEST_LOTTERY_GAME_TYPE_REQUEST, LIST_REQUEST_LOTTERY_GAME_TYPE_REQUEST } from "./Constants";
import { ShowMessage } from "Common/Saga";


function* ListRequestLotteryGameType() {
    yield put(AsyncStartAction())
    try {
        const filterValues = yield select(MakeRequestLotteryGameTypeFilterValueSelector());
        const url = `${ApiEndpoint.REQUEST_LICENSE}/lists`;
        const payload = ApiEndpoint.makeApiPayload(url, POST, filterValues);
        const response = yield call(Request, payload);

        if (response) {
            yield put(ListRequestLotteryGameTypeSuccessAction(response));
        }
    }
    catch {
        yield ShowMessage({
            type: "error",
            message: "មានបញ្ហាពេលរក្សារទុក",
            content: "",
            id: uuid(),
        });
    }
    finally {
        yield put(AsyncEndAction());
    }
}

function* CreateRequestLotteryGameType() {
    yield put(AsyncStartAction());
    try {
        const model = yield select(MakeRequestLotteryGameTypeValueSelector());
        const url = ApiEndpoint.REQUEST_LICENSE;
        const payload = ApiEndpoint.makeApiPayload(url, POST, model);
        const response = yield call(Request, payload);
        if (response) {
            yield ShowMessage({
                type: "success",
                message: "ទិន្នន័យបានរក្សារទុក",
                redirectUrl: "/certificate/lottery/request-game-type",
                content: "",
                id: uuid(),
            });
        }
    }
    catch {
        yield ShowMessage({
            type: "error",
            message: "មានបញ្ហាពេលរក្សារទុក",
            content: "",
            id: uuid(),
        });
    }
    finally {
        yield put(AsyncEndAction());
    }
}

function* GetRequestLotteryGameType() {
    yield put(AsyncStartAction());
    try {
        const id = yield select(MakeRequestLotteryGameTypeIdSelector());

        if (!id) {
            return yield ShowMessage({
                type: "error",
                message: "មានបញ្ហាពេលទាញយក",
                content: "",
                id: uuid(),
            });
        }
        const url = `${ApiEndpoint.REQUEST_LICENSE}/${id}`;
        const payload = ApiEndpoint.makeApiPayload(url, GET, id);
        const response = yield call(Request, payload);
        if (response && !response.status) {
            yield put(GetRequestLotteryGameTypeSuccessAction(response));
        }
    }
    catch {
        yield ShowMessage({
            type: "error",
            message: "មានបញ្ហាពេលទាញយក",
            content: "",
            id: uuid(),
        });
    }
    finally {
        yield put(AsyncEndAction());
    }
}

function* EditRequestLotteryGameType() {
    yield put(AsyncStartAction());
    try {
        const model = yield select(MakeRequestLotteryGameTypeValueSelector());
        const url = ApiEndpoint.REQUEST_LICENSE;
        const payload = ApiEndpoint.makeApiPayload(url, PUT, model);
        const response = yield call(Request, payload);
        if (response) {
            yield ShowMessage({
                type: "success",
                message: "ទិន្នន័យបានរក្សារទុក",
                redirectUrl: "/certificate/lottery/request-game-type",
                content: "",
                id: uuid(),
            });
        }
    }
    catch {
        yield ShowMessage({
            type: "error",
            message: "មានបញ្ហាពេលរក្សារទុក",
            content: "",
            id: uuid(),
        });
    }
    finally {
        yield put(AsyncEndAction());
    }
}

export default function* LotteryApplicationFormSaga() {
    yield all([
        yield takeLatest(LIST_REQUEST_LOTTERY_GAME_TYPE_REQUEST, ListRequestLotteryGameType),
        yield takeLatest(CREATE_REQUEST_LOTTERY_GAME_TYPE_REQUEST, CreateRequestLotteryGameType),
        yield takeLatest(GET_REQUEST_LOTTERY_GAME_TYPE_REQUEST, GetRequestLotteryGameType),
        yield takeLatest(EDIT_REQUEST_LOTTERY_GAME_TYPE_REQUEST, EditRequestLotteryGameType),
    ]);
}