import React from "react";
import { List } from "Modules/Certificate/Casino/ApplicationForm/GameEquipment/List";

import { RequestGameEquipmentList } from "Modules/Certificate/Casino/ApplicationForm/GameEquipment/RequestGameEquipmentList";
import { RequestGameEquipmentCreate } from "Modules/Certificate/Casino/ApplicationForm/GameEquipment//RequestGameEquipmentCreate";
import { RequestGameEquipmentDetail } from "Modules/Certificate/Casino/ApplicationForm/GameEquipment/RequestGameEquipmentDetail";

export const CertificateGameEquipmentRoute = [
    {
        path: "request",
        children: [
            { path: "", element: <RequestGameEquipmentList /> },
            { path: "create", element: <RequestGameEquipmentCreate /> },
            { path: ":id", element: <RequestGameEquipmentDetail /> },
        ],
    },
    {
        path: "",
        children: [
            { path: "", element: <List /> },
            // { path: "create", element: <RequestGameEquipmentCreate /> },
            // { path: ":id", element: <RequestGameEquipmentDetail /> },
        ],
    },
];
