import { call, put, select, takeLatest, all } from "redux-saga/effects";
import { v4 as uuid } from "uuid";
import Request from "Utility/Http";
import { DELETE, GET, POST, PUT } from "Utility/Constant";
import ApiEndpoint from "Utility/Endpoint";
import { ShowMessage } from "Common/Saga";

import { AsyncEndAction, AsyncStartAction } from "Modules/Application/Actions";
import {
    ListEquipmentTypeSuccessAction,
    SetEquipmentTypeStatusAction,
    SelectEquipmentTypeSuccessAction
} from "Modules/Settings/EquipmentType/Actions";
import { MakeEquipmentTypeFilterValueSelector, MakeEquipmentTypeIdSelector, MakeEquipmentTypeValueSelector } from "Modules/Settings/EquipmentType/Selectors";
import {
    CREATE_EQUIPMENT_TYPE_REQUEST,
    DELETE_EQUIPMENT_TYPE_REQUEST,
    EDIT_EQUIPMENT_TYPE_REQUEST,
    GET_EQUIPMENT_TYPE_REQUEST,
    LIST_EQUIPMENT_TYPE_REQUEST,
    SELECT_EQUIPMENT_TYPE_REQUEST
} from "Modules/Settings/EquipmentType/Constants";

function* ListEquipmentType() {
    yield put(AsyncStartAction());
    try {
        const filter = yield select(MakeEquipmentTypeFilterValueSelector());
        const url = `${ApiEndpoint.EQUIPMENT_TYPE_PATH}/lists`;
        const payload = ApiEndpoint.makeApiPayload(url, POST, filter);
        const response = yield call(Request, payload);
        if (response && !response.status && !response.snack) {
            yield put(ListEquipmentTypeSuccessAction(response));
        }
    } catch {
        yield ShowMessage({
            type: "error",
            message: "មានបញ្ហាពេលទាញទិន្នន័យ",
            id: uuid(),
        })
    }
    finally {
        yield put(AsyncEndAction());
    }
}

function* GetEquipmentType() {
    yield put(AsyncStartAction());
    try {
        const id = yield select(MakeEquipmentTypeIdSelector());
        const url = `${ApiEndpoint.EQUIPMENT_TYPE_PATH}/${id}`;
        const payload = ApiEndpoint.makeApiPayload(url, GET, id);
        const response = yield call(Request, payload);
        if (response && !response.status && !response.snack) {
            yield put(ListEquipmentTypeSuccessAction(response));
        }
    } catch {
        yield ShowMessage({
            type: "error",
            message: "មានបញ្ហាពេលទាញទិន្នន័យ",
            id: uuid(),
        })
    }
    finally {
        yield put(AsyncEndAction());
    }
}

function* SelectEquipmentType() {
    yield put(AsyncStartAction());
    try {
        const url = ApiEndpoint.EQUIPMENT_TYPE_PATH;
        const payload = ApiEndpoint.makeApiPayload(url, GET, {});
        const response = yield call(Request, payload);
        if (response && !response.status && !response.snack) {
            yield put(SelectEquipmentTypeSuccessAction(response));
        }
    } catch {
        yield ShowMessage({
            type: "error",
            message: "មានបញ្ហាពេលទាញទិន្នន័យ",
            id: uuid(),
        })
    }
    finally {
        yield put(AsyncEndAction());
    }
}

function* CreateEquipmentType() {
    yield put(AsyncStartAction());
    try {
        const model = yield select(MakeEquipmentTypeValueSelector());
        const url = ApiEndpoint.EQUIPMENT_TYPE_PATH;
        const payload = ApiEndpoint.makeApiPayload(url, POST, model);
        const response = yield call(Request, payload);
        if (response && !response.status && !response.snack) {
            yield put(SetEquipmentTypeStatusAction(1));
            yield ShowMessage({
                type: "success",
                message: "ទិន្នន័យបានរក្សារទុក",
                id: uuid(),
            })
        }
    } catch {
        yield put(SetEquipmentTypeStatusAction(2));
        yield ShowMessage({
            type: "error",
            message: "មានបញ្ហាពេលទាញទិន្នន័យ",
            id: uuid(),
        })
    }
    finally {
        yield put(AsyncEndAction());
    }
}

function* EditEquipmentType() {
    yield put(AsyncStartAction());
    try {
        const model = yield select(MakeEquipmentTypeValueSelector());
        const url = ApiEndpoint.EQUIPMENT_TYPE_PATH;
        const payload = ApiEndpoint.makeApiPayload(url, PUT, model);
        const response = yield call(Request, payload);
        if (response && !response.status && !response.snack) {
            yield ShowMessage({
                type: "success",
                message: "ទិន្នន័យបានរក្សារទុក",
                id: uuid(),
            })
            yield put(SetEquipmentTypeStatusAction(1));
        }
    } catch {
        yield put(SetEquipmentTypeStatusAction(2));
        yield ShowMessage({
            type: "error",
            message: "មានបញ្ហាពេលទាញទិន្នន័យ",
            id: uuid(),
        });
    }
    finally {
        yield put(AsyncEndAction());
    }
}

function* DeleteEquipmentType() {
    yield put(AsyncStartAction());
    try {
        const id = yield select(MakeEquipmentTypeIdSelector());
        const url = `${ApiEndpoint.EQUIPMENT_TYPE_PATH}/${id}`;
        const payload = ApiEndpoint.makeApiPayload(url, DELETE, id);
        const response = yield call(Request, payload);
        if (!response.status && !response.snack) {
            yield put(SetEquipmentTypeStatusAction(3));
            yield ShowMessage({
                type: "success",
                message: "ទិន្នន័យបានលុប",
                id: uuid(),
            })
        }
    } catch {
        yield ShowMessage({
            type: "error",
            message: "មានបញ្ហាពេលទាញទិន្នន័យ",
            id: uuid(),
        });
    }
    finally {
        yield put(AsyncEndAction());
    }
}

export default function* EquipmentTypeSaga() {
    yield all([
        yield takeLatest(LIST_EQUIPMENT_TYPE_REQUEST, ListEquipmentType),
        yield takeLatest(GET_EQUIPMENT_TYPE_REQUEST, GetEquipmentType),
        yield takeLatest(SELECT_EQUIPMENT_TYPE_REQUEST, SelectEquipmentType),

        yield takeLatest(CREATE_EQUIPMENT_TYPE_REQUEST, CreateEquipmentType),
        yield takeLatest(EDIT_EQUIPMENT_TYPE_REQUEST, EditEquipmentType),
        yield takeLatest(DELETE_EQUIPMENT_TYPE_REQUEST, DeleteEquipmentType),
    ]);
}