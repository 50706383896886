import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useInjectReducer } from "Utility/InjectReducer";
import { useInjectSaga } from "Utility/InjectSaga";

import {
    Form,
    Table
} from "antd";
import { Link } from "react-router-dom";
import { FullLayout } from "Components/Layouts/Inner/Full.Layout";
import { HelmetHeader } from "Components/Helmet-Header/index";
import { ArchiveIcon } from "@heroicons/react/outline";
import { CertifyLotteryGameTypeFilterForm } from "Modules/Certificate/Lottery/Certify/GameTypeEquipment/Forms/CertifyLotteryGameTypeFilterForm";

import LotteryCertifyReducer from "Modules/Certificate/Lottery/Certify/Reducer";
import LotteryCertifySaga from "Modules/Certificate/Lottery/Certify/Saga";
import { MakeLotteryCertifyListSelector } from "Modules/Certificate/Lottery/Certify/Selector";
import {
    ListLotteryCertifyRequestAction,
    ListLotteryCertifySuccessAction,
    SetLotteryCertifyFilterValueAction
} from "Modules/Certificate/Lottery/Certify/Actions";

const key = "license";
export const LotteryLicenseList = ({ form }: any) => {
    useInjectReducer({ key, reducer: LotteryCertifyReducer });
    useInjectSaga({ key, saga: LotteryCertifySaga });

    // fields
    const dispatch = useDispatch();
    const lotteryCertifies = useSelector(MakeLotteryCertifyListSelector());

    const lotteryCertifyColumns = [
        {
            key: "code",
            title: "លេខអាជ្ញាបណ្ណ",
            render: (item: any) => <Link to={`/certificate/lottery/certify-game-type/${item.id}`} className="underline text-blue-600">{item.code}</Link>
        },
        {
            key: "licenseIssuedDate",
            title: "ថ្ងៃផ្តល់ឲ្យ",
            dataIndex: "licenseIssuedDate",
        },
        {
            key: "licenseExpiredDate",
            title: "ថ្ងៃផុតសុពលភាព",
            dataIndex: "licenseExpiredDate",
        },
        {
            key: "name",
            title: "ឈ្មោះប្រតិបត្តិករ",
            dataIndex: "name",
        },
    ]

    // constructors
    useEffect(() => {

        return () => {
            dispatch(SetLotteryCertifyFilterValueAction(null));
            dispatch(ListLotteryCertifySuccessAction([]));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // events
    const onSubmit = (values: any) => {
        dispatch(SetLotteryCertifyFilterValueAction(values));
        dispatch(ListLotteryCertifyRequestAction());
    }

    return (
        <>
            <FullLayout
                icon={<ArchiveIcon />}
                title="បញ្ជីអាជ្ញាបណ្ណល្បែងផ្សងសំណាង"
                descriptions=""
                actions={<></>}
            >
                <HelmetHeader meta="បញ្ជី" />
                <Form onFinish={onSubmit} name="certifyLotteryGameTypeFilterForm" layout="vertical" className="flex flex-row items-end gap-2 p-5">
                    <CertifyLotteryGameTypeFilterForm />
                </Form>
                <Table rowKey="id" columns={lotteryCertifyColumns} size="small" dataSource={lotteryCertifies || []} />

            </FullLayout>
        </>
    )


}