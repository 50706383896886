import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useInjectReducer } from "Utility/InjectReducer";
import { useInjectSaga } from "Utility/InjectSaga";

import {
    Tabs,
    Form,
    Row,
    Col,
    Input,
    Select,
    Space,
    DatePicker,
    Radio
} from "antd";

import OperatorCasinoReducer from "Modules/Entity/Operator/Reducer";
import OperatorCasinoSaga from "Modules/Entity/Operator/Saga";

import MakeSelectCasinoLicensePage from "Modules/License/Casino/Selectors";
import {
    GetCasinoModalDetailAction,
    SelectCasinoRequestAction,
    SelectCasinoSuccessAction,
    SetCasinoIdAction,
    GetEntityCasinoByIdSuccessAction
} from "Modules/Entity/Operator/Actions";
import { MakeCasinoSelector, MakeSelectCasinoSelector } from "Modules/Entity/Operator/Selectors";

const operatorKey = "entityCasino";
export const CertifyLotteryGameTypeEquipmentCreateForm = ({ form }: any) => {
    useInjectReducer({ key: operatorKey, reducer: OperatorCasinoReducer });
    useInjectSaga({ key: operatorKey, saga: OperatorCasinoSaga });

    //fields
    const dispatch = useDispatch();
    const { identificationTypes } = useSelector(MakeSelectCasinoLicensePage());
    const operatorOptions = useSelector(MakeSelectCasinoSelector());
    const operatorDetail = useSelector(MakeCasinoSelector());

    // cosntructors
    useEffect(() => {
        dispatch(SelectCasinoRequestAction());
        return () => {
            dispatch(SelectCasinoSuccessAction([]));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {

        if (operatorDetail) {

            const model = {

                formData: {
                    casino: operatorDetail.casino,
                    company: operatorDetail.company,
                }
            }
            form.setFieldsValue(model);
        }
        return () => {
            dispatch(GetEntityCasinoByIdSuccessAction(null));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [operatorDetail])

    // events
    const onOperatorChange = (e: any) => {

        const id = e;
        dispatch(SetCasinoIdAction(id));
        dispatch(GetCasinoModalDetailAction(null));
    }
    return (
        <>
            <Form.Item name="code" hidden>
                <Input />
            </Form.Item>
            <Form.Item name="referenceId" hidden>
                <Input />
            </Form.Item>
            <Tabs defaultActiveKey="1"
                items={[
                    {
                        key: "1",
                        label: "ព័ត៌មានអាជ្ញាបណ្ណ",
                        forceRender: true,
                        children: <>
                            <Row>

                                <Col span={6}>
                                    លេខកូដល្បែងផ្សងសំណាង <br />
                                    Lottery Code
                                </Col>
                                <Col span={18}>
                                    <Form.Item
                                        name="casinoId"
                                        rules={[{ required: true }]}
                                    >
                                        <Select
                                            placeholder="នាមករណ៍ល្បែងផ្សងសំណាងជាអក្សរខ្មែរ / Lottery name in Khmer"
                                            onChange={onOperatorChange}
                                            filterOption={(
                                                input,
                                                option
                                            ) =>
                                                option!.children
                                                    .toString()
                                                    .toLowerCase()
                                                    .includes(
                                                        input.toLowerCase()
                                                    )
                                            }
                                            showSearch>
                                            {
                                                operatorOptions && operatorOptions.map((operator: any) =>
                                                    <Select.Option key={operator.value} value={operator.value}>{operator.label}</Select.Option>)
                                            }
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    នាមករណ៍ក្រុមហ៊ុន <br />
                                    Name of Company
                                </Col>
                                <Col span={18}>
                                    <Form.Item name={["formData", "company", "name"]}>
                                        <Input placeholder="" />
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    នាមករណ៍ល្បែងផ្សងសំណាងជាអក្សរខ្មែរ <br />
                                    Lottery name in Khmer
                                </Col>
                                <Col span={18}>
                                    <Form.Item
                                        name={["formData", "casino", "name"]}
                                        rules={[{ required: true }]}
                                    >
                                        <Input

                                            placeholder="នាមករណ៍ល្បែងផ្សងសំណាងជាអក្សរខ្មែរ / Casino name in Khmer"
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    អាសយដ្ឋាន <br /> Address
                                </Col>
                                <Col span={18}>
                                    <Form.Item
                                        name={["formData", "casino", "address"]}
                                    >
                                        <Input placeholder="អាសយដ្ឋាន / Address" />
                                    </Form.Item>
                                </Col>

                                <Col span={6}>
                                    លេខអាជ្ញាបណ្ណល្បែងផ្សងសំណាង <span className="text-red-600">*</span>
                                    <br />
                                    Lottery License Number
                                </Col>
                                <Col span={18}>
                                    <Form.Item
                                        name="casinoLicenseNumber"
                                    >
                                        <Input autoComplete="off" />
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    ថ្ងៃផ្តល់អាជ្ញាបណ្ណ
                                    <br />
                                    Issued date of License
                                </Col>
                                <Col span={6}>
                                    <Form.Item name="licenseIssuedDate">
                                        <DatePicker
                                            placeholder="ថ្ងៃផ្តល់អាជ្ញាបណ្ណ
                                    /
                                    Issued date of License"
                                        />
                                    </Form.Item>
                                </Col>

                                <Col span={6}>
                                    សុពលភាពអាជ្ញាបណ្ណ
                                    <br />
                                    Expired date of License
                                </Col>
                                <Col span={6}>
                                    <Form.Item name="licenseExpiredDate">
                                        <DatePicker placeholder="សុពលភាពអាជ្ញាបណ្ណ / Expired date of License" />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </>
                    },
                    {
                        key: "2",
                        label: "ព័ត៌មានរបស់បុគ្គលឈរឈ្មោះក្នុងអាជ្ញាបណ្ណ",
                        // forceRender: true,
                        children: <>
                            <Row>
                                <Col span={6}>
                                    គោត្តនាម និងនាម
                                    <br />
                                    Last name and First name
                                </Col>
                                <Col span={18}>
                                    <Row>
                                        <Col span={8}>
                                            អក្សរខ្មែរ <br />
                                            in Khmer
                                        </Col>
                                        <Col span={16}>
                                            <Form.Item
                                                name={[
                                                    "formData",
                                                    "representative",
                                                    "khmerName",
                                                ]}
                                            >
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                        <Col span={8}>
                                            អក្សរឡាតាំង <br />
                                            in Latin
                                        </Col>
                                        <Col span={16}>
                                            <Form.Item
                                                name={[
                                                    "formData",
                                                    "representative",
                                                    "englishName",
                                                ]}
                                            >
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>

                                <Col span={6}>
                                    ភេទ
                                    <br />
                                    Sex
                                </Col>
                                <Col span={18}>
                                    <Form.Item
                                        name={[
                                            "formData",
                                            "representative",
                                            "gender",
                                        ]}
                                    >
                                        <Radio.Group>
                                            <Space direction="horizontal">
                                                <Radio value="male">
                                                    ប្រុស / Male
                                                </Radio>
                                                <Radio value="female">
                                                    ស្រី / Female
                                                </Radio>
                                            </Space>
                                        </Radio.Group>
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    ថ្ងៃខែឆ្នាំកំណើត
                                    <br />
                                    Date of Birth
                                </Col>
                                <Col span={18}>
                                    <Form.Item
                                        name={[
                                            "formData",
                                            "representative",
                                            "dateOfBirth",
                                        ]}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    សញ្ជាតិ
                                    <br />
                                    Nationality
                                </Col>
                                <Col span={18}>
                                    <Form.Item
                                        name={[
                                            "formData",
                                            "representative",
                                            "nationality",
                                        ]}
                                    >
                                        <Input placeholder="សញ្ជាតិ / Nationality" />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item
                                        name={[
                                            "formData",
                                            "representative",
                                            "identityTypeId",
                                        ]}
                                    >
                                        <Radio.Group>
                                            <Space direction="vertical">
                                                {identificationTypes &&
                                                    identificationTypes.map(
                                                        (
                                                            identificationType: any,
                                                            index: number
                                                        ) => (
                                                            <Radio
                                                                key={
                                                                    identificationType.value
                                                                }
                                                                value={
                                                                    identificationType.value
                                                                }
                                                            >
                                                                {
                                                                    identificationType.label
                                                                }
                                                            </Radio>
                                                        )
                                                    )}
                                            </Space>
                                        </Radio.Group>
                                    </Form.Item>
                                </Col>
                                <Col span={16}>
                                    <Form.Item
                                        name={[
                                            "formData",
                                            "representative",
                                            "identityNumber",
                                        ]}
                                    >
                                        <Input placeholder="សូមបំពេញ" />
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    ថ្ងៃខែឆ្នាំផ្តល់ / Issued Date
                                </Col>
                                <Col span={18}>
                                    <Form.Item
                                        name={[
                                            "formData",
                                            "representative",
                                            "issuedDate",
                                        ]}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    ថ្ងៃខែឆ្នាំផុតកំណត / Date of Expiry
                                </Col>
                                <Col span={18}>
                                    <Form.Item
                                        name={[
                                            "formData",
                                            "representative",
                                            "expiredDate",
                                        ]}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    តួនាទីក្នុងក្រុមហ៊ុន / Position in
                                    Company
                                </Col>
                                <Col span={18}>
                                    <Form.Item
                                        name={[
                                            "formData",
                                            "representative",
                                            "designatureId",
                                        ]}
                                    >
                                        <Input placeholder="តួនាទីក្នុងក្រុមហ៊ុន / Position in Company" />
                                    </Form.Item>
                                </Col>
                                <Col span={6}>អាសយដ្ឋាន / Address</Col>
                                <Col span={18}>
                                    <Form.Item
                                        name={[
                                            "formData",
                                            "representative",
                                            "address",
                                        ]}
                                    >
                                        <Input placeholder="អាសយដ្ឋាន / Address" />
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    ទូរស័ព្ទចល័ត / Mobile Phone Number
                                </Col>
                                <Col span={18}>
                                    <Form.Item
                                        name={[
                                            "formData",
                                            "representative",
                                            "mobilePhoneNumber",
                                        ]}
                                    >
                                        <Input placeholder="ទូរស័ព្ទចល័ត / Mobile Phone Number" />
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    សារអេឡិចត្រូនិច / Email Address
                                </Col>
                                <Col span={18}>
                                    <Form.Item
                                        name={[
                                            "formData",
                                            "representative",
                                            "emailAddress",
                                        ]}
                                    >
                                        <Input placeholder="សារអេឡិចត្រូនិច / Email Address" />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </>
                    }
                ]}
            />
        </>
    );
}