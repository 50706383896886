import { useSelector } from "react-redux";
import { Form, Input, Select, Button } from "antd";

import { MakeSelectGameTypeSelector } from "Modules/Settings/GameType/Selectors";
import { useInjectReducer } from "Utility/InjectReducer";
import Reducer from "Modules/Settings/GameType/Reducer";
const key = "gameType";
export const GameRuleFilterForm = ({ form }: any) => {
    useInjectReducer({ key: key, reducer: Reducer });
    // fields

    const gameTypes = useSelector(MakeSelectGameTypeSelector());

    return (
        <Form
            name="gameRuleFilterForm"
            form={form}
            layout="vertical"
            className="p-4 w-full grid grid-cols-4 items-end gap-2"
            initialValues={{ search: "" }}
        >
            <Form.Item name="search" label="វិធានល្បែង">
                <Input />
            </Form.Item>
            <Form.Item name="gameTypeId" label="ប្រភេទល្បែង">
                <Select allowClear={true} showSearch>
                    {gameTypes &&
                        gameTypes.map((item) => (
                            <Select.Option
                                key={`${item.id}`}
                                value={item.id}
                            >
                                {item.name}
                            </Select.Option>
                        ))}
                </Select>
            </Form.Item>
            <Form.Item>
                <Button htmlType="submit" type="default">
                    ស្វែងរក
                </Button>
            </Form.Item>
        </Form>
    );
};
