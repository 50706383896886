import produce from "immer";
import {
    SET_EQUIPMENT_BINDING_VALUE,
    CREATE_EQUIPMENT_BINDING_SUCCESS,
    SET_EQUIPMENT_BINDING_UPLOAD_VALUE,
    SET_UPLOAD_EQUIPMENT_BINDING_STATUS,
    UPLOAD_EQUIPMENT_BINDING_SUCCESS,
    UPLOAD_EQUIPMENT_BINDING_ERROR,
    SET_EQUIPMENT_BINDING_VERIFY_VALUE,
    VERIFY_EQUIPMENT_BINDING_SUCCESS
} from "Modules/Equipments/Casino/Binding/Constants";


export const EquipmentBindingInitialState: any = {
    status: 0,
    equipmentValue: null,
    fileStatus: 0,
    file: null,
    verifyEquipmentId: null,
    equipmentBinding: null,
    verifyEquipmentStatus: 0,
}

const EquipmentBindingReducer = produce((draft, action) => {
    switch (action.type) {
        case SET_EQUIPMENT_BINDING_VALUE:
            draft.equipmentValue = action.values;
            break;
        case SET_EQUIPMENT_BINDING_UPLOAD_VALUE:
            draft.file = action.file;
            break;
        case SET_UPLOAD_EQUIPMENT_BINDING_STATUS:
            draft.fileStatus = action.fileStatus;
            break;
        case UPLOAD_EQUIPMENT_BINDING_ERROR:
            draft.fileStatus = 2;
            break;
        case UPLOAD_EQUIPMENT_BINDING_SUCCESS:
            draft.file = action.file;
            draft.fileStatus = 1;
            break;
        case SET_EQUIPMENT_BINDING_VERIFY_VALUE:
            draft.verifyEquipmentId = action.values;
            break;
        case VERIFY_EQUIPMENT_BINDING_SUCCESS:
            draft.equipmentBinding = action.equipments;
            draft.verifyEquipmentStatus = 1;
            break;
        default: break;
    }
}, EquipmentBindingInitialState);

export default EquipmentBindingReducer;