import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useInjectReducer } from "Utility/InjectReducer";
import { useInjectSaga } from "Utility/InjectSaga";
import { v4 as uuid } from "uuid";

import type { RcFile } from 'antd/es/upload/interface';
import {
    Button,
    Form,
    Input,
    Select,
    Upload,
    Slider,

    QRCode,

} from "antd";

import EquipmentTypeReducer from "Modules/Settings/EquipmentType/Reducer";
import EquipmentTypeSaga from "Modules/Settings/EquipmentType/Saga";
import {
    SelectEquipmentTypeRequestAction,
    SelectEquipmentTypeSuccessAction
} from "Modules/Settings/EquipmentType/Actions";
import { MakeEquipmentTypeOptionsSelector } from "Modules/Settings/EquipmentType/Selectors";
import { SetFileValueAction, SetUploadStatusAction, UploadFileRequestAction, UploadFileSuccessAction } from "Modules/Application/Actions";
import { MakeFileModelSelector, MakeUploadStatusSelector } from "Modules/Application/Selectors";

interface Point {
    width: number;
    height: number;
    x: number;
    y: number;
    serial: number;
}
const equipmentKey = "equipmentType";
export const InspectionCertificateTypeForm = ({ form, setCertificateFile }: any) => {
    useInjectReducer({ key: equipmentKey, reducer: EquipmentTypeReducer });
    useInjectSaga({ key: equipmentKey, saga: EquipmentTypeSaga });

    // const 
    const dispatch = useDispatch();
    const equipmentTypeOptions = useSelector(MakeEquipmentTypeOptionsSelector());
    const uploadStatus = useSelector(MakeUploadStatusSelector());
    const fileModel = useSelector(MakeFileModelSelector());
    const id = Form.useWatch("id", form);

    const [certificatePreview, setCertificatePreview] = useState<any>(null);
    const [position, setPosition] = useState<Point>({
        width: 100,
        height: 100,
        x: 1,
        y: 1,
        serial: 10
    });

    // constructors
    useEffect(() => {
        dispatch(SelectEquipmentTypeRequestAction());

        return () => {
            dispatch(SelectEquipmentTypeSuccessAction([]));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const model = form.getFieldsValue();
        const printSamples = model?.printSamples ? JSON.parse(model?.printSamples ) : null;
        if (printSamples) {
            setCertificatePreview(printSamples.url);
        }
        setPosition({
            width: model.printWidth,
            height: model.printWidth,
            serial: 0,
            x: model.x,
            y: model.y,
        });

        // return () => {
        //     dispatch(SelectEquipmentTypeSuccessAction([]));
        // }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])
    useEffect(() => {
        switch (uploadStatus) {
            case 1:

                form.setFieldValue("printSamples", JSON.stringify(fileModel));
                dispatch(UploadFileSuccessAction(null));
                dispatch(SetUploadStatusAction(0));
                break;
            case 2:
                dispatch(SetUploadStatusAction(0));
                break;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uploadStatus])

    const onXChange = (type: string, value: number) => {

        const model = {
            ...position
        };
        switch (type) {
            case "Width":
                model.width = value;
                break;
            case "Height":
                model.height = value;
                break;
            case "X":
                model.x = value;
                break;
            case "Y":
                model.y = value;
                break;

            case "Serial":
                model.serial = value;
                break;
        }
        setPosition(model);
    }

    const onBeforeUpload = (file: any) => {

        var extension = file.name.split('.').pop();
        const model = {
            fileName: `${uuid()}.${extension}`,
            uuid: file.uid,
        };
        const formData = new FormData();
        for (const key of Object.keys(model)) {
            formData.append(key, model[key]);
        }
        formData.append("File", file as RcFile);
        // setFileUuid(file.uuid);
        dispatch(SetFileValueAction(formData));
        dispatch(UploadFileRequestAction());

        setCertificateFile(file);
        file.arrayBuffer()
            .then(buffer => {
                const blob = new Blob([buffer]);
                const url = URL.createObjectURL(blob);
                // const img = document.getElementById('img');
                setCertificatePreview(url);
            });

        return false;

    };

    return (
        <>
            <Form
                name="inspectionCertificateTypeForm"
                layout="vertical"
                className="w-full flex flex-col p-5 "
                form={form}
                initialValues={
                    {
                        id: null,
                        descriptions: "",
                        equipmentTypeName: "",
                        x: position.x,
                        y: position.y,
                        printWidth: position.width,
                        printHeight: position.height,
                        printSamples: "",
                    }
                }
            >
                <Form.Item name="id" hidden>
                    <Input />
                </Form.Item>
                <Form.Item name="state" hidden>
                    <Input />
                </Form.Item>
                <Form.Item name="printSamples" hidden>
                    <Input />
                </Form.Item>
                <div className="grid grid-cols-3 gap-2">
                    <Form.Item name="code" label="Code">
                        <Input />
                    </Form.Item>
                    <Form.Item name="name" label="Name">
                        <Input />
                    </Form.Item>
                    <Form.Item name="equipmentType" label="Equipment Type">
                        <Select showSearch labelInValue>
                            {
                                equipmentTypeOptions && equipmentTypeOptions.map((equipmentType: any) => <Select.Option key={equipmentType.id} value={equipmentType.id}>{equipmentType.name}</Select.Option>)
                            }
                        </Select>
                    </Form.Item>
                </div>
                <div className="w-full grid grid-cols-2 gap-2">
                    <Upload
                        multiple={false}
                        beforeUpload={onBeforeUpload}
                    >
                        <Button>Upload</Button>
                    </Upload>
                    <div className="">
                        <Form.Item name="printWidth" label="Width">
                            <Slider
                                min={1}
                                max={874}
                                onChange={(value: number) => onXChange("Width", value)}
                            />
                        </Form.Item>

                        <Form.Item name="x" label="X">
                            <Slider
                                min={1}
                                max={874}
                                onChange={(value: number) => onXChange("X", value)}
                            />
                        </Form.Item>
                        <Form.Item name="y" label="Y">
                            <Slider
                                min={1}
                                max={1240}
                                onChange={(value: number) => onXChange("Y", value)}
                            />
                        </Form.Item>
                        <Form.Item name="serial" label="Serial">
                            <Slider
                                min={1}
                                max={1240}
                                onChange={(value: number) => onXChange("Serial", value)}
                            />
                        </Form.Item>
                    </div>
                </div>
                <div className="inspection-certificate-print-preview "
                    style={{
                        backgroundImage: `url(${certificatePreview})`
                    }}>
                    <QRCode className={`relative `} value="Testing"
                        size={position.width}
                        style={{
                            top: position.y,
                            left: position.x,
                        }}
                    />
                    <span
                        className="relative block text-center"
                        style={{
                            top: position.y + position.serial,
                            left: position.x,
                            width: position.width
                        }}
                    >
                        SN022300000123
                    </span>

                </div>
            </Form>
        </>)
}