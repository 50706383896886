import { useState } from "react";
import { useDispatch } from "react-redux";
import { useInjectSaga } from "Utility/InjectSaga";
import { useInjectReducer } from "Utility/InjectReducer";
import Reducer from "Modules/Entity/Company/Reducer";
import Saga from "Modules/Entity/Company/Saga";
import { Button, Form } from "antd";
import { FullLayout } from "Components/Layouts/";
import { HelmetHeader } from "Components/Helmet-Header";
import { CompanyForm } from "Modules/Entity/Company/Create/Forms/CompanyForm";
import { CapitalShareModal } from "Modules/License/Casino/Request/Modal/CapitalShareModal";
import { BoardOfDirectorModal } from "Modules/License/Casino/Request/Modal/BoardOfDirectorModal";
import {
    CreateEntityCompanyRequestAction,
    SetCompanyValueAction
} from "Modules/Entity/Company/Actions";

const key = "entityCompany";
export const CompanyCreate = () => {
    useInjectReducer({ key, reducer: Reducer });
    useInjectSaga({ key, saga: Saga });
    //fields
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [boardOfDirectorVisibility, setBoardOfDirectorVisibility] =
        useState(false);
    const [capitalShareVisibility, setCapitalShareVisibility] = useState(false);

    // method
    const ActionButton = () => {
        return (
            <Button type="primary" onClick={() => form.submit()}>
                រក្សាទុក
            </Button>
        );
    };
    // events
    const onFormProviderSubmit = (name: string, { forms, values }: any) => {
        const { entityCompanyListForm } = forms;
        switch (name) {
            case "entityCompanyListForm": {
                onSubmit(values);
                break;
            }
            case "capitalShareForm":
                onCapitalSubmit({ form: entityCompanyListForm, values });
                break;
            case "boardOfDirectorForm":
                onBoardOfDireactorSubmit({
                    form: entityCompanyListForm,
                    values,
                });
                break;
        }
    };

    const onSubmit = async (values: any) => {
        const valuesData = {
            companyData: {
                boardOfDirectors: [],
            },
            address: "",
            ...values,
        };
        valuesData.companyData.boardOfDirectors =
            form.getFieldValue("boardOfDirectors");

        await dispatch(SetCompanyValueAction(valuesData));
        await dispatch(CreateEntityCompanyRequestAction());
    };

    const onCapitalSubmit = ({ form, values }: any) => {
        const capitalShares = form.getFieldValue("captialShares") || [];

        form.setFieldsValue({
            capitalShares: [...capitalShares, values],
        });
        setCapitalShareVisibility(false);
    };
    const onBoardOfDireactorSubmit = ({ form, values }: any) => {
        const boardOfDirectors = form.getFieldValue("boardOfDirectors") || [];

        form.setFieldsValue({
            boardOfDirectors: [...boardOfDirectors, values],
        });
        setBoardOfDirectorVisibility(false);
    };

    return (
        <FullLayout
            icon={null}
            title="ព័ត៌មានកម្មសិទ្ធិករ"
            descriptions="ត្រលប់ទៅបញ្ជី"
            redirectUrl="/entity/company"
            actions={<ActionButton />}
        >
            <HelmetHeader meta="ព័ត៌មានកម្មសិទ្ធិករ" />
            <Form.Provider onFormFinish={onFormProviderSubmit}>
                <CompanyForm
                    form={form}
                    setCapitalShareVisibility={setCapitalShareVisibility}
                    setBoardOfDirectorVisibility={setBoardOfDirectorVisibility}
                />
                <CapitalShareModal
                    visible={capitalShareVisibility}
                    onCancel={() => setCapitalShareVisibility(false)}
                />
                <BoardOfDirectorModal
                    visible={boardOfDirectorVisibility}
                    onCancel={() => setBoardOfDirectorVisibility(false)}
                />
            </Form.Provider>
        </FullLayout>
    );
};
