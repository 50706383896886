import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useInjectReducer } from "Utility/InjectReducer";
import { useInjectSaga } from "Utility/InjectSaga";


import { HelmetHeader } from "Components/Helmet-Header";
import { ArchiveIcon } from "@heroicons/react/outline";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import {
    Form,
    Table,
    Modal,
    Space,
    Button,
    Popconfirm
} from "antd";
import { FullLayout } from "Components/Layouts/";
import { InspectionCertificateStatusFilterForm } from "Modules/Settings/InspectionCertificateStatus/Forms/InspectionCertificateStatusFilterForm";
import { InspectionCertificateStatusForm } from "Modules/Settings/InspectionCertificateStatus/Forms/InspectionCertificateStatusForm";

import Reducer from "Modules/Settings/InspectionCertificateStatus/Reducer";
import Saga from "Modules/Settings/InspectionCertificateStatus/Saga";
import { MakeInspectionCertificateStatusListSelector, MakeInspectionCertificateStatusStatusSelector } from "Modules/Settings/InspectionCertificateStatus/Selectors";
import {
    CreateInspectionCertificateStatusRequestAction,
    DeleteInspectionCertificateStatusRequestAction,
    EditInspectionCertificateStatusRequestAction,
    ListInspectionCertificateStatusRequestAction,
    ListInspectionCertificateStatusSuccessAction,
    SetInspectionCertificateStatusFilterValueAction,
    SetInspectionCertificateStatusIdAction,
    SetInspectionCertificateStatusStatusAction,
    SetInspectionCertificateStatusValueAction
} from "Modules/Settings/InspectionCertificateStatus/Actions";


const key = "inspectionCertificateStatus";
export const InspectionCertificateStatusList = () => {
    useInjectReducer({ key, reducer: Reducer });
    useInjectSaga({ key, saga: Saga });
    // fields
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const entities = useSelector(MakeInspectionCertificateStatusListSelector());
    const status = useSelector(MakeInspectionCertificateStatusStatusSelector());
    const [formVisible, setFormVisible] = useState<boolean>(false);

    const equipmentTypeColumn = [

        {
            key: "name",
            title: "ប្រភេទឧបករណ៍",
            dataIndex: "name",
        },
        {
            key: "descriptions",
            title: "បរិយាយ",
            dataIndex: "descriptions",
        },
        {
            key: "id",
            width: 100,
            render: (item: any) => <Space>
                <Popconfirm
                    title="Delete the item"
                    description="Are you sure to delete this item?"
                    onConfirm={() => onDeleteClicked(item)}
                    onCancel={() => console.log("Cancel")}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button type="link" className="p-2" >
                        <DeleteOutlined />
                    </Button>
                </Popconfirm>

                <Button type="link" className="p-2" onClick={() => onEditClicked(item)}>
                    <EditOutlined />
                </Button>
            </Space>
        }
    ]

    // constructor 
    useEffect(() => {
        onInspectionCertificateStatusFilterSubmit({ search: "" });
        return () => {
            dispatch(ListInspectionCertificateStatusSuccessAction([]));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {

        switch (status) {
            case 1:
                form.resetFields();
                setFormVisible(false);
                dispatch(SetInspectionCertificateStatusStatusAction(0));
                onInspectionCertificateStatusFilterSubmit({ search: "" });
                break;
            case 2:
                dispatch(SetInspectionCertificateStatusStatusAction(0));
                break;
            case 3:
                dispatch(SetInspectionCertificateStatusStatusAction(0));
                onInspectionCertificateStatusFilterSubmit({ search: "" });
                break;
            default: break;

        }

        return () => {
            dispatch(SetInspectionCertificateStatusStatusAction(0));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status])

    // methods
    const onFormProviderSubmit = (
        name: string,
        { values, forms }: any
    ) => {
        switch (name) {
            case "equipmentTypeFilterForm":
                onInspectionCertificateStatusFilterSubmit(values);
                break;
            case "equipmentTypeForm":
                onSave(values);
                break;
            default: break;
        }
    };

    const onInspectionCertificateStatusFilterSubmit = (values: any) => {
        const model = {
            filter: { ...values }
        }
        dispatch(SetInspectionCertificateStatusFilterValueAction(model));
        dispatch(ListInspectionCertificateStatusRequestAction());
    }

    const onSave = (values: any) => {
        const model = {
            ...values,
        }
        dispatch(SetInspectionCertificateStatusValueAction(model));
        if (values.id) {
            dispatch(EditInspectionCertificateStatusRequestAction());
        }
        else {
            dispatch(CreateInspectionCertificateStatusRequestAction());
        }
    }

    const onNewClicked = (e: any) => {
        form.resetFields();
        form.setFieldValue("state", 1);
        setFormVisible(true);
    }

    const onEditClicked = (item: any) => {
        const model = {
            ...item
        };
        form.setFieldsValue(model);
        setFormVisible(true);
    }

    const onDeleteClicked = (item: any) => {
        dispatch(SetInspectionCertificateStatusIdAction(item.id));
        dispatch(DeleteInspectionCertificateStatusRequestAction());
    }

    // render
    const ActionButtons = () => (
        <>
            <Button
                type="primary"
                onClick={onNewClicked}
            >
                បញ្ចូលថ្មី
            </Button>
        </>
    );

    return (
        <>
            <FullLayout
                icon={<ArchiveIcon />}
                title="ស្ថានភាពលតាបត្រ"
                descriptions=""
                actions={<ActionButtons />}
            >
                <HelmetHeader meta="ស្ថានភាពលតាបត្រ" />
                <Form.Provider onFormFinish={onFormProviderSubmit}>
                    <InspectionCertificateStatusFilterForm />
                    <Modal open={formVisible}
                        onOk={() => form.submit()}
                        onCancel={() => setFormVisible(false)}>
                        <InspectionCertificateStatusForm form={form} />
                    </Modal>
                </Form.Provider>
                <Table rowKey={"id"} size="small" columns={equipmentTypeColumn} dataSource={entities || []} />
            </FullLayout>
        </>
    );
};
