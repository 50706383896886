export const SET_REQUEST_GAME_EQUIPMENT_FILTER_VALUE =
    "SET_REQUEST_GAME_EQUIPMENT_FILTER_VALUE";
export const SET_REQUEST_GAME_EQUIPMENT_VALUE =
    "SET_REQUEST_GAME_EQUIPMENT_VALUE";
export const LIST_REQUEST_GAME_EQUIPMENT_REQUEST =
    "LIST_REQUEST_GAME_EQUIPMENT_REQUEST";
export const LIST_REQUEST_GAME_EQUIPMENT_SUCCESS =
    "LIST_REQUEST_GAME_EQUIPMENT_SUCCESS";

export const CREATE_REQUEST_GAME_EQUIPMENT_REQUEST =
    "CREATE_REQUEST_GAME_RQUIPMENT_REQUEST";

export const SET_REQUEST_GAME_EQUIPMENT_ID = "SET_REQUEST_GAME_EQUIPMENT_ID";
export const GET_REQUEST_GAME_EQUIPMENT_REQUEST =
    "GET_REQUEST_GAME_EQUIPMENT_REQUEST";
export const GET_REQUEST_GAME_EQUIPMENT_SUCCESS =
    "GET_REQUEST_GAME_EQUIPMENT_SUCCESS";
export const EDIT_REQUEST_GAME_EQUIPMENT_REQUEST =
    "EDIT_REQUEST_GAME_EQUIPMENT_REQUEST";

export const SET_CERTIFICATE_GAME_EQUIPMENT_FILTER_VALUE =
    "SET_CERTIFICATE_GAME_EQUIPMENT_FILTER_VALUE";
export const LIST_CERTIFICATE_GAME_EQUIPMENT_REQUEST =
    "LIST_CERTIFICATE_GAME_EQUIPMENT_REQUEST";
export const LIST_CERTIFICATE_GAME_EQUIPMENT_SUCCESS =
    "LIST_CERTIFICATE_GAME_EQUIPMENT_SUCCESS";
