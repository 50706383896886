import { useSelector } from "react-redux";

import { CardLink } from "Components/Toolbox/Card-Link";

import { MakePermissionSelector } from "Modules/Application/Selectors";
import { checkPermissionForComponent } from "Utility/Permission";


export const SettingDashboard = () => {
    // fields
    const permissions = useSelector(MakePermissionSelector());

    const SettingNavigation = [
        {
            name: "ប្រភេទពាក្យស្នើសុំ",
            initials: "បល",
            href: `/settings/inspection-certificate-master`,
            members: 16,
            bgColor: "bg-neutral-600",
            permissionCode: [],
        },
        {
            name: "ប្រភេទឧបករណ៍",
            initials: "បល",
            href: `/settings/equipment-type`,
            members: 16,
            bgColor: "bg-neutral-600",
            permissionCode: [],
        },
        {
            name: "ប្រភេទលតាបត្រ",
            initials: "បល",
            href: `/settings/inspection-certificate-type`,
            members: 16,
            bgColor: "bg-neutral-600",
            permissionCode: [],
        },
        {
            name: "ស្ថានភាពលតាបត្រ",
            initials: "បល",
            href: `/settings/inspection-certificate-status`,
            members: 16,
            bgColor: "bg-neutral-600",
            permissionCode: [],
        },

    ];
    return (
        <>

            <div className="p-4">
                <h2 className="text-gray-500 text-xs font-medium uppercase tracking-wide">
                    រៀបចំ
                </h2>
                <div className="mt-3 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 sm:gap-6">
                    {SettingNavigation.map((setting: any) => checkPermissionForComponent(permissions, {}, setting.permissionCode) && (

                        <div key={setting.name}>
                            <CardLink
                                name={setting.name}
                                bgColor={setting.bgColor}
                                href={setting.href}
                                initials={setting.initials}
                            />
                        </div>
                    ))}
                </div>
            </div>

        </>
    );
};
