import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useInjectReducer } from "Utility/InjectReducer";
import Reducer from "Components/SearchModal/Reducer";
import { Modal, Table } from "antd";
import { SelectedRowAction } from "Components/SearchModal/Actions";

type SearchModalType = {
    title: string;
    onClose: any;
    searchKey: string;
    visibility: boolean;
    columns: any;
    data: any;
    children: any;
    entity: string;
};

const key = "searchModal";
export const SearchModal: React.FC<SearchModalType> = ({
    title,
    onClose,
    visibility,
    columns,
    children,
    data,
    searchKey,
    entity,
}: SearchModalType) => {
    useInjectReducer({ key, reducer: Reducer });
    // fields
    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            dispatch(SelectedRowAction(null));
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    //events
    const onSelected = (item: object) => {
        dispatch(SelectedRowAction({ ...item, entity: entity }));
        onClose();
    };
    const actionColumn = [
        ...columns,
        {
            title: "ប្រតិបត្តិការណ៍",
            render: (item) => (
                <button onClick={() => onSelected(item)}>ជ្រើសរើស</button>
            ),
        },
    ];
    return (
        <>
            <Modal
                open={visibility}
                title={title}
                onCancel={onClose}
                width={1000}
                okText="យល់ព្រម"
                cancelText="បោះបង់"
                okButtonProps={{ type: "default" }}
            >
                {children}
                <Table
                    size="small"
                    dataSource={data}
                    rowKey={searchKey}
                    columns={actionColumn}
                />
            </Modal>
        </>
    );
};
