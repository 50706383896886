import React from "react";
import { useInjectSaga } from "Utility/InjectSaga";
import { useInjectReducer } from "Utility/InjectReducer";
import Reducer from "Modules/Entity/Company/Reducer";
import Saga from "Modules/Entity/Company/Saga";
import { useDispatch, useSelector } from "react-redux";
import { Form, Row, Col, Input, Button } from "antd";
import { SearchModal } from "Components/SearchModal/";

import {
    SetFormValuesAction,
    EntityCompanyListRequestAction,
} from "Modules/Entity/Company/Actions";
import { MakeSelectEntityCompanyListSelector } from "Modules/Entity/Company/Selectors";

const key = "entityCasino";
export const EntityCompanySearchModal = ({
    visibility,
    onClose,
    columns,
}: any) => {
    useInjectReducer({ key, reducer: Reducer });
    useInjectSaga({ key, saga: Saga });
    // fields
    const dispatch = useDispatch();
    const rawColumn = [
        {
            title: "លេខសម្គាល់ក្រុមហ៊ុន",
            dataIndex: "registrationNumber",
            key: "registrationNumber",
        },
        {
            title: "ឈ្មោះក្រុមហ៊ុន",
            dataIndex: "name",
            key: "name",
        },
        {
            title: "អាសយដ្ឋាន",
            dataIndex: "address",
            key: "address",
        },
    ];
    const companies = useSelector(MakeSelectEntityCompanyListSelector());

    // constructor

    const onFormProviderSubmit = async (
        name: string,
        { values, forms }: any
    ) => {
        await dispatch(SetFormValuesAction(values));
        await dispatch(EntityCompanyListRequestAction());
    };
    return (
        <Form.Provider onFormFinish={onFormProviderSubmit}>
            <SearchModal
                title="ស្វែងរកក្រុមហ៊ុន"
                visibility={visibility}
                onClose={onClose}
                columns={rawColumn}
                searchKey="id"
                data={companies}
                entity="Casino"
            >
                <Form
                    name="casinoSearchForm"
                    layout="vertical"
                    initialValues={{ code: "", name: "" }}
                >
                    <Row gutter={4}>
                        <Col span={4}>
                            <Form.Item name="code" label="លេខសម្គាល់ក្រុមហ៊ុន">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item name="name" label="ឈ្មោះក្រុមហ៊ុន">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col
                            span={4}
                            className="flex items-center align-middle"
                        >

                            <Button type="default" htmlType="submit">
                                ស្វែងរក
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </SearchModal>
        </Form.Provider>
    );
};
