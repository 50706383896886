import { useDispatch } from "react-redux";
import { HelmetHeader } from "Components/Helmet-Header";
import { ArchiveIcon } from "@heroicons/react/outline";
import { Form, Button } from "antd";
import { FullLayout } from "Components/Layouts/";

import { useInjectReducer } from "Utility/InjectReducer";
import { useInjectSaga } from "Utility/InjectSaga";
import Reducer from "Modules/Settings/GameType/Reducer";
import Saga from "Modules/Settings/GameType/Saga";

import {
    CreateGameTypeActions,
    SetFormValuesAction,
} from "Modules/Settings/GameType/Actions";
import { GameTypeForm } from "Modules/Settings/GameType/Forms/GameTypeForm";
import { useSearchParams } from "react-router-dom";

const key = "gameType";
export const Create = () => {
    useInjectReducer({ key, reducer: Reducer });
    useInjectSaga({ key, saga: Saga });
    // fields
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();
    const entityType = searchParams.get("entityType");

    // methods
    const onFormProviderSubmit = (
        name: string,
        { values, forms }: any
    ) => {
        const entityTypeId = entityType === "LOTTERY" ? 2 : 1;
        const model = {
            ...values,
            entityType: entityTypeId,
            entityTypeString: entityType,
        }
        dispatch(SetFormValuesAction(model));
        dispatch(CreateGameTypeActions());
    };

    const onAddNew = () => {
        form.submit();
    };

    // render
    const ActionButtons = () => (
        <>
            <Button
                type="primary"
                onClick={onAddNew}
            >
                រក្សាទុក
            </Button>
        </>
    );

    return (
        <>
            <FullLayout
                icon={<ArchiveIcon />}
                title={`បង្កើតប្រភេទល្បែងថ្មី`}
                descriptions="ត្រលប់ទៅបញ្ជីល្បែង"
                actions={<ActionButtons />}
                redirectUrl={`/settings/game-type?entityType=${entityType}`}
            >
                <HelmetHeader meta="ប្រភេទល្បែង" />
                <Form.Provider onFormFinish={onFormProviderSubmit}>
                    <GameTypeForm form={form} />
                </Form.Provider>
            </FullLayout>
        </>
    );
};
