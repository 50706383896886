import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NumberToKhCharacter, NumberToCharacter } from "Utility/StringHelper";
import { useInjectReducer } from "Utility/InjectReducer";
import { useInjectSaga } from "Utility/InjectSaga";
import { v4 as uuid } from "uuid";

import {
    Form,
    Tabs,
    Row,
    Col,
    Table,
    Input,
    Select,
    DatePicker,
    Radio,
    Checkbox,
    Space,
    Button,
    Typography,
    Popconfirm,
} from "antd";

import EntityOperatorReducer from "Modules/Entity/Operator/Reducer";
import EntityOperatorSaga from "Modules/Entity/Operator/Saga";
import { BoardOfDirectorType } from "Common/Constants/BoardOfDirector";
import MakeSelectCasinoLicensePage from "Modules/License/Casino/Selectors";
import { GetCasinoModalDetailAction, SelectCasinoRequestAction, SelectCasinoSuccessAction, SetCasinoIdAction } from "Modules/Entity/Operator/Actions";
import { MakeCasinoSelector, MakeSelectCasinoSelector } from "Modules/Entity/Operator/Selectors";
import { BranchType } from "../../Constants";
import { ColumnTypes } from "Common/Constants/ColumnTypes";
import { EditableCell } from "Components/Toolbox/Editable/EditableCell";

const casinoKey = "entityCasino";
export const RequestLotteryLicenseCreateForm = ({ form, setLotteryGameTypeVisible }: any) => {
    useInjectReducer({ key: casinoKey, reducer: EntityOperatorReducer });
    useInjectSaga({ key: casinoKey, saga: EntityOperatorSaga });
    // fields
    const dispatch = useDispatch();
    const operatorOptions = useSelector(MakeSelectCasinoSelector());
    const { identificationTypes } = useSelector(MakeSelectCasinoLicensePage());
    const operatorDetail = useSelector(MakeCasinoSelector());
    const [shareTypeVisibility, setShareTypeVisibility] = useState<boolean>(false);
    const [capitalShareVisibility, setCapitalShareVisibility] = useState<boolean>(false);
    const [isDifferentRepresentative, setIsDifferentRepresentative] = useState<boolean>(false);
    const [branchKey, setBranchKey] = useState<string>(null);
    const [branches, setBranches] = useState<BranchType[]>([]);

    const boardOfDirectorColumns = [
        {
            title: " ល.រ / No.",
            key: "key",
            render: (value, item, index) => index + 1,
            editable: false,
        },
        {
            title: "គោត្តនាម និងនាម / Last name and First name",
            dataIndex: "name",
            key: "name",
            editable: true,
            name: "boardOfDirectorName",
            type: "input",
        },
        {
            title: "តួនាទី / Position",
            dataIndex: "position",
            key: "position",
            editable: true,
            name: "boardOfDirectorPosition",
            type: "input",
        },
        {
            title: "សញ្ជាតិ / Nationality",
            dataIndex: "nationality",
            key: "nationality",
            editable: true,
            name: "boardOfDirectorNationality",
            type: "input",
        },
        {
            title: "អាសយដ្ឋាន / Address",
            dataIndex: "address",
            key: "address",
            editable: true,
            name: "boardOfDirectorAddress",
            type: "input",
        },
    ];

    const branchColumn = [
        {
            key: "id",
            title: "ល.រ",
            render: (value,
                item,
                index
            ) => <>{index + 1}</>
        },
        {
            key: "branchAddress",
            title: "អាសយដ្ឋានសាខា/Branch Address",
            dataIndex: "branchAddress",
            name: "branchAddress",
            editable: true,
            type: "input",
        },
        {
            key: "branchManager",
            title: "នាយក/ប្រធានសាខា/Branch Manager",
            dataIndex: "branchManager",
            editable: true,
            name: "branchManager",
            type: "input",
        },
        {
            key: "branchMobilePhone",
            title: "ទូរសព្ទចល័ត/Mobile Phone Number",
            dataIndex: "branchMobilePhone",
            name: "branchMobilePhone",
            editable: true,
            type: "input",
        },
        {
            title: "",
            dataIndex: "operation",
            render: (_: any, record: BranchType) => {
                const editable = isBranchEditing(record);
                return editable ? (
                    <span className="text-center">
                        <Typography.Link
                            onClick={() => onBranchSave(record)}
                            style={{ marginRight: 8 }}
                        >
                            រក្សាទុក
                        </Typography.Link>
                        <Popconfirm
                            title="តើអ្នកចង់បោះបង់ការកែប្រែ?"
                            onConfirm={onBranchCancel}
                        >
                            <a className="text-red-500">បោះបង់</a>
                        </Popconfirm>
                    </span>
                ) : (
                    <Space>
                        <Typography.Link
                            disabled={branchKey !== ""}
                            onClick={() => onBranchEdit(record)}
                        >
                            កែប្រែ
                        </Typography.Link><Popconfirm
                            title="តើអ្នកចង់លុប?"
                            onConfirm={() => onBranchDelete(record)}
                        >
                            <Typography.Link
                            >
                                លុប
                            </Typography.Link>
                        </Popconfirm>
                    </Space>
                );
            },
        },
    ];

    const branchActionColumns = branchColumn.map((col) => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record: BranchType) => ({
                record,
                // inputType: col.dataIndex === "age" ? "number" : "text",
                inputType: col.type,
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isBranchEditing(record),
                name: col.name,
            }),
        };
    });

    // constructors
    useEffect(() => {
        dispatch(SelectCasinoRequestAction());

        return () => {
            dispatch(SelectCasinoSuccessAction([]));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (operatorDetail) {

            const model = {
                formData: {
                    operator: {
                        address: operatorDetail.casino.address,
                    },
                    ...operatorDetail.company.companyData,
                },
                boardOfDirectors: operatorDetail.company?.companyData?.boardOfDirectors || [],
            }
            form.setFieldsValue(model);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [operatorDetail])

    // events
    const isBranchEditing = (record: BranchType) => record.branchId === branchKey;

    const onBranchNewRow = () => {

        const branchId = uuid();
        const newBranch: BranchType = {
            branchId: branchId,
            branchAddress: "",
            branchManager: "",
            branchMobilePhone: "",
        };
        form.setFieldsValue({ ...newBranch });
        setBranches([...branches, newBranch]);
        form.setFieldValue("branches", [...branches, newBranch]);
        setBranchKey(branchId);
    }

    const onBranchSave = (branch: any) => {
        const branchForm = form.getFieldsValue([
            "branchAddress",
            "branchManager",
            "branchMobilePhone",
        ]);


        const newBraches = [...branches];
        const index = newBraches.findIndex((item) => branch.branchId === item.branchId);
        newBraches[index] = {
            branchId: branch.branchId,
            ...{
                branchAddress: branchForm.branchAddress,
                branchManager: branchForm.branchManager,
                branchMobilePhone: branchForm.branchMobilePhone,
            },
        };

        setBranches(newBraches);
        form.setFieldValue("branches", newBraches);
        setBranchKey("");
    }

    const onBranchEdit = (branch: any) => {

        form.setFieldsValue({
            branchAddress: branch.branchAddress,
            branchManager: branch.branchManager,
            branchMobilePhone: branch.branchMobilePhone,
        });
        setBranchKey(branch.branchId);
    }

    const onBranchDelete = (branch: any) => {

    }

    const onBranchCancel = () => {

    }

    const onShareTypeChange = (e: any) => {

    }
    const onApplicantStatusChange = (e: any) => {

    }

    const onOperatorChange = (e: any) => {

        dispatch(SetCasinoIdAction(e));
        dispatch(GetCasinoModalDetailAction(null));
    }

    return (
        <Form form={form}
            name="requestLotteryLicenseCreateForm"
            layout="vertical"
            className="p-5"
            initialValues={{
                formData: {
                    singleShareType: 1,
                    hasBranch: true,
                    representativeType: false
                }
            }}>
            <Form.Item name="id" hidden>
                <Input />
            </Form.Item>
            <Tabs defaultActiveKey="1"
                items={[
                    {
                        key: "1",
                        label: "សម្រាប់មន្ត្រី គ.ល.ក.",
                        forceRender: true,
                        children: <>
                            <Form.Item name="code" hidden={true}>
                                <Input />
                            </Form.Item>
                            <Row gutter={16}>
                                <Col span={5}>
                                    លេខសំគាល់ពាក្យស្នើសុំ
                                </Col>
                                <Col span={7}>
                                    <Form.Item name="documentCode">
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col span={5}>ឈ្មោះអ្នកទទួល</Col>
                                <Col span={7}>
                                    <Form.Item name="receiverName">
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col span={5}>
                                    លេខកូដចំណូលកាតព្វកិច្ចពីល្បែង
                                </Col>
                                <Col span={7}>
                                    <Form.Item name="gamblingTaxCode">
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col span={5}>កាលបរិច្ឆេទទទួល</Col>
                                <Col span={7}>
                                    <Form.Item name="receivedDate">
                                        <DatePicker placeholder="កាលបរិច្ឆេទទទួល" />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </>
                    },
                    {
                        key: "2",
                        label: "ព័ត៌មានអ្នកដាក់ពាក្យស្នើសុំ",
                        forceRender: true,
                        children: <>
                            <Row>
                                <Col span={6}>
                                    តួនាទីអ្នកដាកពាក្យស្នើសុំ
                                    <br />
                                    Position of Applicant
                                </Col>
                                <Col span={18}>
                                    <Form.Item
                                        name={[
                                            "formData",
                                            "applicant",
                                            "designatureId",
                                        ]}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row>
                                <Col span={6}>
                                    គោត្តនាម និងនាម
                                    <br />
                                    Last name and First name
                                </Col>
                                <Col span={18}>
                                    <Row>
                                        <Col span={6}>
                                            អក្សរខ្មែរ
                                            <br />
                                            in Khmer
                                        </Col>
                                        <Col span={18}>
                                            <Form.Item
                                                name={[
                                                    "formData",
                                                    "applicant",
                                                    "fullname",
                                                ]}
                                                rules={[
                                                    { required: true },
                                                ]}
                                            >
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                        <Col span={6}>
                                            អក្សរឡាតាំង
                                            <br />
                                            in Latin
                                        </Col>
                                        <Col span={18}>
                                            <Form.Item
                                                name={[
                                                    "formData",
                                                    "applicant",
                                                    "latinFullname",
                                                ]}
                                                rules={[
                                                    { required: true },
                                                ]}
                                            >
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={6}>
                                    ភេទ
                                    <br />
                                    Sex
                                </Col>
                                <Col span={18}>
                                    <Form.Item
                                        name={[
                                            "formData",
                                            "applicant",
                                            "gender",
                                        ]}
                                        rules={[{ required: true }]}
                                    >
                                        <Radio.Group>
                                            <Space direction="horizontal">
                                                <Radio value={1}>
                                                    ប្រុស / Male
                                                </Radio>
                                                <Radio value={2}>
                                                    ស្រី / Female
                                                </Radio>
                                            </Space>
                                        </Radio.Group>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={6}>
                                    ថ្ងៃខែឆ្នាំកំណើត
                                    <br />
                                    Date of Birth
                                </Col>
                                <Col span={18}>
                                    <Form.Item
                                        name={[
                                            "formData",
                                            "applicant",
                                            "dateOfBirth",
                                        ]}
                                        rules={[{ required: true }]}
                                    >
                                        <DatePicker placeholder="ថ្ងៃខែឆ្នាំកំណើត" />
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    សញ្ជាតិ
                                    <br />
                                    Nationality
                                </Col>
                                <Col span={18}>
                                    <Form.Item
                                        name={[
                                            "formData",
                                            "applicant",
                                            "nationality",
                                        ]}
                                        rules={[{ required: true }]}
                                    >
                                        <Input placeholder="សញ្ជាតិ / Nationality" />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={8}>
                                    <Form.Item
                                        name={[
                                            "formData",
                                            "applicant",
                                            "identityTypeId",
                                        ]}
                                        rules={[{ required: true }]}
                                    >
                                        <Radio.Group>
                                            <Space direction="vertical">
                                                {identificationTypes &&
                                                    identificationTypes.map(
                                                        (
                                                            identificationType: any,
                                                            index: number
                                                        ) => (
                                                            <Radio
                                                                key={
                                                                    identificationType.value
                                                                }
                                                                value={
                                                                    identificationType.value
                                                                }
                                                            >
                                                                {
                                                                    identificationType.label
                                                                }
                                                            </Radio>
                                                        )
                                                    )}
                                            </Space>
                                        </Radio.Group>
                                    </Form.Item>
                                </Col>
                                <Col span={16}>
                                    <Form.Item
                                        name={[
                                            "formData",
                                            "applicant",
                                            "identityNumber",
                                        ]}
                                        rules={[
                                            {
                                                required: true,
                                                message:
                                                    "Identificaiton number is required",
                                            },
                                        ]}
                                    >
                                        <Input placeholder="សូមបំពេញ" />
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    ថ្ងៃខែឆ្នាំផ្តល់ / Issued Date
                                </Col>
                                <Col span={18}>
                                    <Form.Item
                                        name={[
                                            "formData",
                                            "applicant",
                                            "identityIssuedDate",
                                        ]}
                                        rules={[{ required: true }]}
                                    >
                                        <DatePicker />
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    ថ្ងៃខែឆ្នាំផុតកំណត / Date of Expiry
                                </Col>
                                <Col span={18}>
                                    <Form.Item
                                        name={[
                                            "formData",
                                            "applicant",
                                            "expiredDate",
                                        ]}
                                        rules={[{ required: true }]}
                                    >
                                        <DatePicker />
                                    </Form.Item>
                                </Col>

                                <Col span={6}>អាសយដ្ឋាន / Address</Col>
                                <Col span={18}>
                                    <Form.Item
                                        name={[
                                            "formData",
                                            "applicant",
                                            "address",
                                        ]}
                                        rules={[{ required: true }]}
                                    >
                                        <Input placeholder="អាសយដ្ឋាន / Address" />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={6}>
                                    ទូរស័ព្ទចល័ត / Mobile Phone Number
                                </Col>
                                <Col span={18}>
                                    <Form.Item
                                        name={[
                                            "formData",
                                            "applicant",
                                            "mobilePhoneNumber",
                                        ]}
                                        rules={[{ required: true }]}
                                    >
                                        <Input placeholder="ទូរស័ព្ទចល័ត / Mobile Phone Number" />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={6}>
                                    សារអេឡិចត្រូនិច / Email Address
                                </Col>
                                <Col span={18}>
                                    <Form.Item
                                        name={[
                                            "formData",
                                            "applicant",
                                            "emailAddress",
                                        ]}
                                        rules={[{ required: true }]}
                                    >
                                        <Input placeholder="សារអេឡិចត្រូនិច / Email Address" />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </>
                    },
                    {
                        key: "3",
                        label: "ព័ត៌មានទូទៅរបស់ក្រុមហ៊ុន",
                        forceRender: true,
                        children: <>
                            <Row>
                                <Col span={6}>
                                    ឈ្មោះក្រុមហ៊ុន / Company Name
                                </Col>
                                <Col span={18}>
                                    <Form.Item
                                        name="casinoId"
                                        rules={[{ required: true }]}
                                    >
                                        <Select showSearch
                                            onChange={onOperatorChange}
                                            filterOption={(input, option) =>

                                                option!.children.toString()
                                                    .toLowerCase()
                                                    .includes(
                                                        input.toLowerCase()
                                                    )
                                            }>
                                            {
                                                operatorOptions && operatorOptions.map(item => <Select.Option key={item.value} value={item.value}>{item.label}</Select.Option>)
                                            }

                                        </Select>
                                    </Form.Item>
                                </Col>

                                <Col span={6}>
                                    អាសយដ្ឋានក្រុមហ៊ុន / Address
                                </Col>
                                <Col span={18}>
                                    <Form.Item
                                        name={[
                                            "formData",
                                            "operator",
                                            "address",
                                        ]}
                                    >
                                        <Input
                                            placeholder="អាសយដ្ឋានក្រុមហ៊ុន / Address"
                                            status="warning"
                                            readOnly
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </>
                    },
                    {
                        key: "4",
                        label: "ព័ត៌មានដើមទុន",
                        forceRender: true,
                        children: <>
                            <Row gutter={16}>
                                <Col span={6}>
                                    ប្រភេទភាគហ៊ុន
                                    <br />
                                    Type of share
                                </Col>
                                <Col span={18}>
                                    <Row>
                                        <Col span={24}>
                                            <Row>
                                                <Col span={24}>
                                                    <Form.Item
                                                        name={[
                                                            "formData",
                                                            "singleShareType",
                                                        ]}
                                                    >
                                                        <Radio.Group
                                                            onChange={
                                                                onShareTypeChange
                                                            }
                                                        >
                                                            <Radio value={1}>
                                                                តែមួយប្រភេទ/Only one
                                                                type
                                                            </Radio>
                                                        </Radio.Group>
                                                    </Form.Item>
                                                </Col>
                                                {!shareTypeVisibility && (
                                                    <Col span={24}>
                                                        <Row>
                                                            <Col span={8}>
                                                                ចំនួនភាគហ៊ុន/Number
                                                                of share
                                                            </Col>
                                                            <Col span={16}>
                                                                <Form.Item
                                                                    name={[
                                                                        "formData",
                                                                        "singleShareTypeValue",
                                                                    ]}
                                                                >
                                                                    <Input
                                                                        status="warning"
                                                                        readOnly
                                                                    />
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                )}
                                            </Row>
                                        </Col>
                                        <Col span={24}>
                                            <Row>
                                                <Col span={24}>
                                                    <Form.Item
                                                        name={[
                                                            "formData",
                                                            "singleShareType",
                                                        ]}
                                                    >
                                                        <Radio.Group
                                                            onChange={
                                                                onShareTypeChange
                                                            }
                                                        >
                                                            <Radio value={2}>
                                                                ច្រើនជាងមួយ/More
                                                                than one type
                                                            </Radio>
                                                        </Radio.Group>
                                                    </Form.Item>
                                                    {shareTypeVisibility && (
                                                        <Button
                                                            type="link"
                                                            onClick={() =>
                                                                setCapitalShareVisibility(
                                                                    true
                                                                )
                                                            }
                                                        >
                                                            បន្ថែមថ្មី
                                                        </Button>
                                                    )}
                                                </Col>
                                                <Col span={12}>
                                                    <Form.Item
                                                        shouldUpdate={(
                                                            preValues,
                                                            curValues
                                                        ) =>
                                                            preValues !== curValues
                                                        }
                                                    >
                                                        {({
                                                            getFieldValue,
                                                        }: any) => {
                                                            const capitalShares =
                                                                getFieldValue(
                                                                    "capitalShares"
                                                                ) || [];

                                                            return (
                                                                <Row gutter={16}>
                                                                    {capitalShares.length >
                                                                        0 &&
                                                                        capitalShares.map(
                                                                            (
                                                                                capitalShare: any,
                                                                                index: number
                                                                            ) => (
                                                                                <Col
                                                                                    className="py-4"
                                                                                    key={
                                                                                        index
                                                                                    }
                                                                                    span={
                                                                                        12
                                                                                    }
                                                                                >{`${NumberToKhCharacter(
                                                                                    index +
                                                                                    1
                                                                                )} ចំនួន/ ${NumberToCharacter(
                                                                                    index +
                                                                                    1
                                                                                )} Number: ${capitalShare.value
                                                                                    }`}</Col>
                                                                            )
                                                                        )}
                                                                </Row>
                                                            );
                                                        }}
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col span={6}>
                                    ដើមទុនចុះបញ្ជី (ជាប្រាក់រៀល)
                                    <br />
                                    Registerd Capital (KHR)
                                </Col>
                                <Col span={18}>
                                    <Row>
                                        <Col span={6}>ជាលេខ / In Number</Col>
                                        <Col span={18}>
                                            <Form.Item
                                                name={["formData", "capital"]}
                                            >
                                                <Input status="warning" readOnly />
                                            </Form.Item>
                                        </Col>
                                        <Col span={6}>ជាអក្សរ / In word</Col>
                                        <Col span={18}>
                                            <Form.Item
                                                name={["formData", "capitalInWord"]}
                                            >
                                                <Input status="warning" readOnly />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col span={6}>
                                    តម្លៃចារឹកលើប័ណ្ណ (ជាប្រាក់រៀល)
                                    <br />
                                    Par Value (KHR/Share)
                                </Col>
                                <Col span={18}>
                                    <Form.Item name={["formData", "parValue"]}>
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    សញ្ជាតិរបស់បណ្តាម្ចាស់ហ៊ុនដែលមានសិទ្ធិបោះឆ្នោតសរុបច្រើនជាងគេ
                                    <br />
                                    nationality of Total Majority Shareholder in
                                    voting right
                                </Col>
                                <Col span={18}>
                                    <Row>
                                        <Col span={14}>
                                            <Form.Item
                                                name={["formData", "mostCapital"]}
                                            >
                                                <Radio.Group>
                                                    <Space direction="horizontal">
                                                        <Radio value="khmer">
                                                            ខ្មែរ / Khmer
                                                        </Radio>
                                                        <Radio value="foreign">
                                                            បរទេស / Foreign
                                                        </Radio>
                                                    </Space>
                                                </Radio.Group>
                                            </Form.Item>
                                        </Col>
                                        <Col span={10}>
                                            <Form.Item
                                                name={[
                                                    "formData",
                                                    "mostCapitalValue",
                                                ]}
                                                label="ចំនួនភាគរយនៃហ៊ុនសរុប"
                                            >
                                                <Input
                                                    addonAfter="%"
                                                    status="warning"
                                                    readOnly
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>
                                {/* Components of business capital */}
                                <Col span={6}>
                                    សមាសធាតិដើមទុនធ្វើអាជីវកម្ម(ជាប្រាក់រៀល)
                                    <br />
                                    nationality of Total Majority Shareholder in
                                    voting right
                                </Col>
                                <Col span={18}>
                                    <Row>
                                        <Col span={14}>
                                            <Form.Item
                                                name={[
                                                    "formData",
                                                    "isCurrentAsset",
                                                ]}
                                                valuePropName="checked"
                                            >
                                                <Checkbox>
                                                    ទ្រព្យសម្បត្តិចរន្ត / Current
                                                    Assets
                                                </Checkbox>
                                            </Form.Item>
                                        </Col>
                                        <Col span={10}>
                                            <Form.Item
                                                name={[
                                                    "formData",
                                                    "currentAssetValue",
                                                ]}
                                            >
                                                <Input
                                                    addonBefore="ចំនួន / Amount"
                                                    addonAfter="រៀល"
                                                    status="warning"
                                                    readOnly
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={14}>
                                            <Form.Item
                                                name={["formData", "isShareHolder"]}
                                                valuePropName="checked"
                                            >
                                                <Checkbox>
                                                    មូលនិធិភាគហ៊ុន / Shareholder
                                                    Funds
                                                </Checkbox>
                                            </Form.Item>
                                        </Col>
                                        <Col span={10}>
                                            <Form.Item
                                                name={[
                                                    "formData",
                                                    "shareHolderValue",
                                                ]}
                                            >
                                                <Input
                                                    addonBefore="ចំនួន / Amount"
                                                    addonAfter="រៀល"
                                                    status="warning"
                                                    readOnly
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={14}>
                                            <Form.Item
                                                name={["formData", "isRealEstate"]}
                                                valuePropName="checked"
                                            >
                                                <Checkbox>
                                                    អចលនទ្រព្យ / Real Estate
                                                </Checkbox>
                                            </Form.Item>
                                        </Col>
                                        <Col span={10}>
                                            <Form.Item
                                                name={[
                                                    "formData",
                                                    "realEstateValue",
                                                ]}
                                            >
                                                <Input
                                                    addonBefore="ចំនួន / Amount"
                                                    addonAfter="រៀល"
                                                    status="warning"
                                                    readOnly
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </>
                    },
                    {
                        key: "5",
                        label: "ក្រុមប្រឹក្សាភិបាល",
                        forceRender: true,
                        children: <>
                            <Form.Item
                                shouldUpdate={(preValues, curValues) =>
                                    preValues.boardOfDirectors !==
                                    curValues.boardOfDirectors
                                }
                            >
                                {({ getFieldValue }: any) => {
                                    const boardOfDirectors: BoardOfDirectorType[] =
                                        getFieldValue("boardOfDirectors") || [];

                                    return (
                                        <Table
                                            bordered
                                            rowKey="name"
                                            size="small"
                                            className="w-full"
                                            pagination={{
                                                position: [],
                                            }}
                                            columns={boardOfDirectorColumns}
                                            dataSource={boardOfDirectors}
                                        />
                                    );
                                }}
                            </Form.Item>
                        </>
                    },
                    {
                        key: "6",
                        label: "ព័ត៌មានអាជីវកម្មល្បែងផ្សងសំណាង",
                        forceRender: true,
                        children: <>
                            <Row>
                                <Col span={8}>
                                    កម្មវត្ថុអាជីវកម្ម(ប្រភេទល្បែងផសងសំណាង)<br />
                                    Purpose of Business(Type of game of chance)
                                </Col>
                                <Col span={16}>
                                    <Form.Item
                                        shouldUpdate={(preValues, curValues) =>
                                            preValues.gameTypes !==
                                            curValues.gameTypes
                                        }
                                    >
                                        {({ getFieldValue }: any) => {
                                            const gameTypes: any[] =
                                                getFieldValue("gameTypes") || [];

                                            return (<div className="grid grid-cols-2 gap-2">
                                                {gameTypes.map((item: any, index: number) => <div className="flex flex-row gap-4" key={item.id}><span>{index + 1}</span><span>{item.gameType.label}</span></div>)}
                                            </div>
                                            );
                                        }}
                                    </Form.Item>
                                    <Button onClick={() => setLotteryGameTypeVisible(true)}>បន្ថែមថ្មី</Button>
                                </Col>

                            </Row>
                        </>
                    },
                    {
                        key: "7",
                        label: "ព័ត៌មានរបស់សាខាល្បែងផ្សងសំណាង",
                        forceRender: true,
                        children: <>
                            <Row>
                                <Col span={8}>
                                    សាខានៅក្នុងប្រទេសកម្ពុជា<br />
                                    Branch in Cambodia
                                </Col>
                                <Col span={16}>
                                    <Form.Item name={["formData", "hasBranch"]}
                                        valuePropName="value">
                                        <Radio.Group
                                        >
                                            <Space direction="vertical">
                                                <Radio value={false}>
                                                    មាន / Have
                                                </Radio>
                                                <Radio value={true}>
                                                    គ្មាន / Non
                                                    Person
                                                </Radio>
                                            </Space>
                                        </Radio.Group>
                                    </Form.Item>

                                </Col>
                                <Col span={24}>
                                    {/* <Form.Item
                                        shouldUpdate={(preValues, curValues) =>
                                            preValues.branches !==
                                            curValues.branches
                                        }
                                    >
                                        {() => {
                                            const formBranches: any[] =
                                                form.getFieldValue("branches") || [];
                                            setBranches(formBranches);
                                            return (
                                                <span></span>
                                            );
                                        }}
                                    </Form.Item> */}

                                    <Table
                                        bordered
                                        rowKey="branchId"
                                        className="w-full"
                                        pagination={{
                                            position: [],
                                        }}
                                        size="small"
                                        dataSource={branches}
                                        components={{
                                            body: {
                                                cell: EditableCell,
                                            },
                                        }}
                                        columns={branchActionColumns as ColumnTypes}

                                    />

                                </Col>
                                <Col span={24}>
                                    <Button onClick={onBranchNewRow}>បន្ថែមថ្មី</Button>
                                </Col>
                            </Row>
                        </>
                    },
                    {
                        key: "8",
                        label: "ព័ត៌មានរបស់បុគ្គលឈរឈ្មោះក្នុងអាជ្ញាបណ្ណ",
                        forceRender: true,
                        children: <>
                            <Row>
                                <Col span={8}>
                                    ស្ថានភាពអ្នកដាក់ពាក្យស្នើសុំ
                                    និងបុគ្គលឈរឆ្មោះក្នុអាជ្ញាបណ្ណ
                                    <br />
                                    Status of the Applicant and License
                                    Representative
                                </Col>
                                <Col span={16}>
                                    <Form.Item
                                        name={["formData", "representativeType"]}
                                    // valuePropName="value"
                                    >
                                        <Radio.Group
                                            onChange={onApplicantStatusChange}
                                        >
                                            <Space direction="vertical">
                                                <Radio value={false}>
                                                    ជាបុគ្គលតែមួយ / Same Person
                                                </Radio>
                                                <Radio value={true}>
                                                    ជាបុគ្គលផ្សេងគ្នា / Different
                                                    Person
                                                </Radio>
                                            </Space>
                                        </Radio.Group>
                                    </Form.Item>
                                    <p>
                                        (សូមបន្តបំពេញប័ត៌មានខាងក្រោម/Please Provider
                                        the Information Below)
                                    </p>
                                </Col>
                            </Row>
                            {isDifferentRepresentative === true ? (
                                <Row>
                                    <Col span={6}>
                                        គោត្តនាម និងនាម
                                        <br />
                                        Last name and First name
                                    </Col>
                                    <Col span={18}>
                                        <Row>
                                            <Col span={8}>
                                                អក្សរខ្មែរ <br />
                                                in Khmer
                                            </Col>
                                            <Col span={16}>
                                                <Form.Item
                                                    name={[
                                                        "formData",
                                                        "representative",
                                                        "khmerName",
                                                    ]}
                                                >
                                                    <Input />
                                                </Form.Item>
                                            </Col>
                                            <Col span={8}>
                                                អក្សរឡាតាំង <br />
                                                in Latin
                                            </Col>
                                            <Col span={16}>
                                                <Form.Item
                                                    name={[
                                                        "formData",
                                                        "representative",
                                                        "englishName",
                                                    ]}
                                                >
                                                    <Input />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Col>

                                    <Col span={6}>
                                        ភេទ
                                        <br />
                                        Sex
                                    </Col>
                                    <Col span={18}>
                                        <Form.Item
                                            name={[
                                                "formData",
                                                "representative",
                                                "gender",
                                            ]}
                                        >
                                            <Radio.Group>
                                                <Space direction="horizontal">
                                                    <Radio value="male">
                                                        ប្រុស / Male
                                                    </Radio>
                                                    <Radio value="female">
                                                        ស្រី / Female
                                                    </Radio>
                                                </Space>
                                            </Radio.Group>
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        ថ្ងៃខែឆ្នាំកំណើត
                                        <br />
                                        Date of Birth
                                    </Col>
                                    <Col span={18}>
                                        <Form.Item
                                            name={[
                                                "formData",
                                                "representative",
                                                "dateOfBirth",
                                            ]}
                                        >
                                            <DatePicker placeholder="ថ្ងៃខែឆ្នាំកំណើត" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        សញ្ជាតិ
                                        <br />
                                        Nationality
                                    </Col>
                                    <Col span={18}>
                                        <Form.Item
                                            name={[
                                                "formData",
                                                "representative",
                                                "nationality",
                                            ]}
                                        >
                                            <Input placeholder="សញ្ជាតិ / Nationality" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item
                                            name={[
                                                "formData",
                                                "representative",
                                                "identityTypeId",
                                            ]}
                                        >
                                            <Radio.Group>
                                                <Space direction="vertical">
                                                    {
                                                        identificationTypes &&
                                                        identificationTypes.map(
                                                            (
                                                                identificationType: any,
                                                                index: number
                                                            ) => (
                                                                <Radio
                                                                    key={
                                                                        identificationType.value
                                                                    }
                                                                    value={
                                                                        identificationType.value
                                                                    }
                                                                >
                                                                    {
                                                                        identificationType.label
                                                                    }
                                                                </Radio>
                                                            )
                                                        )}
                                                </Space>
                                            </Radio.Group>
                                        </Form.Item>
                                    </Col>
                                    <Col span={16}>
                                        <Form.Item
                                            name={[
                                                "formData",
                                                "representative",
                                                "identityNumber",
                                            ]}
                                        >
                                            <Input placeholder="សូមបំពេញ" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        ថ្ងៃខែឆ្នាំផ្តល់ / Issued Date
                                    </Col>
                                    <Col span={18}>
                                        <Form.Item
                                            name={[
                                                "formData",
                                                "representative",
                                                "issuedDate",
                                            ]}
                                        >
                                            <DatePicker placeholder="ថ្ងៃខែឆ្នាំកំណើត" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        ថ្ងៃខែឆ្នាំផុតកំណត / Date of Expiry
                                    </Col>
                                    <Col span={18}>
                                        <Form.Item
                                            name={[
                                                "formData",
                                                "representative",
                                                "expiredDate",
                                            ]}
                                        >
                                            <DatePicker placeholder="ថ្ងៃខែឆ្នាំកំណើត" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        តួនាទីក្នុងក្រុមហ៊ុន / Position in Company
                                    </Col>
                                    <Col span={18}>
                                        <Form.Item
                                            name={[
                                                "formData",
                                                "representative",
                                                "designatureId",
                                            ]}
                                        >
                                            <Input placeholder="តួនាទីក្នុងក្រុមហ៊ុន / Position in Company" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>អាសយដ្ឋាន / Address</Col>
                                    <Col span={18}>
                                        <Form.Item
                                            name={[
                                                "formData",
                                                "representative",
                                                "address",
                                            ]}
                                        >
                                            <Input placeholder="អាសយដ្ឋាន / Address" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        ទូរស័ព្ទចល័ត / Mobile Phone Number
                                    </Col>
                                    <Col span={18}>
                                        <Form.Item
                                            name={[
                                                "formData",
                                                "representative",
                                                "mobilePhoneNumber",
                                            ]}
                                        >
                                            <Input placeholder="ទូរស័ព្ទចល័ត / Mobile Phone Number" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        សារអេឡិចត្រូនិច / Email Address
                                    </Col>
                                    <Col span={18}>
                                        <Form.Item
                                            name={[
                                                "formData",
                                                "representative",
                                                "emailAddress",
                                            ]}
                                        >
                                            <Input placeholder="សារអេឡិចត្រូនិច / Email Address" />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            ) : (
                                <span></span>
                            )}
                        </>
                    },

                ]}
            />

        </Form>
    );
}