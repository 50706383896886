import { RequestLotteryLicenseList } from "Modules/License/Lottery/ApplicationForm/License/List";
import { RequestLotteryLicenseCreate } from "Modules/License/Lottery/ApplicationForm/License/Create";
import { RequestLotteryLicenseDetail } from "Modules/License/Lottery/ApplicationForm/License/Detail";
export const RequestLotteryLicenseRoute = {
    path: "request-license",
    children: [
        { path: "", element: <RequestLotteryLicenseList /> },
        { path: "create", element: <RequestLotteryLicenseCreate /> },
        { path: ":id", element: <RequestLotteryLicenseDetail /> }
    ],
};
