/**
 *
 * Public Route
 *
 */

// import React from "react";

function PublicRoute({ children }: any) {
    return children;
}

export default PublicRoute;
