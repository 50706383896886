import { useState } from "react";
import { useDispatch } from "react-redux";
import {v4 as uuid}  from "uuid";

import { FullLayout } from "Components/Layouts";
import { ArchiveIcon } from "@heroicons/react/outline";
import { HelmetHeader } from "Components/Helmet-Header";
import {
    Button,
    Space,
    QRCode,
} from "antd";
import QrReader from "react-qr-reader";

import { ShowMessage } from "Common/Saga";
import { SetFormValuesAction } from "Modules/Settings/GameType/Actions";
import { GetCompanyService } from "Modules/Entity/Company/Service";
import { SelectCompanySuccessAction } from "Modules/Entity/Company/Actions";
import { GetCasinoTypeService } from "Modules/Entity/Operator/Service";
import { SelectableCasinoTypeSuccessAction } from "Modules/License/Casino/Actions";
import { GetEntityCasinoByIdRequestAction } from "Modules/Entity/Operator/Actions";
import { GetEquipmentByVersionIdService } from "Modules/Certificate/Casino/Verify/Service";

export const CerticiateLotteryVerifyPage = () => {
    const dispatch = useDispatch();
    const [qrScannerVisible, setQrScannerVisible] = useState<boolean>(false);
    const [qrCodeData, setQrCodeData] = useState<string>(null);
    const [delayScan, setDelayScan] = useState<any>(500);
    const [verifyData, setVerifyData] = useState<any>(null);

    const onQRScanError = (error: any) => {
        dispatch(ShowMessage({
            type: "error",
            message: "ខុសឈ្មោះអ្នកប្រើ ឬលេខសម្ងាត់",
            content: "",
            id: uuid(),
        }));
    }

    const onQRScanComplete = (data: any) => {
        if (data) {
            setQrCodeData(data.text);
            setQrScannerVisible(false);
            var versionId = data.text.split('/').pop();
            GetEquipmentByVersionIdService(versionId, dispatch)
                .then((equipmentRs:any) => {
                    setVerifyData(equipmentRs);
                    GetCompanyService(dispatch)
                        .then((companies: any) => {
                        if(companies) {
                            dispatch(SelectCompanySuccessAction(companies));
                        }
                        GetCasinoTypeService(dispatch)
                        .then((casinoTypes: any) => {
                            dispatch(SelectableCasinoTypeSuccessAction(casinoTypes));
                            if (equipmentRs.casinoId) {
                                
                                dispatch(SetFormValuesAction(equipmentRs.casinoId));
                                dispatch(GetEntityCasinoByIdRequestAction());
                            }
                        });
                    })
                });
        }
    }

    return (
        <>
            <FullLayout
                icon={<ArchiveIcon />}
                title="បង្កើតបញ្ជីឧបករណ៍/កម្មវិធីល្បែង"
                descriptions=""
                redirectUrl=""
                actions={<></>}
            >
                <HelmetHeader meta="ស្នើសុំចុះបញ្ជី ឧបករណ៍/កម្មវិធីល្បែង" />
                <Button type="primary" onClick={() => setQrScannerVisible(true)}>
                    ស្កេន
                </Button>
                <div className="flex flex-col items-center">
                    
                    {qrScannerVisible && < QrReader
                        // constraints={
                        //     { facingMode: cameraView }
                        // }
                        facingMode={"environment"}
                        delay={1000}
                        onError={onQRScanError}
                        onScan={onQRScanComplete}
                        // chooseDeviceId={()=>selected}
                        style={{ width: "300px" }}
                    />}
                    {
                        qrCodeData && <Space direction="vertical">
                            <QRCode value={qrCodeData}></QRCode>
                            <p>{qrCodeData}</p>
                        </Space>
                    }
                    {
                        qrCodeData && (
                            <div>
                                <div className="flex mt-4 border-b-2 border-green-900">
                                    <h2
                                        className="px-4 py-2 bg-green-900 rounded-t-lg text-white text-sm __className_e465a2"
                                    >
                                        វិញ្ញាបនបត្រចុះបញ្ជី
                                    </h2>
                                </div>
                                <div className="grid grid-cols-3 py-6 px-4">
                                    <div className="flex flex-col gap-1">
                                        <p className="text-sm text-green-900 border border-green-800">
                                            នាមករណ៍ល្បែងផ្សងសំណាង
                                        </p>
                                        <p className="text-sm text-green-900">Name of Game of Chance</p>
                                    </div>
                                    <div className="flex flex-col gap-1">
                                        <p className="text-sm text-green-900 border border-green-800">
                                            មហាលាភឡូតូ
                                        </p>
                                        <p className="text-sm text-green-900">MOHALEAP LOTTO</p>
                                    </div>
                                </div>
                                <div className="grid grid-cols-3 py-6 px-4 bg-slate-50">
                                    <div className="flex flex-col gap-1">
                                        <p className="text-sm text-green-900 border border-green-800">
                                            នាមករណ៍ក្រុមហ៊ុន
                                        </p>
                                        <p className="text-sm text-green-900">Name of Company</p>
                                    </div>
                                    <div className="flex flex-col gap-1">
                                        <p className="text-sm text-green-900 border border-green-800">
                                            អេវើរី ប្រាយ រីច ត្រេឌីង ខមភេនី អិលធីឌី
                                        </p>
                                        <p className="text-sm text-green-900">
                                            EVERY BRIGHT RICH TRADING COMPANY LTD.
                                        </p>
                                    </div>
                                </div>
                                <div className="grid grid-cols-3 py-6 px-4">
                                    <div className="flex flex-col gap-1">
                                        <p className="text-sm text-green-900 border border-green-800">
                                            ទីតាំងធ្វើអាជីវកម្
                                        </p>
                                        <p className="text-sm text-green-900">Location of Business</p>
                                    </div>
                                    <div className="col-span-2 flex flex-col gap-1">
                                        <p className="text-sm text-green-900 border border-green-800">
                                            អគារលេខ៣០អឺសេរូ ផ្លូវលេខ៤៣២ ក្រុមទី៣៤ សង្កាត់ទួលទំពូង១
                                            ខណ្ឌចំការមន រាជធានីភ្នំពេញ
                                        </p>
                                        <p className="text-sm text-green-900">
                                            Building No.30E0, St.432, Group 34, Tuol Tumpung 1
                                            Commune, Chamkamorn District, Phnom Penh
                                        </p>
                                    </div>
                                </div>
                                <div className="grid grid-cols-3 py-6 px-4 bg-slate-50">
                                    <div className="flex flex-col gap-1">
                                        <p className="text-sm text-green-900 border border-green-800">
                                            កម្មវត្ថុចុះបញ្ជី
                                        </p>
                                        <p className="text-sm text-green-900">
                                            Subject of Registration
                                        </p>
                                    </div>
                                    <div className="col-span-2 flex flex-col gap-1">
                                        <p className="text-sm text-green-900 border border-green-800">
                                            ប្រភេទល្បែង និងឧបករណ៍ល្បែង
                                        </p>
                                        <p className="text-sm text-green-900">
                                            Game Type and Game Equipment
                                        </p>
                                    </div>
                                </div>
                                <div className="grid grid-cols-3 py-6 px-4">
                                    <div className="flex flex-col gap-1">
                                        <p className="text-sm text-green-900 border border-green-800">
                                            វិញ្ញាបនបត្រនេះមានសុពលភាពរហូតដល់
                                        </p>
                                        <p className="text-sm text-green-900">
                                            This Certificate is valid until
                                        </p>
                                    </div>
                                    <div className="flex flex-col gap-1"></div>
                                </div>
                                <div className="flex mt-4 border-b-2 border-green-900">
                                    <h2
                                        className="px-4 py-2 bg-green-900 rounded-t-lg text-white text-sm __className_e465a2"
                                    >
                                        ឧបសម្ព័ន្ធ នៃវិញ្ញាបនបត្រចុះបញ្ជីប្រភេទល្បែង វិធានល្បែង
                                        ឧបករណ៍និង/កម្មវិធីល្បែង
                                    </h2>
                                </div>
                                <div className="max-w-full overflow-scroll mb-6">
                                    <table
                                        className="w-full tabel-auto border-2 border-double border-green-900"
                                    >
                                        <thead>
                                            <tr>
                                                <th className=" border border-green-900">ល.រ.</th>
                                                <th className=" border border-green-900">ប្រភេទល្បែង</th>
                                                <th className=" border border-green-900">ឧបករណ៍ល្បែង</th>
                                                <th className=" border border-green-900">ចេញលទ្ធផលឆ្នោត</th>
                                                <th className=" border border-green-900">ផ្សេងៗ</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className=" border border-green-900">១</td>
                                                <td className=" border border-green-900">លាភមហាសម្បត្តិ៦ខ្ទង់</td>
                                                <td className=" border border-green-900">
                                                    ប្រព័ន្ធចេញលទ្ធផលឆ្នោត៦ខ្ទង់ដោយស្វ័យប្រវត្តិ
                                                </td>
                                                <td className="text-center border border-green-900">
                                                    ២ដង ក្នុង១ថ្ងៃ ៧ថ្ងៃ ក្នុង១សប្តាហ៍
                                                </td>
                                                <td className="text-center border border-green-900"></td>
                                            </tr>
                                            <tr>
                                                <td className=" border border-green-900">២</td>
                                                <td className=" border border-green-900">ឡូតូ៥ខ្ទង់</td>
                                                <td className=" border border-green-900">
                                                    ប្រព័ន្ធចេញលទ្ធផលឆ្នោត៥ខ្ទង់ដោយស្វ័យប្រវត្តិ
                                                </td>
                                                <td className="text-center border border-green-900">
                                                    ២ដង ក្នុង១ថ្ងៃ ៧ថ្ងៃ ក្នុង១សប្តាហ៍
                                                </td>
                                                <td className="text-center border border-green-900"></td>
                                            </tr>
                                            <tr>
                                                <td className=" border border-green-900">៣</td>
                                                <td className=" border border-green-900">ឡូតូ៧ខ្ទង់</td>
                                                <td className=" border border-green-900">
                                                    ប្រព័ន្ធចេញលទ្ធផលឆ្នោត៧ខ្ទង់ដោយស្វ័យប្រវត្តិ
                                                </td>
                                                <td className="text-center border border-green-900">
                                                    ២ដង ក្នុង១ថ្ងៃ ៧ថ្ងៃ ក្នុង១សប្តាហ៍
                                                </td>
                                                <td className="text-center border border-green-900"></td>
                                            </tr>
                                            <tr>
                                                <td className=" border border-green-900">៤</td>
                                                <td className=" border border-green-900">ឆ្នោតសន្លឹក៦ខ្ទង់</td>
                                                <td className=" border border-green-900">
                                                    ប្រព័ន្ធចេញលទ្ធផលឆ្នោតសន្លឹកទង់ដោយស្វ័យប្រវត្តិ
                                                </td>
                                                <td className="text-center border border-green-900">
                                                    ១ដង ក្នុង១ថ្ងៃ ៧ថ្ងៃ ក្នុង១សប្តាហ៍
                                                </td>
                                                <td className="text-center border border-green-900"></td>
                                            </tr>
                                            <tr>
                                                <td className=" border border-green-900">៥</td>
                                                <td className=" border border-green-900">ឆ្នោតកោសលុយសំណាង</td>
                                                <td className=" border border-green-900"></td>
                                                <td className="text-center border border-green-900"></td>
                                                <td className="text-center border border-green-900"></td>
                                            </tr>
                                            <tr>
                                                <td className=" border border-green-900">៦</td>
                                                <td className=" border border-green-900">ឆ្នោតកោសទន្សាយមាស</td>
                                                <td className=" border border-green-900"></td>
                                                <td className="text-center border border-green-900"></td>
                                                <td className="text-center border border-green-900"></td>
                                            </tr>
                                            <tr>
                                                <td className=" border border-green-900">៧</td>
                                                <td className=" border border-green-900">ឆ្នោតកោសសម្បត្តិមហាលាភ</td>
                                                <td className=" border border-green-900"></td>
                                                <td className="text-center border border-green-900"></td>
                                                <td className="text-center border border-green-900"></td>
                                            </tr>
                                            <tr>
                                                <td className=" border border-green-900">៨</td>
                                                <td className=" border border-green-900">ឆ្នោតកោសមហាលាភទ្វេដង</td>
                                                <td className=" border border-green-900"></td>
                                                <td className="text-center border border-green-900"></td>
                                                <td className="text-center border border-green-900"></td>
                                            </tr>
                                            <tr>
                                                <td className=" border border-green-900">៩</td>
                                                <td className=" border border-green-900">10K RAFFLE</td>
                                                <td className="text-center border border-green-900">
                                                    ប្រព័ន្ធចេញលទ្ធផលឆ្នោត៦ខ្ទង់(10K)
                                                    ដោស្វ័យប្រវត្តិ
                                                </td>
                                                <td className="text-center border border-green-900">
                                                    ១ដង ក្នុង១ថ្ងៃ ៧ថ្ងៃ ក្នុង១សប្តាហ៍
                                                </td>
                                                <td></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        
                        )
                    }
                </div>
            </FullLayout>
        </>
    )
}