import produce, { setAutoFreeze } from "immer";
import {
    SET_REQUEST_LOTTERY_GAME_TYPE_FILTER_VALUE,
    SET_REQUEST_LOTTERY_GAME_TYPE_VALUE,
    SET_REQUEST_LOTTERY_GAME_TYPE_ID,
    GET_REQUEST_LOTTERY_GAME_TYPE_SUCCESS,
    LIST_REQUEST_LOTTERY_GAME_TYPE_SUCCESS
} from "Modules/Certificate/Lottery/ApplicationForm/Constants";

export const LotteryApplicationFormInitialState = {
    requestLotteryGameTypes: [],
    requestLotteryGameType: null,
    filterValues: null,
    values: null,
    id: null,
}

setAutoFreeze(false);
const LotteryApplicationFormReducer = produce((draft, action) => {
    switch (action.type) {
        case SET_REQUEST_LOTTERY_GAME_TYPE_FILTER_VALUE:
            draft.filterValues = action.values;
            break;
        case SET_REQUEST_LOTTERY_GAME_TYPE_VALUE:
            draft.values = action.values;
            break;
        case SET_REQUEST_LOTTERY_GAME_TYPE_ID:
            draft.id = action.id;
            break;
        case GET_REQUEST_LOTTERY_GAME_TYPE_SUCCESS:
            draft.requestLotteryGameType = action.requestLotteryGameType;
            break;
        case LIST_REQUEST_LOTTERY_GAME_TYPE_SUCCESS:
            draft.requestLotteryGameTypes = action.requestLotteryGameTypes;
            break;
        default: break;
    }
}, LotteryApplicationFormInitialState);

export default LotteryApplicationFormReducer;