import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useInjectReducer } from "Utility/InjectReducer";
import { useInjectSaga } from "Utility/InjectSaga";

import { Card, } from "antd";
import { VictoryBar, VictoryChart, VictoryTheme } from 'victory';

import reducer from "Modules/Entity/Operator/Reducer";
import saga from "Modules/Entity/Operator/Saga";
import { GetCasinoStatusCountSuccessAction, GetLotteryStatusCountSuccessAction, GetOperatorStatusCountRequestAction } from "Modules/Entity/Operator/Actions";
import { MakeCasinoStatusCountSelector, MakeLotterytatusCountSelector } from "Modules/Entity/Operator/Selectors";

const key = "entityCasino";
export const Dashboard = () => {
    useInjectReducer({ key, reducer });
    useInjectSaga({ key, saga });
    // fields
    const dispatch = useDispatch();
    const casinoStatusCount = useSelector(MakeCasinoStatusCountSelector());
    const lotteryStatusCount = useSelector(MakeLotterytatusCountSelector());


    // constrcutors
    useEffect( () =>  {
        dispatch(GetOperatorStatusCountRequestAction());
        return () => {
            dispatch(GetCasinoStatusCountSuccessAction([]));
            dispatch(GetLotteryStatusCountSuccessAction([]));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // methods
    // events
    return (
        <div className="h-full bg-slate-200">
            <div className=" grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3  gap-6 p-4">
                <Card title="ប្រតិបត្តករកាស៊ីណូ"
                    bordered={false} >
                    <VictoryChart
                        // adding the material theme provided with Victory
                        theme={VictoryTheme.material}
                        domainPadding={20}
                    >
                        <VictoryBar
                            data={casinoStatusCount || []}
                            x="name"
                            y="count"
                            labels={({ datum }) => datum.count}
                        />
                    </VictoryChart>
                </Card>
                <Card title="ប្រតិបត្តិករល្បែងផ្សងសំណាង"
                    bordered={false} >
                    <VictoryChart
                        // adding the material theme provided with Victory
                        theme={VictoryTheme.material}
                        domainPadding={20}
                    >
                        <VictoryBar
                            data={lotteryStatusCount || []}
                            x="name"
                            y="count"
                            labels={({ datum }) => datum.count}
                        />
                    </VictoryChart>
                </Card>
            </div>
        </div>

    );
};
