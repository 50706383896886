import { useEffect, useState }  from "react";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuid } from "uuid";

import { ArchiveIcon } from "@heroicons/react/outline";
import { HelmetHeader } from "Components/Helmet-Header";
import { FullLayout } from "Components/Layouts";
import { useNavigate, useParams } from "react-router-dom";
import { Form, Image, Input, Select,Button, Space, QRCode } from "antd";

import { AsyncEndAction, AsyncStartAction } from "Modules/Application/Actions";
import {  GetBindingEquipmentDetailService, RebindEquipmentDetailService } from "../Service";
import { SelectGameRuleService } from "Modules/Settings/GameRule/Service";
import { SelectGameRuleSuccessAction } from "Modules/Settings/GameRule/Actions";
import { MakeSelectGameRuleSelector } from "Modules/Settings/GameRule/Selectors";
import { useInjectReducer } from "Utility/InjectReducer";
import GameRuleReducer from "Modules/Settings/GameRule/Reducer";
import { EquipmentPictureForm } from "../Forms/EquipmentPictureForm";
import { ShowMessage } from "Common/Saga";
import QrReader from "react-qr-reader";

const key = "equipmentBinding";
const gameRuleKey = "gameRule";
export const EquipmentRebindingDetail = () => {
    useInjectReducer({key: gameRuleKey, reducer: GameRuleReducer});

    // fields
    const dispatch = useDispatch();
    const {id} = useParams();
    const navigate = useNavigate();
    const gameRules = useSelector(MakeSelectGameRuleSelector());
    const [form] = Form.useForm();
    const [qrScannerVisible, setQrScannerVisible] = useState<boolean>(false);
    const [qrCodeData, setQrCodeData] = useState<string>(null);
    
    // constructor
    useEffect(() => {
        if(id) {
            dispatch(AsyncStartAction());
            SelectGameRuleService(1, dispatch).then( (gameRuleRs: any) => {
                if(gameRuleRs) {
                    dispatch(SelectGameRuleSuccessAction(gameRuleRs));
                }
                GetBindingEquipmentDetailService(id, dispatch)
                .then((detailRs: any) => {
                    if(detailRs) {
                        form.setFieldsValue(detailRs);
                        
                    }
                })
                
            })
            .finally(() => {
                dispatch(AsyncEndAction());
            });
            
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    //event
    const onSubmit = () => {
        const qrId = qrCodeData.split("/").pop();
        const values = form.getFieldsValue();
        const model = {
            ...values,
            inspectionCertificateId: qrId,
            equipmentId: values.terminalId,
            gameRuleId: values.gameRule? values.gameRule.value: "00000000-0000-0000-0000-000000000000",
            gameTypeId: values.gameRule ? values.gameRule.key: "00000000-0000-0000-0000-000000000000",
            
        }
        RebindEquipmentDetailService(model, dispatch)
            .then((equpmentDetailRs: any) => {
                if(equpmentDetailRs && !equpmentDetailRs.status) {
                    dispatch(ShowMessage({
                        type: "success",
                        message: "Completed",
                        content: "",
                        id: uuid(),
                    }))
                }
            })
            .catch((ex: any) => {
                dispatch(ShowMessage({
                    type: "error",
                    message: ex.message,
                    content: "",
                    id: uuid(),
                }))
            });
    }

    const onCreateClick = () => {
        navigate(`/equipment/binding/create/${form.getFieldValue("casinoId")}`);
    }

    const onQRScanError = (error: any) => {

    }

    const onQRScanComplete = (data: any) => {
        
        if (data) {
            setQrCodeData(data);
            setQrScannerVisible(false);
        }
    }

    const ActionButtons = () => (
        <>
            <Button
                type="primary"
                onClick={onSubmit}
            >
                រក្សាទុក
            </Button>
            <Button
                type="primary"
                onClick={onCreateClick}
            >
                បញ្ជូលថ្មី
            </Button>
        </>
    );
    return (
        <>
            <FullLayout
                icon={<ArchiveIcon />}
                title="ភ្ជាប់លតាបត្រ(ឧបករណ៍ ឬកម្មវិធីល្បែង)ថ្មី "
                descriptions=""
                actions={<ActionButtons />}
            >
                <HelmetHeader meta="ពត៌មានឧបករណ៍ ឬកម្មវិធីល្បែង" />
                <Form
                    form={form}
                    name="equipmentBindingDetailForm"
                    layout="vertical"
                    
                >
                    <Form.Item name="pictures"  hidden>
                        <Input />
                    </Form.Item>
                    <Form.Item name="id" hidden>
                        <Input />
                    </Form.Item>
                    <Form.Item name="casinoEquipmentDetailId" hidden>
                        <Input />
                    </Form.Item>
                    <Form.Item name="casinoId" hidden>
                        <Input />
                    </Form.Item>
                    <div className="grid grid-cols-1 md:grid-cols-3 p-5 gap-4">
                        <div className="flex flex-col gap-2 items-start">
                            <h2>រូប QR Code</h2>
                            
                            <Button type="primary" onClick={() => setQrScannerVisible(true)}>
                                ស្កេន
                            </Button>
                            {qrScannerVisible && < QrReader
                                // constraints={
                                //     { facingMode: cameraView }
                                // }
                                facingMode={"environment"}
                                delay={1000}
                                onError={onQRScanError}
                                onScan={onQRScanComplete}
                                // chooseDeviceId={()=>selected}
                                style={{ width: "300px" }}
                            />}
                            {
                                qrCodeData && <Space direction="vertical">
                                    <QRCode value={qrCodeData}></QRCode>
                                    <p>{qrCodeData}</p>
                                </Space>
                            }
                        </div>
                        
                    </div>
                    <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 p-4">
                        <Form.Item name="terminalId" label="Terminal Id">
                            <Input />
                        </Form.Item>
                        <Form.Item name="zoneName" label="Zone">
                            <Input />
                        </Form.Item>
                        <Form.Item name="gameRuleId" label="វិធានល្បែង">
                            <Select
                                labelInValue
                                style={{ minWidth: 200 }}
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    {
                                        return (option?.children.toString() ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                }
                                showSearch
                            >
                                {gameRules &&
                                    gameRules.map((item) => (
                                        <Select.Option
                                            key={item["id"]}
                                            value={item["id"]}
                                        >
                                            {item["name"]}
                                        </Select.Option>
                                    ))}
                            </Select>
                        </Form.Item>
                    </div>
                    <div className="w-full p-4">
                        <h2 className="py-4 text-lg font-bold">រូបភាព</h2>
                        <Form.Item
                            
                            shouldUpdate={(preValues, curValues) =>
                                preValues.pictures !==
                                curValues.pictures
                            }
                        >
                            {({ getFieldValue }: any) => {
                                const equipmentPictures: any[] =
                                    getFieldValue("pictures") || [];
                                console.log("Pictures", equipmentPictures);
                                return <div className="flex flex-row flex-wrap gap-4">
                                    <Image.PreviewGroup
                                        preview={{
                                        onChange: (current, prev) => console.log(`current index: ${current}, prev index: ${prev}`),
                                        }}
                                    >
                                    {equipmentPictures && equipmentPictures.map((picture: any) => <EquipmentPictureForm key={picture.id} picture={picture} />)}
                                    </Image.PreviewGroup>

                                </div>
                                
                            }}
                        </Form.Item>
                        
                    </div>
                </Form>
                
            </FullLayout>
        </>
    )
}