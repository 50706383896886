import {
    SET_REQUEST_GAME_TYPE_FILTER_VALUE,
    SET_REQUEST_GAME_TYPE_VALUE,
    LIST_REQUEST_GAME_TYPE_REQUEST,
    LIST_REQUEST_GAME_TYPE_SUCCESS,
    SET_REQUEST_GAME_TYPE_ID,
    CREATE_REQUEST_GAME_TYPE_REQUEST,
    GET_REQUEST_GAME_TYPE_REQUEST,
    GET_REQUEST_GAME_TYPE_SUCCESS,
    EDIT_REQUEST_GAME_TYPE_REQUEST,
    SET_CERTIFICATE_GAME_TYPE_FILTER_VALUES,
    LIST_CERTIFICATE_GAME_TYPE_REQUEST,
    LIST_CERTIFICATE_GAME_TYPE_SUCCESS,
    SET_APPROVE_CERTIFICATE_GAME_TYPE_VALUE,
    SET_CERTIFICATE_GAME_TYPE_VALUE,
    CREATE_CERTIFICATE_GAME_TYPE_REQUEST,

} from "Modules/Certificate/Casino/ApplicationForm/GameType/Constants";

export const SetRequestGameTypeFilterValueAction = (values: any) => {
    return {
        type: SET_REQUEST_GAME_TYPE_FILTER_VALUE,
        values,
    };
};

export const SetRequestGameTypeValueAction = (values: any) => {
    return {
        type: SET_REQUEST_GAME_TYPE_VALUE,
        values,
    };
};

export const CreateRequestGameTypeRequestAction = () => {
    return {
        type: CREATE_REQUEST_GAME_TYPE_REQUEST,
    };
};

export const ListRequestGameTypeRequestAction = () => {
    return {
        type: LIST_REQUEST_GAME_TYPE_REQUEST,
    };
};

export const ListRequestGameTypeSuccessAction = (gameTypeList: any) => {
    return {
        type: LIST_REQUEST_GAME_TYPE_SUCCESS,
        gameTypeList,
    };
};

export const SetRequestGameTypeIdAction = (id: string) => {
    return {
        type: SET_REQUEST_GAME_TYPE_ID,
        id,
    };
};

export const GetRequestGameTypeRequestAction = () => {
    return {
        type: GET_REQUEST_GAME_TYPE_REQUEST,
    };
};

export const GetRequestGameTypeSuccessAction = (requestGameTypeDetail: any) => {
    return {
        type: GET_REQUEST_GAME_TYPE_SUCCESS,
        requestGameTypeDetail,
    };
};

export const EditRequestGameTypeRequestAction = () => {
    return {
        type: EDIT_REQUEST_GAME_TYPE_REQUEST,
    };
};

// certificate
export const SetCertificateGameTypeFilterValueAction = (values: any) => {
    return { type: SET_CERTIFICATE_GAME_TYPE_FILTER_VALUES, values };
};

export const ListCertificateGameTypeRequestAction = () => {
    return { type: LIST_CERTIFICATE_GAME_TYPE_REQUEST };
};

export const ListCertificateGameTypeSuccessAction = (
    certificateGameTypeList
) => {
    return {
        type: LIST_CERTIFICATE_GAME_TYPE_SUCCESS,
        certificateGameTypeList,
    };
};

export const SetApproveCertificateGameTypeValueAction = (requestGameTypeApprove: any) => {
    return {
        type: SET_APPROVE_CERTIFICATE_GAME_TYPE_VALUE,
        requestGameTypeApprove
    }
}

export const CreateCertificateGameTypeRequestAction = () => {
    return {
        type: CREATE_CERTIFICATE_GAME_TYPE_REQUEST,
    }
}

export const SetCertificateGameTypeValueAction = (values: any) => {
    return {
        type: SET_CERTIFICATE_GAME_TYPE_VALUE,
        values
    }
}
