import {
    SET_REQUEST_GAME_RULE_FILTER_VALUE,
    SET_REQUEST_GAME_RULE_VALUE,
    LIST_REQUEST_GAME_RULE_REQUEST,
    LIST_REQUEST_GAME_RULE_SUCCESS,
    SET_REQUEST_GAME_RULE_ID,
    CREATE_REQUEST_GAME_RULE_REQUEST,
    GET_REQUEST_GAME_RULE_REQUEST,
    GET_REQUEST_GAME_RULE_SUCCESS,
    EDIT_REQUEST_GAME_RULE_REQUEST,
    SET_CERTIFICATE_GAME_RULE_FILTER_VALUE,
    LIST_CERTIFICATE_GAME_RULE_REQUEST,
    LIST_CERTIFICATE_GAME_RULE_SUCCESS,
} from "Modules/Certificate/Casino/ApplicationForm/GameRule/Constants";

export const SetRequestGameRuleFilterValueAction = (values: any) => {
    return {
        type: SET_REQUEST_GAME_RULE_FILTER_VALUE,
        values,
    };
};

export const SetRequestGameRuleValueAction = (values: any) => {
    return {
        type: SET_REQUEST_GAME_RULE_VALUE,
        values,
    };
};

export const CreateRequestGameRuleRequestAction = () => {
    return {
        type: CREATE_REQUEST_GAME_RULE_REQUEST,
    };
};

export const ListRequestGameRuleRequestAction = () => {
    return {
        type: LIST_REQUEST_GAME_RULE_REQUEST,
    };
};

export const ListRequestGameRuleSuccessAction = (requestGameRuleList: any) => {
    return {
        type: LIST_REQUEST_GAME_RULE_SUCCESS,
        requestGameRuleList,
    };
};

export const SetRequestGameRuleIdAction = (id: string) => {
    return { type: SET_REQUEST_GAME_RULE_ID, id };
};

export const GetRequestGameRuleRequestAction = () => {
    return {
        type: GET_REQUEST_GAME_RULE_REQUEST,
    };
};
export const GetRequestGameRuleSuccessAction = (requestGameRule: any) => {
    return {
        type: GET_REQUEST_GAME_RULE_SUCCESS,
        requestGameRule,
    };
};

export const EditRequestGameRuleRequestAction = () => {
    return {
        type: EDIT_REQUEST_GAME_RULE_REQUEST,
    };
};

// export const EditRequestGameRuleSuccessAction = () => {};

export const SetCertificateGameRuleFilterValueAction = (values: any) => {
    return {
        type: SET_CERTIFICATE_GAME_RULE_FILTER_VALUE,
        values,
    };
};

export const ListCertificateGameRuleRequestAction = () => {
    return {
        type: LIST_CERTIFICATE_GAME_RULE_REQUEST,
    };
};
export const ListCertificateGameRuleSuccessAction = (listGameRule: any) => {
    return {
        type: LIST_CERTIFICATE_GAME_RULE_SUCCESS,
        listGameRule,
    };
};
