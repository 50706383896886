export const SET_INSPECTION_CERTIFICATE_MASTER_STATUS = "SET_INSPECTION_CERTIFICATE_MASTER_STATUS";
export const SET_GTIN_EQUIPMENT_TYPE_ID = "SET_GTIN_EQUIPMENT_TYPE_ID";
export const SET_LOCAL_GTIN_LATEST_VALUE = "SET_LOCAL_GTIN_LATEST_VALUE";
export const SET_INSPECTION_CERTIFICATE_SERIALS = "SET_INSPECTION_CERTIFICATE_SERIALS";
export const SET_INSPECTION_CERTIFICATE_MASTER_FILTER_VALUE = "SET_INSPECTION_CERTIFICATE_MASTER_FILTER_VALUE";
export const SET_INSPECTION_CERTIFICATE_MASTER_VALUE = "SET_INSPECTION_CERTIFICATE_MASTER_VALUE";
export const SET_INSPECTION_CERTIFICATE_MASTER_ID = "SET_INSPECTION_CERTIFICATE_MASTER_ID";

export const GET_INSPECTION_CERTIFICATE_SERIALS_REQUEST = "GET_INSPECTION_CERTIFICATE_SERIALS_REQUEST";
export const GET_INSPECTION_CERTIFICATE_SERIALS_SUCCESSS = "GET_INSPECTION_CERTIFICATE_SERIALS_SUCCESSS";
export const GET_INSPECTION_CERTIFICATE_MASTER_REQUEST = "GET_INSPECTION_CERTIFICATE_MASTER_REQUEST";
export const GET_INSPECTION_CERTIFICATE_MASTER_SUCCESS = "GET_INSPECTION_CERTIFICATE_MASTER_SUCCESS";
export const LIST_INSPECTION_CERTIFICATE_MASTER_REQUEST = "LIST_INSPECTION_CERTIFICATE_MASTER_REQUEST";
export const LIST_INSPECTION_CERTIFICATE_MASTER_SUCCESS = "LIST_INSPECTION_CERTIFICATE_MASTER_SUCCESS";
export const GET_GTIN_NUMBER_REQUEST = "GET_GTIN_NUMBER_REQUEST";
export const GET_GTIN_NUMBER_SUCCESS = "GET_GTIN_NUMBER_SUCCESS";

export const CREATE_INSPECTION_CERTIFICATE_MASTER_REQUEST = "CREATE_INSPECTION_CERTIFICATE_MASTER_REQUEST";
export const DELETE_INSPECTION_CERTIFICATE_MASTER_REQUEST = "DELETE_INSPECTION_CERTIFICATE_MASTER_REQUEST";
export const EDIT_INSPECTION_CERTIFICATE_MASTER_REQUEST = "EDIT_INSPECTION_CERTIFICATE_MASTER_REQUEST";

export const SET_INSPECTION_CERTIFICATE_DETAIL_ID = "SET_INSPECTION_CERTIFICATE_DETAIL_ID";
export const DELETE_INSPECTION_CERTIFICATE_DETAIL_REQEUEST = "DELETE_INSPECTION_CERTIFICATE_DETAIL_REQEUEST";
export const SET_INSPECTION_CERTIFICATE_DETAIL_VALUE = "SET_INSPECTION_CERTIFICATE_DETAIL_VALUE";
export const EDIT_INSPECTION_CERTIFICATE_DETAIL_REQUEST = "EDIT_INSPECTION_CERTIFICATE_DETAIL_REQUEST";

export const SET_REQPORT_INSPECTION_CERTIFICATE_DETAIL_VALUE = "SET_REQPORT_INSPECTION_CERTIFICATE_DETAIL_VALUE";
export const REQPORT_INSPECTION_CERTIFICATE_DETAIL_REQUEST = "REQPORT_INSPECTION_CERTIFICATE_DETAIL_REQUEST";
export const REQPORT_INSPECTION_CERTIFICATE_DETAIL_SUCCESS = "REQPORT_INSPECTION_CERTIFICATE_DETAIL_SUCCESS";

export const PRINT_INSPECTION_CERTIFICATE_DETAIL_REQUEST = "PRINT_INSPECTION_CERTIFICATE_DETAIL_REQUEST";
export const PRINT_INSPECTION_CERTIFICATE_DETAIL_SUCCESS = "PRINT_INSPECTION_CERTIFICATE_DETAIL_SUCCESS";
export const PRINT_INSPECTION_CERTIFICATE_PRINT_DETAIL_REQUEST = "PRINT_INSPECTION_CERTIFICATE_PRINT_DETAIL_REQUEST";
export const PRINT_INSPECTION_CERTIFICATE_PRINT_DETAIL_SUCCESS = "PRINT_INSPECTION_CERTIFICATE_PRINT_DETAIL_SUCCESS";
export const PRINT_INSPECTION_CERTIFICATE_PRINT_BATCH_REQUEST = "PRINT_INSPECTION_CERTIFICATE_PRINT_BATCH_REQUEST";
export const PRINT_INSPECTION_CERTIFICATE_PRINT_BATCH_SUCCESS = "PRINT_INSPECTION_CERTIFICATE_PRINT_BATCH_SUCCESS";