import  { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Form,
    Row,
    Col,
    Table,
    Input,
    DatePicker,
    Radio,
    Button,
    Space,
    Checkbox,
    Tabs,
    Select,
    Popconfirm,
} from "antd";
import { CertificateCasinoGameRuleModal } from "Modules/Certificate/Casino/ApplicationForm/GameRule/RequestGameRule/Forms/CertificateCasinoGameRuleModal";

import { useInjectSaga } from "Utility/InjectSaga";
import { useInjectReducer } from "Utility/InjectReducer";

import CasinoLicenseReducer from "Modules/License/Casino/Reducer";
import CasinoSaga from "Modules/License/Casino/Saga";
import EntityCasinoReducer from "Modules/Entity/Operator/Reducer";
import EntityCasinoSaga from "Modules/Entity/Operator/Saga";

import {
    GetEntityCasinoByIdSuccessAction, SetCasinoIdAction,
} from "Modules/Entity/Operator/Actions";

import MakeSelectCasinoLicensePage from "Modules/License/Casino/Selectors";
import {
    MakeSelectCasinoSelector,
    MakeCasinoSelector,
} from "Modules/Entity/Operator/Selectors";
import { GetOperatorByIdService } from "Modules/Entity/Operator/Service";
import { DeleteOutlined } from "@ant-design/icons";

interface RequestFormType {
    form: any;
}
const casinoKey = "licenseCasinoGameRule";
const key = "licenseCasino";
const entityCasino = "entityCasino";
export const RequestGameRuleCreateForm = ({ form }: RequestFormType) => {
    useInjectReducer({ key: key, reducer: CasinoLicenseReducer });
    useInjectSaga({ key: key, saga: CasinoSaga });

    useInjectReducer({ key: casinoKey, reducer: CasinoLicenseReducer });
    useInjectSaga({ key: casinoKey, saga: CasinoSaga });

    useInjectReducer({ key: entityCasino, reducer: EntityCasinoReducer });
    useInjectSaga({ key: entityCasino, saga: EntityCasinoSaga });

    //fields
    const dispatch = useDispatch();
    const { identificationTypes } = useSelector(MakeSelectCasinoLicensePage());
    const [
        certificateCasinoGameRuleVisibility,
        setCertificateCasinoGameRuleVisibility,
    ] = useState(false);
    const casinoDetail = useSelector(MakeCasinoSelector());
    const casinoOptions = useSelector(MakeSelectCasinoSelector());

    useEffect(() => {
      if (casinoDetail) {
          const model = {
              code: casinoDetail.casino.code,
              formData: {
                  company: { name: casinoDetail.company.name },
                  casino: { address: casinoDetail.casino.address, name: casinoDetail.casino.name },
              },
          };
          form.setFieldsValue(model);
      }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [casinoDetail]);
    // methods

    useEffect(() => {
        return () => {
            dispatch(GetEntityCasinoByIdSuccessAction(null));
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    // event
    const onCasinoChange = (e: any) => {
      const id = e;
      form.setFieldValue("casinoId", id);
      dispatch(SetCasinoIdAction(id));
        GetOperatorByIdService(id, dispatch).then((casinoRs: any) => {
            if (casinoRs) {
                let formData = {
                    company: { name: casinoRs.company.name },
                    casino: {
                        name: casinoRs.casino.name,
                        address: casinoRs.casino.address,
                    },
                };

                form.setFieldsValue({
                    formData: { ...formData },
                });
            }
        });
    //   dispatch(GetCasinoModalDetailAction(null));
    };

    const onDeleteClicked = (gameRule: any) => {
        const gameRuleData = form.getFieldValue("gameRules")
        const removeGameRule = gameRuleData.filter((obj: any) => obj.gameRuleId.key !== gameRule.gameRuleId.key);

        form.setFieldsValue({
            gameRules: [...removeGameRule],
        });
    }

    return (
        <>
            <Form
                name="requestCasinoGameRuleLicenseForm"
                form={form}
                initialValues={{
                    applicant: { id: "" },
                    representative: { id: "" },
                    boardOfDirectors: [],
                }}
                className="p-5"
            >
                <Form.Item name="casinoId" hidden={true}>
                    <Input />
                </Form.Item>
                <Form.Item name="code" hidden={true}>
                    <Input />
                </Form.Item>
                <Form.Item name={["formData", "casino", "name"]} hidden={true}>
                    <Input />
                </Form.Item>
                <Tabs
                    defaultActiveKey="1"
                    items={[
                        {
                            label: "សម្រាប់មន្ត្រី គ.ល.ក.",
                            key: "1",
                            children: (
                                <Row gutter={16}>
                                    <Col span={5}>លេខសំគាល់ពាក្យស្នើសុំ</Col>
                                    <Col span={7}>
                                        <Form.Item name="documentCode">
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col span={5}>ឈ្មោះអ្នកទទួល</Col>
                                    <Col span={7}>
                                        <Form.Item name="receiverName">
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col span={5}>
                                        លេខកូដចំណូលកាតព្វកិច្ចពីល្បែង
                                    </Col>
                                    <Col span={7}>
                                        <Form.Item name="gamblingTaxCode">
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col span={5}>កាលបរិច្ឆេទទទួល</Col>
                                    <Col span={7}>
                                        <Form.Item name="receivedDate">
                                            <DatePicker placeholder="កាលបរិច្ឆេទទទួល" />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            ),
                        },
                        {
                            label: "ព័ត៌មានអំពីក្រុមហ៊ុន",
                            key: "2",
                            children: (
                                <Row>
                                    <Col span={6}>
                                        ឈ្មោះក្រុមហ៊ុន / Company Name
                                    </Col>
                                    <Col span={18}>
                                        <Form.Item
                                            name={[
                                                "formData",
                                                "company",
                                                "name",
                                            ]}
                                            rules={[{ required: true }]}
                                        >
                                            <Input
                                                placeholder="ឈ្មោះក្រុមហ៊ុន / Company Name"
                                                status="warning"
                                                readOnly
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        ឈ្មោះកាស៊ីណូ / Casino Name
                                    </Col>
                                    <Col span={18}>
                                        <Form.Item
                                            name={["formData", "casino", "id"]}
                                            rules={[{ required: true }]}
                                        >
                                            {/* <Input
                                                    placeholder="ឈ្មោះកាស៊ីណូ / Casino Name"
                                                    status="warning"
                                                    readOnly
                                                /> */}
                                            <Select
                                                placeholder="ជ្រើសរើសកាស៊ីណូ / Choose Casino"
                                                onChange={onCasinoChange}
                                                filterOption={(input, option) =>
                                                    option!.children
                                                        .join(" ")
                                                        .toLowerCase()
                                                        .includes(
                                                            input.toLowerCase()
                                                        )
                                                }
                                                showSearch
                                            >
                                                {casinoOptions &&
                                                    casinoOptions.map(
                                                        (item) => (
                                                            <Select.Option
                                                                key={item.value}
                                                                value={
                                                                    item.value
                                                                }
                                                            >
                                                                {item.label}(
                                                                {
                                                                    item.placeholder
                                                                }
                                                                )
                                                            </Select.Option>
                                                        )
                                                    )}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        អាសយដ្ឋនាកាស៊ីណូ / Casino Address
                                    </Col>
                                    <Col span={18}>
                                        <Form.Item
                                            name={[
                                                "formData",
                                                "casino",
                                                "address",
                                            ]}
                                        >
                                            <Input
                                                placeholder="អាសយដ្ឋនាកាស៊ីណូ / Casino Address"
                                                status="warning"
                                                readOnly
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            ),
                        },
                        {
                            label: "ព័ត៌មានអ្នកដាក់ពាក្យស្នើសុំ",
                            key: "3",
                            children: (
                                <>
                                    <Row>
                                        <Col span={6}>
                                            តួនាទីអ្នកដាកពាក្យស្នើសុំ
                                            <br />
                                            Position of Applicant
                                        </Col>
                                        <Col span={18}>
                                            <Form.Item
                                                name={[
                                                    "formData",
                                                    "designatureId",
                                                ]}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message:
                                                            "Please select one",
                                                    },
                                                ]}
                                            >
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col span={6}>
                                            គោត្តនាម និងនាម
                                            <br />
                                            Last name and First name
                                        </Col>
                                        <Col span={18}>
                                            <Row>
                                                <Col span={6}>
                                                    អក្សរខ្មែរ
                                                    <br />
                                                    in Khmer
                                                </Col>
                                                <Col span={18}>
                                                    <Form.Item
                                                        name={[
                                                            "formData",
                                                            "applicant",
                                                            "fullname",
                                                        ]}
                                                        rules={[
                                                            { required: true },
                                                        ]}
                                                    >
                                                        <Input />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={6}>
                                                    អក្សរឡាតាំង
                                                    <br />
                                                    in Latin
                                                </Col>
                                                <Col span={18}>
                                                    <Form.Item
                                                        name={[
                                                            "formData",
                                                            "applicant",
                                                            "latinFullname",
                                                        ]}
                                                        rules={[
                                                            { required: true },
                                                        ]}
                                                    >
                                                        <Input />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={6}>
                                            ភេទ
                                            <br />
                                            Sex
                                        </Col>
                                        <Col span={18}>
                                            <Form.Item
                                                name={[
                                                    "formData",
                                                    "applicant",
                                                    "gender",
                                                ]}
                                                rules={[{ required: true }]}
                                            >
                                                <Radio.Group>
                                                    <Space direction="horizontal">
                                                        <Radio value={1}>
                                                            ប្រុស / Male
                                                        </Radio>
                                                        <Radio value={2}>
                                                            ស្រី / Female
                                                        </Radio>
                                                    </Space>
                                                </Radio.Group>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={6}>
                                            ថ្ងៃខែឆ្នាំកំណើត
                                            <br />
                                            Date of Birth
                                        </Col>
                                        <Col span={18}>
                                            <Form.Item
                                                name={[
                                                    "formData",
                                                    "applicant",
                                                    "dateOfBirth",
                                                ]}
                                                rules={[{ required: true }]}
                                            >
                                                <DatePicker placeholder="ថ្ងៃខែឆ្នាំកំណើត" />
                                            </Form.Item>
                                        </Col>
                                        <Col span={6}>
                                            សញ្ជាតិ
                                            <br />
                                            Nationality
                                        </Col>
                                        <Col span={18}>
                                            <Form.Item
                                                name={[
                                                    "formData",
                                                    "applicant",
                                                    "nationality",
                                                ]}
                                                rules={[{ required: true }]}
                                            >
                                                <Input placeholder="សញ្ជាតិ / Nationality" />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={8}>
                                            <Form.Item
                                                name={[
                                                    "formData",
                                                    "applicant",
                                                    "identityTypeId",
                                                ]}
                                                rules={[{ required: true }]}
                                            >
                                                <Radio.Group>
                                                    <Space direction="vertical">
                                                        {identificationTypes &&
                                                            identificationTypes.map(
                                                                (
                                                                    identificationType: any,
                                                                    index: number
                                                                ) => (
                                                                    <Radio
                                                                        key={
                                                                            identificationType.value
                                                                        }
                                                                        value={
                                                                            identificationType.value
                                                                        }
                                                                    >
                                                                        {
                                                                            identificationType.label
                                                                        }
                                                                    </Radio>
                                                                )
                                                            )}
                                                    </Space>
                                                </Radio.Group>
                                            </Form.Item>
                                        </Col>
                                        <Col span={16}>
                                            <Form.Item
                                                name={[
                                                    "formData",
                                                    "applicant",
                                                    "identityNumber",
                                                ]}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message:
                                                            "Identificaiton number is required",
                                                    },
                                                ]}
                                            >
                                                <Input placeholder="សូមបំពេញ" />
                                            </Form.Item>
                                        </Col>
                                        <Col span={6}>
                                            ថ្ងៃខែឆ្នាំផ្តល់ / Issued Date
                                        </Col>
                                        <Col span={18}>
                                            <Form.Item
                                                name={[
                                                    "formData",
                                                    "applicant",
                                                    "identityIssuedDate",
                                                ]}
                                                rules={[{ required: true }]}
                                            >
                                                <DatePicker />
                                            </Form.Item>
                                        </Col>
                                        <Col span={6}>
                                            ថ្ងៃខែឆ្នាំផុតកំណត / Date of Expiry
                                        </Col>
                                        <Col span={18}>
                                            <Form.Item
                                                name={[
                                                    "formData",
                                                    "applicant",
                                                    "expiredDate",
                                                ]}
                                                rules={[{ required: true }]}
                                            >
                                                <DatePicker />
                                            </Form.Item>
                                        </Col>

                                        <Col span={6}>អាសយដ្ឋាន / Address</Col>
                                        <Col span={18}>
                                            <Form.Item
                                                name={[
                                                    "formData",
                                                    "applicant",
                                                    "address",
                                                ]}
                                                rules={[{ required: true }]}
                                            >
                                                <Input placeholder="អាសយដ្ឋាន / Address" />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={6}>
                                            ទូរស័ព្ទចល័ត / Mobile Phone Number
                                        </Col>
                                        <Col span={18}>
                                            <Form.Item
                                                name={[
                                                    "formData",
                                                    "applicant",
                                                    "mobilePhoneNumber",
                                                ]}
                                                rules={[{ required: true }]}
                                            >
                                                <Input placeholder="ទូរស័ព្ទចល័ត / Mobile Phone Number" />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={6}>
                                            សារអេឡិចត្រូនិច / Email Address
                                        </Col>
                                        <Col span={18}>
                                            <Form.Item
                                                name={[
                                                    "formData",
                                                    "applicant",
                                                    "emailAddress",
                                                ]}
                                                rules={[{ required: true }]}
                                            >
                                                <Input placeholder="សារអេឡិចត្រូនិច / Email Address" />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </>
                            ),
                        },
                        {
                            label: "ព័ត៌មានអំពី វិធានល្បែង",
                            key: "4",
                            children: (
                                <div>
                                    <Form.Item
                                        shouldUpdate={(preValues, curValues) =>
                                            preValues.gameRules !==
                                            curValues.gameRules
                                        }
                                    >
                                        {({ getFieldValue }: any) => {
                                            const gameRules: any[] =
                                                getFieldValue("gameRules") ||
                                                [];

                                            return (
                                                <Table
                                                    bordered
                                                    rowKey="id"
                                                    className="w-full"
                                                    pagination={{
                                                        // position: ["bottomRight"],
                                                        // pageSize: 100,
                                                    }}
                                                    dataSource={gameRules}
                                                    size="small"
                                                >
                                                    <Table.Column
                                                        title="ល.រ / No"
                                                        key="no"
                                                        render={(
                                                            value,
                                                            item,
                                                            index
                                                        ) => <>{index + 1}</>}
                                                    />
                                                    <Table.Column
                                                        title="វិធានល្បែង / Game Rule"
                                                        key="gameRule"
                                                        render={(item: any) => {
                                                            return (
                                                                item.gameRuleId.label
                                                            )
                                                        }
                                                        }
                                                    />
                                                    <Table.Column
                                                        title="ប្រភេទល្បែង / Game Type"
                                                        key="gameType"
                                                        render={(item: any) => (
                                                            <div className="flex flex-row gap-2" key={item.gameRuleId.key}>
                                                                {
                                                                    item.gameTypeIds.map((gameType: any, index: number)=> <span key={gameType.key}>{gameType.label} { index != item.gameTypeIds.length - 1 ? " / " : ""} </span>)
                                                                }
                                                            </div>
                                                        )}
                                                    />
                                                    <Table.Column
                                                        title="ផ្សេងៗ / Other"
                                                        dataIndex="other"
                                                        key="other"
                                                    />
                                                    <Table.Column
                                                        key= "id"
                                                        width= "50px"
                                                        render={ (item: any) => <Space>
                                                                <Popconfirm
                                                                    title="Delete the item"
                                                                    description="Are you sure to delete this item?"
                                                                    onConfirm={() => onDeleteClicked(item)}
                                                                    onCancel={() => console.log("Cancel")}
                                                                    okText="Yes"
                                                                    cancelText="No"
                                                                >
                                                                    <Button type="link" className="p-2" >
                                                                        <DeleteOutlined />
                                                                    </Button>
                                                                </Popconfirm>

                                                            </Space>
                                                        }
                                                    />
                                                </Table>
                                            );
                                        }}
                                    </Form.Item>
                                    <Button
                                        type="primary"
                                        onClick={() =>
                                            setCertificateCasinoGameRuleVisibility(
                                                true
                                            )
                                        }
                                    >
                                        បន្ថែមថ្មី
                                    </Button>
                                </div>
                            ),
                        },
                        {
                            label: "សំណុំឯកសារភ្ជាប់",
                            key: "5",
                            children: (
                                <Row>
                                    <Col span={24}>
                                        <Form.Item
                                            name={[
                                                "formData",
                                                "authorizationLetter",
                                            ]}
                                            valuePropName="checked"
                                        >
                                            <Checkbox>
                                                លិខិតប្រគល់សិទ្ធិ
                                                ចំពោះបុគ្គលដាក់ពាក្យស្នើសុំជាតំណាងស្របច្បាប់
                                                / Authorization letter for
                                                representative
                                            </Checkbox>
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <Form.Item
                                            name={[
                                                "formData",
                                                "specificationList",
                                            ]}
                                            valuePropName="checked"
                                        >
                                            <Checkbox>
                                                លិខិតប្រគល់សិទ្ធិ
                                                ចំពោះបុគ្គលដាក់ពាក្យស្នើសុំជាតំណាងស្របច្បាប់
                                                / Specification list of each
                                                game rule
                                            </Checkbox>
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <Form.Item
                                            name={[
                                                "formData",
                                                "receiptOfApplication",
                                            ]}
                                            valuePropName="checked"
                                        >
                                            <Checkbox>
                                                លិខិតប្រគល់សិទ្ធិ
                                                ចំពោះបុគ្គលដាក់ពាក្យស្នើសុំជាតំណាងស្របច្បាប់
                                                / Receipt of application fee
                                                payment
                                            </Checkbox>
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <Form.Item
                                            name={[
                                                "formData",
                                                "receiptOfInvestigationFee",
                                            ]}
                                            valuePropName="checked"
                                        >
                                            <Checkbox>
                                                លិខិតប្រគល់សិទ្ធិ
                                                ចំពោះបុគ្គលដាក់ពាក្យស្នើសុំជាតំណាងស្របច្បាប់
                                                / Receipt of C.G.M.C
                                                investigation fee
                                            </Checkbox>
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <Form.Item
                                            name={[
                                                "formData",
                                                "representative",
                                                "registrationFeeReceipt",
                                            ]}
                                            valuePropName="checked"
                                        >
                                            <Checkbox>
                                                លិខិតប្រគល់សិទ្ធិ
                                                ចំពោះបុគ្គលដាក់ពាក្យស្នើសុំជាតំណាងស្របច្បាប់
                                                / Registration fee Receipt
                                            </Checkbox>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            ),
                        },
                    ]}
                />
            </Form>

            <CertificateCasinoGameRuleModal
                visibility={certificateCasinoGameRuleVisibility}
                onCancel={() => setCertificateCasinoGameRuleVisibility(false)}
            />
        </>
    );
};
