import React from "react";
import { Form, Input, Button } from "antd";
import { EmptyValue } from "Modules/Entity/Company/Constants";

export const FilterForm = ({ form }: any) => {
    return (
        <Form
            name="entityCompanyListForm"
            layout="vertical"
            className="p-4 w-full grid grid-cols-2 md:grid-cols-4 lg:grid-cols-4 gap-2"
            form={form}
            initialValues={EmptyValue}
        >
            <Form.Item name="name" label="ឈ្មោះក្រុមហ៊ុន">
                <Input />
            </Form.Item>
            <Form.Item className="flex items-end">
                <Button htmlType="submit" type="default">
                    ស្វែងរក
                </Button>
            </Form.Item>


        </Form>
    );
};
