import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { v4 as uuid } from "uuid";
import { useInjectReducer } from "Utility/InjectReducer";
import { useInjectSaga } from "Utility/InjectSaga";

import { HelmetHeader } from "Components/Helmet-Header";
import { ArchiveIcon } from "@heroicons/react/outline";
import { Form, Button } from "antd";
import { FullLayout } from "Components/Layouts/";
import { RequestGameEquipmentCreateForm } from "Modules/Certificate/Casino/ApplicationForm/GameEquipment/Forms/RequestGameEquipmentCreateForm";

import Reducer from "Modules/Certificate/Casino/ApplicationForm/GameEquipment/Reducer";
import Saga from "Modules/Certificate/Casino/ApplicationForm/GameEquipment/Saga";
import {
    SetRequestGameEquipmentValueAction,
    CreateRequestGameEquipmentRequestAction,
} from "Modules/Certificate/Casino/ApplicationForm/GameEquipment/Actions";
import { GetIdentificationTypeOptionService, GetOperatorOptionService } from "Modules/Entity/Operator/Service";
import { SelectableIdentificationTypeSuccessAction } from "Modules/License/Casino/Actions";
import { GetGameRuleListService } from "Modules/Settings/GameRule/Service";
import { QueryGameRuleSuccessAction, SelectGameRuleSuccessAction } from "Modules/Settings/GameRule/Actions";
import { SelectCasinoSuccessAction } from "Modules/Entity/Operator/Actions";
import { AsyncEndAction, AsyncStartAction } from "Modules/Application/Actions";

const key = "certificateGameEquipment";
export const RequestGameEquipmentCreate = () => {
    useInjectReducer({ key, reducer: Reducer });
    useInjectSaga({ key, saga: Saga });
    // fields
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();
    const entityType = searchParams.get("entityType");

    // constructor
    useEffect(() => {
        const entityTypeId = entityType === "LOTTERY" ? 2 : 1;
        const model = {
            entityType: entityTypeId,
            search: ""
        }
        dispatch(AsyncStartAction());
        GetIdentificationTypeOptionService(dispatch).then((nationalityRs: any) => {
            if(nationalityRs) {
                dispatch(SelectableIdentificationTypeSuccessAction(nationalityRs));
            }
            GetGameRuleListService(model, dispatch).then((gameTypeOption: any) => {
                if(gameTypeOption) {
                    dispatch(QueryGameRuleSuccessAction(gameTypeOption));
                }
                GetOperatorOptionService(dispatch).then((casinoOptionsRs: any) => {
                    if(casinoOptionsRs) {
                        dispatch(SelectCasinoSuccessAction(casinoOptionsRs));
                    }
                    dispatch(AsyncEndAction());
                });
            });
        });
        

        return () => {
            dispatch(SelectableIdentificationTypeSuccessAction([]));
            dispatch(SelectCasinoSuccessAction([]));
            dispatch(SelectGameRuleSuccessAction([]));
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const submitCasinoRuleLicenseForm = (name: string, values: any) => {
        // await form.validateFields();

        let licenseModel = {
            ...form.getFieldsValue(),
            entityName: "Casino Game Equipment",
            formType: "C004",
            CompanyNameInKhmer: values.formData.company.name,
            CasinoNameInKhmer: values.formData.casino.name,
            casinoId: values.casinoId,
            code: values.code,
            applicantName: values.formData.applicant.fullname,
            applicantNationality: values.formData.applicant.nationality,
            applicantContactNumber: values.formData.applicant.mobilePhoneNumber,
            representativeName: values.formData.applicant.fullname,
            representativeNationality: values.formData.applicant.nationality,
            representativeContactNumber:
                values.formData.applicant.mobilePhoneNumber,
            formData: {
                ...values.formData,
                gameEquipments: form.getFieldValue("gameEquipments"),
            },
        };
        dispatch(SetRequestGameEquipmentValueAction(licenseModel));
        dispatch(CreateRequestGameEquipmentRequestAction());
    };

    const onGameEquipmentModalFormSubmit = (
        requestCasinoGameEquipmentLicenseForm: any,
        values: any
    ) => {
        const gameRules = form.getFieldValue("gameEquipments") || [];
        form.setFieldValue("gameEquipments", [...gameRules, { id: uuid(), ...values }]);
    };

    const ActionButton = () => {
        return (
            <Button
                onClick={() => {
                    form.submit();
                }}
                type="primary"
            >
                រក្សាទុក
            </Button>
        );
    };

    // events
    const onFormProviderSubmit = (name: string, { values, forms }: any) => {
        switch (name) {
          case "requestCasinoGameEquipmentLicenseForm":
              submitCasinoRuleLicenseForm(name, values);
              break;
          case "gameEquipmentModalForm":
              const { requestCasinoGameEquipmentLicenseForm } = forms;
              onGameEquipmentModalFormSubmit(
                  requestCasinoGameEquipmentLicenseForm,
                  values
              );
              break;
          default: {
              break;
          }
        }
    };

    const onDeleteEquipment = (item: any) => {
        const gameRules = form.getFieldValue("gameEquipments") || [];
        const index = gameRules.indexOf(item);
        if (index > -1) {
            gameRules.splice(index, 1);
            form.setFieldValue("gameEquipments", [...gameRules]);
        }
    }


    return (
        <FullLayout
            icon={<ArchiveIcon />}
            title="ស្នើសុំចុះបញ្ជី ឧបករណ៍/កម្មវិធីល្បែង (C004)"
            descriptions="ត្រលប់ទៅបញ្ជី"
            redirectUrl="/certificate/casino/request-game-rule/request"
            actions={<ActionButton />}
        >
            <HelmetHeader meta="ស្នើសុំចុះបញ្ជី ឧបករណ៍/កម្មវិធីល្បែង" />
            <Form.Provider onFormFinish={onFormProviderSubmit}>
                <RequestGameEquipmentCreateForm form={form} onDeleteEquipment={onDeleteEquipment} onEquipmentCreate={console.log("Test")} />
            </Form.Provider>
        </FullLayout>
    );
};
