import produce from "immer";
import {
    CasinoConstant,
    SET_REQUEST_CASINO_LICENSE_FILTER_VALUE,
    SET_REQUEST_CASINO_LICENSE_VALUE,
    LIST_REQUEST_CASINO_LICENSE_SUCCESS,
    SET_REQUEST_CASINO_LICENSE_ID,
    GET_REQUEST_CASINO_LICENSE_SUCCESS
} from "Modules/License/Casino/Constants";

export const CasinoLicenseInitialState = {
    identificationTypes: [],
    applicationTypes: [],
    casinoTypes: [],
    nationality: [],
    errors: [],
    requestCasinoFilterValue: null,
    requestCasinoValue: null,
    requestCasinoLicenses: [],
    requestCasinoLicense: null,
    requestCasinoLicenseId: null,
};

const CasinoLicenseReducer = produce((draft, action) => {

    switch (action.type) {
        case CasinoConstant.SELECTABLE_CASINO_TYPE_SUCCESS:
            draft.casinoTypes = action.casinoTypes;
            break;
        case CasinoConstant.SELECTABLE_NATIONALITY_SUCCESS:
            draft.nationality = action.nationality;
            break;
        case CasinoConstant.SELECTABLE_APPLICATION_TYPE_SUCCESS:
            draft.applicationTypes = action.applicationTypes;
            break;
        case CasinoConstant.SELECTABLE_IDENTIFICATION_TYPE_SUCCESS:
            draft.identificationTypes = action.identificationTypes;
            break;
        case SET_REQUEST_CASINO_LICENSE_FILTER_VALUE:
            draft.requestCasinoFilterValue = action.filterValues;
            break;
        case LIST_REQUEST_CASINO_LICENSE_SUCCESS:
            draft.requestCasinoLicenses = action.requestCasinoLicenses;
            break;
        case SET_REQUEST_CASINO_LICENSE_VALUE:
            draft.requestCasinoValue = action.values;
            break;
        case SET_REQUEST_CASINO_LICENSE_ID:
            draft.requestCasinoLicenseId = action.id;
            break;
        case GET_REQUEST_CASINO_LICENSE_SUCCESS:
            draft.requestCasinoLicense = action.requestCasinoLicense;
            break;
        default: break;
    }
}, CasinoLicenseInitialState);
export default CasinoLicenseReducer;
