import { CasinoEquipmentMaster } from "Modules/Equipments/Casino/List";
import { CreateCasinoEquipmentMaster } from "Modules/Equipments/Casino/Create";
import { CasinoEquipmentMasterDetail } from "Modules/Equipments/Casino/Detail";

export const CasinoEquipmentRoute = [
    {
        path: "",
        children: [
            { path: ":id", element: <CasinoEquipmentMaster /> },
            { path: "create/:id", element: <CreateCasinoEquipmentMaster /> },
            { path: "detail/:id", element: <CasinoEquipmentMasterDetail /> },
        ],
    },
];
