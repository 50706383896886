import { InspectionCertificateTypeInitialState } from "Modules/Settings/InspectionCertificateType/Reducer";
import { createSelector } from "reselect";

const InspectionCertificateTypeDomain = (state) => state.inspectionCertificateType || InspectionCertificateTypeInitialState;

const MakeInspectionCertificateTypeFilterValueSelector = () => createSelector(InspectionCertificateTypeDomain, substate => substate.filterValues);
const MakeInspectionCertificateTypeValueSelector = () => createSelector(InspectionCertificateTypeDomain, substate => substate.values);
const MakeInspectionCertificateTypeIdSelector = () => createSelector(InspectionCertificateTypeDomain, substate => substate.id);

const MakeInspectionCertificateTypeListSelector = () => createSelector(InspectionCertificateTypeDomain, substate => substate.entities);
const MakeInspectionCertificateTypeSelector = () => createSelector(InspectionCertificateTypeDomain, substate => substate.entity);
const MakeInspectionCertificateTypeOptionsSelector = () => createSelector(InspectionCertificateTypeDomain, substate => substate.options);
const MakeInspectionCertificateTypeStatusSelector = () => createSelector(InspectionCertificateTypeDomain, substate => substate.status);

export {
    MakeInspectionCertificateTypeFilterValueSelector,
    MakeInspectionCertificateTypeValueSelector,
    MakeInspectionCertificateTypeIdSelector,
    MakeInspectionCertificateTypeListSelector,
    MakeInspectionCertificateTypeSelector,
    MakeInspectionCertificateTypeOptionsSelector,
    MakeInspectionCertificateTypeStatusSelector
}