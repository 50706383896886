import {
    Form,
    Input,
    Select
} from "antd"
export const TableEquipomentForm = ({ form, gameRules, equipmentTypes, currencies }: any) =>
    <Form name="tableEquipmentForm" className="grid grid-cols-4 gap-x-2" layout="vertical" form={form}>
        <Form.Item name="gameRule" label="Game Rule">
            <Select showSearch labelInValue>
                {gameRules && gameRules.map((gameRule) => <Select.Option key={gameRule.id} value={gameRule.id}>{gameRule.name}</Select.Option>)}
            </Select>
        </Form.Item>
        <Form.Item name="terminalId" label="Table ID">
            <Input />
        </Form.Item>
        <Form.Item name="equipmentType" label="Equipment Type">
            <Select showSearch labelInValue>
                {equipmentTypes && equipmentTypes.map((equipmentType) => <Select.Option key={equipmentType.id} value={equipmentType.id}>{equipmentType.name}</Select.Option>)}
            </Select>
        </Form.Item>
        <Form.Item name="zonenpm stId" label="Zone">
            <Input />
        </Form.Item>
        <Form.Item name="seat" label="Seat">
            <Input />
        </Form.Item>
        <Form.Item name="currencyId" label="Currency">
            <Select showSearch>
                {
                    currencies && currencies.map((equipmentType) => <Select.Option key={equipmentType.id} value={equipmentType.id}>{equipmentType.name}</Select.Option>)
                }
            </Select>
        </Form.Item>
        <Form.Item name="minimumBet" label="Min. Bet">
            <Input />
        </Form.Item>
        <Form.Item name="maximumBet" label="Max. Bet">
            <Input />
        </Form.Item>
        <Form.Item name="note" label="Note" className="col-span-4">
            <Input.TextArea />
        </Form.Item>
    </Form>
