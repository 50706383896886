import {
    Form,
    Table,
    Space
} from "antd";
import { FullLayout } from "Components/Layouts"
import { FormTypeFilterForm } from "Modules/Settings/FormType/Forms/FormTypeFilterForm";

export const FormTypeList = () => {
    // fields
    const [form] = Form.useForm();

    const formTypeColumns = [
        {
            key: "code",
            title: "កូដ",
            dataIndex: "code",
        },
        {
            key: "name",
            title: "Form Type",
            dataIndex: "name",
        },
    ]
    return (
        <FullLayout
            icon={null}
            title="ប្រភេទឯកសារ"
            descriptions=""
            redirectUrl=""
            actions={<Space></Space>}>
            <FormTypeFilterForm form={form} />
            <Table rowKey="id" size="small" columns={formTypeColumns} dataSource={[]} />
        </FullLayout>
    )


}