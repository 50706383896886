import React from "react";
import { List } from "Modules/Certificate/Casino/ApplicationForm/GameType/List";

import { RequestGameRuleList } from "Modules/Certificate/Casino/ApplicationForm/GameRule/RequestGameRuleList";
import { RequestGameRuleCreate } from "Modules/Certificate/Casino/ApplicationForm/GameRule/RequestGameRuleCreate";
import { RequestGameRuleDetail } from "Modules/Certificate/Casino/ApplicationForm/GameRule/RequestGameRuleDetail";

export const CertificateGameRuleRoute = [
    {
        path: "request",
        children: [
            { path: "", element: <RequestGameRuleList /> },
            { path: "create", element: <RequestGameRuleCreate /> },
            { path: ":id", element: <RequestGameRuleDetail /> },
        ],
    },
    {
        path: "",
        children: [
            { path: "", element: <List /> },
            // { path: "create", element: <RequestGameTypeCreate /> },
            // { path: ":id", element: <RequestGameTypeDetail /> },
        ],
    },
];
