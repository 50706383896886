import { GET_INSPECTION_CERTIFICATE_SERIALS_REQUEST, PRINT_INSPECTION_CERTIFICATE_PRINT_BATCH_REQUEST, REQPORT_INSPECTION_CERTIFICATE_DETAIL_REQUEST } from './Constants';
import { all, put, call, select, takeLatest } from "redux-saga/effects";
import { DELETE, GET, POST, PUT } from "Utility/Constant";
import ApiEndpoint from "Utility/Endpoint";
import Request from "Utility/Http";
import { v4 as uuid } from "uuid";

import { AsyncEndAction, AsyncStartAction } from "Modules/Application/Actions";

import {
    ListInspectionCertificateMasterSuccessAction,
    GetInspectionCertificateMasterSuccessAction,
    GetGtinNumberSuccessAction,
    SetInspectionCertificateMasterStatusAction,
    PrintInspectionCertificateDetailSuccessAction,
    GetInspectionCertificateMasterRequestAction,
    PrintInspectionCertificatePrintDetailSuccessAction,
    PrintInspectionCertificatePrintBatchSuccessAction,
    ReportInspectionCertificateDetailSuccessAction,
    GetInspectionCertificateSerialSuccessAction,

} from "Modules/Settings/InspectionCertificateMaster/Actions";
import {
    MakeInspectionCertificateDetailIdSelector,
    MakeInspectionCertificateDetailValueSelector,
    MakeInspectionCertificateMasterFilterValueSelector,
    MakeInspectionCertificateMasterIdSelector,
    MakeInspectionCertificateMasterValueSelector,
    MakeReportFilterValueSelector,
    MakeSerialsSerlector,
} from "Modules/Settings/InspectionCertificateMaster/Selectors";
import { ShowMessage } from "Common/Saga";
import {
    CREATE_INSPECTION_CERTIFICATE_MASTER_REQUEST,
    DELETE_INSPECTION_CERTIFICATE_DETAIL_REQEUEST,
    EDIT_INSPECTION_CERTIFICATE_DETAIL_REQUEST,
    GET_INSPECTION_CERTIFICATE_MASTER_REQUEST,
    LIST_INSPECTION_CERTIFICATE_MASTER_REQUEST,
    GET_GTIN_NUMBER_REQUEST,
    PRINT_INSPECTION_CERTIFICATE_DETAIL_REQUEST,
    PRINT_INSPECTION_CERTIFICATE_PRINT_DETAIL_REQUEST,
} from "Modules/Settings/InspectionCertificateMaster/Constants";
import { MakePrintParamsSelector } from "Modules/Application/Selectors";
import { SelectInspectionCertificateType } from '../InspectionCertificateType/Saga';


function* ListInspectionCertificateMaster() {
    yield put(AsyncStartAction());
    try {
        const filter = yield select(MakeInspectionCertificateMasterFilterValueSelector());
        const url = `${ApiEndpoint.INSPECTION_CERTIFICATE_MASTER_PATH}/lists`;
        const payload = ApiEndpoint.makeApiPayload(url, POST, filter || {});
        const response = yield call(Request, payload);
        if (response && !response.status) {
            yield put(ListInspectionCertificateMasterSuccessAction(response));
        }

    } catch {
        yield ShowMessage({
            type: "error",
            message: "មានបញ្ហាពេលទាញយកទិន្នន័យ",
            id: uuid(),
        })

    } finally {
        yield put(AsyncEndAction());
    }
}
function* GetInspectionCertificateMaster() {
    yield SelectInspectionCertificateType();
    yield put(AsyncStartAction());
    try {
        const id = yield select(MakeInspectionCertificateMasterIdSelector());
        const url = `${ApiEndpoint.INSPECTION_CERTIFICATE_MASTER_PATH}/${id}`;
        const payload = ApiEndpoint.makeApiPayload(url, GET, id);
        const response = yield call(Request, payload);
        if (response && !response.status) {
            yield put(GetInspectionCertificateMasterSuccessAction(response));
        }

    } catch {
        yield ShowMessage({
            type: "error",
            message: "មានបញ្ហាពេលទាញយកទិន្នន័យ",
            id: uuid(),
        })

    } finally {
        yield put(AsyncEndAction());
    }
}

function* CreateInspectionCertificateMaster() {
    yield put(AsyncStartAction());
    try {
        const model = yield select(MakeInspectionCertificateMasterValueSelector());
        const url = ApiEndpoint.INSPECTION_CERTIFICATE_MASTER_PATH;
        const payload = ApiEndpoint.makeApiPayload(url, POST, model);
        const response = yield call(Request, payload);
        if (response && !response.snack && !response.status) {
            yield ShowMessage({
                type: "success",
                message: "ទិន្នន័យបានរក្សារទុក",
                redirectUrl: `/settings/inspection-certificate-master/${response.id}`,
                id: uuid(),
            })
        }

    } catch {
        yield ShowMessage({
            type: "error",
            message: "មានបញ្ហាពេលរក្សារទុក",
            id: uuid(),
        })

    } finally {
        yield put(AsyncEndAction());
    }
}

function* ReportInspectionCertificateDetail() {
  yield put(AsyncStartAction());
  try {
    const filter = yield select(MakeReportFilterValueSelector());
    const url = `${ApiEndpoint.INSPECTION_CERTIFICATE_MASTER_PATH}/reports`;
    const payload = ApiEndpoint.makeApiPayload(url, POST, filter || {});
    const response = yield call(Request, payload);
    if (response && !response.status) {
      yield put(ReportInspectionCertificateDetailSuccessAction(response));
    }
  } catch {
    yield ShowMessage({
      type: "error",
      message: "មានបញ្ហាពេលទាញយកទិន្នន័យ",
      id: uuid(),
    })
  } finally {
      yield put(AsyncEndAction());
  }
}

function* DeleteInspectionCertificateDetail() {
    yield put(AsyncStartAction());
    try {
        const id = yield select(MakeInspectionCertificateDetailIdSelector());
        const url = `${ApiEndpoint.INSPECTION_CERTIFICATE_DETAIL_PATH}/${id}`;
        const payload = ApiEndpoint.makeApiPayload(url, DELETE, id);
        const response = yield call(Request, payload);
        if (response && !response.status) {
            // yield put(GetInspectionCertificateMasterSuccessAction(response));
            yield ShowMessage({
                type: "success",
                message: "ទិន្នន័យបានលុប",
                id: uuid(),
            })
        }

    } catch {
        yield ShowMessage({
            type: "error",
            message: "មានបញ្ហាពេលទាញយកទិន្នន័យ",
            id: uuid(),
        });

    } finally {
        yield put(AsyncEndAction());
    }
}

function* EditInspectionCertificateDetail() {
    yield put(AsyncStartAction());
    try {
        const model = yield select(MakeInspectionCertificateDetailValueSelector());
        const url = `${ApiEndpoint.INSPECTION_CERTIFICATE_DETAIL_PATH}`;
        const payload = ApiEndpoint.makeApiPayload(url, PUT, model);
        const response = yield call(Request, payload);
        if (response && !response.snack && !response.status) {
            // yield put(GetInspectionCertificateMasterSuccessAction(response));
            yield ShowMessage({
                type: "success",
                message: "ទិន្នន័យបានរក្សារទុក",
                id: uuid(),
            })
        }

    } catch {
        yield ShowMessage({
            type: "error",
            message: "មានបញ្ហាពេលទាញយកទិន្នន័យ",
            id: uuid(),
        });

    } finally {
        yield put(AsyncEndAction());
    }
}

function* GetGtinNumber() {
    yield put(AsyncStartAction());
    try {
        const url = `${ApiEndpoint.INSPECTION_CERTIFICATE_DETAIL_PATH}/gtin`
        const payload = ApiEndpoint.makeApiPayload(url, GET, {});
        const response = yield call(Request, payload);
        if (response && !response.status && !response.snack) {
            yield put(GetGtinNumberSuccessAction(response));
            yield put(SetInspectionCertificateMasterStatusAction(4));
        }
    }
    catch {

    }
    finally {
        yield put(AsyncEndAction());
    }
}

function* PrintInspectionCertificate() {
    const id = yield select(MakeInspectionCertificateDetailIdSelector());
    if (!id) {
        return yield ShowMessage({
            type: "error",
            message: "មានបញ្ហាពេលទាញយកទិន្នន័យ",
            id: uuid(),
        })
    }
    yield put(AsyncStartAction());
    try {
        const url = `${ApiEndpoint.INSPECTION_CERTIFICATE_DETAIL_PATH}/print/${id}`
        const payload = ApiEndpoint.makeApiPayload(url, GET, id);
        const response = yield call(Request, payload);
        if (response && !response.status && !response.snack) {
            yield put(PrintInspectionCertificateDetailSuccessAction());
            yield put(GetInspectionCertificateMasterRequestAction());
        }
    }
    catch {

    }
    finally {
        yield put(AsyncEndAction());
    }
}

export function* GetInspectionCertificateSerials() {
    const serials = yield select(MakeSerialsSerlector());
    yield put(AsyncStartAction());
    try {
        const url = `${ApiEndpoint.INSPECTION_CERTIFICATE_DETAIL_PATH}/serials`
        const payload = ApiEndpoint.makeApiPayload(url, POST, {serials: serials});
        const response = yield call(Request, payload);
        if (response && !response.status && !response.snack) {
            yield put(GetInspectionCertificateSerialSuccessAction(response));
        }
    }
    catch {

    }
    finally {
        yield put(AsyncEndAction());
    }
}

function* PrintInspectionCertificateBatch() {
    const model = yield select(MakePrintParamsSelector());
    
    if (!model) {
        return yield ShowMessage({
            type: "error",
            message: "មានបញ្ហាពេលទាញយកទិន្នន័យ",
            id: uuid(),
        })
    }
    yield put(AsyncStartAction());
    try {
        const url = `${ApiEndpoint.INSPECTION_CERTIFICATE_MASTER_PATH}/print-ids`
        const payload = ApiEndpoint.makeApiPayload(url, POST, {SerialIds: model});
        const response = yield call(Request, payload);
        if (response && !response.status && !response.snack) {
            yield put(PrintInspectionCertificatePrintBatchSuccessAction(response));
        }
    }
    catch {

    }
    finally {
        yield put(AsyncEndAction());
    }
}

function* PrintInspectionCertificatePrintDetail() {
    const id = yield select(MakeInspectionCertificateDetailIdSelector());
    if (!id) {
        return yield ShowMessage({
            type: "error",
            message: "មានបញ្ហាពេលទាញយកទិន្នន័យ",
            id: uuid(),
        })
    }
    yield put(AsyncStartAction());
    try {
        const url = `${ApiEndpoint.INSPECTION_CERTIFICATE_DETAIL_PATH}/print-detail/${id}`
        const payload = ApiEndpoint.makeApiPayload(url, GET, id);
        const response = yield call(Request, payload);
        if (response && !response.status && !response.snack) {
            yield put(PrintInspectionCertificatePrintDetailSuccessAction(response));
        }
    }
    catch {

    }
    finally {
        yield put(AsyncEndAction());
    }
}

export default function* InspectionCertificateMasterSaga() {
    yield all([
        yield takeLatest(LIST_INSPECTION_CERTIFICATE_MASTER_REQUEST, ListInspectionCertificateMaster),
        yield takeLatest(GET_INSPECTION_CERTIFICATE_MASTER_REQUEST, GetInspectionCertificateMaster),
        yield takeLatest(CREATE_INSPECTION_CERTIFICATE_MASTER_REQUEST, CreateInspectionCertificateMaster),
        //detail
        yield takeLatest(DELETE_INSPECTION_CERTIFICATE_DETAIL_REQEUEST, DeleteInspectionCertificateDetail),
        yield takeLatest(EDIT_INSPECTION_CERTIFICATE_DETAIL_REQUEST, EditInspectionCertificateDetail),
        yield takeLatest(REQPORT_INSPECTION_CERTIFICATE_DETAIL_REQUEST, ReportInspectionCertificateDetail),
        yield takeLatest(GET_INSPECTION_CERTIFICATE_SERIALS_REQUEST, GetInspectionCertificateSerials),
        // gtin
        yield takeLatest(GET_GTIN_NUMBER_REQUEST, GetGtinNumber),
        // print
        yield takeLatest(PRINT_INSPECTION_CERTIFICATE_DETAIL_REQUEST, PrintInspectionCertificate),
        yield takeLatest(PRINT_INSPECTION_CERTIFICATE_PRINT_DETAIL_REQUEST, PrintInspectionCertificatePrintDetail),
        yield takeLatest(PRINT_INSPECTION_CERTIFICATE_PRINT_BATCH_REQUEST, PrintInspectionCertificateBatch),
    ]);
}