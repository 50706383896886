import { ShowFormattedAlert, ShowMessage } from "Common/Saga";
import {
    GetProfileAction,
    GetProfileSuccessAction,
    IsLoggedErrorAction,
    IsLoggedSuccessAction,
    LogoutErrorAction,
    AsyncEndAction,
    AsyncStartAction,
    LogoutAction,
    SetPermissionValueAction,
    SetUploadStatusAction,
    UploadFileSuccessAction,
    AuthenticationAppSuccessAction
} from "Modules/Application/Actions";
import { ClearSnackMessageAction } from "Components/SnackMessage/Actions";
import {
    GET_PROFILE_REQUEST,
    LOGOUT,
    REFRESH_TOKEN,
    DASHBOARD_REQUEST_REDIRECT,
    AUTHENTICATION_APP_REQUEST,
    CHANGE_PASSWORD_REQUEST,
    UPLOAD_FILE_REQUEST,
} from "Modules/Application/Constants";
import { call, put, all, takeLatest, select } from "redux-saga/effects";
import ApiEndpoint, { CORE_UPLOAD_FILE_PATH } from "Utility/Endpoint";
import { POST, GET } from "Utility/Constant";
import AuthenticationRequest from "Utility/AuthenticationHttp";
import { decodePermission } from "Utility/Permission";
import { MakeAuthenticationAppRequestIdSelector, MakePasswordValueSelector, MakeUploadFileSelector } from "Modules/Application/Selectors";

import { v4 as uuid } from "uuid";

/**
 *  query logged in user profile
 * @returns {IterableIterator<*>}
 */
export function* handleProfile(): any {
    const requestUrl = ApiEndpoint.getProfilePath();
    const payload = ApiEndpoint.makeApiPayload(requestUrl, GET);
    try {
        const Response = yield call(AuthenticationRequest, payload);

        if (Response) {

            yield put(IsLoggedSuccessAction());
            yield put(GetProfileSuccessAction(Response));
            // return yield put(GetProfileSuccessAction(Response));
        }

    } catch (error: any) {
        return yield put(IsLoggedErrorAction());
    }
}

/**
 * logout logged in user
 * @returns {IterableIterator<*>}
 */
export function* handleLogout(): any {
    const requestUrl = ApiEndpoint.getLogoutPath();
    const item: any = window.localStorage.getItem("cgmcToken");
    const model = { accessToken: item };
    const payload = ApiEndpoint.makeApiPayload(
        requestUrl,
        POST,
        model,
        null,
        false,
        true
    );

    try {
        const Response = yield call(AuthenticationRequest, payload);

        if (Response?.status === 200) {
            window.localStorage.removeItem("cgmcToken");
            // yield put(LogoutSuccessAction());
            window.location.href = "https://core.cgmc.gov.kh/security/logout"
        }
    } catch (error: any) {
        return yield put(LogoutErrorAction(error));
    }
}

/**
 * handle refresh token action
 * @returns {IterableIterator<*>}
 */
export function* handleRefreshToken(): any {
    yield put(AsyncStartAction());
    const requestUrl = ApiEndpoint.getRefreshTokenPath();
    const requestPayload = ApiEndpoint.makeApiPayload(requestUrl, POST);

    try {
        yield call(AuthenticationRequest, requestPayload);

        return yield put(GetProfileAction());
    } catch (error) {
        yield put(IsLoggedErrorAction());
        yield put(AsyncEndAction());
        return yield ShowFormattedAlert("error", "Invalid Refresh");
    }
}

export function* GetRequestId(): any {
    yield put(AsyncStartAction());
    const requestUrl = ApiEndpoint.getRequestIdPath();
    const requestPayload = ApiEndpoint.makeApiPayload(requestUrl, POST);

    try {
        yield call(AuthenticationRequest, requestPayload);
        yield put(AsyncEndAction());
    } catch (error) {
        yield put(IsLoggedErrorAction());
        yield put(AsyncEndAction());
        return yield ShowFormattedAlert("error", "Invalid Refresh");
    }
}

export function* AuthenticationAppRequest(): any {
    yield put(AsyncStartAction());
    const formValues = yield select(MakeAuthenticationAppRequestIdSelector());
    const payload = {
        requestId: formValues,
        isAuthenticated: false
    };
    const requestUrl = "/api/auth/redirect-login";
    const requestPayload = ApiEndpoint.makeApiPayload(
        requestUrl,
        POST,
        payload,
        null,
        true
    );

    try {
        const Response = yield call(AuthenticationRequest, requestPayload);

        if (Response && !Response.status && !Response.snack) {
            const grants = decodePermission(Response.token);
            yield put(SetPermissionValueAction(grants));
            localStorage.setItem("cgmcToken", Response.token);
            yield put(GetProfileAction());
            yield put(AuthenticationAppSuccessAction(1));
        }


    } catch (error: any) {

        if (error.data && error.data.status === 422) {
        }

        if (error.data && error.status === 400) {
            yield ShowMessage({
                type: "error",
                message: "ខុសឈ្មោះអ្នកប្រើ ឬលេខសម្ងាត់",
                content: "",
                id: uuid(),
            });
        }
        put(AuthenticationAppSuccessAction(2));
    }
    finally {
        yield put(AsyncEndAction());
    }
}


export function* ChangePassword(): any {
    yield put(AsyncStartAction());
    const formValues = yield select(MakePasswordValueSelector());
    const requestUrl = "/api/auth/change-password";
    const requestPayload = ApiEndpoint.makeApiPayload(
        requestUrl,
        POST,
        formValues,
        null,
        true
    );

    try {
        const Response = yield call(AuthenticationRequest, requestPayload);
        yield put(AsyncEndAction());
        yield put(ClearSnackMessageAction());

        if (Response) {
            return yield put(LogoutAction());
        }
    } catch (error: any) {
        yield put(AsyncEndAction());
        if (error.data && error.data.status === 422) {
        }

        if (error.data && error.status === 400) {
            yield ShowMessage({
                type: "error",
                message: "ខុសឈ្មោះអ្នកប្រើ ឬលេខសម្ងាត់",
                content: "",
                id: uuid(),
            });
        }
    }
}

function* UploadFile() {
    try {
        yield put(AsyncStartAction());
        const model = yield select(MakeUploadFileSelector());
        const url = CORE_UPLOAD_FILE_PATH;
        const payload = ApiEndpoint.makeApiPayload(url, POST, model);
        const response = yield call(AuthenticationRequest, payload);

        if (response && !response.snack) {
            yield put(UploadFileSuccessAction(response));
            yield put(SetUploadStatusAction(1));
        }
    } catch {
        
    }
    finally {
        yield put(AsyncEndAction());
    }
}

export default function* ApplicationSaga() {
    yield all([
        yield takeLatest(LOGOUT, handleLogout),
        yield takeLatest(GET_PROFILE_REQUEST, handleProfile),
        yield takeLatest(REFRESH_TOKEN, handleRefreshToken),
        yield takeLatest(DASHBOARD_REQUEST_REDIRECT, GetRequestId),
        yield takeLatest(AUTHENTICATION_APP_REQUEST, AuthenticationAppRequest),
        yield takeLatest(CHANGE_PASSWORD_REQUEST, ChangePassword),
        yield takeLatest(UPLOAD_FILE_REQUEST, UploadFile)
    ]);
}
