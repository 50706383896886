import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { useInjectReducer } from "Utility/InjectReducer";
import { useInjectSaga } from "Utility/InjectSaga";

import SnackMessage from "Components/SnackMessage/";
import RootRouter from "Routers";

import ApplicationReducer from "Modules/Application/Reducer";
import ApplicationSaga from "Modules/Application/Saga";
import CasinoLicenseReducer from "Modules/License/Casino/Reducer";
import CasinoSaga from "Modules/License/Casino/Saga";

import { MakeIsLoggedSelector } from "Modules/Application/Selectors";
import { LogoutSuccessAction } from "Modules/Application/Actions";

const key = "application";
export default function Bootstrap() {
    useInjectReducer({ key, reducer: ApplicationReducer });
    useInjectSaga({ key, saga: ApplicationSaga });
    useInjectReducer({ key: "licenseCasino", reducer: CasinoLicenseReducer });
    useInjectSaga({ key: "licenseCasino", saga: CasinoSaga });

    // fields
    const dispatch = useDispatch();
    const isLogged = useSelector(MakeIsLoggedSelector());

    useEffect(() => {
        if (isLogged) {
            const cgmcToken = localStorage.getItem("cgmcToken");
            if (!cgmcToken) {
                dispatch(LogoutSuccessAction());
            }
            else {
                // dispatch(SelectableCasinoOptionAction());
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLogged])

    return (
        <>
            <Helmet titleTemplate="%s - CGMC" defaultTitle="CGMC">
                <meta name="description" content="CGMC" />
            </Helmet>

            <BrowserRouter>
                <SnackMessage />
                <RootRouter />
            </BrowserRouter>
        </>
    );
}
