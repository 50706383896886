import React from "react";
import { Outlet } from "react-router-dom";
import { CertificateGameTypeRoute } from "Modules/Certificate/Casino/ApplicationForm/GameType/";
import { CertificateGameRuleRoute } from "Modules/Certificate/Casino/ApplicationForm/GameRule";
import { CertificateGameEquipmentRoute } from "Modules/Certificate/Casino/ApplicationForm/GameEquipment";
import { CerticiateCasinoVerifyPage } from "./Verify";

export const CertificateCasinoRoute = [
    {
        path: "",
        element: <Outlet />,
        children: [
            {
                path: "request-game-type",
                element: <Outlet />,
                children: CertificateGameTypeRoute,
            },
            {
                path: "request-game-rule",
                element: <Outlet />,
                children: CertificateGameRuleRoute,
            },
            {
                path: "request-game-equipment",
                element: <Outlet />,
                children: CertificateGameEquipmentRoute,
            },
            {
                path: "verify",
                element: <CerticiateCasinoVerifyPage />,
            },
        ],
    },
];
