import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useInjectReducer } from "Utility/InjectReducer";
import { useInjectSaga } from "Utility/InjectSaga";
import { Form, Row, Col, Input, Select, Button } from "antd";
import { EmptyValue } from "Modules/Entity/Operator/Constants";

import CasinoStatusReducer from "Modules/Settings/CasinoStatus/Reducer";
import CasinoStatusSaga from "Modules/Settings/CasinoStatus/Saga";
import { MakeSelectCasinoStatusSelector } from "Modules/Settings/CasinoStatus/Selectors";
import {
    SelectCasinoStatusRequestAction,
    SelectCasinoStatusSuccessAction,
} from "Modules/Settings/CasinoStatus/Actions";

const casinoStatusKey = "casinoStatus";
export const LotteryFilterForm = ({ form }: any) => {
    useInjectReducer({ key: casinoStatusKey, reducer: CasinoStatusReducer });
    useInjectSaga({ key: casinoStatusKey, saga: CasinoStatusSaga });
    // fields
    const dispatch = useDispatch();
    const casinoStatuses = useSelector(MakeSelectCasinoStatusSelector());

    // constructors
    useEffect(() => {
        dispatch(SelectCasinoStatusRequestAction());
        return () => {
            dispatch(SelectCasinoStatusSuccessAction([]));
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <Form
            name="entityCasinoListForm"
            layout="vertical"
            className="p-4 flex flex-row items-end gap-1 "
            form={form}
            initialValues={EmptyValue}
        >
          <Form.Item name="code" label="លេខសម្គាល់ល្បែងផ្សងសំណាង">
            <Input />
          </Form.Item>
          <Form.Item name="name" label="ឈ្មោះល្បែងផ្សងសំណង">
              <Input />
          </Form.Item>
          <Form.Item name="casinoStatusId" label="ស្ថានភាពអាជ្ញាបណ្ណ">
              <Select showSearch allowClear>
                  {casinoStatuses &&
                      casinoStatuses.map((item: any) => (
                          <Select.Option
                              key={item.id}
                              value={item.id}
                          >
                              {item.name}
                          </Select.Option>
                      ))}
              </Select>
          </Form.Item>
          <Form.Item>
            <Button htmlType="submit" type="default">
                ស្វែងរក
            </Button>
          </Form.Item>
            
        </Form>
    );
};
