import {
    SELECT_CURRENCY_REQUEST,
    SELECT_CURRENCY_SUCCESS
} from "Modules/Settings/Currency/Constants";

export const SelectCurrencyRequestAction = () => {
    return {
        type: SELECT_CURRENCY_REQUEST
    }
}
export const SelectCurrencySuccessAction = (currencies: any) => {
    return {
        type: SELECT_CURRENCY_SUCCESS,
        currencies
    }
}