import React from "react";
import Helmet from "react-helmet";

export interface HelmetHeaderType {
    meta: string;
}

export const HelmetHeader = ({ meta }: HelmetHeaderType) => {
    return (
        <>
            <Helmet>
                <title>{meta}</title>
            </Helmet>
        </>
    );
};
