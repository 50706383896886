import {
    SET_REQUEST_LOTTERY_GAME_TYPE_FILTER_VALUE,
    SET_REQUEST_LOTTERY_GAME_TYPE_VALUE,
    SET_REQUEST_LOTTERY_GAME_TYPE_ID,
    GET_REQUEST_LOTTERY_GAME_TYPE_REQUEST,
    GET_REQUEST_LOTTERY_GAME_TYPE_SUCCESS,
    LIST_REQUEST_LOTTERY_GAME_TYPE_REQUEST,
    LIST_REQUEST_LOTTERY_GAME_TYPE_SUCCESS,
    CREATE_REQUEST_LOTTERY_GAME_TYPE_REQUEST,
    EDIT_REQUEST_LOTTERY_GAME_TYPE_REQUEST,
    DELETE_REQUEST_LOTTERY_GAME_TYPE_REQUEST,
} from "Modules/Certificate/Lottery/ApplicationForm/Constants";

export const SetRequestLotteryGameTypeFilterValueAction = (values: any) => {
    return {
        type: SET_REQUEST_LOTTERY_GAME_TYPE_FILTER_VALUE,
        values,
    }
}

export const SetRequestLotteryGameTypeValueAction = (values: any) => {
    return {
        type: SET_REQUEST_LOTTERY_GAME_TYPE_VALUE,
        values
    }
}

export const SetRequestLotteryGameTypeIdAction = (id: string) => {
    return {
        type: SET_REQUEST_LOTTERY_GAME_TYPE_ID,
        id
    }
}

export const GetRequestLotteryGameTypeRequestAction = () => {
    return {
        type: GET_REQUEST_LOTTERY_GAME_TYPE_REQUEST,
    }
}

export const GetRequestLotteryGameTypeSuccessAction = (requestLotteryGameType: any) => {
    return {
        type: GET_REQUEST_LOTTERY_GAME_TYPE_SUCCESS,
        requestLotteryGameType,
    }
}

export const ListRequestLotteryGameTypeRequestAction = () => {
    return {
        type: LIST_REQUEST_LOTTERY_GAME_TYPE_REQUEST,
    }
}

export const ListRequestLotteryGameTypeSuccessAction = (requestLotteryGameTypes: any) => {
    return {
        type: LIST_REQUEST_LOTTERY_GAME_TYPE_SUCCESS,
        requestLotteryGameTypes
    }
}

export const CreateRequestLotteryGameTypeRequestAction = () => {
    return {
        type: CREATE_REQUEST_LOTTERY_GAME_TYPE_REQUEST,
    }
}

export const EditRequestLotteryGameTypeRequestAction = () => {
    return {
        type: EDIT_REQUEST_LOTTERY_GAME_TYPE_REQUEST,
    }
}

export const DeleteRequestLotteryGameTypeRequestAction = () => {
    return {
        type: DELETE_REQUEST_LOTTERY_GAME_TYPE_REQUEST
    }
}