export const SET_REQUEST_LOTTERY_LICENSE_FILTER_VALUE =
    "SET_REQUEST_LOTTERY_LICENSE_FILTER_VALUE";
export const SET_REQUEST_LOTTERY_LICENSE_VALUE =
    "SET_REQUEST_LOTTERY_LICENSE_VALUE";
export const SET_REQUEST_LOTTERY_ID = "SET_REQUEST_LOTTERY_ID";
export const LIST_REQUEST_LOTTERY_LICENSE_REQUEST =
    "LIST_REQUEST_LOTTERY_LICENSE_REQUEST";
export const LIST_REQUEST_LOTTERY_LICENSE_SUCCESS =
    "LIST_REQUEST_LOTTERY_LICENSE_SUCCESS";
export const GET_REQUEST_LOTTERY_LICENSE_REQUEST =
    "GET_REQUEST_LOTTERY_LICENSE_REQUEST";
export const GET_REQUEST_LOTTERY_LICENSE_SUCCESS =
    "GET_REQUEST_LOTTERY_LICENSE_SUCCESS";

export const CREATE_REQUEST_LOTTERY_LICENSE_REQUEST =
    "CREATE_REQUEST_LOTTERY_LICENSE_REQUEST";
export const EDIT_REQUEST_LOTTERY_LICENSE_REQUEST =
    "EDIT_REQUEST_LOTTERY_LICENSE_REQUEST";
export const EXPORT_REQUEST_LOTTERY_LICENSE_REQUEST =
    "EXPORT_REQUEST_LOTTERY_LICENSE_REQUEST";

export interface BranchType {
    branchId: string;
    branchAddress: string;
    branchManager: string;
    branchMobilePhone: string;
}
