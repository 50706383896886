export const SET_EQUIPMENT_TYPE_FILTER_VALUE = "SET_EQUIPMENT_TYPE_FILTER_VALUE";
export const SET_EQUIPMENT_TYPE_VALUE = "SET_EQUIPMENT_TYPE_VALUE";
export const SET_EQUIPMENT_TYPE_ID = "SET_EQUIPMENT_TYPE_ID";


export const LIST_EQUIPMENT_TYPE_REQUEST = "LIST_EQUIPMENT_TYPE_REQUEST";
export const LIST_EQUIPMENT_TYPE_SUCCESS = "LIST_EQUIPMENT_TYPE_SUCCESS";
export const GET_EQUIPMENT_TYPE_REQUEST = "GET_EQUIPMENT_TYPE_REQUEST";
export const GET_EQUIPMENT_TYPE_SUCCESS = "GET_EQUIPMENT_TYPE_SUCCESS";
export const SELECT_EQUIPMENT_TYPE_REQUEST = "SELECT_EQUIPMENT_TYPE_REQUEST";
export const SELECT_EQUIPMENT_TYPE_SUCCESS = "SELECT_EQUIPMENT_TYPE_SUCCESS";

export const SET_EQUIPMENT_TYPE_STATUS = "SET_EQUIPMENT_TYPE_STATUS";
export const CREATE_EQUIPMENT_TYPE_REQUEST = "CREATE_EQUIPMENT_TYPE_REQUEST";
export const EDIT_EQUIPMENT_TYPE_REQUEST = "EDIT_EQUIPMENT_TYPE_REQUEST";
export const DELETE_EQUIPMENT_TYPE_REQUEST = "DELETE_EQUIPMENT_TYPE_REQUEST";
export const TERMINATE_EQUIPMENT_TYPE_REQUEST = "TERMINATE_EQUIPMENT_TYPE_REQUEST";