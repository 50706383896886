import { useEffect } from "react";
import { useInjectReducer } from "Utility/InjectReducer";
import { useInjectSaga } from "Utility/InjectSaga";
import { useSelector, useDispatch } from "react-redux";
import dayjs from "dayjs";
import { Link } from "react-router-dom";

import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { HelmetHeader } from "Components/Helmet-Header";
import { ArchiveIcon } from "@heroicons/react/outline";
import { Form, Table, Space, Button } from "antd";
import { FullLayout } from "Components/Layouts/";
import { InspectionCertificateMasterFilterForm } from "Modules/Settings/InspectionCertificateMaster/Forms/InspectionCertificateMasterFilterForm";

import Reducer from "Modules/Settings/InspectionCertificateMaster/Reducer";
import Saga from "Modules/Settings/InspectionCertificateMaster/Saga";
import {
    MakeInspectionCertificateMasterListSelector
} from "Modules/Settings/InspectionCertificateMaster/Selectors";
import {
    ListInspectionCertificateMasterRequestAction,
    ListInspectionCertificateMasterSuccessAction,
    SetInspectionCertificateMasterFilterValueAction
} from "Modules/Settings/InspectionCertificateMaster/Actions";
import { DATE_TIME_FORMAT, PermissionKeys } from "Modules/Application/Constants";
import { checkItemPermission } from "Utility/Permission";

const key = "inspectionCertificateMaster";
export const InspectionCertificateMasterList = () => {
    useInjectReducer({ key, reducer: Reducer });
    useInjectSaga({ key, saga: Saga });
    // fields
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const inspectionCertificateMasters = useSelector(MakeInspectionCertificateMasterListSelector());

    const inspectionCertificateMasterColumn = [
        {
            title: "Register Date",
            key: "registeredDate",
            render: (item: any) => <span>{dayjs(item.registeredDate).format(DATE_TIME_FORMAT)}</span>
        },
        {
            title: "Descriptions",
            dataIndex: "descriptions",
            key: "descriptions",
        },
        {
            title: "Noted",
            dataIndex: "note",
            key: "note",
        },
        {
            key: "id",
            width: 50,
            render: (item: any) => <Space>
                <Button type="link" className="p-0" onClick={() => onDeleteInspectionCertificate(item)}>
                    <DeleteOutlined />
                </Button>
                <Link to={`/settings/inspection-certificate-master/${item.id}`} className="no-underline text-blue-500">
                    <EditOutlined />
                </Link>
            </Space>
        }
    ]

    // constructors
    useEffect(() => {
        const model = {
            ...form.getFieldsValue(),
        };
        dispatch(SetInspectionCertificateMasterFilterValueAction(model));
        dispatch(ListInspectionCertificateMasterRequestAction());
        return () => {
            dispatch(ListInspectionCertificateMasterSuccessAction([]));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // events
    const onFilterSubmit = (values: any) => {
        const model = {
            ...values,
        };
        dispatch(SetInspectionCertificateMasterFilterValueAction(model));
        dispatch(ListInspectionCertificateMasterRequestAction());
    }

    const onDeleteInspectionCertificate = (item: any) => {

    }

    // render
    const ActionButtons = () => (
        <>
            <Link to="create">
                <Button type="primary" disabled={checkItemPermission([PermissionKeys.CASINO_EQUIPMENT__BINDING])}>បញ្ចូលថ្មី</Button>
            </Link>
        </>
    );

    return (
        <>
            <FullLayout
                icon={<ArchiveIcon />}
                title="បញ្ជីលតាបត្រ"
                descriptions=""
                actions={<ActionButtons />}
            >
                <HelmetHeader meta="បញ្ជីលតាបត្រ" />
                <Form
                    name="inspectionCertificateMasterFilterForm"
                    form={form}
                    layout="vertical"
                    className="grid grid-cols-4 items-end p-5 gap-2"
                    onFinish={onFilterSubmit}>
                    <InspectionCertificateMasterFilterForm />
                </Form>
                <Table rowKey="id" size="small" dataSource={inspectionCertificateMasters || []} columns={inspectionCertificateMasterColumn || []} />
            </FullLayout>
        </>
    );
};
