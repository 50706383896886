import { createSelector } from "reselect";
import { initialState } from "Modules/Application/Reducer";

const CasinoStatusDomain = (state: any) => state.casinoStatus || initialState;
const MakeSelectCasinoStatusSelector = () =>
    createSelector(
        CasinoStatusDomain,
        (substate) => substate.selectCasinoStatus
    );

export { MakeSelectCasinoStatusSelector };
