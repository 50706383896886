import {
    SET_REQUEST_LOTTERY_LICENSE_FILTER_VALUE,
    SET_REQUEST_LOTTERY_LICENSE_VALUE,
    SET_REQUEST_LOTTERY_ID,
    LIST_REQUEST_LOTTERY_LICENSE_REQUEST,
    LIST_REQUEST_LOTTERY_LICENSE_SUCCESS,
    GET_REQUEST_LOTTERY_LICENSE_REQUEST,
    GET_REQUEST_LOTTERY_LICENSE_SUCCESS,
    CREATE_REQUEST_LOTTERY_LICENSE_REQUEST,
    EDIT_REQUEST_LOTTERY_LICENSE_REQUEST,
    EXPORT_REQUEST_LOTTERY_LICENSE_REQUEST,
} from "Modules/License/Lottery/ApplicationForm/Constants";

export const SetRequestLotteryLicenseFilterValueAction = (values: any) => {
    return {
        type: SET_REQUEST_LOTTERY_LICENSE_FILTER_VALUE,
        values,
    };
};

export const SetRequestLotteryLicenseValueAction = (values: any) => {
    return {
        type: SET_REQUEST_LOTTERY_LICENSE_VALUE,
        values,
    };
};

export const SetRequestLotteryLicenseIdAction = (id: string) => {
    return {
        type: SET_REQUEST_LOTTERY_ID,
        id,
    };
};

export const ListRequestLotteryLicenseRequestAction = () => {
    return {
        type: LIST_REQUEST_LOTTERY_LICENSE_REQUEST,
    };
};

export const ListRequestLotteryLicenseSuccessAction = (
    lotteryLicenses: any[]
) => {
    return {
        type: LIST_REQUEST_LOTTERY_LICENSE_SUCCESS,
        lotteryLicenses,
    };
};

export const GetRequestLotteryLicenseRequestAction = () => {
    return {
        type: GET_REQUEST_LOTTERY_LICENSE_REQUEST,
    };
};
export const GetRequestLotteryLicenseSuccessAction = (lotteryLicense: any) => {
    return {
        type: GET_REQUEST_LOTTERY_LICENSE_SUCCESS,
        lotteryLicense,
    };
};

export const CreateRequestLotteryLicenseRequestAction = () => {
    return {
        type: CREATE_REQUEST_LOTTERY_LICENSE_REQUEST,
    };
};

export const EditRequestLotteryLicenseRequestAction = () => {
    return {
        type: EDIT_REQUEST_LOTTERY_LICENSE_REQUEST,
    };
};

export const ExportRequestLotteryLicenseRequestAction = () => {
    return {
        type: EXPORT_REQUEST_LOTTERY_LICENSE_REQUEST,
    };
};
