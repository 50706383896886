export const CasinoConstant = {
    SELECTABLE_CASINO_OPTION_REQUEST: "SELECTABLE_CASINO_OPTION_REQUEST",

    SELECTABLE_CASINO_TYPE: "SELECTABLE_CASINO_TYPE",
    SELECTABLE_CASINO_TYPE_ERROR: "SELECTABLE_CASINO_TYPE_ERROR",
    SELECTABLE_CASINO_TYPE_SUCCESS: "SELECTABLE_CASINO_TYPE_SUCCESS",

    SELECTABLE_NATIONALITY: "SELECTABLE_NATIONALITY",
    SELECTABLE_NATIONALITY_ERROR: "SELECTABLE_NATIONALITY_ERROR",
    SELECTABLE_NATIONALITY_SUCCESS: "SELECTABLE_NATIONALITY_SUCCESS",

    SELECTABLE_IDENTIFICATION_TYPE: "SELECTABLE_IDENTIFICATION_TYPE",
    SELECTABLE_IDENTIFICATION_TYPE_ERROR:
        "SELECTABLE_IDENTIFICATION_TYPE_ERROR",
    SELECTABLE_IDENTIFICATION_TYPE_SUCCESS:
        "SELECTABLE_IDENTIFICATION_TYPE_SUCCESS",

    SELECTABLE_APPLICATION_TYPE: "SELECTABLE_APPLICATION_TYPE",
    SELECTABLE_APPLICATION_TYPE_ERROR: "SELECTABLE_APPLICATION_TYPE_ERROR",
    SELECTABLE_APPLICATION_TYPE_SUCCESS: "SELECTABLE_APPLICATION_TYPE_SUCCESS",
};

export const SET_REQUEST_CASINO_LICENSE_FILTER_VALUE =
    "SET_REQUEST_CASINO_LICENSE_FILTER_VALUE";
export const LIST_REQUEST_CASINO_LICENSE_REQUEST =
    "LIST_REQUEST_CASINO_LICENSE_REQUEST";
export const LIST_REQUEST_CASINO_LICENSE_SUCCESS =
    "LIST_REQUEST_CASINO_LICENSE_SUCCESS";

export const SET_REQUEST_CASINO_LICENSE_VALUE =
    "SET_REQUEST_CASINO_LICENSE_VALUE";
export const SET_REQUEST_CASINO_LICENSE_ID = "SET_REQUEST_CASINO_LICENSE_ID";
export const GET_REQUEST_CASINO_LICENSE_REQUEST =
    "GET_REQUEST_CASINO_LICENSE_REQUEST";
export const GET_REQUEST_CASINO_LICENSE_SUCCESS =
    "GET_REQUEST_CASINO_LICENSE_SUCCESS";
export const CREATE_REQUEST_CASINO_LICENSE_REQUEST =
    "CREATE_REQUEST_CASINO_LICENSE_REQUEST";
export const EDIT_REQUEST_CASINO_LICENSE_REQUEST =
    "EDIT_REQUEST_CASINO_LICENSE_REQUEST";
export const EXPORT_REQUEST_CASINO_LICENSE_REQUEST =
    "EXPORT_REQUEST_CASINO_LICENSE_REQUEST";
