import { useState } from "react";
import { useDispatch } from "react-redux";
import { useInjectReducer } from "Utility/InjectReducer";
import { useInjectSaga } from "Utility/InjectSaga";
import { v4 as uuid } from "uuid";

import { Form, Button, Modal } from "antd";
import { FullLayout } from "Components/Layouts/Inner/Full.Layout";
import { HelmetHeader } from "Components/Helmet-Header/index";
import { ArchiveIcon } from "@heroicons/react/outline";
import {
    RequestLotteryLicenseCreateForm
} from "Modules/License/Lottery/ApplicationForm/License/Forms/RequestLotteryLicenseCreateForm";
import { LotteryGameTypeForm } from "Modules/Certificate/Lottery/ApplicationForm/GameTypeEquipment/Forms/LotteryGameTypeForm";

import LotteryLicenseApplicationFormReducer from "Modules/License/Lottery/ApplicationForm/Reducer";
import LotteryLicenseApplicationFormSaga from "Modules/License/Lottery/ApplicationForm/Saga";
import {
    CreateRequestLotteryLicenseRequestAction,
    SetRequestLotteryLicenseValueAction
} from "Modules/License/Lottery/ApplicationForm/Actions";

const key = "lotteryLicenseApplicationForm";
export const RequestLotteryLicenseCreate = () => {
    useInjectReducer({ key, reducer: LotteryLicenseApplicationFormReducer });
    useInjectSaga({ key, saga: LotteryLicenseApplicationFormSaga });
    // fields
    const [form] = Form.useForm();
    const [lotteryGameTypeForm] = Form.useForm();
    const dispatch = useDispatch();
    const [lotteryGameTypeVisible, setLotteryGameTypeVisible] = useState<boolean>(false);


    //events
    const onFormProviderSubmit = (name: string, { forms, values }: any) => {
        switch (name) {
            case "requestLotteryLicenseCreateForm":
                onRequestLotteryLicenseSubmit(values);
                break;
            case "lotteryGameTypeForm":
                const gameTypes: any[] = form.getFieldValue("gameTypes") || [];
                const model = { id: uuid(), ...values };
                gameTypes.push(model);
                form.setFieldValue("gameTypes", gameTypes);
                lotteryGameTypeForm.resetFields();
                setLotteryGameTypeVisible(false);
                break;

            default: break;
        }

    };

    const onRequestLotteryLicenseSubmit = (values: any) => {
        const model = {
            formType: "L001",
            entityName: "Lottery",
            ...values.formData.applicant,
            ...values,
        };
        model.formData.boardOfDirectors = form.getFieldValue("boardOfDirectors");
        model.formData.gameTypes = form.getFieldValue("gameTypes");
        model.formData.branches = form.getFieldValue("branches");
        if (values.formData?.representativeType === false) {
            const representativeData = {
                address: values?.formData?.applicant?.address,
                dateOfBirth: values?.formData?.applicant?.dateOfBirth,
                emailAddress: values?.formData?.applicant?.emailAddress,
                expiredDate: values?.formData?.applicant?.expiredDate,
                fullname: values?.formData?.applicant?.fullname,
                gender: values?.formData?.applicant?.gender,
                identityIssuedDate: values?.formData?.applicant?.identityIssuedDate,
                identityNumber: values?.formData?.applicant?.identityNumber,
                identityTypeId: values?.formData?.applicant?.identityTypeId,
                latinFullname: values?.formData?.applicant?.latinFullname,
                mobilePhoneNumber: values?.formData?.applicant?.mobilePhoneNumber,
                nationality: values?.formData?.applicant?.nationality,
            }
            model.formData.representative = { ...representativeData };
        };

        dispatch(SetRequestLotteryLicenseValueAction(model));
        dispatch(CreateRequestLotteryLicenseRequestAction());

    }

    const ActionButtons = () => (
        <>
            <Button
                onClick={() => form.submit()}
                type="primary"
            >
                បញ្ចូលថ្មី
            </Button>
        </>
    );

    return (
        <>
            <FullLayout
                icon={<ArchiveIcon />}
                title="ការបញ្ចូលទិន្នន័យពាក្យស្នើសុំអាជ្ញាបណ្ណល្បែងផ្សងសំណាង"
                descriptions=""
                actions={<ActionButtons />}
            >
                <HelmetHeader meta="ពាក្យស្នើសុំអាជ្ញាបណ្ណផ្សងសំណាង" />
                <Form.Provider onFormFinish={onFormProviderSubmit}>
                    <RequestLotteryLicenseCreateForm form={form} setLotteryGameTypeVisible={setLotteryGameTypeVisible} />
                    <Modal open={lotteryGameTypeVisible} onCancel={() => setLotteryGameTypeVisible(false)} onOk={() => lotteryGameTypeForm.submit()}>
                        <LotteryGameTypeForm form={lotteryGameTypeForm} />
                    </Modal>
                </Form.Provider>

            </FullLayout>
        </>
    );
};

