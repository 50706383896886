import { createSelector } from "reselect";
import { CasinoLicenseInitialState } from "Modules/License/Casino/Reducer";

const selectCasinoLicenseDomain = (state: any) =>
    state.licenseCasino || CasinoLicenseInitialState;
const MakeCasinoTypeSelector = () =>
    createSelector(
        selectCasinoLicenseDomain,
        (substate) => substate.casinoTypes
    );

const MakeApplicationTypeSelector = () =>
    createSelector(
        selectCasinoLicenseDomain,
        (substate) => substate.applicationTypes
    );

const MakeIdentificationTypeSelector = () =>
    createSelector(
        selectCasinoLicenseDomain,
        (substate) => substate.identificationTypes
    );

const MakeNationalitySelector = () =>
    createSelector(
        selectCasinoLicenseDomain,
        (substate) => substate.nationality
    );
const MakeRequestCasinoLicenseFilterValueSelector = () => createSelector(selectCasinoLicenseDomain, substate => substate.requestCasinoFilterValue);
const MakeRequestCasinoLicenseListSelector = () => createSelector(selectCasinoLicenseDomain, substate => substate.requestCasinoLicenses);
const MakeRequestCasinoLicenseValueSelector = () => createSelector(selectCasinoLicenseDomain, substate => substate.requestCasinoValue);
const MakeRequestCasinoLicenseIdSelector = () => createSelector(selectCasinoLicenseDomain, substate => substate.requestCasinoLicenseId);
const MakeRequestCasinoLicenseSelector = () => createSelector(selectCasinoLicenseDomain, substate => substate.requestCasinoLicense);
/**
 * Default selector used by LoginPage
 */

const MakeSelectCasinoLicensePage = () =>
    createSelector(selectCasinoLicenseDomain, (substate) => substate);

export default MakeSelectCasinoLicensePage;
export {
    MakeCasinoTypeSelector,
    MakeApplicationTypeSelector,
    MakeIdentificationTypeSelector,
    MakeNationalitySelector,

    // Application Form
    MakeRequestCasinoLicenseFilterValueSelector,
    MakeRequestCasinoLicenseListSelector,
    MakeRequestCasinoLicenseValueSelector,
    MakeRequestCasinoLicenseIdSelector,
    MakeRequestCasinoLicenseSelector
};
