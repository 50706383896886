import { Form, Input, DatePicker, Select, Button } from "antd";
import { LicenseType } from "Common/Constants/LicenseType";

export const RequestCasinoLicenseFilterForm = ({ form }: any) => {
    return (
        <Form
            name="licenseListForm"
            layout="vertical"
            className="grid grid-cols-4 items-end gap-2 p-4"
            form={form}
            initialValues={{}}
        >
            <Form.Item name="search" label="ព័ត៌មានស្វែងរក">
                <Input placeholder="លេខអាជ្ញាបណ្ណ / ឈ្មោះ " />
            </Form.Item>
            <Form.Item>
                <Button htmlType="submit" type="default">
                    ស្វែងរក
                </Button>
            </Form.Item>
            <Form.Item
                name="expiredDate"
                label="ស្វែងរកតាមសុពលភាពអាជ្ញាបណ្ណ"
                className="hidden"
            >
                <DatePicker.RangePicker />
            </Form.Item>
            <Form.Item name="formType"
                label="ប្រភេទអាជ្ញាបណ្ណ"
                className="hidden">
                <Select>
                    {LicenseType &&
                        LicenseType.map((item) => (
                            <Select.Option
                                key={`${item["entityName"]}`}
                                value={item["formType"]}
                            >
                                {item["label"]}
                            </Select.Option>
                        ))}
                </Select>
            </Form.Item>
        </Form>
    );
};
