import produce from "immer";
import {
    SET_LOTTERY_GAME_TYPE_CERTIFY_APPROVE_VALUE,
    SET_LOTTERY_CERTIFY_VALUE,
    SET_LOTTERY_CERTIFY_FILTER_VALUE,
    SET_LOTTERY_CERTIFY_ID,
    LIST_LOTTERY_CERITFY_SUCCESS,
    GET_LOTTERY_CERTIFY_SUCCESS
} from "Modules/Certificate/Lottery/Certify/Constants";

export const LotteryCertifyInitialState = {
    lotteryGameTypeCertifyApprove: null,
    lotteryCertifyValue: null,
    lotteryCertifyFilterValue: null,
    lotteryCertifies: [],
    lotteryCertify: null,
    lotteryCertifyId: null,
}

const LotteryCerifyReducer = produce((draft, action) => {
    switch (action.type) {
        case SET_LOTTERY_GAME_TYPE_CERTIFY_APPROVE_VALUE:
            draft.lotteryGameTypeCertifyApprove = action.values;
            break;
        case SET_LOTTERY_CERTIFY_VALUE:
            draft.lotteryCertifyValue = action.values;
            break;
        case SET_LOTTERY_CERTIFY_FILTER_VALUE:
            draft.lotteryCertifyFilterValue = action.values;
            break;
        case LIST_LOTTERY_CERITFY_SUCCESS:
            draft.lotteryCertifies = action.lotteryCertifies;
            break;
        case GET_LOTTERY_CERTIFY_SUCCESS:
            draft.lotteryCertify = action.lotteryCertify;
            break;
        case SET_LOTTERY_CERTIFY_ID:
            draft.lotteryCertifyId = action.id;
            break;
        default: break;
    }
}, LotteryCertifyInitialState)
export default LotteryCerifyReducer;