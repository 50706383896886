
import { useDispatch } from "react-redux";
import { useInjectReducer } from "Utility/InjectReducer";
import { useInjectSaga } from "Utility/InjectSaga";
import { v4 as uuid } from "uuid";

import { Form, Button } from "antd";
import { FullLayout } from "Components/Layouts/Inner/Full.Layout";
import { HelmetHeader } from "Components/Helmet-Header/index";
import { ArchiveIcon } from "@heroicons/react/outline";
import { RequestLotteryGameTypeEquipmentCreateForm } from "Modules/Certificate/Lottery/ApplicationForm/GameTypeEquipment/Forms/RequestLotteryGameTypeEquipmentCreateForm";

import LotteryApplicationFormReducer from "Modules/Certificate/Lottery/ApplicationForm/Reducer";
import LotteryApplicationFormSaga from "Modules/Certificate/Lottery/ApplicationForm/Saga";
import { CreateRequestLotteryGameTypeRequestAction, SetRequestLotteryGameTypeValueAction } from "Modules/Certificate/Lottery/ApplicationForm/Actions";

const key = "lotteryApplicationForm";
export const RequestLotteryGameTypeCreate = () => {
    useInjectReducer({ key, reducer: LotteryApplicationFormReducer });
    useInjectSaga({ key, saga: LotteryApplicationFormSaga });
    // fields
    const [form] = Form.useForm();
    const dispatch = useDispatch();


    //events
    const onFormProviderSubmit = (name: string, { forms, values }: any) => {
        switch (name) {
            case "lotteryGameTypeForm":
                const { lotteryGameTypeForm } = forms;
                if (lotteryGameTypeForm) {
                    const gameEquipments = form.getFieldValue("gameTypes") || [];
                    const gameEquipment = { id: uuid(), ...lotteryGameTypeForm.getFieldsValue() };
                    gameEquipments.push(gameEquipment)
                    form.setFieldValue("gameTypes", gameEquipments);
                    lotteryGameTypeForm.resetFields();
                }
                break;
            case "lotteryGameEquipmentForm":

                const { lotteryGameEquipmentForm } = forms;
                if (lotteryGameEquipmentForm) {
                    const gameEquipments = form.getFieldValue("gameEquipments") || [];
                    const gameEquipment = { id: uuid(), ...lotteryGameEquipmentForm.getFieldsValue() };
                    gameEquipments.push(gameEquipment)
                    form.setFieldValue("gameEquipments", gameEquipments);
                    lotteryGameEquipmentForm.resetFields();
                }
                break;
            case "requestLotteryGameTypeEquipmentCreateForm":
              const gameTypes: any[] = form.getFieldValue("gameTypes");
              const gameEquipments: any[] = form.getFieldValue("gameEquipments");
              console.log("form.getFieldsValue(),", form.getFieldsValue());
              let model = {
                ...form.getFieldsValue(),
                entityName: "Lottery Game Type",
                formType: "G002",
                CompanyNameInKhmer: values.formData.company.name,
                CasinoNameInKhmer: values.formData.casino.name,
                code: values.formData.casino.code,
                applicantName: values.formData.applicant.fullname,
                applicantNationality: values.formData.applicant.nationality,
                applicantContactNumber: values.formData.applicant.mobilePhoneNumber,
                representativeName: values.formData.applicant.fullname,
                representativeNationality: values.formData.applicant.nationality,
                representativeContactNumber:
                    values.formData.applicant.mobilePhoneNumber,
              };
              model.receiverName = " ";
              model.trackingCode = " ";
              if (values.formData.representativeType === true) {
                model.representativeName =
                      values.formData.representative.fullname;
                      model.representativeNationality =
                      values.formData.representative.nationality;
                      model.representativeContactNumber =
                      values.formData.representative.mobilePhoneNumber;
              }
              model.formData.gameTypes = gameTypes;
              model.formData.gameEquipments = gameEquipments;

              dispatch(SetRequestLotteryGameTypeValueAction(model));
              dispatch(CreateRequestLotteryGameTypeRequestAction());
              break;
        }

    };

    const ActionButtons = () => (
        <>
            <Button
                onClick={() => form.submit()}
                className="inline-flex items-center justify-center px-4 py-2 mx-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500"
            >
                បញ្ចូលថ្មី
            </Button>
        </>
    );

    return (
        <>
            <FullLayout
                icon={<ArchiveIcon />}
                title="ពាក្យស្នើសុំចុះបញ្ជីប្រភេទល្បែង និឧបករណ៍ល្បែង សម្រាប់ល្បែងផ្សងសំណាង (L003)"
                descriptions=""
                actions={<ActionButtons />}
            >
                <HelmetHeader meta="ពាក្យស្នើសុំចុះបញ្ជីប្រភេទល្បែង និងឿបករណ៍ល្បែង សម្រាប់ល្បែងផ្សងសំណាង" />
                <Form.Provider onFormFinish={onFormProviderSubmit}>
                    <RequestLotteryGameTypeEquipmentCreateForm form={form} />
                </Form.Provider>

            </FullLayout>
        </>
    );
};
