export const EntityCompanyListConstant = {
    SET_FORM_VALUES: "SET_FORM_VALUES",
    ENTITY_COMPANY_LIST_REQUEST: "ENTITY_COMAPNY_LIST_REQUEST",
    ENTITY_COMPANY_LIST_SUCCESS: "ENTITY_COMPANY_LIST_SUCCESS",
    ENTITY_COMPANY_LIST_ERROR: "ENTITY_COMPANY_LIST_ERROR",

    SET_COMPANY_VALUES: "SET_COMPANY_VALUES",
    CREATE_ENTITY_COMPANY_REQUEST: "CREATE_ENTITY_COMPANY_REQUEST",
    SELECT_COMPANY_REQUEST: "SELECT_COMPANY_REQUEST",
    SELECT_COMPANY_SUCCESS: "SELECT_COMPANY_SUCCESS",
};

export const GET_ENTITY_COMPANY_BY_ID_REQUEST =
    "GET_ENTITY_COMPANY_BY_ID_REQUEST";
export const GET_ENTITY_COMPANY_BY_ID_SUCCESS =
    "GET_ENTITY_COMPANY_BY_ID_SUCCESS";

export const CREATE_COMPANY_MODAL_REQUEST = "CREATE_COMPANY_MODAL_REQUEST";
export const CREATE_COMPANY_MODAL_SUCCESS = "CREATE_COMPANY_MODAL_SUCCESS";

export const EDIT_ENTITY_COMPANY_REQUEST = "EDIT_ENTITY_COMPANY_REQUEST";
export const EDIT_ENTITY_COMPANY_SUCCESS = "EDIT_ENTITY_COMPANY_SUCCESS";

export const EmptyValue = {
    name: "",
};
