import { ShowAlert } from "Common/Saga";
import { SHOW_SNACK_MESSAGE } from "Components/SnackMessage/Constants";
import {  AsyncStartAction } from "Modules/Application/Actions";
import AuthenticationRequest from "Utility/AuthenticationHttp";
import { GET, POST, PUT } from "Utility/Constant";
import ApiEndpoint, { CORE_UPLOAD_FILE_PATH } from "Utility/Endpoint";
import Request from "Utility/Http";
import { v4 as uuid } from "uuid";

export const GetBindingEquipmentDetailService = (id: string, dispatch: any) => {
    const requestUrl = `${ApiEndpoint.EQUIPMENT_CERTIFICATE_MAP_PATH}/${id}`;
    const httpPayload = ApiEndpoint.makeApiPayload(
        requestUrl,
        GET,
    );

    try {
        const response:any = Request(httpPayload);
        return response;
    } catch (error: any) {
        dispatch(ShowAlert("error", error.data.message));
        return null;
    }
}

export const GetEquipmentPictureService = (id: string, dispatch: any) => {
    const requestUrl = `${ApiEndpoint.EQUIPMENT_CERTIFICATE_MAP_PATH}/${id}`;
    const httpPayload = ApiEndpoint.makeApiPayload(
        requestUrl,
        GET,
    );

    try {
        const response:any = Request(httpPayload);
        return response;
    } catch (error: any) {
        dispatch(ShowAlert("error", error.data.message));
        return null;
    }
}

export const GetEquipmentMapService = (id: string, dispatch: any) => {
    const requestUrl = `${ApiEndpoint.EQUIPMENT_CERTIFICATE_MAP_PATH}/${id}`;
    const httpPayload = ApiEndpoint.makeApiPayload(
        requestUrl,
        GET,
    );

    try {
        const response:any = Request(httpPayload);
        return response;
    } catch (error: any) {
        dispatch(ShowAlert("error", error.data.message));
        return null;
    }
}

export const EditEquipmentDetailService = (model: any, dispatch: any) => {
    const requestUrl = `${ApiEndpoint.EQUIPMENT_CERTIFICATE_MAP_PATH}`;
    const httpPayload = ApiEndpoint.makeApiPayload(
        requestUrl,
        PUT,
        model
    );

    try {
        const response:any = Request(httpPayload);
        return response;
    } catch (error: any) {
        dispatch(ShowAlert("error", error.data.message));
        return null;
    }
}

export const RebindEquipmentDetailService = (model: any, dispatch: any) => {
    const requestUrl = `${ApiEndpoint.EQUIPMENT_CERTIFICATE_MAP_PATH}/rebind`;
    const httpPayload = ApiEndpoint.makeApiPayload(
        requestUrl,
        POST,
        model
    );

    try {
        const response:any = Request(httpPayload);
        return response;
    } catch (error: any) {
        dispatch(ShowAlert("error", error.data.message));
        return null;
    }
}


export const UploadPicture = (model: any, dispatch: any) => {
    try {
        dispatch(AsyncStartAction());
        const url = CORE_UPLOAD_FILE_PATH;
        const payload = ApiEndpoint.makeApiPayload(url, POST, model);
        const response = AuthenticationRequest(payload);
        if (response) {
            return response;
        }
    } catch {
        dispatch({
            type: SHOW_SNACK_MESSAGE,
            snack: {
                type: "error",
                message: "Image upload error!",
                id: uuid(),
            },
        });
        return null;
    }
    finally {
    }
}