import { Form, Input, Button } from "antd"


export const RequestLotteryGameTypeEquipmentFilterForm = ({ form }: any) => {

    return (
        <Form name="requestLotteryGameTypeEquipmentFilterForm" layout="vertical" className="flex flex-row items-end gap-2 p-5" form={form}>
            <Form.Item name="search" label="លេខសម្គាល់">
                <Input autoComplete="off" />
            </Form.Item>
            <Form.Item>
                <Button type="default" onClick={() => form.submit()}>
                    ស្វែងរក
                </Button>
            </Form.Item>
        </Form>
    )

}