import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { LogoutAction } from "Modules/Application/Actions";
import { Spin, Menu } from "antd";
import type { MenuProps } from "antd";
import { MakeIndicatorSelector } from "Modules/Application/Selectors";
import { ProfileAvatar } from "Components/ProfileAvatar";
import { DollarOutlined, HomeOutlined, PercentageOutlined, SolutionOutlined } from "@ant-design/icons";

import Logo from "Assets/Images/cmgc-logo.png";
import { EntityType } from "Common/Constants/EntityType";
import { PermissionKeys } from "Modules/Application/Constants";
// import { decodePermission } from "Utility/Permission";
export interface MainLayoutType {
    children: React.ReactNode;
}

export const MainLayout = ({ children }: MainLayoutType) => {
    // fields
    const dispatch = useDispatch();
    const isLoading = useSelector(MakeIndicatorSelector());
    // const renderNaviation: MenuProps["items"] = [];
    const mainNavigation: MenuProps["items"] = [
        {
            label: (<Link to="/">ទំព័រដើម</Link>),
            key: 0,
            icon: <HomeOutlined />,
        },
        {
            label: "កាស៊ីណូ",
            key: PermissionKeys.CASINO,
            icon: <DollarOutlined />,
            children: [
                {
                    key: "160",
                    label: "ពាក្យស្នើសុំ",
                    children: [
                        {
                            label: (
                                <Link to="/license/casino/application-form/request-license">
                                    ពាក្យស្នើសុំអាជ្ញាបណ្ណ
                                </Link>
                            ),
                            key: PermissionKeys.REQUEST_CASINO_LICENSE,
                        },
                        {
                            label: (
                                <Link to="/certificate/casino/request-game-type/request">
                                    ពាក្យស្នើសុំចុះបញ្ជីប្រភេទល្បែង
                                </Link>
                            ),
                            key: PermissionKeys.REQUEST_GAME_TYPE,
                        },
                        {
                            label: (
                                <Link to="/certificate/casino/request-game-rule/request">
                                    ពាក្យស្នើសុំចុះបញ្ជីវិធានល្បែង
                                </Link>
                            ),
                            key: PermissionKeys.REQUEST_GAME_RULE,
                        },
                        {
                            label: (
                                <Link to="/certificate/casino/request-game-equipment/request">
                                    ពាក្យស្នើសុំចុះបញ្ជី ឧបករណ៍/កម្មវិធីល្បែង
                                </Link>
                            ),
                            key: PermissionKeys.REQUEST_GAME_EQUIPMENT,
                        }

                    ],
                },
                {
                    key: "180",
                    label: "អាជ្ញាបណ្ណ/វិញ្ញាបណ្ណបត្រ",
                    children: [
                        {
                            label: "អាជ្ញាបណ្ណ",
                            key: "181",
                        },
                        {
                            label: "វិញ្ញាបណ្ណបត្រប្រភេទល្បែង",
                            key: "182",
                        },
                        {
                            label: "វិញ្ញាបណ្ណបត្រវិធានល្បែង",
                            key: "183",
                        },
                        {
                            label: "វិញ្ញាបណ្ណបត្រឧបករណ៍ល្បែង",
                            key: "184",
                        }

                    ],
                },
                {
                    type: "group",
                    key: "140",
                    label: "ព័ត៌មាន",
                    children: [
                        {
                            label: (
                                <Link to={`/entity/company?entityType=${EntityType.CASINO}`}>
                                    កម្មសិទ្ធករ/ក្រុមហ៊ុន
                                </Link>
                            ),
                            key: PermissionKeys.COMPANY,
                        },
                        {
                            label: (
                                <Link to={`/entity/operator?entityType=${EntityType.CASINO}`}>
                                    ប្រតិបត្តករកាស៊ីណូ
                                </Link>
                            ),
                            key: PermissionKeys.CASINO,
                        },
                        {
                            label: (
                                <Link to={`/certificate/casino/verify?entityType=${EntityType.CASINO}`}>
                                    ផ្ទៀងផ្ទាត់ QR Code
                                </Link>
                            ),
                            key: PermissionKeys.EQUIPMENT_BINDING__VIEW,
                        },
                    ],
                },
                {
                    type: "group",
                    label: "ទិន្នន័យគោល",
                    key: "110",
                    children: [
                        {
                            label: (
                                <Link to={`/settings/game-type?entityType=${EntityType.CASINO}`}>
                                    ប្រភេទល្បែង
                                </Link>
                            ),
                            key: PermissionKeys.GAME_TYPE,
                        },
                        {
                            label: (
                                <Link to={`/settings/game-rule?entityType=${EntityType.CASINO}`}>
                                    វិធានល្បែង
                                </Link>
                            ),
                            key: PermissionKeys.GAME_RULE,
                        },
                        {
                            label: (
                                <Link to="/settings/inspection-certificate-master">
                                    លតាបត្រ
                                </Link>
                            ),
                            key: PermissionKeys.INSPECTION_CERTIFICATE,
                        },
                    ],
                },
                {
                  type: "group",
                  label: "របាយការណ៍",
                  key: "500",
                  children: [
                        {
                            label: (
                                <Link to={`/reports/casino-inspection-certiciate-report`}>
                                    សង្ខេបពីឧបករណ៍/កម្មវិធីល្បែង
                                </Link>
                            ),
                            key: PermissionKeys.CASINO_EQUIPMENT,
                        },
                        {
                            label: (
                                <Link to={`/reports/casino-equipment-binding-report`}>
                                    ការ Binding
                                </Link>
                            ),
                            key: 'casino-equipoment-binding',
                        },
                        {
                            label: (
                                <Link to={`/reports/certificate-printing-report`}>
                                    ការបោះពុម្ភលតាបត្រ
                                </Link>
                            ),
                            key: 'certificate-printing-report',
                        },
                  ],
              },
                
            ],
        },
        {
            label: "ល្បែងផ្សងសំណាង",
            key: "3",
            icon: <PercentageOutlined />,
            children: [
                {
                    key: PermissionKeys.LOTTER,
                    label: "ពាក្យស្នើសុំ",
                    children: [
                        {
                            label: (
                                <Link to="/license/lottery/application-form/request-license">
                                    ពាក្យស្នើសុំអាជ្ញាបណ្ណ
                                </Link>
                            ),
                            key: PermissionKeys.REQUEST_CASINO_LICENSE,
                        },
                        {
                            label:  <Link to="/license/lottery/application-form/request-license-branch">
                                        បញ្ជីពាក្យស្នើសុំបើកសាខា
                                    </Link>,
                            key: "202",
                        },
                        {
                            label: (
                                <Link to="/certificate/lottery/request-game-type">
                                    ពាក្យស្នើសុំចុះបញ្ជីប្រភេទល្បែង/ឧបករណ៍ល្បែង
                                </Link>
                            ),
                            key: "203",
                        },

                    ],
                },
                {
                    type: "group",
                    key: "240",
                    label: "ព័ត៌មាន",
                    children: [
                        {
                            label: (
                                <Link to={`/entity/company?entityType=${EntityType.LOTTERY}`}>
                                    កម្មសិទ្ធករ/ក្រុមហ៊ុន
                                </Link>
                            ),
                            key: PermissionKeys.COMPANY,
                        },
                        {
                            label: (
                                <Link to={`/entity/operator?entityType=${EntityType.LOTTERY}`}>
                                    ប្រតិបត្តករល្បែងផ្សងសំណាង
                                </Link>
                            ),
                            key: PermissionKeys.LOTTER,
                        },
                    ],
                },
                {
                    type: "group",
                    label: "ទិន្នន័យគោល",
                    key: "210",
                    children: [
                        {
                            label: (
                                <Link to={`/settings/game-type?entityType=${EntityType.LOTTERY}`}>
                                    ប្រភេទល្បែង
                                </Link>
                            ),
                            key: PermissionKeys.GAME_TYPE,
                        }
                    ],
                }
            ],

        },
        {
            label: "ទិន្នន័យគោល",
            key: "4",
            icon: <SolutionOutlined />,
            children: [
                {
                    label: "កាស៊ីណូ",
                    key: PermissionKeys.CASINO + '123',
                    children: [
                        {
                            label: (
                                <Link to={`/settings/game-type?entityType=${EntityType.CASINO}`}>
                                    ប្រភេទល្បែង
                                </Link>
                            ),
                            key: PermissionKeys.GAME_TYPE,
                        },
                        {
                            label: (
                                <Link to={`/settings/game-rule`}>
                                    វិធានល្បែង
                                </Link>
                            ),
                            key: PermissionKeys.GAME_RULE,
                        },
                        {
                            label: (
                                <Link to={`/settings/equipment-type`}>
                                    ប្រភេទឧបករន៍ល្បែង
                                </Link>
                            ),
                            key: PermissionKeys.EQUIPMENT_TYPE,
                        },
                        {
                            label: (
                                <Link to={`/settings/inspection-certificate-type`}>
                                    ប្រភេទលតាបត្រ
                                </Link>
                            ),
                            key: PermissionKeys.INSPECTION_CERTIFICATE_TYPE,
                        },
                        {
                            label: (
                                <Link to={`/settings/inspection-certificate-status`}>
                                    ស្ថានភាពលតាបត្រ
                                </Link>
                            ),
                            key: "115",
                        }
                    ],
                },
                {
                    label: "ល្បែងផ្សងសំណាង",
                    key: PermissionKeys.LOTTER,
                    children: [
                        {
                            label: (
                                <Link to={`/settings/game-type?entityType=${EntityType.LOTTERY}`}>
                                    ប្រភេទល្បែង
                                </Link>
                            ),
                            key: PermissionKeys.GAME_TYPE,
                        },

                    ],
                },
                {
                    key: "50",
                    label: "ទិន្នន័យប្រព័ន្ធ",
                    children: [
                        {
                            label: (
                                <Link to="/settings/form-type">
                                    ប្រភេទពាក្យស្នើសុំ
                                </Link>
                            ),
                            key: PermissionKeys.EQUIPMENT_TYPE,
                        },

                    ],
                },
            ],

        },
        {
            label: <Link to="/reports/operator">របាយការណ៍</Link>,
            key: "5",
            icon: <SolutionOutlined />,
        }

    ];

    // useEffect(() => {
    //     const permission = checkMenuPermission();
    // }, []);

    // // methods
    // const checkMenuPermission = () => {
    //     const token = localStorage.getItem("cgmcToken");
    //     if(!token) {
    //         return ;
    //     }
    //     const grants = decodePermission(token);
        
    //     return renderMenuItem(mainNavigation, grants);
    // }
    // const renderMenuItem = (baseMenuItem: any, permissions: any) => {
    //     const menuItem = [];
    //     baseMenuItem.forEach((item: any) => {
    //         if(!item.children) {
    //             const isAuthorize = permissions.includes(Number(item.key));
    //             // const isAuthorize = checkPermissionForComponent([item.key], null, permissions);
    //             if(isAuthorize) {
    //                 menuItem.push(item);
    //             }
                
    //         } else {
    //             const childs = renderMenuItem(item.children, permissions);
    //             if(childs && childs.length > 0) {
    //                 item.children = childs;
    //                 menuItem.push(item);
    //             }
    //         }
    //     });
    //     return menuItem;
    // }

    // events
    const onSignout = () => {
        dispatch(LogoutAction());
    };

    return (
        // <Spin spinning={false} delay={500} className="">
        <div className="h-screen flex ">
            {/* Narrow sidebar */}
            <div className=" items-center w-28 bg-emerald-800 overflow-y-auto md:flex md:flex-col">
                {/* <SidebarDesktop sidebarNavigation={sidebarNavigation} /> */}
                <div className="flex-none py-3">
                    <img className="h-16 w-auto m-auto " src={Logo} alt="CGMC" />
                </div>
                <Menu
                    className="main-navigation grow bg-transparent "
                    onClick={(e) => console.log(e)}
                    defaultSelectedKeys={["1"]}
                    defaultOpenKeys={["sub1"]}
                    mode="vertical"
                    // items={checkMenuPermission()}
                    items={mainNavigation}
                />
                <ProfileAvatar
                    onSignout={onSignout}
                />
            </div>

            {/* Mobile menu */}
            {/* <SidebarMobile sidebarNavigation={mainNavigation} /> */}

            {/* Content area */}
            <div className="flex-1 flex flex-col overflow-hidden">


                {/* Main content */}

                <div className="flex-1 flex relative items-stretch overflow-hidden ">
                    <div
                        className={`flex w-full h-full overflow-hidden absolute backdrop-blur-sm bg-white/10 items-center align-middle z-50 ${isLoading ? "visible" : "invisible"
                            }`}
                    >
                        <Spin
                            size="large"
                            className="m-auto"
                            spinning={isLoading}
                        />
                    </div>

                    <main className="flex-1 overflow-y-auto ">
                        <section
                            aria-labelledby="primary-heading"
                            className="min-w-0 flex-1 h-full flex flex-col lg:order-last relative"
                        >

                            {/* Your content */}

                            {children}
                        </section>
                    </main>
                </div>
            </div>
        </div>
        // </Spin>
    );
};
