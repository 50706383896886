import { call, put, all, takeLatest } from "redux-saga/effects";

import { GET } from "Utility/Constant";
import Request from "Utility/Http";
import ApiEndpoint from "Utility/Endpoint";
import { AsyncStartAction, AsyncEndAction } from "Modules/Application/Actions";
import { ShowAlert } from "Common/Saga/";

import { SELECT_CASINO_STATUS_REQUEST } from "Modules/Settings/CasinoStatus/Constants";
import { ClearSnackMessageAction } from "Components/SnackMessage/Actions";
import { SelectCasinoStatusSuccessAction } from "Modules/Settings/CasinoStatus/Actions";

export function* SelectCasinoStatus() {
    yield put(AsyncStartAction());

    const requestUrl = `${ApiEndpoint.CASINO_STATUS_PATH}/select`;
    const httpPayload = ApiEndpoint.makeApiPayload(requestUrl, GET);

    try {
        const response = yield call(Request, httpPayload);
        yield put(AsyncEndAction());
        yield put(ClearSnackMessageAction());
        yield put(SelectCasinoStatusSuccessAction(response));
    } catch (error: any) {
        yield put(AsyncEndAction());
        if (error.data && error.data.statusCode === 422) {
        }
        return yield ShowAlert("error", error.data.message);
    }
}

export default function* CasinoStatusSaga() {
    yield all([
        yield takeLatest(SELECT_CASINO_STATUS_REQUEST, SelectCasinoStatus),
    ]);
}
