import { List } from "Modules/Settings/GameType/List";
import { Create } from "Modules/Settings/GameType/Create";
import { Detail } from "Modules/Settings/GameType/Detail";

export const SettingGameTypeRoute = {
    path: "game-type",
    children: [
        { path: "", element: <List /> },
        { path: "create", element: <Create /> },
        { path: "detail/:id", element: <Detail /> },
    ],
};
