import { useState,  useRef } from "react";
import { useDispatch } from "react-redux";
import dayjs from "dayjs";

import { DeleteOutlined, EditOutlined, FolderViewOutlined } from "@ant-design/icons";
import {
    DatePicker,
    Form,
    Table,
    Input,
    Button,
    Space,
    QRCode,
    Modal,
    InputNumber,

} from "antd";
import {
    GetGtinNumberRequestAction,
    GetInspectionCertificateMasterRequestAction,
    SetInspectionCertificateMasterIdAction,
} from "Modules/Settings/InspectionCertificateMaster/Actions";
import { DATE_FORMAT, DATE_TIME_FORMAT } from "Modules/Application/Constants";
import  { useReactToPrint } from "react-to-print";
import { PrintTemplate } from "../Print/PrintTemplate";
import { GetInspectionCertificateSerialsService, PrintInspectionCertificateDetailService } from "../Service";
import { AsyncEndAction } from "Modules/Application/Actions";

export const InspectionCertificateMasterForm = ({ form, setSerialVisible, setDetailVisible, onDelete, onEdit, getGtinLatest }: any) => {
    const dispatch = useDispatch();
    const printTemplateRef = useRef();
    const [serialSelectedKeys, setSerialSelectedKeys] = useState([]);
    const [certificateQr, setCertificateQr] = useState<any>(null);
    const [certificateId, setCertificateId] = useState<string>(null);
    const [qrVisible, setQrVisible] = useState<boolean>(false);
    const [printQuantity, setPrintQuantity] = useState<number>(1);
    const [inspectionSerials, setInspectionSerials] = useState<any[]>([]);

    
    //fields
    const inspectionCertificateDetailColumns = [
        {
            width: 140,
            title: "#",
            render: (value, item, index) => <>{index + 1}</>
        },
        {
            width: 140,
            dataIndex: "inspectionCertificateType",
            key: "inspectionCertificateType",
            title: "Certificate Type",
        },
        {
            width: 140,
            dataIndex: "serialNumber",
            key: "serialNumber",
            title: "Serial Number",
        },
        {
            width: 80,
            dataIndex: "quantity",
            key: "quantity",
            title: "Quantity",
        },
        {
            dataIndex: "versionGuid",
            key: "versionGuid",
            title: "UUID",
        },
        {
            width: 130,
            key: "printDate",
            title: "Print Date",
            render: (item: any) => <>{item.printDate ? dayjs(item.printDate).format(DATE_TIME_FORMAT) : ""}</>
        },
        {
          width: 130,
          key: "validedDate",
          title: "Valid until",
          render: (item: any) => <>{item.validedDate ? dayjs(item.validedDate).format(DATE_FORMAT) : ""}</>
          
      },
        {
            width: 120,
            dataIndex: "inspectionCertificateStatus",
            key: "inspectionCertificateStatus",
            title: "Status",
        },
        {
            width: 120,
            key: "id",
            render: (item: any) => <Space>
                <Button type="link" className="p-1" onClick={() => onDelete(item)}>
                    <DeleteOutlined />
                </Button>
                <Button type="link" className="p-1" onClick={() => onEdit(item)}>
                    <EditOutlined />
                </Button>
                { form.getFieldValue('id') && <Button type="link" className="p-1" onClick={() => onPreview(item)}>
                    <FolderViewOutlined />
                </Button>}
            </Space>
        }
    ];

    // events
    const onPreview = (item: any) => {
        setCertificateId(item.id);
        setCertificateQr(`https://qr.cgmc.gov.kh/inspection-certificate/${item.versionGuid}`);
        setQrVisible(true);
    }

    const onPrintConfirm = () => {
        PrintInspectionCertificateDetailService(certificateId, printQuantity, dispatch)
        .then((rs: any) => {
            if(rs && !rs.status) {
                window.open(`/print-format/inspection-certificate/print/${certificateId}`);
                dispatch(SetInspectionCertificateMasterIdAction(rs.inspectionCertificateMasterId));
                dispatch(GetInspectionCertificateMasterRequestAction());
                setPrintQuantity(1);
                setQrVisible(false);
            }
        }).catch((ex: any)=> {
            console.log("Error", ex);
        });
        
    }

    const onManualAddClicked = () => {
        getGtinLatest();
        dispatch(GetGtinNumberRequestAction());
        setDetailVisible(true);
    }

    const onSerialGenerateClicked = () => {
        getGtinLatest();
        dispatch(GetGtinNumberRequestAction());
        setSerialVisible(true);
    }

    const onSelectedSerialChange = (serials: React.Key[]) => {

    }

    const onPrintSerials = () => {
        Modal.info({
            title: 'Print Confirmation',
            content: (
              <div>
                <p>Do you want to print these serials?</p>
                
              </div>
            ),
            onOk() {
                GetInspectionCertificateSerialsService({serials: serialSelectedKeys, updateQuantity: true}, dispatch)
                .then((rs: any) => {
                    setInspectionSerials(rs);
                    setTimeout(() => {
                        dispatch(AsyncEndAction());
                        onPrintSerialsContent();
                        
                    }, 1000);
                    
                });
            },
        });
        
        
    }

    const onPrintSerialsContent = useReactToPrint({
        content: () => {
            return printTemplateRef.current;
        }
    })

    const onPrintQuantityChange = (value: any) => {
        setPrintQuantity(value);
    }

    return (
        <>
            <Form
                name="inspectionCertificateMasterForm"
                form={form}
                className="w-full flex flex-col p-5 gap-2"
                layout="vertical"
            >
                <Form.Item name="id" hidden>
                    <Input />
                </Form.Item>
                
                <Form.Item name="registeredDate" label="Register Date"  required>
                    <DatePicker showTime />
                </Form.Item>
                <Form.Item name="descriptions" label="Descriptions" required>
                    <Input.TextArea />
                </Form.Item>
                <Form.Item name="note" label="Other" required>
                    <Input.TextArea />
                </Form.Item>
                <Space direction="horizontal">
                    <Button type="default" onClick={onSerialGenerateClicked}>
                        Generate Serial
                    </Button>
                    <Button type="default" onClick={onManualAddClicked}>
                        Add
                    </Button>
                    { 
                        form.getFieldValue('id') && 
                        <Button type="default" disabled={serialSelectedKeys && serialSelectedKeys.length === 0} onClick={onPrintSerials}>
                            Print
                        </Button>
                    }
                    
                </Space>
                <h2 className="text-lg mt-4">
                    Serial List
                </h2>
                <Form.Item
                    shouldUpdate={(preValues, curValues) =>
                        preValues.serials !==
                        curValues.serials
                    }
                >
                    {({ getFieldValue }: any) => {
                        const serials: any[] =
                            getFieldValue(
                                "serials"
                            ) || [];
                        return (
                            <Table rowKey="serialNumber"
                            size="small"
                                pagination={{ showSizeChanger: true, pageSizeOptions: [10, 50, 100, 500] }}
                                rowSelection={{
                                    type: "checkbox",
                                    // selectedRowKeys: serialSelectedKeys,
                                    onChange: (selectedRowKeys) => {
                                        onSelectedSerialChange(selectedRowKeys);
                                        setSerialSelectedKeys(selectedRowKeys);
                                    }
                                }}
                                dataSource={serials.sort((a, b) => a.serialNumber - b.serialNumber)}
                                columns={inspectionCertificateDetailColumns} />
                        );
                    }}
                </Form.Item>

            </Form >
            <div className="w-full hidden">
                <div className="m-auto" ref={printTemplateRef}>
                    {
                        inspectionSerials && inspectionSerials.length > 0 && 
                            inspectionSerials.map((inspectionCertificate: any) => <PrintTemplate key={inspectionCertificate.id} inspectionCertificate={inspectionCertificate}/>)
                    }
                </div>
            </div>
            
            <Modal open={qrVisible}
                onOk={onPrintConfirm}
                onCancel={() => setQrVisible(false)}>
                <div className="w-full flex flex-col text-center items-center gap-4 pt-8">
                    
                    <div className="w-full flex items-start ">
                        <InputNumber className="w-full" placeholder="Quantity" onChange={onPrintQuantityChange} value={printQuantity} />
                    </div>
                    
                    <QRCode value={certificateQr} />
                    <p>{certificateQr}</p>
                </div>
            </Modal>
        </>
    )
}