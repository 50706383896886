import { call, all, put, takeLatest, select } from "redux-saga/effects";
import Request from "Utility/Http";
import { GET, POST, PUT } from "Utility/Constant";
import ApiEndpoint from "Utility/Endpoint";
import { v4 as uuid } from "uuid";

import { ShowMessage } from "Common/Saga";
import { AsyncEndAction, AsyncStartAction } from "Modules/Application/Actions";

import {
    CREATE_REQUEST_LOTTERY_LICENSE_REQUEST,
    GET_REQUEST_LOTTERY_LICENSE_REQUEST,
    LIST_REQUEST_LOTTERY_LICENSE_REQUEST,
    EDIT_REQUEST_LOTTERY_LICENSE_REQUEST,
    EXPORT_REQUEST_LOTTERY_LICENSE_REQUEST,
} from "Modules/License/Lottery/ApplicationForm/Constants";
import {
    MakeRequestLotteryLicenseFilterValueSelector,
    MakeRequestLotteryLicenseIdSelector,
    MakeRequestLotteryLicenseValueSelector,
} from "Modules/License/Lottery/ApplicationForm/Selectors";
import {
    ListRequestLotteryLicenseSuccessAction,
    GetRequestLotteryLicenseSuccessAction,
} from "Modules/License/Lottery/ApplicationForm/Actions";

function* ListRequestLotteryLicense() {
    yield put(AsyncStartAction());

    try {
        const filterValues = yield select(
            MakeRequestLotteryLicenseFilterValueSelector()
        );
        const url = `${ApiEndpoint.REQUEST_LICENSE}/lists`;
        const payload = ApiEndpoint.makeApiPayload(url, POST, filterValues);
        const response = yield call(Request, payload);
        if (response && !response.status) {
            yield put(ListRequestLotteryLicenseSuccessAction(response));
        }
    } catch {
        yield ShowMessage({
            type: "error",
            message: "មានបញ្ហាពេលស្វែងរក",
            content: "",
            id: uuid(),
        });
    } finally {
        yield put(AsyncEndAction());
    }
}

function* GetRequestLotteryLicense() {
    try {
        const id = yield select(MakeRequestLotteryLicenseIdSelector());
        if (!id) {
            return yield ShowMessage({
                type: "error",
                message: "មានបញ្ហាពេលទាញយកទិន្នន័យ",
                content: "",
                id: uuid(),
            });
        }
        yield put(AsyncStartAction());
        const url = `${ApiEndpoint.REQUEST_LICENSE}/${id}`;
        const payload = ApiEndpoint.makeApiPayload(url, GET, id);
        const response = yield call(Request, payload);
        if (response && !response.status) {
            yield put(GetRequestLotteryLicenseSuccessAction(response));
        }
    } catch {
        yield ShowMessage({
            type: "error",
            message: "មានបញ្ហាពេលរក្សារទុក",
            content: "",
            id: uuid(),
        });
    } finally {
        yield put(AsyncEndAction());
    }
}

function* CreateRequestLotteryLicense() {
    try {
        const model = yield select(MakeRequestLotteryLicenseValueSelector());
        if (!model) {
            return yield ShowMessage({
                type: "error",
                message: "មានបញ្ហាពេលរក្សារទុក",
                content: "",
                id: uuid(),
            });
        }
        yield put(AsyncStartAction());
        const url = ApiEndpoint.REQUEST_LICENSE;
        const payload = ApiEndpoint.makeApiPayload(url, POST, model);
        const response = yield call(Request, payload);
        if (response) {
            yield ShowMessage({
                type: "success",
                message: "ទិន្នន័យបានរក្សារទុក",
                content: "",
                id: uuid(),
            });
        }
    } catch {
        yield ShowMessage({
            type: "error",
            message: "មានបញ្ហាពេលរក្សារទុក",
            content: "",
            id: uuid(),
        });
    } finally {
        yield put(AsyncEndAction());
    }
}

function* EditRequestLotteryLicense() {
    try {
        const model = yield select(MakeRequestLotteryLicenseValueSelector());
        if (!model) {
            return yield ShowMessage({
                type: "error",
                message: "មានបញ្ហាពេលរក្សារទុក",
                content: "",
                id: uuid(),
            });
        }
        yield put(AsyncStartAction());
        const url = ApiEndpoint.REQUEST_LICENSE;
        const payload = ApiEndpoint.makeApiPayload(url, PUT, model);
        const response = yield call(Request, payload);
        if (response) {
            yield ShowMessage({
                type: "success",
                message: "ទិន្នន័យបានរក្សារទុក",
                content: "",
                id: uuid(),
            });
        }
    } catch {
        yield ShowMessage({
            type: "error",
            message: "មានបញ្ហាពេលរក្សារទុក",
            content: "",
            id: uuid(),
        });
    } finally {
        yield put(AsyncEndAction());
    }
}

export function* ExportRequestLotteryLicense() {
    yield put(AsyncStartAction());
    const requestUrl = `${ApiEndpoint.REQUEST_LICENSE}/excel`;
    const formValues = yield select(
        MakeRequestLotteryLicenseFilterValueSelector()
    );
    const httpPayload = ApiEndpoint.makeApiPayload(
        requestUrl,
        POST,
        formValues,
        null,
        false,
        true
    );
    try {
        httpPayload.responseType = "blob";
        httpPayload.isRaw = true;
        const response = yield call(Request, httpPayload);

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "របាយការណ៍.xlsx");
        document.body.appendChild(link);
        link.click();
    } catch (error: any) {
        if (error.data && error.data.statusCode === 422) {
        }
    } finally {
        yield put(AsyncEndAction());
    }
}

export default function* LotteryLicenseApplicationFormSaga() {
    yield all([
        yield takeLatest(
            LIST_REQUEST_LOTTERY_LICENSE_REQUEST,
            ListRequestLotteryLicense
        ),
        yield takeLatest(
            GET_REQUEST_LOTTERY_LICENSE_REQUEST,
            GetRequestLotteryLicense
        ),
        yield takeLatest(
            CREATE_REQUEST_LOTTERY_LICENSE_REQUEST,
            CreateRequestLotteryLicense
        ),
        yield takeLatest(
            EDIT_REQUEST_LOTTERY_LICENSE_REQUEST,
            EditRequestLotteryLicense
        ),
        yield takeLatest(
            EXPORT_REQUEST_LOTTERY_LICENSE_REQUEST,
            ExportRequestLotteryLicense
        ),
    ]);
}
