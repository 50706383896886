export interface GameTypeProps {
    gameType: string;
    other: string;
}

export const SET_REQUEST_GAME_TYPE_FILTER_VALUE =
    "SET_REQUEST_GAME_TYPE_FILTER_VALUE";
export const SET_REQUEST_GAME_TYPE_VALUE = "SET_REQUEST_GAME_TYPE_VALUE";

export const CREATE_REQUEST_GAME_TYPE_REQUEST =
    "CREATE_REQUEST_GAME_TYPE_REQUEST";

export const LIST_REQUEST_GAME_TYPE_REQUEST = "LIST_REQUEST_GAME_TYPE_REQUEST";
export const LIST_REQUEST_GAME_TYPE_SUCCESS = "LIST_REQUEST_GAME_TYPE_SUCCESS";

export const SET_REQUEST_GAME_TYPE_ID = "SET_REQUEST_GAME_TYPE_ID";
export const GET_REQUEST_GAME_TYPE_REQUEST = "GET_REQUEST_GAME_TYPE_REQUEST";
export const GET_REQUEST_GAME_TYPE_SUCCESS = "GET_REQUEST_GAME_TYPE_SUCCESS";

export const EDIT_REQUEST_GAME_TYPE_REQUEST = "EDIT_REQUEST_GAME_TYPE_REQUEST";
export const EDIT_REQUEST_GAME_TYPE_SUCCESS = "EDIT_REQUEST_GAME_TYPE_SUCCESS";

// Certificate
export const SET_CERTIFICATE_GAME_TYPE_FILTER_VALUES = "SET_CERTIFICATE_GAME_TYPE_FILTER_VALUES";
export const LIST_CERTIFICATE_GAME_TYPE_REQUEST = "LIST_CERTIFICATE_GAME_TYPE_REQUEST";
export const LIST_CERTIFICATE_GAME_TYPE_SUCCESS = "LIST_CERTIFICATE_GAME_TYPE_SUCCESS";

export const SET_CERTIFICATE_GAME_TYPE_VALUE = "SET_CERTIFICATE_GAME_TYPE_VALUE";
export const CREATE_CERTIFICATE_GAME_TYPE_REQUEST = "CREATE_CERTIFICATE_GAME_TYPE_REQUEST";
export const CREATE_CERTIFICATE_GAME_TYPE_SUCCESS = "CREATE_CERTIFICATE_GAME_TYPE_SUCCESS";

export const SET_APPROVE_CERTIFICATE_GAME_TYPE_VALUE = "SET_APPROVE_CERTIFICATE_GAME_TYPE_VALUE";