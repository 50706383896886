import { createSelector } from "reselect";
import { initialState } from "Modules/Settings/GameType/Reducer";

const selectGameType = (state) => state.gameType || initialState;

const makeFormValuesSelector = () =>
    createSelector(selectGameType, (substate) => substate.formValues);

const makeGameTypesSelector = () =>
    createSelector(selectGameType, (substate) => substate.gameTypes);
const MakeGameTypeIdSelector = () =>
    createSelector(selectGameType, (substate) => substate.id);
const MakeGameTypeDetailSelector = () =>
    createSelector(selectGameType, (substate) => substate.gameTypeDetail);

const MakeSelectGameTypeSelector = () => createSelector(selectGameType, substate => substate.gameTypeOptions);

export {
    makeFormValuesSelector,
    makeGameTypesSelector,
    MakeGameTypeDetailSelector,
    MakeGameTypeIdSelector,
    MakeSelectGameTypeSelector
};
