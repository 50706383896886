import { useEffect, useRef } from "react";
import type { FormInstance } from "antd/es/form";
// reset form fields when modal is form, closed
export const useResetFormOnCloseModal = ({
    form,
    visible,
}: {
    form: FormInstance;
    visible: boolean;
}) => {
    const prevVisibleRef = useRef<boolean>();
    useEffect(() => {
        prevVisibleRef.current = visible;
    }, [visible]);
    const prevVisible = prevVisibleRef.current;

    useEffect(() => {
        if (!visible && prevVisible) {
            form.resetFields();
        }
    }, [form, prevVisible, visible]);
};
