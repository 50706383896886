import { all, call, put, select, takeLatest } from "redux-saga/effects";
import { v4 as uuid } from "uuid";

import { GET, POST } from "Utility/Constant";
import ApiEndpoint from "Utility/Endpoint";
import { ShowMessage } from "Common/Saga";
import Request from "Utility/Http";

import { AsyncEndAction, AsyncStartAction } from "Modules/Application/Actions";
import { MakeLotteryCertifyFilterValueSelector, MakeLotteryCertifyIdSelector, MakeLotteryCertifyValueSelector } from "Modules/Certificate/Lottery/Certify/Selector";
import {
    CREATE_LOTTERY_CERTIFY_REQUEST,
    GET_LOTTERY_CERTIFY_REQUEST,
    LIST_LOTTERY_CERITFY_REQUEST
} from "Modules/Certificate/Lottery/Certify/Constants";
import {
    GetLotteryCertifySuccessAction,
    ListLotteryCertifySuccessAction
} from "Modules/Certificate/Lottery/Certify/Actions";

function* CreateLotteryCertify() {

    try {
        const model = yield select(MakeLotteryCertifyValueSelector());
        if (!model) {
            return yield ShowMessage({
                type: "error",
                message: "ទិន្នន័យបានរក្សារទុក",
                redirectUrl: "",
                content: "certificate/lottery/certify-game-type",
                id: uuid(),
            });
        }
        yield put(AsyncStartAction());
        const url = ApiEndpoint.LICENSE_PATH;
        const payload = ApiEndpoint.makeApiPayload(url, POST, model);
        const response = yield call(Request, payload);
        if (response && !response.status) {
            yield ShowMessage({
                type: "error",
                message: "ទិន្នន័យបានរក្សារទុក",
                redirectUrl: "",
                content: "certificate/lottery/certify-game-type",
                id: uuid(),
            });
        }

    } catch {
        yield ShowMessage({
            type: "error",
            message: "មានបញ្ហាពេលរក្សារទុកទិន្នន័យ",
            content: "",
            id: uuid(),
        });
    } finally {
        yield put(AsyncEndAction());
    }
}

function* ListLotteryCertify() {
    yield put(AsyncStartAction());
    try {
        const model = yield select(MakeLotteryCertifyFilterValueSelector());
        const url = `${ApiEndpoint.LICENSE_PATH}/list`;
        const payload = ApiEndpoint.makeApiPayload(url, POST, model);
        const response = yield call(Request, payload);
        if (response && !response.status) {
            yield put(ListLotteryCertifySuccessAction(response));
        }
    } catch {
        yield ShowMessage({
            type: "error",
            message: "មិនអាចទាញយកទិន្នន័យបាន",
            content: "",
            id: uuid(),
        });
    } finally {
        yield put(AsyncEndAction());
    }
}

function* GetLotteryCertify() {
    yield put(AsyncStartAction());
    try {
        const id = yield select(MakeLotteryCertifyIdSelector());
        if (!id) {
            return yield ShowMessage({
                type: "error",
                message: "ស្វែងរកមិនឃើញ",
                redirectUrl: "",
                content: "certificate/lottery/certify-game-type",
                id: uuid(),
            });
        }
        const url = `${ApiEndpoint.LICENSE_PATH}/${id}`;
        const payload = ApiEndpoint.makeApiPayload(url, GET, id);
        const response = yield call(Request, payload);
        if (response && !response.status) {
            yield put(GetLotteryCertifySuccessAction(response));
        }
    } catch {
        yield ShowMessage({
            type: "error",
            message: "មិនអាចទាញយកទិន្នន័យបាន",
            content: "",
            id: uuid(),
        });
    } finally {
        yield put(AsyncEndAction());
    }
}

export default function* LotteryCertifySaga() {
    yield all([
        yield takeLatest(CREATE_LOTTERY_CERTIFY_REQUEST, CreateLotteryCertify),
        yield takeLatest(LIST_LOTTERY_CERITFY_REQUEST, ListLotteryCertify),
        yield takeLatest(GET_LOTTERY_CERTIFY_REQUEST, GetLotteryCertify),
    ])
}