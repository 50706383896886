import {
    SET_INSPECTION_CERTIFICATE_TYPE_FILTER_VALUE,
    SET_INSPECTION_CERTIFICATE_TYPE_ID,
    SET_INSPECTION_CERTIFICATE_TYPE_VALUE,

    LIST_INSPECTION_CERTIFICATE_TYPE_REQUEST,
    LIST_INSPECTION_CERTIFICATE_TYPE_SUCCESS,
    GET_INSPECTION_CERTIFICATE_TYPE_REQUEST,
    GET_INSPECTION_CERTIFICATE_TYPE_SUCCESS,
    SELECT_INSPECTION_CERTIFICATE_TYPE_REQUEST,
    SELECT_INSPECTION_CERTIFICATE_TYPE_SUCCESS,

    SET_INSPECTION_CERTIFICATE_TYPE_STATUS,
    CREATE_INSPECTION_CERTIFICATE_TYPE_REQUEST,
    EDIT_INSPECTION_CERTIFICATE_TYPE_REQUEST,
    DELETE_INSPECTION_CERTIFICATE_TYPE_REQUEST,

} from "Modules/Settings/InspectionCertificateType/Constants";

export const SetInspectionCertificateTypeFilterValueAction = (values: any) => {
    return {
        type: SET_INSPECTION_CERTIFICATE_TYPE_FILTER_VALUE,
        values
    }
}

export const SetInspectionCertificateTypeValueAction = (values: any) => {
    return {
        type: SET_INSPECTION_CERTIFICATE_TYPE_VALUE,
        values
    }
}

export const SetInspectionCertificateTypeIdAction = (id: string) => {
    return {
        type: SET_INSPECTION_CERTIFICATE_TYPE_ID,
        id,
    }
}

export const ListInspectionCertificateTypeRequestAction = () => {
    return {
        type: LIST_INSPECTION_CERTIFICATE_TYPE_REQUEST,
    }
}

export const ListInspectionCertificateTypeSuccessAction = (entities: any[]) => {
    return {
        type: LIST_INSPECTION_CERTIFICATE_TYPE_SUCCESS,
        entities
    }
}

export const GetInspectionCertificateTypeRequestAction = () => {
    return {
        type: GET_INSPECTION_CERTIFICATE_TYPE_REQUEST,
    }
}

export const GetInspectionCertificateTypeSuccessAction = (entity: any) => {
    return {
        type: GET_INSPECTION_CERTIFICATE_TYPE_SUCCESS,
        entity
    }
}

export const SelectInspectionCertificateTypeRequestAction = () => {
    return {
        type: SELECT_INSPECTION_CERTIFICATE_TYPE_REQUEST,
    }
}

export const SelectInspectionCertificateTypeSuccessAction = (entities: any[]) => {
    return {
        type: SELECT_INSPECTION_CERTIFICATE_TYPE_SUCCESS,
        entities
    }
}

export const SetInspectionCertificateTypeStatusAction = (status: number) => {
    return {
        type: SET_INSPECTION_CERTIFICATE_TYPE_STATUS,
        status,
    }
}

export const CreateInspectionCertificateTypeRequestAction = () => {
    return {
        type: CREATE_INSPECTION_CERTIFICATE_TYPE_REQUEST
    }
}

export const EditInspectionCertificateTypeRequestAction = () => {
    return {
        type: EDIT_INSPECTION_CERTIFICATE_TYPE_REQUEST
    }
}

export const DeleteInspectionCertificateTypeRequestAction = () => {
    return {
        type: DELETE_INSPECTION_CERTIFICATE_TYPE_REQUEST
    }
}