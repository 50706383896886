import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useInjectReducer } from "Utility/InjectReducer";
import { useInjectSaga } from "Utility/InjectSaga";

import {
    Form,
    Input,
    Button,
} from "antd";

import LotteryLicenseApplicationFormReducer from "Modules/License/Lottery/ApplicationForm/Reducer";
import LotteryLicenseApplicationFormSaga from "Modules/License/Lottery/ApplicationForm/Saga";
import { ListRequestLotteryLicenseRequestAction, 
    ListRequestLotteryLicenseSuccessAction, 
    SetRequestLotteryLicenseFilterValueAction 
} from "../../Actions";

const key = "lotteryLicenseApplicationForm";
export const LotteryBranchApplicationFormFilter = () => {
    useInjectReducer({ key, reducer: LotteryLicenseApplicationFormReducer });
    useInjectSaga({ key, saga: LotteryLicenseApplicationFormSaga });
    // fields
    const dispatch = useDispatch();
    const [form] = Form.useForm();

    // constructor
    useEffect(() => {
        const model = {
            ...form.getFieldsValue(),
            formType: "G002",
        };
        dispatch(SetRequestLotteryLicenseFilterValueAction(model));
        dispatch(ListRequestLotteryLicenseRequestAction());
        return () => {
            dispatch(ListRequestLotteryLicenseSuccessAction([]));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    // events
    const onFinish = (values: any) => {
        const model = {
            ...values,
            formType: "G002",
        };
        dispatch(SetRequestLotteryLicenseFilterValueAction(model));
        dispatch(ListRequestLotteryLicenseRequestAction());
        return () => {
            dispatch(ListRequestLotteryLicenseSuccessAction([]));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }
    return (
        <Form 
            name="lotteryBranchApplicationFormFilter"
            onFinish={onFinish}
            layout="inline"
            className="p-4">
            <Form.Item name="search">
                <Input  placeholder="ពាក្យ"/>
            </Form.Item>
            <Form.Item>
                <Button type="default" htmlType="submit">ស្វែងរក</Button>
            </Form.Item>
        </Form>
    )
}