import { createSelector } from "reselect";
import { initialState } from "Components/SignIn/Reducer";

const selectRequestCasinoLicenseDomain = (state: any) =>
    state.requestLicenseCasino || initialState;
const MakeFormValuesSelector = () =>
    createSelector(
        selectRequestCasinoLicenseDomain,
        (substate) => substate.formValues
    );

const MakeInitialValuesSelector = () =>
    createSelector(
        selectRequestCasinoLicenseDomain,
        (substate) => substate.initialValues
    );

const MakeErrorSelector = () =>
    createSelector(
        selectRequestCasinoLicenseDomain,
        (substate) => substate.errors
    );

const MakeIsLoadingSelector = () =>
    createSelector(
        selectRequestCasinoLicenseDomain,
        (substate) => substate.isLoading
    );

/**
 * Default selector used by LoginPage
 */

const MakeSelectRequestCasinoLicensePage = () =>
    createSelector(selectRequestCasinoLicenseDomain, (substate) => substate);

export default MakeSelectRequestCasinoLicensePage;
export {
    MakeFormValuesSelector,
    MakeInitialValuesSelector,
    MakeErrorSelector,
    MakeIsLoadingSelector,
};
