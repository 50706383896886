import { useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useInjectReducer } from "Utility/InjectReducer";
import { useInjectSaga } from "Utility/InjectSaga";
import Reducer from "Modules/Settings/GameRule/Reducer";
import Saga from "Modules/Settings/GameRule/Saga";

import { Form, Button } from "antd";
import { FullLayout } from "Components/Layouts/Inner/Full.Layout";
import { HelmetHeader } from "Components/Helmet-Header/index";
import { ArchiveIcon } from "@heroicons/react/outline";
import { SettingGameRuleDetailForm } from "Modules/Settings/GameRule/Forms/GameRuleDetailForm";

import {
    UpdateGameRuleRequestAction,
    SetFormValuesAction,
    GetGameRuleSuccessAction,
} from "Modules/Settings/GameRule/Actions";
import { MakeGameRuleDetailSelector } from "Modules/Settings/GameRule/Selectors";

import { GetGameRuleByIdService } from "Modules/Settings/GameRule/Service";


const key = "gameRule";

export const Detail = () => {
    useInjectReducer({ key: key, reducer: Reducer });
    useInjectSaga({ key: key, saga: Saga });

    // fields
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const { id } = useParams();
    const [searchParams] = useSearchParams();
    const gameRuleDetail = useSelector(MakeGameRuleDetailSelector());
    const entityType = searchParams.get("entityType");

    //constructor
    useEffect(() => {
        const onLoad = async () => {
            const detail = await GetGameRuleByIdService(id, dispatch);
            return detail;
        }
        onLoad()
        .then((detail: any) => {
            dispatch(GetGameRuleSuccessAction(detail));
        })
        .catch((ex: any) => console.log("Erro",ex));
        // dispatch(SetGameRuleIdAction(id));
        // dispatch(GetGameRuleRequestAction());
        
        return () => {
            dispatch(GetGameRuleSuccessAction(null));
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    useEffect(() => {

        if (gameRuleDetail) {
            const gameTypes = gameRuleDetail.gameTypes ? gameRuleDetail.gameTypes.map((gameType) => gameType.id) : [];
            const model = {
                ...gameRuleDetail,
                gameTypeIds: gameTypes
            }
            form.setFieldsValue(model);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [gameRuleDetail])

    // events
    const onFormProviderSubmit = (values: any) => {

        dispatch(SetFormValuesAction(values));
        dispatch(UpdateGameRuleRequestAction());
    };

    const ActionButtons = () => (
        <>
            <Button
                type="primary"
                onClick={() => form.submit()}
            >
                រក្សាទុក
            </Button>
        </>
    );
    return (
        <>
            <FullLayout
                icon={<ArchiveIcon />}
                title={`វិធានល្បែង (${gameRuleDetail?.name})`}
                descriptions="ត្រលប់ទៅបញ្ជី"
                redirectUrl={`/settings/game-rule?entityType=${entityType}`}
                actions={<ActionButtons />}
            >
                <HelmetHeader meta="វិធានល្បែង" />
                <Form onFinish={onFormProviderSubmit}
                    name="settingGameRuleForm"
                    form={form}
                    initialValues={gameRuleDetail}
                    className="p-8">
                    {gameRuleDetail && (
                        <SettingGameRuleDetailForm
                        />
                    )}
                </Form>
            </FullLayout>
        </>
    );
};
