import {
    Row,
    Col,
    Form,
    Input,
    Select,
    Radio,
    DatePicker,
    Space    
} from "antd";
export const CasinoTabDetailForm = (form: any) => {
    return <div>
    <Row>
        <Col span={6}>
            នាមករណ៍ក្រុមហ៊ុនជាអក្សរខ្មែរ <br />
            Company name in Khmer
        </Col>
    
    </Row>
    <Row>
        <Col span={6}>លេខកូដកាស៊ីណូ</Col>
        <Col span={18}>
            <Form.Item name="code" rules={[{ required: true }]}>
                <Input placeholder="លេខកូដកាស៊ីណូ" />
            </Form.Item>
        </Col>
    </Row>
    <Row>
        <Col span={6}>
            នាមករណ៍កាស៊ីណូជាអក្សរខ្មែរ <br />
            Casino name in Khmer
        </Col>
        <Col span={18}>
            <Form.Item name="name" rules={[{ required: true }]}>
                <Input placeholder="នាមករណ៍កាស៊ីណូជាអក្សរខ្មែរ / Casino name in Khmer" />
            </Form.Item>
        </Col>
        <Col span={6}>
            នាមករណ៍កាស៊ីណូជាអក្សរបរទេស <br />
            Casino name in Foreign Language
        </Col>
        <Col span={18}>
            <Form.Item
                name="latinName"
                rules={[{ required: true }]}
            >
                <Input placeholder="នាមករណ៍កាស៊ីណូជាអក្សរឡាតាំង / Casino name in Latin" />
            </Form.Item>
        </Col>

        <Col span={6}>
            ប្រភេទកាស៊ីណូ <br />
            Casino Type
        </Col>
        <Col span={6}>
            ស្ថានភាពអាជ្ញាបណ្ណ <br />
            License Status
        </Col>
        <Col span={18}>
            <Form.Item name="casinoStatusId">
                <Select
                    style={{ minWidth: 200 }}
                    showSearch
                >
                    <Select.Option key="79a726ad-8a2e-4ccb-afa8-04a0d0c398d6" value="79a726ad-8a2e-4ccb-afa8-04a0d0c398d6">
                    គ្មានអាជ្ញាបណ្ណ
                    </Select.Option>
                    <Select.Option key="fecb16b5-e956-4a20-9d9e-a078bce3a47d" value="fecb16b5-e956-4a20-9d9e-a078bce3a47d">
                        មានអាជ្ញាបណ្ណ
                    </Select.Option>
                    <Select.Option key="216beea0-afe9-4542-9dab-90fa6a094e20" value="216beea0-afe9-4542-9dab-90fa6a094e20">
                        ផុតសុពលភាព
                    </Select.Option>
                </Select>
            </Form.Item>
        </Col>
        <Col span={6}>
            លេខអាជ្ញាបណ្ណកាស៊ីណូ
            <br />
            Casino License Number
        </Col>
        <Col span={18}>
            <Row gutter={16}>
                <Col span={8}>
                    <Form.Item name="casinoLicenseNumber">
                        <Input placeholder="លេខអាជ្ញាបណ្ណកាស៊ីណូ / Casino License Number" />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    សុពលភាពអាជ្ញាបណ្ណ
                    <br />
                    Expired date of License
                </Col>
                <Col span={8}>
                    <Form.Item name="licenseExpiredDate">
                        <DatePicker placeholder="សុពលភាពអាជ្ញាបណ្ណ / Expired date of License" />
                    </Form.Item>
                </Col>
            </Row>
        </Col>
        <Col span={6}>
            អាសយដ្ឋានធ្វើអាជីវកម្ម
            <br />
            Address of Business
        </Col>
        <Col span={18}>
            <Form.Item
                name="address"
                rules={[{ required: true }]}
            >
                <Input placeholder="អាសយដ្ឋានធ្វើអាជីវកម្ម / Address of Business" />
            </Form.Item>
        </Col>
    </Row>
    <Row>
        <Col span={6}>
            ទីតាំងនេះជាកម្មសិទ្ធិ ឬជួល?
            <br />
            Is this above Location an Owned or Rented Property?
        </Col>
        <Col span={18}>
            <Form.Item
                name={["casinoData", "propertyStatus"]}
                rules={[
                    {
                        required: true,
                        message: "Please select",
                    },
                ]}
            >
                <Radio.Group>
                    <Space direction="horizontal">
                        <Radio value={1}>
                            កម្មសិទ្ធិ / Owned
                        </Radio>
                        <Radio value={2}>ជួល / Rented</Radio>
                    </Space>
                </Radio.Group>
            </Form.Item>
        </Col>
    </Row>
    </div>
}