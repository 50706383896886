
import { call, put, select, takeLatest,  all } from "redux-saga/effects";
import Request from "Utility/Http";

import ApiEndpoint from "Utility/Endpoint";
import { AsyncStartAction, AsyncEndAction } from "Modules/Application/Actions";
import {
    EntityCasinoListConstant,
    GET_ENTITY_CASINO_BY_ID_REQUEST,
    CREATE_CASINO_MODAL_REQUEST,
    GET_CASINO_MODAL_DETAIL,
    EDIT_ENTITY_CASINO_REQUEST,
    EXPORT_ENTITY_CASINO_REQUEST,
    GET_CASINO_STATUS_COUNT_REQUEST,
    GET_LOTTERY_STATUS_COUNT_REQUEST,
    REPORT_OPERATOR_BY_AREACODE_REQUEST,
    GET_OEPRATOR_STATUS_COUNT_REQUEST,
} from "Modules/Entity/Operator/Constants";
import { POST, GET, PUT } from "Utility/Constant";
import { ShowAlert, ShowMessage } from "Common/Saga/";
import {
    MakeselectFormValueSelector,
    MakeCasinoIdSelector,
} from "Modules/Entity/Operator/Selectors";
import { ClearSnackMessageAction } from "Components/SnackMessage/Actions";
import {
    EntityCasinoListSuccessAction,
    QueryCasinoWithCompanySuccessAction,
    SelectCasinoSuccessAction,
    GetEntityCasinoByIdSuccessAction,
    CreateCasinoModalSuccessAction,
    GetCasinoStatusCountSuccessAction,
    GetLotteryStatusCountSuccessAction,
    ReportOperatorByAreaSuccessAction,
} from "Modules/Entity/Operator/Actions";
import { v4 as uuid } from "uuid";

export function* GetCasinoList(): any {
    yield put(AsyncStartAction());
    const requestUrl = ApiEndpoint.CASINO_LIST_PATH;
    const formValues = yield select(MakeselectFormValueSelector());
    const httpPayload = ApiEndpoint.makeApiPayload(
        requestUrl,
        POST,
        formValues
    );
    try {
        const response = yield call(Request, httpPayload);
        yield put(AsyncEndAction());
        yield put(ClearSnackMessageAction());
        yield put(EntityCasinoListSuccessAction(response));
    } catch (error: any) {
        yield put(AsyncEndAction());
        if (error.data && error.data.statusCode === 422) {
        }
        return yield ShowAlert("error", error.data.message);
    }
}

export function* GetCasino() {
    yield put(AsyncStartAction());
    const formValues = yield select(MakeselectFormValueSelector());
    const requestUrl = `${ApiEndpoint.CASINO_BY_ID_PATH}${formValues}`;
    const httpPayload = ApiEndpoint.makeApiPayload(requestUrl, GET, formValues);
    try {
        const response = yield call(Request, httpPayload);
        yield put(AsyncEndAction());

        yield put(GetEntityCasinoByIdSuccessAction(response));
    } catch (error: any) {
        yield put(AsyncEndAction());
        if (error.data && error.data.statusCode === 422) {
        }
        return yield ShowAlert("error", error.data.message);
    }
}

export function* GetCasinoStatusCount() {
    yield put(AsyncStartAction());
    const requestUrl = `${ApiEndpoint.CASINO_PATH}/casino-status/1`;
    const httpPayload = ApiEndpoint.makeApiPayload(requestUrl, GET, null);
    try {
        const response = yield call(Request, httpPayload);
        yield put(GetCasinoStatusCountSuccessAction(response));
    } catch (error: any) {
        if (error.data && error.data.statusCode === 422) {
        }
        return yield ShowAlert("error", error.data.message);
    }
    finally {
        yield put(AsyncEndAction());
    } 
}

export function* GetLotteryStatusCount() {
    yield put(AsyncStartAction());
    const requestUrl = `${ApiEndpoint.CASINO_PATH}/casino-status/2`;
    const httpPayload = ApiEndpoint.makeApiPayload(requestUrl, GET, null);
    try {
        const response = yield call(Request, httpPayload);
        yield put(GetLotteryStatusCountSuccessAction(response));
    } catch (error: any) {
        if (error.data && error.data.statusCode === 422) {
        }
        return yield ShowAlert("error", error.data.message);
    }
    finally {
        yield put(AsyncEndAction());
    }
}

export function* CreateCasino() {
    yield put(AsyncStartAction());
    const requestUrl = `${ApiEndpoint.CASINO_PATH}/create`;
    const formValues = yield select(MakeselectFormValueSelector());
    const httpPayload = ApiEndpoint.makeApiPayload(
        requestUrl,
        POST,
        formValues,
        null,
        false,
        true
    );
    try {
        const response = yield call(Request, httpPayload);
        yield put(AsyncEndAction());
        if (response && response?.id) {
            yield ShowMessage({
                type: "success",
                message: "ទិន្នយបានរក្សាទុក",
                content: "ទិន្នយបានរក្សាទុក",
                id: uuid(),
                redirectUrl: `/entity/operator/${response.id}`,
            });
        }
    } catch (error: any) {
        yield put(AsyncEndAction());
        if (error.data && error.data.statusCode === 422) {
            // return yield put(EnterValidationErrorAction(error.data.message));
        }
        return yield ShowAlert("error", error.data.message);
    }
}

export function* CreateCasinoModal() {
    yield put(AsyncStartAction());
    const requestUrl = ApiEndpoint.CASINO_CREATE_PATH;
    const formValues = yield select(MakeselectFormValueSelector());
    const httpPayload = ApiEndpoint.makeApiPayload(
        requestUrl,
        POST,
        formValues,
        null,
        false,
        true
    );
    try {
        const response = yield call(Request, httpPayload);
        yield put(AsyncEndAction());
        if (response && response?.id) {
            yield ShowMessage({
                type: "success",
                message: "ទិន្នយកាស៊ីណូបានរក្សាទុក",
                content: "ទិន្នយកាស៊ីណូបានរក្សាទុក",
                id: uuid(),
            });
            yield put(CreateCasinoModalSuccessAction(response));
        }
    } catch (error: any) {
        yield put(AsyncEndAction());
        if (error.data && error.data.statusCode === 422) {
            // return yield put(EnterValidationErrorAction(error.data.message));
        }
        return yield ShowAlert("error", error.data.message);
    }
}

export function* SelectCasino() {
    yield put(AsyncStartAction());
    const requestUrl = ApiEndpoint.CASINO_SELECT_PATH;

    const httpPayload = ApiEndpoint.makeApiPayload(requestUrl, POST);
    try {
        const response = yield call(Request, httpPayload);
        yield put(AsyncEndAction());
        yield put(ClearSnackMessageAction());
        yield put(SelectCasinoSuccessAction(response));
    } catch (error: any) {
        yield put(AsyncEndAction());
        if (error.data && error.data.statusCode === 422) {
            // return yield put(EnterValidationErrorAction(error.data.message));
        }
        return yield ShowAlert("error", error.data.message);
    }
}

export function* ReportOperatorByArea() {
    yield put(AsyncStartAction());
    const requestUrl = `${ApiEndpoint.CASINO_PATH}/by-areacode`;
    const httpPayload = ApiEndpoint.makeApiPayload(requestUrl, POST);
    try {
        const response = yield call(Request, httpPayload);
        yield put(ReportOperatorByAreaSuccessAction(response));
    } catch (error: any) {
        yield put(AsyncEndAction());
        if (error.data && error.data.statusCode === 422) {
            // return yield put(EnterValidationErrorAction(error.data.message));
        }
        return yield ShowAlert("error", error.data.message);
    }
    finally{
        yield put(AsyncEndAction());
    }
}

export function* GetCasinoByCompanyList(): any {
    yield put(AsyncStartAction());
    const requestUrl = ApiEndpoint.CASINO_BY_COMPANY_LIST_PATH;
    const formValues = yield select(MakeselectFormValueSelector());
    const httpPayload = ApiEndpoint.makeApiPayload(
        requestUrl,
        POST,
        formValues
    );
    try {
        const response = yield call(Request, httpPayload);
        yield put(AsyncEndAction());
        yield put(ClearSnackMessageAction());
        yield put(QueryCasinoWithCompanySuccessAction(response));
    } catch (error: any) {
        yield put(AsyncEndAction());
        if (error.data && error.data.statusCode === 422) {
            // return yield put(EnterValidationErrorAction(error.data.message));
        }
        return yield ShowAlert("error", error.data.message);
    }
}

export function* GetCasinoDetail() {
    yield put(AsyncStartAction());
    const id = yield select(MakeCasinoIdSelector());
    const requestUrl = `${ApiEndpoint.CASINO_BY_ID_PATH}detail/${id}`;
    const httpPayload = ApiEndpoint.makeApiPayload(
        requestUrl,
        GET,
        id,
        false,
        false
    );
    try {
        const response = yield call(Request, httpPayload);
        yield put(AsyncEndAction());

        yield put(GetEntityCasinoByIdSuccessAction(response));
    } catch (error: any) {
        yield put(AsyncEndAction());
        if (error.data && error.data.statusCode === 422) {
        }
        return yield ShowAlert("error", error.data.message);
    }
}

export function* EditCasino() {
    yield put(AsyncStartAction());
    const requestUrl = ApiEndpoint.CASINO_PATH;
    const formValues = yield select(MakeselectFormValueSelector());
    const httpPayload = ApiEndpoint.makeApiPayload(
        requestUrl,
        PUT,
        formValues,
        null,
        false,
        true
    );
    try {
        const response = yield call(Request, httpPayload);
        yield put(AsyncEndAction());
        if (response && response?.id) {
            yield ShowMessage({
                type: "success",
                message: "ទិន្នយកាស៊ីណូបានរក្សាទុក",
                content: "ទិន្នយកាស៊ីណូបានរក្សាទុក",
                id: uuid(),
            });
            yield put(CreateCasinoModalSuccessAction(response));
        }
    } catch (error: any) {
        yield put(AsyncEndAction());
        if (error.data && error.data.statusCode === 422) {
            // return yield put(EnterValidationErrorAction(error.data.message));
        }
        return yield ShowAlert("error", error.data.message);
    }
}

export function* ExportCasino() {
    yield put(AsyncStartAction());
    const requestUrl = `${ApiEndpoint.CASINO_PATH}/excel`;
    const formValues = yield select(MakeselectFormValueSelector());
    const httpPayload = ApiEndpoint.makeApiPayload(
        requestUrl,
        POST,
        formValues,
        null,
        false,
        true
    );
    try {
        httpPayload.responseType = "blob";
        httpPayload.isRaw = true;
        const response = yield call(Request, httpPayload);
        yield put(AsyncEndAction());
        yield put(ClearSnackMessageAction());

        // yield put(EntityCasinoListSuccessAction(response));

        // let url = window.URL.createObjectURL(new Blob([response]));
        // saveAs(url, "fileName.xlsx");

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "របាយការកិច្ចប្រជុំ.xlsx");
        document.body.appendChild(link);
        link.click();
    } catch (error: any) {
        yield put(AsyncEndAction());
        if (error.data && error.data.statusCode === 422) {
        }
        return yield ShowAlert("error", error);
    }
}

function* GetChart() {
    yield (GetCasinoStatusCount());
    yield (GetLotteryStatusCount());
}

export default function* EntityCasinoSaga() {
    yield all([
        yield takeLatest(
            EntityCasinoListConstant.ENTITY_CASINO_LIST_REQUEST,
            GetCasinoList
        ),
        yield takeLatest(
            EntityCasinoListConstant.SELECT_CASINO_REQUEST,
            SelectCasino
        ),
        yield takeLatest(
            EntityCasinoListConstant.QUERY_CASINO_WITH_COMPANY_REQUEST,
            GetCasinoByCompanyList
        ),
        yield takeLatest(
            EntityCasinoListConstant.CREATE_CASINO_REQUEST,
            CreateCasino
        ),
        yield takeLatest(GET_ENTITY_CASINO_BY_ID_REQUEST, GetCasino),
        yield takeLatest(GET_CASINO_STATUS_COUNT_REQUEST, GetCasinoStatusCount),
        yield takeLatest(GET_LOTTERY_STATUS_COUNT_REQUEST, GetLotteryStatusCount),
        yield takeLatest(GET_OEPRATOR_STATUS_COUNT_REQUEST, GetChart),
        yield takeLatest(CREATE_CASINO_MODAL_REQUEST, CreateCasinoModal),
        yield takeLatest(GET_CASINO_MODAL_DETAIL, GetCasinoDetail),
        yield takeLatest(EDIT_ENTITY_CASINO_REQUEST, EditCasino),
        yield takeLatest(EXPORT_ENTITY_CASINO_REQUEST, ExportCasino),
        yield takeLatest(REPORT_OPERATOR_BY_AREACODE_REQUEST, ReportOperatorByArea),
        
    ]);
}