import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useInjectReducer } from "Utility/InjectReducer";
import { useInjectSaga } from "Utility/InjectSaga";
import { v4 as uuid } from "uuid";
import dayjs from "dayjs";

import { Form, Button, Space } from "antd";
import { FullLayout } from "Components/Layouts/Inner/Full.Layout";
import { HelmetHeader } from "Components/Helmet-Header/index";
import { ArchiveIcon } from "@heroicons/react/outline";
import { RequestLotteryGameTypeEquipmentDetailForm } from "Modules/Certificate/Lottery/ApplicationForm/GameTypeEquipment/Forms/RequestLotteryGameTypeEquipmentDetailForm";

import LotteryGameTypeCertifyReducer from "Modules/Certificate/Lottery/Certify/Reducer";
import LotteryApplicationFormReducer from "Modules/Certificate/Lottery/ApplicationForm/Reducer";
import LotteryApplicationFormSaga from "Modules/Certificate/Lottery/ApplicationForm/Saga";
import {
    EditRequestLotteryGameTypeRequestAction, GetRequestLotteryGameTypeSuccessAction,
    SetRequestLotteryGameTypeIdAction, SetRequestLotteryGameTypeValueAction
} from "Modules/Certificate/Lottery/ApplicationForm/Actions";
import { GetRequestLotteryGameTypeRequestAction } from "Modules/Certificate/Lottery/ApplicationForm/Actions";
import { MakeRequestLotteryGameTypeSelector } from "Modules/Certificate/Lottery/ApplicationForm/Selectors";
// import { SetLotteryGameTypeApproveCertifyValueAction } from "Modules/Certificate/Lottery/Certify/Actions";

const key = "lotteryApplicationForm";
const certifyKey = "lotteryCertify";
export const RequestLotteryGameTypeDetail = () => {
    useInjectReducer({ key, reducer: LotteryApplicationFormReducer });
    useInjectSaga({ key, saga: LotteryApplicationFormSaga });

    useInjectReducer({ key: certifyKey, reducer: LotteryGameTypeCertifyReducer });
    // fields
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const { id } = useParams();
    const requestLotteryGameType = useSelector(MakeRequestLotteryGameTypeSelector())

    // cosntructor
    useEffect(() => {
        if (id) {
            dispatch(SetRequestLotteryGameTypeIdAction(id));
            dispatch(GetRequestLotteryGameTypeRequestAction());
        }
        return () => {
            dispatch(GetRequestLotteryGameTypeSuccessAction(null));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (requestLotteryGameType) {
            const model = {
                ...requestLotteryGameType
            };
            model.receivedDate = requestLotteryGameType.receivedDate ? dayjs(requestLotteryGameType.receivedDate) : null;
            model.formData.applicant.dateOfBirth = requestLotteryGameType?.formData?.applicant?.dateOfBirth ? dayjs(requestLotteryGameType?.formData?.applicant?.dateOfBirth) : null;
            model.formData.applicant.identityIssuedDate = requestLotteryGameType?.formData?.applicant?.identityIssuedDate ? dayjs(requestLotteryGameType?.formData?.applicant?.identityIssuedDate) : null;
            model.formData.applicant.expiredDate = requestLotteryGameType?.formData?.applicant?.expiredDate ? dayjs(requestLotteryGameType?.formData?.applicant?.expiredDate) : null;
            model.gameTypes = requestLotteryGameType.formData?.gameTypes || [];
            model.gameEquipments = requestLotteryGameType.formData?.gameEquipments || [];
            form.setFieldsValue(model);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [requestLotteryGameType])

    //events
    const onFormProviderSubmit = (name: string, { forms, values }: any) => {
        switch (name) {
            case "lotteryGameTypeForm":
                const { lotteryGameTypeForm } = forms;
                if (lotteryGameTypeForm) {
                    const gameEquipments = form.getFieldValue("gameTypes") || [];
                    const gameEquipment = { id: uuid(), ...lotteryGameTypeForm.getFieldsValue() };

                    gameEquipments.push(gameEquipment)
                    form.setFieldValue("gameTypes", gameEquipments);
                    lotteryGameTypeForm.resetFields();
                }
                break;
            case "lotteryGameEquipmentForm":

                const { lotteryGameEquipmentForm } = forms;
                if (lotteryGameEquipmentForm) {
                    const gameEquipments = form.getFieldValue("gameEquipments") || [];
                    const gameEquipment = { id: uuid(), ...lotteryGameEquipmentForm.getFieldsValue() };
                    gameEquipments.push(gameEquipment)
                    form.setFieldValue("gameEquipments", gameEquipments);
                    lotteryGameEquipmentForm.resetFields();
                }
                break;
            case "requestLotteryGameTypeEquipmentDetailForm":
                const gameTypes: any[] = form.getFieldValue("gameTypes");
                const gameEquipments: any[] = form.getFieldValue("gameEquipments");

                const model = { ...form.getFieldsValue() };
                model.formData.gameTypes = gameTypes;
                model.formData.gameEquipments = gameEquipments;

                dispatch(SetRequestLotteryGameTypeValueAction(model));
                dispatch(EditRequestLotteryGameTypeRequestAction());
                break;
        }

    };

    // const onApproveClick = () => {
    //     if (requestLotteryGameType) {
    //         dispatch(SetLotteryGameTypeApproveCertifyValueAction(requestLotteryGameType));
    //         navigate("/certificate/lottery/certify-game-type/approve")
    //     }
    // }

    const ActionButtons = () => (
        <Space>
            <Button
                onClick={() => form.submit()}
                className="inline-flex items-center justify-center mx-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500"
            >
                រក្សារទុក
            </Button>

        </Space>
    );

    return (
        <>
            <FullLayout
                icon={<ArchiveIcon />}
                
                title={`ពាក្យស្នើសុំចុះបញ្ជីប្រភេទល្បែង និឧបករណ៍ល្បែង សម្រាប់ល្បែងផ្សងសំណាង (L003) ${requestLotteryGameType?.formData?.casino?.name} `}
                descriptions=""
                actions={<ActionButtons />}
            >
                <HelmetHeader meta="ពាក្យស្នើសុំចុះបញ្ជីប្រភេទល្បែង និឧបករណ៍ល្បែង សម្រាប់ល្បែងផ្សងសំណាង" />
                <Form.Provider onFormFinish={onFormProviderSubmit}>
                    <RequestLotteryGameTypeEquipmentDetailForm form={form} />
                </Form.Provider>

            </FullLayout>
        </>
    );
};
