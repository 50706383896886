import {
    Button,
    Form,
    Input
} from "antd";

export const InspectionCertificateTypeFilterForm = () => {

    return (
        <>
            <Form
                name="equipmentTypeFilterForm"
                layout="vertical"
                className="w-full grid grid-cols-4 items-end gap-2 p-5"
                initialValues={{ search: "" }}
            >
                <Form.Item name="search" label="ប្រភេទឧបករណ៍">
                    <Input autoComplete="off" />
                </Form.Item>
                <Form.Item>
                    <Button htmlType="submit" type="default">
                        ស្វែងរក
                    </Button>
                </Form.Item>
            </Form>
        </>)
}