import { LotteryGameTypeCertifyApprove } from "Modules/Certificate/Lottery/Certify/GameTypeEquipment/Approve";
import { LotteryGameTypeCertifyList } from "Modules/Certificate/Lottery/Certify/GameTypeEquipment/List";
import { LotteryGameTypeCertifyDetail } from "Modules/Certificate/Lottery/Certify/GameTypeEquipment/Detail";
export const CertifyLotteryGameTypeRoute = {
    path: "certify-game-type",
    children: [
        { path: "", element: <LotteryGameTypeCertifyList /> },
        { path: "approve", element: <LotteryGameTypeCertifyApprove /> },
        { path: ":id", element: <LotteryGameTypeCertifyDetail /> }
    ],
};
