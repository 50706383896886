export const ADD_VALIDATION_ERROR = "containers/SignIn/ADD_VALIDATION_ERROR";
export const SET_FORM_VALUES = "containers/SignIn/SET_FORM_VALUES";
export const CHANGE_LOGIN = "containers/SignIn/CHANGE_LOGIN";
export const ENTER_LOGIN_ERROR = "containers/SignIn/ENTER_LOGIN_ERROR";
export const LOGIN_REQUEST = "containers/SignIn/LOGIN_REQUEST";
export const LOGIN = "containers/SignIn/LOGIN";
export const LOGIN_SUCCESS = "containers/SignIn/LOGIN_SUCCESS";
export const LOGIN_ERROR = "containers/SignIn/LOGIN_ERROR";

//
export interface LoginProps {
    email: string;
    password: string;
}
