import { EquipmentTypeInitialState } from "Modules/Settings/EquipmentType/Reducer";
import { createSelector } from "reselect";

const EquipmentTypeDomain = (state) => state.equipmentType || EquipmentTypeInitialState;

const MakeEquipmentTypeFilterValueSelector = () => createSelector(EquipmentTypeDomain, substate => substate.filterValues);
const MakeEquipmentTypeValueSelector = () => createSelector(EquipmentTypeDomain, substate => substate.values);
const MakeEquipmentTypeIdSelector = () => createSelector(EquipmentTypeDomain, substate => substate.id);

const MakeEquipmentTypeListSelector = () => createSelector(EquipmentTypeDomain, substate => substate.entities);
const MakeEquipmentTypeSelector = () => createSelector(EquipmentTypeDomain, substate => substate.entity);
const MakeEquipmentTypeOptionsSelector = () => createSelector(EquipmentTypeDomain, substate => substate.options);
const MakeEquipmentTypeStatusSelector = () => createSelector(EquipmentTypeDomain, substate => substate.status);

export {
    MakeEquipmentTypeFilterValueSelector,
    MakeEquipmentTypeValueSelector,
    MakeEquipmentTypeIdSelector,
    MakeEquipmentTypeListSelector,
    MakeEquipmentTypeSelector,
    MakeEquipmentTypeOptionsSelector,
    MakeEquipmentTypeStatusSelector
}