import { useEffect } from "react";
import { useDispatch } from "react-redux";
import dayjs from "dayjs";
import { useInjectSaga } from "Utility/InjectSaga";
import { useInjectReducer } from "Utility/InjectReducer";

import { Link } from "react-router-dom";
import { Button, Form, Table } from "antd";
import { FullLayout } from "Components/Layouts/";
import { HelmetHeader } from "Components/Helmet-Header";
import { CertificateGameTypeFilterForm } from "Modules/Certificate/Casino/ApplicationForm/GameType/Forms/CertificateGameTypeFilterForm";

import Reducer from "Modules/Certificate/Casino/ApplicationForm/GameType//Reducer";
import Saga from "Modules/Certificate/Casino/ApplicationForm/GameType//Saga";
import CertificateGameTypeReducer from "Modules/Certificate/Casino/ApplicationForm/GameType/Reducer";
import CertificateGameTypeSaga from "Modules/Certificate/Casino/ApplicationForm/GameType/Saga";
import { SetCertificateGameTypeFilterValueAction, ListCertificateGameTypeRequestAction, ListCertificateGameTypeSuccessAction } from "Modules/Certificate/Casino/ApplicationForm/GameType/Actions";

// import { MakeSelectLicenseListSelector } from "Modules/Certificate/Casino/ApplicationForm/GameType/Selectors";
import { DATE_FORMAT } from "Modules/Application/Constants";

const key = "licenseList";
const certificateGameTypeKey = "certificateGameType";
export const List = () => {
    useInjectReducer({ key, reducer: Reducer });
    useInjectSaga({ key, saga: Saga });

    useInjectReducer({
        key: certificateGameTypeKey,
        reducer: CertificateGameTypeReducer,
    });
    useInjectSaga({
        key: certificateGameTypeKey,
        saga: CertificateGameTypeSaga,
    });
    //fields
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    // const licenses = useSelector(MakeSelectLicenseListSelector());

    const licenseColumns = [
        {
            title: "លេខអាជ្ញាបណ្ណ",
            key: "code",
            render: (item: any) => {
                const address = JSON.parse(item.formData).casino
                    .casinoLicenseNumber;

                return (
                    <Link
                        to={`/license/casino/detail/${item.id}`}
                        className="underline text-blue-500"
                    >
                        {address}
                    </Link>
                );
            },
        },
        {
            title: "ឈ្មោះក្រុមហ៊ុន",
            key: "companyNameInKhmer",
            dataIndex: "companyNameInKhmer",
        },
        {
            title: "ឈ្មោះកាស៊ីណូ",
            key: "casinoNameInKhmer",
            dataIndex: "casinoNameInKhmer",
        },
        {
            title: "ទីតាំងធ្វើអាជីវកម្ម",
            key: "address",
            // dataIndex: "representativeName",
            render: (item: any) => {
                const address = JSON.parse(item.formData).casino.address;

                return <span>{address}</span>;
            },
        },
        {
            title: "ថ្ងៃផ្តល់អាជ្ញាបណ្ណ",
            key: "licenseIssuedDate",
            // dataIndex: "licenseExpiredDate",
            render: (item: any) => (
                <span>
                    {item.licenseIssuedDate
                        ? dayjs(item.licenseIssuedDate).format(DATE_FORMAT)
                        : ""}
                </span>
            ),
        },
        {
            title: "សុពលភាពអាជ្ញាបណ្ណ",
            key: "licenseExpiredDate",
            // dataIndex: "licenseExpiredDate",
            render: (item: any) => (
                <span>
                    {item.licenseExpiredDate
                        ? dayjs(item.licenseExpiredDate).format(DATE_FORMAT)
                        : ""}
                </span>
            ),
        },
    ];

    // cosntructors
    useEffect(() => {
        return () => {
            dispatch(ListCertificateGameTypeSuccessAction([]));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    // method
    const ActionButton = () => {
        return (
            <>
                <Link
                    to="create">
                    <Button type="primary">
                        បញ្ចូលថ្មី
                    </Button>
                </Link>
            </>
        );
    };
    // events

    const onFilterFormSubmit = (values: any) => {
        const formValues = {
            code: form.getFieldValue("code") || "",
            startExpiredEDate: null,
            endExpiredDate: null,
            formType: values.formType || "C002",
        };
        dispatch(SetCertificateGameTypeFilterValueAction(formValues));
        dispatch(ListCertificateGameTypeRequestAction());
    };

    return (
        <FullLayout
            icon={null}
            title="បញ្ជីការចុះបញ្ជីប្រភេទល្បែង"
            descriptions=""
            actions={<ActionButton />}
        >
            <HelmetHeader meta="បញ្ជីការចុះបញ្ជីប្រភេទល្បែង" />
            <Form
                onFinish={onFilterFormSubmit}
                name="licenseListForm"
                layout="vertical"
                className="p-4"
                form={form}
                initialValues={{ search: "" }}
            >
                <CertificateGameTypeFilterForm form={form} />
                <Table columns={licenseColumns} dataSource={[]} rowKey="id" size="small"/>
            </Form>
        </FullLayout>
    );
};
