import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Row, Col, Input, Select } from "antd";

import { useInjectReducer } from "Utility/InjectReducer";
import GameTypeReducer from "Modules/Settings/GameType/Reducer";
import { MakeSelectGameTypeSelector } from "Modules/Settings/GameType/Selectors";
import {
    SelectGameTypeSuccessAction,
} from "Modules/Settings/GameType/Actions";
import { useSearchParams } from "react-router-dom";
import { SelectGameTypeService } from "Modules/Settings/GameType/Service";

const gameTypeKey = "gameType";
export const SettingGameRuleDetailForm = () => {
    useInjectReducer({ key: gameTypeKey, reducer: GameTypeReducer });

    //fields
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();
    const gameTypeOptions = useSelector(MakeSelectGameTypeSelector());
    const entityType = searchParams.get("entityType");

    // constructor
    useEffect(() => {
        const onLoad = async () => {
            const entityTypeId = entityType === "LOTTERY" ? 2 : 1;
            const gameTypeRS = await SelectGameTypeService(entityType !== "LOTTERY" && entityType !== "CASINO" ? 0 : entityTypeId, dispatch);
            return gameTypeRS;
        }
        onLoad()
        .then((rs:any) => {
            dispatch(SelectGameTypeSuccessAction(rs || []));
        });
        
        return () => {
            dispatch(SelectGameTypeSuccessAction([]));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Form.Item name="id" hidden>
                <Input />
            </Form.Item>
            <Row>
                <Col span={4}>វិធានល្បែង</Col>
                <Col span={20}>
                    <Form.Item name="name">
                        <Input />
                    </Form.Item>
                </Col>
                <Col span={4}>ប្រភេទល្បែង</Col>
                <Col span={20}>
                    <Form.Item name="gameTypeIds">

                        <Select mode="multiple">
                            {gameTypeOptions &&
                                gameTypeOptions.map((item) => (
                                    <Select.Option
                                        key={`${item.id}`}
                                        value={item.id}
                                    >
                                        {item.name}
                                    </Select.Option>
                                ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={4}>បរិយាយ / ផ្សេងៗ</Col>
                <Col span={20}>
                    <Form.Item name="descriptions">
                        <Input.TextArea />
                    </Form.Item>
                </Col>
            </Row>
        </>
    );
};
