import {
    SNACK_UNMOUNT,
    AUTO_DISMISS_SNACK,
    CLEAR_SNACK,
    SHOW_SNACK_MESSAGE,
} from "Components/SnackMessage/Constants";

export function EnqueueSnackMessageAction(snack: any) {
    return {
        type: SHOW_SNACK_MESSAGE,
        snack,
    };
}

export function ClearSnackMessageAction() {
    return {
        type: CLEAR_SNACK,
    };
}

export function AutoDismissSnackMessageAction() {
    return {
        type: AUTO_DISMISS_SNACK,
    };
}

export function SnackUnmountAction() {
    return {
        type: SNACK_UNMOUNT,
    };
}
