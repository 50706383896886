import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useInjectReducer } from "Utility/InjectReducer";
import { useInjectSaga } from "Utility/InjectSaga";

import {
    Tabs,
    Modal,
    Form,
    Row,
    Col,
    Table,
    Checkbox,
    Input,
    DatePicker,
    Radio,
    Space,
    Select,
    Button
} from "antd";

import OperatorReducer from "Modules/Entity/Operator/Reducer";
import OperatorSaga from "Modules/Entity/Operator/Saga";
import { GameTypeProps } from "Modules/Certificate/Casino/ApplicationForm/GameType/Constants";
import MakeSelectCasinoLicensePage from "Modules/License/Casino/Selectors";
import { MakeCasinoSelector, MakeSelectCasinoSelector } from "Modules/Entity/Operator/Selectors";
import { GetCasinoModalDetailAction, SelectCasinoRequestAction, SelectCasinoSuccessAction, SetCasinoIdAction } from "Modules/Entity/Operator/Actions";
import { LotteryGameTypeForm } from "Modules/Certificate/Lottery/ApplicationForm/GameTypeEquipment/Forms/LotteryGameTypeForm";
import { LotteryGameEquipmentForm } from "Modules/Certificate/Lottery/ApplicationForm/GameTypeEquipment/Forms/LotteryGameEquipmentForm";

const key = "entityCasino";
export const RequestLotteryGameTypeEquipmentCreateForm = ({ form, onFinish }: any) => {
    useInjectReducer({ key, reducer: OperatorReducer });
    useInjectSaga({ key, saga: OperatorSaga });
    // fields
    const dispatch = useDispatch();
    // const [searchParams] = useSearchParams();
    const [gameTypeVisible, setGameTypeVisible] = useState(false);
    const [gameEquipmentVisible, setGameEquipmentVisible] = useState(false);
    const [gameTypeForm] = Form.useForm();
    const [gameEquipmentForm] = Form.useForm();
    const operatorOptions = useSelector(MakeSelectCasinoSelector());
    const { identificationTypes } = useSelector(MakeSelectCasinoLicensePage());
    // const entityType = searchParams.get("entityType");
    const casinoDetail = useSelector(MakeCasinoSelector());

    useEffect(() => {
      if (casinoDetail) {
          const model = {
              code: casinoDetail.casino.code,
              formData: {
                  company: { name: casinoDetail.company.name },
                  casino: { address: casinoDetail.casino.address, name: casinoDetail.casino.name },
              },
          };
          form.setFieldsValue(model);
      }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [casinoDetail]);

    const lotteryGameTypeColumn = [
        {
            key: "no",
            title: "ល.រ/No.",
            render: (
                value,
                item,
                index
            ) => <>{index + 1}</>
        },
        {
            key: "gameType",
            title: "ប្រភេទល្បែង / Game Type",
            render: (item: any) => <>{item?.gameType?.label}</>
        },
        {
            key: "other",
            title: "ផ្សេងៗ/Other",
            dataIndex: "other"
        }
    ];
    const lotteryGameEquipmentColumn = [
        {
            key: "no",
            title: "ល.រ/No.",
            render: (
                value,
                item,
                index
            ) => <>{index + 1}</>
        },
        {
            key: "gameEquipment",
            title: "ឧបករណ៍ល្បែង / Game Equipment",
            render: (item: any) => <>{item.gameEquipment}</>
        },
        {
            key: "other",
            title: "ផ្សេងៗ/Other",
            dataIndex: "other"
        }
    ];

    // constructor
    useEffect(() => {
        dispatch(SelectCasinoRequestAction());

        return () => {
            dispatch(SelectCasinoSuccessAction([]));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // events
    const onOperatorChange = (e: any) => {
      const id = e;
      form.setFieldValue("casinoId", id);
      dispatch(SetCasinoIdAction(id));
      dispatch(GetCasinoModalDetailAction(null));
    }

    return (<>
        <Form name="requestLotteryGameTypeEquipmentCreateForm" form={form}>
            <Form.Item name="casinoId" hidden={true}>
                <Input />
            </Form.Item>
            <Tabs
                defaultActiveKey="1"
                className="p-5"
                items={[
                    {
                        label: `សម្រាប់មន្ត្រី គ.ល.ក..`,
                        key: "1",
                        children: (
                            <>
                                <Form.Item name="code" hidden={true}>
                                    <Input />
                                </Form.Item>
                                <Form.Item name="casinoId" hidden={true}>
                                    <Input />
                                </Form.Item>
                                <Form.Item name={["formData", "casino", "name"]} hidden={true}>
                                    <Input />
                                </Form.Item>
                                <Row gutter={16}>
                                    <Col span={5}>
                                        លេខសំគាល់ពាក្យស្នើសុំ
                                    </Col>
                                    <Col span={7}>
                                        <Form.Item name="documentCode">
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col span={5}>ឈ្មោះអ្នកទទួល</Col>
                                    <Col span={7}>
                                        <Form.Item name="receiverName">
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col span={5}>
                                        លេខកូដចំណូលកាតព្វកិច្ចពីល្បែង
                                    </Col>
                                    <Col span={7}>
                                        <Form.Item name="gamblingTaxCode">
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col span={5}>កាលបរិច្ឆេទទទួល</Col>
                                    <Col span={7}>
                                        <Form.Item name="receivedDate">
                                            <DatePicker placeholder="កាលបរិច្ឆេទទទួល" />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </>
                        ),
                    },
                    {
                        label: `ព័ត៌មានអំពីក្រុមហ៊ុន`,
                        key: "2",
                        children: (
                            <>
                                <Row>
                                <Col span={6}>
                                        ឈ្មោះក្រុមហ៊ុន / Company Name
                                    </Col>
                                    <Col span={18}>
                                        <Form.Item
                                            name={[
                                                "formData",
                                                "company",
                                                "name",
                                            ]}
                                            rules={[{ required: true }]}
                                        >
                                            <Input
                                                placeholder="ឈ្មោះក្រុមហ៊ុន / Company Name"
                                                status="warning"
                                                readOnly
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        ឈ្មោះកាស៊ីណូ / Casino Name
                                    </Col>
                                    <Col span={18}>
                                        <Form.Item
                                            name={["formData", "casino", "id"]}
                                            rules={[{ required: true }]}
                                        >
                                            {/* <Input
                                                    placeholder="ឈ្មោះកាស៊ីណូ / Casino Name"
                                                    status="warning"
                                                    readOnly
                                                /> */}
                                            <Select
                                                placeholder="ជ្រើសរើសប្រតិបត្តិករល្បែងផ្សងសំណាង / Choose Lottery"
                                                onChange={onOperatorChange}
                                                filterOption={(input, option) =>
                                                    option!.children
                                                        .join(" ")
                                                        .toLowerCase()
                                                        .includes(
                                                            input.toLowerCase()
                                                        )
                                                }
                                                showSearch
                                            >
                                                {operatorOptions &&
                                                    operatorOptions.map(
                                                        (item) => (
                                                            <Select.Option
                                                                key={item.value}
                                                                value={
                                                                    item.value
                                                                }
                                                            >
                                                                {item.label}(
                                                                {
                                                                    item.placeholder
                                                                }
                                                                )
                                                            </Select.Option>
                                                        )
                                                    )}
                                            </Select>
                                        </Form.Item>
                                    </Col>

                                    <Col span={6}>
                                        អាសយដ្ឋានក្រុមហ៊ុន / Address
                                    </Col>
                                    <Col span={18}>
                                        <Form.Item
                                            name={[
                                                "formData",
                                                "casino",
                                                "address",
                                            ]}
                                        >
                                            <Input
                                                placeholder="អាសយដ្ឋនាល្បែងផ្សងសំណាង / Lottery Address"
                                                status="warning"
                                                readOnly
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </>
                        ),
                    },
                    {
                        label: `ព័ត៌មានអ្នកដាក់ពាក្យស្នើសុំ`,
                        key: "3",
                        children: (
                            <>
                                <Row>
                                    <Col span={6}>
                                        តួនាទីអ្នកដាកពាក្យស្នើសុំ
                                        <br />
                                        Position of Applicant
                                    </Col>
                                    <Col span={18}>
                                        <Form.Item
                                            name={[
                                                "formData",
                                                "designatureId",
                                            ]}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col span={6}>
                                        គោត្តនាម និងនាម
                                        <br />
                                        Last name and First name
                                    </Col>
                                    <Col span={18}>
                                        <Row>
                                            <Col span={6}>
                                                អក្សរខ្មែរ
                                                <br />
                                                in Khmer
                                            </Col>
                                            <Col span={18}>
                                                <Form.Item
                                                    name={[
                                                        "formData",
                                                        "applicant",
                                                        "fullname",
                                                    ]}
                                                    rules={[
                                                        { required: true },
                                                    ]}
                                                >
                                                    <Input />
                                                </Form.Item>
                                            </Col>
                                            <Col span={6}>
                                                អក្សរឡាតាំង
                                                <br />
                                                in Latin
                                            </Col>
                                            <Col span={18}>
                                                <Form.Item
                                                    name={[
                                                        "formData",
                                                        "applicant",
                                                        "latinFullname",
                                                    ]}
                                                    rules={[
                                                        { required: true },
                                                    ]}
                                                >
                                                    <Input />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={6}>
                                        ភេទ
                                        <br />
                                        Sex
                                    </Col>
                                    <Col span={18}>
                                        <Form.Item
                                            name={[
                                                "formData",
                                                "applicant",
                                                "gender",
                                            ]}
                                            rules={[{ required: true }]}
                                        >
                                            <Radio.Group>
                                                <Space direction="horizontal">
                                                    <Radio value={1}>
                                                        ប្រុស / Male
                                                    </Radio>
                                                    <Radio value={2}>
                                                        ស្រី / Female
                                                    </Radio>
                                                </Space>
                                            </Radio.Group>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={6}>
                                        ថ្ងៃខែឆ្នាំកំណើត
                                        <br />
                                        Date of Birth
                                    </Col>
                                    <Col span={18}>
                                        <Form.Item
                                            name={[
                                                "formData",
                                                "applicant",
                                                "dateOfBirth",
                                            ]}
                                            rules={[{ required: true }]}
                                        >
                                            <DatePicker placeholder="ថ្ងៃខែឆ្នាំកំណើត" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        សញ្ជាតិ
                                        <br />
                                        Nationality
                                    </Col>
                                    <Col span={18}>
                                        <Form.Item
                                            name={[
                                                "formData",
                                                "applicant",
                                                "nationality",
                                            ]}
                                            rules={[{ required: true }]}
                                        >
                                            <Input placeholder="សញ្ជាតិ / Nationality" />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={8}>
                                        <Form.Item
                                            name={[
                                                "formData",
                                                "applicant",
                                                "identityTypeId",
                                            ]}
                                            rules={[{ required: true }]}
                                        >
                                            <Radio.Group>
                                                <Space direction="vertical">
                                                    {identificationTypes &&
                                                        identificationTypes.map(
                                                            (
                                                                identificationType: any,
                                                                index: number
                                                            ) => (
                                                                <Radio
                                                                    key={
                                                                        identificationType.value
                                                                    }
                                                                    value={
                                                                        identificationType.value
                                                                    }
                                                                >
                                                                    {
                                                                        identificationType.label
                                                                    }
                                                                </Radio>
                                                            )
                                                        )}
                                                </Space>
                                            </Radio.Group>
                                        </Form.Item>
                                    </Col>
                                    <Col span={16}>
                                        <Form.Item
                                            name={[
                                                "formData",
                                                "applicant",
                                                "identityNumber",
                                            ]}
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        "Identificaiton number is required",
                                                },
                                            ]}
                                        >
                                            <Input placeholder="សូមបំពេញ" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        ថ្ងៃខែឆ្នាំផ្តល់ / Issued Date
                                    </Col>
                                    <Col span={18}>
                                        <Form.Item
                                            name={[
                                                "formData",
                                                "applicant",
                                                "identityIssuedDate",
                                            ]}
                                            rules={[{ required: true }]}
                                        >
                                            <DatePicker />
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        ថ្ងៃខែឆ្នាំផុតកំណត / Date of Expiry
                                    </Col>
                                    <Col span={18}>
                                        <Form.Item
                                            name={[
                                                "formData",
                                                "applicant",
                                                "expiredDate",
                                            ]}
                                            rules={[{ required: true }]}
                                        >
                                            <DatePicker />
                                        </Form.Item>
                                    </Col>

                                    <Col span={6}>អាសយដ្ឋាន / Address</Col>
                                    <Col span={18}>
                                        <Form.Item
                                            name={[
                                                "formData",
                                                "applicant",
                                                "address",
                                            ]}
                                            rules={[{ required: true }]}
                                        >
                                            <Input placeholder="អាសយដ្ឋាន / Address" />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={6}>
                                        ទូរស័ព្ទចល័ត / Mobile Phone Number
                                    </Col>
                                    <Col span={18}>
                                        <Form.Item
                                            name={[
                                                "formData",
                                                "applicant",
                                                "mobilePhoneNumber",
                                            ]}
                                            rules={[{ required: true }]}
                                        >
                                            <Input placeholder="ទូរស័ព្ទចល័ត / Mobile Phone Number" />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={6}>
                                        សារអេឡិចត្រូនិច / Email Address
                                    </Col>
                                    <Col span={18}>
                                        <Form.Item
                                            name={[
                                                "formData",
                                                "applicant",
                                                "emailAddress",
                                            ]}
                                            rules={[{ required: true }]}
                                        >
                                            <Input placeholder="សារអេឡិចត្រូនិច / Email Address" />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </>
                        ),
                    },
                    {
                        label: `ព័ត៌មានអំពី ប្រភេទល្បែងផ្សងសំណាង`,
                        key: "4",
                        children: (
                            <div>
                                <Form.Item
                                    shouldUpdate={(preValues, curValues) =>
                                        preValues.gameTypes !==
                                        curValues.gameTypes
                                    }
                                >
                                    {({ getFieldValue }: any) => {
                                        const gameTypes: GameTypeProps[] =
                                            getFieldValue("gameTypes") ||
                                            [];

                                        return (
                                            <Table
                                                bordered
                                                rowKey="id"
                                                className="w-full"
                                                pagination={{
                                                    position: [],
                                                }}
                                                dataSource={gameTypes}
                                                columns={lotteryGameTypeColumn}
                                                size="small"
                                            />
                                        );
                                    }}
                                </Form.Item>
                                <Form.Item>
                                    <Button onClick={() => setGameTypeVisible(true)}>បន្ថែមថ្មី</Button>
                                </Form.Item>
                            </div>
                        ),
                    },
                    {
                        label: `ព័ត៌មានអំពី ឧបករណ៍ល្បែងផ្សងសំណាង`,
                        key: "5",
                        children: (
                            <div>
                                <Form.Item
                                    shouldUpdate={(preValues, curValues) =>
                                        preValues.gameEquipments !==
                                        curValues.gameEquipments
                                    }
                                >
                                    {({ getFieldValue }: any) => {
                                        const gameEquipments: any[] =
                                            getFieldValue("gameEquipments") ||
                                            [];

                                        return (
                                            <Table
                                                bordered
                                                rowKey="id"
                                                className="w-full"
                                                pagination={{
                                                    position: [],
                                                }}
                                                columns={lotteryGameEquipmentColumn}
                                                dataSource={gameEquipments}
                                                size="small"
                                            />
                                        );
                                    }}
                                </Form.Item>
                                <Form.Item>
                                    <Button onClick={() => setGameEquipmentVisible(true)}>បន្ថែមថ្មី</Button>
                                </Form.Item>

                            </div>
                        ),
                    },
                    {
                        label: `សំណុំឯកសារភ្ជាប់`,
                        key: "6",
                        children: (
                            <Row>
                                <Col span={24}>
                                    <Form.Item
                                        name={[
                                            "formData",
                                            "authorizationLetter",
                                        ]}
                                        valuePropName="checked"
                                    >
                                        <Checkbox>
                                            លិខិតប្រគល់សិទ្ធិ
                                            ចំពោះបុគ្គលដាក់ពាក្យស្នើសុំជាតំណាងស្របច្បាប់
                                            / Authorization letter for
                                            representative
                                        </Checkbox>
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item
                                        name={[
                                            "formData",
                                            "specificationList",
                                        ]}
                                        valuePropName="checked"
                                    >
                                        <Checkbox>
                                            លិខិតប្រគល់សិទ្ធិ
                                            ចំពោះបុគ្គលដាក់ពាក្យស្នើសុំជាតំណាងស្របច្បាប់
                                            / Specification list of each
                                            game rule
                                        </Checkbox>
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item
                                        name={[
                                            "formData",
                                            "receiptOfApplication",
                                        ]}
                                        valuePropName="checked"
                                    >
                                        <Checkbox>
                                            លិខិតប្រគល់សិទ្ធិ
                                            ចំពោះបុគ្គលដាក់ពាក្យស្នើសុំជាតំណាងស្របច្បាប់
                                            / Receipt of application fee
                                            payment
                                        </Checkbox>
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item
                                        name={[
                                            "formData",
                                            "receiptOfInvestigationFee",
                                        ]}
                                        valuePropName="checked"
                                    >
                                        <Checkbox>
                                            លិខិតប្រគល់សិទ្ធិ
                                            ចំពោះបុគ្គលដាក់ពាក្យស្នើសុំជាតំណាងស្របច្បាប់
                                            / Receipt of C.G.M.C
                                            investigation fee
                                        </Checkbox>
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item
                                        name={[
                                            "formData",
                                            "representative",
                                            "registrationFeeReceipt",
                                        ]}
                                        valuePropName="checked"
                                    >
                                        <Checkbox>
                                            លិខិតប្រគល់សិទ្ធិ
                                            ចំពោះបុគ្គលដាក់ពាក្យស្នើសុំជាតំណាងស្របច្បាប់
                                            / Registration fee Receipt
                                        </Checkbox>
                                    </Form.Item>
                                </Col>
                            </Row>
                        ),
                    },
                ]}
            />
        </Form>
        <Modal open={gameTypeVisible} onCancel={() => setGameTypeVisible(false)} onOk={() => { gameTypeForm.submit(); setGameTypeVisible(false); }}>
            <LotteryGameTypeForm form={gameTypeForm} />
        </Modal>
        <Modal open={gameEquipmentVisible} onCancel={() => setGameEquipmentVisible(false)} onOk={() => { gameEquipmentForm.submit(); setGameEquipmentVisible(false); }}>
            <LotteryGameEquipmentForm form={gameEquipmentForm} />
        </Modal>
    </>
    )
}