import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { v4 as uuid } from "uuid";
import { useInjectReducer } from "Utility/InjectReducer";
import { useInjectSaga } from "Utility/InjectSaga";
import dayjs from "dayjs";

import { FullLayout } from "Components/Layouts"
import { ArchiveIcon } from "@heroicons/react/outline"
import { Form, Space, Button, } from "antd"
import { HelmetHeader } from "Components/Helmet-Header"
import { CreateCasinoEquipmentMasterForm } from "Modules/Equipments/Casino/Forms/CreateCasinoEquipmentMasterForm"

import EquipmentCasinoReducer from "Modules/Equipments/Casino/Reducer";
import EquipmentCasinoSaga from "Modules/Equipments/Casino/Saga";
import GameEquipmentApplicationFormReducer from "Modules/Certificate/Casino/ApplicationForm/GameEquipment/Reducer";
import GameEquipmentApplicationFormSaga from "Modules/Certificate/Casino/ApplicationForm/GameEquipment/Saga";
import {
    SetCasinoEquipmentMasterIdAction,
    GetRequestCasinoEquipmentMasterRequestAction,
    GetRequestCasinoEquipmentMasterSuccessAction
} from "Modules/Equipments/Casino/Actions";
import { DATE_FORMAT, DATE_TIME_FORMAT } from "Modules/Application/Constants";
import {
    CreateCasinoEquipmentMasterRequestAction,
    SetCasinoEquipmentMasterValueAction
} from "Modules/Equipments/Casino/Actions";
import { MakeCasinoEquipmentMasterSelector } from "Modules/Equipments/Casino/Selectors";

const gameEquipmentApplicationFormKey = "certificateGameEquipment";
const key = "equipmentCasino";;
export const CasinoEquipmentMasterDetail = () => {
    useInjectReducer({ key, reducer: EquipmentCasinoReducer });
    useInjectSaga({ key, saga: EquipmentCasinoSaga });
    useInjectReducer({ key: gameEquipmentApplicationFormKey, reducer: GameEquipmentApplicationFormReducer });
    useInjectSaga({ key: gameEquipmentApplicationFormKey, saga: GameEquipmentApplicationFormSaga });
    // fields
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const { id } = useParams();
    const gameEquipmentApplicationForm = useSelector(MakeCasinoEquipmentMasterSelector());

    // constructor 
    useEffect(() => {
        if (id) {
            dispatch(SetCasinoEquipmentMasterIdAction(id));
            dispatch(GetRequestCasinoEquipmentMasterRequestAction());

        }

        return () => {
            dispatch(SetCasinoEquipmentMasterIdAction(null));
            dispatch(GetRequestCasinoEquipmentMasterSuccessAction(null));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])

    useEffect(() => {

        if (gameEquipmentApplicationForm) {
            // const model = {
            //     casinoId: gameEquipmentApplicationForm.casinoId,
            //     casino: `${gameEquipmentApplicationForm.requestLicense.code} (${gameEquipmentApplicationForm.requestLicense.casinoNameLatin})`,
            //     requestLicenseId: gameEquipmentApplicationForm.requestLicenseId,
            //     code: gameEquipmentApplicationForm.code,
            //     applicationDate: dayjs(gameEquipmentApplicationForm.requestLicense.receivedDate),
            //     documentCode: gameEquipmentApplicationForm.requestLicense.documentCode,
            //     receiverName: gameEquipmentApplicationForm.requestLicense.receiverName,
            //     gamblingTaxCode: gameEquipmentApplicationForm.requestLicense.gamblingTaxCode,
            //     receivedDate: dayjs(gameEquipmentApplicationForm.requestLicense.receivedDate),
            //     registeredDate: dayjs(gameEquipmentApplicationForm.registeredDate),
            //     equipments: gameEquipmentApplicationForm.casinoEquipmentDetails
            // }

            const model = { equipments: [...gameEquipmentApplicationForm] };

            form.setFieldsValue(model);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [gameEquipmentApplicationForm])

    // events
    const onFormProviderFinish = (name: string, { values, forms }: any) => {
        switch (name) {
            case "tableEquipmentForm":
                onAddEquipment(forms, values);
                const { tableEquipmentForm } = forms;
                tableEquipmentForm.resetFields();
                break;
            case "machineEquipmentForm":
                onAddEquipment(forms, values);
                break;
            case "createCasinoEquipmentMasterForm":
                onSubmitEquipment(forms, values);
                break;
            default: break;
        }
    }

    const onAddEquipment = (forms: any, values: any) => {
        const { createCasinoEquipmentMasterForm } = forms;
        const equipments = createCasinoEquipmentMasterForm.getFieldValue("equipments") || [];
        const model = {
            id: uuid(),
            ...values,
        };
        model.zoneName = "Test";
        model.gameRule = values.gameRule.label;
        model.gameRuleId = values.gameRule.value;
        model.equipmentType = values.equipmentType.label;
        model.equipmentTypeId = values.equipmentType.value;
        createCasinoEquipmentMasterForm.setFieldValue("equipments", [model, ...equipments]);
    }

    const onSubmitEquipment = (forms: any, values: any) => {

        const model = {
            ...values,
        }
        model.applicationDate = values.applicationDate;
        model.registeredDate = (values.registeredDate);
        model.casinoEquipmentDetails = form.getFieldValue("equipments") || [];


        dispatch(SetCasinoEquipmentMasterValueAction(model));
        dispatch(CreateCasinoEquipmentMasterRequestAction());
    }

    // render
    const ActionButton = () => {
        return (
            <Space>
                <Button onClick={() => form.submit()}>
                    រក្សាទុក
                </Button>
            </Space>
        )
    }
    return (
        <FullLayout
            icon={<ArchiveIcon />}
            title="បង្កើតបញ្ជីឧបករណ៍/កម្មវិធីល្បែង"
            descriptions=""
            redirectUrl=""
            actions={<ActionButton />}
        >
            <HelmetHeader meta="ស្នើសុំចុះបញ្ជី ឧបករណ៍/កម្មវិធីល្បែង" />
            <Form.Provider onFormFinish={onFormProviderFinish}>
                <CreateCasinoEquipmentMasterForm form={form} />
            </Form.Provider>
        </FullLayout>
    )
}