import { SELECT_CASINO_STATUS_SUCCESS } from "Modules/Settings/CasinoStatus/Constants";
import produce from "immer";

export const initialState: any = {
    selectCasinoStatus: [],
    filterFormValues: null,
};

const CasinoStatusReducer = produce((draft, action) => {
    switch (action.type) {
        case SELECT_CASINO_STATUS_SUCCESS:
            draft.selectCasinoStatus = action.casinoStatuses;
            break;

        default:
            break;
    }
}, initialState);

export default CasinoStatusReducer;
