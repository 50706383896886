import axios from "axios";

export const refreshTokenService = async (token: string) => {
    const refreshClient = axios.create({
        baseURL: process.env.REACT_APP_API_CORE_URI,
        withCredentials: true,
    });
    let config = {
        headers: {
            Authorization: ` Bearer ${token}`,
        },
        withCredential: true,
    };
    const newToken = await refreshClient.post(
        "/api/auth/refresh",
        {
            accessToken: token,
        },
        config
    );
    if (newToken.status === 200) {
        localStorage.setItem(
            "cgmcToken",
            newToken.data.accessToken
        );
        return newToken;
        
    }
    return null;
};
