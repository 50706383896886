import produce from "immer";
import {
    EntityCasinoListConstant,
    GET_ENTITY_CASINO_BY_ID_SUCCESS,
    GET_CASINO_STATUS_COUNT_SUCCESS,
    GET_LOTTERY_STATUS_COUNT_SUCCESS,
    CREATE_CASINO_MODAL_SUCCESS,
    SET_CASINO_ID,
    REPORT_OPERATOR_BY_AREACODE_SUCCESS,
} from "Modules/Entity/Operator/Constants";

export const initialState = {
    casinos: [],
    casinoOptions: [],
    errors: null,
    formValues: null,
    casino: null,
    casinoDetailModal: null,
    casinoStatusCount: [],
    lotteryStatusCount: [],
    operatorData: [],
    id: null,
};

const EntityCasinoListReducer = produce((draft, action) => {
    switch (action.type) {
        case EntityCasinoListConstant.SET_FORM_VALUES:
            draft.formValues = action.formValues;
            break;
        case EntityCasinoListConstant.SELECT_CASINO_SUCCESS:
            draft.casinoOptions = action.casinoOptions;
            break;
        case EntityCasinoListConstant.ENTITY_CASINO_LIST_SUCCESS:
            draft.casinos = action.casinos;
            break;
        case EntityCasinoListConstant.QUERY_CASINO_WITH_COMPANY_SUCCESS:
            draft.casinos = action.casinos;
            break;
        case GET_ENTITY_CASINO_BY_ID_SUCCESS:
            draft.casino = action.casino;
            break;
        case CREATE_CASINO_MODAL_SUCCESS:
            draft.casinoDetailModal = action.casinoDetailModal;
            break;
        case GET_CASINO_STATUS_COUNT_SUCCESS:
            draft.casinoStatusCount = action.casinoStatusCount;
            break;
        case GET_LOTTERY_STATUS_COUNT_SUCCESS:
            draft.lotteryStatusCount = action.lotteryStatusCount;
            break;
        case SET_CASINO_ID:
            draft.id = action.id;
            break;
        case REPORT_OPERATOR_BY_AREACODE_SUCCESS:
            draft.operatorData = action.operators;
            break;
    }
}, initialState);

export default EntityCasinoListReducer;
