import React from "react";
import { Form, Row, Col, Input, Modal } from "antd";
import { ModalFormType } from "Common/Constants/ModalFormType";
import { useResetFormOnCloseModal } from "Utility/ResetFormOnCloseModal";

export const BoardOfDirectorModal: React.FC<ModalFormType> = ({
    visible,
    onCancel,
}) => {
    const [form] = Form.useForm();
    useResetFormOnCloseModal({
        form,
        visible,
    });

    const onSubmit = () => {
        form.submit();
    };

    return (
        <Modal
            title="ក្រុមប្រឹក្សាភិបាល"
            open={visible}
            onOk={onSubmit}
            onCancel={onCancel}
            okText="យល់ព្រម"
            cancelText="បោះបង់"
            okButtonProps={{ type: "default" }}
        >
            <Form form={form} layout="vertical" name="boardOfDirectorForm">
                <Row>
                    <Col span={8}>គោត្តនាម និងនាម</Col>
                    <Col span={16}>
                        <Form.Item name="name">
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={8}>តួនាទី</Col>
                    <Col span={16}>
                        <Form.Item name="position">
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={8}>សញ្ជាតិ</Col>
                    <Col span={16}>
                        <Form.Item name="nationality">
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={8}>អាសយដ្ឋាន</Col>
                    <Col span={16}>
                        <Form.Item name="address">
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};
