import {
    EntityCompanyListConstant,
    GET_ENTITY_COMPANY_BY_ID_REQUEST,
    GET_ENTITY_COMPANY_BY_ID_SUCCESS,
    CREATE_COMPANY_MODAL_REQUEST,
    CREATE_COMPANY_MODAL_SUCCESS,
    EDIT_ENTITY_COMPANY_REQUEST,
} from "Modules/Entity/Company/Constants";

export const SetFormValuesAction = (formValues: any) => {
    return {
        type: EntityCompanyListConstant.SET_FORM_VALUES,
        formValues,
    };
};

export const SetCompanyValueAction = (values: any) => {
    return {
        type: EntityCompanyListConstant.SET_COMPANY_VALUES,
        values,
    };
};

export const EntityCompanyListRequestAction = () => {
    return {
        type: EntityCompanyListConstant.ENTITY_COMPANY_LIST_REQUEST,
    };
};

export const EntityCompanyListSuccessAction = (companies: any) => {
    return {
        type: EntityCompanyListConstant.ENTITY_COMPANY_LIST_SUCCESS,
        companies,
    };
};

export const CreateEntityCompanyRequestAction = () => {
    return {
        type: EntityCompanyListConstant.CREATE_ENTITY_COMPANY_REQUEST,
    };
};

export const SelectCompanyRequestAction = () => {
    return {
        type: EntityCompanyListConstant.SELECT_COMPANY_REQUEST,
    };
};

export const SelectCompanySuccessAction = (companyOptions: any) => {
    return {
        type: EntityCompanyListConstant.SELECT_COMPANY_SUCCESS,
        companyOptions,
    };
};

export const GetEntityCompanyByIdRequestAction = () => {
    return {
        type: GET_ENTITY_COMPANY_BY_ID_REQUEST,
    };
};

export const GetEntityCompanyByIdSuccessAction = (company: any) => {
    return {
        type: GET_ENTITY_COMPANY_BY_ID_SUCCESS,
        company,
    };
};

export const CreateCompanyModalRequestAction = () => {
    return {
        type: CREATE_COMPANY_MODAL_REQUEST,
    };
};

export const CreateCompanyModalSuccessAction = (companyDetailModal: any) => {
    return {
        type: CREATE_COMPANY_MODAL_SUCCESS,
        companyDetailModal,
    };
};

export const EditEntityCompanyRequestAction = () => {
    return {
        type: EDIT_ENTITY_COMPANY_REQUEST,
    };
};
