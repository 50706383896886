import React from "react";
import { CompanyList } from "Modules/Entity/Company/List";
import { CompanyCreate } from "Modules/Entity/Company/Create";
import { CompanyDetail } from "Modules/Entity/Company/Detail";

export const EntityCompanyRoute = {
    path: "company",
    children: [
        { path: "", element: <CompanyList /> },
        { path: "create", element: <CompanyCreate /> },
        { path: ":id", element: <CompanyDetail /> },
    ],
};
