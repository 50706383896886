import { useRef, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useInjectReducer } from "Utility/InjectReducer";
import { useInjectSaga } from "Utility/InjectSaga";

import { ArchiveIcon } from "@heroicons/react/outline";
import { FullLayout } from "Components/Layouts";
import { Button } from "antd";
import ReactToPrint from "react-to-print";
import { OperatorFormat } from "./PrintFormat/OperatorFormat";

import CasinoReducer from "Modules/Entity/Operator/Reducer";
import CasinoSaga from "Modules/Entity/Operator/Saga";
import { OperatorByAreaCodeFilterForm } from "./Forms/OperatorByAreaCodeFilterForm";
import { ReportOperatorByAreaRequestAction, ReportOperatorByAreaSuccessAction } from "Modules/Entity/Operator/Actions";
import { MakeOperatorDataSelector } from "Modules/Entity/Operator/Selectors";

const key="entityCasino";
export const OepratorReport = () => {
    useInjectReducer({key, reducer: CasinoReducer});
    useInjectSaga({key, saga: CasinoSaga})
    const dispatch = useDispatch();
    // fields
    const operatorData = useSelector(MakeOperatorDataSelector());
    console.log("data", operatorData)
    const printRef = useRef<any>(null);

    // constructor 
    useEffect(() => {
        return () => {
            dispatch(ReportOperatorByAreaSuccessAction([]));
        }
    }, []);

    const printTrigger = useCallback(() => {
        return (
            <Button type="default">Print</Button>
        )
    }, []);
    const printContent = useCallback(() => {
        return printRef.current;
    }, [printRef.current]);

    // events
    const onFilter = (values: any) => {
        dispatch(ReportOperatorByAreaRequestAction());
    }

    // render
    
    return (
        <FullLayout
                icon={<ArchiveIcon />}
                title="របាយការណ៍"
                descriptions=""
                actions={<><ReactToPrint 
                    trigger={printTrigger}
                    content={printContent}
                /></>}
            >
            
            <OperatorByAreaCodeFilterForm onFilter={onFilter}/>
            <div className="p-4">
                {/* <OperatorFormat ref={printRef} {...operatorData}/> */}
                <OperatorFormat ref={printRef} operatorData={operatorData}/>
            </div>
        </FullLayout>
    )
}