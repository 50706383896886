import { call, put, all, select, takeLatest } from "redux-saga/effects";

import Request from "Utility/Http";
import ApiEndpoint from "Utility/Endpoint";
import { AsyncStartAction, AsyncEndAction } from "Modules/Application/Actions";
import {
    GameTypeConstants,
    GET_GAME_TYPE_REQUEST,
    UPDATE_GAME_TYPE_REQUEST,
    SELECT_GAME_TYPE_REQUEST
} from "./Constants";
import { POST, GET, PUT } from "Utility/Constant";
import { ShowAlert, ShowMessage } from "Common/Saga/";
import {
    makeFormValuesSelector,
    MakeGameTypeIdSelector,
} from "Modules/Settings/GameType/Selectors";
import { ClearSnackMessageAction } from "Components/SnackMessage/Actions";
import {
    QueryGameTypeSuccessActions,
    GetGameTypeSuccessAction,
    SelectGameTypeSuccessAction,
} from "Modules/Settings/GameType/Actions";
import { v4 as uuid } from "uuid";

export function* CreateGameTypeList(): any {
    yield put(AsyncStartAction());
    const formValues = yield select(makeFormValuesSelector());
    const requestUrl = `${ApiEndpoint.GAME_TYPE_CREATE_PATH}`;
    const httpPayload = ApiEndpoint.makeApiPayload(
        requestUrl,
        POST,
        formValues,
        null,
        false,
        true
    );
    try {
        const response = yield call(Request, httpPayload);
        yield put(AsyncEndAction());
        if (response && response?.id) {
            yield ShowMessage({
                type: "success",
                message: "ទិន្នយបានរក្សាទុក",
                content: "ទិន្នយបានរក្សាទុក",
                id: uuid(),
                redirectUrl: `/settings/game-type?entityType=${formValues.entityTypeString}`,
            });
        }
    } catch (error: any) {
        yield put(AsyncEndAction());
        if (error.data && error.data.statusCode === 422) {
        }
        return yield ShowAlert("error", error.data.message);
    }
}

export function* UpdateGameType(): any {
    yield put(AsyncStartAction());
    const formValues = yield select(makeFormValuesSelector());
    const requestUrl = `${ApiEndpoint.GAME_TYPE_CREATE_PATH}`;
    const httpPayload = ApiEndpoint.makeApiPayload(
        requestUrl,
        PUT,
        formValues,
        null,
        false,
        true
    );
    try {
        const response = yield call(Request, httpPayload);
        yield put(AsyncEndAction());
        if (response && response?.id) {
            yield ShowMessage({
                type: "success",
                message: "ទិន្នយបានរក្សាទុក",
                content: "ទិន្នយបានរក្សាទុក",
                id: uuid(),
                redirectUrl: `/settings/game-type?entityType=${formValues.entityTypeString}`,
            });
        }
    } catch (error: any) {
        yield put(AsyncEndAction());
        if (error.data && error.data.statusCode === 422) {
        }
        return yield ShowAlert("error", error.data.message);
    }
}

export function* GetGameTypeList(): any {
    yield put(AsyncStartAction());
    const formValues = yield select(makeFormValuesSelector());
    const requestUrl = `${ApiEndpoint.GAME_TYPE_LIST_PATH}`;
    const httpPayload = ApiEndpoint.makeApiPayload(
        requestUrl,
        POST,
        formValues
    );
    try {
        const response = yield call(Request, httpPayload);
        yield put(AsyncEndAction());
        yield put(ClearSnackMessageAction());
        yield put(QueryGameTypeSuccessActions(response));
    } catch (error: any) {
        yield put(AsyncEndAction());
        if (error.data && error.data.statusCode === 422) {
        }
        return yield ShowAlert("error", error.data);
    }
}

export function* GetGameTypeDetail(): any {
    yield put(AsyncStartAction());
    const id = yield select(MakeGameTypeIdSelector());
    const requestUrl = `${ApiEndpoint.GAME_TYPE_DETAIL_PATH}/${id}`;
    const httpPayload = ApiEndpoint.makeApiPayload(requestUrl, GET);
    try {
        const response = yield call(Request, httpPayload);
        yield put(AsyncEndAction());
        yield put(ClearSnackMessageAction());
        yield put(GetGameTypeSuccessAction(response));
    } catch (error: any) {
        yield put(AsyncEndAction());
        if (error.data && error.data.statusCode === 422) {
        }
        return yield ShowAlert("error", error.data);
    }
}

export function* SelectGameType() {
    yield put(AsyncStartAction());
    const formValues = yield select(makeFormValuesSelector());
    const requestUrl = `${ApiEndpoint.GAME_TYPE_PATH}?entityType=${formValues.entityTypeId}`;

    const httpPayload = ApiEndpoint.makeApiPayload(
        requestUrl,
        GET,
        formValues
    );
    try {
        const response = yield call(Request, httpPayload);
        yield put(SelectGameTypeSuccessAction(response));
    } catch (error: any) {
        if (error.data && error.data.statusCode === 422) {
        }
        return yield ShowAlert("error", error.data);
    }
    finally {
        yield put(AsyncEndAction());
        yield put(ClearSnackMessageAction());
    }
}



export default function* EntityCasinoSaga() {
    yield all([
        yield takeLatest(
            GameTypeConstants.CREATE_GAME_TYPE_REQUEST,
            CreateGameTypeList
        ),
        yield takeLatest(
            GameTypeConstants.QUERY_GAME_TYPE_REQUEST,
            GetGameTypeList
        ),
        yield takeLatest(GET_GAME_TYPE_REQUEST, GetGameTypeDetail),
        yield takeLatest(UPDATE_GAME_TYPE_REQUEST, UpdateGameType),
        yield takeLatest(SELECT_GAME_TYPE_REQUEST, SelectGameType)
    ]);
}
