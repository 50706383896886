export const SET_REQUEST_LOTTERY_GAME_TYPE_FILTER_VALUE = "SET_REQUEST_LOTTERY_GAME_TYPE_FILTER_VALUE";
export const SET_REQUEST_LOTTERY_GAME_TYPE_VALUE = "SET_REQUEST_LOTTERY_GAME_TYPE_VALUE";
export const SET_REQUEST_LOTTERY_GAME_TYPE_ID = "SET_REQUEST_LOTTERY_GAME_TYPE_ID";

export const GET_REQUEST_LOTTERY_GAME_TYPE_REQUEST = "GET_REQUEST_LOTTERY_GAME_TYPE_REQUEST";
export const GET_REQUEST_LOTTERY_GAME_TYPE_SUCCESS = "GET_REQUEST_LOTTERY_GAME_TYPE_SUCCESS";
export const LIST_REQUEST_LOTTERY_GAME_TYPE_REQUEST = "LIST_REQUEST_LOTTERY_GAME_TYPE_REQUEST";
export const LIST_REQUEST_LOTTERY_GAME_TYPE_SUCCESS = "LIST_REQUEST_LOTTERY_GAME_TYPE_SUCCESS";

export const CREATE_REQUEST_LOTTERY_GAME_TYPE_REQUEST = "CREATE_REQUEST_LOTTERY_GAME_TYPE_REQUEST";
export const EDIT_REQUEST_LOTTERY_GAME_TYPE_REQUEST = "EDIT_REQUEST_LOTTERY_GAME_TYPE_REQUEST";
export const DELETE_REQUEST_LOTTERY_GAME_TYPE_REQUEST = "DELETE_REQUEST_LOTTERY_GAME_TYPE_REQUESTs";
