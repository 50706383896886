
import {
    Form,
    Input
} from "antd";

export const LotteryGameEquipmentForm = ({ form }: any) => {
    // fields

    return (
        <Form name="lotteryGameEquipmentForm" layout="vertical" form={form}>
            <Form.Item name="gameEquipment" label="ឧបករណ៍ល្បែង / Game Equipment">
                <Input />
            </Form.Item>
            <Form.Item name="other" label="ផ្សេងៗ/Other">
                <Input.TextArea />
            </Form.Item>
        </Form>
    )
}