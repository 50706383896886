import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useInjectReducer } from "Utility/InjectReducer";
import { useInjectSaga } from "Utility/InjectSaga";

import { ArchiveIcon } from "@heroicons/react/solid";
import {
    Button,
    Space,
} from "antd";
import QrReader from "react-qr-reader";
import { HelmetHeader } from "Components/Helmet-Header";
import { FullLayout } from "Components/Layouts";

import EquipmentBindingReducer from "Modules/Equipments/Casino/Binding/Reducer";
import EquipmentBindingSaga from "Modules/Equipments/Casino/Binding/Saga";

import { useParams } from "react-router-dom";
import { SetEquipmentBindingVerifyValueAction, VerifyEquipmentBindingRequestAction } from "../Actions";
import { MakeEquipmentBindingSelector, MakeEquipmentBindingStatusSelector } from "../Selectors";

const key = "equipmentBinding";
export const EquipmentBindingVerify = () => {
    useInjectReducer({ key, reducer: EquipmentBindingReducer });
    useInjectSaga({ key, saga: EquipmentBindingSaga });
    //fields
    const dispatch = useDispatch();
    const { id } = useParams();
    const [delayScan, setDelayScan] = useState<any>(500);
    const [qrScannerVisible, setQrScannerVisible] = useState<boolean>(false);
    const equipmentBinding = useSelector(MakeEquipmentBindingSelector());
    const verifyEquipmentStatus = useSelector(MakeEquipmentBindingStatusSelector());

    // constructor
    useEffect(() => {
        switch (verifyEquipmentStatus) {
            case 1:
                break;
            default: break;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [verifyEquipmentStatus]);

    useEffect(() => {
        console.log("Test");
        setDelayScan(false);
        setQrScannerVisible(false);
        dispatch(SetEquipmentBindingVerifyValueAction('c14df3df-17f7-44db-9047-b76f64878e2e'));
        dispatch(VerifyEquipmentBindingRequestAction());

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // events
    const onQRScanError = (error: any) => {

    }

    const onQRScanComplete = (data: any) => {
        if (data && data.text) {

            setDelayScan(false);
            setQrScannerVisible(false);
            const qrString = data.text.split("/").pop();
            dispatch(SetEquipmentBindingVerifyValueAction(qrString));
            dispatch(VerifyEquipmentBindingRequestAction());
        }
    }

    // render
    const ActionButton = () => {
        return (
            <Space>
                <Button type="primary" onClick={() => setQrScannerVisible(true)}>
                    ស្កេនd
                </Button>
                {/* <Button type="primary" onClick={() => setQrScannerVisible(true)}>
                    ប្តូរកាម៉េរា
                </Button> */}
            </Space>
        )
    }
    return (
        <FullLayout
            icon={<ArchiveIcon />}
            title="ស្កេនលតាបត្រ"
            descriptions=""
            redirectUrl=""
            actions={<ActionButton />}
        >
            <HelmetHeader meta="ស្នើសុំចុះបញ្ជី ឧបករណ៍/កម្មវិធីល្បែង" />
            <div className="flex flex-col items-center p-5">
                {qrScannerVisible && < QrReader
                    // constraints={
                    //     { facingMode: cameraView }
                    // }
                    facingMode={"environment"}
                    delay={1000}
                    onError={onQRScanError}
                    onScan={onQRScanComplete}
                    // chooseDeviceId={()=>selected}
                    style={{ width: "300px" }}
                />}
            </div>
        </FullLayout>
    )
}