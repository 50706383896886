import { Image } from "antd";
export const EquipmentPictureForm = ({picture}: any) => {
    console.log("Pictu", picture.uri);
    return (
        <div className="p-1 border border-green-800">
            <Image
                width={200}
                className="shadow-lg"
                src={picture.uri}
                alt={picture.name}
            />
        </div>
    )
}