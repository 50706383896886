import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GTINGenerator, GetGtinRunningNumber } from "Utility/StringHelper";
import { v4 as uuid } from "uuid";

import {
  DatePicker,
    Form,
    Input,
    InputNumber,
    Select
} from "antd";
import {
    MakeInspectionCertificateTypeOptionsSelector
} from "Modules/Settings/InspectionCertificateType/Selectors";
import {
    GetGtinNumberSuccessAction
    , SetInspectionCertificateMasterStatusAction,
    SetLocalGtinLatestValueAction
} from "Modules/Settings/InspectionCertificateMaster/Actions";
import {
    MakeInspectionCertificateMasterStatusSelector,
    MakeGtinSelector,
    MakeGtinLocalSelector
} from "Modules/Settings/InspectionCertificateMaster/Selectors";

export const InspectionCertificateDetailForm = ({ form }: any) => {
    // fields
    const dispatch = useDispatch();
    const inspectionCertificateTypes = useSelector(MakeInspectionCertificateTypeOptionsSelector());
    const status = useSelector(MakeInspectionCertificateMasterStatusSelector());
    const gtinLatest = useSelector(MakeGtinLocalSelector());
    const gtin = useSelector(MakeGtinSelector());

    //constructor
    useEffect(() => {
        form.setFieldValue("versionGuid", uuid());
        if (status === 4) {
            const localGtin = parseInt(gtinLatest ?? "1");
            const remoteGtin = parseInt(gtin ?? "1");
            if (localGtin > remoteGtin) {
                const ginNumber = parseInt(GetGtinRunningNumber(gtinLatest)) + 1;
                form.setFieldValue("serialNumber", GTINGenerator(ginNumber));
            }
            else {
                const ginNumber = parseInt(GetGtinRunningNumber(gtin)) + 1;
                form.setFieldValue("serialNumber", GTINGenerator(ginNumber));

            }

            dispatch(SetLocalGtinLatestValueAction(null));
            dispatch(GetGtinNumberSuccessAction(null));
            dispatch(SetInspectionCertificateMasterStatusAction(0));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status])

    // event
    // const onEquipmentTypeChange = (e: any) => {
    //     getGtinLatest(e.value);
    //     dispatch(SetGtinEquipmentTypeIdAction(e.value));
    //     dispatch(GetGtinNumberRequestAction());
    // }
    return (
        <Form
            name="inspectionCertificateDetailForm"
            layout="vertical"
            className="mt-10"
            form={form}
            initialValues={
                { versionGuid: uuid() }
            }>
            <Form.Item name="id" hidden>
                <Input />
            </Form.Item>
            <Form.Item name="inspectionCertificateStatus" hidden>
                <Input />
            </Form.Item>
            <Form.Item name="inspectionCertificateStatusId" hidden>
                <Input />
            </Form.Item>
            <Form.Item name="inspectionCertificateTypeId" hidden>
                <Input />
            </Form.Item>
            <Form.Item name="printDate" hidden>
                <Input />
            </Form.Item>
            <Form.Item name="inspectionCertificateType" label="Inspection Certificate Type">
                <Select showSearch labelInValue >
                    {inspectionCertificateTypes && inspectionCertificateTypes.map((item: any) => <Select.Option key={item.equipmentTypeId} value={item.id}>{item.name}</Select.Option>)}
                </Select>
            </Form.Item>
            <Form.Item name="serialNumber" label="Serial Number">
                <Input />
            </Form.Item>
            <Form.Item name="validedDate" label="Valid until">
                <DatePicker />
            </Form.Item>
            <Form.Item name="versionGuid" label="UUID">
                <Input readOnly />
            </Form.Item>
            <Form.Item name="quantity" label="Quantity">
                <InputNumber />
            </Form.Item>
        </Form>
    )
}