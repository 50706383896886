import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useInjectSaga } from "Utility/InjectSaga";
import { useInjectReducer } from "Utility/InjectReducer";
import dayjs from "dayjs";

// Component
import { Form, Button } from "antd";
import { ArchiveIcon } from "@heroicons/react/outline";
import { FullLayout } from "Components/Layouts/";
import { HelmetHeader } from "Components/Helmet-Header";
import { CapitalShareModal } from "Modules/License/Casino/Request/Modal/CapitalShareModal";
import { RequestCasinoLicenseDetailForm } from "Modules/License/Casino/ApplicationForm/License/Forms/RequestCasinoLicenseDetailForm";

import Saga from "Modules/License/Casino/Saga";
import Reducer from "Modules/License/Casino/Reducer";
import { MakeRequestCasinoLicenseSelector } from "Modules/License/Casino/Selectors";
import {
    EditRequestCasinoLicenseRequestAction,
    GetRequestCasinoLicenseRequestAction,
    GetRequestCasinoLicenseSuccessAction,
    SetRequestCasinoLicenseIdAction,
    SetRequestCasinoLicenseValueAction
} from "Modules/License/Casino/Actions";

const key = "licenseCasino";
export const RequestCasinoLicenseDetail = () => {
    //Injector
    useInjectReducer({ key, reducer: Reducer });
    useInjectSaga({ key, saga: Saga });

    // fields
    const dispatch = useDispatch();

    const { id } = useParams();
    const [form] = Form.useForm();
    const casinoModel = useSelector(MakeRequestCasinoLicenseSelector());
    const [capitalShareVisibility, setCapitalShareVisibility] = useState(false);
    const [isDifferentRepresentative, setIsDifferentRepresentative] =
        useState(false);

    // tables columns

    // constructor
    useEffect(() => {
        if (casinoModel) {

            const model = { ...casinoModel };
            model.receivedDate = casinoModel?.receivedDate ? dayjs(casinoModel?.receivedDate) : dayjs();
            model.formData.applicant.dateOfBirth = casinoModel?.formData?.applicant?.dateOfBirth ? dayjs(casinoModel?.formData?.applicant?.dateOfBirth) : dayjs();
            model.formData.applicant.identityIssuedDate = casinoModel?.formData.applicant.identityIssuedDate ? dayjs(
                casinoModel?.formData.applicant.identityIssuedDate
            ) : dayjs();

            model.formData.applicant.expiredDate = casinoModel?.formData.applicant.expiredDate ? dayjs(
                casinoModel?.formData.applicant.expiredDate
            ) : dayjs();

            if (model.formData.representative) {
                model.formData.representative.dateOfBirth = casinoModel?.formData.representative.dateOfBirth ? dayjs(
                    casinoModel?.formData?.representative?.dateOfBirth
                ) : dayjs();

                model.formData.representative.issuedDate = casinoModel?.formData.representative.issuedDate ? dayjs(
                    casinoModel?.formData.representative.issuedDate
                ) : dayjs();

                model.formData.representative.expiredDate = casinoModel?.formData.representative.expiredDate ? dayjs(
                    casinoModel?.formData.representative.expiredDate
                ) : dayjs();
            }

            model.boardOfDirectors =
                casinoModel?.formData?.boardOfDirectors || [];
            model.code = casinoModel?.formData?.casino?.id;
            setIsDifferentRepresentative(casinoModel?.formData.representativeType);
            form.setFieldsValue({ ...model });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [casinoModel]);

    useEffect(() => {
        dispatch(SetRequestCasinoLicenseIdAction(id));
        dispatch(GetRequestCasinoLicenseRequestAction());

        return () => {
            dispatch(SetRequestCasinoLicenseIdAction(null));
            dispatch(GetRequestCasinoLicenseSuccessAction(null));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    // methods
    const submitCasinoLicenseForm = (name: string, values: any) => {

        let licenseModel = {
            ...form.getFieldsValue(),
            entityName: "Casino",
            formType: "C001",
            CompanyNameInKhmer: values.formData.company.name,
            CasinoNameInKhmer: values.formData.casino.name,
            code: values.formData.casino.code,
            applicantName: values.formData.applicant.fullname,
            applicantNationality: values.formData.applicant.nationality,
            applicantContactNumber: values.formData.applicant.mobilePhoneNumber,
            representativeName: values.formData.applicant.fullname,
            representativeNationality: values.formData.applicant.nationality,
            representativeContactNumber:
                values.formData.applicant.mobilePhoneNumber,
        };
        licenseModel.receiverName = " ";
        licenseModel.trackingCode = " ";
        if (values.formData.representativeType === true) {
            licenseModel.representativeName =
                values.formData.representative.fullname;
            licenseModel.representativeNationality =
                values.formData.representative.nationality;
            licenseModel.representativeContactNumber =
                values.formData.representative.mobilePhoneNumber;
        }
        licenseModel.formData.casinoTypeId = values.formData.casinoTypeId;

        licenseModel.formData.boardOfDirectors =
            form.getFieldValue("boardOfDirectors");

        dispatch(SetRequestCasinoLicenseValueAction(licenseModel));
        dispatch(EditRequestCasinoLicenseRequestAction());
    };

    const submitCapitalShareForm = (forms: any, values: any) => {
        const { requestCasinoLicenseForm } = forms;
        const capitalShares: any[] =
            requestCasinoLicenseForm.getFieldValue("capitalShares") || [];

        requestCasinoLicenseForm.setFieldsValue({
            capitalShares: [...capitalShares, values],
        });
        setCapitalShareVisibility(false);
    };

    const submitBoardofDirectorForm = (forms: any, values: any) => {
        const { requestCasinoLicenseForm } = forms;

        const boardOfDirectors: any =
            requestCasinoLicenseForm.getFieldValue("boardOfDirectors") || [];
        requestCasinoLicenseForm.setFieldsValue({
            boardOfDirectors: [...boardOfDirectors, values],
        });

    };

    const ActionButton = () => {
        return (
            <div>
                <Button
                    onClick={() => {
                        form.submit();
                    }}
                    type="primary"
                    className="inline-flex items-center justify-center px-4 py-2 mx-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500"
                >
                    រក្សាទុក
                </Button>
            </div>
        );
    };

    // events
    const onFormProviderSubmit = (name: string, { values, forms }: any) => {
        switch (name) {
            case "boardOfDirectorForm":
                submitBoardofDirectorForm(forms, values);
                break;
            case "requestCasinoLicenseForm":
                submitCasinoLicenseForm(name, values);
                break;
            case "capitalShareForm":
                submitCapitalShareForm(forms, values);
                break;
            default: {
                break;
            }
        }
    };
    return (
        <FullLayout
            icon={<ArchiveIcon />}
            title={`ពាក្យស្នើសុំអាជ្ញាបណ្ណ (C001) - ${casinoModel?.formData?.casino?.name} (${casinoModel?.formData?.casino?.latinName})`}
            descriptions="ត្រលប់ទៅបញ្ជី"
            redirectUrl="/license/casino/application-form/request-license"
            actions={<ActionButton />}
        >
            <HelmetHeader meta="ចុះបញ្ជី" />
            <Form.Provider onFormFinish={onFormProviderSubmit}>
                <RequestCasinoLicenseDetailForm
                    form={form}
                    setCapitalShareVisibility={setCapitalShareVisibility}
                    isDifferentRepresentative={isDifferentRepresentative}
                    setIsDifferentRepresentative={setIsDifferentRepresentative}
                />
                <CapitalShareModal
                    visible={capitalShareVisibility}
                    onCancel={() => setCapitalShareVisibility(false)}
                />
            </Form.Provider>

        </FullLayout>
    );
};
