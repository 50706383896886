import produce from "immer";
import {
    GameTypeConstants,
    GET_GAME_TYPE_SUCCESS,
    SET_GAME_TYPE_ID,
    SELECT_GAME_TYPE_SUCCESS,
} from "./Constants";

export const initialState = {
    gameTypes: [],
    gameTypeDetail: null,
    gameTypeOptions: [],
    errors: null,
    formValues: { search: "" },
    id: "",
};

const SettingGameTypeListReducer = produce((draft, action) => {
    switch (action.type) {
        case GameTypeConstants.SET_FORM_VALUES:
            draft.formValues = action.formValues;
            break;
        case GameTypeConstants.CREATE_GAME_TYPE_SUCCESS:
            draft.errors = action.errors;
            break;
        case GET_GAME_TYPE_SUCCESS:
            draft.gameTypeDetail = action.gameTypeDetail;
            break;
        case SET_GAME_TYPE_ID:
            draft.id = action.id;
            break;
        case GameTypeConstants.QUERY_GAME_TYPE_SUCCESS:
            draft.errors = action.errors;
            draft.gameTypes = action.gameTypes;
            break;
        case SELECT_GAME_TYPE_SUCCESS:
            draft.gameTypeOptions = action.gameTypeOptions;
            break;
        default: break;
    }
}, initialState);

export default SettingGameTypeListReducer;
