import { useSelector } from "react-redux";
import { useInjectReducer } from "Utility/InjectReducer";

import StringToReactComponent from "string-to-react-component";
import {
    Form,
    Input,
    Button
} from "antd";
import { FullLayout } from "Components/Layouts/Inner/Full.Layout";
import { HelmetHeader } from "Components/Helmet-Header/index";

import ReportReducer from "Modules/Reporting/Reducer";
import { MakeDataSourceSelector } from "Modules/Reporting/Selectors";

const key = "reporting";
export const ReportPreview = () => {
    useInjectReducer({key, reducer: ReportReducer});
    // fields
    const template = {
        params: [
            {
                label: "Search",
                name: "search",
                type: 1,
            },
            {
                label: "ថ្ងៃ",
                name: "date",
                type: 2,
            },
        ],
        body: "<div>{dataSource.map((item) => <h1 key={item}>{item}</h1>)}</div>",
    };
    const dataSource = useSelector(MakeDataSourceSelector());

    // event
    const onFilter = (values: any) => {
        
    }
    
    return (
        <>
            <FullLayout
                icon={<></>}
                title="របាយការណ៍"
                descriptions=""
                actions={<></>}
            >
                <HelmetHeader meta="របាយការណ៍" />
                
                <div className="w-full h-full flex flex-col p-4 overflow-scroll border bg-slate-100">
                    <Form name="filterForm" onFinish={onFilter} layout="vertical" className="flex flex-row items-end px-4 pt-2 gap-1 bg-slate-50">
                        
                        {
                            template?.params.map((formItem: any) => 
                            <Form.Item key={formItem.name} name={formItem.name} label={formItem.label}>
                                <Input />
                            </Form.Item>)
                        }
                        <Form.Item>
                            <Button htmlType="submit">Preview</Button>
                        </Form.Item>
                    </Form>
                    <div className="container flex-1 mt-4 p-4 mx-auto bg-white">
                        <StringToReactComponent data={{dataSource, template}}>
                        {`(props)=>{
                            const { dataSource } = props;
                            return (<>
                            ${template?.body || "Failed to load report"}
                            </>);
                        }`}
                        </StringToReactComponent>
                    </div>
                </div>
                
            </FullLayout>
        </>
    )


}