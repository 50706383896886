import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useInjectReducer } from "Utility/InjectReducer";
import { useInjectSaga } from "Utility/InjectSaga";

import {
    Form,
    Select,
    Input
} from "antd";

import GameTypeReducer from "Modules/Settings/GameType/Reducer";
import GameTypeSaga from "Modules/Settings/GameType/Saga";
import { MakeSelectGameTypeSelector } from "Modules/Settings/GameType/Selectors";
import { SelectGameTypeRequestAction, SelectGameTypeSuccessAction, SetFormValuesAction } from "Modules/Settings/GameType/Actions";
import { useSearchParams } from "react-router-dom";

const key = "gameType";
export const LotteryGameTypeForm = ({ form }: any) => {
    useInjectReducer({ key, reducer: GameTypeReducer });
    useInjectSaga({ key, saga: GameTypeSaga });
    // fields
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();
    const gameTypeOptions = useSelector(MakeSelectGameTypeSelector());
    const entityType = searchParams.get("entityType");

    // constructor
    useEffect(() => {
        const entityTypeId = entityType === "LOTTERY" ? 2 : 1;
        dispatch(SetFormValuesAction({ search: "", entityTypeId: entityTypeId }));
        dispatch(SelectGameTypeRequestAction());

        return () => {
            dispatch(SelectGameTypeSuccessAction([]));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <Form name="lotteryGameTypeForm" layout="vertical" className="pt-5" form={form}>
            <Form.Item name="gameType">
                <Select showSearch labelInValue>
                    {gameTypeOptions && gameTypeOptions.map((gameType: any) => <Select.Option key={gameType.id} value={gameType.id}>{gameType.name}</Select.Option>)}
                </Select>
            </Form.Item>
            <Form.Item name="other" label="ផ្សេងៗ/Other">
                <Input.TextArea />
            </Form.Item>
        </Form>
    )
}