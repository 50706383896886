import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useInjectReducer } from "Utility/InjectReducer";
import { useInjectSaga } from "Utility/InjectSaga";
import { v4 as uuid } from "uuid";
import dayjs from "dayjs";

import { HelmetHeader } from "Components/Helmet-Header";
import { ArchiveIcon } from "@heroicons/react/outline";
import {
    Form,
    Modal,
    Button,
} from "antd";
import { FullLayout } from "Components/Layouts/";
import { InspectionCertificateMasterForm } from "Modules/Settings/InspectionCertificateMaster/Forms/InspectionCertificateMasterForm";
import { InspectionCertificateMasterSerialForm } from "Modules/Settings/InspectionCertificateMaster/Forms/InspectionCertificateMasterSerialForm";

import Reducer from "Modules/Settings/InspectionCertificateMaster/Reducer";
import Saga from "Modules/Settings/InspectionCertificateMaster/Saga";
import InspectionCertificateTypeReducer from "Modules/Settings/InspectionCertificateType/Reducer";
import InspectionCertificateTypeSaga from "Modules/Settings/InspectionCertificateType/Saga";
import { InspectionCertificateDetailForm } from "../Forms/InspectionCertificateDetailForm";
import { CreateInspectionCertificateMasterRequestAction, DeleteInspectionCertificateDetailRequestAction, EditInspectionCertificateDetailRequestAction, GetInspectionCertificateMasterRequestAction, GetInspectionCertificateMasterSuccessAction, SetInspectionCertificateDetailIdAction, SetInspectionCertificateDetailValueAction, SetInspectionCertificateMasterIdAction, SetInspectionCertificateMasterValueAction } from "../Actions";
import { useParams } from "react-router-dom";
import { MakeInspectionCertificateMasterSelector } from "../Selectors";

const key = "inspectionCertificateMaster";
const inspectionCertificateTypeKey = "inspectionCertificateType";
export const InspectionCertificateMasterDetail = () => {
    useInjectReducer({ key, reducer: Reducer });
    useInjectSaga({ key, saga: Saga });
    useInjectReducer({ key: inspectionCertificateTypeKey, reducer: InspectionCertificateTypeReducer });
    useInjectSaga({ key: inspectionCertificateTypeKey, saga: InspectionCertificateTypeSaga });

    // fields
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [serialForm] = Form.useForm();
    const [manualForm] = Form.useForm();
    const { id } = useParams();
    const [serialVisible, setSerialVisible] = useState<boolean>(false);
    const [manualSerialVisible, setManualSerialVisible] = useState<boolean>(false);
    const inspectionCertificateMaster = useSelector(MakeInspectionCertificateMasterSelector());

    // constructor
    useEffect(() => {

        if (id) {
            dispatch(SetInspectionCertificateMasterIdAction(id));
            dispatch(GetInspectionCertificateMasterRequestAction());
        }
        return () => {
            dispatch(SetInspectionCertificateMasterIdAction(null));
            dispatch(GetInspectionCertificateMasterSuccessAction(null));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    useEffect(() => {
        if (inspectionCertificateMaster) {
            const model = {
                ...inspectionCertificateMaster
            }
            model.registeredDate = dayjs(inspectionCertificateMaster.registeredDate);
            model.serials = inspectionCertificateMaster.inspectionCertificateDetails;
            form.setFieldsValue(model);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inspectionCertificateMaster]);

    // events
    const onFormSubmit = (name: string, { forms, values }: any) => {

        switch (name) {
            case "inspectionCertificateMasterSerialForm":
                onGenerateSerial(values);
                break;
            case "inspectionCertificateDetailForm":
                onManualAddSerial(values);
                break;
            case "inspectionCertificateMasterForm":
                onInspectionCertificateMasterSubmit(values);
                break;
            default: break;
        }
    }

    const onManualAddSerial = (values: any) => {
        const serials = form.getFieldValue("serials") || [];
        const serial = serials.map(e => e.id).indexOf(values.id);
        
        if (serial > -1) {
            const model = {
                ...values,
                state: 1,
                inspectionCertificateType: values.inspectionCertificateType.label,
                inspectionCertificateTypeId: values.inspectionCertificateType.value,
                // inspectionCertificateStatusId: "a4dea9f0-4810-4fe3-b450-6c0ef6cbd877",
                equipmentTypeName: values.inspectionCertificateType.label,
            };
            model.inspectionCertificateMasterId = form.getFieldValue("id");
            dispatch(SetInspectionCertificateDetailValueAction(model));
            dispatch(EditInspectionCertificateDetailRequestAction());
            serials[serial] = model;
            form.setFieldValue("serials", [...serials]);
        }
        else {
            const model = {
                id: uuid(),
                inspectionCertificateType: values.inspectionCertificateType.label,
                equipmentTypeName: values.inspectionCertificateType.label,
                inspectionCertificateTypeId: values.inspectionCertificateType.value,
                // inspectionCertificateStatusId: "a4dea9f0-4810-4fe3-b450-6c0ef6cbd877",
                serialNumber: values.serialNumber,
                versionId: values.versionId,
                versionGuid: values.versionId,
                quantity: values.quantity,
                printDate: "",
                status: null,
                state: 1,
            };
            form.setFieldValue("serials", [model, ...serials]);
        }
        manualForm.resetFields();
        setManualSerialVisible(false);
    }
    const onGenerateSerial = (values: any) => {
        const { quantity } = values;
        const serials = [];
        for (let serialIndex = 0; serialIndex < quantity; serialIndex++) {
            const versionId = uuid();
            const model = {
                id: uuid(),
                // inspectionCertificateStatusId: "a4dea9f0-4810-4fe3-b450-6c0ef6cbd877",
                equipmentTypeName: values.inspectionCertificateType.label,
                inspectionCertificateType: values.inspectionCertificateType.label,
                inspectionCertificateTypeId: values.inspectionCertificateType.value,
                serialNumber: (serialIndex + 1).toString().padStart(4, '0'),
                quantity: 0,
                validedDate: values.validUntil,
                versionGuid: versionId,
                printDate: null,
                status: null,
                state: 1,
            };
            serials.push(model);
        }
        form.setFieldValue("serials", serials);
        serialForm.resetFields();
        setSerialVisible(false);
    }

    const onDeleteSerial = (item: any) => {
        const serials = form.getFieldValue("serials");
        const serial = serials.indexOf(item);
        if (serial > -1) {

            dispatch(SetInspectionCertificateDetailIdAction(item.id));
            dispatch(DeleteInspectionCertificateDetailRequestAction());
            serials.splice(serial, 1);
            form.setFieldValue("serials", [...serials]);
        }

    }

    const onEditSerial = (item: any) => {
        const model = {
            ...item,
            inspectionCertificateType: {
                value: item.inspectionCertificateTypeId,
                label: item.inspectionCertificateType,
            }
        };
        model.validedDate = dayjs(item.validedDate);

        manualForm.setFieldsValue(model);
        setManualSerialVisible(true);
    }

    const onNewInspectionCertificateDetail = () => {
        manualForm.setFieldValue("versionId", uuid());
        setManualSerialVisible(true);
    }

    const onInspectionCertificateMasterSubmit = (values: any) => {
        const model = {
            ...values
        };
        model.inspectionCertificateDetails = form.getFieldValue("serials") || [];

        dispatch(SetInspectionCertificateMasterValueAction(model));
        dispatch(CreateInspectionCertificateMasterRequestAction());
    }

    // render
    const ActionButtons = () => (
        <>
            <Button
                type="primary"
                onClick={() => form.submit()}
            >
                រក្សាទុក
            </Button>
        </>
    );

    return (
        <>
            <FullLayout
                icon={<ArchiveIcon />}
                title="លតាបត្រ"
                descriptions=""
                actions={<ActionButtons />}
            >
                <HelmetHeader meta="លតាបត្រ" />
                <Form.Provider onFormFinish={onFormSubmit}>
                    <InspectionCertificateMasterForm form={form}
                        setSerialVisible={setSerialVisible}
                        setDetailVisible={onNewInspectionCertificateDetail}
                        onDelete={onDeleteSerial}
                        onEdit={onEditSerial} />
                    <Modal
                        open={serialVisible}
                        onCancel={() => setSerialVisible(false)}
                        onOk={() => serialForm.submit()}>
                        <InspectionCertificateMasterSerialForm form={serialForm} />
                    </Modal>
                    <Modal
                        open={manualSerialVisible}
                        onCancel={() => setManualSerialVisible(false)}
                        onOk={() => manualForm.submit()}>
                        <InspectionCertificateDetailForm form={manualForm} />
                    </Modal>
                </Form.Provider>
            </FullLayout>
        </>
    );
};
