import {
    SET_CERTIFICATE_GAME_EQUIPMENT_FILTER_VALUE,
    SET_REQUEST_GAME_EQUIPMENT_VALUE,
    SET_REQUEST_GAME_EQUIPMENT_ID,
    GET_REQUEST_GAME_EQUIPMENT_REQUEST,
    GET_REQUEST_GAME_EQUIPMENT_SUCCESS,
    EDIT_REQUEST_GAME_EQUIPMENT_REQUEST,
    CREATE_REQUEST_GAME_EQUIPMENT_REQUEST,
    SET_REQUEST_GAME_EQUIPMENT_FILTER_VALUE,
    LIST_REQUEST_GAME_EQUIPMENT_REQUEST,
    LIST_REQUEST_GAME_EQUIPMENT_SUCCESS,
    LIST_CERTIFICATE_GAME_EQUIPMENT_REQUEST,
    LIST_CERTIFICATE_GAME_EQUIPMENT_SUCCESS,
} from "Modules/Certificate/Casino/ApplicationForm/GameEquipment/Constants";

export const SetRequestGameEquipmentFilterValueAction = (values: any) => {
    return {
        type: SET_REQUEST_GAME_EQUIPMENT_FILTER_VALUE,
        values,
    };
};

export const SetRequestGameEquipmentValueAction = (values: any) => {
    return {
        type: SET_REQUEST_GAME_EQUIPMENT_VALUE,
        values,
    };
};

export const ListRequestGameEquipmentRequestAction = () => {
    return {
        type: LIST_REQUEST_GAME_EQUIPMENT_REQUEST,
    };
};

export const ListRequestGameEquipmentSuccessAction = (
    requestGameEquipmentList: any
) => {
    return {
        type: LIST_REQUEST_GAME_EQUIPMENT_SUCCESS,
        requestGameEquipmentList,
    };
};

export const CreateRequestGameEquipmentRequestAction = () => {
    return {
        type: CREATE_REQUEST_GAME_EQUIPMENT_REQUEST,
    };
};

export const SetCertificateGameEquipmentFilterValueAction = (values: any) => {
    return { type: SET_CERTIFICATE_GAME_EQUIPMENT_FILTER_VALUE, values };
};

export const ListCertificateGameEquipmentRequestAction = () => {
    return { type: LIST_CERTIFICATE_GAME_EQUIPMENT_REQUEST };
};
export const ListCertificateGameEquipmentSuccessAction = (
    listGameEquipment: any
) => {
    return {
        type: LIST_CERTIFICATE_GAME_EQUIPMENT_SUCCESS,
        listGameEquipment,
    };
};

export const SetRequestGameEquipmentIdAction = (id: string) => {
    return {
        type: SET_REQUEST_GAME_EQUIPMENT_ID,
        id,
    };
};

export const GetRequestGameEquipmentRequestAction = () => {
    return {
        type: GET_REQUEST_GAME_EQUIPMENT_REQUEST,
    };
};

export const GetRequestGameEquipmentSuccessAction = (
    requestGameEquipment: any
) => {
    return {
        type: GET_REQUEST_GAME_EQUIPMENT_SUCCESS,
        requestGameEquipment,
    };
};

export const EditRequestGameEquipmentRequestAction = () => {
    return {
        type: EDIT_REQUEST_GAME_EQUIPMENT_REQUEST,
    };
};
