import {
    Form,
    Input,
    Button
} from "antd";


export const CertifyLotteryGameTypeFilterForm = ({ form }: any) => {
    return (
        <>
            <Form.Item name="search" label="លេខអាជ្ញាបណ្ណ">
                <Input autoComplete="off" />
            </Form.Item>
            <Form.Item>
                <Button htmlType="submit" className="">ស្វែងរក</Button>
            </Form.Item>
        </>
    )
}