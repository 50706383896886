import { RequestLotteryGameTypeList } from "Modules/Certificate/Lottery/ApplicationForm/GameTypeEquipment/List";
import { RequestLotteryGameTypeCreate } from "Modules/Certificate/Lottery/ApplicationForm/GameTypeEquipment/Create";
import { RequestLotteryGameTypeDetail } from "Modules/Certificate/Lottery/ApplicationForm/GameTypeEquipment/Detail";
export const RequestLotteryGameTypeRoute = {
    path: "request-game-type",
    children: [
        { path: "", element: <RequestLotteryGameTypeList /> },
        { path: "create", element: <RequestLotteryGameTypeCreate /> },
        { path: ":id", element: <RequestLotteryGameTypeDetail /> }
    ],
};
