import React from "react";
import { List } from "Modules/Certificate/Casino/ApplicationForm/GameType/List";
import { CertificateGameTypeCreate } from "Modules/Certificate/Casino/ApplicationForm/GameType/Create";

import { RequestGameTypeList } from "Modules/Certificate/Casino/ApplicationForm/GameType/RequestGameTypeList";
import { RequestGameTypeCreate } from "Modules/Certificate/Casino/ApplicationForm/GameType/RequestGameType";
import { RequestGameTypeDetail } from "Modules/Certificate/Casino/ApplicationForm/GameType/RequestGameTypeDetail";

export const CertificateGameTypeRoute = [
    {
        path: "request",
        children: [
            { path: "", element: <RequestGameTypeList /> },
            { path: "create", element: <RequestGameTypeCreate /> },
            { path: ":id", element: <RequestGameTypeDetail /> },
        ],
    },
    {
        path: "",
        children: [
            { path: "", element: <List /> },
            { path: "create", element: <CertificateGameTypeCreate /> },
            // { path: ":id", element: <RequestGameTypeDetail /> },
        ],
    },
];
