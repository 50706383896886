import { createSelector } from "reselect";
import { initialState } from "Modules/Application/Reducer";

const selectGameRuleDomain = (state: any) => state.gameRule || initialState;
const MakeFormValueSelector = () =>
    createSelector(selectGameRuleDomain, (substate) => substate.formValues);

const MakeGameRuleSelector = () =>
    createSelector(selectGameRuleDomain, (substate) => substate.gameRules);
const MakeGameRuleIdSelector = () =>
    createSelector(selectGameRuleDomain, (substate) => substate.id);
const MakeGameRuleDetailSelector = () =>
    createSelector(selectGameRuleDomain, (substate) => substate.gameRuleDetail);
const MakeGameRuleFilterValueSelector = () => createSelector(selectGameRuleDomain, substate => substate.gameRuleFilterValue);
const MakeSelectGameRuleSelector = () => createSelector(selectGameRuleDomain, substate => substate.gameRuleOptions)

export {
    MakeFormValueSelector,
    MakeGameRuleSelector,
    MakeGameRuleDetailSelector,
    MakeGameRuleIdSelector,
    MakeSelectGameRuleSelector,
    MakeGameRuleFilterValueSelector
};
