import {
    Form,
    Input
} from "antd";

export const EquipmentTypeForm = ({ form }: any) => {

    return (
        <>
            <Form
                name="equipmentTypeForm"
                layout="vertical"
                className="w-full grid grid-cols-1 items-end gap-2 p-5"
                form={form}
            >
                <Form.Item name="id" hidden>
                    <Input />
                </Form.Item>
                <Form.Item name="state" hidden>
                    <Input />
                </Form.Item>
                <Form.Item name="name" label="ប្រភេទឧបករណ៍">
                    <Input />
                </Form.Item>
                <Form.Item name="prefix" label="កូដ">
                    <Input />
                </Form.Item>
                <Form.Item name="descriptions" label="បរិយាយ">
                    <Input />
                </Form.Item>
            </Form>
        </>)
}