import { createSelector } from "reselect";
import { LotteryCertifyInitialState } from "Modules/Certificate/Lottery/Certify/Reducer";

const LotteryCertifyDomain = (state: any) => state.lotteryCertify || LotteryCertifyInitialState;
const MakeLotteryGameTypeCertifyApproveSelector = () => createSelector(LotteryCertifyDomain, substate => substate.lotteryGameTypeCertifyApprove);
const MakeLotteryCertifyValueSelector = () => createSelector(LotteryCertifyDomain, substate => substate.lotteryCertifyValue);
const MakeLotteryCertifyFilterValueSelector = () => createSelector(LotteryCertifyDomain, substate => substate.lotteryCertifyFilterValue);
const MakeLotteryCertifyListSelector = () => createSelector(LotteryCertifyDomain, substate => substate.lotteryCertifies);
const MakeLotteryCertifySelector = () => createSelector(LotteryCertifyDomain, substate => substate.lotteryCertify);
const MakeLotteryCertifyIdSelector = () => createSelector(LotteryCertifyDomain, substate => substate.lotteryCertifyId);

export {
    MakeLotteryGameTypeCertifyApproveSelector,
    MakeLotteryCertifyValueSelector,
    MakeLotteryCertifyFilterValueSelector,
    MakeLotteryCertifySelector,
    MakeLotteryCertifyListSelector,
    MakeLotteryCertifyIdSelector
};
