import React from "react";
import { Link } from "react-router-dom";
export interface FullLayoutType {
    title: string;
    descriptions: string;
    icon: any;
    actions?: any;
    children: React.ReactNode;
    redirectUrl?: string;
}

export const FullLayout = ({
    title,
    descriptions,
    icon,
    actions,
    children,
    redirectUrl = null,
}: FullLayoutType) => {
    return (
        <div className="h-full flex flex-col justify-between">
            <div className="sticky flex flex-col md:flex-row justify-between items-center p-2 top-0 shadow bg-white z-50 " >
                <div className="flex relative items-center space-x-5 py-2">
                    <div className="flex-shrink-0">
                        <div className="relative">

                            <span
                                className="absolute inset-0 shadow-inner rounded-full"
                                aria-hidden="true"
                            />
                        </div>
                    </div>
                    <div>
                        <h1 className="text-2xl font-bold text-gray-900">
                            {title}
                        </h1>
                        {redirectUrl ? (
                            <Link
                                to={redirectUrl}
                                className="text-sm font-medium text-blue-500 underline"
                            >
                                {descriptions}
                            </Link>
                        ) : (
                            <p className="text-sm font-medium text-gray-500 mt-2">
                                {descriptions}
                            </p>
                        )}
                    </div>
                </div>
                <div className="relative flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3 py-2 mr-4">
                    {actions}
                </div>
            </div>
            <div className="flex-1">
                {children}
            </div>
        </div>
    );
};
