import { useSelector } from "react-redux";
import {
    DatePicker,
    Form,
    InputNumber,
    Select
} from "antd";
import {
    MakeInspectionCertificateTypeOptionsSelector
} from "Modules/Settings/InspectionCertificateType/Selectors";

export const InspectionCertificateMasterSerialForm = ({ form }: any) => {
    // fields
    const inspectionCertificateTypes = useSelector(MakeInspectionCertificateTypeOptionsSelector());

    return (
        <Form
            name="inspectionCertificateMasterSerialForm"
            layout="vertical"
            className="mt-10"
            form={form}>
            <Form.Item name="inspectionCertificateType" label="Inspection Certificate Type">
                <Select showSearch labelInValue>
                    {inspectionCertificateTypes && inspectionCertificateTypes.map((item: any) => <Select.Option key={item.equipmentTypeId} value={item.id}>{item.name}</Select.Option>)}
                </Select>
            </Form.Item>
            <Form.Item name="validUntil" label="Valid Until">
                <DatePicker />
            </Form.Item>
            <Form.Item name="quantity" label="Quantity">
                <InputNumber />
            </Form.Item>
        </Form>
    )
}