
import { useDispatch } from "react-redux";
import { HelmetHeader } from "Components/Helmet-Header";
import { ArchiveIcon } from "@heroicons/react/outline";
import { Form, Input } from "antd";
import { FullLayout } from "Components/Layouts/";

import { SetPasswordValueAction, ChangePasswordRequestAction } from "Modules/Application/Actions"

export const ChangePassword = () => {

    // fields
    const [form] = Form.useForm();
    const dispatch = useDispatch();

    // methods
    const onFinish = (
        values: any
    ) => {
        dispatch(SetPasswordValueAction(values));
        dispatch(ChangePasswordRequestAction());
    };
    // render
    const ActionButtons = () => (
        <>
            <button
                type="button"
                className="inline-flex items-center justify-center px-4 py-2 mx-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500"
                onClick={() => form.submit()}
            >
                ប្តូរលេខសម្ងាត់
            </button>
        </>
    );

    return (
        <>
            <FullLayout
                icon={<ArchiveIcon />}
                title="ប្តូរលេខសម្ងាត់"
                descriptions=""
                actions={<ActionButtons />}
            >
                <HelmetHeader meta="ប្តូរលេខសម្ងាត់" />
                <Form name="changePasswordForm" layout="vertical" onFinish={onFinish} className="p-5" form={form}>
                    <Form.Item name="oldPassword" label="Old password">
                        <Input.Password />
                    </Form.Item>
                    <Form.Item name="newPassword" label="New Password">
                        <Input.Password />
                    </Form.Item>
                </Form>

            </FullLayout>
        </>
    );
};
