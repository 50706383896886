import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useInjectReducer } from "Utility/InjectReducer";
import { useInjectSaga } from "Utility/InjectSaga";
import {
    Form,
    Button
} from "antd";
import { FullLayout } from "Components/Layouts/Inner/Full.Layout";
import { HelmetHeader } from "Components/Helmet-Header/index";
import { ArchiveIcon } from "@heroicons/react/outline";
import { CertifyLotteryGameTypeEquipmentCreateForm } from "Modules/Certificate/Lottery/Certify/GameTypeEquipment/Forms/CertifyLotteryGameTypeEquipmentCreateForm";

import LotteryCertifyReducer from "Modules/Certificate/Lottery/Certify/Reducer";
import LotteryCertifySaga from "Modules/Certificate/Lottery/Certify/Saga";
import {
    GetCasinoModalDetailAction,
    SetCasinoIdAction,
} from "Modules/Entity/Operator/Actions";
import { CreateLotteryCertifyRequestAction, SetLotteryCertifyValueAction } from "Modules/Certificate/Lottery/Certify/Actions";
import { MakeLotteryGameTypeCertifyApproveSelector } from "Modules/Certificate/Lottery/Certify/Selector";

const certifyKey = "lotteryCertify";
export const LotteryGameTypeCertifyApprove = () => {
    useInjectReducer({ key: certifyKey, reducer: LotteryCertifyReducer });
    useInjectSaga({ key: certifyKey, saga: LotteryCertifySaga });
    //fields
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const lotteryGameTypeCertifyApprove = useSelector(MakeLotteryGameTypeCertifyApproveSelector());

    // constructors
    useEffect(() => {
        if (lotteryGameTypeCertifyApprove) {
            const model = {
                referenceId: lotteryGameTypeCertifyApprove.id,
                ...lotteryGameTypeCertifyApprove
            };

            form.setFieldsValue(model);
            dispatch(SetCasinoIdAction(model.casinoId));
            dispatch(GetCasinoModalDetailAction(null));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lotteryGameTypeCertifyApprove])

    // event
    const onSubmit = (values: any) => {
        const model = {
            formType: "L003",
            entityName: "LOTTERY",
            ReceiverName: " ",
            TrackingCode: " ",
            code: values.casinoLicenseNumber,
            ...values
        }
        dispatch(SetLotteryCertifyValueAction(model));
        dispatch(CreateLotteryCertifyRequestAction());
    }

    const ActionButtons = () => (
        <>
            <Button
                onClick={() => form.submit()}
                className="inline-flex items-center justify-center px-4 py-2 mx-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500"
            >
                បញ្ចូលថ្មី
            </Button>
        </>
    );

    return (
        <>
            <FullLayout
                icon={<ArchiveIcon />}
                title="ពាក្យស្នើសុំចុះបញ្ជីប្រភេទល្បែង និឧបករណ៍ល្បែង សម្រាប់ល្បែងផ្សងសំណាង"
                descriptions=""
                actions={<ActionButtons />}
            >
                <HelmetHeader meta="ពាក្យស្នើសុំចុះបញ្ជីប្រភេទល្បែង និងឿបករណ៍ល្បែង សម្រាប់ល្បែងផ្សងសំណាង" />
                <Form
                    name="lotteryGameTypeCertifyApproveForm" layout="vertical"
                    form={form} className="p-5"
                    onFinish={onSubmit}>
                    <CertifyLotteryGameTypeEquipmentCreateForm form={form} />
                </Form>

            </FullLayout>
        </>
    );
}