import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { HelmetHeader } from "Components/Helmet-Header";
import { ArchiveIcon } from "@heroicons/react/outline";
import { Form, Button } from "antd";
import { FullLayout } from "Components/Layouts/";
import { useInjectReducer } from "Utility/InjectReducer";
import { useInjectSaga } from "Utility/InjectSaga";
import Reducer from "Modules/Certificate/Casino/ApplicationForm/GameRule/Reducer";
import Saga from "Modules/Certificate/Casino/ApplicationForm/GameRule/Saga";
import { RequestGameRuleCreateForm } from "Modules/Certificate/Casino/ApplicationForm/GameRule/Forms/RequestGameRuleCreateForm";
import { v4 as uuid} from "uuid";

import RequestReducer from "Modules/License/Casino/Request/Reducer";
import RequestSaga from "Modules/License/Casino/Request/Saga";
import {
    CreateRequestGameRuleRequestAction,
    SetRequestGameRuleValueAction,
} from "Modules/Certificate/Casino/ApplicationForm/GameRule/Actions";
import { QueryGameRuleSuccessAction, SelectGameRuleSuccessAction } from "Modules/Settings/GameRule/Actions";
import { GetIdentificationTypeOptionService, GetOperatorOptionService } from "Modules/Entity/Operator/Service";
import { SelectCasinoSuccessAction } from "Modules/Entity/Operator/Actions";
import { useSearchParams } from "react-router-dom";
import { GetGameRuleListService } from "Modules/Settings/GameRule/Service";
import { SelectableIdentificationTypeSuccessAction } from "Modules/License/Casino/Actions";

const key = "certificateGameRule";
const requestKey = "requestLicenseCasino";
export const RequestGameRuleCreate = () => {
  useInjectReducer({ key, reducer: Reducer });
  useInjectSaga({ key, saga: Saga });

  useInjectReducer({ key: requestKey, reducer: RequestReducer });
  useInjectSaga({ key: requestKey, saga: RequestSaga });

  // fields
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const entityType = searchParams.get("entityType");

    // constructor
    useEffect(() => {
        const entityTypeId = entityType === "LOTTERY" ? 2 : 1;
        const model = {
            entityType: entityTypeId,
            search: ""
        }
        GetIdentificationTypeOptionService(dispatch).then((nationalityRs: any) => {
            if(nationalityRs) {
                dispatch(SelectableIdentificationTypeSuccessAction(nationalityRs));
            }
            GetGameRuleListService(model, dispatch).then((gameTypeOption: any) => {
                if(gameTypeOption) {
                    dispatch(QueryGameRuleSuccessAction(gameTypeOption));
                }
                GetOperatorOptionService(dispatch).then((casinoOptionsRs: any) => {
                    if(casinoOptionsRs) {
                        dispatch(SelectCasinoSuccessAction(casinoOptionsRs));
                    }
                });
            });
        });
        

        return () => {
            dispatch(SelectableIdentificationTypeSuccessAction([]));
            dispatch(SelectCasinoSuccessAction([]));
            dispatch(SelectGameRuleSuccessAction([]));
        };
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

  // events
  const submitCasinoRuleLicenseForm = (name: string, values: any) => {
    form.validateFields();
     let licenseModel = {
      ...form.getFieldsValue(),
      trackingCode: "",
      entityName: "Casino Game Rule",
      formType: "C003",
      CompanyNameInKhmer: values.formData.company.name,
      CasinoNameInKhmer: values.formData.casino.name,
      code: values.code,
      applicantName: values.formData.applicant.fullname,
      applicantNationality: values.formData.applicant.nationality,
      applicantContactNumber: values.formData.applicant.mobilePhoneNumber,
      representativeName: values.formData.applicant.fullname,
      representativeNationality: values.formData.applicant.nationality,
      representativeContactNumber:
          values.formData.applicant.mobilePhoneNumber,
      formData: {
          ...values.formData,
          gameRules: form.getFieldValue("gameRules"),
      },
    };
    if (values.formData.representativeType === true) {
        licenseModel.representativeName =
            values.formData.representative.fullname;
        licenseModel.representativeNationality =
            values.formData.representative.nationality;
        licenseModel.representativeContactNumber =
            values.formData.representative.mobilePhoneNumber;
    }
    licenseModel.formData.casinoTypeId = values.formData.casinoTypeId;
    
    dispatch(SetRequestGameRuleValueAction(licenseModel));
    dispatch(CreateRequestGameRuleRequestAction());
  };

    const onGameRuleModalFormSubmit = (
        requestCasinoGameRuleLicenseForm: any,
        values: any
    ) => {
        const gameRules = form.getFieldValue("gameRules") || [];
        const model = {
            ...values,
            id: uuid()
        }
        form.setFieldsValue({ gameRules: [...gameRules, model] });
    };

    const ActionButton = () => {
        return (
            <Button
                onClick={() => {
                    form.submit();
                }}
                type="primary"
            >
                រក្សាទុក
            </Button>
        );
    };

    // events
    const onFormProviderSubmit = (name: string, { values, forms }: any) => {
        switch (name) {
            case "requestCasinoGameRuleLicenseForm":
                submitCasinoRuleLicenseForm(name, values);
                break;
            case "gameRuleModalForm":
                const { requestCasinoGameRuleLicenseForm } = forms;
                onGameRuleModalFormSubmit(
                    requestCasinoGameRuleLicenseForm,
                    values
                );
                break;
            default: {
                break;
            }
        }
    };

    return (
        <FullLayout
            icon={<ArchiveIcon />}
            title="ស្នើសុំចុះបញ្ជីវិធានល្បែង (C003)"
            descriptions="ត្រលប់ទៅបញ្ជី"
            redirectUrl="/certificate/casino/request-game-rule/request"
            actions={<ActionButton />}
        >
            <HelmetHeader meta="ចុះបញ្ជី" />
            <Form.Provider onFormFinish={onFormProviderSubmit}>
                <RequestGameRuleCreateForm form={form} />
            </Form.Provider>
        </FullLayout>
    );
};
