import produce from "immer";
import {
    SET_REQUEST_GAME_RULE_FILTER_VALUE,
    SET_REQUEST_GAME_RULE_VALUE,
    LIST_REQUEST_GAME_RULE_SUCCESS,
    SET_REQUEST_GAME_RULE_ID,
    GET_REQUEST_GAME_RULE_SUCCESS,
    SET_CERTIFICATE_GAME_RULE_FILTER_VALUE,
    LIST_CERTIFICATE_GAME_RULE_SUCCESS,
} from "Modules/Certificate/Casino/ApplicationForm/GameRule/Constants";

export const FilterFormCode = "C003";

export const EmptyFields = {
    formTypeCode: "C003",
};
export const initialState = {
    initialValues: EmptyFields,
    gameRuleList: [],
    listGameRule: [],
    formValues: null,
    requestFormValue: null,
    filterValues: {},
    certificateFilterValue: null,
    errors: [],
    id: null,
    requestGameRuleDetail: null,
};

const RequestGameRuleReducer = produce((draft, action) => {
    switch (action.type) {
        case SET_REQUEST_GAME_RULE_FILTER_VALUE:
            draft.filterValues = action.values;
            break;

        case LIST_REQUEST_GAME_RULE_SUCCESS:
            draft.gameRuleList = action.requestGameRuleList;
            break;
        case SET_REQUEST_GAME_RULE_ID:
            draft.id = action.id;
            break;
        case GET_REQUEST_GAME_RULE_SUCCESS:
            draft.requestGameRuleDetail = action.requestGameRule;
            break;
        case SET_REQUEST_GAME_RULE_VALUE:
            draft.requestFormValue = action.values;
            break;
        case SET_CERTIFICATE_GAME_RULE_FILTER_VALUE:
            draft.certificateFilterValue = action.values;
            break;
        case LIST_CERTIFICATE_GAME_RULE_SUCCESS:
            draft.listGameRule = action.listGameRule;
            break;
        default:
            break;
    }
}, initialState);
export default RequestGameRuleReducer;
