import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { useInjectReducer } from "Utility/InjectReducer";
import { useInjectSaga } from "Utility/InjectSaga";
import dayjs from "dayjs";

import { DeleteOutlined } from "@ant-design/icons";
import {
    Form,
    Tabs,
    Input,
    Table,
    Button,
    Space,
    Modal,
    DatePicker
} from "antd";

import GameRuleReducer from "Modules/Settings/GameRule/Reducer";
import GameRuleSaga from "Modules/Settings/GameRule/Saga";
import EquipmentTypeReducer from "Modules/Settings/EquipmentType/Reducer";
import EquipmentTypeSaga from "Modules/Settings/EquipmentType/Saga";
import CurrencyReducer from "Modules/Settings/Currency/Reducer";
import CurrencySaga from "Modules/Settings/Currency/Saga";
import { TableEquipomentForm } from "Modules/Equipments/Casino/Forms/TableEquipmentForm";
import { MachineEquipomentForm } from "Modules/Equipments/Casino/Forms/MachineEquipmentForm";
import {
    SelectGameRuleRequestAction,
    SelectGameRuleSuccessAction,
    SetGameRuleFilterValueAction
} from "Modules/Settings/GameRule/Actions";
import { MakeSelectGameRuleSelector } from "Modules/Settings/GameRule/Selectors";
import {
    SelectEquipmentTypeRequestAction,
    SelectEquipmentTypeSuccessAction
} from "Modules/Settings/EquipmentType/Actions";
import { MakeEquipmentTypeOptionsSelector } from "Modules/Settings/EquipmentType/Selectors";
import {
    SelectCurrencyRequestAction,
    SelectCurrencySuccessAction
} from "Modules/Settings/Currency/Actions";
import { MakeSelectCurrencySelector } from "Modules/Settings/Currency/Selectors";
import { MakeCasinoEquipmentMasterSelector } from "../Selectors";

const gameRuleKey = "gameRule";
const equipmentTypeKey = "equipmentType";
const currencyKey = "currency";
export const CreateCasinoEquipmentMasterModalForm = ({ form, onDeleteEquipment }: any) => {
    useInjectReducer({ key: gameRuleKey, reducer: GameRuleReducer });
    useInjectSaga({ key: gameRuleKey, saga: GameRuleSaga });
    useInjectReducer({ key: equipmentTypeKey, reducer: EquipmentTypeReducer });
    useInjectSaga({ key: equipmentTypeKey, saga: EquipmentTypeSaga });
    useInjectReducer({ key: currencyKey, reducer: CurrencyReducer });
    useInjectSaga({ key: currencyKey, saga: CurrencySaga });
    // fields
    const dispatch = useDispatch();
    const [tableForm] = Form.useForm();
    const [machineForm] = Form.useForm();
    const [searchParam] = useSearchParams();
    const equipmentType = searchParam.get("equipmentType");
    const [tableVisible, setTableVisible] = useState<boolean>(false);
    const [machineVisible, setMachineVisible] = useState<boolean>(false);
    const gameRuleOptions = useSelector(MakeSelectGameRuleSelector());
    const equpmentTypeOptions = useSelector(MakeEquipmentTypeOptionsSelector());
    const currencyOptions = useSelector(MakeSelectCurrencySelector());
    const casinoEquipmentMaster = useSelector(MakeCasinoEquipmentMasterSelector());
    const casinoEquipmentDetailColumns = [
        {
            key: "gameRule",
            dataIndex: "gameRule",
            title: "Game Rule",
        },
        {
            key: "terminalId",
            dataIndex: "terminalId",
            title: "Table / Machine ID",
        },
        {
            key: "equipmentType",
            dataIndex: "equipmentType",
            title: "Equipment Type",
        },
        {
            key: "zoneId",
            dataIndex: "zoneId",
            title: "Zone",
        },
        {
            key: "note",
            dataIndex: "note",
            title: "Note"
        },
        {
            key: "id",
            width: 50,
            render: (item: any) => <Space>
                <Button type="link" onClick={() => onDeleteEquipment(item)}>
                    <DeleteOutlined />
                </Button>
            </Space>
        }
    ];

    // constructors
    useEffect(() => {
        dispatch(SetGameRuleFilterValueAction({ entityTypeId: 1 }));
        dispatch(SelectGameRuleRequestAction());

        dispatch(SelectEquipmentTypeRequestAction());

        dispatch(SelectCurrencyRequestAction());

        return () => {
            dispatch(SetGameRuleFilterValueAction(null));
            dispatch(SelectGameRuleSuccessAction([]));
            dispatch(SelectEquipmentTypeSuccessAction([]));
            dispatch(SelectCurrencySuccessAction([]));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (casinoEquipmentMaster) {

        }
    }, [casinoEquipmentMaster]);

    // events
    const onCloseTerminal = () => {
        setTableVisible(false);
        setMachineVisible(false);
    }

    //render
    const renderTerminalButton = () => {
        if (equipmentType === "1") {
            return <Button className="mb-2" onClick={() => setTableVisible(true)}>
                បន្ថែមតុ
            </Button>

        } else if (equipmentType === "2") {
            return <Button className="mb-2" onClick={() => setMachineVisible(true)}>
                បន្ថែមម៉ាស៊ីន
            </Button>
        }
        return <></>;
    }
    return (
        <>
            <Form name="createCasinoEquipmentMasterForm" layout="vertical" className="p-5" form={form}>
                <Form.Item name="code" hidden={true}>
                    <Input />
                </Form.Item>
                <Form.Item name="requestLicenseId" hidden={true}>
                    <Input />
                </Form.Item>
                <Form.Item name="casinoId" hidden={true}>
                    <Input />
                </Form.Item>
                <Form.Item name="requestGameRuleId" hidden>
                    <Input />
                </Form.Item>
                <Form.Item name="id" hidden>
                    <Input />
                </Form.Item>
                <Tabs
                    defaultActiveKey="1"
                    items={[
                        {
                            key: "1",
                            label: "Info",
                            children: (<>
                                <div className="grid grid-cols-3 gap-2">
                                    <Form.Item label="Game Rule" name="gameRule">
                                        <Input />
                                    </Form.Item>
                                    <Form.Item label="Casino" name="casino">
                                        <Input />
                                    </Form.Item>
                                    {/* <Form.Item label="Application form Tracking Code" name="documentCode">
                                        <Input />
                                    </Form.Item>
                                    <Form.Item label="Application Date" name="applicationDate">
                                        <DatePicker />
                                    </Form.Item> */}
                                    <Form.Item label="Register Date" name="registeredDate">
                                        <DatePicker showTime={{ defaultValue: dayjs('00:00:00', 'HH:mm:ss') }} />
                                    </Form.Item>
                                </div>
                            </>)
                        },
                        {
                            key: "2",
                            label: "Equipments",
                            children: (<>
                                {
                                    !equipmentType &&
                                    <Space>
                                        <Button className="mb-2" onClick={() => setTableVisible(true)}>
                                            បន្ថែមតុ
                                        </Button>
                                        <Button className="mb-2" onClick={() => setMachineVisible(true)}>
                                            បន្ថែមម៉ាស៊ីន
                                        </Button>
                                    </Space>
                                }
                                {
                                    renderTerminalButton()
                                }
                                <Form.Item shouldUpdate={(preValues, curValues) =>
                                    preValues.equipments !==
                                    curValues.equipments
                                }>
                                    {({ getFieldValue }: any) => {
                                        const equipments: any[] =
                                            getFieldValue(
                                                "equipments"
                                            ) || [];

                                        return (
                                            <Table size="small" rowKey="id" dataSource={equipments} columns={casinoEquipmentDetailColumns} />
                                        );
                                    }}
                                </Form.Item>

                            </>)
                        },


                    ]} />
            </Form >
            <Modal open={tableVisible}
                width="90%"
                onCancel={onCloseTerminal}
                onOk={() => { tableForm.submit(); setTableVisible(false); }}>
                <TableEquipomentForm gameRules={gameRuleOptions} currencies={currencyOptions} equipmentTypes={equpmentTypeOptions} form={tableForm} />
            </Modal>
            <Modal open={machineVisible}
                width="90%"
                onCancel={onCloseTerminal}
                onOk={() => { machineForm.submit(); setMachineVisible(false); }}>
                <MachineEquipomentForm gameRules={gameRuleOptions} currencies={currencyOptions} equipmentTypes={equpmentTypeOptions} form={machineForm} />
            </Modal>
        </>
    )
}