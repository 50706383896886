import { call, put, takeLatest, all, select } from "redux-saga/effects";
import ApiEndpoint from "Utility/Endpoint";
import Request from "Utility/Http";
import { DELETE, GET, POST } from "Utility/Constant";
import { v4 as uuid } from "uuid";
import { ShowMessage } from "Common/Saga";

import { AsyncEndAction, AsyncStartAction } from "Modules/Application/Actions";
import { MakeCasinoEquipmentMasterIdSelector, MakeCasinoEquipmentMasterValueSelector, MakeEquipmentDetailIdSelector, MakeEquipmentDetailValueSelector } from "./Selectors";

import { CREATE_CASINO_EQUIPMENT_MASTER_REQUEST, CREATE_EQUIPMENT_DETAIL_REQUEST, DELETE_EQUIPMENT_DETAIL_REQUEST, GET_GAME_RULE_EQUIPMENT_MASTER_REQUEST, GET_REQUEST_CASINO_EQUIPMENT_MASTER_REQUEST } from "./Constants";
import { GetGameRuleEquipmentMasterSuccessAction, GetRequestCasinoEquipmentMasterSuccessAction } from "./Actions";

function* CreateEquipmentCasino() {
    const model = yield select(MakeCasinoEquipmentMasterValueSelector());
    try {
        yield put(AsyncStartAction());
        const url = ApiEndpoint.CASINO_EQUIPMENT_MASTER_PATH;
        const payload = ApiEndpoint.makeApiPayload(url, POST, model);
        const response = yield call(Request, payload);

        if (response && !response.snack && !response.status) {
            yield ShowMessage({
                type: "success",
                message: "ទិន្នន័យបានរក្សារទុក",
                content: "",
                id: uuid(),
            });
        }
    }
    catch {
        yield ShowMessage({
            type: "error",
            message: "មានបញ្ហាពេលរក្សារទុក",
            content: "",
            id: uuid(),
        })
    }
    finally {
        yield put(AsyncEndAction());
    }
}

function* GetEquipmentCasino() {
    const model = yield select(MakeCasinoEquipmentMasterIdSelector());

    try {
        yield put(AsyncStartAction());
        const url = `${ApiEndpoint.CASINO_EQUIPMENT_MASTER_PATH}/request/${model}`;
        const payload = ApiEndpoint.makeApiPayload(url, GET, model);
        const response = yield call(Request, payload);

        if (response && !response.snack && !response.status) {
            yield put(GetRequestCasinoEquipmentMasterSuccessAction(response));
        }
    }
    catch {
        yield ShowMessage({
            type: "error",
            message: "ទិន្នន័យរកមិនឃើញ",
            content: "",
            id: uuid(),
        })
    }
    finally {
        yield put(AsyncEndAction());
    }
}

function* GetEquipmentCasinoGameRule() {
    const model = yield select(MakeCasinoEquipmentMasterIdSelector());

    try {
        yield put(AsyncStartAction());
        const url = `${ApiEndpoint.CASINO_EQUIPMENT_MASTER_PATH}/game-rule/${model}`;
        const payload = ApiEndpoint.makeApiPayload(url, GET, model);
        const response = yield call(Request, payload);

        // if (response && !response.snack && !response.status) {

        // }
        yield put(GetGameRuleEquipmentMasterSuccessAction(response));
    }
    catch {
        yield ShowMessage({
            type: "error",
            message: "ទិន្នន័យរកមិនឃើញ",
            content: "",
            id: uuid(),
        })
    }
    finally {
        yield put(AsyncEndAction());
    }
}

function* CreateEquipmentDetail() {
    const model = yield select(MakeEquipmentDetailValueSelector());
    try {
        yield put(AsyncStartAction());
        const url = ApiEndpoint.CASINO_EQUIPMENT_DETAIL_PATH;
        const payload = ApiEndpoint.makeApiPayload(url, POST, model);
        const response = yield call(Request, payload);

        if (response && !response.snack && !response.status) {
            yield ShowMessage({
                type: "success",
                message: "ទិន្នន័យបានរក្សារទុក",
                content: "",
                id: uuid(),
            });
        }
    }
    catch {
        yield ShowMessage({
            type: "error",
            message: "មានបញ្ហាពេលរក្សារទុក",
            content: "",
            id: uuid(),
        })
    }
    finally {
        yield put(AsyncEndAction());
    }
}

function* DeleteEquipmentDetail() {
    const model = yield select(MakeEquipmentDetailIdSelector());

    try {
        yield put(AsyncStartAction());
        const url = `${ApiEndpoint.CASINO_EQUIPMENT_DETAIL_PATH}/${model}`;
        const payload = ApiEndpoint.makeApiPayload(url, DELETE, model);
        const response = yield call(Request, payload);

        if (response && !response.snack && !response.status) {
            yield put(GetRequestCasinoEquipmentMasterSuccessAction(response));
        }
    }
    catch {
        yield ShowMessage({
            type: "error",
            message: "ទិន្នន័យរកមិនឃើញ",
            content: "",
            id: uuid(),
        })
    }
    finally {
        yield put(AsyncEndAction());
    }
}

export default function* EquipmentCasinoSaga() {
    yield all([
        yield takeLatest(CREATE_CASINO_EQUIPMENT_MASTER_REQUEST, CreateEquipmentCasino),
        yield takeLatest(GET_REQUEST_CASINO_EQUIPMENT_MASTER_REQUEST, GetEquipmentCasino),
        yield takeLatest(GET_GAME_RULE_EQUIPMENT_MASTER_REQUEST, GetEquipmentCasinoGameRule),
        yield takeLatest(CREATE_EQUIPMENT_DETAIL_REQUEST, CreateEquipmentDetail),
        yield takeLatest(DELETE_EQUIPMENT_DETAIL_REQUEST, DeleteEquipmentDetail),
    ]);
}