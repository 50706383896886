import { useState,useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {v4 as uuid}  from "uuid";

import { FullLayout } from "Components/Layouts";
import { ArchiveIcon } from "@heroicons/react/outline";
import { HelmetHeader } from "Components/Helmet-Header";
import {
    Button,
    Space,
    QRCode,
    Image,
    Tabs,
    Form,
} from "antd";
import QrReader from "react-qr-reader";
import dayjs from "dayjs";

import Reducer from "Modules/Entity/Operator/Reducer";
import Saga from "Modules/Entity/Operator/Saga";
import CompanyReducer from "Modules/Entity/Company/Reducer";
import CompanySaga from "Modules/Entity/Company/Saga";

import { ShowMessage } from "Common/Saga";
import { GetEquipmentByVersionIdService, GetInspectionCertificateByVersionIdService } from "./Service";
import { GetCompanyService } from "Modules/Entity/Company/Service";
import { GetEntityCompanyByIdRequestAction, GetEntityCompanyByIdSuccessAction, SelectCompanySuccessAction, SetCompanyValueAction } from "Modules/Entity/Company/Actions";
import { GetCasinoTypeService, GetOperatorByIdService } from "Modules/Entity/Operator/Service";
import { SelectableCasinoTypeSuccessAction } from "Modules/License/Casino/Actions";
import { CasinoTabDetailForm } from "./Forms/CasinoTabDetailForm";
import { CompanyTabDetailForm } from "./Forms/CompanyTabDetailForm";
import { MakeCasinoSelector } from "Modules/Entity/Operator/Selectors";
import { SetFormValuesAction } from "Modules/Settings/GameType/Actions";
import { GetEntityCasinoByIdRequestAction, GetEntityCasinoByIdSuccessAction } from "Modules/Entity/Operator/Actions";
import { useInjectReducer } from "Utility/InjectReducer";
import { useInjectSaga } from "Utility/InjectSaga";
import MakeSelectEntityCompanyListPage from "Modules/Entity/Company/Selectors";
import { EquipmentPictureForm } from "Modules/Equipments/Casino/Binding/Forms/EquipmentPictureForm";

const key = "entityCasino";
const companyKey = "entityCompany";
export const CerticiateCasinoVerifyPage = () => {
    useInjectReducer({ key: key, reducer: Reducer });
    useInjectSaga({ key: key, saga: Saga });
    useInjectReducer({ key: companyKey, reducer: CompanyReducer });
    useInjectSaga({ key: companyKey, saga: CompanySaga });

    const dispatch = useDispatch();
    const [qrScannerVisible, setQrScannerVisible] = useState<boolean>(false);
    const [qrCodeData, setQrCodeData] = useState<string>(null);
    const [equipment, setEquipoment] = useState<any>(null);
    const [inspectionDetail, setInspectionDetail] = useState<any>(null);
    const casino = useSelector(MakeCasinoSelector());
    console.log("inspectionDetail", inspectionDetail);
    const { company } = useSelector(
        MakeSelectEntityCompanyListPage()
    );
    const [form] = Form.useForm();

    // fields
    const casinoTabs = [
        {
            key: "1",
            label: "ព័ត៌មាន",
            children: <div>
                <div className="w-full flex mt-4 border-b-2 border-green-900">
                    
                </div>
                    { equipment && <>
                    <div className="grid grid-cols-3 py-6 px-4 gap-4 bg-slate-50">
                        <div className="flex flex-col gap-1">
                            <p className="text-sm text-green-900 ">
                                នាមករណ៍កាស៊ីណូ
                            </p>
                            <p className="text-sm text-green-900">Name of Casino</p>
                        </div>
                        <div className="flex flex-col gap-1">
                            <p className="text-sm text-green-900 ">
                                {equipment.casinoNameKh}
                            </p>
                            <p className="text-sm text-green-900">
                                {equipment.casinoName}
                            </p>
                        </div>
                    </div>
                    <div className="grid grid-cols-3 py-6 px-4 gap-4">
                        <div className="flex flex-col gap-1">
                            <p className="text-sm text-green-900 ">
                                លេខសម្គាល់ឧបករណ៍
                            </p>
                            <p className="text-sm text-green-900">Terminal/Equpment Id</p>
                        </div>
                        <div className="flex flex-col gap-1">
                            <p className="text-sm text-green-900 ">
                                {equipment.terminalId}
                            </p>                                        
                        </div>
                    </div>
                    <div className="grid grid-cols-3 py-6 px-4 gap-4">
                        <div className="flex flex-col gap-1">
                            <p className="text-sm text-green-900 ">
                                លេខ Serial
                            </p>
                            <p className="text-sm text-green-900">Serial Number</p>
                        </div>
                        <div className="flex flex-col gap-1">
                            <p className="text-sm text-green-900 ">
                                {equipment.serialNumber}
                            </p>                                        
                        </div>
                    </div>
                    <div className="grid grid-cols-3 py-6 px-4 gap-4 bg-slate-50">
                        <div className="flex flex-col gap-1">
                            <p className="text-sm text-green-900 ">
                                ទីតាំង
                            </p>
                            <p className="text-sm text-green-900">Zone</p>
                        </div>
                        <div className="flex flex-col gap-1">
                            <p className="text-sm text-green-900 ">
                                {equipment.zoneName}
                            </p>
                        </div>
                    </div>
                    <div className="grid grid-cols-3 py-6 px-4 gap-4 bg-slate-50">
                        <div className="flex flex-col gap-1">
                            <p className="text-sm text-green-900 ">
                                ប្រភេទលតាបត្រ
                            </p>
                            <p className="text-sm text-green-900">Equipment Type</p>
                        </div>
                        <div className="flex flex-col gap-1">
                            <p className="text-sm text-green-900 ">
                                {equipment.equipmentTypeName}
                            </p>
                        </div>
                    </div>
                    <div className="grid grid-cols-3 py-6 px-4 gap-4">
                        <div className="flex flex-col gap-1">
                            <p className="text-sm text-green-900 ">
                                វិញ្ញាបនបត្រនេះមានសុពលភាពរហូតដល់
                            </p>
                            <p className="text-sm text-green-900">
                                This Certificate is valid until
                            </p>
                        </div>
                        <div className="flex flex-col gap-1">
                            {dayjs(equipment.validedDate).format("DD-MM-YYYY")}
                        </div>
                    </div>
                    <div className="w-full p-4">
                        <h2 className="py-4 text-lg font-bold">រូបភាព</h2>
                        <div className="flex flex-row flex-wrap gap-4">
                                    <Image.PreviewGroup
                                        preview={{
                                        onChange: (current, prev) => console.log(`current index: ${current}, prev index: ${prev}`),
                                        }}
                                    >
                                    {equipment.pictures && equipment.pictures.map((picture: any) => <EquipmentPictureForm key={picture.id} picture={picture} />)}
                                    </Image.PreviewGroup>

                        </div>
                    </div>
                    </>
                }
            </div>,
        },
        
        {
            key: "2",
            label: "ព័ត៌មានកាស៊ីណូ",
            children: <CasinoTabDetailForm />,
        },
        {
            key: "3",
            label: "ព័ត៌មានលម្អិតពីក្រុមហ៊ុន",
            children: <CompanyTabDetailForm />,
        }
    ]

    //constructors

    useEffect(() => {
        if (company && company !== null) {
            const companyModel = company?.company;
            const formData = {
                company: {
                    name: companyModel?.name,
                    latinName: companyModel?.latinName,
                    registrationNumber: companyModel?.registrationNumber,
                    taxIdentificationNumber:
                        companyModel?.taxIdentificationNumber,
                    nationalId: `${companyModel?.nationalId}`,
                    registrationOffice: companyModel?.registrationOffice,
                    address: companyModel?.address,
                    propertyStatus: companyModel?.propertyStatus,
                    mobilePhoneNumber: companyModel?.mobilePhoneNumber,
                    telephoneNumber: companyModel?.telephoneNumber,
                    emailAddress: companyModel?.emailAddress,
                    websiteAddress: companyModel?.websiteAddress,
                    gAPFirstname: companyModel.companyData?.gAPFirstname,
                    gAPLastname: companyModel.companyData?.gAPLastname,
                },
            };
            form.setFieldsValue({
                // companyId: companyModel?.id,
                formData: formData,
            });
        }

        return () => {
            dispatch(SetFormValuesAction(null));
            dispatch(GetEntityCasinoByIdSuccessAction(null));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [company]);

    useEffect(() => {
        if (casino) {
          const model = {
              ...casino.casino,
              companyId: casino.casino.companyId.toUpperCase(),
              casinoData: casino.casinoData
          };

            form.setFieldsValue(model);
            dispatch(SetCompanyValueAction(model.companyId));
            dispatch(GetEntityCompanyByIdRequestAction());
        }
        return () => {
            dispatch(SetCompanyValueAction(null));
            dispatch(GetEntityCompanyByIdSuccessAction(null));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [casino])

    const onQRScanError = (error: any) => {
        dispatch(ShowMessage({
            type: "error",
            message: "ខុសឈ្មោះអ្នកប្រើ ឬលេខសម្ងាត់",
            content: "",
            id: uuid(),
        }));
    }

    const onQRScanComplete = (data: any) => {
        if (data) {
            dispatch(SetCompanyValueAction(null));
            dispatch(GetEntityCompanyByIdSuccessAction(null));
            dispatch(SetFormValuesAction(null));
            dispatch(GetEntityCasinoByIdSuccessAction(null));
            var versionId = data.split('/').pop();
            
                GetEquipmentByVersionIdService(versionId, dispatch)
                .then((equipmentRs:any) => {
                    
                    if(equipmentRs && !equipmentRs.status) {
                        setInspectionDetail(null);
                        setEquipoment(equipmentRs);
                        GetCompanyService(dispatch)
                            .then((companies: any) => {
                            if(companies) {
                                dispatch(SelectCompanySuccessAction(companies));
                            }
                            GetCasinoTypeService(dispatch)
                            .then((casinoTypes: any) => {
                                dispatch(SelectableCasinoTypeSuccessAction(casinoTypes));
                                if (equipmentRs.casinoId) {
                                    
                                    dispatch(SetFormValuesAction(equipmentRs.casinoId));
                                    dispatch(GetEntityCasinoByIdRequestAction());
                                }
                            });
                        });
                    }
                    
                })
                .catch((ex: any) => {
                    setEquipoment(null);
                    GetInspectionCertificateByVersionIdService(versionId, dispatch).then((inspectionDetailRs: any) => {
                        setInspectionDetail(inspectionDetailRs);
                    });
                });
            
            setQrCodeData(data);
            setQrScannerVisible(false);
        }
    }
    

    return (
        <>
            <FullLayout
                icon={<ArchiveIcon />}
                title="ផ្ទៀងផ្ទាត់លតាបត្រ ឧបករណ៍/កម្មវិធីល្បែង"
                descriptions=""
                redirectUrl=""
                actions={<Button size="large" type="primary" onClick={() => setQrScannerVisible(!qrScannerVisible)}>
                    ស្កេន
                </Button>}
            >
                <HelmetHeader meta="ភ្ជាប់លតាបត្រ ឧបករណ៍/កម្មវិធីល្បែង" />
                
                <div className="w-full flex flex-col items-center">
                    
                    {qrScannerVisible && < QrReader
                        // constraints={
                        //     { facingMode: cameraView }
                        // }
                        facingMode={"environment"}
                        delay={1000}
                        onError={onQRScanError}
                        onScan={onQRScanComplete}
                        // chooseDeviceId={()=>selected}
                        style={{ width: "300px" }}
                    />}
                    {
                        qrCodeData && <Space direction="vertical">
                        {/* <QRCode value={qrCodeData}></QRCode> */}
                            <p className="p-4">{qrCodeData}</p>
                        </Space>
                    }

                    {
                        qrCodeData && equipment &&  (
                            <Form
                                name="entityCasinoForm"
                                layout="vertical"
                                className="w-full p-4"
                                form={form}
                                initialValues={{ name: "", lastinName: "" }}
                            >
                                <Tabs className="min-w-[600px] p-4" items={casinoTabs}/>
                            </Form>
                        )
                    }
                    {
                        qrCodeData && inspectionDetail && (
                        
                            <div className="flex flex-col py-6 px-4 gap-4 bg-slate-50">
                                
                                <div className="grid grid-cols-3 py-6 px-4 gap-4">
                                    <div className="flex flex-col gap-1">
                                        <p className="text-sm text-green-900 ">
                                            លេខ Serial
                                        </p>
                                        <p className="text-sm text-green-900">Serial Number</p>
                                    </div>
                                    <div className="flex flex-col gap-1">
                                        <p className="text-sm text-green-900 ">
                                            {inspectionDetail.serialNumber}
                                        </p>                                        
                                    </div>
                                </div>
                                <div className="grid grid-cols-3 py-6 px-4 gap-4 bg-slate-50">
                                    <div className="flex flex-col gap-1">
                                        <p className="text-sm text-green-900 ">
                                            ប្រភេទលតាបត្រ
                                        </p>
                                        <p className="text-sm text-green-900">Equipment Type</p>
                                    </div>
                                    <div className="flex flex-col gap-1">
                                        <p className="text-sm text-green-900 ">
                                            {inspectionDetail.equipmentTypeName}
                                        </p>
                                    </div>
                                </div>
                                <div className="grid grid-cols-3 py-6 px-4 gap-4">
                                    <div className="flex flex-col gap-1">
                                        <p className="text-sm text-green-900 ">
                                            វិញ្ញាបនបត្រនេះមានសុពលភាពរហូតដល់
                                        </p>
                                        <p className="text-sm text-green-900">
                                            This Certificate is valid until
                                        </p>
                                    </div>
                                    <div className="flex flex-col gap-1">
                                        {dayjs(inspectionDetail.validedDate).format("DD-MM-YYYY")}
                                    </div>
                                </div>
                                
                            </div>
                        )
                    }
                </div>
            </FullLayout>
        </>
    )
}