export const GameRuleConstants = {
    SET_FORM_VALUES: "SET_FORM_VALUES",
    QUERY_GAME_RULE_REQUEST: "QUERY_GAME_RULE_REQUEST",
    QUERY_GAME_RULE_SUCCESS: "QUERY_GAME_RULE_SUCCESS",
    QUERY_GAME_RULE_ERROR: "QUERY_GAME_RULE_ERROR",
    CREATE_GAME_RULE_REQUEST: "CREATE_GAME_RULE_REQUEST",
    CREATE_GAME_RULE_SUCCESS: "CREATE_GAME_RULE_SUCCESS",
    CREATE_GAME_RULE_ERROR: "CREATE_GAME_RULE_ERROR",
};

export const SET_GAME_RULE_FILTER_VALUE = "SET_GAME_RULE_FILTER_VALUE";
export const SELECT_GAME_RULE_REQUEST = "SELECT_GAME_RULE_REQUEST";
export const SELECT_GAME_RULE_SUCCESS = "SELECT_GAME_RULE_SUCCESS";

export const SET_GAME_RULE_ID = "SET_GAME_RULE_ID";
export const GET_GAME_RULE_REQUEST = "GET_GAME_RULE_REQUEST";
export const GET_GAME_RULE_SUCCESS = "GET_GAME_RULE_SUCCESS";
export const UPDATE_GAME_RULE_REQUEST = "UPDATE_GAME_RULE_REQUEST";
