import { createSelector } from "reselect";
import { CasinoLicenseInitialState } from "Modules/License/Casino/Reducer";

const CertificateGameTypeDomain = (state: any) =>
    state.certificateGameType || CasinoLicenseInitialState;

const MakeRequestGameTypeFilterValueSelector = () =>
    createSelector(
        CertificateGameTypeDomain,
        (substate) => substate.filterValues
    );

const MakeListRequestGameTypeSelector = () =>
    createSelector(
        CertificateGameTypeDomain,
        (substate) => substate.gameTypeList
    );
const MakeRequestGameTypeValueSelector = () =>
    createSelector(
        CertificateGameTypeDomain,
        (substate) => substate.formValues
    );

const MakeRequestGameTypeIdSelector = () =>
    createSelector(CertificateGameTypeDomain, (substate) => substate.id);

const MakeRequestGameTypeDetailSelector = () =>
    createSelector(
        CertificateGameTypeDomain,
        (substate) => substate.requestGameTypeDetail
    );
const MakeApproveCertificateGameTypeSelector = () => createSelector(CertificateGameTypeDomain, substate => substate.requestGameTypeApprove);

const MakeCertificateGameTypeValueSelector = () => createSelector(CertificateGameTypeDomain, substate => substate.certificateGameTypeValue);

const MakeCertificateFilterValueSelector = () => createSelector(CertificateGameTypeDomain, substate => substate.certificateFilterValue);

export default CertificateGameTypeDomain;
export {
    MakeRequestGameTypeFilterValueSelector,
    MakeListRequestGameTypeSelector,
    MakeRequestGameTypeIdSelector,
    MakeRequestGameTypeDetailSelector,
    MakeRequestGameTypeValueSelector,
    MakeCertificateGameTypeValueSelector,
    MakeApproveCertificateGameTypeSelector, MakeCertificateFilterValueSelector
};
