import produce from "immer";
import {
    SET_REQUEST_GAME_EQUIPMENT_FILTER_VALUE,
    LIST_REQUEST_GAME_EQUIPMENT_SUCCESS,
    LIST_CERTIFICATE_GAME_EQUIPMENT_SUCCESS,
    SET_REQUEST_GAME_EQUIPMENT_VALUE,
    SET_REQUEST_GAME_EQUIPMENT_ID,
    GET_REQUEST_GAME_EQUIPMENT_SUCCESS,
} from "Modules/Certificate/Casino/ApplicationForm/GameEquipment/Constants";

export const FilterFormCode = "C004";

export const EmptyFields = {
    formTypeCode: "C004",
};
export const initialState = {
    initialValues: EmptyFields,
    requestGameEquipmentList: [],
    certificateGameRuleList: [],
    formValues: {},
    requestFormValue: null,
    filterValues: {},
    errors: [],
    id: null,
    requestGameEquipmentDetail: null,
};

const RequestGameEquipmentReducer = produce((draft, action) => {
    switch (action.type) {
        case SET_REQUEST_GAME_EQUIPMENT_FILTER_VALUE:
            draft.filterValues = action.values;
            break;
        case SET_REQUEST_GAME_EQUIPMENT_ID:
            draft.id = action.id;
            break;
        case SET_REQUEST_GAME_EQUIPMENT_VALUE:
            draft.requestFormValue = action.values;
            break;
        case GET_REQUEST_GAME_EQUIPMENT_SUCCESS:
            draft.requestGameEquipmentDetail = action.requestGameEquipment;
            break;
        case LIST_REQUEST_GAME_EQUIPMENT_SUCCESS:
            draft.requestGameEquipmentList = action.requestGameEquipmentList;
            break;
        case LIST_CERTIFICATE_GAME_EQUIPMENT_SUCCESS:
            draft.certificateGameRuleList = action.certificateGameRuleList;
            break;

        default:
            break;
    }
}, initialState);
export default RequestGameEquipmentReducer;
