export interface CasinoEquipmentDetailType {
    terminalId: string;
    gameRuleId: string;
    gameTypeId: string;
    zoneId: string;
    seat: number;
    currency: string;
    minimumBet: number;
    maximumBet: number;
    note: string;
}

export const SET_CASINO_EQUIPMENT_MASTER_VALUE = "SET_CASINO_EQUIPMENT_MASTER_VALUE";
export const CREATE_CASINO_EQUIPMENT_MASTER_REQUEST = "CREATE_CASINO_EQUIPMENT_MASTER_REQUEST";
export const SET_CASINO_EQUIPMENT_MASTER_ID = "SET_CASINO_EQUIPMENT_MASTER_ID";
export const SET_CASINO_EQUIPMENT_MASTER_REDIRECT = "SET_CASINO_EQUIPMENT_MASTER_REDIRECT";
export const GET_REQUEST_CASINO_EQUIPMENT_MASTER_REQUEST = "GET_REQUEST_CASINO_EQUIPMENT_MASTER_REQUEST";
export const GET_REQUEST_CASINO_EQUIPMENT_MASTER_SUCCESS = "GET_REQUEST_CASINO_EQUIPMENT_MASTER_SUCCESS";

export const GET_GAME_RULE_EQUIPMENT_MASTER_REQUEST = "GET_GAME_RULE_EQUIPMENT_MASTER_REQUEST";
export const GET_GAME_RULE_EQUIPMENT_MASTER_SUCCESS = "GET_GAME_RULE_EQUIPMENT_MASTER_SUCCESS";

export const SET_EQUIPMENT_FOR_OPERATOR_ID = "SET_EQUIPMENT_FOR_OPERATOR_ID";
export const GET_EQUIPMENT_FOR_OPERATOR_REQUEST = "GET_EQUIPMENT_FOR_OPERATOR_REQUEST";
export const GET_EQUIPMENT_FOR_OPERATOR_SUCCESS = "GET_EQUIPMENT_FOR_OPERATOR_SUCCESS";


export const SET_EQUIPMENT_DETAIL_ID = "SET_EQUIPMENT_DETAIL_ID";
export const SET_EQUIPMENT_DETAIL_VALUE = "SET_EQUIPMENT_DETAIL_VALUE";
export const CREATE_EQUIPMENT_DETAIL_REQUEST = "CREATE_EQUIPMENT_DETAIL_REQUEST";
export const DELETE_EQUIPMENT_DETAIL_REQUEST = "DELETE_EQUIPMENT_DETAIL_REQUEST";
export const DELETE_EQUIPMENT_DETAIL_SUCCESS = "DELETE_EQUIPMENT_DETAIL_SUCCESS";