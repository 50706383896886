import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useInjectReducer } from "Utility/InjectReducer";
import { useInjectSaga } from "Utility/InjectSaga";

import {
    QRCode
} from "antd";

import Reducer from "Modules/Settings/InspectionCertificateMaster/Reducer";
import Saga from "Modules/Settings/InspectionCertificateMaster/Saga";
import {
    MakeInspectionCertificatePrintDetailSelector
}
    from "Modules/Settings/InspectionCertificateMaster/Selectors";
import { PrintInspectionCertificatePrintDetailRequestAction, SetInspectionCertificateDetailIdAction } from "../Actions";

const key = "inspectionCertificateMaster";
export const InspectionCertificatePrint = () => {
    useInjectReducer({ key, reducer: Reducer });
    useInjectSaga({ key, saga: Saga });
    //fields
    const dispatch = useDispatch();
    const { id } = useParams();
    const detail = useSelector(MakeInspectionCertificatePrintDetailSelector());
    const [certificatePreview, setCertificatePreview] = useState<any>(null);
    console.log("Test", id);
    // cosntructor
    useEffect(() => {
        if (id) {
            dispatch(SetInspectionCertificateDetailIdAction(id));
            dispatch(PrintInspectionCertificatePrintDetailRequestAction());
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    useEffect(() => {
        if (detail) {
            const printSamples = JSON.parse(detail.inspectionCertificateType.printSamples);
            setCertificatePreview(printSamples.url);
        }


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [detail]);

    return (
        <div className="inspection-certificate-print-preview "
            style={{
                backgroundImage: `url(${certificatePreview})`
            }}
        >
            {detail && <>
                <QRCode className="relative" value={`https://qr.cgmc.gov.kh/inspection-certificate/${detail.versionGuid}`}
                    size={detail?.inspectionCertificateType?.printWidth}
                    style={{
                        top: detail?.inspectionCertificateType?.y,
                        left: detail?.inspectionCertificateType?.x,
                    }}
                />
                <span
                    className="relative block text-center text-2xl font-bold"
                    style={{
                        top: detail?.inspectionCertificateType?.y + 60,
                        left: detail?.inspectionCertificateType?.x,
                        width: detail?.inspectionCertificateType?.printWidth
                    }}
                >
                    SN022300000123
                </span></>}

        </div>

    )
}