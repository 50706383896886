/**
 * PermissionDeniedPage
 *
 * This is the page we show when the user visits a url that doesn't have permission to a route
 */
import React from "react";
import { Link } from "react-router-dom";
import { Result } from "antd";
// import { FormattedMessage } from "react-intl";
import messages from "Components/PermissionDenied/Messages";

/**
 * @return {boolean}
 */

export const PermissionDenied = () => {
    return (
        <div className="mh-100">
            <Result
                status="403"
                title={messages.header.defaultMessage}
                subTitle={messages.message.defaultMessage}
                extra={
                    <Link
                        className="inline-flex items-center justify-center px-4 py-2 mx-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500"
                        to="/signin"
                    >
                        {messages.back.defaultMessage}
                    </Link>
                }
            />
        </div>
    );
};
