import React from "react";
import { Link } from "react-router-dom";
import Logo from "Assets/Images/cmgc-logo.png";
export const NotFound = () => {
    return (
        <>
            <div className="min-h-full pt-16 pb-12 flex flex-col bg-white">
                <main className="flex-grow flex flex-col justify-center max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="flex-shrink-0 flex justify-center">
                        <span className="inline-flex">
                            <span className="sr-only">Workflow</span>
                            <img className="h-24 w-auto" src={Logo} alt="" />
                        </span>
                    </div>
                    <div className="py-16">
                        <div className="text-center">
                            <h1 className="mt-2 text-lg font-extrabold text-gray-900 tracking-tight">
                                សូមទោសទំព័រដែលលោកអ្នកចុចមិនមាននៅក្នុងប្រព័ន្ធនោះទេ
                            </h1>
                            <div className="mt-6">
                                <Link
                                    to="/"
                                    className="text-base font-medium text-indigo-600 hover:text-indigo-500"
                                >
                                    ត្រលប់ទៅទំព័រដើម
                                    <span aria-hidden="true"> &rarr;</span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </>
    );
};
