import { call, put, all, select, takeLatest } from "redux-saga/effects";
import { v4 as uuid } from "uuid";
import { POST, GET, PUT } from "Utility/Constant";
import ApiEndpoint from "Utility/Endpoint";
import Request from "Utility/Http";

import { ShowAlert, ShowMessage } from "Common/Saga";

import { AsyncStartAction, AsyncEndAction } from "Modules/Application/Actions";
import {
    MakeRequestGameEquipmentFilterValueSelector,
    MakeRequestGameEquipmentValueSelector,
    MakeRequestGameEquimentIdSelector,
} from "Modules/Certificate/Casino/ApplicationForm/GameEquipment/Selectors";
import {
    LIST_CERTIFICATE_GAME_EQUIPMENT_REQUEST,
    CREATE_REQUEST_GAME_EQUIPMENT_REQUEST,
    LIST_REQUEST_GAME_EQUIPMENT_REQUEST,
    GET_REQUEST_GAME_EQUIPMENT_REQUEST,
    EDIT_REQUEST_GAME_EQUIPMENT_REQUEST,
} from "Modules/Certificate/Casino/ApplicationForm/GameEquipment/Constants";
import {
    ListCertificateGameEquipmentSuccessAction,
    ListRequestGameEquipmentSuccessAction,
    GetRequestGameEquipmentSuccessAction,
} from "Modules/Certificate/Casino/ApplicationForm/GameEquipment/Actions";

export function* ListCertificateGameEquipment(): any {
    yield put(AsyncStartAction());
    const formValues = yield select(
        MakeRequestGameEquipmentFilterValueSelector()
    );

    const requestUrl = `${ApiEndpoint.REQUEST_LICENSE}/lists`;
    const httpPayload = ApiEndpoint.makeApiPayload(
        requestUrl,
        POST,
        formValues
    );
    try {
        const response = yield call(Request, httpPayload);
        yield put(ListCertificateGameEquipmentSuccessAction(response));
        yield put(AsyncEndAction());
    } catch (error: any) {
        yield put(AsyncEndAction());
        if (error.data && error.data.statusCode === 422) {
        }
        return yield ShowAlert("error", error.data.message);
    }
}

export function* ListRequestGameEquipment() {
    yield put(AsyncStartAction());
    const formValues = yield select(
        MakeRequestGameEquipmentFilterValueSelector()
    );

    const requestUrl = `${ApiEndpoint.REQUEST_LICENSE}/lists`;
    const httpPayload = ApiEndpoint.makeApiPayload(
        requestUrl,
        POST,
        formValues
    );
    try {
        const response = yield call(Request, httpPayload);
        yield put(ListRequestGameEquipmentSuccessAction(response));
        yield put(AsyncEndAction());
    } catch (error: any) {
        yield put(AsyncEndAction());
        if (error.data && error.data.statusCode === 422) {
        }
        return yield ShowAlert("error", error.data.message);
    }
}

export function* CreateRequestGameEquipment() {
    yield put(AsyncStartAction());
    const formValues = yield select(MakeRequestGameEquipmentValueSelector());

    const requestUrl = ApiEndpoint.REQUEST_LICENSE;
    const httpPayload = ApiEndpoint.makeApiPayload(
        requestUrl,
        POST,
        formValues
    );
    try {
        const response = yield call(Request, httpPayload);
        yield put(AsyncEndAction());
        if (response)
            yield ShowMessage({
                type: "success",
                message: "ទិន្នយបានរក្សាទុក",
                content: "ទិន្នយបានរក្សាទុក",
                id: uuid(),
                redirectUrl:
                    "/certificate/casino/request-game-equipment/request",
            });
    } catch (error: any) {
        yield put(AsyncEndAction());
        if (error.data && error.data.statusCode === 422) {
        }
        return yield ShowAlert("error", error.data.message);
    }
}

export function* EditRequestGameEquipment() {
    yield put(AsyncStartAction());
    const formValues = yield select(MakeRequestGameEquipmentValueSelector());

    const requestUrl = ApiEndpoint.REQUEST_LICENSE;
    const httpPayload = ApiEndpoint.makeApiPayload(requestUrl, PUT, formValues);
    try {
        const response = yield call(Request, httpPayload);
        yield put(AsyncEndAction());
        if (response)
            yield ShowMessage({
                type: "success",
                message: "ទិន្នយបានរក្សាទុក",
                content: "ទិន្នយបានរក្សាទុក",
                id: uuid(),
                redirectUrl:
                    "/certificate/casino/request-game-equipment/request",
            });
    } catch (error: any) {
        yield put(AsyncEndAction());
        if (error.data && error.data.statusCode === 422) {
        }
        return yield ShowAlert("error", error.data.message);
    }
}

export function* GetRequestGameEquipment(): any {
    yield put(AsyncStartAction());
    const id = yield select(MakeRequestGameEquimentIdSelector());

    const requestUrl = `${ApiEndpoint.REQUEST_LICENSE}/${id}`;
    const httpPayload = ApiEndpoint.makeApiPayload(requestUrl, GET, id);
    try {
        const response = yield call(Request, httpPayload);
        yield put(GetRequestGameEquipmentSuccessAction(response));
        yield put(AsyncEndAction());
    } catch (error: any) {
        yield put(AsyncEndAction());
        if (error.data && error.data.statusCode === 422) {
        }
        return yield ShowAlert("error", error.data.message);
    }
}



export default function* requestGameEquipmentSaga() {
    yield all([
        yield takeLatest(
            LIST_CERTIFICATE_GAME_EQUIPMENT_REQUEST,
            ListCertificateGameEquipment
        ),

        yield takeLatest(
            CREATE_REQUEST_GAME_EQUIPMENT_REQUEST,
            CreateRequestGameEquipment
        ),
        yield takeLatest(
            LIST_REQUEST_GAME_EQUIPMENT_REQUEST,
            ListRequestGameEquipment
        ),
        yield takeLatest(
            GET_REQUEST_GAME_EQUIPMENT_REQUEST,
            GetRequestGameEquipment
        ),
        yield takeLatest(
            EDIT_REQUEST_GAME_EQUIPMENT_REQUEST,
            EditRequestGameEquipment
        )
    ]);
}
