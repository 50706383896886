import { Form, Input, Button } from "antd";
export const FormTypeFilterForm = ({ form }: any) => {
    return (
        <Form name="formTypeFilterForm" layout="vertical" form={form} className="grid grid-cols-4 p-5 gap-2">
            <Form.Item name="search" label="ឈ្មោះ ឬកូដ">
                <Input autoComplete="off" />
            </Form.Item>
            <Form.Item className="flex flex-row items-end">
                <Button htmlType="submit" type="default">
                    ស្វែងរក
                </Button>
            </Form.Item>
        </Form>
    )
}