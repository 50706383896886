import {
    Button,
    Form, 
    Input,
    Select,
} from "antd";

export const OperatorByAreaCodeFilterForm = ({ onFilter }: any) => {
    return <Form className="p-4" name="operatorByAreaCodeFilterForm" layout="inline" onFinish={onFilter}>
        <Form.Item name="search">
            <Input placeholder="Search"/>
        </Form.Item>
        <Form.Item name="areaCode">
            <Select style={{width: 200}} placeholder="តំបន់" showSearch allowClear>
                <Select.Option key="kht" value="kht">តំបន់ព្រំដែនកម្ពុជា-ថៃ (KHT)</Select.Option>
                <Select.Option key="khv" value="sea">តំបន់ព្រំដែនកម្ពុជា-វៀតណាម (KHV)</Select.Option>
                <Select.Option key="sea" value="sea">តំបន់ខេត្តជាប់មានត់សមុទ្រ (sea)</Select.Option>
                <Select.Option key="php" value="sea">តំបន់រាជធានី-ខេត្ត (PHP)</Select.Option>
            </Select>
        </Form.Item>
        <Form.Item>
            <Button htmlType="submit" type="default">
                ស្វែងរក
            </Button>
        </Form.Item>
    </Form>
}