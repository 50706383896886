import React, { useEffect, useTransition, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PlusOutlined } from "@ant-design/icons";
import {
    Form,
    Tabs,
    Row,
    Col,
    Table,
    Input,
    DatePicker,
    Radio,
    Button,
    Space,
    Checkbox,
    Select,
    Divider,
} from "antd";
import type { ColumnsType } from "antd/lib/table";
import { RequestSection } from "Components/Layouts/";
import { CreateCasinoModalForm } from "Modules/Entity/Operator/Forms/CreateCasinoModalForm";
import { BoardOfDirectorType } from "../../../Request/Constants";

import { useInjectSaga } from "Utility/InjectSaga";
import { useInjectReducer } from "Utility/InjectReducer";

import Reducer from "Modules/Entity/Operator/Reducer";
import Saga from "Modules/Entity/Operator/Saga";

import {
    SelectCasinoRequestAction,
    GetCasinoModalDetailAction,
    SetCasinoIdAction,
    GetEntityCasinoByIdSuccessAction,
    SelectCasinoSuccessAction,
} from "Modules/Entity/Operator/Actions";
import MakeSelectCasinoLicensePage from "Modules/License/Casino/Selectors";
import {
    MakeSelectCasinoSelector,
    MakeCasinoSelector,
} from "Modules/Entity/Operator/Selectors";
import { MakeCasinoDetailModalSelector } from "Modules/Entity/Operator/Selectors";
import { NumberToKhCharacter, NumberToCharacter } from "Utility/StringHelper";
import { GetOperatorOptionService } from "Modules/Entity/Operator/Service";

interface RequestFormType {
    form: any;
    setBoardOfDirectorVisibility: any;
    setCapitalShareVisibility: any;
    setCasinoVisibility: any;
}
const key = "entityCasino";
export const RequestCasinoLicenseForm = ({
    form,
    setBoardOfDirectorVisibility,
    setCapitalShareVisibility,
    setCasinoVisibility,
}: RequestFormType) => {
    useInjectReducer({ key: key, reducer: Reducer });
    useInjectSaga({ key: key, saga: Saga });
    //fields
    const dispatch = useDispatch();
    const [isPending, startTransition] = useTransition();
    const casinoOptions = useSelector(MakeSelectCasinoSelector());

    const { casinoTypes, identificationTypes, nationality } = useSelector(
        MakeSelectCasinoLicensePage()
    );
    const casinoDetailModel = useSelector(MakeCasinoDetailModalSelector());
    const casinoModel = useSelector(MakeCasinoSelector());
    const [isDifferentRepresentative, setIsDifferentRepresentative] =
        useState(false);
    const [shareTypeVisibility, setShareTypeVisibility] = useState(false);
    const [casinoVisible, setCasinoVisible] = useState(false);
    const boardOfDirectorColumns: ColumnsType<BoardOfDirectorType> = [
        {
            title: " ល.រ / No.",
            key: "no",
            render: (value, item, index) => index + 1,
        },
        {
            title: "គោត្តនាម និងនាម / Last name and First name",
            dataIndex: "name",
            key: "name",
        },
        {
            title: "តួនាទី / Position",
            dataIndex: "position",
            key: "position",
        },
        {
            title: "សញ្ជាតិ / Nationality",
            dataIndex: "nationality",
            key: "nationality",
        },
        {
            title: "អាសយដ្ឋាន / Address",
            dataIndex: "address",
            key: "address",
        },
    ];

    useEffect(() => {
        GetOperatorOptionService(dispatch).then((casinoOptions: any) => {
            if(casinoOptions) {
                dispatch(SelectCasinoSuccessAction(casinoOptions));
            }
        })
        startTransition(() => {
            form.setFieldsValue({
                formData: {
                    representativeType: false,
                },
            });
        });

        return () => {
            dispatch(GetEntityCasinoByIdSuccessAction(null));
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if(casinoDetailModel) {
            GetOperatorOptionService(dispatch).then((casinoOptions: any) => {
                if(casinoOptions) {
                    dispatch(SelectCasinoSuccessAction(casinoOptions));
                }
            });
        }
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [casinoDetailModel]);

    // methods
    const onApplicantStatusChange = (e: any) => {
        startTransition(() => {
            // form.setFieldsValue({ representativeType: !e.target.value });
            setIsDifferentRepresentative(e.target.value);
        });
    };

    useEffect(() => {
        if (casinoModel) {
            const company = casinoModel.company;

            const casinoData = {
                boardOfDirectors:
                    casinoModel.company.companyData.boardOfDirectors,
                formData: {
                    company: {
                        ...company,
                        nationalId: `${company.nationalId}`,
                    },

                    casinoTypeId: casinoModel.casino.casinoTypeId,
                    gAPFirstname: company.companyData.gAPFirstname,
                    gAPLastname: company.companyData.gAPLastname,
                    casino: casinoModel.casino,
                    singleShareType: company.companyData.singleShareType,
                    singleShareTypeValue: company.companyData.singleShareTypeValue,
                    capital: company.companyData.capital,
                    capitalInWord: company.companyData.capitalInWord,
                    parValue: company.companyData.parValue,
                    mostCapital: company.companyData.mostCapital,
                    mostCapitalValue: company.companyData.mostCapitalValue,
                    isCurrentAsset: company.companyData.isCurrentAsset,
                    currentAssetValue: company.companyData.currentAssetValue,
                    isShareHolder: company.companyData.isShareHolder,
                    shareHolderValue: company.companyData.shareHolderValue,
                    isRealEstate: company.companyData.isRealEstate,
                    realEstateValue: company.companyData.realEstateValue,
                },
            };

            const values = form.getFieldsValue();
            form.setFieldValue({ boardOfDirectors: company?.companyData?.boardOfDirectors || [] });
            form.setFieldsValue({ ...values, ...casinoData });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [casinoModel]);

    const onShareTypeChange = (e: any) => {
        const shareType = e.target.value;
        switch (shareType) {
            case 1:
                setShareTypeVisibility(false);
                form.setFieldsValue({ singleShareTypeValue: "" });
                break;
            case 2:
                setShareTypeVisibility(true);
                form.setFieldsValue({ capitalShares: [] });
                break;
        }
    };

    const onCasinoSubmit = (values: any) => {
        dispatch(SelectCasinoRequestAction());
        setCasinoVisible(false);
    };

    const onCasinoChange = (e: any) => {
        const id = e;
        dispatch(SetCasinoIdAction(id));
        dispatch(GetCasinoModalDetailAction(null));
    };

    return (
        <>
            <Form
                name="requestCasinoLicenseForm"
                form={form}
                initialValues={{
                    applicant: { id: "" },
                    representative: { id: "" },
                    formData: { representativeType: isDifferentRepresentative },
                    boardOfDirector: [],
                }}
            >
                <Form.Item name="casinoId" hidden={true}>
                    <Input />
                </Form.Item>
                <Form.Item name="companyId" hidden={true}>
                    <Input />
                </Form.Item>
                <Tabs 
                    defaultActiveKey="1" 
                    className="p-2" 
                    items={[
                        {
                            key: "1",
                            label: "សម្រាប់មន្រ្តី គ.ល.ក",
                            children: <>
                                <Row gutter={16}>
                                    <Col span={5}>លេខសំគាល់ពាក្យស្នើសុំ</Col>
                                    <Col span={7}>
                                        <Form.Item name="documentCode">
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col span={5}>ឈ្មោះអ្នកទទួល</Col>
                                    <Col span={7}>
                                        <Form.Item name="receiverName">
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col span={5}>លេខកូដចំណូលកាតព្វកិច្ចពីល្បែង</Col>
                                    <Col span={7}>
                                        <Form.Item name="gamblingTaxCode">
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col span={5}>កាលបរិច្ឆេទទទួល</Col>
                                    <Col span={7}>
                                        <Form.Item name="receivedDate">
                                            <DatePicker />
                                        </Form.Item>
                                    </Col>
                                    {/* <Col span={5}>លេខសម្រាប់តាមដានឯកសារ</Col>
                                    <Col span={7}>
                                        <Form.Item name="trackingCode">
                                            <Input />
                                        </Form.Item>
                                    </Col> */}
                                </Row>
                            </>
                        },
                        {
                            key: "2",
                            label: "ព័ត៌មានអ្នកដាក់ពាក្យស្នើសុំ",
                            children: <>
                                <Row>
                                    <Col span={6}>
                                        តួនាទីអ្នកដាកពាក្យស្នើសុំ
                                        <br />
                                        Position of Applicant
                                    </Col>
                                    <Col span={18}>
                                        <Form.Item
                                            name={["formData", "designatureId"]}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Please select one",
                                                },
                                            ]}
                                        >
                                            <Radio.Group>
                                                <Space direction="horizontal">
                                                    <Radio value={1}>
                                                        ប្រធានក្រុមប្រឹក្សាភិបាល / BoD
                                                    </Radio>
                                                    <Radio value={2}>
                                                        អភិបាល / Director
                                                    </Radio>
                                                    <Radio value={3}>នាយក / CEO</Radio>
                                                </Space>
                                            </Radio.Group>
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col span={6}>
                                        គោត្តនាម និងនាម
                                        <br />
                                        Last name and First name
                                    </Col>
                                    <Col span={18}>
                                        <Row>
                                            <Col span={6}>
                                                អក្សរខ្មែរ
                                                <br />
                                                in Khmer
                                            </Col>
                                            <Col span={18}>
                                                <Form.Item
                                                    name={[
                                                        "formData",
                                                        "applicant",
                                                        "fullname",
                                                    ]}
                                                    rules={[{ required: true }]}
                                                >
                                                    <Input />
                                                </Form.Item>
                                            </Col>
                                            <Col span={6}>
                                                អក្សរឡាតាំង
                                                <br />
                                                in Latin
                                            </Col>
                                            <Col span={18}>
                                                <Form.Item
                                                    name={[
                                                        "formData",
                                                        "applicant",
                                                        "latinFullname",
                                                    ]}
                                                    rules={[{ required: true }]}
                                                >
                                                    <Input />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={6}>
                                        ភេទ
                                        <br />
                                        Sex
                                    </Col>
                                    <Col span={18}>
                                        <Form.Item
                                            name={["formData", "applicant", "gender"]}
                                            rules={[{ required: true }]}
                                        >
                                            <Radio.Group>
                                                <Space direction="horizontal">
                                                    <Radio value={1}>
                                                        ប្រុស / Male
                                                    </Radio>
                                                    <Radio value={2}>
                                                        ស្រី / Female
                                                    </Radio>
                                                </Space>
                                            </Radio.Group>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={6}>
                                        ថ្ងៃខែឆ្នាំកំណើត
                                        <br />
                                        Date of Birth
                                    </Col>
                                    <Col span={18}>
                                        <Form.Item
                                            name={[
                                                "formData",
                                                "applicant",
                                                "dateOfBirth",
                                            ]}
                                            rules={[{ required: true }]}
                                        >
                                            <DatePicker placeholder="ថ្ងៃខែឆ្នាំកំណើត" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        សញ្ជាតិ
                                        <br />
                                        Nationality
                                    </Col>
                                    <Col span={18}>
                                        <Form.Item
                                            name={[
                                                "formData",
                                                "applicant",
                                                "nationality",
                                            ]}
                                            rules={[{ required: true }]}
                                        >
                                            <Input placeholder="សញ្ជាតិ / Nationality" />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={8}>
                                        <Form.Item
                                            name={[
                                                "formData",
                                                "applicant",
                                                "identityTypeId",
                                            ]}
                                            rules={[{ required: true }]}
                                        >
                                            <Radio.Group>
                                                <Space direction="vertical">
                                                    {!isPending &&
                                                        identificationTypes &&
                                                        identificationTypes.map(
                                                            (
                                                                identificationType: any,
                                                                index: number
                                                            ) => (
                                                                <Radio
                                                                    key={
                                                                        identificationType.value
                                                                    }
                                                                    value={
                                                                        identificationType.value
                                                                    }
                                                                >
                                                                    {
                                                                        identificationType.label
                                                                    }
                                                                </Radio>
                                                            )
                                                        )}
                                                </Space>
                                            </Radio.Group>
                                        </Form.Item>
                                    </Col>
                                    <Col span={16}>
                                        <Form.Item
                                            name={[
                                                "formData",
                                                "applicant",
                                                "identityNumber",
                                            ]}
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        "Identificaiton number is required",
                                                },
                                            ]}
                                        >
                                            <Input placeholder="សូមបំពេញ" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>ថ្ងៃខែឆ្នាំផ្តល់ / Issued Date</Col>
                                    <Col span={18}>
                                        <Form.Item
                                            name={[
                                                "formData",
                                                "applicant",
                                                "identityIssuedDate",
                                            ]}
                                            rules={[{ required: true }]}
                                        >
                                            <DatePicker />
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        ថ្ងៃខែឆ្នាំផុតកំណត / Date of Expiry
                                    </Col>
                                    <Col span={18}>
                                        <Form.Item
                                            name={[
                                                "formData",
                                                "applicant",
                                                "expiredDate",
                                            ]}
                                            rules={[{ required: true }]}
                                        >
                                            <DatePicker />
                                        </Form.Item>
                                    </Col>

                                    <Col span={6}>អាសយដ្ឋាន / Address</Col>
                                    <Col span={18}>
                                        <Form.Item
                                            name={["formData", "applicant", "address"]}
                                            rules={[{ required: true }]}
                                        >
                                            <Input placeholder="អាសយដ្ឋាន / Address" />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={6}>
                                        ទូរស័ព្ទចល័ត / Mobile Phone Number
                                    </Col>
                                    <Col span={18}>
                                        <Form.Item
                                            name={[
                                                "formData",
                                                "applicant",
                                                "mobilePhoneNumber",
                                            ]}
                                            rules={[{ required: true }]}
                                        >
                                            <Input placeholder="ទូរស័ព្ទចល័ត / Mobile Phone Number" />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={6}>សារអេឡិចត្រូនិច / Email Address</Col>
                                    <Col span={18}>
                                        <Form.Item
                                            name={[
                                                "formData",
                                                "applicant",
                                                "emailAddress",
                                            ]}
                                            rules={[{ required: true }]}
                                        >
                                            <Input placeholder="សារអេឡិចត្រូនិច / Email Address" />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </>
                        },
                        {
                            key: "3",
                            label: "ព័ត៌មានទូទៅរបស់ក្រុមហ៊ុន",
                            children: <>
                                <Row>
                                    <Col span={6}>
                                        កាស៊ីណូកូដ <br />
                                        Code
                                    </Col>
                                    <Col span={18}>
                                        <Form.Item
                                            name="casinoId"
                                            rules={[{ required: true }]}
                                        >
                                            {/* <Input
                                                readOnly={true}
                                                placeholder="នាមករណ៍កាស៊ីណូជាអក្សរខ្មែរ / Casino name in Khmer"
                                            /> */}
                                            <Select
                                                placeholder="ជ្រើសរើសកាស៊ីណូ / Choose Casino"
                                                onChange={onCasinoChange}
                                                filterOption={(input, option) =>
                                                    option!.children
                                                        .join(" ")
                                                        .toLowerCase()
                                                        .includes(input.toLowerCase())
                                                }
                                                dropdownRender={(menu) => (
                                                    <>
                                                        {menu}
                                                        <Divider
                                                            style={{
                                                                margin: "1px 0",
                                                            }}
                                                        />
                                                        <Button
                                                            type="text"
                                                            className="w-full border text-left"
                                                            icon={<PlusOutlined />}
                                                            onClick={() =>
                                                                setCasinoVisible(true)
                                                            }
                                                        >
                                                            បន្ថែមថ្មី
                                                        </Button>
                                                    </>
                                                )}
                                                showSearch
                                            >
                                                {casinoOptions &&
                                                    casinoOptions.map((item) => (
                                                        <Select.Option
                                                            key={item.value}
                                                            value={item.value}
                                                        >
                                                            {item.label}(
                                                            {item.placeholder})
                                                        </Select.Option>
                                                    ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={6}>
                                        នាមករណ៍កាស៊ីណូជាអក្សរខ្មែរ <br />
                                        Casino name in Khmer
                                    </Col>
                                    <Col span={18}>
                                        <Form.Item
                                            name={["formData", "casino", "name"]}
                                            rules={[{ required: true }]}
                                        >
                                            <Input
                                                placeholder="នាមករណ៍កាស៊ីណូជាអក្សរខ្មែរ / Casino name in Khmer"
                                                status="warning"
                                                readOnly
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={6}>
                                        នាមករណ៍កាស៊ីណូជាអក្សរបរទេស <br />
                                        Casino name in Foreign Language
                                    </Col>
                                    <Col span={18}>
                                        <Form.Item
                                            name={["formData", "casino", "latinName"]}
                                            rules={[{ required: true }]}
                                        >
                                            <Input
                                                placeholder="នាមករណ៍កាស៊ីណូជាអក្សរឡាតាំង / Casino name in Latin"
                                                status="warning"
                                                readOnly
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col span={6}>
                                        នាមករណ៍ក្រុមហ៊ុនជាអក្សរខ្មែរ <br />
                                        Company name in Khmer
                                    </Col>
                                    <Col span={18}>
                                        <Form.Item
                                            name={["formData", "company", "name"]}
                                            rules={[{ required: true }]}
                                        >
                                            <Input
                                                placeholder="នាមករណ៍ក្រុមហ៊ុនជាអក្សរខ្មែរ / Company name in Khmer"
                                                status="warning"
                                                readOnly
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={6}>
                                        នាមករណ៍ក្រុមហ៊ុនជាអក្សរឡាតាំង <br />
                                        Company name in Latin
                                    </Col>
                                    <Col span={18}>
                                        <Form.Item
                                            name={["formData", "company", "latinName"]}
                                            rules={[{ required: true }]}
                                        >
                                            <Input
                                                placeholder="នាមករណ៍ក្រុមហ៊ុនជាអក្សរឡាតាំង / Company name in Latin"
                                                status="warning"
                                                readOnly
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                {/* <Row>
                                <Col span={6}>
                                    នាមករណ៍កាស៊ីណូជាអក្សរខ្មែរ <br />
                                    Casino name in Khmer
                                </Col>
                                <Col span={18}>
                                    <Form.Item
                                        name={["formData", "casino", "name"]}
                                        rules={[{ required: true }]}
                                    >
                                        <Input placeholder="នាមករណ៍កាស៊ីណូជាអក្សរខ្មែរ / Casino name in Khmer" />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={6}>
                                    នាមករណ៍កាស៊ីណូជាអក្សរបរទេស <br />
                                    Casino name in Foreign Language
                                </Col>
                                <Col span={18}>
                                    <Form.Item
                                        name={["formData", "casino", "latinName"]}
                                        rules={[{ required: true }]}
                                    >
                                        <Input placeholder="នាមករណ៍កាស៊ីណូជាអក្សរឡាតាំង / Casino name in Latin" />
                                    </Form.Item>
                                </Col>
                            </Row> */}
                                <Row>
                                    <Col span={6}>
                                        នាមករណ៍បុគ្គលទទួលបាន ស.ជ.ណ <br />
                                        name of Person received government
                                        approval-in-Principle
                                    </Col>
                                    <Col span={18}>
                                        <Row>
                                            <Col span={8}>
                                                អក្សរខ្មែរ <br />
                                                in Khmer
                                            </Col>
                                            <Col span={16}>
                                                <Form.Item
                                                    name={["formData", "gAPFirstname"]}
                                                >
                                                    <Input status="warning" readOnly />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col span={8}>
                                                អក្សរឡាតាំង <br />
                                                in Latin
                                            </Col>
                                            <Col span={16}>
                                                <Form.Item
                                                    name={["formData", "gAPLastname"]}
                                                >
                                                    <Input status="warning" readOnly />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={6}>
                                        នាមករណ៍ក្រុមហ៊ុនពីមុន(ប្រសិនបើមាន) <br />
                                        Former name(If Any)
                                    </Col>
                                    <Col span={16}>
                                        <Row gutter={16}>
                                            <Col span={8}>
                                                <Form.Item
                                                    name={["formData", "formerName"]}
                                                >
                                                    <Input
                                                        placeholder="នាមករណ៍ក្រុមហ៊ុនពីមុន(ប្រសិនបើមាន) / Former name(If Any)"
                                                        status="warning"
                                                        readOnly
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col span={8}>
                                                កាលបរិច្ឆេទនៃការផ្លាស់ប្តូរនាមករណ៍
                                                <br />
                                                Date of Changing the name
                                            </Col>
                                            <Col span={8}>
                                                <Form.Item
                                                    name={[
                                                        "formData",
                                                        "dateOfChangingName",
                                                    ]}
                                                >
                                                    <DatePicker
                                                        placeholder="កាលបរិច្ឆេទនៃការផ្លាស់ប្តូរនាមករណ៍ / Date of Changing the name"
                                                        status="warning"
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col span={6}>
                                        លេខចុះបញ្ជីពាណិជ្ជកម្ម <br />
                                        Registration Number
                                    </Col>
                                    <Col span={18}>
                                        <Form.Item
                                            name={[
                                                "formData",
                                                "company",
                                                "registrationNumber",
                                            ]}
                                        >
                                            <Input
                                                placeholder="លេខចុះបញ្ជីពាណិជ្ជកម្ម / Registration Number"
                                                status="warning"
                                                readOnly
                                            />
                                        </Form.Item>
                                    </Col>

                                    <Col span={6}>
                                        ប្រភេទកាស៊ីណូ <br />
                                        Casino Type
                                    </Col>
                                    <Col span={18}>
                                        <Form.Item name={["formData", "casinoTypeId"]}>
                                            <Radio.Group>
                                                <Space direction="vertical">
                                                    {!isPending &&
                                                        casinoTypes &&
                                                        casinoTypes.map(
                                                            (
                                                                casinoType: any,
                                                                index: number
                                                            ) => (
                                                                <Radio
                                                                    key={casinoType.id}
                                                                    value={
                                                                        casinoType.id
                                                                    }
                                                                >
                                                                    {
                                                                        casinoType.descriptions
                                                                    }
                                                                </Radio>
                                                            )
                                                        )}
                                                </Space>
                                            </Radio.Group>
                                        </Form.Item>
                                    </Col>

                                    <Col span={6}>
                                        លេខអត្តសញ្ញាណកម្មសារពើពន្ធ <br /> Tax
                                        Identificaiton Number (TIN)
                                    </Col>
                                    <Col span={18}>
                                        <Form.Item
                                            name={[
                                                "formData",
                                                "company",
                                                "taxIdentificationNumber",
                                            ]}
                                            rules={[{ required: true }]}
                                        >
                                            <Input
                                                placeholder="លេខអត្តសញ្ញាណកម្មសារពើពន្ធ / Tax Identificaiton Number (TIN)"
                                                status="warning"
                                                readOnly
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        សញ្ជាតិក្រុមហ៊ុន <br /> Company Nationality
                                    </Col>
                                    <Col span={18}>
                                        <Form.Item
                                            name={["formData", "company", "nationalId"]}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Please select",
                                                },
                                            ]}
                                        >
                                            <Radio.Group>
                                                <Space direction="horizontal">
                                                    {!isPending &&
                                                        nationality &&
                                                        nationality.map(
                                                            (nation: any) => (
                                                                <Radio
                                                                    key={nation.value}
                                                                    value={`${nation.value}`}
                                                                >
                                                                    {nation.label}
                                                                </Radio>
                                                            )
                                                        )}
                                                </Space>
                                            </Radio.Group>
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        ទីស្នាក់ការចុះបញ្ជីក្រុមហ៊ុន
                                        <br />
                                        Company Registration Office
                                    </Col>
                                    <Col span={18}>
                                        <Form.Item
                                            name={[
                                                "formData",
                                                "company",
                                                "registrationOffice",
                                            ]}
                                            rules={[{ required: true }]}
                                        >
                                            <Input
                                                placeholder="ទីស្នាក់ការចុះបញ្ជីក្រុមហ៊ុន / Company Registration Office"
                                                status="warning"
                                                readOnly
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={6}>
                                        អាសយដ្ឋានធ្វើអាជីវកម្ម
                                        <br />
                                        Address of Business
                                    </Col>
                                    <Col span={18}>
                                        <Form.Item
                                            name={["formData", "casino", "address"]}
                                            rules={[{ required: true }]}
                                        >
                                            <Input
                                                placeholder="អាសយដ្ឋានធ្វើអាជីវកម្ម / Address of Business"
                                                status="warning"
                                                readOnly
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={6}>
                                        ទីតាំងនេះជាកម្មសិទ្ធិ ឬជួល?
                                        <br />
                                        Is this above Location an Owned or Rented
                                        Property?
                                    </Col>
                                    <Col span={18}>
                                        <Form.Item
                                            name={[
                                                "formData",
                                                "company",
                                                "propertyStatus",
                                            ]}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Please select",
                                                },
                                            ]}
                                        >
                                            <Radio.Group>
                                                <Space direction="horizontal">
                                                    <Radio value={0}>
                                                        កម្មសិទ្ធិ / Owned
                                                    </Radio>
                                                    <Radio value={1}>
                                                        ជួល / Rented
                                                    </Radio>
                                                </Space>
                                            </Radio.Group>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={6}>
                                        ទូរស័ព្ទច​ល័ត
                                        <br />
                                        Mobile Phone Number
                                    </Col>
                                    <Col span={18}>
                                        <Row gutter={16}>
                                            <Col span={8}>
                                                <Form.Item
                                                    name={[
                                                        "formData",
                                                        "company",
                                                        "mobilePhoneNumber",
                                                    ]}
                                                    rules={[{ required: true }]}
                                                >
                                                    <Input
                                                        placeholder="ទូរស័ព្ទច​ល័ត / Mobile Phone Number"
                                                        status="warning"
                                                        readOnly
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col span={8}>
                                                ទូរស័ព្ទលើតុ
                                                <br />
                                                Office Phone Number
                                            </Col>
                                            <Col span={8}>
                                                <Form.Item
                                                    name={[
                                                        "formData",
                                                        "company",
                                                        "telephoneNumber",
                                                    ]}
                                                    rules={[{ required: true }]}
                                                >
                                                    <Input
                                                        placeholder="ទូរស័ព្ទលើតុ / Office Phone Number"
                                                        status="warning"
                                                        readOnly
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={6}>
                                        សារអេឡិចត្រូនិច
                                        <br />
                                        Email Address
                                    </Col>
                                    <Col span={18}>
                                        <Form.Item
                                            name={[
                                                "formData",
                                                "company",
                                                "emailAddress",
                                            ]}
                                            rules={[{ required: true }]}
                                        >
                                            <Input
                                                placeholder="សារអេឡិចត្រូនិច / Email Address"
                                                status="warning"
                                                readOnly
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={6}>
                                        គេហទំព័រ
                                        <br />
                                        Website
                                    </Col>
                                    <Col span={18}>
                                        <Form.Item
                                            name={[
                                                "formData",
                                                "company",
                                                "websiteAddress",
                                            ]}
                                            rules={[{ required: true }]}
                                        >
                                            <Input
                                                placeholder="គេហទំព័រ / Website"
                                                status="warning"
                                                readOnly
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </>
                        },
                        {
                            key: "4",
                            label: "ព័ត៌មានដើមទុន",
                            children: <>
                                <Row gutter={16}>
                                    <Col span={6}>
                                        ប្រភេទភាគហ៊ុន
                                        <br />
                                        Type of share
                                    </Col>
                                    <Col span={18}>
                                        <Row>
                                            <Col span={24}>
                                                <Row>
                                                    <Col span={24}>
                                                        <Form.Item
                                                            name={[
                                                                "formData",
                                                                "singleShareType",
                                                            ]}
                                                        >
                                                            <Radio.Group
                                                                onChange={
                                                                    onShareTypeChange
                                                                }
                                                            >
                                                                <Radio value={1}>
                                                                    តែមួយប្រភេទ/Only one
                                                                    type
                                                                </Radio>
                                                            </Radio.Group>
                                                        </Form.Item>
                                                    </Col>
                                                    {!shareTypeVisibility && (
                                                        <Col span={24}>
                                                            <Row>
                                                                <Col span={8}>
                                                                    ចំនួនភាគហ៊ុន/Number
                                                                    of share
                                                                </Col>
                                                                <Col span={16}>
                                                                    <Form.Item
                                                                        name={[
                                                                            "formData",
                                                                            "singleShareTypeValue",
                                                                        ]}
                                                                    >
                                                                        <Input
                                                                            status="warning"
                                                                            readOnly
                                                                        />
                                                                    </Form.Item>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    )}
                                                </Row>
                                            </Col>
                                            <Col span={24}>
                                                <Row>
                                                    <Col span={24}>
                                                        <Form.Item
                                                            name={[
                                                                "formData",
                                                                "singleShareType",
                                                            ]}
                                                        >
                                                            <Radio.Group
                                                                onChange={
                                                                    onShareTypeChange
                                                                }
                                                            >
                                                                <Radio value={2}>
                                                                    ច្រើនជាងមួយ/More
                                                                    than one type
                                                                </Radio>
                                                            </Radio.Group>
                                                        </Form.Item>
                                                        {shareTypeVisibility && (
                                                            <Button
                                                                type="link"
                                                                onClick={() =>
                                                                    setCapitalShareVisibility(
                                                                        true
                                                                    )
                                                                }
                                                            >
                                                                បន្ថែមថ្មី
                                                            </Button>
                                                        )}
                                                    </Col>
                                                    <Col span={12}>
                                                        <Form.Item
                                                            shouldUpdate={(
                                                                preValues,
                                                                curValues
                                                            ) =>
                                                                preValues !== curValues
                                                            }
                                                        >
                                                            {({
                                                                getFieldValue,
                                                            }: any) => {
                                                                const capitalShares =
                                                                    getFieldValue(
                                                                        "capitalShares"
                                                                    ) || [];

                                                                return (
                                                                    <Row gutter={16}>
                                                                        {capitalShares.length >
                                                                            0 &&
                                                                            capitalShares.map(
                                                                                (
                                                                                    capitalShare: any,
                                                                                    index: number
                                                                                ) => (
                                                                                    <Col
                                                                                        className="py-4"
                                                                                        key={
                                                                                            index
                                                                                        }
                                                                                        span={
                                                                                            12
                                                                                        }
                                                                                    >{`${NumberToKhCharacter(
                                                                                        index +
                                                                                        1
                                                                                    )} ចំនួន/ ${NumberToCharacter(
                                                                                        index +
                                                                                        1
                                                                                    )} Number: ${capitalShare.value
                                                                                        }`}</Col>
                                                                                )
                                                                            )}
                                                                    </Row>
                                                                );
                                                            }}
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col span={6}>
                                        ដើមទុនចុះបញ្ជី (ជាប្រាក់រៀល)
                                        <br />
                                        Registerd Capital (KHR)
                                    </Col>
                                    <Col span={18}>
                                        <Row>
                                            <Col span={6}>ជាលេខ / In Number</Col>
                                            <Col span={18}>
                                                <Form.Item
                                                    name={["formData", "capital"]}
                                                >
                                                    <Input status="warning" readOnly />
                                                </Form.Item>
                                            </Col>
                                            <Col span={6}>ជាអក្សរ / In word</Col>
                                            <Col span={18}>
                                                <Form.Item
                                                    name={["formData", "capitalInWord"]}
                                                >
                                                    <Input status="warning" readOnly />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col span={6}>
                                        តម្លៃចារឹកលើប័ណ្ណ (ជាប្រាក់រៀល)
                                        <br />
                                        Par Value (KHR/Share)
                                    </Col>
                                    <Col span={18}>
                                        <Form.Item name={["formData", "parValue"]}>
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        សញ្ជាតិរបស់បណ្តាម្ចាស់ហ៊ុនដែលមានសិទ្ធិបោះឆ្នោតសរុបច្រើនជាងគេ
                                        <br />
                                        nationality of Total Majority Shareholder in
                                        voting right
                                    </Col>
                                    <Col span={18}>
                                        <Row>
                                            <Col span={14}>
                                                <Form.Item
                                                    name={["formData", "mostCapital"]}
                                                >
                                                    <Radio.Group>
                                                        <Space direction="horizontal">
                                                            <Radio value="khmer">
                                                                ខ្មែរ / Khmer
                                                            </Radio>
                                                            <Radio value="foreign">
                                                                បរទេស / Foreign
                                                            </Radio>
                                                        </Space>
                                                    </Radio.Group>
                                                </Form.Item>
                                            </Col>
                                            <Col span={10}>
                                                <Form.Item
                                                    name={[
                                                        "formData",
                                                        "mostCapitalValue",
                                                    ]}
                                                    label="ចំនួនភាគរយនៃហ៊ុនសរុប"
                                                >
                                                    <Input
                                                        addonAfter="%"
                                                        status="warning"
                                                        readOnly
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Col>
                                    {/* Components of business capital */}
                                    <Col span={6}>
                                        សមាសធាតិដើមទុនធ្វើអាជីវកម្ម(ជាប្រាក់រៀល)
                                        <br />
                                        nationality of Total Majority Shareholder in
                                        voting right
                                    </Col>
                                    <Col span={18}>
                                        <Row>
                                            <Col span={14}>
                                                <Form.Item
                                                    name={[
                                                        "formData",
                                                        "isCurrentAsset",
                                                    ]}
                                                    valuePropName="checked"
                                                >
                                                    <Checkbox>
                                                        ទ្រព្យសម្បត្តិចរន្ត / Current
                                                        Assets
                                                    </Checkbox>
                                                </Form.Item>
                                            </Col>
                                            <Col span={10}>
                                                <Form.Item
                                                    name={[
                                                        "formData",
                                                        "currentAssetValue",
                                                    ]}
                                                >
                                                    <Input
                                                        addonBefore="ចំនួន / Amount"
                                                        addonAfter="រៀល"
                                                        status="warning"
                                                        readOnly
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col span={14}>
                                                <Form.Item
                                                    name={["formData", "isShareHolder"]}
                                                    valuePropName="checked"
                                                >
                                                    <Checkbox>
                                                        មូលនិធិភាគហ៊ុន / Shareholder
                                                        Funds
                                                    </Checkbox>
                                                </Form.Item>
                                            </Col>
                                            <Col span={10}>
                                                <Form.Item
                                                    name={[
                                                        "formData",
                                                        "shareHolderValue",
                                                    ]}
                                                >
                                                    <Input
                                                        addonBefore="ចំនួន / Amount"
                                                        addonAfter="រៀល"
                                                        status="warning"
                                                        readOnly
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col span={14}>
                                                <Form.Item
                                                    name={["formData", "isRealEstate"]}
                                                    valuePropName="checked"
                                                >
                                                    <Checkbox>
                                                        អចលនទ្រព្យ / Real Estate
                                                    </Checkbox>
                                                </Form.Item>
                                            </Col>
                                            <Col span={10}>
                                                <Form.Item
                                                    name={[
                                                        "formData",
                                                        "realEstateValue",
                                                    ]}
                                                >
                                                    <Input
                                                        addonBefore="ចំនួន / Amount"
                                                        addonAfter="រៀល"
                                                        status="warning"
                                                        readOnly
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </>
                        },
                        {
                            key: "5",
                            label: "ក្រុមប្រឹក្សាភិបាល",
                            children: <>
                                <div>
                                    <Form.Item
                                        shouldUpdate={(preValues, curValues) =>
                                            preValues.boardOfDirectors !==
                                            curValues.boardOfDirectors
                                        }
                                    >
                                        {({ getFieldValue }: any) => {
                                            const boardOfDirectors: BoardOfDirectorType[] =
                                                getFieldValue("boardOfDirectors") || [];

                                            return (
                                                <Table
                                                    bordered
                                                    rowKey="name"
                                                    className="w-full"
                                                    size="small"
                                                    pagination={{
                                                        position: [],
                                                    }}
                                                    columns={boardOfDirectorColumns}
                                                    dataSource={boardOfDirectors}
                                                />
                                            );
                                        }}
                                    </Form.Item>
                                    {/* <Button
                                        type="link"
                                        onClick={() =>
                                            setBoardOfDirectorVisibility(true)
                                        }
                                    >
                                        បន្ថែមថ្មី
                                    </Button> */}
                                </div>
                            </>
                        },
                        {
                            key: "6",
                            label: "ព័ត៌មានបុគ្គលឈរឈ្មោះក្នុងអាជ្ញាបណ្ណ",
                            children: <>
                                <Row>
                                    <Col span={8}>
                                        ស្ថានភាពអ្នកដាក់ពាក្យស្នើសុំ
                                        និងបុគ្គលឈរឆ្មោះក្នុអាជ្ញាបណ្ណ
                                        <br />
                                        Status of the Applicant and License
                                        Representative
                                    </Col>
                                    <Col span={16}>
                                        <Form.Item
                                            name={["formData", "representativeType"]}
                                        // valuePropName="checked"
                                        >
                                            <Radio.Group
                                                onChange={onApplicantStatusChange}
                                            >
                                                <Space direction="vertical">
                                                    <Radio value={false}>
                                                        ជាបុគ្គលតែមួយ / Same Person
                                                    </Radio>
                                                    <Radio value={true}>
                                                        ជាបុគ្គលផ្សេងគ្នា / Different
                                                        Person
                                                    </Radio>
                                                </Space>
                                            </Radio.Group>
                                        </Form.Item>
                                        <p>
                                            (សូមបន្តបំពេញប័ត៌មានខាងក្រោម/Please Provider
                                            the Information Below)
                                        </p>
                                    </Col>
                                </Row>
                                {isDifferentRepresentative === true ? (
                                    <Row>
                                        <Col span={6}>
                                            គោត្តនាម និងនាម
                                            <br />
                                            Last name and First name
                                        </Col>
                                        <Col span={18}>
                                            <Row>
                                                <Col span={8}>
                                                    អក្សរខ្មែរ <br />
                                                    in Khmer
                                                </Col>
                                                <Col span={16}>
                                                    <Form.Item
                                                        name={[
                                                            "formData",
                                                            "representative",
                                                            "khmerName",
                                                        ]}
                                                    >
                                                        <Input />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={8}>
                                                    អក្សរឡាតាំង <br />
                                                    in Latin
                                                </Col>
                                                <Col span={16}>
                                                    <Form.Item
                                                        name={[
                                                            "formData",
                                                            "representative",
                                                            "englishName",
                                                        ]}
                                                    >
                                                        <Input />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </Col>

                                        <Col span={6}>
                                            ភេទ
                                            <br />
                                            Sex
                                        </Col>
                                        <Col span={18}>
                                            <Form.Item
                                                name={[
                                                    "formData",
                                                    "representative",
                                                    "gender",
                                                ]}
                                            >
                                                <Radio.Group>
                                                    <Space direction="horizontal">
                                                        <Radio value="male">
                                                            ប្រុស / Male
                                                        </Radio>
                                                        <Radio value="female">
                                                            ស្រី / Female
                                                        </Radio>
                                                    </Space>
                                                </Radio.Group>
                                            </Form.Item>
                                        </Col>
                                        <Col span={6}>
                                            ថ្ងៃខែឆ្នាំកំណើត
                                            <br />
                                            Date of Birth
                                        </Col>
                                        <Col span={18}>
                                            <Form.Item
                                                name={[
                                                    "formData",
                                                    "representative",
                                                    "dateOfBirth",
                                                ]}
                                            >
                                                <DatePicker placeholder="ថ្ងៃខែឆ្នាំកំណើត" />
                                            </Form.Item>
                                        </Col>
                                        <Col span={6}>
                                            សញ្ជាតិ
                                            <br />
                                            Nationality
                                        </Col>
                                        <Col span={18}>
                                            <Form.Item
                                                name={[
                                                    "formData",
                                                    "representative",
                                                    "nationality",
                                                ]}
                                            >
                                                <Input placeholder="សញ្ជាតិ / Nationality" />
                                            </Form.Item>
                                        </Col>
                                        <Col span={8}>
                                            <Form.Item
                                                name={[
                                                    "formData",
                                                    "representative",
                                                    "identityTypeId",
                                                ]}
                                            >
                                                <Radio.Group>
                                                    <Space direction="vertical">
                                                        {!isPending &&
                                                            identificationTypes &&
                                                            identificationTypes.map(
                                                                (
                                                                    identificationType: any,
                                                                    index: number
                                                                ) => (
                                                                    <Radio
                                                                        key={
                                                                            identificationType.value
                                                                        }
                                                                        value={
                                                                            identificationType.value
                                                                        }
                                                                    >
                                                                        {
                                                                            identificationType.label
                                                                        }
                                                                    </Radio>
                                                                )
                                                            )}
                                                    </Space>
                                                </Radio.Group>
                                            </Form.Item>
                                        </Col>
                                        <Col span={16}>
                                            <Form.Item
                                                name={[
                                                    "formData",
                                                    "representative",
                                                    "identityNumber",
                                                ]}
                                            >
                                                <Input placeholder="សូមបំពេញ" />
                                            </Form.Item>
                                        </Col>
                                        <Col span={6}>
                                            ថ្ងៃខែឆ្នាំផ្តល់ / Issued Date
                                        </Col>
                                        <Col span={18}>
                                            <Form.Item
                                                name={[
                                                    "formData",
                                                    "representative",
                                                    "issuedDate",
                                                ]}
                                            >
                                                <DatePicker placeholder="ថ្ងៃខែឆ្នាំកំណើត" />
                                            </Form.Item>
                                        </Col>
                                        <Col span={6}>
                                            ថ្ងៃខែឆ្នាំផុតកំណត / Date of Expiry
                                        </Col>
                                        <Col span={18}>
                                            <Form.Item
                                                name={[
                                                    "formData",
                                                    "representative",
                                                    "expiredDate",
                                                ]}
                                            >
                                                <DatePicker placeholder="ថ្ងៃខែឆ្នាំកំណើត" />
                                            </Form.Item>
                                        </Col>
                                        <Col span={6}>
                                            តួនាទីក្នុងក្រុមហ៊ុន / Position in Company
                                        </Col>
                                        <Col span={18}>
                                            <Form.Item
                                                name={[
                                                    "formData",
                                                    "representative",
                                                    "designatureId",
                                                ]}
                                            >
                                                <Input placeholder="តួនាទីក្នុងក្រុមហ៊ុន / Position in Company" />
                                            </Form.Item>
                                        </Col>
                                        <Col span={6}>អាសយដ្ឋាន / Address</Col>
                                        <Col span={18}>
                                            <Form.Item
                                                name={[
                                                    "formData",
                                                    "representative",
                                                    "address",
                                                ]}
                                            >
                                                <Input placeholder="អាសយដ្ឋាន / Address" />
                                            </Form.Item>
                                        </Col>
                                        <Col span={6}>
                                            ទូរស័ព្ទចល័ត / Mobile Phone Number
                                        </Col>
                                        <Col span={18}>
                                            <Form.Item
                                                name={[
                                                    "formData",
                                                    "representative",
                                                    "mobilePhoneNumber",
                                                ]}
                                            >
                                                <Input placeholder="ទូរស័ព្ទចល័ត / Mobile Phone Number" />
                                            </Form.Item>
                                        </Col>
                                        <Col span={6}>
                                            សារអេឡិចត្រូនិច / Email Address
                                        </Col>
                                        <Col span={18}>
                                            <Form.Item
                                                name={[
                                                    "formData",
                                                    "representative",
                                                    "emailAddress",
                                                ]}
                                            >
                                                <Input placeholder="សារអេឡិចត្រូនិច / Email Address" />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                ) : (
                                    <span></span>
                                )}
                            </>
                        },
                    ]}
                />
                <div className="hidden">
                    <RequestSection
                        title="ផ្នែកទី១៖ គោលបំណងនៃការដាក់ពាក្យ"
                        subTitle="PURPOSE OF APPLICATION"
                    >
                        <p>
                            ស្នើសុំអាជ្ញាបណ្ណកាស៊ីណូ / Apply for Casino License
                        </p>
                    </RequestSection>
                </div>

            </Form>
            <CreateCasinoModalForm
                visible={casinoVisible}
                onOk={onCasinoSubmit}
                onCancel={() => setCasinoVisible(false)}
            />
        </>
    );
};
