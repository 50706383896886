import  { useState } from "react";
import { useSelector } from "react-redux";
import { Modal, Form, Row, Col, Input, Select } from "antd";
import { useResetFormOnCloseModal } from "Utility/ResetFormOnCloseModal";

import { useInjectReducer } from "Utility/InjectReducer";
import Reducer from "Modules/Settings/GameRule/Reducer";
import { MakeGameRuleSelector } from "Modules/Settings/GameRule/Selectors";

type GameTypeProps = {
    visibility: boolean;
    onCancel: any;
};

const key = "gameRule";
export const CertificateCasinoGameRuleModal = ({
    visibility,
    onCancel,
}: GameTypeProps) => {
    useInjectReducer({ key, reducer: Reducer });
    // fields
    const [form] = Form.useForm();
    const gameRules = useSelector(MakeGameRuleSelector());
    const [gameType, setGameType] = useState<any>([]);

    // methods
    useResetFormOnCloseModal({
        form,
        visible: visibility,
    });

    const onSubmit = () => {
        form.submit();
        onCancel();
    };

    const onGameRuleSelect = (value: any) => {
      const gameRule = gameRules.find(i => i.id === value.value)
      if(gameRule) {
        setGameType(gameRule.gameTypes);
      }
    }

    return (
        <Modal
            title="វិធានល្បែង"
            open={visibility}
            onOk={onSubmit}
            onCancel={onCancel}
            okText="យល់ព្រម"
            cancelText="បោះបង់"
            okButtonProps={{ type: "default" }}
        >
            <Form
                name="gameRuleModalForm"
                form={form}
                initialValues={{
                    gameRuleId: { key: "", value: "", label: "" },
                    other: "",
                }}
            >
                <Row>
                    <Col span={10}>វិធានល្បែង</Col>
                    <Col span={14}>
                        <Form.Item name="gameRuleId">
                            <Select
                                labelInValue
                                style={{ minWidth: 200 }}
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    {
                                        return (option?.children.toString() ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                }
                                onSelect={onGameRuleSelect}
                                showSearch
                            >
                                {gameRules &&
                                    gameRules.map((item) => {
                                        item.gameTypes?.map((gameTypes: any) => (`${gameTypes.name}`))
                                        return <Select.Option
                                            key={item.id}
                                            value={item["id"]}
                                        >
                                            {item["name"]}
                                        </Select.Option>
                                    }

                                    )}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={10}>ប្រភេទល្បែង</Col>
                    <Col span={14}>
                        <Form.Item name="gameTypeIds">
                            <Select
                                labelInValue
                                style={{ minWidth: 200 }}
                                // filterOption={(input, option) => {

                                //     return option!.children
                                //         .join(" ")
                                //         .toLowerCase()
                                //         .includes(input.toLowerCase())
                                // }}
                                mode="multiple"
                            >
                                {gameType &&
                                    gameType.map((item) => 
                                      <Select.Option
                                      key={item.id}
                                      value={item["id"]}
                                    >
                                        {item["name"]}
                                    </Select.Option>
                                  )}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={10}>ផ្សេងៗ</Col>
                    <Col span={14}>
                        <Form.Item name="other">
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};
