import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useSearchParams } from "react-router-dom";
import { HelmetHeader } from "Components/Helmet-Header";
import { ArchiveIcon } from "@heroicons/react/outline";
import { Form, Table, Row, Col, Button, Popconfirm } from "antd";
import { FullLayout } from "Components/Layouts/";

import { useInjectReducer } from "Utility/InjectReducer";
import { useInjectSaga } from "Utility/InjectSaga";
import Reducer from "Modules/Settings/GameType/Reducer";
import Saga from "Modules/Settings/GameType/Saga";

import {
    QueryGameTypeActions,
    QueryGameTypeSuccessActions,
    SetFormValuesAction,
} from "Modules/Settings/GameType/Actions";
import { GameTypeFilterForm } from "Modules/Settings/GameType/Forms/GameTypeFilterForm";
import { makeGameTypesSelector } from "Modules/Settings/GameType/Selectors";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";

const key = "gameType";
export const List = () => {
    useInjectReducer({ key, reducer: Reducer });
    useInjectSaga({ key, saga: Saga });
    // fields
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();
    const gameTypes = useSelector(makeGameTypesSelector());
    const entityType = searchParams.get("entityType");

    useEffect(() => {
        const entityTypeId = entityType === "LOTTERY" ? 2 : 1;
        const model = {
            entityType: entityTypeId
        }
        dispatch(SetFormValuesAction(model));
        dispatch(QueryGameTypeActions());
        return () => {
            dispatch(QueryGameTypeSuccessActions([]));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [entityType])

    // methods
    const onFormProviderSubmit = (
        name: string,
        { values, forms }: any
    ) => {
        const entityTypeId = entityType === "LOTTERY" ? 2 : 1;
        const model = {
            ...values,
            entityType: entityTypeId
        }
        dispatch(SetFormValuesAction(model));
        dispatch(QueryGameTypeActions());
    };

    const onDeleteClicked = (item: any) => {

    }

    // render
    const ActionButtons = () => (
        <>
            <Link to={`/settings/game-type/create?entityType=${entityType}`}>
                <Button type="primary">
                    បញ្ចូលថ្មី
                </Button>
            </Link>

        </>
    );

    return (
        <>
            <FullLayout
                icon={<ArchiveIcon />}
                title="បញ្ជីប្រភេទល្បែង"
                descriptions=""
                actions={<ActionButtons />}
            >
                <HelmetHeader meta="ប្រភេទល្បែង" />
                <Form.Provider onFormFinish={onFormProviderSubmit}>
                    <GameTypeFilterForm form={form} />
                </Form.Provider>
                <Row>
                    <Col span={24}>
                        <Table rowKey="id" dataSource={gameTypes} size="small">
                            <Table.Column
                                title="ប្រភេទល្បែង"
                                key="name"
                                render={(item: any) => (
                                    <Link
                                        to={`/settings/game-type/detail/${item.id}?entityType=${entityType}`}
                                        className="text-sm font-medium rounded-md text-blue-700 underline"
                                    >
                                        {item.name}
                                    </Link>
                                )}
                            />
                            <Table.Column
                                title="ផ្សេងៗ"
                                key="descriptions"
                                dataIndex="descriptions"
                            />
                            <Table.Column 
                                key= "id"
                                width= '100px'
                                render={
                                    (item: any) => <div className="">
                                        <Popconfirm
                                            title="Delete the item"
                                            description="Are you sure to delete this item?"
                                            onConfirm={() => onDeleteClicked(item)}
                                            onCancel={() => console.log("Cancel")}
                                            okText="Yes"
                                            cancelText="No"
                                        >
                                            <Button type="link" className="p-2" >
                                                <DeleteOutlined />
                                            </Button>
                                        </Popconfirm>
                        
                                        <Link
                                            to={`/settings/game-type/detail/${item.id}?entityType=${entityType}`}
                                            className="text-sm font-medium rounded-md text-blue-700 underline"
                                        >
                                            <EditOutlined />
                                        </Link>
                                    </div>
                                }
                            />
                        </Table>
                    </Col>
                </Row>
            </FullLayout>
        </>
    );
};
