import produce from "immer";
import { SELECT_CURRENCY_SUCCESS } from "./Constants";

export const CurrencyInitialState = {
    currencyOptions: []
}

const CurrencyReducer = produce((draft, action) => {
    switch (action.type) {
        case SELECT_CURRENCY_SUCCESS:
            draft.currencyOptions = action.currencies;
            break;
        default: break;
    }
}, CurrencyInitialState);

export default CurrencyReducer;