import { all, takeLatest, put, call, select } from "redux-saga/effects";
import { GET, POST } from "Utility/Constant";
import { CORE_UPLOAD_FILE_PATH } from "Utility/Endpoint";
import ApiEndpoint from "Utility/Endpoint";

import { AsyncEndAction, AsyncStartAction } from "Modules/Application/Actions";
import { MakeEquipmentBindingUploadValueSelector, MakeEquipmentBindingValueSelector, MakeVerifyEquipmentBindingValueSelector } from "./Selectors";
import AuthenticationRequest from "Utility/AuthenticationHttp";
import {
    UploadEquipmentBindingSuccessAction
} from "Modules/Equipments/Casino/Binding/Actions";
import { CREATE_EQUIPMENT_BINDING_REQUEST, UPLOAD_EQUIPMENT_BINDING_REQUEST, VERIFY_EQUIPMENT_BINDING_REQUEST } from "./Constants";
import Request from "Utility/Http";
import { ShowMessage } from "Common/Saga";
import { v4 as uuid } from "uuid";


function* UploadPicture() {
    try {
        yield put(AsyncStartAction());
        const model = yield select(MakeEquipmentBindingUploadValueSelector());
        const url = CORE_UPLOAD_FILE_PATH;
        const payload = ApiEndpoint.makeApiPayload(url, POST, model);
        const response = yield call(AuthenticationRequest, payload);
        if (response) {
            yield put(UploadEquipmentBindingSuccessAction(response));
        }
    } catch {

    }
    finally {
        yield put(AsyncEndAction());
    }
}

function* CreateEquipmentCertificate() {
    try {
        yield put(AsyncStartAction());
        const model = yield select(MakeEquipmentBindingValueSelector());
        const url = ApiEndpoint.EQUIPMENT_CERTIFICATE_MAP_PATH;
        const payload = ApiEndpoint.makeApiPayload(url, POST, model);
        const response = yield call(Request, payload);
        if (response &&  response.type !== "containers/AlertMessage/SHOW_SNACK_MESSAGE" ) {
            yield ShowMessage({
                type: "success",
                message: "ទិន្នន័យបានរក្សារទុក",
                redirectUrl: `/equipment/binding/detail/${response.id}`,
                id: uuid(),
            });
        
        }
        
    } catch {

    }
    finally {
        yield put(AsyncEndAction());
    }
}

function* VerifiyEquipment() {
    try {
        yield put(AsyncStartAction());
        const id = yield select(MakeVerifyEquipmentBindingValueSelector());
        const url = `${ApiEndpoint.EQUIPMENT_CERTIFICATE_MAP_PATH}/${id}`;
        const payload = ApiEndpoint.makeApiPayload(url, GET, id);
        const response = yield call(Request, payload);

        if (response) {
            yield put(UploadEquipmentBindingSuccessAction(response));
        }
    } catch {
    }
    finally {
        yield put(AsyncEndAction());
    }
}

export default function* EquipmentBindingSaga() {
    yield all([
        yield takeLatest(UPLOAD_EQUIPMENT_BINDING_REQUEST, UploadPicture),
        yield takeLatest(CREATE_EQUIPMENT_BINDING_REQUEST, CreateEquipmentCertificate),
        yield takeLatest(VERIFY_EQUIPMENT_BINDING_REQUEST, VerifiyEquipment),
    ]);
}