import { call, put, select, takeLatest, all } from "redux-saga/effects";
import { v4 as uuid } from "uuid";
import Request from "Utility/Http";
import { DELETE, GET, POST, PUT } from "Utility/Constant";
import ApiEndpoint from "Utility/Endpoint";
import { ShowMessage } from "Common/Saga";

import { AsyncEndAction, AsyncStartAction } from "Modules/Application/Actions";
import {
    ListInspectionCertificateStatusSuccessAction,
    SetInspectionCertificateStatusStatusAction,
    SelectInspectionCertificateStatusSuccessAction
} from "Modules/Settings/InspectionCertificateStatus/Actions";
import {
    MakeInspectionCertificateStatusFilterValueSelector,
    MakeInspectionCertificateStatusIdSelector,
    MakeInspectionCertificateStatusValueSelector
} from "Modules/Settings/InspectionCertificateStatus/Selectors";
import {
    CREATE_INSPECTION_CERTIFICATE_STATUS_REQUEST,
    DELETE_INSPECTION_CERTIFICATE_STATUS_REQUEST,
    EDIT_INSPECTION_CERTIFICATE_STATUS_REQUEST,
    GET_INSPECTION_CERTIFICATE_STATUS_REQUEST,
    LIST_INSPECTION_CERTIFICATE_STATUS_REQUEST,
    SELECT_INSPECTION_CERTIFICATE_STATUS_REQUEST
} from "Modules/Settings/InspectionCertificateStatus/Constants";

function* ListInspectionCertificateStatus() {
    yield put(AsyncStartAction());
    try {
        const filter = yield select(MakeInspectionCertificateStatusFilterValueSelector());
        const url = `${ApiEndpoint.INSPECTION_CERTIFICATE_STATUS_PATH}/lists`;
        const payload = ApiEndpoint.makeApiPayload(url, POST, filter);
        const response = yield call(Request, payload);
        if (response && !response.status && !response.snack) {
            yield put(ListInspectionCertificateStatusSuccessAction(response));
        }
    } catch {
        yield ShowMessage({
            type: "error",
            message: "មានបញ្ហាពេលទាញទិន្នន័យ",
            id: uuid(),
        })
    }
    finally {
        yield put(AsyncEndAction());
    }
}

function* GetInspectionCertificateStatus() {
    yield put(AsyncStartAction());
    try {
        const id = yield select(MakeInspectionCertificateStatusIdSelector());
        const url = `${ApiEndpoint.INSPECTION_CERTIFICATE_STATUS_PATH}/${id}`;
        const payload = ApiEndpoint.makeApiPayload(url, GET, id);
        const response = yield call(Request, payload);
        if (response && !response.status && !response.snack) {
            yield put(ListInspectionCertificateStatusSuccessAction(response));
        }
    } catch {
        yield ShowMessage({
            type: "error",
            message: "មានបញ្ហាពេលទាញទិន្នន័យ",
            id: uuid(),
        })
    }
    finally {
        yield put(AsyncEndAction());
    }
}

function* SelectInspectionCertificateStatus() {
    yield put(AsyncStartAction());
    try {
        const url = ApiEndpoint.INSPECTION_CERTIFICATE_STATUS_PATH;
        const payload = ApiEndpoint.makeApiPayload(url, GET, {});
        const response = yield call(Request, payload);
        if (response && !response.status && !response.snack) {
            yield put(SelectInspectionCertificateStatusSuccessAction(response));
        }
    } catch {
        yield ShowMessage({
            type: "error",
            message: "មានបញ្ហាពេលទាញទិន្នន័យ",
            id: uuid(),
        })
    }
    finally {
        yield put(AsyncEndAction());
    }
}

function* CreateInspectionCertificateStatus() {
    yield put(AsyncStartAction());
    try {
        const model = yield select(MakeInspectionCertificateStatusValueSelector());
        const url = ApiEndpoint.INSPECTION_CERTIFICATE_STATUS_PATH;
        const payload = ApiEndpoint.makeApiPayload(url, POST, model);
        const response = yield call(Request, payload);
        if (response && !response.status && !response.snack) {
            yield put(SetInspectionCertificateStatusStatusAction(1));
            yield ShowMessage({
                type: "success",
                message: "ទិន្នន័យបានរក្សារទុក",
                id: uuid(),
            })
        }
    } catch {
        yield put(SetInspectionCertificateStatusStatusAction(2));
        yield ShowMessage({
            type: "error",
            message: "មានបញ្ហាពេលទាញទិន្នន័យ",
            id: uuid(),
        })
    }
    finally {
        yield put(AsyncEndAction());
    }
}

function* EditInspectionCertificateStatus() {
    yield put(AsyncStartAction());
    try {
        const model = yield select(MakeInspectionCertificateStatusValueSelector());
        const url = ApiEndpoint.INSPECTION_CERTIFICATE_STATUS_PATH;
        const payload = ApiEndpoint.makeApiPayload(url, PUT, model);
        const response = yield call(Request, payload);
        if (response && !response.status && !response.snack) {
            yield ShowMessage({
                type: "success",
                message: "ទិន្នន័យបានរក្សារទុក",
                id: uuid(),
            })
            yield put(SetInspectionCertificateStatusStatusAction(1));
        }
    } catch {
        yield put(SetInspectionCertificateStatusStatusAction(2));
        yield ShowMessage({
            type: "error",
            message: "មានបញ្ហាពេលទាញទិន្នន័យ",
            id: uuid(),
        });
    }
    finally {
        yield put(AsyncEndAction());
    }
}

function* DeleteInspectionCertificateStatus() {
    yield put(AsyncStartAction());
    try {
        const id = yield select(MakeInspectionCertificateStatusIdSelector());
        const url = `${ApiEndpoint.INSPECTION_CERTIFICATE_STATUS_PATH}/${id}`;
        const payload = ApiEndpoint.makeApiPayload(url, DELETE, id);
        const response = yield call(Request, payload);
        if (!response.status && !response.snack) {
            yield put(SetInspectionCertificateStatusStatusAction(3));
            yield ShowMessage({
                type: "success",
                message: "ទិន្នន័យបានលុប",
                id: uuid(),
            })
        }
    } catch {
        yield ShowMessage({
            type: "error",
            message: "មានបញ្ហាពេលទាញទិន្នន័យ",
            id: uuid(),
        });
    }
    finally {
        yield put(AsyncEndAction());
    }
}

export default function* InspectionCertificateStatusSaga() {
    yield all([
        yield takeLatest(LIST_INSPECTION_CERTIFICATE_STATUS_REQUEST, ListInspectionCertificateStatus),
        yield takeLatest(GET_INSPECTION_CERTIFICATE_STATUS_REQUEST, GetInspectionCertificateStatus),
        yield takeLatest(SELECT_INSPECTION_CERTIFICATE_STATUS_REQUEST, SelectInspectionCertificateStatus),

        yield takeLatest(CREATE_INSPECTION_CERTIFICATE_STATUS_REQUEST, CreateInspectionCertificateStatus),
        yield takeLatest(EDIT_INSPECTION_CERTIFICATE_STATUS_REQUEST, EditInspectionCertificateStatus),
        yield takeLatest(DELETE_INSPECTION_CERTIFICATE_STATUS_REQUEST, DeleteInspectionCertificateStatus),
    ]);
}