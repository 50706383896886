import { call, put, all, select, takeLatest } from "redux-saga/effects";
import { v4 as uuid } from "uuid";
import ApiEndpoint from "Utility/Endpoint";
import Request from "Utility/Http";
import { MakeFormValuesSelector } from "Modules/License/Casino/Request/Selectors";
import { RequestCasinoLicense } from "Modules/License/Casino/Request/Constants";
import { POST } from "Utility/Constant";
import { AsyncStartAction, AsyncEndAction } from "Modules/Application/Actions";
import { ShowAlert, ShowMessage } from "Common/Saga";

export function* createCasinoLicense(): any {
    yield put(AsyncStartAction());
    const formValues = yield select(MakeFormValuesSelector());

    const requestUrl = ApiEndpoint.REQUEST_LICENSE;
    const httpPayload = ApiEndpoint.makeApiPayload(
        requestUrl,
        POST,
        formValues
    );
    try {
        const response = yield call(Request, httpPayload);
        yield put(AsyncEndAction());
        if (response)
            yield ShowMessage({
                type: "success",
                message: "ទិន្នយបានរក្សាទុក",
                content: "ទិន្នយបានរក្សាទុក",
                id: uuid(),
                redirectUrl: "/license/casino/request/list",
            });
    } catch (error: any) {
        yield put(AsyncEndAction());
        if (error.data && error.data.statusCode === 422) {
        }
        return yield ShowAlert("error", error.data.message);
    }
}

export default function* requestCasinoLicenseSaga() {
    yield all([
        yield takeLatest(
            RequestCasinoLicense.CASINO_LICENSE_REQUEST,
            createCasinoLicense
        ),
    ]);
}
