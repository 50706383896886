import {
    GameRuleConstants,
    SET_GAME_RULE_ID,
    GET_GAME_RULE_REQUEST,
    UPDATE_GAME_RULE_REQUEST,
    GET_GAME_RULE_SUCCESS,
    SET_GAME_RULE_FILTER_VALUE,
    SELECT_GAME_RULE_REQUEST,
    SELECT_GAME_RULE_SUCCESS
} from "Modules/Settings/GameRule/Constants";

export const SetFormValuesAction = (formValues: any) => {
    return {
        type: GameRuleConstants.SET_FORM_VALUES,
        formValues,
    };
};

export const QueryGameRuleSuccessAction = (gameRules: any) => {
    return {
        type: GameRuleConstants.QUERY_GAME_RULE_SUCCESS,
        gameRules,
    };
};

export const QueryGameRuleRequestAction = () => {
    return {
        type: GameRuleConstants.QUERY_GAME_RULE_REQUEST,
    };
};

export const CreateGameRuleRequestAction = () => {
    return {
        type: GameRuleConstants.CREATE_GAME_RULE_REQUEST,
    };
};

export const SetGameRuleIdAction = (id: string) => {
    return {
        type: SET_GAME_RULE_ID,
        id,
    };
};

export const GetGameRuleRequestAction = () => {
    return {
        type: GET_GAME_RULE_REQUEST,
    };
};

export const GetGameRuleSuccessAction = (gameRuleDetail: any) => {
    return {
        type: GET_GAME_RULE_SUCCESS,
        gameRuleDetail,
    };
};

export const UpdateGameRuleRequestAction = () => {
    return {
        type: UPDATE_GAME_RULE_REQUEST,
    };
};

export const SetGameRuleFilterValueAction = (values: any) => {
    return {
        type: SET_GAME_RULE_FILTER_VALUE,
        values,
    }
}

export const SelectGameRuleRequestAction = () => {
    return {
        type: SELECT_GAME_RULE_REQUEST
    }
}

export const SelectGameRuleSuccessAction = (gameRuleOptions: any) => {
    return {
        type: SELECT_GAME_RULE_SUCCESS,
        gameRuleOptions
    }
}