import { createSelector } from "reselect";
import { initialState } from "Modules/Application/Reducer";

const selectApplicationDomain = (state: any) =>
    state.application || initialState;
const MakeIsLoggedSelector = () =>
    createSelector(selectApplicationDomain, (substate) => substate.isLogged);

const MakeRequestIdSelector = () =>
    createSelector(selectApplicationDomain, (substate) => substate.requestId);

const UserProfileSelector = () =>
    createSelector(selectApplicationDomain, (substate) => substate.user);
const MakeIndicatorSelector = () =>
    createSelector(selectApplicationDomain, (substate) => substate.isLoading);

const MakeAuthenticationAppRequestIdSelector = () =>
    createSelector(
        selectApplicationDomain,
        (substate) => substate.authenticationRequestId
    );

const MakePasswordValueSelector = () => createSelector(
    selectApplicationDomain,
    (substate) => substate.password
);

const MakeUploadFileSelector = () => createSelector(selectApplicationDomain, substate => substate.file);
const MakeFileModelSelector = () => createSelector(selectApplicationDomain, substate => substate.fileModel);
const MakeUploadStatusSelector = () => createSelector(selectApplicationDomain, substate => substate.uploadStatus);

const MakePermissionSelector = () => createSelector(selectApplicationDomain, substate => substate.permissions);
const MakeAuthenticationAppStatusSelector = () => createSelector(selectApplicationDomain, substate => substate.authenticationStatus);
const MakePrintParamsSelector = () => createSelector(selectApplicationDomain, substate => substate.printParams);

export {
    MakeIsLoggedSelector,
    UserProfileSelector,
    MakeRequestIdSelector,
    MakeIndicatorSelector,
    MakeAuthenticationAppRequestIdSelector,
    MakePasswordValueSelector,
    MakePermissionSelector,
    MakeUploadFileSelector,
    MakeFileModelSelector,
    MakeUploadStatusSelector,
    MakeAuthenticationAppStatusSelector,
    MakePrintParamsSelector,
};
