import {
    GameTypeConstants,
    GET_GAME_TYPE_REQUEST,
    GET_GAME_TYPE_SUCCESS,
    SET_GAME_TYPE_ID,
    UPDATE_GAME_TYPE_REQUEST,
    SELECT_GAME_TYPE_REQUEST,
    SELECT_GAME_TYPE_SUCCESS
} from "Modules/Settings/GameType/Constants";

export function SetFormValuesAction(formValues: any) {
    return {
        type: GameTypeConstants.SET_FORM_VALUES,
        formValues,
    };
}

export function CreateGameTypeActions() {
    return {
        type: GameTypeConstants.CREATE_GAME_TYPE_REQUEST,
    };
}

export function CreateGameTypeSuccessActions(gameTypes: any) {
    return {
        type: GameTypeConstants.CREATE_GAME_TYPE_SUCCESS,
        gameTypes,
    };
}

export function QueryGameTypeActions() {
    return {
        type: GameTypeConstants.QUERY_GAME_TYPE_REQUEST,
    };
}

export function QueryGameTypeSuccessActions(gameTypes: any) {
    return {
        type: GameTypeConstants.QUERY_GAME_TYPE_SUCCESS,
        gameTypes,
    };
}

export function GetGameTypeRequestAction() {
    return {
        type: GET_GAME_TYPE_REQUEST,
    };
}

export function GetGameTypeSuccessAction(gameTypeDetail: any) {
    return {
        type: GET_GAME_TYPE_SUCCESS,
        gameTypeDetail,
    };
}

export const SetGameTypeIdAction = (id: string) => {
    return {
        type: SET_GAME_TYPE_ID,
        id,
    };
};

export const UpdateGameTypeRequestAction = () => {
    return {
        type: UPDATE_GAME_TYPE_REQUEST,
    };
};

export const SelectGameTypeRequestAction = () => {
    return {
        type: SELECT_GAME_TYPE_REQUEST
    }
}

export const SelectGameTypeSuccessAction = (gameTypeOptions: any) => {
    return {
        type: SELECT_GAME_TYPE_SUCCESS,
        gameTypeOptions
    }
}