import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, } from "react-router-dom";
import { useInjectReducer } from "Utility/InjectReducer";
import { useInjectSaga } from "Utility/InjectSaga";

import EquipmentCasinoReducer from "Modules/Equipments/Casino/Reducer";
import EquipmentCasinoSaga from "Modules/Equipments/Casino/Saga";
import {
    GetRequestCasinoEquipmentMasterRequestAction,
    GetRequestCasinoEquipmentMasterSuccessAction,
    SetCasinoEquipmentMasterIdAction
} from "Modules/Equipments/Casino/Actions";
import { MakeCasinoEquipmentMasterSelector } from "../Selectors";

const key = "equipmentCasino";;
export const CasinoEquipmentMaster = () => {
    useInjectReducer({ key, reducer: EquipmentCasinoReducer });
    useInjectSaga({ key, saga: EquipmentCasinoSaga });

    // fields
    const dispatch = useDispatch();

    const { id } = useParams();
    const casinoEquipmentMaster = useSelector(MakeCasinoEquipmentMasterSelector());

    useEffect(() => {
        if (id) {
            dispatch(SetCasinoEquipmentMasterIdAction(id));
            dispatch(GetRequestCasinoEquipmentMasterRequestAction());
        }
        return () => {
            dispatch(SetCasinoEquipmentMasterIdAction(null));
            dispatch(GetRequestCasinoEquipmentMasterSuccessAction(null));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        // if (casinoEquipmentMaster) {
        //     navigate(`/equipment/casino/detail/${id}`)
        // }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [casinoEquipmentMaster]);


    return <span>Loading</span>


}