import { InspectionCertificateStatusInitialState } from "Modules/Settings/InspectionCertificateStatus/Reducer";
import { createSelector } from "reselect";

const InspectionCertificateStatusDomain = (state) => state.inspectionCertificateStatus || InspectionCertificateStatusInitialState;

const MakeInspectionCertificateStatusFilterValueSelector = () => createSelector(InspectionCertificateStatusDomain, substate => substate.filterValues);
const MakeInspectionCertificateStatusValueSelector = () => createSelector(InspectionCertificateStatusDomain, substate => substate.values);
const MakeInspectionCertificateStatusIdSelector = () => createSelector(InspectionCertificateStatusDomain, substate => substate.id);

const MakeInspectionCertificateStatusListSelector = () => createSelector(InspectionCertificateStatusDomain, substate => substate.entities);
const MakeInspectionCertificateStatusSelector = () => createSelector(InspectionCertificateStatusDomain, substate => substate.entity);
const MakeInspectionCertificateStatusOptionsSelector = () => createSelector(InspectionCertificateStatusDomain, substate => substate.options);
const MakeInspectionCertificateStatusStatusSelector = () => createSelector(InspectionCertificateStatusDomain, substate => substate.status);

export {
    MakeInspectionCertificateStatusFilterValueSelector,
    MakeInspectionCertificateStatusValueSelector,
    MakeInspectionCertificateStatusIdSelector,
    MakeInspectionCertificateStatusListSelector,
    MakeInspectionCertificateStatusSelector,
    MakeInspectionCertificateStatusOptionsSelector,
    MakeInspectionCertificateStatusStatusSelector
}