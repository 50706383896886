import { createSelector } from "reselect";
import { EquipmentCasinoInitialState } from "Modules/Equipments/Casino/Reducer";

const EquipmentCasinoDomain = (state: any) => state.equipmentCasino || EquipmentCasinoInitialState;
const MakeCasinoEquipmentMasterValueSelector = () => createSelector(EquipmentCasinoDomain, substate => substate.equipmentMasterValue);
const MakeCasinoEquipmentMasterIdSelector = () => createSelector(EquipmentCasinoDomain, substate => substate.equipmentMasterId);
const MakeCasinoEquipmentMasterSelector = () => createSelector(EquipmentCasinoDomain, substate => substate.equipmentMaster)
const MakeGameRuleEquipmentMasterSelector = () => createSelector(EquipmentCasinoDomain, substate => substate.gameRuleEquipmentMaster)
const MakeCasinoEquipmentMasterRedirectSelector = () => createSelector(EquipmentCasinoDomain, substate => substate.redirect);
const MakeEquipmentDetailValueSelector = () => createSelector(EquipmentCasinoDomain, substate => substate.equipmentDetailValue);
const MakeEquipmentDetailIdSelector = () => createSelector(EquipmentCasinoDomain, substate => substate.equipmentDetailId);
export {
    MakeCasinoEquipmentMasterValueSelector,
    MakeCasinoEquipmentMasterIdSelector,
    MakeCasinoEquipmentMasterSelector,
    MakeCasinoEquipmentMasterRedirectSelector,
    MakeGameRuleEquipmentMasterSelector,
    MakeEquipmentDetailValueSelector,
    MakeEquipmentDetailIdSelector
};
