
import { useDispatch, useSelector } from "react-redux";
import { useInjectReducer } from "Utility/InjectReducer";

import { Form, Input, Select, Button } from "antd";
import { EmptyValue } from "Modules/Entity/Operator/Constants";

import CasinoStatusReducer from "Modules/Settings/CasinoStatus/Reducer";
import { MakeSelectCasinoStatusSelector } from "Modules/Settings/CasinoStatus/Selectors";
const casinoStatusKey = "casinoStatus";
export const CasinoFilterForm = ({ form }: any) => {
    useInjectReducer({ key: casinoStatusKey, reducer: CasinoStatusReducer });
    
    // fields
    const dispatch = useDispatch();
    const casinoStatuses = useSelector(MakeSelectCasinoStatusSelector());


    return (
        <Form
            name="entityCasinoListForm"
            layout="vertical"
            form={form}
            initialValues={EmptyValue}
            className="p-4"
        >
            <div className="grid grid-cols-1 md:grid-cols-5">
            <Form.Item name="code" label="លេខសម្គាល់កាស៊ីណូ">
                <Input />
            </Form.Item>
            <Form.Item name="name" label="ឈ្មោះកាស៊ីណូ">
                <Input />
            </Form.Item>
            <Form.Item name="casinoStatusId" label="ស្ថានភាពអាជ្ញាបណ្ណ">
                <Select className="w-full" showSearch allowClear>
                    {casinoStatuses &&
                        casinoStatuses.map((item: any) => (
                            <Select.Option
                                key={item.id}
                                value={item.id}
                            >
                                {item.name}
                            </Select.Option>
                        ))}
                </Select>
                </Form.Item>
                <Form.Item name="area" label="តំបន់">
                    <Select className="w-full" showSearch allowClear>
                        <Select.Option key="SEA" value="SEA">
                            SEA
                        </Select.Option>
                        <Select.Option key="KHT" value="KHT">
                            KHT
                        </Select.Option>
                        <Select.Option key="KHV" value="KHV">
                            KHV
                        </Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item>
                    <Button htmlType="submit" type="default" className="w-full md:w-auto">
                        ស្វែងរក
                    </Button>
                </Form.Item>
            </div>
        </Form>
    );
};
