import React from "react";
import { Form, Row, Col, Input } from "antd";

export const GameTypeForm = ({ form }: any) => {
    return (
        <Form
            name="gameTypeForm"
            className="p-4"
            form={form}
            initialValues={{ id: "", name: "", descriptions: "" }}
        >
            <Row>
                <Col span={4}>ប្រភេទល្បែង</Col>
                <Col span={20}>
                    <Form.Item name="name">
                        <Input />
                    </Form.Item>
                </Col>
                <Col span={4}>បរិយាយ / ផ្សេងៗ</Col>
                <Col span={20}>
                    <Form.Item name="descriptions">
                        <Input.TextArea />
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
};
