import React from "react";

import { useRoutes, Outlet } from "react-router-dom";
import PublicRoute from "Components/PublicRoute";
import PrivateRoute from "Components/PrivateRoute";

// Layouts
import { MainLayout } from "Components/Layouts/Main.Layout";

// Pages
import { NotFound } from "Components/NotFound";
import { AuthenticationRequest } from "Components/Authentication";
import { Dashboard } from "Components/Dashboard";
import { ChangePassword } from "Components/ChangePassword";

// License
import { LicenseRoute } from "Modules/License";
import { CasinoDashboard } from "Components/Dashboard/CasinoDashboard";
import { LotteryDashboard } from "Components/Dashboard/LotteryDashboard";
import { SettingRoute } from "Modules/Settings";
import { CertificateCasinoRoute } from "Modules/Certificate/Casino";
import { EntityRoute } from "Modules/Entity";

// equipments
import { CasinoEquipmentRoute } from "Modules/Equipments/Casino/";
import { EquipmentBindingRoute } from "Modules/Equipments/Casino/Binding";

import { PermissionKeys } from "Modules/Application/Constants";
import { LotteryApplicationFormRoute } from "Modules/Certificate/Lottery";

// print format
import { InspectionCertificatePrintRoute } from "Modules/Settings/InspectionCertificateMaster/Print";
import { ReportRoute } from "Modules/Reporting";

const routes = [
    {
        path: "/authentication/:id",
        element: (
            <PublicRoute>
                <AuthenticationRequest />
            </PublicRoute>
        )

    },
    {
        path: "/",
        element: (
            <PrivateRoute method="get" resource="root" requirePermission={[0]} defaultPermission>
                <MainLayout>
                    <Dashboard />
                </MainLayout>
            </PrivateRoute>
        ),
    },
    {
        path: "/change-password",
        element: (
            <PrivateRoute method="get" resource="root" requirePermission={[0]} defaultPermission>
                <MainLayout>
                    <ChangePassword />
                </MainLayout>
            </PrivateRoute>
        ),
    },

    {
        path: "/authentication/request",
        element: (
            <PublicRoute>
                <AuthenticationRequest />
            </PublicRoute>
        ),
    },
    {
        path: "/settings",
        element: (
            <PrivateRoute method="get" resource="root" requirePermission={[PermissionKeys.REQUEST_CASINO_LICENSE]} defaultPermission={false}>
                <MainLayout>
                    <Outlet />
                </MainLayout>
            </PrivateRoute>
        ),
        children: SettingRoute,
    },
    {
        path: "/license",
        element: (
            <PrivateRoute method="get" resource="root" requirePermission={[PermissionKeys.REQUEST_CASINO_LICENSE]} defaultPermission={false}>
                <MainLayout>
                    <Outlet />
                </MainLayout>
            </PrivateRoute>
        ),
        children: [LicenseRoute]
    },
    {
        path: "/casino",
        element: (
            <PrivateRoute method="get" resource="root" requirePermission={[PermissionKeys.CASINO]} defaultPermission={false}>
                <MainLayout>
                    <CasinoDashboard />
                </MainLayout>
            </PrivateRoute>
        ),
    },
    {
        path: "/lottery",
        element: (
            <PrivateRoute method="get" resource="root" requirePermission={[PermissionKeys.LOTTER]} defaultPermission={false}>
                <MainLayout>
                    <LotteryDashboard />
                </MainLayout>
            </PrivateRoute>
        ),
    },
    {
        path: "/certificate",
        element: (
            <PrivateRoute method="get" resource="root" requirePermission={[]} defaultPermission={false}>
                <MainLayout>
                    <Outlet />
                </MainLayout>
            </PrivateRoute>
        ),
        children: [
            {
                path: "casino",
                element: <Outlet />,
                children: CertificateCasinoRoute,
            },
            LotteryApplicationFormRoute
        ],
    },
    {
        path: "equipment",
        element: (
            <PrivateRoute method="get" resource="root" requirePermission={[]} defaultPermission={false}>
                <MainLayout>
                    <Outlet />
                </MainLayout>
            </PrivateRoute>
        ),
        children: [
            {
                path: "casino",
                element: <Outlet />,
                children: CasinoEquipmentRoute,
            },
            {
                path: "binding",
                element: <Outlet />,
                children: EquipmentBindingRoute,
            }
        ],
    },
    {
        path: "/entity",
        element: (
            <PrivateRoute method="get" resource="root" requirePermission={[PermissionKeys.CASINO]} defaultPermission={false}>
                <MainLayout>
                    <Outlet />
                    
                </MainLayout>
            </PrivateRoute>
        ),
        children: EntityRoute,
    },

    //report

    {
        path: "/print-format",
        element: (
            <PrivateRoute method="get" resource="root" requirePermission={[PermissionKeys.CASINO]} defaultPermission={false}>
                <Outlet />
            </PrivateRoute>
        ),
        children: [InspectionCertificatePrintRoute],
    },
    {
        path: "/reports",
        element: (
            <PrivateRoute method="get" resource="root" requirePermission={[PermissionKeys.CASINO]} defaultPermission={false}>
                <MainLayout>
                    <Outlet />
                </MainLayout>
            </PrivateRoute>
        ),
        children: ReportRoute
    },
    {
        path: "*",
        element: (
            <PublicRoute>
                <NotFound />
            </PublicRoute>
        ),
    },

];

const RootRouter = () => useRoutes(routes);

export default RootRouter;
