import {  useSelector } from "react-redux";
import { useInjectReducer } from "Utility/InjectReducer";
import { Modal, Form, Row, Col, Input, Select } from "antd";
import { useResetFormOnCloseModal } from "Utility/ResetFormOnCloseModal";
import { MakeSelectGameTypeSelector } from "Modules/Settings/GameType/Selectors";

import GameTypeReducer from "Modules/Settings/GameType/Reducer";

type GameTypeProps = {
    visibility: boolean;
    onCancel: any;
};

const gameTypeKey = "gameType";
export const GameTypeModalForm = ({ visibility, onCancel }: GameTypeProps) => {
    useInjectReducer({ key: gameTypeKey, reducer: GameTypeReducer });
    // fields
    const [form] = Form.useForm();
    const gameTypes = useSelector(MakeSelectGameTypeSelector());

    // methods
    useResetFormOnCloseModal({
        form,
        visible: visibility,
    });

    const onSubmit = () => {
        form.submit();
        onCancel();
    };

    return (
        <Modal
            title="ប្រភេទល្បែង"
            open={visibility}
            onOk={onSubmit}
            onCancel={onCancel}
            okText="យល់ព្រម"
            cancelText="បោះបង់"
            okButtonProps={{ type: "default" }}
        >
            <Form
                name="gameTypeModalForm"
                form={form}
                initialValues={{
                    gameTypeId: { key: "", value: "", label: "" },
                    other: "",
                }}
            >
                <Row>
                    <Col span={10}>ប្រភេទល្បែង</Col>
                    <Col span={14}>
                        <Form.Item name="gameTypeId">
                            <Form.Item name="gameTypeId">
                                <Select
                                    labelInValue
                                    style={{ minWidth: 200 }}
                                    filterOption={(input, option) =>
                                        option!.children
                                            .join(" ")
                                            .toLowerCase()
                                            .includes(input.toLowerCase())
                                    }
                                >
                                    {gameTypes &&
                                        gameTypes.map((item) => (
                                            <Select.Option
                                                key={`${item["id"]}`}
                                                value={item["id"]}
                                            >
                                                {item["name"]}
                                            </Select.Option>
                                        ))}
                                </Select>
                            </Form.Item>
                        </Form.Item>
                    </Col>
                    <Col span={10}>ផ្សេងៗ</Col>
                    <Col span={14}>
                        <Form.Item name="other">
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};
