import { createSelector } from "reselect";
import { LotteryLicenseApplicationFormInitialState } from "Modules/License/Lottery/ApplicationForm/Reducer";

const RequestLotteryLicenseDomain = (state: any) => state.lotteryLicenseApplicationForm || LotteryLicenseApplicationFormInitialState;
const MakeRequestLotteryLicenseFilterValueSelector = () => createSelector(RequestLotteryLicenseDomain, substate => substate.filterValues);
const MakeRequestLotteryLicenseValueSelector = () => createSelector(RequestLotteryLicenseDomain, substate => substate.values);
const MakeRequestLotteryLicenseIdSelector = () => createSelector(RequestLotteryLicenseDomain, substate => substate.id);
const MakeRequestLotteryLicenseListSelector = () => createSelector(RequestLotteryLicenseDomain, substate => substate.requestLotteryLicenses);
const MakeRequestLotteryLicenseSelector = () => createSelector(RequestLotteryLicenseDomain, substate => substate.requestLotteryLicense);

export {
    MakeRequestLotteryLicenseFilterValueSelector,
    MakeRequestLotteryLicenseValueSelector,
    MakeRequestLotteryLicenseIdSelector,
    MakeRequestLotteryLicenseListSelector,
    MakeRequestLotteryLicenseSelector,
};
