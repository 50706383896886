import produce from "immer";
import {
    SET_INSPECTION_CERTIFICATE_MASTER_FILTER_VALUE,
    SET_INSPECTION_CERTIFICATE_MASTER_ID,
    SET_INSPECTION_CERTIFICATE_MASTER_VALUE,
    SET_GTIN_EQUIPMENT_TYPE_ID,
    SET_INSPECTION_CERTIFICATE_MASTER_STATUS,
    GET_INSPECTION_CERTIFICATE_MASTER_SUCCESS,
    GET_GTIN_NUMBER_SUCCESS,
    LIST_INSPECTION_CERTIFICATE_MASTER_SUCCESS,
    REQPORT_INSPECTION_CERTIFICATE_DETAIL_SUCCESS,
    SET_REQPORT_INSPECTION_CERTIFICATE_DETAIL_VALUE,

    //DETAIL
    SET_INSPECTION_CERTIFICATE_DETAIL_ID,
    SET_INSPECTION_CERTIFICATE_DETAIL_VALUE,
    SET_LOCAL_GTIN_LATEST_VALUE,
    PRINT_INSPECTION_CERTIFICATE_DETAIL_SUCCESS,
    PRINT_INSPECTION_CERTIFICATE_PRINT_DETAIL_SUCCESS,
    PRINT_INSPECTION_CERTIFICATE_PRINT_BATCH_SUCCESS,
    SET_INSPECTION_CERTIFICATE_SERIALS,
    GET_INSPECTION_CERTIFICATE_SERIALS_SUCCESSS,
} from "Modules/Settings/InspectionCertificateMaster/Constants";

export const InspectionCertificateMasterInitialState = {
    id: null,
    filterValue: null,
    values: null,
    inspectionCertificateMaster: null,
    inspectionCertificateMasters: [],
    inspectionCertificateDetailId: null,
    inspectionCertificateDetailValue: null,
    // 6 print, 7 print batch
    status: 0,
    gtinEquipmentTypeId: null,
    gtin: null,
    gtinLatestValue: null,
    inspectionCertificatePrintDetail: null,
    inspectionCertificatePrintBatch: [],
    inspectionCertificateDetailReport: [],
    reportFilterValue: null,
    inspectionSerials: [],
    serials: [],
}

const InspectionCertificateMasterReducer = produce((draft, action) => {
    switch (action.type) {
        case SET_INSPECTION_CERTIFICATE_MASTER_FILTER_VALUE:
            draft.filterValue = action.values;
            break;
        case SET_INSPECTION_CERTIFICATE_MASTER_ID:
            draft.id = action.id;
            break;
        case SET_INSPECTION_CERTIFICATE_MASTER_VALUE:
            draft.values = action.values;
            break;
        case GET_INSPECTION_CERTIFICATE_MASTER_SUCCESS:
            draft.inspectionCertificateMaster = action.inspectionCertificateMaster;
            break;
        case LIST_INSPECTION_CERTIFICATE_MASTER_SUCCESS:
            draft.inspectionCertificateMasters = action.inspectionCertificateMasters;
            break;
        case SET_INSPECTION_CERTIFICATE_DETAIL_ID:
            draft.inspectionCertificateDetailId = action.id;
            break;
        case SET_INSPECTION_CERTIFICATE_DETAIL_VALUE:
            draft.inspectionCertificateDetailValue = action.values;
            break;
        case SET_GTIN_EQUIPMENT_TYPE_ID:
            draft.gtinEquipmentTypeId = action.id;
            break;
        case SET_INSPECTION_CERTIFICATE_MASTER_STATUS:
            draft.status = action.status;
            break;
        case GET_GTIN_NUMBER_SUCCESS:
            draft.gtin = action.gtin;
            break;
        case PRINT_INSPECTION_CERTIFICATE_DETAIL_SUCCESS:
            draft.status = 6;
            break;
        case PRINT_INSPECTION_CERTIFICATE_PRINT_DETAIL_SUCCESS:
            draft.inspectionCertificatePrintDetail = action.detail;
            break;
        case PRINT_INSPECTION_CERTIFICATE_PRINT_BATCH_SUCCESS:
            draft.inspectionCertificatePrintBatch = action.details;
            break;
        case SET_LOCAL_GTIN_LATEST_VALUE:
            draft.gtinLatestValue = action.gtin;
            break;
        case REQPORT_INSPECTION_CERTIFICATE_DETAIL_SUCCESS:
            draft.inspectionCertificateDetailReport = action.reports;
            break;
        case SET_REQPORT_INSPECTION_CERTIFICATE_DETAIL_VALUE:
          draft.reportFilterValue = action.values;
          break;
          case SET_INSPECTION_CERTIFICATE_SERIALS:
            draft.serials = action.serials;
            break;
        case GET_INSPECTION_CERTIFICATE_SERIALS_SUCCESSS:
            draft.inspectionSerials = action.inspectionSerials;
            break;
        default: break;
    }
}, InspectionCertificateMasterInitialState);

export default InspectionCertificateMasterReducer;