
import { InspectionCertificatePrint } from "Modules/Settings/InspectionCertificateMaster/Print/Print";
import { InspectionCertificatePrintSerials } from "Modules/Settings/InspectionCertificateMaster/Print/PrintSerials";

export const InspectionCertificatePrintRoute = {
    path: "inspection-certificate",
    children: [
        { path: "print/:id", element: <InspectionCertificatePrint /> },
        { path: "print-serials/:id", element: <InspectionCertificatePrintSerials /> },
    ],
};
