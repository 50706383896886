import produce from "immer";
import {
    EntityCompanyListConstant,
    GET_ENTITY_COMPANY_BY_ID_SUCCESS,
    CREATE_COMPANY_MODAL_SUCCESS,
} from "Modules/Entity/Company/Constants";

export const initialState = {
    companies: [],
    companyOptions: [],
    errors: null,
    formValues: null,
    values: null,
    company: null,
    companyDetailModal: null,
};

const EntityCompanyListReducer = produce((draft, action) => {
    switch (action.type) {
        case EntityCompanyListConstant.SELECT_COMPANY_SUCCESS:
            draft.companyOptions = action.companyOptions;
            break;
        case EntityCompanyListConstant.SET_FORM_VALUES:
            draft.formValues = action.formValues;
            break;
        case EntityCompanyListConstant.SET_COMPANY_VALUES:
            draft.values = action.values;
            break;
        case EntityCompanyListConstant.ENTITY_COMPANY_LIST_SUCCESS:
            draft.companies = action.companies;
            break;
        case GET_ENTITY_COMPANY_BY_ID_SUCCESS:
            draft.company = action.company;
            break;
        case CREATE_COMPANY_MODAL_SUCCESS:
            draft.companyDetailModal = action.companyDetailModal;
            break;
    }
}, initialState);

export default EntityCompanyListReducer;
