import { Create } from "./Create";
import { LotteryBranchApplicationFormList } from "./List";

export const LotteryBranchApplicationFormRoute = {
    path: "request-license-branch",
    children: [
        { path: "", element: <LotteryBranchApplicationFormList /> },
        { path: "create", element: <Create /> },
    ],
};
