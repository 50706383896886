import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useInjectReducer } from "Utility/InjectReducer";
import { useInjectSaga } from "Utility/InjectSaga";

import {
    DatePicker,
    Form, 
    Input, 
    Select, 
    Tabs,
    Row,
    Col,
    Radio,
    Checkbox,
    Button,
    Space,
} from "antd";

import EntityOperatorReducer from "Modules/Entity/Operator/Reducer";
import EntityOperatorSaga from "Modules/Entity/Operator/Saga";
import { MakeSelectCasinoSelector } from "Modules/Entity/Operator/Selectors";
import { SelectCasinoRequestAction, SelectCasinoSuccessAction } from "Modules/Entity/Operator/Actions";

const casinoKey = "entityCasino";
export const  LotteryBranchApplicationFormCreate = ({setLotteryGameTypeVisible} :any) => {
    useInjectReducer({ key: casinoKey, reducer: EntityOperatorReducer });
    useInjectSaga({ key: casinoKey, saga: EntityOperatorSaga });
    // fields
    const dispatch = useDispatch();
    const operatorOptions = useSelector(MakeSelectCasinoSelector());

    // constructor 
    useEffect(() => {
        dispatch(SelectCasinoRequestAction());

        return () => {
            dispatch(SelectCasinoSuccessAction([]));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Form 
            name="requestLotteryLicenseCreateForm"
            layout="vertical"
            className="p-5"
            initialValues={{
                formData: {
                    singleShareType: 1,
                    hasBranch: true,
                    representativeType: false
                }
            }}>
            <Form.Item name="id" hidden>
                <Input />
            </Form.Item>
            <Tabs defaultActiveKey="1"
                items={[
                    {
                        key: "1",
                        label: "សម្រាប់មន្ត្រី គ.ល.ក.",
                        forceRender: true,
                        children: <>
                            <Form.Item name="code" hidden={true}>
                                <Input />
                            </Form.Item>
                            <Row gutter={16}>
                                <Col span={5}>
                                    លេខសំគាល់ពាក្យស្នើសុំ
                                </Col>
                                <Col span={7}>
                                    <Form.Item name="documentCode">
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col span={5}>ឈ្មោះអ្នកទទួល</Col>
                                <Col span={7}>
                                    <Form.Item name="receiverName">
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col span={5}>
                                    លេខកូដចំណូលកាតព្វកិច្ចពីល្បែង
                                </Col>
                                <Col span={7}>
                                    <Form.Item name="gamblingTaxCode">
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col span={5}>កាលបរិច្ឆេទទទួល</Col>
                                <Col span={7}>
                                    <Form.Item name="receivedDate">
                                        <DatePicker placeholder="កាលបរិច្ឆេទទទួល" />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </>
                    },
                    {
                        key: "2",
                        label: "ព័ត៌មានអ្នកដាក់ពាក្យស្នើសុំ",
                        forceRender: true,
                        children: <>
                            <Row>
                                <Col span={6}>
                                    តួនាទីអ្នកដាកពាក្យស្នើសុំ
                                    <br />
                                    Position of Applicant
                                </Col>
                                <Col span={18}>
                                    <Form.Item
                                        name={[
                                            "formData",
                                            "applicant",
                                            "designatureId",
                                        ]}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row>
                                <Col span={6}>
                                    គោត្តនាម និងនាម
                                    <br />
                                    Last name and First name
                                </Col>
                                <Col span={18}>
                                    <Row>
                                        <Col span={6}>
                                            អក្សរខ្មែរ
                                            <br />
                                            in Khmer
                                        </Col>
                                        <Col span={18}>
                                            <Form.Item
                                                name={[
                                                    "formData",
                                                    "applicant",
                                                    "fullname",
                                                ]}
                                                rules={[
                                                    { required: true },
                                                ]}
                                            >
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                        <Col span={6}>
                                            អក្សរឡាតាំង
                                            <br />
                                            in Latin
                                        </Col>
                                        <Col span={18}>
                                            <Form.Item
                                                name={[
                                                    "formData",
                                                    "applicant",
                                                    "latinFullname",
                                                ]}
                                                rules={[
                                                    { required: true },
                                                ]}
                                            >
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={6}>
                                    ភេទ
                                    <br />
                                    Sex
                                </Col>
                                <Col span={18}>
                                    <Form.Item
                                        name={[
                                            "formData",
                                            "applicant",
                                            "gender",
                                        ]}
                                        rules={[{ required: true }]}
                                    >
                                        <Radio.Group>
                                            <Space direction="horizontal">
                                                <Radio value={1}>
                                                    ប្រុស / Male
                                                </Radio>
                                                <Radio value={2}>
                                                    ស្រី / Female
                                                </Radio>
                                            </Space>
                                        </Radio.Group>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={6}>
                                    ថ្ងៃខែឆ្នាំកំណើត
                                    <br />
                                    Date of Birth
                                </Col>
                                <Col span={18}>
                                    <Form.Item
                                        name={[
                                            "formData",
                                            "applicant",
                                            "dateOfBirth",
                                        ]}
                                        rules={[{ required: true }]}
                                    >
                                        <DatePicker placeholder="ថ្ងៃខែឆ្នាំកំណើត" />
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    សញ្ជាតិ
                                    <br />
                                    Nationality
                                </Col>
                                <Col span={18}>
                                    <Form.Item
                                        name={[
                                            "formData",
                                            "applicant",
                                            "nationality",
                                        ]}
                                        rules={[{ required: true }]}
                                    >
                                        <Input placeholder="សញ្ជាតិ / Nationality" />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={8}>
                                    <Form.Item
                                        name={[
                                            "formData",
                                            "applicant",
                                            "identityTypeId",
                                        ]}
                                        rules={[{ required: true }]}
                                    >
                                        <Radio.Group>
                                            <Space direction="vertical">
                                                {/* {identificationTypes &&
                                                    identificationTypes.map(
                                                        (
                                                            identificationType: any,
                                                            index: number
                                                        ) => (
                                                            <Radio
                                                                key={
                                                                    identificationType.value
                                                                }
                                                                value={
                                                                    identificationType.value
                                                                }
                                                            >
                                                                {
                                                                    identificationType.label
                                                                }
                                                            </Radio>
                                                        )
                                                    )} */}
                                            </Space>
                                        </Radio.Group>
                                    </Form.Item>
                                </Col>
                                <Col span={16}>
                                    <Form.Item
                                        name={[
                                            "formData",
                                            "applicant",
                                            "identityNumber",
                                        ]}
                                        rules={[
                                            {
                                                required: true,
                                                message:
                                                    "Identificaiton number is required",
                                            },
                                        ]}
                                    >
                                        <Input placeholder="សូមបំពេញ" />
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    ថ្ងៃខែឆ្នាំផ្តល់ / Issued Date
                                </Col>
                                <Col span={18}>
                                    <Form.Item
                                        name={[
                                            "formData",
                                            "applicant",
                                            "identityIssuedDate",
                                        ]}
                                        rules={[{ required: true }]}
                                    >
                                        <DatePicker />
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    ថ្ងៃខែឆ្នាំផុតកំណត / Date of Expiry
                                </Col>
                                <Col span={18}>
                                    <Form.Item
                                        name={[
                                            "formData",
                                            "applicant",
                                            "expiredDate",
                                        ]}
                                        rules={[{ required: true }]}
                                    >
                                        <DatePicker />
                                    </Form.Item>
                                </Col>

                                <Col span={6}>អាសយដ្ឋាន / Address</Col>
                                <Col span={18}>
                                    <Form.Item
                                        name={[
                                            "formData",
                                            "applicant",
                                            "address",
                                        ]}
                                        rules={[{ required: true }]}
                                    >
                                        <Input placeholder="អាសយដ្ឋាន / Address" />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={6}>
                                    ទូរស័ព្ទចល័ត / Mobile Phone Number
                                </Col>
                                <Col span={18}>
                                    <Form.Item
                                        name={[
                                            "formData",
                                            "applicant",
                                            "mobilePhoneNumber",
                                        ]}
                                        rules={[{ required: true }]}
                                    >
                                        <Input placeholder="ទូរស័ព្ទចល័ត / Mobile Phone Number" />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={6}>
                                    សារអេឡិចត្រូនិច / Email Address
                                </Col>
                                <Col span={18}>
                                    <Form.Item
                                        name={[
                                            "formData",
                                            "applicant",
                                            "emailAddress",
                                        ]}
                                        rules={[{ required: true }]}
                                    >
                                        <Input placeholder="សារអេឡិចត្រូនិច / Email Address" />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </>
                    },
                    {
                        key: "3",
                        label: "ព័ត៌មានទូទៅរបស់ក្រុមហ៊ុន",
                        forceRender: true,
                        children: <>
                            <Row>
                                <Col span={6}>
                                    ឈ្មោះក្រុមហ៊ុន / Company Name
                                </Col>
                                <Col span={18}>
                                    <Form.Item
                                        name="casinoId"
                                        rules={[{ required: true }]}
                                    >
                                        <Select showSearch
                                            // onChange={onOperatorChange}
                                            filterOption={(input, option) =>

                                                option!.children.toString()
                                                    .toLowerCase()
                                                    .includes(
                                                        input.toLowerCase()
                                                    )
                                            }>
                                            {
                                                operatorOptions && operatorOptions.map(item => <Select.Option key={item.value} value={item.value}>{item.label}</Select.Option>)
                                            }

                                        </Select>
                                    </Form.Item>
                                </Col>

                                <Col span={6}>
                                    អាសយដ្ឋានក្រុមហ៊ុន / Address
                                </Col>
                                <Col span={18}>
                                    <Form.Item
                                        name={[
                                            "formData",
                                            "operator",
                                            "address",
                                        ]}
                                    >
                                        <Input
                                            placeholder="អាសយដ្ឋានក្រុមហ៊ុន / Address"
                                            readOnly
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </>
                    },
                    {
                        key: "4",
                        label: "ព័ត៌មានរបស់សាខាល្បែងផ្សងសំណាងកំពុងដំណើការ",
                        forceRender: true,
                        children: <>
                            <Row>
                                <Col span={6}>
                                    លេខអាជ្ញាបណ្ណសាខា / Number of Branch License
                                </Col>
                                <Col span={18}>
                                <Form.Item name="branchCode" className="flex-1 grow">
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                
                                <Col span={6}>
                                    សុពលភាពអាជ្ញាបណ្ណ / expired date of License
                                </Col>
                                <Col span={18}>
                                <Form.Item name="branchCode" className="flex-1 grow">
                                        <DatePicker />
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    កម្មវត្ថុអាជីវកម្ម(ប្រភេទល្បែងផសងសំណាង) <br />
                                    Purpose of Business (Type of game of chance)
                                </Col>
                                
                                <Col span={16}>
                                    <Form.Item
                                        shouldUpdate={(preValues, curValues) =>
                                            preValues.gameTypes !==
                                            curValues.gameTypes
                                        }
                                    >
                                        {({ getFieldValue }: any) => {
                                            const gameTypes: any[] =
                                                getFieldValue("gameTypes") || [];

                                            return (<div className="grid grid-cols-2 gap-2">
                                                {gameTypes.map((item: any, index: number) => <div className="flex flex-row gap-4" key={item.id}><span>{index + 1}</span><span>{item.gameType.label}</span></div>)}
                                            </div>
                                            );
                                        }}
                                    </Form.Item>
                                    <Button className="pb-4" onClick={() => setLotteryGameTypeVisible(true)}>បន្ថែមថ្មី</Button>
                                </Col>
                                <Col span={6}>
                                    ការចេញលទ្ធផល / Result
                                </Col>
                                <Col span={18}>
                                    <Form.Item name="resultPerWeek">
                                        <Input prefix="ចំនួន/Number" suffix="ថ្ងៃ/សប្តាហ៍ Day/Week"/>
                                    </Form.Item>
                                    <Form.Item name="resultPerDay">
                                        <Input  prefix="ចំនួន/Number" suffix="ដង/ថ្ងៃ Time/Day"/>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </>
                    },
                    {
                        key: "5",
                        label: "ព័ត៌មានអាជីវកម្មរបស់សាខាស្នើសុំថ្មី",
                        forceRender: true,
                        children: <>
                            <Row>
                                <Col span={6}>
                                    អាសយដ្ឋានរបស់សាខាថ្មី <br />
                                    Address of Branch
                                </Col>
                                <Col span={18}>
                                    <Form.Item name="branchAddress">
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    ទីតាំងនេះជាកម្មសិទ្ធិ ឬជួល?<br />
                                    Is this above Location an Owned or Rented Property?
                                </Col>
                                <Col span={18}>
                                    <Form.Item name="own">
                                        <Radio.Group>
                                            <Space direction="vertical">
                                                <Radio value={false}>
                                                    កម្មសិទ្ធិ / Owned
                                                </Radio>
                                                <Radio value={true}>
                                                    ជួល / Rented
                                                    
                                                </Radio>
                                            </Space>
                                        </Radio.Group>
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    កម្មវត្ថុអាជីវកម្មសាខា <br />
                                    (ប្រភេទល្បែងផ្សងសំណាង)<br />
                                    Purpose of Branch Business<br />
                                    (Type of game of chance)
                                </Col>
                                <Col span={18}>
                                    <Button type="default">បន្ថែមថ្មី</Button>
                                </Col>
                                <Col span={6}>
                                    ការចេញលទ្ធផល / Result
                                </Col>
                                <Col span={18}>
                                    <Form.Item name="resultPerWeek">
                                        <Input prefix="ចំនួន/Number" suffix="ថ្ងៃ/សប្តាហ៍ Day/Week"/>
                                    </Form.Item>
                                    <Form.Item name="resultPerDay">
                                        <Input  prefix="ចំនួន/Number" suffix="ដង/ថ្ងៃ Time/Day"/>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </>
                    },
                    {
                        key: "6",
                        label: "រចនាសម្ព័ន្ធគ្រប់គ្រងក្រុមហ៊ុន",
                        forceRender: true,
                        children: <>
                            <Row>
                                <Col span={8}>
                                    កម្មវត្ថុអាជីវកម្ម(ប្រភេទល្បែងផសងសំណាង)<br />
                                    Purpose of Business(Type of game of chance)
                                </Col>
                                <Col span={16}>
                                    <Form.Item
                                        shouldUpdate={(preValues, curValues) =>
                                            preValues.gameTypes !==
                                            curValues.gameTypes
                                        }
                                    >
                                        {({ getFieldValue }: any) => {
                                            const gameTypes: any[] =
                                                getFieldValue("gameTypes") || [];

                                            return (<div className="grid grid-cols-2 gap-2">
                                                {gameTypes.map((item: any, index: number) => <div className="flex flex-row gap-4" key={item.id}><span>{index + 1}</span><span>{item.gameType.label}</span></div>)}
                                            </div>
                                            );
                                        }}
                                    </Form.Item>
                                    {/* <Button onClick={() => setLotteryGameTypeVisible(true)}>បន្ថែមថ្មី</Button> */}
                                </Col>

                            </Row>
                        </>
                    },
                    
                    {
                        key: "7",
                        label: "ព័ត៌មានរបស់បុគ្គលឈរឈ្មោះក្នុងអាជ្ញាបណ្ណ",
                        forceRender: true,
                        children: <>
                            <Row>
                                <Col span={8}>
                                    ស្ថានភាពអ្នកដាក់ពាក្យស្នើសុំ
                                    និងបុគ្គលឈរឆ្មោះក្នុអាជ្ញាបណ្ណ
                                    <br />
                                    Status of the Applicant and License
                                    Representative
                                </Col>
                                <Col span={16}>
                                    <Form.Item
                                        name={["formData", "representativeType"]}
                                    // valuePropName="value"
                                    >
                                        {/* <Radio.Group
                                            onChange={onApplicantStatusChange}
                                        >
                                            <Space direction="vertical">
                                                <Radio value={false}>
                                                    ជាបុគ្គលតែមួយ / Same Person
                                                </Radio>
                                                <Radio value={true}>
                                                    ជាបុគ្គលផ្សេងគ្នា / Different
                                                    Person
                                                </Radio>
                                            </Space>
                                        </Radio.Group> */}
                                    </Form.Item>
                                    <p>
                                        (សូមបន្តបំពេញប័ត៌មានខាងក្រោម/Please Provider
                                        the Information Below)
                                    </p>
                                </Col>
                            </Row>
                            {true === true ? (
                                <Row>
                                    <Col span={6}>
                                        គោត្តនាម និងនាម
                                        <br />
                                        Last name and First name
                                    </Col>
                                    <Col span={18}>
                                        <Row>
                                            <Col span={8}>
                                                អក្សរខ្មែរ <br />
                                                in Khmer
                                            </Col>
                                            <Col span={16}>
                                                <Form.Item
                                                    name={[
                                                        "formData",
                                                        "representative",
                                                        "khmerName",
                                                    ]}
                                                >
                                                    <Input />
                                                </Form.Item>
                                            </Col>
                                            <Col span={8}>
                                                អក្សរឡាតាំង <br />
                                                in Latin
                                            </Col>
                                            <Col span={16}>
                                                <Form.Item
                                                    name={[
                                                        "formData",
                                                        "representative",
                                                        "englishName",
                                                    ]}
                                                >
                                                    <Input />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Col>

                                    <Col span={6}>
                                        ភេទ
                                        <br />
                                        Sex
                                    </Col>
                                    <Col span={18}>
                                        <Form.Item
                                            name={[
                                                "formData",
                                                "representative",
                                                "gender",
                                            ]}
                                        >
                                            <Radio.Group>
                                                <Space direction="horizontal">
                                                    <Radio value="male">
                                                        ប្រុស / Male
                                                    </Radio>
                                                    <Radio value="female">
                                                        ស្រី / Female
                                                    </Radio>
                                                </Space>
                                            </Radio.Group>
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        ថ្ងៃខែឆ្នាំកំណើត
                                        <br />
                                        Date of Birth
                                    </Col>
                                    <Col span={18}>
                                        <Form.Item
                                            name={[
                                                "formData",
                                                "representative",
                                                "dateOfBirth",
                                            ]}
                                        >
                                            <DatePicker placeholder="ថ្ងៃខែឆ្នាំកំណើត" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        សញ្ជាតិ
                                        <br />
                                        Nationality
                                    </Col>
                                    <Col span={18}>
                                        <Form.Item
                                            name={[
                                                "formData",
                                                "representative",
                                                "nationality",
                                            ]}
                                        >
                                            <Input placeholder="សញ្ជាតិ / Nationality" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item
                                            name={[
                                                "formData",
                                                "representative",
                                                "identityTypeId",
                                            ]}
                                        >
                                            <Radio.Group>
                                                <Space direction="vertical">
                                                    {/* {
                                                        identificationTypes &&
                                                        identificationTypes.map(
                                                            (
                                                                identificationType: any,
                                                                index: number
                                                            ) => (
                                                                <Radio
                                                                    key={
                                                                        identificationType.value
                                                                    }
                                                                    value={
                                                                        identificationType.value
                                                                    }
                                                                >
                                                                    {
                                                                        identificationType.label
                                                                    }
                                                                </Radio>
                                                            )
                                                        )} */}
                                                </Space>
                                            </Radio.Group>
                                        </Form.Item>
                                    </Col>
                                    <Col span={16}>
                                        <Form.Item
                                            name={[
                                                "formData",
                                                "representative",
                                                "identityNumber",
                                            ]}
                                        >
                                            <Input placeholder="សូមបំពេញ" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        ថ្ងៃខែឆ្នាំផ្តល់ / Issued Date
                                    </Col>
                                    <Col span={18}>
                                        <Form.Item
                                            name={[
                                                "formData",
                                                "representative",
                                                "issuedDate",
                                            ]}
                                        >
                                            <DatePicker placeholder="ថ្ងៃខែឆ្នាំកំណើត" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        ថ្ងៃខែឆ្នាំផុតកំណត / Date of Expiry
                                    </Col>
                                    <Col span={18}>
                                        <Form.Item
                                            name={[
                                                "formData",
                                                "representative",
                                                "expiredDate",
                                            ]}
                                        >
                                            <DatePicker placeholder="ថ្ងៃខែឆ្នាំកំណើត" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        តួនាទីក្នុងក្រុមហ៊ុន / Position in Company
                                    </Col>
                                    <Col span={18}>
                                        <Form.Item
                                            name={[
                                                "formData",
                                                "representative",
                                                "designatureId",
                                            ]}
                                        >
                                            <Input placeholder="តួនាទីក្នុងក្រុមហ៊ុន / Position in Company" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>អាសយដ្ឋាន / Address</Col>
                                    <Col span={18}>
                                        <Form.Item
                                            name={[
                                                "formData",
                                                "representative",
                                                "address",
                                            ]}
                                        >
                                            <Input placeholder="អាសយដ្ឋាន / Address" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        ទូរស័ព្ទចល័ត / Mobile Phone Number
                                    </Col>
                                    <Col span={18}>
                                        <Form.Item
                                            name={[
                                                "formData",
                                                "representative",
                                                "mobilePhoneNumber",
                                            ]}
                                        >
                                            <Input placeholder="ទូរស័ព្ទចល័ត / Mobile Phone Number" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        សារអេឡិចត្រូនិច / Email Address
                                    </Col>
                                    <Col span={18}>
                                        <Form.Item
                                            name={[
                                                "formData",
                                                "representative",
                                                "emailAddress",
                                            ]}
                                        >
                                            <Input placeholder="សារអេឡិចត្រូនិច / Email Address" />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            ) : (
                                <span></span>
                            )}
                        </>
                    },

                ]}
            />

        </Form>
        
    )
}