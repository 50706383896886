import { useDispatch, useSelector } from "react-redux";
import { Link, useSearchParams } from "react-router-dom";
import { useInjectSaga } from "Utility/InjectSaga";
import { useInjectReducer } from "Utility/InjectReducer";
import Reducer from "Modules/Entity/Operator/Reducer";
import Saga from "Modules/Entity/Operator/Saga";
import { Button, Form, Table } from "antd";
import { FullLayout } from "Components/Layouts/";
import { HelmetHeader } from "Components/Helmet-Header";
import { CasinoFilterForm } from "Modules/Entity/Operator/Forms/CasinoFilterForm";

import {
    SetFormValuesAction,
    EntityCasinoListRequestAction,
    ExportEntityCasinoRequestAction,
    EntityCasinoListSuccessAction,
} from "Modules/Entity/Operator/Actions";
import { MakeSelectEntityCasinoListSelector } from "Modules/Entity/Operator/Selectors";
import { EntityType } from "Common/Constants/EntityType";
import { LotteryFilterForm } from "../Forms/LotteryFilterForm";
import { useEffect } from "react";
import { GetCasinoStatusOptions } from "Modules/Settings/CasinoStatus/Service";
import { SelectCasinoStatusSuccessAction } from "Modules/Settings/CasinoStatus/Actions";

const key = "entityCasino";
export const CasinoList = () => {
    useInjectReducer({ key, reducer: Reducer });
    useInjectSaga({ key, saga: Saga });
    //fields
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [searchParams] = useSearchParams();
    const entityType = searchParams.get("entityType");
    const casinos = useSelector(MakeSelectEntityCasinoListSelector());

    const casinoColums = [
        {
            title: "កូដ",
            key: "code",
            render: (item: any) => (
            <Link
                    to={`/entity/operator/${item.id}?entityType=${entityType}`}
                    className="underline text-blue-500"
                >
                    {item.code}
                </Link>
            ),
        },
        {
            title: entityType === EntityType.LOTTERY ? "នាមករណ៍ល្បែងផ្សងសំណាង" : "នាមករណ៍កាស៊ីណូ",
            key: "casinoName",
            dataIndex: "casinoName",
        },
        {
            title: "នាមករណ៍ក្រុមហ៊ុន",
            key: "companyName",
            dataIndex: "companyName",
        },
        // {
        //     title: "ប្រភេទកាស៊ីណូ",
        //     key: "casinoType",
        //     dataIndex: "casinoType",
        // },
        {
            title: "អាសយដ្ឋានធ្វើអាជីវកម្ម",
            key: "address",
            dataIndex: "address",
        },
        {
            title: "ស្ថានភាពអាជ្ញាបណ្ណ",
            key: "casinoStatus",
            render: (item) => (
                <span>
                    {
                      item.casinoStatus === "79a726ad-8a2e-4ccb-afa8-04a0d0c398d6" ? "គ្មានអាជ្ញាបណ្ណ" :
                      item.casinoStatus === "fecb16b5-e956-4a20-9d9e-a078bce3a47d" ? "មានអាជ្ញាបណ្ណ" : "ផុតសុពលភាព"
                    }
                </span>
            ),
        },
    ];

    // constructors
    useEffect(() => {
        const onLoad = async () => {
            const detail = await GetCasinoStatusOptions(dispatch);
            return detail;
        }
        onLoad()
        .then((detail: any) => {
            dispatch(SelectCasinoStatusSuccessAction(detail));
            const entityTypeId = entityType === "LOTTERY" ? 2 : 1;
            const model = {
                entityTypeId: entityTypeId
            }
            dispatch(SetFormValuesAction(model));
            dispatch(EntityCasinoListRequestAction());
        })
        .catch((ex: any) => console.log("Erro",ex));
        
        
        return () => {
            dispatch(EntityCasinoListSuccessAction([]));
            dispatch(SelectCasinoStatusSuccessAction([]));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [entityType])

    // method
    const ActionButton = () => {
        return (
            <>
                <Button
                    onClick={onExportExcel}
                    type="primary"
                    className="hidden md:visible"
                >
                    Excel
                </Button>
                <Link
                    to={`/entity/operator/create?entityType=${entityType}`}>
                    <Button type="primary">
                        បញ្ចូលថ្មី
                    </Button>
                </Link>
            </>
        );
    };
    // events
    const onFormProviderSubmit = (name: string, { forms, values }: any) => {
        switch (name) {
            case "entityCasinoListForm": {
                onFilterFormSubmit(values);
                break;
            }
        }
    };

    const onFilterFormSubmit = (values: any) => {
        const entityTypeId = entityType === "LOTTERY" ? 2 : 1;
        const model = {
            ...values,
            entityTypeId: entityTypeId
        }
        dispatch(SetFormValuesAction(model));
        dispatch(EntityCasinoListRequestAction());
    };

    const onExportExcel = () => {
        dispatch(SetFormValuesAction(form.getFieldsValue()));
        dispatch(ExportEntityCasinoRequestAction());
    };

    // render
    return (
        <FullLayout
            icon={null}
            title={entityType === EntityType.LOTTERY ? "បញ្ជីឈ្មោះប្រតិបត្តិករ ល្បែងផ្សងសំណាង" : "បញ្ជីឈ្មោះកាស៊ីណូ"}
            descriptions=""
            actions={<ActionButton />}
        >
            <HelmetHeader meta="បញ្ជីកាស៊ីណូ" />
            <Form.Provider onFormFinish={onFormProviderSubmit}>
                {
                    entityType === EntityType.LOTTERY ? <LotteryFilterForm form={form} /> : <CasinoFilterForm form={form} />
                }
                <Table
                    columns={casinoColums}
                    dataSource={casinos}
                    rowKey="id"
                    className="min-w-[600px] w-full overflow-visible"
                    size="small"
                />
            </Form.Provider>
        </FullLayout>
    );
};
