import { call, put, all, select, takeLatest } from "redux-saga/effects";
import Request from "Utility/Http";
import ApiEndpoint from "Utility/Endpoint";
import { AsyncStartAction, AsyncEndAction } from "Modules/Application/Actions";
import {
    EntityCompanyListConstant,
    GET_ENTITY_COMPANY_BY_ID_REQUEST,
    CREATE_COMPANY_MODAL_REQUEST,
    EDIT_ENTITY_COMPANY_REQUEST,
} from "./Constants";
import { POST, GET, PUT } from "Utility/Constant";
import { ShowAlert, ShowMessage } from "Common/Saga/";
import {
    MakeselectFormValueSelector,
    MakeCompanyValueSelector,
} from "Modules/Entity/Company/Selectors";
import { ClearSnackMessageAction } from "Components/SnackMessage/Actions";
import {
    EntityCompanyListSuccessAction,
    SelectCompanySuccessAction,
    GetEntityCompanyByIdSuccessAction,
    CreateCompanyModalSuccessAction,
} from "Modules/Entity/Company/Actions";
import { v4 as uuid } from "uuid";
import { getSelectableIdentificationType } from "Modules/License/Casino/Saga";

export function* GetCompanyList(): any {
    yield put(AsyncStartAction());
    const requestUrl = ApiEndpoint.COMPANY_LIST_PATH;
    const formValues = yield select(MakeselectFormValueSelector());
    const httpPayload = ApiEndpoint.makeApiPayload(
        requestUrl,
        POST,
        formValues
    );
    try {
        const response = yield call(Request, httpPayload);
        yield put(AsyncEndAction());
        yield put(ClearSnackMessageAction());
        yield put(EntityCompanyListSuccessAction(response));
    } catch (error: any) {
        yield put(AsyncEndAction());
        if (error.data && error.data.statusCode === 422) {
            // return yield put(EnterValidationErrorAction(error.data.message));
        }
        return yield ShowAlert("error", error.data.message);
    }
}

export function* SelectCompany() {
    yield put(AsyncStartAction());
    const requestUrl = ApiEndpoint.COMPANY_SELECT_PATH;

    const payload = ApiEndpoint.makeApiPayload(requestUrl, GET);

    try {
        const response = yield call(Request, payload);
        yield put(AsyncEndAction());
        yield put(ClearSnackMessageAction());
        yield put(SelectCompanySuccessAction(response));
    } catch (error: any) {}
}

export function* CreateCompany() {
    yield put(AsyncStartAction());
    const requestUrl = ApiEndpoint.COMPANY_CREATE_PATH;
    const values = yield select(MakeCompanyValueSelector());
    const payload = ApiEndpoint.makeApiPayload(
        requestUrl,
        POST,
        values,
        null,
        false,
        true
    );
    try {
        const response = yield call(Request, payload);
        yield put(AsyncEndAction());
        if (response && response?.id) {
            yield ShowMessage({
                type: "success",
                message: "Success",
                content: "Test",
                id: uuid(),
                redirectUrl: "/entity/company",
            });
        }
    } catch (error: any) {
        if (error.data && error.data.statusCode === 422) {
            // return yield put(EnterValidationErrorAction(error.data.message));
        }
        return yield ShowAlert("error", error.data.message);
    }
}

export function* GetCompanyById() {
    yield put(AsyncStartAction());
    const values = yield select(MakeCompanyValueSelector());
    const requestUrl = `${ApiEndpoint.COMPANY_DETAIL_PATH}${values}`;
    const payload = ApiEndpoint.makeApiPayload(requestUrl, GET, values);
    try {
        const response = yield call(Request, payload);
        yield put(GetEntityCompanyByIdSuccessAction(response));
        yield put(AsyncEndAction());
        yield getSelectableIdentificationType();
    } catch (error: any) {
        if (error.data && error.data.statusCode === 422) {
            // return yield put(EnterValidationErrorAction(error.data.message));
        }
        return yield ShowAlert("error", error.data.message);
    }
}

export function* CreateCompanyModal() {
    yield put(AsyncStartAction());
    const requestUrl = ApiEndpoint.COMPANY_CREATE_PATH;
    const values = yield select(MakeCompanyValueSelector());
    const payload = ApiEndpoint.makeApiPayload(
        requestUrl,
        POST,
        values,
        null,
        false,
        true
    );
    try {
        const response = yield call(Request, payload);
        yield put(AsyncEndAction());
        if (response && response?.id) {
            yield ShowMessage({
                type: "success",
                message: "ទិន្នន័យបានរក្សារទុក",
                content: "ទិន្នន័យបានរក្សារទុក",
                id: uuid(),
            });
            yield put(CreateCompanyModalSuccessAction(response));
        }
    } catch (error: any) {
        if (error.data && error.data.statusCode === 422) {
            // return yield put(EnterValidationErrorAction(error.data.message));
        }
        return yield ShowAlert("error", error.data.message);
    }
}

export function* EditCompany() {
    yield put(AsyncStartAction());
    const values = yield select(MakeCompanyValueSelector());
    const requestUrl = `${ApiEndpoint.COMPANY_PATH}/${values.id}`;
    const payload = ApiEndpoint.makeApiPayload(
        requestUrl,
        PUT,
        values,
        null,
        false,
        true
    );
    try {
        const response = yield call(Request, payload);
        yield put(AsyncEndAction());
        if (response && response?.latinName) {
            yield ShowMessage({
                type: "success",
                message: "ទិន្នន័យបានរក្សារទុក",
                content: "ទិន្នន័យបានរក្សារទុក",
                id: uuid(),
            });
            yield put(CreateCompanyModalSuccessAction(response));
        }
    } catch (error: any) {
        if (error.data && error.data.statusCode === 422) {
            // return yield put(EnterValidationErrorAction(error.data.message));
        }
        return yield ShowAlert("error", error.data.message);
    }
}

export default function* EntityCasinoSaga() {
    yield all([
        yield takeLatest(GET_ENTITY_COMPANY_BY_ID_REQUEST, GetCompanyById),
        yield takeLatest(
            EntityCompanyListConstant.ENTITY_COMPANY_LIST_REQUEST,
            GetCompanyList
        ),
        yield takeLatest(
            EntityCompanyListConstant.SELECT_COMPANY_REQUEST,
            SelectCompany
        ),
        yield takeLatest(
            EntityCompanyListConstant.CREATE_ENTITY_COMPANY_REQUEST,
            CreateCompany
        ),
        yield takeLatest(CREATE_COMPANY_MODAL_REQUEST, CreateCompanyModal),
        yield takeLatest(EDIT_ENTITY_COMPANY_REQUEST, EditCompany),
    ]);
}
