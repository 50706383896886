import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuid } from "uuid";

import { ArchiveIcon } from "@heroicons/react/solid";
import {
    Form,
    Upload,
    Button,
    Space,
    QRCode
} from "antd";
import type { RcFile, UploadProps } from 'antd/es/upload/interface';
import ImgCrop from "antd-img-crop";
import { HelmetHeader } from "Components/Helmet-Header";
import { FullLayout } from "Components/Layouts";
import { EquipmentBindingForm } from "Modules/Equipments/Casino/Binding/Forms/EquipmentBindingForm";

import EquipmentBindingReducer from "../Reducer";
import EquipmentBindingSaga from "../Saga";
import {
    CreateEquipmentBindingRequestAction,
    SetEquipmentBindingValueAction,
    SetUploadEquipmentBindingStatusAction,
} from "../Actions";
import { useInjectReducer } from "Utility/InjectReducer";
import { useInjectSaga } from "Utility/InjectSaga";
import { useParams } from "react-router-dom";
import { MakeEquipmentBindingUploadValueSelector, MakeUploadFileEquipmentBindingStatusSelector } from "../Selectors";
import { SelectGameRuleService } from "Modules/Settings/GameRule/Service";
import { SelectGameRuleSuccessAction } from "Modules/Settings/GameRule/Actions";
import GameRuleReducer from "Modules/Settings/GameRule/Reducer";
import { AsyncEndAction, AsyncStartAction } from "Modules/Application/Actions";
import { UploadPicture } from "../Service";
import imageCompression from "browser-image-compression";
import QrReader from "react-qr-reader";
import { SHOW_SNACK_MESSAGE } from "Components/SnackMessage/Constants";

const key = "equipmentBinding";
const gameRuleKey= "gameRule";
export const EquipmentBindingCreate = () => {
    useInjectReducer({ key, reducer: EquipmentBindingReducer });
    useInjectSaga({ key, saga: EquipmentBindingSaga });
    useInjectReducer({ key: gameRuleKey, reducer: GameRuleReducer});
    //fields
    const dispatch = useDispatch();
    const { id } = useParams();
    const [form] = Form.useForm();
    const fileData = useSelector(MakeEquipmentBindingUploadValueSelector());
    const fileStatus = useSelector(MakeUploadFileEquipmentBindingStatusSelector());
    // const [fileUuid, setFileUuid] = useState<any>(null);
    const [qrCodeData, setQrCodeData] = useState<string>(null);
    const [equipmentImageList, setEquipmentImageList] = useState<any>([]);
    const [equipmentImages, setEquipmentImages] = useState<any>([]);
    // const [delayScan, setDelayScan] = useState<any>(500);
    const [qrScannerVisible, setQrScannerVisible] = useState<boolean>(false);
    const [fileModel, setFileModel] = useState<any[]>([]);
    // const [cameraView, setCameraView] = useState<string>("rear");

    // constructor
    useEffect(() => {
        if (id) {
            form.setFieldValue("casinoId", id);
        }
        dispatch(AsyncStartAction());
        SelectGameRuleService(1, dispatch).then( (gameRuleRs: any) => {
            if(gameRuleRs) {
                dispatch(SelectGameRuleSuccessAction(gameRuleRs));
            }
        })
        .catch((ex: any) => {

        })
        .finally(() => {
            dispatch(AsyncEndAction());
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    useEffect(() => {
        switch (fileStatus) {
            case 2:
                dispatch(SetUploadEquipmentBindingStatusAction(0));
                break;
            case 1:
                setEquipmentImages([...equipmentImages, { uri: fileData.url, uuid: uuid(), ...fileData }]);
                dispatch(SetUploadEquipmentBindingStatusAction(0));
                break;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fileStatus])

    // events
    const onQRScanError = (error: any) => {

    }

    const onQRScanComplete = (data: any) => {
        
        if (data) {
            setQrCodeData(data);
            setQrScannerVisible(false);
        }
    }

    const onBeforeUpload = async (file: any) => {
        var extension = file.name.split('.').pop();
        const model = {
            fileName: `${uuid()}.${extension}`,
            uuid: file.uid,
        };
        const formData = new FormData();
        for (const key of Object.keys(model)) {
            formData.append(key, model[key]);
        }
        const compressImageData = await compressImage(file);
        // formData.append("File", file as RcFile);
        formData.append("File", compressImageData);
        UploadPicture(formData, dispatch)
        .then((uploadRs: any) => {
            console.log("Upload Rs", uploadRs);
            if(uploadRs && uploadRs.status==="done") {
                setFileModel([...fileModel, {
                    uuid: uuid(),
                    name: uploadRs.name,
                    uri: uploadRs.url,
                }]);
            }
            else {
                dispatch({
                    type: SHOW_SNACK_MESSAGE,
                    snack: {
                        type: "info",
                        message: "Image upload error!",
                        id: uuid(),
                    },
                });
            }
            dispatch(AsyncEndAction());
        })
        // dispatch(SetEquipmentBindingUploadValueAction(formData));
        // dispatch(UploadEquipmentBindingRequestAction());
        return false;

    };

    const compressImage = async (file) => {
        const options = {
          maxSizeMB: 2,
          maxWidthOrHeight: 1920,
          useWebWorker: false
        };
        try {
          const compressImageData = await imageCompression(file, options);
          return compressImageData;
        } catch (error) {
            dispatch({
                type: SHOW_SNACK_MESSAGE,
                snack: {
                    type: "error",
                    message: "Image can't be compress!. Please reupload",
                    id: uuid(),
                },
            });
          console.log(error);
        }
    };

    const onChange: UploadProps['onChange'] = async ({ file, fileList: newFileList }) => {
        
        console.log("Test", file);
        if (file.status === "done") {
            setEquipmentImageList(newFileList);
            await compressImage(file.originFileObj);
        }
        setEquipmentImageList(
            newFileList.filter((item) => item.status === "done" || "uploading")
        );
    };

    const onPreview = async (file: any) => {
        let src = file.url as string;
        if (!src) {
            src = await new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj as RcFile);
                reader.onload = () => resolve(reader.result as string);
            });
        }
        const image = new Image();
        image.src = src;
        const imgWindow = window.open(src);
        imgWindow?.document.write(image.outerHTML);
    };

    const onFormProvideSubmit = (name: string, { forms, values }: any) => {
        switch (name) {
            case "equipmentBindingForm":
                onCreated(values);
                break;
        }
    }

    const onCreated = (values: any) => {
        const qrId = qrCodeData.split("/").pop();
        const model = {
            inspectionCertificateId: qrId,
            // equipmentPictures: equipmentImages,
            equipmentPictures: fileModel,
            zoneName: values.zone,
            gameRuleId: values.gameRule? values.gameRule.value: "00000000-0000-0000-0000-000000000000",
            gameTypeId: values.gameRule ? values.gameRule.key: "00000000-0000-0000-0000-000000000000",
            ...values,
        }
        dispatch(SetEquipmentBindingValueAction(model));
        dispatch(CreateEquipmentBindingRequestAction());
    }

    // render
    const ActionButton = () => {
        return (
            <Space>
                <Button type="primary" onClick={() => form.submit()}>
                    រក្សាទុក
                </Button>
                {/* <Button type="primary" onClick={() => setQrScannerVisible(true)}>
                    រូប QR
                </Button> */}

            </Space>
        )
    }
    return (
        <FullLayout
            icon={<ArchiveIcon />}
            title="ភ្ជាប់ឧបករណ៍/កម្មវិធីល្បែង"
            descriptions=""
            redirectUrl=""
            actions={<ActionButton />}
        >
            <HelmetHeader meta="ភ្ជាប់ឧបករណ៍/កម្មវិធីល្បែង" />
            <Form.Provider onFormFinish={onFormProvideSubmit}>
                <EquipmentBindingForm form={form} />
                <div className="grid grid-cols-1 md:grid-cols-3 p-5 gap-4">
                    <div className="flex flex-col gap-2 items-start">
                        <h2>រូប QR Code</h2>
                        
                        <Button type="primary" onClick={() => setQrScannerVisible(true)}>
                            ស្កេន
                        </Button>
                        {qrScannerVisible && < QrReader
                            // constraints={
                            //     { facingMode: cameraView }
                            // }
                            facingMode={"environment"}
                            delay={1000}
                            onError={onQRScanError}
                            onScan={onQRScanComplete}
                            // chooseDeviceId={()=>selected}
                            style={{ width: "300px" }}
                        />}
                        {
                            qrCodeData && <Space direction="vertical">
                                <QRCode value={qrCodeData}></QRCode>
                                <p>{qrCodeData}</p>
                            </Space>
                        }
                    </div>
                    <div className="flex flex-col gap-2">
                        <h2>រូបភាពឧបករណ៍</h2>
                        <ImgCrop aspectSlider aspect={3/2}>
                            <Upload
                                className="col-span-2"
                                listType="picture-card"
                                fileList={equipmentImageList}
                                accept="image/jpeg,image/png,image/jpg"
                                beforeUpload={onBeforeUpload}
                                onChange={onChange}
                                onPreview={onPreview}
                            >
                                {equipmentImageList.length >= 8 ? null : "Equipment Picture"}
                            </Upload>
                        </ImgCrop>
                         {/* <Upload
                                className="col-span-2"
                                listType="picture-card"
                                fileList={equipmentImageList}
                                accept="image/jpeg,image/png,image/jpg"
                                beforeUpload={onBeforeUpload}
                                onChange={onChange}
                                onPreview={onPreview}
                            >
                                {equipmentImageList.length >= 8 ? null : "Equipment Picture"}
                        </Upload> */}
                    </div>
                </div>

            </Form.Provider>
        </FullLayout>
    )
}