import React from "react";
import hoistNonReactStatics from "hoist-non-react-statics";
import { useStore, ReactReduxContext } from "react-redux";

import getInjectors from "Utility/SagaInjector";

/**
 * Dynamically injects a saga, passes component's props as saga arguments
 *
 * @param {string} key A key of the saga
 * @param {function} saga A root saga that will be injected
 * @param {string} [mode] By default (constants.DAEMON) the saga will be started
 * on component mount and never canceled or started again. Another two options:
 *   - constants.RESTART_ON_REMOUNT — the saga will be started on component mount and
 *   cancelled with `task.cancel()` on component unmount for improved performance,
 *   - constants.ONCE_TILL_UNMOUNT — behaves like 'RESTART_ON_REMOUNT' but never runs it again.
 *
 */
// eslint-disable-next-line import/no-anonymous-default-export
export default ({ key, saga, mode }: any) =>
    (WrappedComponent: any) => {
        class InjectSaga extends React.Component<{}, any> {
            static WrappedComponent = WrappedComponent;

            // eslint-disable-next-line react/static-property-placement
            static contextType = ReactReduxContext;

            // eslint-disable-next-line react/static-property-placement
            static displayName = `withSaga(${
                WrappedComponent.displayName ||
                WrappedComponent.name ||
                "Component"
            })`;

            // constructor(props: any, context: any) {
            //     super(props, context);
            //     this.injectors = getInjectors(context.store);
            //     this.injectors.injectSaga(key, { saga, mode }, this.props);
            // }

            // componentWillUnmount() {
            //     const { injectors } = this.state;
            //     injectors.ejectSaga(key);
            // }

            render() {
                return <WrappedComponent {...this.props} />;
            }
        }

        return hoistNonReactStatics(InjectSaga, WrappedComponent);
    };

const useInjectSaga = ({ key, saga, mode = null }: any) => {
    const store = useStore();

    React.useEffect(() => {
        const injectors = getInjectors(store);
        
        injectors.injectSaga(key, saga, mode);

        return () => {
            injectors.ejectSaga(key);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
};

export { useInjectSaga };
