import { useSelector } from "react-redux";

import { CardLink } from "Components/Toolbox/Card-Link";

import { MakePermissionSelector } from "Modules/Application/Selectors";
import { checkPermissionForComponent } from "Utility/Permission";
import { PermissionKeys } from "Modules/Application/Constants";
import { EntityType } from "Common/Constants/EntityType";


export const CasinoDashboard = () => {
    // fields
    const permissions = useSelector(MakePermissionSelector());
    // const CertificateOperations = [
    //     {
    //         name: "ប្រភេទល្បែង ",
    //         initials: "បលក",
    //         href: "/certificate/casino/request-game-type",
    //         members: 16,
    //         bgColor: "bg-sky-600",
    //         permissionCode: [PermissionKeys.GAME_TYPE__LIST],
    //     },
    //     {
    //         name: "វិធានល្បែង",
    //         initials: "វលក",
    //         href: "/certificate/casino/request-game-rule",
    //         members: 16,
    //         bgColor: "bg-sky-600",
    //         permissionCode: [PermissionKeys.GAME_RULE__LIST],
    //     },
    //     {
    //         name: "ឧបករណ៍ល្បែង",
    //         initials: "ឧក",
    //         href: "/certificate/casino/request-game-equipment",
    //         members: 16,
    //         bgColor: "bg-sky-600",
    //         permissionCode: [PermissionKeys.EQUIPMENT__LIST],
    //     },
    // ];
    const RequestCertificateOperations = [
        {
            name: "ប្រភេទល្បែង ",
            initials: "បលក",
            href: "/certificate/casino/request-game-type/request",
            members: 16,
            bgColor: "bg-sky-600",
            permissionCode: [],
        },
        {
            name: "វិធានល្បែង",
            initials: "វលក",
            href: "/certificate/casino/request-game-rule/request",
            members: 16,
            bgColor: "bg-sky-600",
            permissionCode: [],
        },
        {
            name: "ឧបករណ៍ល្បែង",
            initials: "ឧក",
            href: "/certificate/casino/request-game-equipment/request",
            members: 16,
            bgColor: "bg-sky-600",
            permissionCode: [],
        },
    ];

    const ListNavigation = [
        {
            name: "បញ្ជីអាជ្ញាបណ្ណ",
            initials: "អ",
            href: "/license/casino/license/list",
            members: 16,
            bgColor: "bg-green-600",
            permissionCode: [PermissionKeys.CASINO],
        },
        {
            name: "បញ្ជីពាក្យស្នើសុំអាជ្ញាបណ្ណ",
            initials: "CL",
            href: "/license/casino/application-form/request-license",
            members: 16,
            bgColor: "bg-green-600",
            permissionCode: [PermissionKeys.REQUEST_CASINO_LICENSE],
        },
    ];

    const EntityNavigation = [
        {
            name: "កាស៊ីណូ",
            initials: "ក",
            href: `/entity/operator?entityType=${EntityType.CASINO}`,
            members: 16,
            bgColor: "bg-green-600",
            permissionCode: [PermissionKeys.CASINO],
        },
        {
            name: "ក្រុមហ៊ុន",
            initials: "ក្រ",
            href: "/entity/company",
            members: 16,
            bgColor: "bg-green-600",
            permissionCode: [PermissionKeys.COMPANY],
        },
    ];

    const SettingNavigation = [
        {
            name: "លតាបត្រ",
            initials: "បល",
            href: `/settings/inspection-certificate-master`,
            members: 16,
            bgColor: "bg-neutral-600",
            permissionCode: [],
        },
        {
            name: "ប្រភេទលតាបត្រ",
            initials: "បល",
            href: `/settings/inspection-certificate-type`,
            members: 16,
            bgColor: "bg-neutral-600",
            permissionCode: [],
        },

        {
            name: "ប្រភេទល្បែង",
            initials: "បល",
            href: `/settings/game-type?entityType=${EntityType.CASINO}`,
            members: 16,
            bgColor: "bg-neutral-600",
            permissionCode: [],
        },

        {
            name: "វិធានល្បែង",
            initials: "វល",
            href: `/settings/game-rule?entityType=${EntityType.CASINO}`,
            members: 16,
            bgColor: "bg-neutral-600",
            permissionCode: [],
        },
        
    ];


    return (
        <>
            <div className="p-4">
                <h2 className="text-gray-500 text-xs font-medium uppercase tracking-wide">
                    បញ្ជីអាជ្ញាបណ្ណ/បញ្ជីពាក្យស្នើសុំអាជ្ញាបណ្ណ
                </h2>
                <div className="mt-3 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 sm:gap-6">
                    {ListNavigation.map((project: any) => checkPermissionForComponent(permissions, {}, project.permissionCode) && (
                        <div key={project.name}>
                            <CardLink
                                name={project.name}
                                bgColor={project.bgColor}
                                href={project.href}
                                initials={project.initials}
                            />
                        </div>
                    ))}
                </div>
            </div>
            <div className="p-4">
                <h2 className="text-gray-500 text-xs font-medium uppercase tracking-wide">
                    ពាក្យស្នើសុំ ប្រភេទល្បែង/វិធានល្បែង/ឧបករណ៍ល្បែង
                </h2>
                <div className="mt-3 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 sm:gap-6">
                    {RequestCertificateOperations.map((project) => checkPermissionForComponent(permissions, {}, project.permissionCode) && (
                        <div key={project.name}>
                            <CardLink
                                name={project.name}
                                bgColor={project.bgColor}
                                href={project.href}
                                initials={project.initials}
                            />
                        </div>
                    ))}
                </div>
            </div>
            <div className="p-4">
                <h2 className="text-gray-500 text-xs font-medium uppercase tracking-wide">
                    ក្រុមហ៊ុន/កាស៊ីណូ
                </h2>
                <div className="mt-3 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 sm:gap-6">
                    {EntityNavigation.map((entity: any) => checkPermissionForComponent(permissions, {}, entity.permissionCode) && (
                        <div key={entity.name}>
                            <CardLink
                                name={entity.name}
                                bgColor={entity.bgColor}
                                href={entity.href}
                                initials={entity.initials}
                            />
                        </div>
                    ))}
                </div>
            </div>
            <div className="p-4">
                <h2 className="text-gray-500 text-xs font-medium uppercase tracking-wide">
                    រៀបចំ
                </h2>
                <div className="mt-3 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 sm:gap-6">
                    {SettingNavigation.map((setting: any) => checkPermissionForComponent(permissions, {}, setting.permissionCode) && (

                        <div key={setting.name}>
                            <CardLink
                                name={setting.name}
                                bgColor={setting.bgColor}
                                href={setting.href}
                                initials={setting.initials}
                            />
                        </div>
                    ))}
                </div>
            </div>

        </>
    );
};
