/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Modal,
    Form,
    Row,
    Col,
    Input,
    Radio,
    Space,
    Tabs,
    DatePicker,
    Table,
    Select,
    Divider,
    Button,
    Typography,
    Popconfirm,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { CreateCompanyModalForm } from "Modules/Entity/Company/Forms/CreateCompanyModalForm";
import { EditableCell } from "Components/Toolbox/Editable/EditableCell";

import { useInjectSaga } from "Utility/InjectSaga";
import { useInjectReducer } from "Utility/InjectReducer";

import CompanyReducer from "Modules/Entity/Company/Reducer";
import CompanySaga from "Modules/Entity/Company/Saga";
import CasinoLicenseReducer from "Modules/License/Casino/Reducer";
import CasinoSaga from "Modules/License/Casino/Saga";

import {
    SelectableCasinoTypeAction,
    SelectableNationalityAction,
} from "Modules/License/Casino/Actions";

import {
    CreateCasinoModalRequestAction,
    SetFormValuesAction,
} from "Modules/Entity/Operator/Actions";

import {
    SelectCompanyRequestAction,
    SelectCompanySuccessAction,
} from "Modules/Entity/Company/Actions";
import { MakeSelectEntityCompanyListPage } from "Modules/Entity/Company/Selectors";
import MakeSelectCasinoLicensePage from "Modules/License/Casino/Selectors";

const { TabPane } = Tabs;
type EditableTableProps = Parameters<typeof Table>[0];
type ColumnTypes = Exclude<EditableTableProps["columns"], undefined>;
const companyKey = "entityCompany";
interface DataType {
    key: string;
    licenseNumber: string;
    startDate: string;
    endDate: string;
}

const casinoKey = "licenseCasino";
export const CreateCasinoModalForm = ({ visible, onOk, onCancel }: any) => {
    useInjectReducer({ key: companyKey, reducer: CompanyReducer });
    useInjectSaga({ key: companyKey, saga: CompanySaga });
    useInjectReducer({ key: casinoKey, reducer: CasinoLicenseReducer });
    useInjectSaga({ key: casinoKey, saga: CasinoSaga });
    //fields
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const [companyVisible, setCompanyVisible] = useState(false);
    const [editingKey, setEditingKey] = useState("");
    const [count, setCount] = useState(1);
    const { casinoTypes, nationality } = useSelector(
        MakeSelectCasinoLicensePage()
    );
    const { companyOptions, companyDetailModal } = useSelector(
        MakeSelectEntityCompanyListPage()
    );
    const [licenseHistorySource, setLicenseHistorySource] = useState<
        DataType[]
    >([]);

    const columns = [
        {
            title: "លេខអាជ្ញាបណ្ណ",
            dataIndex: "licenseNumber",
            width: "30%",
            editable: true,
        },
        {
            title: "ថ្ងៃទទួលបានអាជ្ញាបណ្ណ",
            dataIndex: "startDate",
            editable: true,
        },
        {
            title: "ថ្ងៃផុតកំណត់អាជ្ញាបណ្ណ",
            dataIndex: "endDate",
            editable: true,
        },
        {
            title: "",
            dataIndex: "operation",
            render: (_: any, record: DataType) => {
                const editable = isEditing(record);
                return editable ? (
                    <span className="text-center">
                        <Typography.Link
                            onClick={() => save(record)}
                            style={{ marginRight: 8 }}
                        >
                            រក្សាទុក
                        </Typography.Link>
                        <Popconfirm
                            title="តើអ្នកចង់បោះបង់ការកែប្រែ?"
                            onConfirm={cancel}
                        >
                            <a className="text-red-500">បោះបង់</a>
                        </Popconfirm>
                    </span>
                ) : (
                    <Typography.Link
                        disabled={editingKey !== ""}
                        onClick={() => edit(record)}
                    >
                        កែប្រែ
                    </Typography.Link>
                );
            },
        },
    ];

    const mergedColumns = columns.map((col) => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record: DataType) => ({
                record,
                inputType: col.dataIndex === "age" ? "number" : "text",
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
            }),
        };
    });

    // constructor
    useEffect(() => {
        // dispatch(SelectableCasinoTypeAction());
        // dispatch(SelectableNationalityAction());
        // dispatch(SelectCompanyRequestAction());

        return () => {
            // dispatch(SelectCompanySuccessAction([]));
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if(companyDetailModal){
            // dispatch(SelectCompanyRequestAction());
        }
        

        return () => {
            dispatch(SelectCompanySuccessAction(null));
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [companyDetailModal]);

    // events
    const onCompanySubmit = () => {
        setCompanyVisible(false);
    };
    const onCompanyCancel = () => {
        setCompanyVisible(false);
    };

    const isEditing = (record: DataType) => record.key === editingKey;
    const onAddLicenseHistory = () => {
        const newData: DataType = {
            key: count + "",
            licenseNumber: "លេខអាជ្ញាបណ្ណ",
            startDate: "ថ្ងៃទទួលបានអាជ្ញាបណ្ណ",
            endDate: "ថ្ងៃផុតកំណត់អាជ្ញាបណ្ណ",
        };
        form.setFieldsValue({
            licenseNumber: "",
            startDate: "",
            endDate: "",
            ...newData,
        });
        setLicenseHistorySource([...licenseHistorySource, newData]);
        setEditingKey(count + "");
        setCount(count + 1);
    };
    const save = (row: DataType) => {
        // const newData = [...licenseHistorySource];
        // const index = newData.findIndex((item) => row.key === item.key);
        // const item = newData[index];
        // newData.splice(index, 1, {
        //     ...item,
        //     ...row,
        // });
        // setLicenseHistorySource(newData);

        const newData = [...licenseHistorySource];
        const values = form.getFieldsValue([
            "licenseNumber",
            "startDate",
            "endDate",
        ]);

        const index = newData.findIndex((item) => row.key === item.key);
        const item = newData[index];
        newData[index] = { key: item.key, ...values };
        setLicenseHistorySource(newData);
        setEditingKey("");
        form.setFieldsValue({
            casinoData: {
                licenseHistory: newData,
            },
        });
    };

    const edit = (record: Partial<DataType> & { key: React.Key }) => {
        form.setFieldsValue({
            licenseNumber: "",
            startDate: "",
            endDate: "",
            ...record,
        });
        setEditingKey(record.key);
    };

    const cancel = () => {
        setEditingKey("");
    };

    const onFinish = () => {
        const values = form.getFieldsValue();

        dispatch(
            SetFormValuesAction({
                ...values,
                companyId: values.formData.company.id,
                gPAName: "",
                gAPLatinName: "",
                casinoLicenseNumber: " ",
            })
        );
        dispatch(CreateCasinoModalRequestAction());
        onOk();
    };

    return (
        <>
            <Modal
                title="បង្កើតកាស៊ីណូថ្មី"
                open={visible}
                onOk={onFinish}
                onCancel={onCancel}
                width="100"
                okText="យល់ព្រម"
                cancelText="បោះបង់"
                okButtonProps={{ type: "default" }}
            >
                <Form
                    name="entityCasinoForm"
                    layout="vertical"
                    className="p-4"
                    form={form}
                    initialValues={{ name: "", lastinName: "" }}
                >
                    <Form.Item name="companyId" hidden={true}>
                        <Input />
                    </Form.Item>
                    <Tabs defaultActiveKey="1">
                        <TabPane tab="ព័ត៌មានកាស៊ីណូ" key="1">
                            <Row>
                                <Col span={6}>
                                    នាមករណ៍ក្រុមហ៊ុនជាអក្សរខ្មែរ <br />
                                    Company name in Khmer
                                </Col>
                                <Col span={18}>
                                    <Form.Item
                                        name={["formData", "company", "id"]}
                                        rules={[{ required: true }]}
                                    >
                                        {/* <Input
                                            placeholder="នាមករណ៍ក្រុមហ៊ុនជាអក្សរខ្មែរ / Company name in Khmer"
                                            disabled
                                        /> */}
                                        <Select
                                            placeholder="នាមករណ៍ក្រុមហ៊ុនជាអក្សរខ្មែរ / Company name in Khmer"
                                            filterOption={(input, option) =>
                                                option!.children
                                                    .join(" ")
                                                    .toLowerCase()
                                                    .includes(
                                                        input.toLowerCase()
                                                    )
                                            }
                                            dropdownRender={(menu) => (
                                                <>
                                                    {menu}
                                                    <Divider
                                                        style={{
                                                            margin: "1px 0",
                                                        }}
                                                    />
                                                    <Button
                                                        type="text"
                                                        className="w-full border text-left"
                                                        icon={<PlusOutlined />}
                                                        onClick={() =>
                                                            setCompanyVisible(
                                                                true
                                                            )
                                                        }
                                                    >
                                                        បន្ថែមថ្មី
                                                    </Button>
                                                </>
                                            )}
                                            showSearch
                                        >
                                            {companyOptions &&
                                                companyOptions.map((item) => (
                                                    <Select.Option
                                                        key={item.value}
                                                        value={item.value}
                                                    >
                                                        {item.label}(
                                                        {item.placeholder})
                                                    </Select.Option>
                                                ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                {/* <Col span={2}>
                                    <button
                                        type="button"
                                        className="inline-flex items-center justify-center px-2 py-2 mx-2 rounded-full border border-gray-300 shadow-sm text-sm font-medium  text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500 mb-2"
                                        onClick={() => setCompanyVisible(true)}
                                    >
                                        <DocumentSearchIcon
                                            className="w-5 h-5 text-black"
                                            aria-hidden="true"
                                        />
                                    </button>
                                </Col> */}
                            </Row>
                            <Row>
                                <Col span={6}>លេខកូដកាស៊ីណូ</Col>
                                <Col span={18}>
                                    <Form.Item
                                        name="code"
                                        rules={[{ required: true }]}
                                    >
                                        <Input placeholder="លេខកូដកាស៊ីណូ" />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={6}>
                                    នាមករណ៍កាស៊ីណូជាអក្សរខ្មែរ <br />
                                    Casino name in Khmer
                                </Col>
                                <Col span={18}>
                                    <Form.Item
                                        name="name"
                                        rules={[{ required: true }]}
                                    >
                                        <Input placeholder="នាមករណ៍កាស៊ីណូជាអក្សរខ្មែរ / Casino name in Khmer" />
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    នាមករណ៍កាស៊ីណូជាអក្សរបរទេស <br />
                                    Casino name in Foreign Language
                                </Col>
                                <Col span={18}>
                                    <Form.Item
                                        name="latinName"
                                        rules={[{ required: true }]}
                                    >
                                        <Input placeholder="នាមករណ៍កាស៊ីណូជាអក្សរឡាតាំង / Casino name in Latin" />
                                    </Form.Item>
                                </Col>

                                <Col span={6}>
                                    ប្រភេទកាស៊ីណូ <br />
                                    Casino Type
                                </Col>
                                <Col span={18}>
                                    <Form.Item name="casinoTypeId">
                                        <Radio.Group>
                                            <Space direction="vertical">
                                                {casinoTypes &&
                                                    casinoTypes.map(
                                                        (
                                                            casinoType: any,
                                                            index: number
                                                        ) => (
                                                            <Radio
                                                                key={
                                                                    casinoType.id
                                                                }
                                                                value={
                                                                    casinoType.id
                                                                }
                                                            >
                                                                {
                                                                    casinoType.descriptions
                                                                }
                                                            </Radio>
                                                        )
                                                    )}
                                            </Space>
                                        </Radio.Group>
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    លេខអាជ្ញាបណ្ណកាស៊ីណូ
                                    <br />
                                    Casino License Number
                                </Col>
                                <Col span={18}>
                                    <Row gutter={16}>
                                        <Col span={8}>
                                            <Form.Item name="casinoLicenseNumber">
                                                <Input placeholder="លេខអាជ្ញាបណ្ណកាស៊ីណូ / Casino License Number" />
                                            </Form.Item>
                                        </Col>
                                        <Col span={8}>
                                            សុពលភាពអាជ្ញាបណ្ណ
                                            <br />
                                            Expired date of License
                                        </Col>
                                        <Col span={8}>
                                            <Form.Item name="licenseExpiredDate">
                                                <DatePicker placeholder="សុពលភាពអាជ្ញាបណ្ណ / Expired date of License" />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col span={6}>
                                    អាសយដ្ឋានធ្វើអាជីវកម្ម
                                    <br />
                                    Address of Business
                                </Col>
                                <Col span={18}>
                                    <Form.Item
                                        name="address"
                                        rules={[{ required: true }]}
                                    >
                                        <Input placeholder="អាសយដ្ឋានធ្វើអាជីវកម្ម / Address of Business" />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={6}>
                                    ទីតាំងនេះជាកម្មសិទ្ធិ ឬជួល?
                                    <br />
                                    Is this above Location an Owned or Rented
                                    Property?
                                </Col>
                                <Col span={18}>
                                    <Form.Item
                                        name="propertyStatus"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Please select",
                                            },
                                        ]}
                                    >
                                        <Radio.Group>
                                            <Space direction="horizontal">
                                                <Radio value={1}>
                                                    កម្មសិទ្ធិ / Owned
                                                </Radio>
                                                <Radio value={2}>
                                                    ជួល / Rented
                                                </Radio>
                                            </Space>
                                        </Radio.Group>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </TabPane>
                        <TabPane tab="ព័ត៌មានលម្អិតពីក្រុមហ៊ុន" key="2">
                            <Row>
                                <Col span={6}>
                                    នាមករណ៍ក្រុមហ៊ុនជាអក្សរឡាតាំង <br />
                                    Company name in Latin
                                </Col>
                                <Col span={18}>
                                    <Form.Item
                                        name={[
                                            "formData",
                                            "company",
                                            "latinName",
                                        ]}
                                        rules={[{ required: true }]}
                                    >
                                        <Input
                                            placeholder="នាមករណ៍ក្រុមហ៊ុនជាអក្សរឡាតាំង / Company name in Latin"
                                            disabled
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    នាមករណ៍បុគ្គលទទួលបាន ស.ជ.ណ <br />
                                    name of Person received government
                                    approval-in-Principle
                                </Col>
                                <Col span={18}>
                                    <Row>
                                        <Col span={8}>
                                            អក្សរខ្មែរ <br />
                                            in Khmer
                                        </Col>
                                        <Col span={16}>
                                            <Form.Item
                                                name={[
                                                    "formData",
                                                    "company",
                                                    "gAPFirstname",
                                                ]}
                                            >
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={8}>
                                            អក្សរឡាតាំង <br />
                                            in Latin
                                        </Col>
                                        <Col span={16}>
                                            <Form.Item
                                                name={[
                                                    "formData",
                                                    "company",
                                                    "gAPLastname",
                                                ]}
                                            >
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>

                            <Row>
                                <Col span={6}>
                                    នាមករណ៍ក្រុមហ៊ុនពីមុន(ប្រសិនបើមាន) <br />
                                    Former name(If Any)
                                </Col>
                                <Col span={16}>
                                    <Row gutter={16}>
                                        <Col span={8}>
                                            <Form.Item
                                                name={[
                                                    "formData",
                                                    "company",
                                                    "formerName",
                                                ]}
                                            >
                                                <Input
                                                    placeholder="នាមករណ៍ក្រុមហ៊ុនពីមុន(ប្រសិនបើមាន) / Former name(If Any)"
                                                    disabled
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={8}>
                                            កាលបរិច្ឆេទនៃការផ្លាស់ប្តូរនាមករណ៍
                                            <br />
                                            Date of Changing the name
                                        </Col>
                                        <Col span={8}>
                                            <Form.Item
                                                name={[
                                                    "formData",
                                                    "company",
                                                    "dateOfChangingName",
                                                ]}
                                            >
                                                <DatePicker
                                                    placeholder="កាលបរិច្ឆេទនៃការផ្លាស់ប្តូរនាមករណ៍ / Date of Changing the name"
                                                    disabled
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col span={6}>
                                    លេខចុះបញ្ជីពាណិជ្ជកម្ម <br />
                                    Registration Number
                                </Col>
                                <Col span={18}>
                                    <Form.Item
                                        name={[
                                            "formData",
                                            "company",
                                            "registrationNumber",
                                        ]}
                                    >
                                        <Input
                                            placeholder="លេខចុះបញ្ជីពាណិជ្ជកម្ម / Registration Number"
                                            disabled
                                        />
                                    </Form.Item>
                                </Col>

                                <Col span={6}>
                                    លេខអត្តសញ្ញាណកម្មសារពើពន្ធ <br /> Tax
                                    Identificaiton Number (TIN)
                                </Col>
                                <Col span={18}>
                                    <Form.Item
                                        name={[
                                            "formData",
                                            "company",
                                            "taxIdentificationNumber",
                                        ]}
                                        rules={[{ required: true }]}
                                    >
                                        <Input
                                            placeholder="លេខអត្តសញ្ញាណកម្មសារពើពន្ធ / Tax Identificaiton Number (TIN)"
                                            disabled
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    សញ្ជាតិក្រុមហ៊ុន <br /> Company Nationality
                                </Col>
                                <Col span={18}>
                                    <Form.Item
                                        name={[
                                            "formData",
                                            "company",
                                            "nationalId",
                                        ]}
                                        rules={[
                                            {
                                                required: true,
                                                message: "Please select",
                                            },
                                        ]}
                                    >
                                        <Radio.Group disabled>
                                            <Space direction="horizontal">
                                                {nationality &&
                                                    nationality.map(
                                                        (nation: any) => (
                                                            <Radio
                                                                key={
                                                                    nation.value
                                                                }
                                                                value={
                                                                    nation.value
                                                                }
                                                            >
                                                                {nation.label}
                                                            </Radio>
                                                        )
                                                    )}
                                            </Space>
                                        </Radio.Group>
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    ទីស្នាក់ការចុះបញ្ជីក្រុមហ៊ុន
                                    <br />
                                    Company Registration Office
                                </Col>
                                <Col span={18}>
                                    <Form.Item
                                        name={[
                                            "formData",
                                            "company",
                                            "registrationOffice",
                                        ]}
                                        rules={[{ required: true }]}
                                    >
                                        <Input
                                            placeholder="ទីស្នាក់ការចុះបញ្ជីក្រុមហ៊ុន / Company Registration Office"
                                            disabled
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>

                            {/* <Row>
                        <Col span={6}>
                            ទីតាំងនេះជាកម្មសិទ្ធិ ឬជួល?
                            <br />
                            Is this above Location an Owned or Rented Property?
                        </Col>
                        <Col span={18}>
                            <Form.Item
                                name={["formData", "company", "propertyStatus"]}
                                rules={[
                                    {
                                        required: true,
                                        message: "Please select",
                                    },
                                ]}
                            >
                                <Radio.Group disabled>
                                    <Space direction="horizontal">
                                        <Radio value={1}>
                                            កម្មសិទ្ធិ / Owned
                                        </Radio>
                                        <Radio value={2}>ជួល / Rented</Radio>
                                    </Space>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                    </Row> */}
                            <Row>
                                <Col span={6}>
                                    ទូរស័ព្ទច​ល័ត
                                    <br />
                                    Mobile Phone Number
                                </Col>
                                <Col span={18}>
                                    <Row gutter={16}>
                                        <Col span={8}>
                                            <Form.Item
                                                name={[
                                                    "formData",
                                                    "company",
                                                    "mobilePhoneNumber",
                                                ]}
                                                rules={[{ required: true }]}
                                            >
                                                <Input
                                                    placeholder="ទូរស័ព្ទច​ល័ត / Mobile Phone Number"
                                                    disabled
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={8}>
                                            ទូរស័ព្ទលើតុ
                                            <br />
                                            Office Phone Number
                                        </Col>
                                        <Col span={8}>
                                            <Form.Item
                                                name={[
                                                    "formData",
                                                    "company",
                                                    "telephoneNumber",
                                                ]}

                                            >
                                                <Input
                                                    placeholder="ទូរស័ព្ទលើតុ / Office Phone Number"
                                                    disabled
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={6}>
                                    សារអេឡិចត្រូនិច
                                    <br />
                                    Email Address
                                </Col>
                                <Col span={18}>
                                    <Form.Item
                                        name={[
                                            "formData",
                                            "company",
                                            "emailAddress",
                                        ]}
                                        rules={[{ required: true }]}
                                    >
                                        <Input
                                            placeholder="សារអេឡិចត្រូនិច / Email Address"
                                            disabled
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={6}>
                                    គេហទំព័រ
                                    <br />
                                    Website
                                </Col>
                                <Col span={18}>
                                    <Form.Item
                                        name={[
                                            "formData",
                                            "company",
                                            "websiteAddress",
                                        ]}
                                        rules={[{ required: true }]}
                                    >
                                        <Input
                                            placeholder="គេហទំព័រ / Website"
                                            disabled
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </TabPane>
                        <TabPane tab="ព័ត៌មាអាជ្ញាបណ្ណ" key="3">
                            <Button
                                className="bg-blue-500"
                                onClick={onAddLicenseHistory}
                                type="primary"
                                style={{ marginBottom: 16 }}
                            >
                                បន្ថែមថ្មី
                            </Button>
                            <br />
                            <Table
                                size="small"
                                dataSource={licenseHistorySource}
                                rowKey="key"
                                components={{
                                    body: {
                                        // row: EditableRow,
                                        cell: EditableCell,
                                    },
                                }}
                                columns={mergedColumns as ColumnTypes}
                            />
                        </TabPane>
                    </Tabs>
                    {/* <Row className="mt-4 flex flex-row-reverse">
                        <Col span={24} className="flex flex-row-reverse">
                            <button
                                onClick={() => form.submit()}
                                type="button"
                                className="inline-flex ml-0 items-center justify-center px-4 py-2 mx-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500"
                            >
                                រក្សាទុក
                            </button>
                        </Col>
                    </Row> */}
                </Form>
            </Modal>
            <CreateCompanyModalForm
                visible={companyVisible}
                onOk={onCompanySubmit}
                onCancel={onCompanyCancel}
            />
        </>
    );
};
