import { createSelector } from "reselect";
import { LotteryApplicationFormInitialState } from "Modules/Certificate/Lottery/ApplicationForm/Reducer";

const RequestLotteryGameTypeDomain = (state: any) => state.lotteryApplicationForm || LotteryApplicationFormInitialState;
const MakeRequestLotteryGameTypeListSelector = () => createSelector(RequestLotteryGameTypeDomain, substate => substate.requestLotteryGameTypes);
const MakeRequestLotteryGameTypeSelector = () => createSelector(RequestLotteryGameTypeDomain, substate => substate.requestLotteryGameType);
const MakeRequestLotteryGameTypeIdSelector = () => createSelector(RequestLotteryGameTypeDomain, substate => substate.id);
const MakeRequestLotteryGameTypeFilterValueSelector = () => createSelector(RequestLotteryGameTypeDomain, substate => substate.filterValues);
const MakeRequestLotteryGameTypeValueSelector = () => createSelector(RequestLotteryGameTypeDomain, substate => substate.values);

export {
    MakeRequestLotteryGameTypeListSelector,
    MakeRequestLotteryGameTypeSelector,
    MakeRequestLotteryGameTypeIdSelector,
    MakeRequestLotteryGameTypeFilterValueSelector,
    MakeRequestLotteryGameTypeValueSelector
};
