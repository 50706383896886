
import { InspectionCertificateMasterList } from "Modules/Settings/InspectionCertificateMaster/List";
import { InspectionCertificateMasterCreate } from "Modules/Settings/InspectionCertificateMaster/Create";
import { InspectionCertificateMasterDetail } from "Modules/Settings/InspectionCertificateMaster/Detail"

export const SettingInspectionCertificateMasterRoute = {
    path: "inspection-certificate-master",
    children: [
        { path: "", element: <InspectionCertificateMasterList /> },
        { path: "create", element: <InspectionCertificateMasterCreate /> },
        { path: ":id", element: <InspectionCertificateMasterDetail /> }
    ],
};
