import { createSelector } from "reselect";
import { initialState } from "Modules/Certificate/Casino/ApplicationForm/GameRule/Reducer";

const CertificateGameRuleDomain = (state: any) =>
    state.certificateGameRule || initialState;

const MakeRequestGameRuleFilterValueSelector = () =>
    createSelector(
        CertificateGameRuleDomain,
        (substate) => substate.filterValues
    );

const MakeRequestGameRuleValueSelector = () =>
    createSelector(
        CertificateGameRuleDomain,
        (substate) => substate.requestFormValue
    );

const MakeListRequestGameRuleSelector = () =>
    createSelector(
        CertificateGameRuleDomain,
        (substate) => substate.gameRuleList
    );

const MakeRequestGameRuleIdSelector = () =>
    createSelector(CertificateGameRuleDomain, (substate) => substate.id);

const MakeRequestGameRuleDetailSelector = () =>
    createSelector(
        CertificateGameRuleDomain,
        (substate) => substate.requestGameRuleDetail
    );

export default CertificateGameRuleDomain;
export {
    MakeRequestGameRuleFilterValueSelector,
    MakeListRequestGameRuleSelector,
    MakeRequestGameRuleIdSelector,
    MakeRequestGameRuleDetailSelector,
    MakeRequestGameRuleValueSelector,
};
