import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useInjectReducer } from "Utility/InjectReducer";
import { useInjectSaga } from "Utility/InjectSaga";
import dayjs from "dayjs";

import { HelmetHeader } from "Components/Helmet-Header";
import { ArchiveIcon } from "@heroicons/react/outline";
import { Form } from "antd";
import { FullLayout } from "Components/Layouts/";
import { CertificateGameTypeForm } from "Modules/Certificate/Casino/ApplicationForm/GameType/Forms/CertificateGameTypeForm";

import Reducer from "Modules/Certificate/Casino/ApplicationForm/GameType/Reducer";
import Saga from "Modules/Certificate/Casino/ApplicationForm/GameType/Saga";
import {
    CreateCertificateGameTypeRequestAction,
    SetCertificateGameTypeValueAction,
} from "Modules/Certificate/Casino/ApplicationForm/GameType/Actions";


import { MakeApproveCertificateGameTypeSelector } from "Modules/Certificate/Casino/ApplicationForm/GameType/Selectors";
import { DATE_FORMAT } from "Modules/Application/Constants";
import { useSearchParams } from "react-router-dom";
import { SelectGameTypeService } from "Modules/Settings/GameType/Service";
import { SelectGameTypeSuccessAction } from "Modules/Settings/GameType/Actions";
import { GetNationalityOptionService } from "Modules/Entity/Operator/Service";
import { SelectableNationalitySuccessAction } from "Modules/License/Casino/Actions";

const key = "certificateGameType";
export const CertificateGameTypeCreate = () => {
    useInjectReducer({ key, reducer: Reducer });
    useInjectSaga({ key, saga: Saga });
    // fields
    const [form] = Form.useForm();
    const [searchParams] = useSearchParams();
    const dispatch = useDispatch();
    const entityType = searchParams.get("entityType");
    const requestGameTypeApprove = useSelector(MakeApproveCertificateGameTypeSelector());

    // constructor
    useEffect(() => {
        const entityTypeId = entityType === "LOTTERY" ? 2 : 1;
        GetNationalityOptionService(dispatch).then((nationalityRs: any) => {
            if(nationalityRs) {
                SelectableNationalitySuccessAction(nationalityRs);
            }
            SelectGameTypeService(entityTypeId, dispatch).then((gameTypeOption: any) => {
                if(gameTypeOption) {
                    dispatch(SelectGameTypeSuccessAction(gameTypeOption));
                }
            });
        })
        

        return () => {
            dispatch(SelectableNationalitySuccessAction([]));
            dispatch(SelectGameTypeSuccessAction([]));
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {

        if (requestGameTypeApprove) {
            const formModel = {
                ...requestGameTypeApprove,
            };
            formModel.referenceId = requestGameTypeApprove.id;
            formModel.receivedDate = dayjs(
                requestGameTypeApprove.receivedDate,
                DATE_FORMAT
            );
            formModel.formData.applicant.dateOfBirth = dayjs(
                requestGameTypeApprove.formData.applicant.dateOfBirth,
                DATE_FORMAT
            );
            formModel.formData.applicant.identityIssuedDate = dayjs(
                requestGameTypeApprove.formData.applicant.identityIssuedDate,
                DATE_FORMAT
            );
            formModel.formData.applicant.expiredDate = dayjs(
                requestGameTypeApprove.formData.applicant.expiredDate,
                DATE_FORMAT
            );
            form.setFieldsValue(formModel);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [requestGameTypeApprove])

    // events
    const submitCasinoTypeLicenseForm = (name: string, values: any) => {

        let licenseModel = {
            ...requestGameTypeApprove,
            ...form.getFieldsValue(),
            entityName: "Casino",
            formType: "C001",
            CompanyNameInKhmer: values.formData.company.name,
            CasinoNameInKhmer: values.formData.casino.name,
            code: values.formData.casino.code,
            applicantName: values.formData.applicant.fullname,
            applicantNationality: values.formData.applicant.nationality,
            applicantContactNumber: values.formData.applicant.mobilePhoneNumber,
            representativeName: values.formData.applicant.fullname,
            representativeNationality: values.formData.applicant.nationality,
            representativeContactNumber:
                values.formData.applicant.mobilePhoneNumber,
        };
        licenseModel.receiverName = " ";
        licenseModel.trackingCode = " ";
        if (values.formData.representativeType === true) {
            licenseModel.representativeName =
                values.formData.representative.fullname;
            licenseModel.representativeNationality =
                values.formData.representative.nationality;
            licenseModel.representativeContactNumber =
                values.formData.representative.mobilePhoneNumber;
        }
        licenseModel.formData.casinoTypeId = values.formData.casinoTypeId;


        dispatch(SetCertificateGameTypeValueAction(licenseModel));
        dispatch(CreateCertificateGameTypeRequestAction());
    };

    const onGameTypeModalFormSubmit = (
        requestCasinoGameRuleLicenseForm: any,
        values: any
    ) => {
        const gameTypes =
            requestCasinoGameRuleLicenseForm.getFieldValue("gameTypes") || [];

        requestCasinoGameRuleLicenseForm.setFieldsValue({
            gamesType: [...gameTypes, values],
        });
        requestCasinoGameRuleLicenseForm.setFieldsValue({
            gameTypes: [...gameTypes, values],
        });
    };

    const ActionButton = () => {
        return (
            <button
                onClick={() => {
                    form.submit();
                }}
                type="button"
                className="inline-flex items-center justify-center px-4 py-2 mx-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500"
            >
                រក្សាទុក
            </button>
        );
    };

    // events
    const onFormProviderSubmit = (name: string, { values, forms }: any) => {

        switch (name) {
            case "certificateGameTypeForm":
                submitCasinoTypeLicenseForm(name, values);
                break;
            case "gameTypeModalForm":
                const { requestCasinoGameRuleLicenseForm } = forms;
                onGameTypeModalFormSubmit(
                    requestCasinoGameRuleLicenseForm,
                    values
                );
                break;
            default: {
                break;
            }
        }
    };

    return (
        <FullLayout
            icon={<ArchiveIcon />}
            title={`ស្នើសុំចុះបញ្ជីប្រភេទល្បែង  (C002)`}
            descriptions=" "
            actions={<ActionButton />}
        >
            <HelmetHeader meta="ចុះបញ្ជី" />
            <Form.Provider onFormFinish={onFormProviderSubmit}>
                <CertificateGameTypeForm form={form} />
            </Form.Provider>
        </FullLayout>
    );
};
