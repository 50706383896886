import {
    ALERT_UNMOUNT,
    AUTO_DISMISS_ALERT,
    CLEAR_ALERT,
    SHOW_ALERT_MESSAGE,
} from "Components/AlertMessage/Constants";

/**
 * Enqueue alert on the screen
 * @param  {object} alert payload
 *
 */

export function EnqueueAlertAction(alert: any) {
    return {
        type: SHOW_ALERT_MESSAGE,
        alert,
    };
}

/**
 * Remove alert from the screen
 *
 * @return {object} An action object with a type of CLEAR_ALERT
 */
export function ClearAlertAction() {
    return {
        type: CLEAR_ALERT,
    };
}

export function AutoDismissAlertAction() {
    return {
        type: AUTO_DISMISS_ALERT,
    };
}

export function AlertUnmountAction() {
    return {
        type: ALERT_UNMOUNT,
    };
}
