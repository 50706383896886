import React from "react";
import "./PrintTemplate.css";
import { QRCode } from "antd";
import dayjs from "dayjs";
export const PrintTemplate = React.forwardRef(({inspectionCertificate}: any) => {
    console.log("inspectionCertificate", inspectionCertificate);
    return (
      <div className="pagebreak" >
        <QRCode className=" relative border-0" value={`https://qr.cgmc.gov.kh/inspection-certificate/${inspectionCertificate.versionGuid}`}
            // size={120}
            size={inspectionCertificate.inspectionCertificateType.printWidth}
            style={{
                top: inspectionCertificate.inspectionCertificateType.y,
                left: inspectionCertificate.inspectionCertificateType.x,
            }}
        />
        <span
            className="relative block text-center text-xs"
            style={{
                top: inspectionCertificate.inspectionCertificateType.y + 2,
                left: inspectionCertificate.inspectionCertificateType.x,
                width: inspectionCertificate.inspectionCertificateType.printWidth
            }}
        >
            {inspectionCertificate.serialNumber}
        </span>
        <span
            className="relative block text-center text-lg font-extrabold"
            style={{
                top: inspectionCertificate.inspectionCertificateType.y - 26,
                left: inspectionCertificate.inspectionCertificateType.x - 145,
                width: inspectionCertificate.inspectionCertificateType.printWidth
            }}
        >
            {dayjs(inspectionCertificate.validedDate).format("YYYY")}
        </span>
      </div>
    );
  });