import {
    SET_LOTTERY_GAME_TYPE_CERTIFY_APPROVE_VALUE,
    SET_LOTTERY_CERTIFY_VALUE,
    SET_LOTTERY_CERTIFY_FILTER_VALUE,
    SET_LOTTERY_CERTIFY_ID,
    LIST_LOTTERY_CERITFY_REQUEST,
    LIST_LOTTERY_CERITFY_SUCCESS,
    GET_LOTTERY_CERTIFY_REQUEST,
    GET_LOTTERY_CERTIFY_SUCCESS,
    CREATE_LOTTERY_CERTIFY_REQUEST
} from "Modules/Certificate/Lottery/Certify/Constants";

export const SetLotteryGameTypeApproveCertifyValueAction = (values: any) => {
    return {
        type: SET_LOTTERY_GAME_TYPE_CERTIFY_APPROVE_VALUE,
        values
    }
}

export const SetLotteryCertifyValueAction = (values: any) => {
    return {
        type: SET_LOTTERY_CERTIFY_VALUE,
        values,
    }
}

export const SetLotteryCertifyFilterValueAction = (values: any) => {
    return {
        type: SET_LOTTERY_CERTIFY_FILTER_VALUE,
        values,
    }
}

export const SetLotteryCertifyIdAction = (id: string) => {
    return {
        type: SET_LOTTERY_CERTIFY_ID,
        id
    }
}

export const ListLotteryCertifyRequestAction = () => {
    return {
        type: LIST_LOTTERY_CERITFY_REQUEST,
    }
}

export const ListLotteryCertifySuccessAction = (lotteryCertifies: any[]) => {
    return {
        type: LIST_LOTTERY_CERITFY_SUCCESS,
        lotteryCertifies,
    }
}

export const GetLotteryCertifyRequestAction = () => {
    return {
        type: GET_LOTTERY_CERTIFY_REQUEST
    }
}

export const GetLotteryCertifySuccessAction = (lotteryCertify: any) => {
    return {
        type: GET_LOTTERY_CERTIFY_SUCCESS,
        lotteryCertify
    }
}

export const CreateLotteryCertifyRequestAction = () => {
    return {
        type: CREATE_LOTTERY_CERTIFY_REQUEST
    }
}

