import { useDispatch } from "react-redux";
import dayjs from "dayjs";
import { useInjectSaga } from "Utility/InjectSaga";
import { useInjectReducer } from "Utility/InjectReducer";
import { Link } from "react-router-dom";

import { Form, Table, Button } from "antd";
import { FullLayout } from "Components/Layouts/";
import { HelmetHeader } from "Components/Helmet-Header";
import { CertificateGameEquipmentFilterForm } from "Modules/Certificate/Casino/ApplicationForm/GameEquipment/Forms/CertificateGameEquipmentFilterForm";

// import Reducer from "Modules/Certificate/Casino/GameEquipment/Reducer";
// import Saga from "Modules/Certificate/Casino/GameEquipment/Saga";
import CertificateGameEquipmentReducer from "Modules/Certificate/Casino/ApplicationForm/GameEquipment/Reducer";
import CertificateGameEquipmentSaga from "Modules/Certificate/Casino/ApplicationForm/GameEquipment/Saga";
import {
    SetCertificateGameEquipmentFilterValueAction,
    ListCertificateGameEquipmentRequestAction,
} from "Modules/Certificate/Casino/ApplicationForm/GameEquipment/Actions";
// import { MakeSelectLicenseListSelector } from "Modules/Certificate/Casino/GameEquipment/Selectors";
import { DATE_FORMAT } from "Modules/Application/Constants";

// const key = "licenseList";
const key = "certificateGameEquipment";
export const List = () => {
    useInjectReducer({ key, reducer: CertificateGameEquipmentReducer });
    useInjectSaga({ key, saga: CertificateGameEquipmentSaga });

    //fields
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    // const licenses = useSelector(MakeSelectLicenseListSelector());

    const licenseColumns = [
        {
            title: "លេខអាជ្ញាបណ្ណ",
            key: "code",
            render: (item: any) => {
                const address = JSON.parse(item.formData).casino
                    .casinoLicenseNumber;

                return (
                    <Link
                        to={`/license/casino/detail/${item.id}`}
                        className="underline text-blue-500"
                    >
                        {address}
                    </Link>
                );
            },
        },
        {
            title: "ឈ្មោះក្រុមហ៊ុន",
            key: "companyNameInKhmer",
            dataIndex: "companyNameInKhmer",
        },
        {
            title: "ឈ្មោះកាស៊ីណូ",
            key: "casinoNameInKhmer",
            dataIndex: "casinoNameInKhmer",
        },
        {
            title: "ទីតាំងធ្វើអាជីវកម្ម",
            key: "address",
            // dataIndex: "representativeName",
            render: (item: any) => {
                const address = JSON.parse(item.formData).casino.address;

                return <span>{address}</span>;
            },
        },
        {
            title: "ថ្ងៃផ្តល់អាជ្ញាបណ្ណ",
            key: "licenseIssuedDate",
            // dataIndex: "licenseExpiredDate",
            render: (item: any) => (
                <span>
                    {item.licenseIssuedDate
                        ? dayjs(item.licenseIssuedDate).format(DATE_FORMAT)
                        : ""}
                </span>
            ),
        },
        {
            title: "សុពលភាពអាជ្ញាបណ្ណ",
            key: "licenseExpiredDate",
            // dataIndex: "licenseExpiredDate",
            render: (item: any) => (
                <span>
                    {item.licenseExpiredDate
                        ? dayjs(item.licenseExpiredDate).format(DATE_FORMAT)
                        : ""}
                </span>
            ),
        },
    ];
    // method
    const ActionButton = () => {
        return (
            <>
                <Button type="primary">
                    <Link
                        to="/license/casino/create"

                    >
                        បញ្ចូលថ្មី
                    </Link>
                </Button>

            </>
        );
    };
    // events

    const onFilterFormSubmit = async (values: any) => {
        // const expiredDate: any = form.getFieldValue("expiredDate");
        const formValues = {
            code: form.getFieldValue("code"),
            // startExpiredEDate:
            //     expiredDate[0] === "" || !expiredDate[0]
            //         ? null
            //         : expiredDate[0].toString("YYYY-MM-DD"),
            // endExpiredEDate:
            //     expiredDate[1] === "" || !expiredDate[1]
            //         ? null
            //         : expiredDate[1].toString("YYYY-MM-DD"),
            formType: values.formType,
        };

        dispatch(SetCertificateGameEquipmentFilterValueAction(formValues));
        dispatch(ListCertificateGameEquipmentRequestAction());
    };

    return (
        <FullLayout
            icon={null}
            title="បញ្ជីការចុះបញ្ជីឧបករណ៍ល្បែង"
            descriptions=""
            actions={<ActionButton />}
        >
            <HelmetHeader meta="បញ្ជីការចុះបញ្ជីឧបករណ៍ល្បែង" />
            <Form
                onFinish={onFilterFormSubmit}
                name="licenseListForm"
                layout="vertical"
                className="p-4"
                form={form}
                initialValues={{ search: "" }}
            >
                <CertificateGameEquipmentFilterForm form={form} />
                <Table columns={licenseColumns} dataSource={[]} rowKey="id" size="small"/>
            </Form>
        </FullLayout>
    );
};
