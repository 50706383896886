import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Row, Col, Input, Select } from "antd";

import { useInjectReducer } from "Utility/InjectReducer";
import { useInjectSaga } from "Utility/InjectSaga";
import GameTypeReducer from "Modules/Settings/GameType/Reducer";
import GameTypeSaga from "Modules/Settings/GameType/Saga";
import { MakeSelectGameTypeSelector } from "Modules/Settings/GameType/Selectors";
import {
    SetFormValuesAction,

    SelectGameTypeRequestAction,
} from "Modules/Settings/GameType/Actions";
import { useSearchParams } from "react-router-dom";

const gameTypeKey = "gameType";
export const SettingGameRuleForm = ({ form }: any) => {
    useInjectReducer({ key: gameTypeKey, reducer: GameTypeReducer });
    useInjectSaga({ key: gameTypeKey, saga: GameTypeSaga });

    //fields
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();
    const gameTypeOptions = useSelector(MakeSelectGameTypeSelector());
    const entityType = searchParams.get("entityType");

    // constructor
    useEffect(() => {
        const entityTypeId = entityType === "LOTTERY" ? 2 : 1;
        dispatch(SetFormValuesAction({ search: "", entityTypeId: entityTypeId }));
        dispatch(SelectGameTypeRequestAction());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (

        <Row>
            <Col span={4}>វិធានល្បែង</Col>
            <Col span={20}>
                <Form.Item name="name">
                    <Input />
                </Form.Item>
            </Col>
            <Col span={4}>ប្រភេទល្បែង</Col>
            <Col span={20}>
                <Form.Item name="gameTypeIds">
                    {/* <ComboBox data={gameTypes} label="name" keyValue="id" /> */}
                    <Select mode="multiple">
                        {gameTypeOptions &&
                            gameTypeOptions.map((item) => (
                                <Select.Option
                                    key={`${item["id"]}`}
                                    value={item["id"]}
                                >
                                    {item["name"]}
                                </Select.Option>
                            ))}
                    </Select>
                </Form.Item>
            </Col>
            <Col span={4}>បរិយាយ / ផ្សេងៗ</Col>
            <Col span={20}>
                <Form.Item name="descriptions">
                    <Input.TextArea />
                </Form.Item>
            </Col>
        </Row>

    );
};
