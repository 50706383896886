export const RequestCasinoLicense = {
    CASINO_LICENSE_REQUEST: "license/casino/CASINO_LICENSE_REQUEST",
    CASINO_LICENSE_ERROR: "license/casino/CASINO_LICENSE_REQUEST",
    CASINO_LICENSE_SUCCESS: "license/casino/CASINO_LICENSE_REQUEST",
    SET_FORM_VALUES: "license/casino/SET_FORM_VALUES",
};

export interface BoardOfDirectorType {
    key: string;
    name: string;
    position: string;
    nationality: string;
    address: string;
}
