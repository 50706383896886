import axios from "axios";
import { isExpired } from "react-jwt";
import { LogoutAction, SetPermissionValueAction } from "Modules/Application/Actions";
import { SHOW_SNACK_MESSAGE } from "Components/SnackMessage/Constants";
import { getItem } from "Hooks/useCookie";
import { v4 as uuid } from "uuid";
import { store } from "Store";
import { StatusCodesList } from "Utility/Constant";
import { decodePermission } from "Utility/Permission";

/**
 * Create an Axios Client with defaults
 */
const client = axios.create({
    baseURL: process.env.REACT_APP_API_CORE_URI,
    withCredentials: true,
});

client.interceptors.response.use(
    (res) => {
        return res;
    },
    async (err) => {
        const originalConfig = err.config;
        const token = localStorage.getItem("cgmcToken") || "";
        const isTokenExpired = isExpired(token);

        if (
            err.response.status === StatusCodesList.Unauthorize &&
            isTokenExpired === true
        ) {
            // eslint-disable-next-line no-underscore-dangle
            if (!originalConfig._retry) {
                // eslint-disable-next-line no-underscore-dangle
                originalConfig._retry = true;
                try {
                    const refreshClient = axios.create({
                        baseURL: process.env.REACT_APP_API_CORE_URI,
                        withCredentials: true,
                    });
                    let config = {
                        headers: {
                            Authorization: ` Bearer ${token}`,
                        },
                        withCredential: true,
                    };

                    const newToken = await refreshClient.post(
                        "/api/auth/refresh",
                        {
                            accessToken: token,
                        },
                        config
                    );

                    if (newToken.status === 200) {
                        localStorage.setItem(
                            "cgmcToken",
                            newToken.data.accessToken
                        );
                        const grants = decodePermission(newToken.data.accessToken);
                        store.dispatch(SetPermissionValueAction(grants));
                        originalConfig.headers.Authorization = `Bearer ${newToken.data.accessToken}`;
                    }
                    return client(originalConfig);
                } catch (_error) {
                    console.log("Error", _error);
                    return Promise.reject(_error);
                }
            }
            return {
                ...originalConfig,
                cancelToken: new axios.CancelToken((cancel) =>
                    cancel("Cancel repeated request")
                ),
            };
        }
        if (
            err.response.status === StatusCodesList.TokenExpired &&
            !getItem("ExpiresIn")
        ) {

            store.dispatch(LogoutAction());
        }

        return Promise.reject(err);
    }
);

/**
 * Request Wrapper with default success/error actions
 */
const AuthenticationRequest = (options: any) => {
    const onSuccess = (response: any) => {
        if (options.withStatus === true) {
            return response;
        }
        return response.data;
    };
    const onError = (error: any) => {
        if (error.response?.data) {
            // Error codes Array
            const errorCodesArray = [
                StatusCodesList.InvalidRefreshToken,
                StatusCodesList.RefreshTokenExpired,
                StatusCodesList.TokenExpired,
            ];
            // Check if otp required error code is in the array
            if (error.response.data.code === StatusCodesList.OtpRequired) {
                // return store.dispatch(otpUnVerifiedAction());
            }
            if (errorCodesArray.includes(error.response.data.code)) {
                return store.dispatch({
                    type: SHOW_SNACK_MESSAGE,
                    snack: {
                        type: "error",
                        message: error.response.data.message,
                        id: uuid(),
                    },
                });
            }
        }

        return Promise.reject(error.response || error.message);
    };

    return client(options).then(onSuccess).catch(onError);
};

export default AuthenticationRequest;
