import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useInjectReducer } from "Utility/InjectReducer";
import { useInjectSaga } from "Utility/InjectSaga";

import { ArchiveIcon } from "@heroicons/react/outline";
import { HelmetHeader } from "Components/Helmet-Header";
import { FullLayout } from "Components/Layouts";
import { 
  Form,
  Select,
  Table,
  Button,
  Modal,
  DatePicker,
} from "antd"

import InspectionCertificateMasterReducer from "Modules/Settings/InspectionCertificateMaster/Reducer";
import InspectionCertificateMasterSaga from "Modules/Settings/InspectionCertificateMaster/Saga";
import CasinoReducer from "Modules/Entity/Operator/Reducer";
import CasinoSaga from "Modules/Entity/Operator/Saga";
import { SelectCasinoSuccessAction } from "Modules/Entity/Operator/Actions";
import { MakeSelectCasinoSelector } from "Modules/Entity/Operator/Selectors";
import { GetOperatorOptionService } from "Modules/Entity/Operator/Service";
import { useReactToPrint } from "react-to-print";
import { GetEquipmentBinding } from "../Service";

const key = "inspectionCertificateMaster";
const entityKey = "entityCasino";
export const CasinoEquipmentBindingReport = () => {
  useInjectReducer({key, reducer: InspectionCertificateMasterReducer});
  useInjectSaga({key, saga: InspectionCertificateMasterSaga});
  useInjectReducer({key: entityKey, reducer: CasinoReducer});
  useInjectSaga({key: entityKey, saga: CasinoSaga});
  // fields
  const dispatch = useDispatch();
  const [reports, setReports] = useState<any>();
  const casinoOptions = useSelector(MakeSelectCasinoSelector());
  const [equipmentTypeReport, setEquipmentTypeReport] = useState<any>();
  const [gameRuleReport, setGameRuleReport] = useState<any>();
  const printTemplateRef = useRef();

  const printColumns = [
    {
      title: "លេខកូដកាស៊ីណូ",
      key: "code",
      dataIndex: "code",
    },
    {
      title: "ប្រតិបត្តិករកាស៊ីណូ",
      key: "name",
      dataIndex: "name",
    },
    {
      title: "ឈ្មោះកម្មសិទ្ធករ",
      key: "company",
      dataIndex: "company",
    },
    {
      title: "Table",
      key: "table",
      dataIndex: "table",
    },
    {
      title: "Slot",
      key: "slot",
      dataIndex: "slot",
    },
    {
        title: "ETG 2-8",
        key: "etg",
        dataIndex: "etg",
      },
      {
        title: "ETG > 8",
        key: "etgOver",
        dataIndex: "etgOver",
      },
      
  ];

  // constructo;r
  useEffect(() => {
    GetOperatorOptionService(dispatch)
    .then((casinoRs: any) => {
        if(casinoRs) {
            dispatch(SelectCasinoSuccessAction(casinoRs));
        }
    })
    
    return() => {
      dispatch(SelectCasinoSuccessAction([]));
    
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // event
  const onFilter = (e) => {
    
    console.log("e", e.filterDate)
    const filter = {
        casinoId: null,
    }
    GetEquipmentBinding(dispatch,filter).then(
        (rs: any) => {
            console.log("Rs", rs);
            setReports(rs);
        }
    )
  }

  const onSummary = () => {
    const result: any = [];
    const gameRuleResult: any = [];
    reports.map(function(value: any) {
        if(!gameRuleResult[value.gameRuleName]) {
            gameRuleResult[value.gameRuleName] = 0;
        }
        if(!result[value.equipmentType]) {
            result[value.equipmentType] = 0;
        }
        result[value.equipmentType] += 1;
        gameRuleResult[value.gameRuleName] += 1;
        return result;
    });
    console.log("Result", result);
    setEquipmentTypeReport(result);
    setGameRuleReport(gameRuleResult);
  }

  const onPrintSerials = () => {
    Modal.confirm({
        title: 'Print Confirmation',
        content: (
          <div>
            <p>Do you want to print?</p>
            
          </div>
        ),
        okText: "Print",
        cancelText: "Cancel",
        onOk() {
            onPrintSerialsContent();
        },
    });
    
    
}

  const onPrintSerialsContent = useReactToPrint({
        content: () => {
            return printTemplateRef.current;
        }
    })

  // render
  const ActionButton = () => {
    return <>
        <Button type="default" onClick={onPrintSerials}>
            Print
        </Button>
    </>
  }

  const gameRuleSummary = () => {
    const gameRuleData = []
    for (const key in gameRuleReport) {
        console.log(`${key}: ${gameRuleReport[key]}`);
        gameRuleData.push({
            key: key,
            value: gameRuleReport[key],
        });
    }
    return gameRuleData;
  }

  const equipmentTypeSummary = () => {
    const gameRuleData = []
    for (const key in equipmentTypeReport) {
        console.log(`${key}: ${equipmentTypeReport[key]}`);
        gameRuleData.push({
            key: key,
            value: equipmentTypeReport[key],
        });
    }
    return gameRuleData;
  }

  return (
    <FullLayout
        icon={<ArchiveIcon />}
        title="របាយការណ៍ ពីការ Binding ឧបករណ៍"
        descriptions=""
        redirectUrl=""
        actions={<ActionButton />}
    >
        <HelmetHeader meta="របាយការណ៍ ពីការ Binding ឧបករណ៍" />
        <Form 
            name="casinoInspectionCertificateReportForm" 
            layout="vertical"
            className="flex flex-row items-end p-4 m-0 gap-2"
            onFinish={onFilter}>
            <Form.Item name="casino">
            <Select 
                placeholder="កាស៊ីណូ" 
                style={{width: 310}} 
                // onChange={onFilter}
                
                filterOption={(
                    input,
                    option
                ) =>
                    option!.children
                        .join(" ")
                        .toLowerCase()
                        .includes(
                            input.toLowerCase()
                        )
                }
                showSearch
                allowClear>
            {casinoOptions &&
                casinoOptions.map(
                    (item) => (
                        <Select.Option
                            key={
                                item.value
                            }
                            value={
                                item.value
                            }
                        >
                            {item.label}
                            (
                            {
                                item.placeholder
                            }
                            )
                        </Select.Option>
                    )
                )}
            </Select>
            </Form.Item>
            <Form.Item name="filterDate">
                <DatePicker.RangePicker />
            </Form.Item>
            <Form.Item >
                <Button htmlType="submit">ស្វែងរក</Button>
            </Form.Item>
        </Form>
      
        
        <div className="w-full">
            <div className="m-auto" ref={printTemplateRef}>
                {/* {
                    inspectionSerials && inspectionSerials.length > 0 && 
                        inspectionSerials.map((inspectionCertificate: any) => <PrintTemplate key={inspectionCertificate.id} inspectionCertificate={inspectionCertificate}/>)
                } */}
                {
                    equipmentTypeReport && <Table 
                        className="" 
                        size="small" rowKey="key" 
                        dataSource={equipmentTypeSummary()}
                        pagination={false}
                    >
                        <Table.Column 
                            title="ប្រភេទឧបករណ៍ / Equipment Type"
                            dataIndex="key"
                        />
                        <Table.Column 
                            title="ចំនួន"
                            dataIndex="value"
                        />
                    </Table>
                }

                <Table 
                    className="" 
                    size="small" rowKey="id" 
                    pagination={false} 
                    dataSource={reports} columns={printColumns}
                    // summary={() => (
                    //     <Table.Summary fixed>
                    //     <Table.Summary.Row>
                    //         <Table.Summary.Cell index={0}>Total Terminal</Table.Summary.Cell>
                    //         <Table.Summary.Cell index={1}>{reports.length}</Table.Summary.Cell>
                    //     </Table.Summary.Row>
                    //     </Table.Summary>
                    // )}
                />
            </div>
        </div>
  </FullLayout>
  )
}