import { FullLayout } from "Components/Layouts";
import { LotteryBranchApplicationFormFilter } from "../Forms/LotteryBranchApplicationFormFilter";
import { HelmetHeader } from "Components/Helmet-Header";
import { ArchiveIcon } from "@heroicons/react/outline";
import { Button, Space, Table } from "antd";
import { Link } from "react-router-dom";

export const LotteryBranchApplicationFormList = () => {
    // fields
    const branchApplicationFormColumns = [
        {
            key: "documentCode",
            title: "លេខតាមដានឯកសារ",
            render: (item: any) => (
                <Link to={item.id} className="underline text-blue-500">
                    {item.documentCode}
                </Link>
            ),
        },
        {
            key: "code",
            title: "កូដ",
            dataIndex: "code",
        },
        {
            key: "casinoName",
            title: "ល្បែងផ្សងសំណាង",
            render: (item: any) => (
                <span>
                    {item.casinoName} - {item.casinoNameInKhmer}
                </span>
            ),
        },
    ];

    // constructor

    // render
    const ActionButtons = () => {
        return(
            <Space>
                <Link to="create"><Button type="primary">បញ្ចូលថ្មី</Button></Link>
            </Space>
        )
    }

    return (
        <FullLayout
            icon={<ArchiveIcon />}
            title="បញ្ជីពាក្យស្នើសុំចុះបញ្ជីអាជ្ញាបណ្ណផ្សងសំណាង"
            descriptions=""
            actions={<ActionButtons />}
        >
            <HelmetHeader meta="បញ្ជីពាក្យស្នើសុំចុះបញ្ជីអាជ្ញាបណ្ណសាខាល្បែងផ្សងសំណាង" />

            <LotteryBranchApplicationFormFilter
                // form={form}
                // onFinish={onFinish}
            />
            <Table
                rowKey="id"
                columns={branchApplicationFormColumns}
                size="small"
                // dataSource={requestLotteryLicenses || []}
            />
        </FullLayout>
    )
}