import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import { useInjectSaga } from "Utility/InjectSaga";
import { useInjectReducer } from "Utility/InjectReducer";
import dayjs from "dayjs";

import { Form, Space, Button } from "antd";
import { ArchiveIcon } from "@heroicons/react/outline";
import { FullLayout } from "Components/Layouts/";
import { HelmetHeader } from "Components/Helmet-Header";
import { RequestGameTypeDetailForm } from "Modules/Certificate/Casino/ApplicationForm/GameType/Forms/RequestGameTypeDetailForm";

import Reducer from "Modules/License/Casino/Request/Reducer";
import Saga from "Modules/License/Casino/Request/Saga";

import RequestGameTypeReducer from "Modules/Certificate/Casino/ApplicationForm/GameType/Reducer";
import RequestGameTypeSaga from "Modules/Certificate/Casino/ApplicationForm/GameType/Saga";
import LicenseCasinoReducer from "Modules/License/Casino/Reducer";

import {
    SetRequestGameTypeIdAction,
    GetRequestGameTypeSuccessAction,
    GetRequestGameTypeRequestAction,
    SetRequestGameTypeValueAction,
    EditRequestGameTypeRequestAction,
    SetApproveCertificateGameTypeValueAction
} from "Modules/Certificate/Casino/ApplicationForm/GameType/Actions";
import { MakeCasinoSelector } from "Modules/Entity/Operator/Selectors";
import { MakeRequestGameTypeDetailSelector } from "Modules/Certificate/Casino/ApplicationForm/GameType/Selectors";
import { DATE_FORMAT } from "Modules/Application/Constants";
import { GetIdentificationTypeOptionService, GetOperatorOptionService } from "Modules/Entity/Operator/Service";
import { SelectableIdentificationTypeSuccessAction } from "Modules/License/Casino/Actions";
import { SelectGameTypeSuccessAction } from "Modules/Settings/GameType/Actions";
import { SelectGameTypeService } from "Modules/Settings/GameType/Service";
import { SelectCasinoSuccessAction } from "Modules/Entity/Operator/Actions";

const key = "certificateGameType";
const certificateGameTypeKey = "certificateGameType";
const licenseKey = "licenseCasino";
export const RequestGameTypeDetail = () => {
    //Injector
    useInjectReducer({ key, reducer: Reducer });
    useInjectSaga({ key, saga: Saga });
    useInjectReducer({ key: licenseKey, reducer: LicenseCasinoReducer });

    useInjectReducer({
        key: certificateGameTypeKey,
        reducer: RequestGameTypeReducer,
    });
    useInjectSaga({ key: certificateGameTypeKey, saga: RequestGameTypeSaga });

    // fields
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const casinoModel = useSelector(MakeCasinoSelector());
    const requestGameTypeDetail = useSelector(
        MakeRequestGameTypeDetailSelector()
    );
    const [searchParams] = useSearchParams();
    const entityType = searchParams.get("entityType");
    const { id } = useParams();

    // tables columns

    // constructor
    useEffect(() => {
        if (casinoModel) {

            let formData = {
                company: { name: casinoModel.company.name },
                casino: {
                    name: casinoModel.casino.name,
                    address: casinoModel.casino.address,
                },
            };

            form.setFieldsValue({
                formData: { ...formData },
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [casinoModel]);

    useEffect(() => {
        if (requestGameTypeDetail) {
            const formModel = {
                // documentCode: "",
                // receiverName: "",
                // gamblingTaxCode: "",
                ...requestGameTypeDetail,
            };
            formModel.gameTypes = requestGameTypeDetail.formData.gameTypes;
            formModel.receivedDate = dayjs(
                requestGameTypeDetail.receivedDate,
                DATE_FORMAT
            );
            formModel.formData.applicant.dateOfBirth = dayjs(
                requestGameTypeDetail.formData.applicant.dateOfBirth,
                DATE_FORMAT
            );
            formModel.formData.applicant.identityIssuedDate = dayjs(
                requestGameTypeDetail.formData.applicant.identityIssuedDate,
                DATE_FORMAT
            );
            formModel.formData.applicant.expiredDate = dayjs(
                requestGameTypeDetail.formData.applicant.expiredDate,
                DATE_FORMAT
            );
            form.setFieldsValue(formModel);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [requestGameTypeDetail]);

    useEffect(() => {
        const entityTypeId = entityType === "LOTTERY" ? 2 : 1;
        if (id) {
            GetIdentificationTypeOptionService(dispatch).then((identityRs: any) => {
                if(identityRs) {
                    dispatch(SelectableIdentificationTypeSuccessAction(identityRs));
                }
                SelectGameTypeService(entityTypeId, dispatch).then((gameTypeOption: any) => {
                    if(gameTypeOption) {
                        dispatch(SelectGameTypeSuccessAction(gameTypeOption));
                    }
                    GetOperatorOptionService(dispatch).then((casinoOptionsRs: any) => {
                        if(casinoOptionsRs) {
                            dispatch(SelectCasinoSuccessAction(casinoOptionsRs));
                        }
                        
                            dispatch(SetRequestGameTypeIdAction(id));
                            dispatch(GetRequestGameTypeRequestAction());
                        
                    });
                });
                
            });
        }
        

        return () => {
            dispatch(SelectableIdentificationTypeSuccessAction([]));
            dispatch(SelectCasinoSuccessAction([]));
            dispatch(SelectGameTypeSuccessAction([]));
            dispatch(GetRequestGameTypeSuccessAction(null));
        };
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    // methods
    const submitCasinoLicenseForm = async (name: string, values: any) => {
        // await form.validateFields();

        let licenseModel = {
            ...form.getFieldsValue(),
            entityName: "GameType",
            formType: "C002",
            CompanyNameInKhmer: values.formData.company.name,
            CasinoNameInKhmer: values.formData.casino.name,
            code: values.formData.casino.code,
            applicantName: values.formData.applicant.fullname,
            applicantNationality: values.formData.applicant.nationality,
            applicantContactNumber: values.formData.applicant.mobilePhoneNumber,
            representativeName: values.formData.applicant.fullname,
            representativeNationality: values.formData.applicant.nationality,
            representativeContactNumber:
                values.formData.applicant.mobilePhoneNumber,
        };
        // licenseModel.receiverName = " ";
        licenseModel.trackingCode = " ";
        if (values.formData.representativeType === true) {
            licenseModel.representativeName =
                values.formData.representative.fullname;
            licenseModel.representativeNationality =
                values.formData.representative.nationality;
            licenseModel.representativeContactNumber =
                values.formData.representative.mobilePhoneNumber;
        }
        licenseModel.formData.casinoTypeId = values.formData.casinoTypeId;

        licenseModel.formData.gameTypes = form.getFieldValue("gameTypes");

        await dispatch(SetRequestGameTypeValueAction(licenseModel));
        await dispatch(EditRequestGameTypeRequestAction());
    };

    const onApprove = () => {
        const values = form.getFieldsValue();
        let certificateModel = {
            ...casinoModel,
            ...values,
            entityName: "GameType",
            formType: "C002",

            CompanyNameInKhmer: values.formData.company.name,
            CasinoNameInKhmer: values.formData.casino.name,
            code: values.formData.casino.code || "",
            applicantName: values.formData.applicant.fullname,
            applicantNationality: values.formData.applicant.nationality,
            applicantContactNumber: values.formData.applicant.mobilePhoneNumber,
            representativeName: values.formData.applicant.fullname,
            representativeNationality: values.formData.applicant.nationality,
            representativeContactNumber:
                values.formData.applicant.mobilePhoneNumber,
        };
        // licenseModel.receiverName = " ";
        certificateModel.trackingCode = " ";
        certificateModel.gameTypes = form.getFieldValue("gameTypes");
        if (values.formData.representativeType === true) {
            certificateModel.representativeName =
                values.formData.representative.fullname;
            certificateModel.representativeNationality =
                values.formData.representative.nationality;
            certificateModel.representativeContactNumber =
                values.formData.representative.mobilePhoneNumber;
        }
        certificateModel.formData.casinoTypeId = values.formData.casinoTypeId;

        dispatch(SetApproveCertificateGameTypeValueAction(certificateModel));
        navigate("/certificate/casino/request-game-type/create")
    }

    const ActionButton = () => {
        return (
            <Space>
                <Button
                    onClick={() => {
                        form.submit();
                    }}
                    type="primary"
                >
                    រក្សាទុក
                </Button>
            </Space>
        );
    };

    const onGameTypeModalFormSubmit = (
        requestGameTypeForm: any,
        values: any
    ) => {
        const gameTypes = requestGameTypeForm.getFieldValue("gameTypes") || [];

        requestGameTypeForm.setFieldValue("gamesType", [...gameTypes, values]);
        // requestGameTypeForm.setFieldsValue({
        //     gameTypes: [...gameTypes, values],
        // });
    };

    // events
    const onFormProviderSubmit = (name: string, { values, forms }: any) => {
        switch (name) {
            case "requestGameTypeForm":
                submitCasinoLicenseForm(name, values);
                break;
            case "gameTypeModalForm":
                const { requestGameTypeForm } = forms;
                onGameTypeModalFormSubmit(requestGameTypeForm, values);
                break;
            default: {
                break;
            }
        }
    };
    return (
        <FullLayout
            icon={<ArchiveIcon />}
            title={`ពាក្យស្នើសុំចុះប្រភេទល្បែង (C002) - ${requestGameTypeDetail?.casinoNameInKhmer} (${requestGameTypeDetail?.casinoNameLatin})`}
            descriptions="ត្រលប់ទៅបញ្ជី"
            redirectUrl="/certificate/casino/request-game-type/request"
            actions={<ActionButton />}
        >
            <HelmetHeader meta="ស្នើសុំចុះប្រភេទល្បែង" />
            <Form.Provider onFormFinish={onFormProviderSubmit}>
                <RequestGameTypeDetailForm form={form} />
            </Form.Provider>
        </FullLayout>
    );
};
