import { call, put, all, takeLatest, select } from "redux-saga/effects";
import { v4 as uuid } from "uuid";
import { POST, GET, PUT } from "Utility/Constant";
import Request from "Utility/Http";
import ApiEndpoint from "Utility/Endpoint";
import { AsyncStartAction, AsyncEndAction } from "Modules/Application/Actions";
import { ShowAlert, ShowMessage } from "Common/Saga/";
import {
    MakeFormValueSelector,
    MakeGameRuleFilterValueSelector,
    MakeGameRuleIdSelector,
} from "Modules/Settings/GameRule/Selectors";
import {
    GameRuleConstants,
    GET_GAME_RULE_REQUEST,
    SELECT_GAME_RULE_REQUEST,
    UPDATE_GAME_RULE_REQUEST,
} from "Modules/Settings/GameRule/Constants";
import { ClearSnackMessageAction } from "Components/SnackMessage/Actions";
import {
    QueryGameRuleSuccessAction,
    GetGameRuleSuccessAction,
    SelectGameRuleSuccessAction,
} from "Modules/Settings/GameRule/Actions";

export function* GetGameRules() {
    yield put(AsyncStartAction());
    const formValues = yield select(MakeFormValueSelector());
    const requestUrl = `${ApiEndpoint.GAME_RULE_PATH}/lists`;
    const httpPayload = ApiEndpoint.makeApiPayload(
        requestUrl,
        POST,
        formValues
    );

    try {
        const response = yield call(Request, httpPayload);
        yield put(AsyncEndAction());
        yield put(ClearSnackMessageAction());
        yield put(QueryGameRuleSuccessAction(response));
    } catch (error: any) {
        yield put(AsyncEndAction());
        if (error.data && error.data.statusCode === 422) {
        }
        return yield ShowAlert("error", error.data.message);
    }
}

export function* CreateGameRule() {
    yield put(AsyncStartAction());
    const formValues = yield select(MakeFormValueSelector());
    const requestUrl = `${ApiEndpoint.GAME_RULE_CREATE_PATH}`;
    const httpPayload = ApiEndpoint.makeApiPayload(
        requestUrl,
        POST,
        formValues,
        null,
        false,
        true
    );

    try {
        const response = yield call(Request, httpPayload);
        yield put(AsyncEndAction());
        if (response && response?.id) {
            yield ShowMessage({
                type: "success",
                message: "ទិន្នយបានរក្សាទុក",
                content: "ទិន្នយបានរក្សាទុក",
                id: uuid(),
                redirectUrl: `/settings/game-rule?entityType=${formValues.entityType}`,
            });
        }
    } catch (error: any) {
        yield put(AsyncEndAction());
        if (error.data && error.data.statusCode === 422) {
        }
        return yield ShowAlert("error", error.data.message);
    }
}

export function* GetGameRule() {
    yield put(AsyncStartAction());
    const id = yield select(MakeGameRuleIdSelector());
    const requestUrl = `${ApiEndpoint.GAME_RULE_PATH}/${id}`;
    const httpPayload = ApiEndpoint.makeApiPayload(requestUrl, GET);

    try {
        const response = yield call(Request, httpPayload);
        yield put(AsyncEndAction());
        yield put(ClearSnackMessageAction());
        yield put(GetGameRuleSuccessAction(response));
    } catch (error: any) {
        yield put(AsyncEndAction());
        if (error.data && error.data.statusCode === 422) {
        }
        return yield ShowAlert("error", error.data.message);
    }
}

function* SelectGameRule() {
    const model = yield select(MakeGameRuleFilterValueSelector())

    if (!model) {
        return yield ShowMessage({
            type: "error",
            message: "ទាញយកទិន្នន័យមិនបាន",
            content: "ទិន្នយបានរក្សាទុក",
            id: uuid(),
        });
    }
    try {
        yield put(AsyncStartAction());
        const url = `${ApiEndpoint.GAME_RULE_PATH}?entityType=${model.entityTypeId}`;
        const payload = ApiEndpoint.makeApiPayload(url, GET, model);
        const response = yield call(Request, payload);
        if (response && !response.status) {
            yield put(SelectGameRuleSuccessAction(response));
        }
    }
    catch {
        yield ShowMessage({
            type: "error",
            message: "ទាញយកទិន្នន័យមិនបាន",
            content: "ទិន្នយបានរក្សាទុក",
            id: uuid(),
        });
    }
    finally {
        yield put(AsyncEndAction());
    }
}

export function* UpdateGameRule() {
    yield put(AsyncStartAction());
    const formValues = yield select(MakeFormValueSelector());
    const requestUrl = `${ApiEndpoint.GAME_RULE_PATH}`;
    const httpPayload = ApiEndpoint.makeApiPayload(
        requestUrl,
        PUT,
        formValues,
        null,
        false,
        true
    );

    try {
        const response = yield call(Request, httpPayload);
        yield put(AsyncEndAction());
        if (response && response?.id) {
            yield ShowMessage({
                type: "success",
                message: "ទិន្នយបានរក្សាទុក",
                content: "ទិន្នយបានរក្សាទុក",
                id: uuid(),
                redirectUrl: "/settings/game-rule",
            });
        }
    } catch (error: any) {
        yield put(AsyncEndAction());
        if (error.data && error.data.statusCode === 422) {
        }
        return yield ShowAlert("error", error.data.message);
    }
}

export default function* GameRuleSaga() {
    yield all([
        yield takeLatest(
            GameRuleConstants.QUERY_GAME_RULE_REQUEST,
            GetGameRules
        ),
        yield takeLatest(
            GameRuleConstants.CREATE_GAME_RULE_REQUEST,
            CreateGameRule
        ),
        yield takeLatest(GET_GAME_RULE_REQUEST, GetGameRule),
        yield takeLatest(UPDATE_GAME_RULE_REQUEST, UpdateGameRule),
        yield takeLatest(SELECT_GAME_RULE_REQUEST, SelectGameRule),
    ]);
}
