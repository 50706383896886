import produce from "immer";
import { SELECTED_ROW } from "Components/SearchModal/Constants";

export const initialState = {
    selectedRow: null,
    errors: null,
};

const SearchModalReducer = produce((draft, action) => {
    switch (action.type) {
        case SELECTED_ROW:
            draft.selectedRow = action.selectedRow;
            break;
    }
}, initialState);

export default SearchModalReducer;
