import { CasinoLicenseApplicationFormRoute } from "Modules/License/Casino/ApplicationForm";
import { CaslinoLicenseRoute } from "Modules/License/Casino/License";

export const LicenseCasinoRoute =
{
    path: "casino",

    children: [
        CasinoLicenseApplicationFormRoute,
        CaslinoLicenseRoute
    ],
}
