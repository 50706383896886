import {
    CurrencyInitialState
} from "Modules/Settings/Currency/Reducer";
import { createSelector } from "reselect";

const CurrencyDomain = (state: any) => state.currency || CurrencyInitialState;
const MakeSelectCurrencySelector = () => createSelector(CurrencyDomain, substate => substate.currencyOptions);

export {
    MakeSelectCurrencySelector
}