import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useInjectReducer } from "Utility/InjectReducer";
import { useInjectSaga } from "Utility/InjectSaga";

import { Form, Table, Button, Space } from "antd";
import { FullLayout } from "Components/Layouts/Inner/Full.Layout";
import { HelmetHeader } from "Components/Helmet-Header/index";
import { ArchiveIcon } from "@heroicons/react/outline";
import { RequestLotteryLicenseFilterForm } from "Modules/License/Lottery/ApplicationForm/License/Forms/RequestLotteryLicenseFilterForm";

import LotteryLicenseApplicationFormReducer from "Modules/License/Lottery/ApplicationForm/Reducer";
import LotteryLicenseApplicationFormSaga from "Modules/License/Lottery/ApplicationForm/Saga";
import { MakeRequestLotteryLicenseListSelector } from "Modules/License/Lottery/ApplicationForm/Selectors";
import {
    ListRequestLotteryLicenseRequestAction,
    SetRequestLotteryLicenseFilterValueAction,
    ExportRequestLotteryLicenseRequestAction,
    ListRequestLotteryLicenseSuccessAction,
} from "Modules/License/Lottery/ApplicationForm/Actions";
import { EntityType } from "Common/Constants/EntityType";

const key = "lotteryLicenseApplicationForm";
export const RequestLotteryLicenseList = () => {
    useInjectReducer({ key, reducer: LotteryLicenseApplicationFormReducer });
    useInjectSaga({ key, saga: LotteryLicenseApplicationFormSaga });
    // fields
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const requestLotteryLicenses = useSelector(
        MakeRequestLotteryLicenseListSelector()
    );

    const requestLotteryLicenseColumn = [
        {
            key: "documentCode",
            title: "លេខតាមដានឯកសារ",
            render: (item: any) => (
                <Link to={item.id} className="underline text-blue-500">
                    {item.documentCode}
                </Link>
            ),
        },
        {
            key: "code",
            title: "កូដ",
            dataIndex: "code",
        },
        {
            key: "casinoName",
            title: "ល្បែងផ្សងសំណាង",
            render: (item: any) => (
                <span>
                    {item.casinoName} - {item.casinoNameInKhmer}
                </span>
            ),
        },
    ];
    // cosntructors
    useEffect(() => {

        const model = {
            ...form.getFieldsValue(),
            formType: "L001",
        };
        dispatch(SetRequestLotteryLicenseFilterValueAction(model));
        dispatch(ListRequestLotteryLicenseRequestAction());
        return () => {
            dispatch(ListRequestLotteryLicenseSuccessAction([]));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    //events
    const onFinish = (values: any) => {
        const model = {
            ...values,
            formType: "L001",
        };
        dispatch(SetRequestLotteryLicenseFilterValueAction(model));
        dispatch(ListRequestLotteryLicenseRequestAction());
    };

    const onExport = () => {
        const model = {
            ...form.getFieldsValue(),
            formType: "L001",
        };
        dispatch(SetRequestLotteryLicenseFilterValueAction(model));
        dispatch(ExportRequestLotteryLicenseRequestAction());
    };

    const ActionButtons = () => (
        <Space>
            <Button type="primary" onClick={onExport}>
                ទាញយក
            </Button>

            <Link to={`create?entityType=${EntityType.LOTTERY}`}>
                <Button type="primary">
                    បញ្ចូលថ្មី
                </Button>
            </Link>

        </Space>
    );

    return (
        <>
            <FullLayout
                icon={<ArchiveIcon />}
                title="បញ្ជីពាក្យស្នើសុំចុះបញ្ជីអាជ្ញាបណ្ណផ្សងសំណាង"
                descriptions=""
                actions={<ActionButtons />}
            >
                <HelmetHeader meta="បញ្ជីពាក្យស្នើសុំចុះបញ្ជីអាជ្ញាបណ្ណផ្សងសំណាង" />

                <RequestLotteryLicenseFilterForm
                    form={form}
                    onFinish={onFinish}
                />
                <Table
                    rowKey="id"
                    columns={requestLotteryLicenseColumn}
                    dataSource={requestLotteryLicenses || []}
                    size="small"
                />
            </FullLayout>
        </>
    );
};
