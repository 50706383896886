import { useDispatch, useSelector } from "react-redux";
import { Link, useSearchParams } from "react-router-dom";
import { useInjectSaga } from "Utility/InjectSaga";
import { useInjectReducer } from "Utility/InjectReducer";
import Reducer from "Modules/Entity/Company/Reducer";
import Saga from "Modules/Entity/Company/Saga";
import { Form, Table, Space, Button } from "antd";
import { FullLayout } from "Components/Layouts/";
import { HelmetHeader } from "Components/Helmet-Header";
import { FilterForm } from "Modules/Entity/Company/List/Form/FilterForm";

import {
    SetFormValuesAction,
    EntityCompanyListRequestAction,
    EntityCompanyListSuccessAction,
} from "Modules/Entity/Company/Actions";
import { MakeSelectEntityCompanyListSelector } from "Modules/Entity/Company/Selectors";
import { useEffect } from "react";

const key = "entityCompany";
export const CompanyList = () => {
  useInjectReducer({ key, reducer: Reducer });
  useInjectSaga({ key, saga: Saga });
  //fields
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const [form] = Form.useForm();
  const entityType = searchParams.get("entityType");

  const companies = useSelector(MakeSelectEntityCompanyListSelector());
  const companyColums = [
    {
        title: "ឈ្មោះក្រុមហ៊ុន",
        key: "name",
        render: (item: any) => (
            <Link
                to={`/entity/company/${item.id}?entityType=${entityType}`}
                className="underline text-blue-500"
            >
                {item.name}
            </Link>
        ),
    },
    {
      title: "ឈ្មោះក្រុមហ៊ុនជាអក្សរឡាតាំង",
      key: "name",
      render: (item: any) => (
        <Link
            to={`/entity/company/${item.id}?entityType=${entityType}`}
            className="underline text-blue-500"
        >
            {item.latinName}
        </Link>
      ),
    },
    {
        title: "លេខចុះបញ្ជីពាណិជ្ជកម្ម",
        key: "registrationNumber",
        dataIndex: "registrationNumber",
    },

    {
        title: "ទីស្នាក់ការចុះបញ្ជីក្រុមហ៊ុន",
        key: "registrationOffice",
        dataIndex: "registrationOffice",
    },
    {
        title: "ទូរស័ព្ទច​ល័ត",
        key: "mobilePhoneNumber",
        dataIndex: "mobilePhoneNumber",
    },
  ];
  // constructors
  useEffect(() => {
      dispatch(SetFormValuesAction({ name: "" }));
      dispatch(EntityCompanyListRequestAction());

      return () => {
          dispatch(EntityCompanyListSuccessAction([]));
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  // method
  const ActionButton = () => {
      return (
          <Space>
              <Link
                  to="create">
                  <Button type="primary">
                      បញ្ចូលថ្មី
                  </Button>
              </Link>
          </Space>
      );
  };
  // events
  const onFormProviderSubmit = (name: string, { forms, values }: any) => {
      switch (name) {
          case "entityCompanyListForm": {
              onFilterFormSubmit(values);
              break;
          }
      }
  };

  const onFilterFormSubmit = (values: any) => {
      dispatch(SetFormValuesAction(form.getFieldsValue()));
      dispatch(EntityCompanyListRequestAction());
  };

  return (
      <FullLayout
          icon={null}
          title="បញ្ជីក្រុមហ៊ុន"
          descriptions=""
          actions={<ActionButton />}
      >
          <HelmetHeader meta="បញ្ជីក្រុមហ៊ុន" />
          <Form.Provider onFormFinish={onFormProviderSubmit}>
              <FilterForm form={form} />
              <Table
                  columns={companyColums}
                  dataSource={companies}
                  rowKey="id"
                  className="p-4"
                  size="small"
              />
          </Form.Provider>
      </FullLayout>
  );
};
