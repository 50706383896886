import { ShowAlert } from "Common/Saga";
import { POST } from "Utility/Constant";
import ApiEndpoint from "Utility/Endpoint";
import Request from "Utility/Http";

export const GetEquipmentBinding = (dispatch: any, filter: any) => {
    const requestUrl = `${ApiEndpoint.INSPECTION_CERTIFICATE_MASTER_PATH}/binding-reports`;
    const httpPayload = ApiEndpoint.makeApiPayload(
        requestUrl,
        POST,
        filter
    );
    try {
        const response:any = Request(httpPayload);
        return response;
    } catch (error: any) {
        dispatch(ShowAlert("error", error.data.message));
        return null;
    }
}
