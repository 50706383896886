import {
    SET_EQUIPMENT_FOR_OPERATOR_ID,
    GET_EQUIPMENT_FOR_OPERATOR_REQUEST,
    GET_EQUIPMENT_FOR_OPERATOR_SUCCESS,
    SET_CASINO_EQUIPMENT_MASTER_VALUE,
    CREATE_CASINO_EQUIPMENT_MASTER_REQUEST,
    SET_CASINO_EQUIPMENT_MASTER_ID,
    GET_REQUEST_CASINO_EQUIPMENT_MASTER_REQUEST,
    GET_REQUEST_CASINO_EQUIPMENT_MASTER_SUCCESS,

    GET_GAME_RULE_EQUIPMENT_MASTER_REQUEST,
    GET_GAME_RULE_EQUIPMENT_MASTER_SUCCESS,

    // equipment detail
    SET_EQUIPMENT_DETAIL_ID,
    SET_EQUIPMENT_DETAIL_VALUE,
    DELETE_EQUIPMENT_DETAIL_REQUEST,
    DELETE_EQUIPMENT_DETAIL_SUCCESS,
    CREATE_EQUIPMENT_DETAIL_REQUEST,
    SET_CASINO_EQUIPMENT_MASTER_REDIRECT
} from "Modules/Equipments/Casino/Constants";

export const SetEquipmentForOperatorIdAction = (id: string) => {
    return {
        type: SET_EQUIPMENT_FOR_OPERATOR_ID,
        id
    }
}

export const GetEquipmentForOperatorRequestAction = () => {
    return {
        type: GET_EQUIPMENT_FOR_OPERATOR_REQUEST
    }
}

export const GetEquipmentForOperatorSuccessAction = (operator: any) => {
    return {
        type: GET_EQUIPMENT_FOR_OPERATOR_SUCCESS,
        operator
    }
}

export const SetCasinoEquipmentMasterValueAction = (values: any) => {
    return {
        type: SET_CASINO_EQUIPMENT_MASTER_VALUE,
        values
    }
}

export const CreateCasinoEquipmentMasterRequestAction = () => {
    return {
        type: CREATE_CASINO_EQUIPMENT_MASTER_REQUEST
    }
}

export const SetCasinoEquipmentMasterIdAction = (id: string) => {
    return {
        type: SET_CASINO_EQUIPMENT_MASTER_ID,
        id
    }
}

export const SetCasnoEquipmentMasterRedirectAction = (redirect: string) => {
    return {
        type: SET_CASINO_EQUIPMENT_MASTER_REDIRECT,
        redirect
    }
}

export const GetRequestCasinoEquipmentMasterRequestAction = () => {
    return {
        type: GET_REQUEST_CASINO_EQUIPMENT_MASTER_REQUEST
    }
}

export const GetRequestCasinoEquipmentMasterSuccessAction = (casinoEquipments: any) => {
    return {
        type: GET_REQUEST_CASINO_EQUIPMENT_MASTER_SUCCESS,
        casinoEquipments
    }
}

// equipment detail
export const SetEquipmentDetailIdAction = (id: string) => {
    return {
        type: SET_EQUIPMENT_DETAIL_ID,
        id,
    }
}

export const DeleteEquipmentDetailRequestAction = () => {
    return {
        type: DELETE_EQUIPMENT_DETAIL_REQUEST,
    }
}

export const DeleteEquipmentDetailSuccessAction = (equipmentDetail: any) => {
    return {
        DELETE_EQUIPMENT_DETAIL_SUCCESS,
        equipmentDetail
    }
}

export const GetGameRuleEquipmentMasterReqeustAction = () => {
    return {
        type: GET_GAME_RULE_EQUIPMENT_MASTER_REQUEST
    }
}

export const GetGameRuleEquipmentMasterSuccessAction = (casinoEquipments: any) => {
    return {
        type: GET_GAME_RULE_EQUIPMENT_MASTER_SUCCESS,
        casinoEquipments
    }
}

export const SetEquipmentDetailValueAction = (values: any) => {
    return {
        type: SET_EQUIPMENT_DETAIL_VALUE,
        values
    }
}

export const CreateEquipmentDetailRequestAction = () => {
    return {
        type: CREATE_EQUIPMENT_DETAIL_REQUEST,
    }
}