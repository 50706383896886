import React from "react";
import { Form, Row, Col, Input, Modal } from "antd";
import { ModalFormType } from "Common/Constants/ModalFormType";
import { useResetFormOnCloseModal } from "Utility/ResetFormOnCloseModal";
export const CapitalShareModal: React.FC<ModalFormType> = ({
    visible,
    onCancel,
}) => {
    const [form] = Form.useForm();
    useResetFormOnCloseModal({
        form,
        visible,
    });

    const onSubmit = () => {
        form.submit();
    };
    return (
        <Modal
            title="ប្រភេទភាគហ៊ុន"
            open={visible}
            onOk={onSubmit}
            onCancel={onCancel}
            okText="យល់ព្រម"
            cancelText="បោះបង់"
            okButtonProps={{ type: "default" }}
        >
            <Form form={form} layout="vertical" name="capitalShareForm">
                <Row>
                    <Col span={8}>ចំនួន/Number</Col>
                    <Col span={16}>
                        <Form.Item name="value">
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};
