export interface RequestSectionType {
    title: string;
    subTitle: string;
    children: JSX.Element | JSX.Element[];
}
export const RequestSection = ({
    title,
    subTitle,
    children,
}: RequestSectionType) => {
    return (
        <div className="border-t border-gray-200">
            <div className="px-4 py-5 sm:px-6">
                <h2
                    id="applicant-information-title"
                    className="text-lg leading-6 font-medium text-gray-900"
                >
                    {title}
                </h2>
                <p className="mt-1 max-w-2xl text-sm text-gray-500">
                    {subTitle}
                </p>
            </div>
            <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                {children}
            </div>
        </div>
    );
};
