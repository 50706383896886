export const EntityCasinoListConstant = {
    SET_FORM_VALUES: "SET_FORM_VALUES",
    ENTITY_CASINO_LIST_REQUEST: "ENTITY_CASINO_LIST_REQUEST",
    ENTITY_CASINO_LIST_ERROR: "ENTITY_CASINO_LIST_ERROR",
    ENTITY_CASINO_LIST_SUCCESS: "ENTITY_CASINO_LIST_SUCCESS",
    QUERY_CASINO_WITH_COMPANY_REQUEST: "QUERY_CASINO_WITH_COMPANY_REQUEST",
    QUERY_CASINO_WITH_COMPANY_SUCCESS: "QUERY_CASINO_WITH_COMPANY_SUCCESS",
    QUERY_CASINO_WITH_COMPANY_ERROR: "QUERY_CASINO_WITH_COMPANY_ERROR",
    SELECT_CASINO_REQUEST: "SELECT_CASINO_REQUEST",
    SELECT_CASINO_SUCCESS: "SELECT_CASINO_SUCCESS",
    CREATE_CASINO_REQUEST: "CREATE_CASINO_REQUEST",
};

export const GET_ENTITY_CASINO_BY_ID_REQUEST = "GET_ENTITY_CASINO_BY_ID_REQUEST";
export const GET_ENTITY_CASINO_BY_ID_SUCCESS = "GET_ENTITY_CASINO_BY_ID_SUCCESS";
export const GET_CASINO_STATUS_COUNT_REQUEST = "GET_CASINO_STATUS_COUNT_REQUEST";
export const GET_OEPRATOR_STATUS_COUNT_REQUEST = "GET_OEPRATOR_STATUS_COUNT_REQUEST";
export const GET_CASINO_STATUS_COUNT_SUCCESS = "GET_CASINO_STATUS_COUNT_SUCCESS";
export const GET_LOTTERY_STATUS_COUNT_REQUEST = "GET_LOTTERY_STATUS_COUNT_REQUEST";
export const GET_LOTTERY_STATUS_COUNT_SUCCESS = "GET_LOTTERY_STATUS_COUNT_SUCCESS";

export const CREATE_CASINO_MODAL_REQUEST = "CREATE_CASINO_MODAL_REQUEST";
export const CREATE_CASINO_MODAL_SUCCESS = "CREATE_CASINO_MODAL_SUCCESS";
export const GET_CASINO_MODAL_DETAIL = "GET_CASINO_MODAL_DETAIL";
export const SET_CASINO_ID = "SET_CASINO_ID";

export const EDIT_ENTITY_CASINO_REQUEST = "EDIT_ENTITY_CASINO_REQUEST";
export const EDIT_ENTITY_CASINO_SUCCESS = "EDIT_ENTITY_CASINO_SUCCESS";

export const EXPORT_ENTITY_CASINO_REQUEST = "EXPORT_ENTITY_CASINO_REQUEST";
export const REPORT_OPERATOR_BY_AREACODE_REQUEST = "REPORT_OPERATOR_BY_AREACODE_REQUEST";
export const REPORT_OPERATOR_BY_AREACODE_SUCCESS = "REPORT_OPERATOR_BY_AREACODE_SUCCESS";

export const EmptyValue = {
    code: "",
    name: "",
    hasLicense: 0,
    casinoStatus: null,
    area: null,
};
