import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";
import { v4 as uuid } from "uuid";
import dayjs from "dayjs";

import { HelmetHeader } from "Components/Helmet-Header";
import { ArchiveIcon } from "@heroicons/react/outline";
import { Form, Modal, Button } from "antd";
import { FullLayout } from "Components/Layouts/";
import { useInjectReducer } from "Utility/InjectReducer";
import { useInjectSaga } from "Utility/InjectSaga";

import { RequestGameEquipmentCreateForm } from "Modules/Certificate/Casino/ApplicationForm/GameEquipment/Forms/RequestGameEquipmentCreateForm";

import EquipmentCasinoReducer from "Modules/Equipments/Casino/Reducer";
import EquipmentCasinoSaga from "Modules/Equipments/Casino/Saga";
import Reducer from "Modules/Certificate/Casino/ApplicationForm/GameEquipment/Reducer";
import Saga from "Modules/Certificate/Casino/ApplicationForm/GameEquipment/Saga";
import {
    SetRequestGameEquipmentValueAction,
    SetRequestGameEquipmentIdAction,
    GetRequestGameEquipmentRequestAction,
    EditRequestGameEquipmentRequestAction,
    GetRequestGameEquipmentSuccessAction,
} from "Modules/Certificate/Casino/ApplicationForm/GameEquipment/Actions";

import {
    CreateCasinoEquipmentMasterRequestAction,
    CreateEquipmentDetailRequestAction,
    DeleteEquipmentDetailRequestAction,
    GetGameRuleEquipmentMasterReqeustAction,
    GetRequestCasinoEquipmentMasterRequestAction,
    GetRequestCasinoEquipmentMasterSuccessAction,
    SetCasinoEquipmentMasterIdAction,
    SetCasinoEquipmentMasterValueAction,
    SetEquipmentDetailIdAction,
    SetEquipmentDetailValueAction
} from "Modules/Equipments/Casino/Actions";
import { MakeRequestGameEquipmentSelector } from "Modules/Certificate/Casino/ApplicationForm/GameEquipment/Selectors";
import { DATE_FORMAT } from "Modules/Application/Constants";
import { CasinoEquipmentModalForm } from "Modules/Equipments/Casino/Forms/CasinoEquipmentModalForm";
import { AsyncEndAction, AsyncStartAction } from "Modules/Application/Actions";
import { GetIdentificationTypeOptionService, GetOperatorOptionService } from "Modules/Entity/Operator/Service";
import { SelectableIdentificationTypeSuccessAction } from "Modules/License/Casino/Actions";
import { GetGameRuleListService } from "Modules/Settings/GameRule/Service";
import { QueryGameRuleSuccessAction, SelectGameRuleSuccessAction } from "Modules/Settings/GameRule/Actions";
import { SelectCasinoSuccessAction } from "Modules/Entity/Operator/Actions";

const key = "certificateGameEquipment";
const equpmentKey = "equipmentCasino";;
export const RequestGameEquipmentDetail = () => {
    useInjectReducer({ key, reducer: Reducer });
    useInjectSaga({ key, saga: Saga });
    useInjectReducer({ key: equpmentKey, reducer: EquipmentCasinoReducer });
    useInjectSaga({ key: equpmentKey, saga: EquipmentCasinoSaga });

    // fields
    const [form] = Form.useForm();
    const [equipmentForm] = Form.useForm();
    const dispatch = useDispatch();
    const { id } = useParams();
    const [searchParams] = useSearchParams();
    const entityType = searchParams.get("entityType");
    const requestGameEquipment = useSelector(
        MakeRequestGameEquipmentSelector()
    );
    const [equipmentVisible, setEquipmentVisible] = useState<boolean>(false);
    // constructor
    useEffect(() => {
        const entityTypeId = entityType === "LOTTERY" ? 2 : 1;
        const model = {
            entityType: entityTypeId,
            search: ""
        }
        dispatch(AsyncStartAction());
        GetIdentificationTypeOptionService(dispatch).then((nationalityRs: any) => {
            if(nationalityRs) {
                dispatch(SelectableIdentificationTypeSuccessAction(nationalityRs));
            }
            GetGameRuleListService(model, dispatch).then((gameTypeOption: any) => {
                if(gameTypeOption) {
                    dispatch(QueryGameRuleSuccessAction(gameTypeOption));
                }
                GetOperatorOptionService(dispatch).then((casinoOptionsRs: any) => {
                    if(casinoOptionsRs) {
                        dispatch(SelectCasinoSuccessAction(casinoOptionsRs));
                    }
                    if (id) {
                        dispatch(SetRequestGameEquipmentIdAction(id));
                        dispatch(GetRequestGameEquipmentRequestAction());
                        
                        // dispatch(SetCasinoEquipmentMasterIdAction(id));
                        // dispatch(GetRequestCasinoEquipmentMasterRequestAction());
                    }
                    dispatch(AsyncEndAction());
                });
            });
        });
        

        return () => {
            
        };
        

        return () => {
            dispatch(GetRequestGameEquipmentSuccessAction(null));
            dispatch(SetCasinoEquipmentMasterIdAction(null));
            dispatch(GetRequestCasinoEquipmentMasterSuccessAction(null));
            dispatch(SelectableIdentificationTypeSuccessAction([]));
            dispatch(SelectCasinoSuccessAction([]));
            dispatch(SelectGameRuleSuccessAction([]));
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    useEffect(() => {
        if (requestGameEquipment) {
            const formModel = {
                id: uuid(),
                ...requestGameEquipment,
            };
            formModel.gameEquipments = requestGameEquipment.formData.gameRules;
            formModel.receivedDate = dayjs(
                requestGameEquipment.receivedDate,
                DATE_FORMAT
            );
            formModel.formData.applicant.dateOfBirth = dayjs(
                requestGameEquipment.formData.applicant.dateOfBirth,
                DATE_FORMAT
            );
            formModel.formData.applicant.identityIssuedDate = dayjs(
                requestGameEquipment.formData.applicant.identityIssuedDate,
                DATE_FORMAT
            );
            formModel.formData.applicant.expiredDate = dayjs(
                requestGameEquipment.formData.applicant.expiredDate,
                DATE_FORMAT
            );
            formModel.gameEquipments = requestGameEquipment.formData.gameEquipments;

            form.setFieldsValue(formModel);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [requestGameEquipment]);

    const submitCasinoRuleLicenseForm = (name: string, values: any) => {
        // await form.validateFields();

        let licenseModel = {
            ...form.getFieldsValue(),
            id: form.getFieldValue("id"),
            entityName: "Casino Game Equipment",
            formType: "C004",
            CompanyNameInKhmer: values.formData.company.name,
            CasinoNameInKhmer: values.formData.casino.name,
            casinoId: values.casinoId,
            code: values.code,
            applicantName: values.formData.applicant.fullname,
            applicantNationality: values.formData.applicant.nationality,
            applicantContactNumber: values.formData.applicant.mobilePhoneNumber,
            representativeName: values.formData.applicant.fullname,
            representativeNationality: values.formData.applicant.nationality,
            representativeContactNumber:
                values.formData.applicant.mobilePhoneNumber,
            formData: {
                ...values.formData,
                gameEquipments: form.getFieldValue("gameEquipments"),
            },
        };

        dispatch(SetRequestGameEquipmentValueAction(licenseModel));
        dispatch(EditRequestGameEquipmentRequestAction());
    };

    const onGameEquipmentModalFormSubmit = (
        requestCasinoGameEquipmentLicenseForm: any,
        values: any
    ) => {
        const gameEquipments = form.getFieldValue("gameEquipments") || [];
        form.setFieldValue("gameEquipments", [...gameEquipments, { id: uuid(), ...values }]);
    };

    const onEquipmentList = (item: any) => {
        equipmentForm.resetFields();
        const model = {
            requestGameRuleId: item.gameRuleId.value,
            requestLicenseId: id,
            casinoId: form.getFieldValue("casinoId"),
            gameRule: item.gameRuleId.label,

        };
        dispatch(SetCasinoEquipmentMasterIdAction(model.requestGameRuleId));
        dispatch(GetGameRuleEquipmentMasterReqeustAction());
        equipmentForm.setFieldsValue(model);
        setEquipmentVisible(true);
    }

    const ActionButton = () => {
        return (
            <Button
                onClick={() => {
                    form.submit();
                }}
                type="primary"
            >
                រក្សាទុក
            </Button>
        );
    };

    // events
    const onFormProviderSubmit = (name: string, { values, forms }: any) => {

        switch (name) {
            case "requestCasinoGameEquipmentLicenseForm":
                submitCasinoRuleLicenseForm(name, values);
                break;
            case "gameEquipmentModalForm":
                const { requestCasinoGameEquipmentLicenseForm } = forms;
                onGameEquipmentModalFormSubmit(
                    requestCasinoGameEquipmentLicenseForm,
                    values
                );
                break;
            case "tableEquipmentForm":

                onCreateTerminal(forms, values);
                break;
            case "machineEquipmentForm":
                onCreateTerminal(forms, values);
                break;
            case "createCasinoEquipmentMasterForm":
                onSubmitEquipmentMaster(forms, values);
                break;
            default: {
                break;
            }
        }
    };

    const onSubmitEquipmentMaster = (forms: any, values: any) => {

        const model = {
            ...values,
        }

        model.applicationDate = values.applicationDate;
        model.registeredDate = (values.registeredDate);
        model.casinoEquipmentDetails = equipmentForm.getFieldValue("equipments") || [];
        const equipmentId = equipmentForm.getFieldValue("id");
        if (!equipmentId) {
            dispatch(SetCasinoEquipmentMasterValueAction(model));
            dispatch(CreateCasinoEquipmentMasterRequestAction());
        }

        equipmentForm.resetFields();
        setEquipmentVisible(false);
    }

    const onCreateTerminal = (forms: any, values: any) => {
        const equipmentFieldValue = equipmentForm.getFieldsValue();
        const { createCasinoEquipmentMasterForm } = forms;
        const equipments = createCasinoEquipmentMasterForm.getFieldValue("equipments") || [];
        const model = {
            id: uuid(),
            gameRule: "",
            gameType: "",
            ...values,
        };
        model.zoneName = "Test";
        model.gameRule = values.gameRule.label;
        model.gameRuleId = values.gameRule.value;
        model.equipmentType = values.equipmentType.label;
        model.equipmentTypeId = values.equipmentType.value;
        if (equipmentFieldValue.id) {
            model.casinoEquipmentMasterId = equipmentFieldValue.id;
            dispatch(SetEquipmentDetailValueAction(model));
            dispatch(CreateEquipmentDetailRequestAction());
        }
        createCasinoEquipmentMasterForm.setFieldValue("equipments", [model, ...equipments]);

    }

    const onDeleteEquipmentApi = (item: any) => {
        dispatch(SetEquipmentDetailIdAction(item.id));
        dispatch(DeleteEquipmentDetailRequestAction());
    }

    return (
        <FullLayout
            icon={<ArchiveIcon />}
            title={`ស្នើសុំចុះបញ្ជី ឧបករណ៍/កម្មវិធីល្បែង (C004) - ${requestGameEquipment?.casinoNameInKhmer} (${requestGameEquipment?.casinoNameLatin})`}
            descriptions="ត្រលប់ទៅបញ្ជី"
            redirectUrl="/certificate/casino/request-game-rule/request"
            actions={<ActionButton />}
        >
            <HelmetHeader meta="ស្នើសុំចុះបញ្ជី ឧបករណ៍/កម្មវិធីល្បែង" />
            <Form.Provider onFormFinish={onFormProviderSubmit}>
                <RequestGameEquipmentCreateForm form={form} onDeleteEquipment={null} onEquipmentCreate={onEquipmentList} />
                <Modal
                    width={"90%"}
                    open={equipmentVisible}
                    onOk={() => equipmentForm.submit()}
                    onCancel={() => setEquipmentVisible(false)}>
                    <CasinoEquipmentModalForm equipmentForm={equipmentForm} onDbDelete={onDeleteEquipmentApi} />
                </Modal>
            </Form.Provider>
        </FullLayout>
    );
};
