/*
 *
 * AuthProvider reducer
 *
 */
import produce, { setAutoFreeze } from "immer";

import {
    GET_PROFILE_ERROR,
    GET_PROFILE_SUCCESS,
    IS_LOGGED_ERROR,
    IS_LOGGED_SUCCESS,
    LOGGED_IN,
    LOGOUT,
    LOGOUT_ERROR,
    LOGOUT_SUCCESS,
    DASHBOARD_REQUEST_REDIRECT_SUCCESS,
    ASYNC_START,
    ASYNC_END,
    SET_AUTHENTICATION_APP_REQUEST_ID,
    AUTHENTICATION_APP_SUCCESS,
    SET_PASSWORD_VALUE,
    SET_PERMISSION_VALUE,
    SET_UPLOAD_STATUS,
    SET_FILE_VALUE,
    UPLOAD_FILE_SUCCESS,
    VERIFY_CAPTCHA_TOKEN_SUCCESS,
    SET_PRINT_PARAMETERS,

} from "Modules/Application/Constants";

const device = /(iPhone|iPad|iPod|iOS|Android)/i.test(navigator.userAgent)
    ? "MOBILE"
    : "DESKTOP";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const collapsed = device !== "DESKTOP";

export const initialState: any = {
    device: "",
    collapsed: false,
    isLoading: false,
    otpVerified: true,
    otp: "",
    otpError: false,
    isLogged: null,
    errors: [],
    user: {},
    requestId: null,
    authenticationRequestId: null,
    authenticationStatus: 0,
    password: null,
    permissions: [],
    file: null,
    fileModel: null,
    uploadStatus: 0,
    captchaToken: null,
    printParams: null,
};

setAutoFreeze(false);
/* eslint-disable default-case, no-param-reassign */
const ApplicationReducer = produce((draft, action) => {
    switch (action.type) {
        case LOGGED_IN:
        case IS_LOGGED_SUCCESS:
            draft.isLogged = true;
            break;
        case IS_LOGGED_ERROR:
            draft.isLogged = false;
            draft.otpVerified = true;
            break;
        case GET_PROFILE_SUCCESS:
            draft.user = action.user;
            break;
        case GET_PROFILE_ERROR:
            draft.error = action.error;
            break;
        case ASYNC_START:
            draft.isLoading = true;
            break;
        case AUTHENTICATION_APP_SUCCESS:
            draft.authenticationStatus = action.status;
            break;
        case ASYNC_END:
            draft.isLoading = false;
            break;
        case LOGOUT:
            break;
        case LOGOUT_SUCCESS:
            draft.isLogged = false;
            draft.user = {};
            draft.requestId = null;
            break;
        case DASHBOARD_REQUEST_REDIRECT_SUCCESS:
            draft.requestId = action.requestId;
            break;
        case LOGOUT_ERROR:
            draft.errors = [];
            draft.otp = "";
            draft.error = "";
            draft.user = {};
            draft.isLogged = false;
            draft.otpError = false;
            break;
        case SET_AUTHENTICATION_APP_REQUEST_ID:
            draft.authenticationRequestId = action.id;
            break;
        case SET_PASSWORD_VALUE:
            draft.password = action.values;
            break;
        case SET_PERMISSION_VALUE:
            draft.permissions = action.values;
            break;
        case SET_FILE_VALUE:
            draft.file = action.values;
            break;
        case SET_UPLOAD_STATUS:
            draft.uploadStatus = action.status;
            break;
        case UPLOAD_FILE_SUCCESS:
            draft.fileModel = action.fileModel;
            break;
        case VERIFY_CAPTCHA_TOKEN_SUCCESS:
            draft.captchaToken = action.token;
            break;
        case SET_PRINT_PARAMETERS:
            draft.printParams = action.params;
            break;
        default:
    }
}, initialState);

export default ApplicationReducer;
