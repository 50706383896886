import React from "react";
import { Form, Row, Input, Button } from "antd";

export const GameTypeFilterForm = ({ form }: any) => {
    return (
        <Form
            name="gameTypeForm"
            layout="vertical"
            className="p-5 w-full"
            initialValues={{ search: "" }}
        >
            <Row className="w-full grid grid-cols-4 items-end gap-2" gutter={4}>
                <Form.Item name="search" label="ប្រភេទល្បែង">
                    <Input />
                </Form.Item>
                <Form.Item>
                    <Button htmlType="submit" type="default">
                        ស្វែងរក
                    </Button>
                </Form.Item>

            </Row>
        </Form>
    );
};
