import { EXPORT_REQUEST_CASINO_LICENSE_REQUEST } from "./Constants";
import { call, put, all, select, takeLatest } from "redux-saga/effects";
import ApiEndpoint from "Utility/Endpoint";
import Request from "Utility/Http";
import { v4 as uuid } from "uuid";
import { MakeFormValuesSelector } from "Components/SignIn/Selectors";
import {
    CasinoConstant,
    CREATE_REQUEST_CASINO_LICENSE_REQUEST,
    EDIT_REQUEST_CASINO_LICENSE_REQUEST,
    GET_REQUEST_CASINO_LICENSE_REQUEST,
    LIST_REQUEST_CASINO_LICENSE_REQUEST,
} from "Modules/License/Casino/Constants";
import { POST, PUT, GET } from "Utility/Constant";
import { AsyncStartAction, AsyncEndAction } from "Modules/Application/Actions";
import { ShowAlert, ShowMessage } from "Common/Saga";

import {
    SelectableCasinoTypeSuccessAction,
    SelectableApplicationTypeSuccessAction,
    SelectableIdentificationTypeSuccessAction,
    SelectableNationalitySuccessAction,
    ListRequestCasinoLicenseSuccessAction,
    GetRequestCasinoLicenseSuccessAction,
} from "Modules/License/Casino/Actions";

import {
    MakeRequestCasinoLicenseFilterValueSelector,
    MakeRequestCasinoLicenseIdSelector,
    MakeRequestCasinoLicenseValueSelector,
} from "Modules/License/Casino/Selectors";
import { ClearSnackMessageAction } from "Components/SnackMessage/Actions";

export function* getSelectableNationality(): any {
    yield put(AsyncStartAction());
    const requestUrl = ApiEndpoint.SELECTABLE_NATIONALITY_PATH;
    const httpPayload = ApiEndpoint.makeApiPayload(requestUrl, POST);
    try {
        const response = yield call(Request, httpPayload);

        yield put(SelectableNationalitySuccessAction(response));
        yield put(AsyncEndAction());
        yield put(ClearSnackMessageAction());
    } catch (error: any) {
        yield put(AsyncEndAction());
        if (error.data && error.data.statusCode === 422) {
            // return yield put(EnterValidationErrorAction(error.data.message));
        }
        return yield ShowAlert("error", error);
    }
}
export function* getSelectableCasinoType(): any {
    yield put(AsyncStartAction());
    const requestUrl = ApiEndpoint.SELECTABLE_CASINO_TYPE_PATH;
    const httpPayload = ApiEndpoint.makeApiPayload(requestUrl, POST);
    try {
        const response = yield call(Request, httpPayload);

        yield put(AsyncEndAction());
        yield put(ClearSnackMessageAction());
        return yield put(SelectableCasinoTypeSuccessAction(response));
    } catch (error: any) {
        yield put(AsyncEndAction());
        if (error.data && error.data.statusCode === 422) {
            // return yield put(EnterValidationErrorAction(error.data.message));
        }
        return yield ShowAlert("error", error);
    }
}
export function* getSelectableApplicationType(): any {
    yield put(AsyncStartAction());
    const formValues = yield select(MakeFormValuesSelector());
    const requestUrl = ApiEndpoint.SELECTABLE_APPLICATION_TYPE_PATH;
    const httpPayload = ApiEndpoint.makeApiPayload(
        requestUrl,
        POST,
        formValues
    );
    try {
        const response = yield call(Request, httpPayload);

        yield put(SelectableApplicationTypeSuccessAction(response));

        yield put(AsyncEndAction());
        yield put(ClearSnackMessageAction());
    } catch (error: any) {
        yield put(AsyncEndAction());
        if (error.data && error.data.statusCode === 422) {
            // return yield put(EnterValidationErrorAction(error.data.message));
        }
        return yield ShowAlert("error", error);
    }
}
export function* getSelectableIdentificationType(): any {
    yield put(AsyncStartAction());
    const formValues = yield select(MakeFormValuesSelector());
    const requestUrl = ApiEndpoint.SELECTABLE_IDENTIFICATION_TYPE_PATH;
    const httpPayload = ApiEndpoint.makeApiPayload(
        requestUrl,
        POST,
        formValues
    );
    try {
        const response = yield call(Request, httpPayload);
        yield put(AsyncEndAction());
        yield put(ClearSnackMessageAction());
        return yield yield put(
            SelectableIdentificationTypeSuccessAction(response)
        );
    } catch (error: any) {
        yield put(AsyncEndAction());
        if (error.data && error.data.statusCode === 422) {
            return yield ShowAlert("error", error.data);
        }
        return yield ShowAlert("error", error);
    }
}
export function* GetSelectableCasinoOption(): any {
    // yield getSelectableIdentificationType();
    // yield getSelectableApplicationType();
    // yield getSelectableCasinoType();
    // yield getSelectableNationality();
}

function* ListRequestCasinoLicense() {
    yield put(AsyncStartAction());
    const model = yield select(MakeRequestCasinoLicenseFilterValueSelector());
    const requestUrl = `${ApiEndpoint.REQUEST_LICENSE}/lists`;
    const httpPayload = ApiEndpoint.makeApiPayload(
        requestUrl,
        POST,
        model || {}
    );
    try {
        const response = yield call(Request, httpPayload);
        yield put(ListRequestCasinoLicenseSuccessAction(response));
        yield put(AsyncEndAction());
    } catch (error: any) {
        yield put(AsyncEndAction());
        if (error.data && error.data.statusCode === 422) {
        }
        return yield ShowAlert("error", error.data.message);
    }
}

function* CreateRequestCasinoLicense() {
    yield put(AsyncStartAction());
    const model = yield select(MakeRequestCasinoLicenseValueSelector());
    const requestUrl = `${ApiEndpoint.REQUEST_LICENSE}`;
    const httpPayload = ApiEndpoint.makeApiPayload(requestUrl, POST, model);
    try {
        const response = yield call(Request, httpPayload);

        if (response && !response.status) {
            yield ShowMessage({
                type: "success",
                message: "ទិន្នន័យបានក្សារទុក",
                content: "",
                id: uuid(),
            });
        }
    } catch (error: any) {
        if (error.data && error.data.statusCode === 422) {
        }
        return yield ShowAlert("error", "Error");
    } finally {
        yield put(AsyncEndAction());
    }
}

function* GetRequestCasinoLicense() {
    const model = yield select(MakeRequestCasinoLicenseIdSelector());
    if (!model) {
        return yield ShowMessage({
            type: "error",
            message: "រកមិនឃើញទិន្នន័យ",
            content: "",
            id: uuid(),
        });
    }
    yield put(AsyncStartAction());
    const requestUrl = `${ApiEndpoint.REQUEST_LICENSE}/${model}`;
    const httpPayload = ApiEndpoint.makeApiPayload(requestUrl, GET, model);
    try {
        const response = yield call(Request, httpPayload);

        if (response && !response.status) {
            yield put(GetRequestCasinoLicenseSuccessAction(response));
        }
    } catch (error: any) {
        if (error.data && error.data.statusCode === 422) {
        }
        return yield ShowAlert("error", "Error");
    } finally {
        yield put(AsyncEndAction());
    }
}

function* EditRequestCasinoLicense() {
    yield put(AsyncStartAction());
    const model = yield select(MakeRequestCasinoLicenseValueSelector());
    const requestUrl = `${ApiEndpoint.REQUEST_LICENSE}`;
    const httpPayload = ApiEndpoint.makeApiPayload(requestUrl, PUT, model);
    try {
        const response = yield call(Request, httpPayload);

        if (response && !response.status) {
            yield ShowMessage({
                type: "success",
                message: "ទិន្នន័យបានក្សារទុក",
                content: "",
                id: uuid(),
            });
        }
    } catch (error: any) {
        if (error.data && error.data.statusCode === 422) {
        }
        return yield ShowAlert("error", "Error");
    } finally {
        yield put(AsyncEndAction());
    }
}

export function* ExportRequestCasinoLicense() {
    yield put(AsyncStartAction());
    const requestUrl = `${ApiEndpoint.REQUEST_LICENSE}/excel`;
    const formValues = yield select(
        MakeRequestCasinoLicenseFilterValueSelector()
    );
    const httpPayload = ApiEndpoint.makeApiPayload(
        requestUrl,
        POST,
        formValues,
        null,
        false,
        true
    );
    try {
        httpPayload.responseType = "blob";
        httpPayload.isRaw = true;
        const response = yield call(Request, httpPayload);

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "របាយការណ៍.xlsx");
        document.body.appendChild(link);
        link.click();
    } catch (error: any) {
        if (error.data && error.data.statusCode === 422) {
        }
        return yield ShowAlert("error", error);
    } finally {
        yield put(AsyncEndAction());
        yield put(ClearSnackMessageAction());
    }
}

export default function* requestCasinoLicenseSaga() {
    yield all([
        yield takeLatest(
            CasinoConstant.SELECTABLE_CASINO_OPTION_REQUEST,
            GetSelectableCasinoOption
        ),
        yield takeLatest(
            CasinoConstant.SELECTABLE_CASINO_TYPE,
            getSelectableCasinoType
        ),
        yield takeLatest(
            CasinoConstant.SELECTABLE_APPLICATION_TYPE,
            getSelectableApplicationType
        ),
        yield takeLatest(
            CasinoConstant.SELECTABLE_IDENTIFICATION_TYPE,
            getSelectableIdentificationType
        ),
        yield takeLatest(
            CasinoConstant.SELECTABLE_NATIONALITY,
            getSelectableNationality
        ),
        yield takeLatest(
            LIST_REQUEST_CASINO_LICENSE_REQUEST,
            ListRequestCasinoLicense
        ),
        yield takeLatest(
            CREATE_REQUEST_CASINO_LICENSE_REQUEST,
            CreateRequestCasinoLicense
        ),
        yield takeLatest(
            EDIT_REQUEST_CASINO_LICENSE_REQUEST,
            EditRequestCasinoLicense
        ),
        yield takeLatest(
            GET_REQUEST_CASINO_LICENSE_REQUEST,
            GetRequestCasinoLicense
        ),
        yield takeLatest(
            EXPORT_REQUEST_CASINO_LICENSE_REQUEST,
            ExportRequestCasinoLicense
        ),
    ]);
}
