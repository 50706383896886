import {
    CasinoConstant,

    // Application Form Request License
    LIST_REQUEST_CASINO_LICENSE_REQUEST,
    LIST_REQUEST_CASINO_LICENSE_SUCCESS,
    SET_REQUEST_CASINO_LICENSE_FILTER_VALUE,
    SET_REQUEST_CASINO_LICENSE_VALUE,
    CREATE_REQUEST_CASINO_LICENSE_REQUEST,
    EDIT_REQUEST_CASINO_LICENSE_REQUEST,
    SET_REQUEST_CASINO_LICENSE_ID,
    GET_REQUEST_CASINO_LICENSE_REQUEST,
    GET_REQUEST_CASINO_LICENSE_SUCCESS,
    EXPORT_REQUEST_CASINO_LICENSE_REQUEST,
} from "Modules/License/Casino/Constants";

export function SelectableCasinoTypeAction() {
    return {
        type: CasinoConstant.SELECTABLE_CASINO_TYPE,
    };
}
export function SelectableCasinoTypeSuccessAction(casinoTypes: any) {
    return {
        type: CasinoConstant.SELECTABLE_CASINO_TYPE_SUCCESS,
        casinoTypes,
    };
}

export const SelectableCasinoOptionAction = () => {
    return {
        type: CasinoConstant.SELECTABLE_CASINO_OPTION_REQUEST,
    };
};

export function SelectableApplicationTypeAction() {
    return {
        type: CasinoConstant.SELECTABLE_APPLICATION_TYPE,
    };
}

export function SelectableApplicationTypeSuccessAction(applicationTypes: any) {
    return {
        type: CasinoConstant.SELECTABLE_APPLICATION_TYPE_SUCCESS,
        applicationTypes,
    };
}

export function SelectableIdentificationTypeAction() {
    return {
        type: CasinoConstant.SELECTABLE_IDENTIFICATION_TYPE,
    };
}

export function SelectableIdentificationTypeSuccessAction(
    identificationTypes: any
) {
    return {
        type: CasinoConstant.SELECTABLE_IDENTIFICATION_TYPE_SUCCESS,
        identificationTypes,
    };
}

export function SelectableNationalityAction() {
    return {
        type: CasinoConstant.SELECTABLE_NATIONALITY,
    };
}

export function SelectableNationalitySuccessAction(nationality: any) {
    return {
        type: CasinoConstant.SELECTABLE_NATIONALITY_SUCCESS,
        nationality,
    };
}

// LISTS
export const SetRequestCasinoLicenseFilterValueAction = (filterValues: any) => {
    return { type: SET_REQUEST_CASINO_LICENSE_FILTER_VALUE, filterValues };
};

export const ListRequestCasinoLicenseRequestAction = () => {
    return {
        type: LIST_REQUEST_CASINO_LICENSE_REQUEST,
    };
};

export const ListRequestCasinoLicenseSuccessAction = (
    requestCasinoLicenses: any
) => {
    return { type: LIST_REQUEST_CASINO_LICENSE_SUCCESS, requestCasinoLicenses };
};

// CREATE
export const SetRequestCasinoLicenseValueAction = (values: any) => {
    return { type: SET_REQUEST_CASINO_LICENSE_VALUE, values };
};

export const CreateRequestCasinoLicenseRequestAction = () => {
    return {
        type: CREATE_REQUEST_CASINO_LICENSE_REQUEST,
    };
};

export const EditRequestCasinoLicenseRequestAction = () => {
    return {
        type: EDIT_REQUEST_CASINO_LICENSE_REQUEST,
    };
};

export const SetRequestCasinoLicenseIdAction = (id: string) => {
    return {
        type: SET_REQUEST_CASINO_LICENSE_ID,
        id,
    };
};

export const GetRequestCasinoLicenseRequestAction = () => {
    return {
        type: GET_REQUEST_CASINO_LICENSE_REQUEST,
    };
};
export const GetRequestCasinoLicenseSuccessAction = (
    requestCasinoLicense: any
) => {
    return {
        type: GET_REQUEST_CASINO_LICENSE_SUCCESS,
        requestCasinoLicense,
    };
};

export const ExportRequestCasinoLicenseRequestAction = () => {
    return {
        type: EXPORT_REQUEST_CASINO_LICENSE_REQUEST,
    };
};
