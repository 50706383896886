import { initialState } from "Modules/Entity/Operator/Reducer";
import { createSelector } from "reselect";

const selectEntityCasinoListDomain = (state: any) =>
    state.entityCasino || initialState;

const MakeselectFormValueSelector = () => createSelector(selectEntityCasinoListDomain, (substate) => substate.formValues);
const MakeSelectEntityCasinoListSelector = () => createSelector(selectEntityCasinoListDomain, (substate) => substate.casinos);
const MakeSelectCasinoSelector = () => createSelector(selectEntityCasinoListDomain, (substate) => substate.casinoOptions);
const MakeCasinoSelector = () => createSelector(selectEntityCasinoListDomain, (substate) => substate.casino);
const MakeCasinoDetailModalSelector = () => createSelector(selectEntityCasinoListDomain, (substate) => substate.casinoDetailModal);
const MakeSelectEntityCasinoListPage = () => createSelector(selectEntityCasinoListDomain, (substate) => substate);
const MakeCasinoIdSelector = () => createSelector(selectEntityCasinoListDomain, (substate) => substate.id);
const MakeCasinoStatusCountSelector = () => createSelector(selectEntityCasinoListDomain, substate => substate.casinoStatusCount);
const MakeLotterytatusCountSelector = () => createSelector(selectEntityCasinoListDomain, substate => substate.lotteryStatusCount);
const MakeOperatorDataSelector = () => createSelector(selectEntityCasinoListDomain, substate => substate.operatorData);

export default MakeSelectEntityCasinoListPage;
export {
    MakeSelectEntityCasinoListPage,
    MakeselectFormValueSelector,
    MakeSelectEntityCasinoListSelector,
    MakeSelectCasinoSelector,
    MakeCasinoSelector,
    MakeCasinoDetailModalSelector,
    MakeCasinoIdSelector,
    MakeCasinoStatusCountSelector,
    MakeLotterytatusCountSelector,
    MakeOperatorDataSelector,
};
