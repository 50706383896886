import produce from "immer";
import {
    SET_REQUEST_GAME_TYPE_FILTER_VALUE,
    LIST_REQUEST_GAME_TYPE_SUCCESS,
    SET_REQUEST_GAME_TYPE_ID,
    GET_REQUEST_GAME_TYPE_SUCCESS,
    SET_REQUEST_GAME_TYPE_VALUE,
    SET_CERTIFICATE_GAME_TYPE_VALUE,
    SET_APPROVE_CERTIFICATE_GAME_TYPE_VALUE,
    SET_CERTIFICATE_GAME_TYPE_FILTER_VALUES,
} from "Modules/Certificate/Casino/ApplicationForm/GameType/Constants";

export const FilterFormCode = "C002";

export const EmptyFields = {
    formTypeCode: "C002",
};
export const initialState = {
    initialValues: EmptyFields,
    gameTypeList: [],
    formValues: {},
    filterValues: {},
    errors: [],
    id: null,
    requestGameTypeDetail: null,
    requestGameTypeApprove: null,

    certificateFilterValue: {},
    certificateGameTypeValue: null,
};

const RequestGameTypeReducer = produce((draft, action) => {
    switch (action.type) {
        case SET_REQUEST_GAME_TYPE_VALUE:
            draft.formValues = action.values;
            break;
        case SET_REQUEST_GAME_TYPE_FILTER_VALUE:
            draft.filterValues = action.values;
            break;
        case LIST_REQUEST_GAME_TYPE_SUCCESS:
            draft.gameTypeList = action.gameTypeList;
            break;
        case SET_REQUEST_GAME_TYPE_ID:
            draft.id = action.id;
            break;
        case GET_REQUEST_GAME_TYPE_SUCCESS:
            draft.requestGameTypeDetail = action.requestGameTypeDetail;
            break;
        case SET_APPROVE_CERTIFICATE_GAME_TYPE_VALUE:
            draft.requestGameTypeApprove = action.requestGameTypeApprove;
            break;
        case SET_CERTIFICATE_GAME_TYPE_VALUE:
            draft.certificateGameTypeValue = action.values;

            break;
        case SET_CERTIFICATE_GAME_TYPE_FILTER_VALUES:
            draft.certificateFilterValue = action.values;
            break;
        default:
            break;
    }
}, initialState);
export default RequestGameTypeReducer;
