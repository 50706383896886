import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useInjectSaga } from "Utility/InjectSaga";
import { useInjectReducer } from "Utility/InjectReducer";

import Reducer from "Modules/Entity/Operator/Reducer";
import Saga from "Modules/Entity/Operator/Saga";
import { Form, Button } from "antd";
import { FullLayout } from "Components/Layouts/";
import { HelmetHeader } from "Components/Helmet-Header";
import { EntityCasinoForm } from "Modules/Entity/Operator/Forms/EntityCasinoForm";
import { LotteryCreateForm } from "Modules/Entity/Operator/Forms/LotteryCreateForm";
import { EntityCompanySearchModal } from "Modules/Entity/Company/Modals/";

import {
    SetFormValuesAction,
    CreateCasinoRequestAction,
} from "Modules/Entity/Operator/Actions";
import { EntityType } from "Common/Constants/EntityType";

const key = "entityCasino";
export const CasinoCreate = () => {
    useInjectReducer({ key, reducer: Reducer });
    useInjectSaga({ key, saga: Saga });
    // // // fields
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [searchParams] = useSearchParams();
    const [companyVisibility, setCompanyVisibility] = useState(false);
    const entityType = searchParams.get("entityType");
    // method
    const ActionButton = () => {
        return (
            <>
                <Button
                    onClick={() => form.submit()}
                    type="primary"
                >
                    រក្សាទុក
                </Button>
            </>
        );
    };
    // events
    const onFormProviderSubmit = (name: string, { forms, values }: any) => {
        switch (name) {
            case "entityCasinoForm": {
                onOperatorSubmit(values);
                break;
            }
        }
    };

    const onOperatorSubmit = (values: any) => {
      
      const model = {
          ...values,
          gPAName: "",
          gAPLatinName: "",
          licenseExpiredDate: null,
          casinoLicenseNumber: values.casinoLicenseNumber || " ",
          entityTypeId: 1,
      };

      if (entityType === EntityType.LOTTERY) {
          model.entityTypeId = 2;
      }
      if (values.licenseExpiredDate) {
          model.licenseExpiredDate = values.licenseExpiredDate.format("YYYY-MM-DD");
      }
      dispatch(SetFormValuesAction(model));
      dispatch(CreateCasinoRequestAction());
    };
    return (
        <>
            <FullLayout
                icon={null}
                title={`បញ្ជូលព័ត៌មាន${entityType === EntityType.LOTTERY ? "ប្រតិបត្តិករណ៍ល្បែងផ្សងសំណាង" : "កាស៊ីណូ"}`}
                redirectUrl={`/entity/operator?entityType=${entityType}`}
                descriptions="ត្រលប់ទៅបញ្ជី"
                actions={<ActionButton />}
            >
                <HelmetHeader meta="បញ្ជូលព័ត៌មានកាស៊ីណូ" />
                <Form.Provider onFormFinish={onFormProviderSubmit}>

                    {
                        entityType === EntityType.LOTTERY ? <LotteryCreateForm form={form} setCompanyVisibility={setCompanyVisibility} />
                            : <EntityCasinoForm form={form} setCompanyVisibility={setCompanyVisibility} />
                    }
                </Form.Provider>
                <EntityCompanySearchModal
                    visibility={companyVisibility}
                    onClose={() => setCompanyVisibility(false)}
                />
            </FullLayout>
        </>
    );
};
