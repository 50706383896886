import { InspectionCertificateMasterInitialState } from "Modules/Settings/InspectionCertificateMaster/Reducer";
import { createSelector } from "reselect";

const InspectionCertificateMasterDomain = (state) => state.inspectionCertificateMaster || InspectionCertificateMasterInitialState;
const MakeInspectionCertificateMasterStatusSelector = () => createSelector(InspectionCertificateMasterDomain, substate => substate.status);
const MakeInspectionCertificateMasterIdSelector = () => createSelector(InspectionCertificateMasterDomain, substate => substate.id);
const MakeInspectionCertificateMasterValueSelector = () => createSelector(InspectionCertificateMasterDomain, substate => substate.values);
const MakeInspectionCertificateMasterFilterValueSelector = () => createSelector(InspectionCertificateMasterDomain, substate => substate.filterValue);
const MakeInspectionCertificateMasterSelector = () => createSelector(InspectionCertificateMasterDomain, substate => substate.inspectionCertificateMaster);
const MakeInspectionCertificateMasterListSelector = () => createSelector(InspectionCertificateMasterDomain, substate => substate.inspectionCertificateMasters);

// DETAIL
const MakeInspectionCertificateDetailIdSelector = () => createSelector(InspectionCertificateMasterDomain, substate => substate.inspectionCertificateDetailId);
const MakeInspectionCertificateDetailValueSelector = () => createSelector(InspectionCertificateMasterDomain, substate => substate.inspectionCertificateDetailValue);
const MakeInspectionCertificatePrintDetailSelector = () => createSelector(InspectionCertificateMasterDomain, substate => substate.inspectionCertificatePrintDetail);
const MakeInspectionCertificateDetailReportSelector = () => createSelector(InspectionCertificateMasterDomain, substate => substate.inspectionCertificateDetailReport);
const MakeReportFilterValueSelector = () => createSelector(InspectionCertificateMasterDomain, substate => substate.reportFilterValue);
const MakeSerialsSerlector = () => createSelector(InspectionCertificateMasterDomain, substate =>substate.serials);
const MakeInspectionSerialsSelector = () => createSelector(InspectionCertificateMasterDomain, substate => substate.inspectionSerials);

// GTIN
const MakeGtinEquipmentTypeIdSelector = () => createSelector(InspectionCertificateMasterDomain, substate => substate.gtinEquipmentTypeId);
const MakeGtinSelector = () => createSelector(InspectionCertificateMasterDomain, substate => substate.gtin);
const MakeGtinLocalSelector = () => createSelector(InspectionCertificateMasterDomain, substate => substate.gtinLatestValue);

export {
    MakeInspectionCertificateMasterStatusSelector,
    MakeInspectionCertificateMasterIdSelector,
    MakeInspectionCertificateMasterValueSelector,
    MakeInspectionCertificateMasterFilterValueSelector,
    MakeInspectionCertificateMasterSelector,
    MakeInspectionCertificateMasterListSelector,
    MakeReportFilterValueSelector,

    // details
    MakeInspectionCertificateDetailIdSelector,
    MakeInspectionCertificateDetailValueSelector,
    MakeInspectionCertificatePrintDetailSelector,
    MakeInspectionCertificateDetailReportSelector,
    MakeSerialsSerlector,
    MakeInspectionSerialsSelector,
    //gtin
    MakeGtinEquipmentTypeIdSelector,
    MakeGtinSelector,
    MakeGtinLocalSelector
}