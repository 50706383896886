import { createSelector } from "reselect";
import { CasinoLicenseInitialState } from "Modules/License/Casino/Reducer";

const CertificateGameEquipmentDomain = (state: any) =>
    state.certificateGameEquipment || CasinoLicenseInitialState;

const MakeRequestGameEquipmentFilterValueSelector = () =>
    createSelector(
        CertificateGameEquipmentDomain,
        (substate) => substate.filterValues
    );

const MakeRequestGameEquipmentValueSelector = () =>
    createSelector(
        CertificateGameEquipmentDomain,
        (substate) => substate.requestFormValue
    );
const MakeRequestGameEquipmentListSelector = () =>
    createSelector(
        CertificateGameEquipmentDomain,
        (substate) => substate.requestGameEquipmentList
    );

const MakeRequestGameEquimentIdSelector = () =>
    createSelector(CertificateGameEquipmentDomain, (substate) => substate.id);
const MakeRequestGameEquipmentSelector = () =>
    createSelector(
        CertificateGameEquipmentDomain,
        (substate) => substate.requestGameEquipmentDetail
    );
export default CertificateGameEquipmentDomain;
export {
    MakeRequestGameEquipmentFilterValueSelector,
    MakeRequestGameEquipmentListSelector,
    MakeRequestGameEquipmentValueSelector,
    MakeRequestGameEquimentIdSelector,
    MakeRequestGameEquipmentSelector,
};
