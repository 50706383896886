import { all, call, put, takeLatest } from "redux-saga/effects";
import Request from "Utility/Http";
import ApiEndpoint from "Utility/Endpoint";
import { GET } from "Utility/Constant";
import { v4 as uuid } from "uuid";

import { AsyncEndAction, AsyncStartAction } from "Modules/Application/Actions";
import { ShowMessage } from "Common/Saga";
import { SelectCurrencySuccessAction } from "./Actions";
import { SELECT_CURRENCY_REQUEST } from "./Constants";




function* SelectCurrency() {
    try {
        yield put(AsyncStartAction());
        const url = ApiEndpoint.CURRENCY_PATH;
        const payload = ApiEndpoint.makeApiPayload(url, GET, {});
        const response = yield call(Request, payload);
        if (response && !response.snack && !response.status) {
            yield put(SelectCurrencySuccessAction(response));
        }
    } catch {
        yield ShowMessage({
            type: "error",
            message: "មានបញ្ហាពេលទាញយកទិន្នន័យ",
            content: "",
            id: uuid(),
        })

    } finally {
        yield put(AsyncEndAction());
    }
}

export default function* CurrencySaga() {
    yield all([
        yield takeLatest(SELECT_CURRENCY_REQUEST, SelectCurrency),
    ]);
}