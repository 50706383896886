export const SET_EQUIPMENT_BINDING_VALUE = "SET_EQUIPMENT_BINDING_VALUE";
export const CREATE_EQUIPMENT_BINDING_REQUEST = "CREATE_EQUIPMENT_BINDING_REQUEST";
export const CREATE_EQUIPMENT_BINDING_SUCCESS = "CREATE_EQUIPMENT_BINDING_SUCCESS";


export const SET_EQUIPMENT_BINDING_UPLOAD_VALUE = "SET_EQUIPMENT_BINDING_UPLOAD_VALUE";
export const SET_UPLOAD_EQUIPMENT_BINDING_STATUS = "SET_UPLOAD_EQUIPMENT_BINDING_STATUS";
export const UPLOAD_EQUIPMENT_BINDING_REQUEST = "UPLOAD_EQUIPMENT_BINDING_REQUEST";
export const UPLOAD_EQUIPMENT_BINDING_SUCCESS = "UPLOAD_EQUIPMENT_BINDING_SUCCESS";
export const UPLOAD_EQUIPMENT_BINDING_ERROR = "UPLOAD_EQUIPMENT_BINDING_ERROR";

export const SET_EQUIPMENT_BINDING_VERIFY_VALUE = "SET_EQUIPMENT_BINDING_VERIFY_VALUE";
export const VERIFY_EQUIPMENT_BINDING_REQUEST = "VERIFY_EQUIPMENT_BINDING_REQUEST";
export const VERIFY_EQUIPMENT_BINDING_SUCCESS = "VERIFY_EQUIPMENT_BINDING_SUCCESS";