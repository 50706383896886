export const LicenseType = [
    {
        entityName: "CasinoLicense",
        formType: "C001",
        label: "Casino License",
    },
    {
        entityName: "Casino Game Type Certificate",
        formType: "C002",
        label: "Casino Game Type Certificate",
    },
    {
        entityName: "Casino Game Rule Certificate",
        formType: "C003",
        label: "Casino Game Rule Certificate",
    },
    {
        entityName: "Casino Game Equipment Certificate",
        formType: "C004",
        label: "Casino Game Equipment Certificate",
    },
    {
        entityName: "LotteryLicense",
        formType: "License",
        label: "Lottery License",
    },
];
