import React from "react";
import { Form, Input, DatePicker } from "antd";

export interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
    editing: boolean;
    dataIndex: string;
    title: any;
    inputType: string;
    record: any;
    name: string;
    index: number;
    children: React.ReactNode;
    handleSave: (record: any) => void;
}

export const EditableCell: React.FC<EditableCellProps> = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    name,
    children,
    handleSave,
    ...restProps
}) => {
    // const inputNode = inputType === "number" ? <InputNumber /> : <Input />;
    let inputNode = null;
    switch (inputType) {
        case "input":
            inputNode = <Input />;
            break;
        case "date":
            inputNode = <DatePicker />;
            break;
    }
    return (
        <td {...restProps}>
            {editing ? (
                <Form.Item
                    name={name}
                    style={{ margin: 0 }}
                    rules={[
                        {
                            required: true,
                            message: `Please Input ${title}!`,
                        },
                    ]}
                >
                    {inputNode}
                </Form.Item>
            ) : (
                children
            )}
        </td>
    );
};
