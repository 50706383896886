import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useInjectSaga } from "Utility/InjectSaga";
import { useInjectReducer } from "Utility/InjectReducer";

import { Form, Button } from "antd";
import { ArchiveIcon } from "@heroicons/react/outline";
import { FullLayout } from "Components/Layouts/";
import { HelmetHeader } from "Components/Helmet-Header";
import { BoardOfDirectorModal } from "Modules/License/Casino/Request/Modal/BoardOfDirectorModal";
import { CapitalShareModal } from "Modules/License/Casino/Request/Modal/CapitalShareModal";
import { RequestCasinoLicenseForm } from "Modules/License/Casino/ApplicationForm/License/Forms/RequestCasinoLicenseForm";
import { CasinoSearchModal } from "Modules/Entity/Operator/Modals/CasinoSearchModal";

import Reducer from "Modules/License/Casino/Reducer";
import Saga from "Modules/License/Casino/Saga";
import {
    CreateRequestCasinoLicenseRequestAction,
    SetRequestCasinoLicenseValueAction,
} from "Modules/License/Casino/Actions";
import { MakeSelectedRowSelector } from "Components/SearchModal/Selectors";

const key = "licenseCasino";
export const RequestCasinoLicenseCreate = () => {
    //Injector
    useInjectReducer({ key, reducer: Reducer });
    useInjectSaga({ key, saga: Saga });

    // fields
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const casinoModel = useSelector(MakeSelectedRowSelector());

    const [boardOfDirectorVisibility, setBoardOfDirectorVisibility] =
        useState(false);
    const [capitalShareVisibility, setCapitalShareVisibility] = useState(false);
    const [casinoVisibility, setCasinoVisibility] = useState(false);

    // tables columns

    // constructor
    useEffect(() => {
        if (casinoModel) {
            let formData = {
                company: casinoModel.company,
                casino: {
                    code: casinoModel.code,
                    name: casinoModel.name,
                    latinName: casinoModel.latinName,
                    address: casinoModel.address,
                },
                mostCapitalValue: casinoModel.company.mostCapitalValue,
                personGovermentApprovalKhmer: casinoModel.gAPName,
                personGovermentApprovalLatin: casinoModel.gAPLatin,
            };

            const companyData = JSON.parse(casinoModel.companyData);
            form.setFieldsValue({
                code: casinoModel.code,
                formData: { ...companyData ,...formData},
                casinoId: casinoModel.id,
                companyId: casinoModel.company?.id,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [casinoModel]);

    // methods
    const submitCasinoLicenseForm = (name: string, values: any) => {
        let licenseModel = {
            ...form.getFieldsValue(),
            entityName: "Casino",
            formType: "C001",
            companyNameInKhmer: values.formData.company.name,
            casinoNameInKhmer: values.formData.casino.name,
            code: values.formData.casino.code,
            applicantName: values.formData.applicant.fullname,
            applicantNationality: values.formData.applicant.nationality,
            applicantContactNumber: values.formData.applicant.mobilePhoneNumber,
            representativeName: values.formData.applicant.fullname,
            representativeNationality: values.formData.applicant.nationality,
            representativeContactNumber:
                values.formData.applicant.mobilePhoneNumber,
        };
        // licenseModel.receiverName = " ";
        licenseModel.trackingCode = " ";
        if (values.formData.representativeType === true) {
            licenseModel.representativeName =
                values.formData.representative.fullname;
            licenseModel.representativeNationality =
                values.formData.representative.nationality;
            licenseModel.representativeContactNumber =
                values.formData.representative.mobilePhoneNumber;
        }
        licenseModel.formData.casinoTypeId = values.formData.casinoTypeId;

        licenseModel.formData.boardOfDirectors =
            form.getFieldValue("boardOfDirectors");

        dispatch(SetRequestCasinoLicenseValueAction(licenseModel));
        dispatch(CreateRequestCasinoLicenseRequestAction());
    };

    const submitCapitalShareForm = (forms: any, values: any) => {
        const { requestCasinoLicenseForm } = forms;
        const capitalShares: any[] =
            requestCasinoLicenseForm.getFieldValue("capitalShares") || [];

        requestCasinoLicenseForm.setFieldsValue({
            capitalShares: [...capitalShares, values],
        });
        setCapitalShareVisibility(false);
    };

    const submitBoardofDirectorForm = (forms: any, values: any) => {
        const { requestCasinoLicenseForm } = forms;

        const boardOfDirectors: any =
            requestCasinoLicenseForm.getFieldValue("boardOfDirectors") || [];
        requestCasinoLicenseForm.setFieldsValue({
            boardOfDirectors: [...boardOfDirectors, values],
        });

        setBoardOfDirectorVisibility(false);
    };
    const ActionButton = () => {
        return (
            <div>
                <Button
                    onClick={() => {
                        form.submit();
                    }}
                    type="primary"
                >
                    រក្សាទុក
                </Button>
            </div>
        );
    };

    // events
    const onFormProviderSubmit = (name: string, { values, forms }: any) => {

        switch (name) {
            case "boardOfDirectorForm":
                submitBoardofDirectorForm(forms, values);
                break;
            case "requestCasinoLicenseForm":

                submitCasinoLicenseForm(name, values);
                break;
            case "capitalShareForm":
                submitCapitalShareForm(forms, values);
                break;
            default: {
                break;
            }
        }
    };
    return (
        <FullLayout
            icon={<ArchiveIcon />}
            title="ពាក្យស្នើសុំអាជ្ញាបណ្ណ (C001)"
            descriptions="ត្រលប់ទៅបញ្ជី"
            redirectUrl="/license/casino/application-form/request-license"
            actions={<ActionButton />}
        >
            <HelmetHeader meta="ចុះបញ្ជី" />
            <Form.Provider onFormFinish={onFormProviderSubmit}>
                <RequestCasinoLicenseForm
                    form={form}
                    setBoardOfDirectorVisibility={setBoardOfDirectorVisibility}
                    setCapitalShareVisibility={setCapitalShareVisibility}
                    setCasinoVisibility={setCasinoVisibility}
                />
                <BoardOfDirectorModal
                    visible={boardOfDirectorVisibility}
                    onCancel={() => setBoardOfDirectorVisibility(false)}
                />
                <CapitalShareModal
                    visible={capitalShareVisibility}
                    onCancel={() => setCapitalShareVisibility(false)}
                />
            </Form.Provider>
            {/* <CasinoSearchModal
                visibility={casinoVisibility}
                onClose={() => setCasinoVisibility(false)}
            /> */}
        </FullLayout>
    );
};
