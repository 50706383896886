import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { useInjectSaga } from "Utility/InjectSaga";
import { useInjectReducer } from "Utility/InjectReducer";

import { Form, Button } from "antd";
import { ArchiveIcon } from "@heroicons/react/outline";
import { FullLayout } from "Components/Layouts/";
import { HelmetHeader } from "Components/Helmet-Header";
import { RequestGameTypeCreateForm } from "Modules/Certificate/Casino/ApplicationForm/GameType/Forms/RequestGameTypeCreateForm";

import Reducer from "Modules/Certificate/Casino/ApplicationForm/GameType/Reducer";
import Saga from "Modules/Certificate/Casino/ApplicationForm/GameType/Saga";

import {
    CreateRequestGameTypeRequestAction,
    SetRequestGameTypeValueAction,
} from "Modules/Certificate/Casino/ApplicationForm/GameType/Actions";
import { SelectGameTypeSuccessAction } from "Modules/Settings/GameType/Actions";
import { SelectGameTypeService } from "Modules/Settings/GameType/Service";
import { useSearchParams } from "react-router-dom";
import { 
    GetIdentificationTypeOptionService, 
    GetOperatorOptionService } from "Modules/Entity/Operator/Service";
import { SelectCasinoSuccessAction } from "Modules/Entity/Operator/Actions";
import { SelectableIdentificationTypeSuccessAction } from "Modules/License/Casino/Actions";
import LicenseCasinoReducer from "Modules/License/Casino/Reducer";

const key = "certificateGameType";
const licenseKey = "licenseCasino";
export const RequestGameTypeCreate = () => {
    //Injector
    useInjectReducer({ key, reducer: Reducer });
    useInjectSaga({ key, saga: Saga });
    useInjectReducer({ key: licenseKey, reducer: LicenseCasinoReducer });
    // fields
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();
    const entityType = searchParams.get("entityType");
    const [form] = Form.useForm();

    // constructor
    useEffect(() => {
        const entityTypeId = entityType === "LOTTERY" ? 2 : 1;
        GetIdentificationTypeOptionService(dispatch).then((identityRs: any) => {
            if(identityRs) {
                dispatch(SelectableIdentificationTypeSuccessAction(identityRs));
            }
            SelectGameTypeService(entityTypeId, dispatch).then((gameTypeOption: any) => {
                if(gameTypeOption) {
                    dispatch(SelectGameTypeSuccessAction(gameTypeOption));
                }
                GetOperatorOptionService(dispatch).then((casinoOptionsRs: any) => {
                    if(casinoOptionsRs) {
                        dispatch(SelectCasinoSuccessAction(casinoOptionsRs));
                    }
                });
            });
            
        });
        

        return () => {
            dispatch(SelectableIdentificationTypeSuccessAction([]));
            dispatch(SelectCasinoSuccessAction([]));
            dispatch(SelectGameTypeSuccessAction([]));
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // methods
    const submitCasinoLicenseForm = (name: string, values: any) => {
        // await form.validateFields();

        let licenseModel = {
            ...form.getFieldsValue(),
            entityName: "GameType",
            formType: "C002",
            CompanyNameInKhmer: values.formData.company.name,
            CasinoNameInKhmer: values.formData.casino.name,
            code: values.formData.casino.code,
            applicantName: values.formData.applicant.fullname,
            applicantNationality: values.formData.applicant.nationality,
            applicantContactNumber: values.formData.applicant.mobilePhoneNumber,
            representativeName: values.formData.applicant.fullname,
            representativeNationality: values.formData.applicant.nationality,
            representativeContactNumber:
                values.formData.applicant.mobilePhoneNumber,
        };
        // licenseModel.receiverName = " ";
        licenseModel.trackingCode = " ";
        if (values.formData.representativeType === true) {
            licenseModel.representativeName =
                values.formData.representative.fullname;
            licenseModel.representativeNationality =
                values.formData.representative.nationality;
            licenseModel.representativeContactNumber =
                values.formData.representative.mobilePhoneNumber;
        }
        licenseModel.formData.casinoTypeId = values.formData.casinoTypeId;

        licenseModel.formData.gameTypes = form.getFieldValue("gameTypes");

        dispatch(SetRequestGameTypeValueAction(licenseModel));
        dispatch(CreateRequestGameTypeRequestAction());
    };

    const ActionButton = () => {
        return (
            <div>
                <Button
                    onClick={() => {
                        form.submit();
                    }}
                    type="primary"

                >
                    រក្សាទុក
                </Button>
            </div>
        );
    };

    const onGameTypeModalFormSubmit = (
        requestGameTypeForm: any,
        values: any
    ) => {
        const gameTypes = requestGameTypeForm.getFieldValue("gameTypes") || [];

        requestGameTypeForm.setFieldsValue({
            gamesType: [...gameTypes, values],
        });
        requestGameTypeForm.setFieldsValue({
            gameTypes: [...gameTypes, values],
        });
    };

    // events
    const onFormProviderSubmit = (name: string, { values, forms }: any) => {
        switch (name) {
            case "requestGameTypeForm":
                submitCasinoLicenseForm(name, values);
                break;
            case "gameTypeModalForm":
                const { requestGameTypeForm } = forms;
                onGameTypeModalFormSubmit(requestGameTypeForm, values);
                break;
            default: {
                break;
            }
        }
    };
    return (
        <FullLayout
            icon={<ArchiveIcon />}
            title="ស្នើសុំចុះប្រភេទល្បែង  (C002)"
            descriptions="ត្រលប់ទៅបញ្ជី"
            redirectUrl="/certificate/casino/request-game-type/request"
            actions={<ActionButton />}
        >
            <HelmetHeader meta="ស្នើសុំចុះប្រភេទល្បែង" />
            <Form.Provider onFormFinish={onFormProviderSubmit}>
                <RequestGameTypeCreateForm form={form} />
            </Form.Provider>
        </FullLayout>
    );
};
