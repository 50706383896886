import { Form, Input, DatePicker, Select, Button } from "antd";

import { LicenseType } from "Common/Constants/LicenseType";

export const CertificateGameTypeFilterForm = ({ form }: any) => {
    return (
        <>
            <Form.Item name="code" label="ព័ត៌មានស្វែងរក">
                <Input placeholder="លេខអាជ្ញាបណ្ណ / ឈ្មោះ " />
            </Form.Item>
            <Form.Item>
                <Button htmlType="submit" type="default">
                    ស្វែងរក
                </Button>
            </Form.Item>
            <Form.Item
                name="expiredDate"
                label="ស្វែងរកតាមសុពលភាពអាជ្ញាបណ្ណ"
            >
                <DatePicker.RangePicker />
            </Form.Item>
            <Form.Item name="formType" label="ប្រភេទអាជ្ញាបណ្ណ">
                {/* <ComboBox
                            data={LicenseType}
                            label="label"
                            keyValue="entityName"
                        /> */}
                <Select>
                    {LicenseType &&
                        LicenseType.map((item) => (
                            <Select.Option
                                key={`${item["entityName"]}`}
                                value={item["formType"]}
                            >
                                {item["label"]}
                            </Select.Option>
                        ))}
                </Select>
            </Form.Item>
        </>

    );
};
