export const NumberToCharacter = (num: number) => {
    return (num + 9).toString(36).toUpperCase();
};

export const NumberToKhNumber = (num: number) => {
    return num.toString(36).toUpperCase();
};

export const NumberToKhCharacter = (num: number) => {
    switch (num) {
        case 1:
            return "ក";
        case 2:
            return "ខ";
        case 3:
            return "គ";
        case 4:
            return "ឃ";
        case 5:
            return "ង";

        default:
            break;
    }
};


export const GTINGenerator = (initialValue: number) => {
    const currentDate = new Date();
    var gs1Prefix = [(currentDate.getFullYear() % 100).toString(), currentDate.getMonth().toString().padStart(2, "0")];


    var labelerCode = [];
    for (var index = 0; index < 10; index++) {
        labelerCode[index] = Math.floor((Math.random() * 10));
    }
    var gtinArray: string[] = [];

    gtinArray.push(gs1Prefix[0]);
    gtinArray.push(gs1Prefix[1]);
    gtinArray.push(initialValue.toString().padStart(6, "0"));

    gtinArray.push(CheckDigit12(gtinArray));

    var gtinString = gtinArray.join('');

    return gtinString;
}

export const CheckDigit12 = (gtinArray: any) => {
    var sum = 0;
    for (var index = 0; index < gtinArray.length; index++) {
        if (index % 2 !== 0) {
            sum += parseInt(gtinArray[index]);
        }
        else {
            sum += parseInt(gtinArray[index]) * 3;
        }
    }

    var checkDigit = 0;
    var remainder = sum % 10;

    if (remainder !== 0) {
        checkDigit = 10 - remainder;
    }
    //alert('sum=' + sum + ', remainder=' + remainder + ', checkDigit=' + checkDigit);
    return checkDigit.toString().padStart(2, '0');
}

export const GetGtinRunningNumber = (gtin: string) => {

    const runningNumber: string = gtin.slice(4, 10);
    return runningNumber;
}