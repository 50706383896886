import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";

import { CreateCasinoEquipmentMasterModalForm } from "Modules/Equipments/Casino/Forms/CreateCasinoEquipmentMasterModalForm"

import { MakeGameRuleEquipmentMasterSelector } from "../Selectors";
import { useEffect } from "react";
import { GetGameRuleEquipmentMasterSuccessAction, SetCasinoEquipmentMasterIdAction } from "../Actions";

export const CasinoEquipmentModalForm = ({ equipmentForm, onDbDelete }: any) => {
    // fields
    const dispatch = useDispatch();
    const gameRuleEquipmentMaster = useSelector(MakeGameRuleEquipmentMasterSelector());


    // events
    useEffect(() => {

        if (gameRuleEquipmentMaster) {
            const model = {
                ...equipmentForm.getFieldsValue(),
                id: gameRuleEquipmentMaster.id,
                registeredDate: dayjs(gameRuleEquipmentMaster.registeredDate),
                equipments: gameRuleEquipmentMaster.casinoEquipmentDetails || [],
            }
            equipmentForm.setFieldsValue(model);
        }
    }, [gameRuleEquipmentMaster]);

    const onDeleteEquipment = (item: any) => {
        const equipments = equipmentForm.getFieldValue("equipments") || [];
        const equipment = equipments.indexOf(item);
        if (equipment > -1) {
            equipments.splice(equipment, 1);
            onDbDelete(item);
            equipmentForm.setFieldValue("equipments", [...equipments]);
        }
    }

    // render

    return (
        <>
            <CreateCasinoEquipmentMasterModalForm form={equipmentForm} onDeleteEquipment={onDeleteEquipment} />
        </>
    )
}