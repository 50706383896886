import {
    Row,
    Col,
    Form,
    Input,
    DatePicker,
} from "antd";
export const CompanyTabDetailForm = (form: any) => {
    return <div>
        <Row> 
            <Col span={6}>
                នាមករណ៍ក្រុមហ៊ុនជាអក្សរខ្មែរ <br />
                Company name in Khmer
            </Col>
            <Col span={18}>
                <Form.Item
                    name={["formData", "company", "name"]}
                    rules={[{ required: true }]}
                >
                    <Input
                        placeholder="នាមករណ៍ក្រុមហ៊ុនជាអក្សរខ្មែរ / Company name in Khmer"
                        readOnly
                    />
                </Form.Item>
            </Col>
            <Col span={6}>
                នាមករណ៍ក្រុមហ៊ុនជាអក្សរឡាតាំង <br />
                Company name in Latin
            </Col>
            <Col span={18}>
                <Form.Item
                    name={["formData", "company", "latinName"]}
                    rules={[{ required: true }]}
                >
                    <Input
                        placeholder="នាមករណ៍ក្រុមហ៊ុនជាអក្សរឡាតាំង / Company name in Latin"
                        readOnly
                    />
                </Form.Item>
            </Col>
            <Col span={6}>
                នាមករណ៍បុគ្គលទទួលបាន ស.ជ.ណ <br />
                name of Person received government
                approval-in-Principle
            </Col>
            <Col span={18}>
                <Row>
                    <Col span={8}>
                        អក្សរខ្មែរ <br />
                        in Khmer
                    </Col>
                    <Col span={16}>
                        <Form.Item
                            name={[
                                "formData",
                                "company",
                                "gAPFirstname",
                            ]}
                        >
                            <Input readOnly />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={8}>
                        អក្សរឡាតាំង <br />
                        in Latin
                    </Col>
                    <Col span={16}>
                        <Form.Item
                            name={[
                                "formData",
                                "company",
                                "gAPLastname",
                            ]}
                        >
                            <Input readOnly />
                        </Form.Item>
                    </Col>
                </Row>
            </Col>
        </Row>

        <Row>
            <Col span={6}>
                នាមករណ៍ក្រុមហ៊ុនពីមុន(ប្រសិនបើមាន) <br />
                Former name(If Any)
            </Col>
            <Col span={16}>
                <Row gutter={16}>
                    <Col span={8}>
                        <Form.Item
                            name={[
                                "formData",
                                "company",
                                "formerName",
                            ]}
                        >
                            <Input
                                placeholder="នាមករណ៍ក្រុមហ៊ុនពីមុន(ប្រសិនបើមាន) / Former name(If Any)"
                                readOnly
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        កាលបរិច្ឆេទនៃការផ្លាស់ប្តូរនាមករណ៍
                        <br />
                        Date of Changing the name
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            name={[
                                "formData",
                                "company",
                                "dateOfChangingName",
                            ]}
                        >
                            <DatePicker placeholder="កាលបរិច្ឆេទនៃការផ្លាស់ប្តូរនាមករណ៍ / Date of Changing the name" />
                        </Form.Item>
                    </Col>
                </Row>
            </Col>
            <Col span={6}>
                លេខចុះបញ្ជីពាណិជ្ជកម្ម <br />
                Registration Number
            </Col>
            <Col span={18}>
                <Form.Item
                    name={[
                        "formData",
                        "company",
                        "registrationNumber",
                    ]}
                >
                    <Input
                        placeholder="លេខចុះបញ្ជីពាណិជ្ជកម្ម / Registration Number"
                        readOnly
                    />
                </Form.Item>
            </Col>

            <Col span={6}>
                លេខអត្តសញ្ញាណកម្មសារពើពន្ធ <br /> Tax Identificaiton
                Number (TIN)
            </Col>
            <Col span={18}>
                <Form.Item
                    name={[
                        "formData",
                        "company",
                        "taxIdentificationNumber",
                    ]}
                    rules={[{ required: true }]}
                >
                    <Input
                        placeholder="លេខអត្តសញ្ញាណកម្មសារពើពន្ធ / Tax Identificaiton Number (TIN)"
                        readOnly
                    />
                </Form.Item>
            </Col>
            <Col span={6}>
                សញ្ជាតិក្រុមហ៊ុន <br /> Company Nationality
            </Col>
            {/* <Col span={18}>
                <Form.Item
                    name={["formData", "company", "nationalId"]}
                    rules={[
                        {
                            required: true,
                            message: "Please select",
                        },
                    ]}
                >
                    <Radio.Group>
                        <Space direction="horizontal">
                            {nationality &&
                                nationality.map((nation: any) => (
                                    <Radio
                                        key={nation.value}
                                        value={nation.value}
                                    >
                                        {nation.label}
                                    </Radio>
                                ))}
                        </Space>
                    </Radio.Group>
                </Form.Item>
            </Col> */}
            <Col span={6}>
                ទីស្នាក់ការចុះបញ្ជីក្រុមហ៊ុន
                <br />
                Company Registration Office
            </Col>
            <Col span={18}>
                <Form.Item
                    name={[
                        "formData",
                        "company",
                        "registrationOffice",
                    ]}
                    rules={[{ required: true }]}
                >
                    <Input
                        placeholder="ទីស្នាក់ការចុះបញ្ជីក្រុមហ៊ុន / Company Registration Office"
                        readOnly
                    />
                </Form.Item>
            </Col>
        </Row>
        <Row>
            <Col span={6}>
                ទូរស័ព្ទច​ល័ត
                <br />
                Mobile Phone Number
            </Col>
            <Col span={18}>
                <Row gutter={16}>
                    <Col span={8}>
                        <Form.Item
                            name={[
                                "formData",
                                "company",
                                "mobilePhoneNumber",
                            ]}
                            rules={[{ required: true }]}
                        >
                            <Input
                                placeholder="ទូរស័ព្ទច​ល័ត / Mobile Phone Number"
                                readOnly
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        ទូរស័ព្ទលើតុ
                        <br />
                        Office Phone Number
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            name={[
                                "formData",
                                "company",
                                "telephoneNumber",
                            ]}
                        >
                            <Input
                                placeholder="ទូរស័ព្ទលើតុ / Office Phone Number"
                                readOnly
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Col>
        </Row>
        <Row>
            <Col span={6}>
                សារអេឡិចត្រូនិច
                <br />
                Email Address
            </Col>
            <Col span={18}>
                <Form.Item
                    name={["formData", "company", "emailAddress"]}
                    rules={[{ required: true }]}
                >
                    <Input
                        placeholder="សារអេឡិចត្រូនិច / Email Address"
                        readOnly
                    />
                </Form.Item>
            </Col>
        </Row>
        <Row>
            <Col span={6}>
                គេហទំព័រ
                <br />
                Website
            </Col>
            <Col span={18}>
                <Form.Item
                    name={["formData", "company", "websiteAddress"]}
                    rules={[{ required: true }]}
                >
                    <Input
                        placeholder="គេហទំព័រ / Website"
                        readOnly
                    />
                </Form.Item>
            </Col>
        </Row>
    </div>
}