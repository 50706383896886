
export const BASE_URL = process.env.REACT_APP_API_BASE_URI;
const AUTH_PATH = "/api/auth/login";
export const CORE_UPLOAD_FILE_PATH = "/api/upload/file";
const PROFILE_PATH = "/api/auth/info";
const LOGOUT_PATH = "api/auth/logout";
const CREATE_LICENSE_PATH = "/api/license/create";

export default class ApiEndpoint {
    /// Casino Selectable
    static SELECTABLE_APPLICATION_TYPE_PATH = "/api/applicationType/list";
    static CASINO_TYPE_PATH = "/api/casinoType";
    static SELECTABLE_CASINO_TYPE_PATH = "/api/casinoType/list";
    static SELECTABLE_IDENTIFICATION_TYPE_PATH = "/api/identificationType/list";
    static SELECTABLE_NATIONALITY_PATH = "/api/nationality/list";
    static LICENSE_LIST_PATH = "/api/license/list";
    // LICENSE
    static LICENSE_PATH = "/api/license";

    static getLoginPath = () => AUTH_PATH;
    static getRequestIdPath = () => "/api/auth/redirect-request";

    // casino
    static CASINO_PATH = "/api/casino";
    static CASINO_LIST_PATH = "/api/casino/list";
    static CASINO_CREATE_PATH = "/api/casino/create";
    static CASINO_BY_ID_PATH = "/api/casino/";
    static CASINO_BY_COMPANY_LIST_PATH = "/api/casino/listByCompany";
    static CASINO_SELECT_PATH = "/api/casino/select";
    // company
    static COMPANY_PATH = "/api/company";
    static COMPANY_LIST_PATH = "/api/company/list";
    static COMPANY_CREATE_PATH = "/api/company/CREATE";
    static COMPANY_SELECT_PATH = "/api/company/select";
    static COMPANY_DETAIL_PATH = "/api/company/";

    // operator
    static OPERATOR_PATH = "/api/operator";
    static OPERATOR_LIST_PATH = "/api/operator/list";
    static OPERATOR_CREATE_PATH = "/api/operator/create";

    // license
    static LICENSE_DETAIL_PATH = "/api/license/get";

    // equipment
    static CASINO_EQUIPMENT_MASTER_PATH = "/api/casino-equipment-master";
    static CASINO_EQUIPMENT_DETAIL_PATH = "/api/casino-equipment-detail";
    static EQUIPMENT_CERTIFICATE_MAP_PATH = "/api/equipment-certificate-binding";

    static REQUEST_LICENSE = "/api/request-license";

    static getProfilePath = () => PROFILE_PATH;

    static getLogoutPath = () => LOGOUT_PATH;

    static getCreateLicensePath = () => CREATE_LICENSE_PATH;

    static getRegisterPath = () => `/auth/register`;

    static getRefreshTokenPath = () => `/api/auth/refresh`;

    // setting
    static CURRENCY_PATH = "/api/currency";
    static EQUIPMENT_TYPE_PATH = "/api/equipment-type";
    static GAME_TYPE_PATH = "/api/gameType";
    static GAME_TYPE_LIST_PATH = "/api/gameType/list";
    static GAME_TYPE_CREATE_PATH = "/api/gameType";
    static GAME_TYPE_DETAIL_PATH = "/api/gameType";

    static GAME_RULE_LIST_PATH = "/api/gameRule/list";
    static GAME_RULE_PATH = "/api/gameRule";
    static GAME_RULE_CREATE_PATH = "/api/gameRule";

    static CASINO_STATUS_PATH = "/api/casinoStatus";
    static FORM_TYPE = "/api/formType"

    static INSPECTION_CERTIFICATE_TYPE_PATH = "/api/inspection-certificate-type";
    static INSPECTION_CERTIFICATE_MASTER_PATH = "/api/inspection-certificate-master";
    static INSPECTION_CERTIFICATE_DETAIL_PATH = "/api/inspection-certificate-detail";
    static INSPECTION_CERTIFICATE_STATUS_PATH = "/api/inspection-certificate-status"

    /**
     * Make API payload
     * @param url
     * @param method
     * @param payload
     * @param contentType
     * @returns {{headers: {}, method: *}}
     */
    static makeApiPayload = (
        url: string,
        method: string,
        payload: any = null,
        contentType = null,
        withCredentials = false,
        withStatus = false
    ) => {
        let jsonPayload: any = {
            url,
            method,
            headers: {},
            withCredentials: withCredentials,
            withStatus: withStatus,
        };

        if (!contentType) {
            jsonPayload.headers.Accept = "application/json";
            jsonPayload.headers["Content-Type"] = "application/json";
        } else {
            jsonPayload.headers.Accept = contentType;
            jsonPayload.headers["Content-Type"] = contentType;
        }

        if (payload !== null) {

            // const formData = new FormData();
            switch (jsonPayload.headers["Content-Type"]) {
                case "application/json":
                    jsonPayload.data = payload;
                    break;
                case "multipart/form-data":
                    jsonPayload.data = payload;
                    break;
                default:
                    jsonPayload.data = null;
                    break;
            }
        }
        const token = localStorage.getItem("cgmcToken") || "";
        jsonPayload.headers["Authorization"] = ` Bearer ${token}`;

        return jsonPayload;
    };
}
