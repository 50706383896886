import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useInjectReducer } from "Utility/InjectReducer";
import { useInjectSaga } from "Utility/InjectSaga";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import { v4 as uuid } from "uuid";

import { HelmetHeader } from "Components/Helmet-Header";
import { ArchiveIcon } from "@heroicons/react/outline";
import { Form, Button } from "antd";
import { FullLayout } from "Components/Layouts/";
import { RequestGameRuleDetailForm } from "Modules/Certificate/Casino/ApplicationForm/GameRule/Forms/RequestGameRuleDetailForm";

import Reducer from "Modules/Certificate/Casino/ApplicationForm/GameRule/Reducer";
import Saga from "Modules/Certificate/Casino/ApplicationForm/GameRule/Saga";
import {
    SetRequestGameRuleIdAction,
    GetRequestGameRuleRequestAction,
    SetRequestGameRuleValueAction,
    EditRequestGameRuleRequestAction,
} from "Modules/Certificate/Casino/ApplicationForm/GameRule/Actions";
import { MakeRequestGameRuleDetailSelector } from "Modules/Certificate/Casino/ApplicationForm/GameRule/Selectors";
import { DATE_FORMAT } from "Modules/Application/Constants";
import { QueryGameRuleSuccessAction, SelectGameRuleSuccessAction } from "Modules/Settings/GameRule/Actions";
import { SelectCasinoSuccessAction } from "Modules/Entity/Operator/Actions";
import { SelectableIdentificationTypeSuccessAction } from "Modules/License/Casino/Actions";
import { GetIdentificationTypeOptionService, GetOperatorOptionService } from "Modules/Entity/Operator/Service";
import { GetGameRuleListService } from "Modules/Settings/GameRule/Service";
import { AsyncEndAction, AsyncStartAction } from "Modules/Application/Actions";

const key = "certificateGameRule";
export const RequestGameRuleDetail = () => {
    useInjectReducer({ key, reducer: Reducer });
    useInjectSaga({ key, saga: Saga });

    // fields
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const { id } = useParams();
    const gameRuleDetail = useSelector(MakeRequestGameRuleDetailSelector());

    // constructor
    useEffect(() => {
        if (gameRuleDetail) {
            const formModel = {
                ...gameRuleDetail,
            };
            formModel.gameRules = gameRuleDetail.formData.gameRules;
            formModel.receivedDate = dayjs(
                gameRuleDetail.receivedDate,
                DATE_FORMAT
            );
            formModel.formData.applicant.dateOfBirth = dayjs(
                gameRuleDetail.formData.applicant.dateOfBirth,
                DATE_FORMAT
            );
            formModel.formData.applicant.identityIssuedDate = dayjs(
                gameRuleDetail.formData.applicant.identityIssuedDate,
                DATE_FORMAT
            );
            formModel.formData.applicant.expiredDate = dayjs(
                gameRuleDetail.formData.applicant.expiredDate,
                DATE_FORMAT
            );
            form.setFieldsValue(formModel);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [gameRuleDetail]);

    useEffect(() => {
        
            const entityTypeId = 1;
            const model = {
                entityType: entityTypeId,
                search: ""
            }
            dispatch(AsyncStartAction());
            GetIdentificationTypeOptionService(dispatch).then((nationalityRs: any) => {
                if(nationalityRs) {
                    dispatch(SelectableIdentificationTypeSuccessAction(nationalityRs));
                }
                GetGameRuleListService(model, dispatch).then((gameTypeOption: any) => {
                    if(gameTypeOption) {
                        dispatch(QueryGameRuleSuccessAction(gameTypeOption));
                    }
                    GetOperatorOptionService(dispatch).then((casinoOptionsRs: any) => {
                        if(casinoOptionsRs) {
                            dispatch(SelectCasinoSuccessAction(casinoOptionsRs));
                        }
                        if (id) {
                            dispatch(SetRequestGameRuleIdAction(id));
                            dispatch(GetRequestGameRuleRequestAction());
                        }
                        dispatch(AsyncEndAction());
                    });
                });
            });
        
        return () => {
            dispatch(SetRequestGameRuleIdAction(null));
            dispatch(SelectableIdentificationTypeSuccessAction([]));
            dispatch(SelectCasinoSuccessAction([]));
            dispatch(SelectGameRuleSuccessAction([]));
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    // events
    const submitCasinoRuleLicenseForm = (name: string, values: any) => {
        // await form.validateFields();

        let licenseModel = {
            ...form.getFieldsValue(),
            trackingCode: "",
            entityName: "Casino Game Rule",
            formType: "C003",
            casinoId: values.formData.casino.id,
            CompanyNameInKhmer: values.formData.company.name,
            CasinoNameInKhmer: values.formData.casino.name,
            code: values.code,
            applicantName: values.formData.applicant.fullname,
            applicantNationality: values.formData.applicant.nationality,
            applicantContactNumber: values.formData.applicant.mobilePhoneNumber,
            representativeName: values.formData.applicant.fullname,
            representativeNationality: values.formData.applicant.nationality,
            representativeContactNumber:
                values.formData.applicant.mobilePhoneNumber,
            formData: {
                ...values.formData,
                gameRules: form.getFieldValue("gameRules"),
            },
        };
        dispatch(SetRequestGameRuleValueAction(licenseModel));
        dispatch(EditRequestGameRuleRequestAction());
    };

    const onGameRuleModalFormSubmit = (
        requestCasinoGameRuleLicenseForm: any,
        values: any
    ) => {
        const gameRules = form.getFieldValue("gameRules") || [];
        const model = {
            ...values,
            id: uuid()
        }
        form.setFieldsValue({ gameRules: [...gameRules, model] });
    };

    const ActionButton = () => {
        return (
            <Button
                onClick={() => {
                    form.submit();
                }}
                type="primary"
            >
                រក្សាទុក
            </Button>
        );
    };

    // events
    const onFormProviderSubmit = (name: string, { values, forms }: any) => {
        switch (name) {
            case "requestCasinoGameRuleLicenseForm":
                submitCasinoRuleLicenseForm(name, values);
                break;
            case "gameRuleModalForm":
                const { requestCasinoGameRuleLicenseForm } = forms;
                onGameRuleModalFormSubmit(
                    requestCasinoGameRuleLicenseForm,
                    values
                );
                break;
            default: {
                break;
            }
        }
    };

    return (
        <FullLayout
            icon={<ArchiveIcon />}
            title={`ពាក្យស្នើសុំចុះបញ្ជីវិធានល្បែង (C003) - ${gameRuleDetail?.casinoNameInKhmer} (${gameRuleDetail?.casinoNameLatin})`}
            descriptions="ត្រលប់ទៅបញ្ជី"
            redirectUrl="/certificate/casino/request-game-rule/request"
            actions={<ActionButton />}
        >
            <HelmetHeader meta="ចុះបញ្ជី" />
            <Form.Provider onFormFinish={onFormProviderSubmit}>
                <RequestGameRuleDetailForm form={form} />
            </Form.Provider>
        </FullLayout>
    );
};
