import {
    Form,
    Input,
    Button
} from "antd";

export const RequestLotteryLicenseFilterForm = ({ form, onFinish }: any) => {
    return (
        <Form name="requestLotteryLicenseFilterForm"
            layout="vertical"
            form={form}
            onFinish={onFinish}
            className="flex flex-row items-end gap-2 p-5">
            <Form.Item name="search" label="លេខសម្គាល់ឯកសារ">
                <Input autoComplete="off" />
            </Form.Item>
            <Form.Item>
                <Button htmlType="submit" type="default">
                    ស្វែងរក
                </Button>
            </Form.Item>
        </Form>
    )
}