import produce from "immer";
import { RequestCasinoLicense } from "Modules/License/Casino/Request/Constants";

const EmptyFields = {
    formTypeCode: "C001",
};
export const initialState = {
    initialValues: EmptyFields,
    formValues: {},
    errors: [],
};

const RequestCasinoLicenseReducer = produce((draft, action) => {
    switch (action.type) {
        case RequestCasinoLicense.SET_FORM_VALUES:
            draft.formValues = action.formValues;
            break;
        case RequestCasinoLicense.CASINO_LICENSE_ERROR:
            draft.errors = action.errors;
            break;
        // case LOGIN:
        //     // draft.login = action.login;
        //     // draft.password = action.password;
        //     // draft.isLoading = true;
        //     break;
        // case CASINO_LICENSE_SUCCESS:
        //     break;
    }
}, initialState);
export default RequestCasinoLicenseReducer;
