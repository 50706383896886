import {
    GameRuleConstants,
    SET_GAME_RULE_ID,
    GET_GAME_RULE_SUCCESS,
    SET_GAME_RULE_FILTER_VALUE,
    SELECT_GAME_RULE_SUCCESS
} from "Modules/Settings/GameRule/Constants";
import produce from "immer";

export const initialState: any = {
    gameRules: [],
    formValues: { search: "" },
    errors: null,
    id: null,
    gameRuleDetail: null,
    gameRuleFilterValue: null,
    gameRuleOptions: [],
};

const GameRuleReducer = produce((draft, action) => {
    switch (action.type) {
        case GameRuleConstants.SET_FORM_VALUES:
            draft.formValues = action.formValues;
            break;
        case SET_GAME_RULE_ID:
            draft.id = action.id;
            break;
        case GET_GAME_RULE_SUCCESS:
            draft.gameRuleDetail = action.gameRuleDetail;
            break;
        case GameRuleConstants.QUERY_GAME_RULE_SUCCESS:
            draft.gameRules = action.gameRules;
            break;
        case SET_GAME_RULE_FILTER_VALUE:
            draft.gameRuleFilterValue = action.values;
            break;
        case SELECT_GAME_RULE_SUCCESS:
            draft.gameRuleOptions = action.gameRuleOptions;
            break;
        default:
            break;
    }
}, initialState);

export default GameRuleReducer;
