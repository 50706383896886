
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import type { MenuProps } from 'antd';
import { SettingOutlined } from "@ant-design/icons";
import { Button, Dropdown } from "antd";

import { UserProfileSelector } from "Modules/Application/Selectors";

export interface ProfileAvatarItemType {
    name: string;
    href: string;
    type: number;
    onClick: any;
}

export interface ProfileAvatarType {
    userNavigation: ProfileAvatarItemType[];
}

export const ProfileAvatar = ({ onSignout }: any) => {
    // fields
    const user = useSelector(UserProfileSelector());

    const menuItems: MenuProps['items'] = [
        {
            key: '1',
            label: (
                <Link to="/change-password">
                    Change password
                </Link>
            ),
        },
        {
            key: '2',
            label: (
                <Button type="link" onClick={onSignout}>
                    Signout
                </Button>
            ),

        },

    ];

    return (
        <div className="flex flex-col items-center mb-6 w-full p-2">
            {/* <div className="relative flex-shrink-0 uppercase">
                {user?.session?.username}
            </div> */}

            <Dropdown menu={{ items: menuItems }} placement="topLeft" >
                <Button
                    className="w-full text-2xl text-white"
                    type="link"
                    icon={<SettingOutlined />}>{user?.session?.username}
                </Button>
            </Dropdown>

        </div>
    );
};
