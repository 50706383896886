import { useEffect } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useInjectReducer } from "Utility/InjectReducer";
import { useInjectSaga } from "Utility/InjectSaga";
import Reducer from "Modules/Settings/GameRule/Reducer";
import Saga from "Modules/Settings/GameRule/Saga";

import { Form, Table, Row, Col, Space, Button, Popconfirm } from "antd";
import { FullLayout } from "Components/Layouts/Inner/Full.Layout";
import { HelmetHeader } from "Components/Helmet-Header/index";
import { ArchiveIcon } from "@heroicons/react/outline";
import { GameRuleFilterForm } from "Modules/Settings/GameRule/Forms/GameRuleFilterForm";

import { MakeGameRuleSelector } from "Modules/Settings/GameRule/Selectors";
import {
    QueryGameRuleRequestAction,
    QueryGameRuleSuccessAction,
    SetFormValuesAction,
} from "Modules/Settings/GameRule/Actions";
import { SelectGameTypeSuccessAction } from "Modules/Settings/GameType/Actions";
import { SelectGameTypeService } from "Modules/Settings/GameType/Service";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";

const key = "gameRule";
export const List = () => {
    useInjectReducer({ key: key, reducer: Reducer });
    useInjectSaga({ key: key, saga: Saga });
    // fields
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();
    const gameRules = useSelector(MakeGameRuleSelector());
    const entityType = searchParams.get("entityType") || "CASINO";
    
    // constructor
    useEffect(() => {
        const entityTypeId = entityType === "LOTTERY" ? 2 : 1;
        const model = {
            search: "",
            entityTypeId: entityTypeId
        }
        
        const onLoad = async () => {
            const gameTypeRS = await SelectGameTypeService(entityType !== "LOTTERY" && entityType !== "CASINO" ? 0 : entityTypeId, dispatch);
            return gameTypeRS;
        };
        onLoad().then( (rs: any) => {
            dispatch(SelectGameTypeSuccessAction(rs || []));
            dispatch(SetFormValuesAction(model));
            dispatch(QueryGameRuleRequestAction());
        });

        return () => {
            dispatch(QueryGameRuleSuccessAction([]));
            dispatch(SelectGameTypeSuccessAction([]));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //events

    const onFormProviderSubmit = async (form, { name, values }: any) => {
        const entityTypeId = entityType === "LOTTERY" ? 2 : 1;
        const model = {
            ...values,
            entityTypeId: entityTypeId
        }
        dispatch(SetFormValuesAction(model));
        dispatch(QueryGameRuleRequestAction());
    };

    const onDeleteClicked = (item: any) => {

    }

    const ActionButtons = () => (
        <>
            <Link
                to={`/settings/game-rule/create?entityType=${entityType}`}
            >
                <Button type="primary">
                    បញ្ចូលថ្មី
                </Button>

            </Link>
        </>
    );

    return (
        <>
            <FullLayout
                icon={<ArchiveIcon />}
                title="បញ្ជីវិធានល្បែង"
                descriptions=""
                actions={<ActionButtons />}
            >
                <HelmetHeader meta="ប្រភេទល្បែង" />
                <Form.Provider onFormFinish={onFormProviderSubmit}>
                    <GameRuleFilterForm form={form} />
                </Form.Provider>
                <Row>
                    <Col span={24}>
                        <Table rowKey="id" dataSource={gameRules} size="small">
                            <Table.Column
                                title="វិធានល្បែង"
                                key="name"
                                render={(item) => (
                                    <Link
                                        to={`/settings/game-rule/detail/${item.id}?entityType=${entityType}`}
                                        className="text-blue-500 underline"
                                    >
                                        {item.name}
                                    </Link>
                                )}
                            />
                            <Table.Column
                                title="ប្រភេទល្បែង"
                                key="gameTypes"
                                render={(item: any) => (
                                    <Space>
                                        {item.gameTypes.map((gameType: any) => <Link
                                            key={`${item.id}-${gameType.id}`}
                                            to={`/settings/game-type/detail/${gameType.id}?entityType=${entityType}`}
                                            className="text-blue-500 underline"
                                        >
                                            {gameType.name}
                                        </Link>)}
                                    </Space>
                                )}
                            />
                            <Table.Column
                                title="ផ្សេងៗ"
                                key="descriptions"
                                dataIndex="descriptions"
                            />
                            <Table.Column 
                                key= "id"
                                width= '100px'
                                render={
                                    (item: any) => <div className="">
                                        <Popconfirm
                                            title="Delete the item"
                                            description="Are you sure to delete this item?"
                                            onConfirm={() => onDeleteClicked(item)}
                                            onCancel={() => console.log("Cancel")}
                                            okText="Yes"
                                            cancelText="No"
                                        >
                                            <Button type="link" className="p-2" >
                                                <DeleteOutlined />
                                            </Button>
                                        </Popconfirm>
                        
                                        <Link
                                            to={`/settings/game-rule/detail/${item.id}?entityType=${entityType}`}
                                            className="text-sm font-medium rounded-md text-blue-700 underline"
                                        >
                                            <EditOutlined />
                                        </Link>
                                    </div>
                                }
                            />
                        </Table>
                    </Col>
                </Row>
            </FullLayout>
        </>
    );
};
