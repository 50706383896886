import {
    Form,
    Input,
    Select
} from "antd"
export const MachineEquipomentForm = ({ form, gameRules, equipmentTypes, currencies }: any) =>
    <Form name="machineEquipmentForm" className="grid grid-cols-4 gap-x-2" layout="vertical" form={form}>
        <Form.Item name="gameRule" label="Game Rule">
            <Select showSearch labelInValue>
                {gameRules && gameRules.map((gameRule) => <Select.Option key={gameRule.id} value={gameRule.id}>{gameRule.name}</Select.Option>)}
            </Select>
        </Form.Item>
        <Form.Item name="terminalId" label="Machine ID">
            <Input />
        </Form.Item>
        <Form.Item name="equipmentType" label="Equipment Type">
            <Select showSearch labelInValue>
                {
                    equipmentTypes && equipmentTypes.map((equipmentType) => <Select.Option key={equipmentType.id} value={equipmentType.id}>{equipmentType.name}</Select.Option>)
                }
            </Select>
        </Form.Item>
        <Form.Item name="zoneId" label="Zone">
            <Input />
        </Form.Item>
        <Form.Item name="seat" label="Seat">
            <Input />
        </Form.Item>
        <Form.Item name="serialNumber" label="Serial Number">
            <Input />
        </Form.Item>
        <Form.Item name="model" label="Model">
            <Input />
        </Form.Item>
        <Form.Item name="manufacturerName" label="Manufacturer Name">
            <Input />
        </Form.Item>
        <Form.Item name="manufacturerDate" label="Manufacturer Date">
            <Input />
        </Form.Item>
        <Form.Item name="operatingSystem" label="Operating System">
            <Input />
        </Form.Item>
        <Form.Item name="gameSoftware" label="Game Software">
            <Input />
        </Form.Item>
        <Form.Item name="techStandard" label="Tech. Standard">
            <Input />
        </Form.Item>
        <Form.Item name="RtpWithoutJp" label="RTP without JP">
            <Input />
        </Form.Item>
        <Form.Item name="RtpWithJp" label="RTP with JP">
            <Input />
        </Form.Item>
        <Form.Item name="currencyId" label="Currency">
            <Select showSearch >
                {
                    currencies && currencies.map((equipmentType) => <Select.Option key={equipmentType.id} value={equipmentType.id}>{equipmentType.name}</Select.Option>)
                }
            </Select>
        </Form.Item>
        <Form.Item name="minimumBet" label="Min. Bet">
            <Input />
        </Form.Item>
        <Form.Item name="maximumBet" label="Max. Bet">
            <Input />
        </Form.Item>
        <Form.Item name="note" label="Note">
            <Input />
        </Form.Item>
    </Form>
