import produce from "immer";
import {
    SET_EQUIPMENT_FOR_OPERATOR_ID,
    GET_EQUIPMENT_FOR_OPERATOR_SUCCESS,
    SET_CASINO_EQUIPMENT_MASTER_VALUE,
    SET_CASINO_EQUIPMENT_MASTER_ID,
    GET_REQUEST_CASINO_EQUIPMENT_MASTER_SUCCESS,
    SET_CASINO_EQUIPMENT_MASTER_REDIRECT,

    GET_GAME_RULE_EQUIPMENT_MASTER_SUCCESS,
    // equipment detail
    SET_EQUIPMENT_DETAIL_ID,
    SET_EQUIPMENT_DETAIL_VALUE,
    DELETE_EQUIPMENT_DETAIL_SUCCESS,
} from "Modules/Equipments/Casino/Constants";

export const EquipmentCasinoInitialState = {
    casinoId: null,
    casinoDetail: null,
    equipmentMasterValue: null,
    gameRuleEquipmentMaster: null,
    equipmentMaster: null,
    equipmentMasterId: null,
    equipmentDetailValue: null,
    equipmentDetailId: null,
    equipmentDetailStatus: null,
    redirect: null,

}

const EquipmentCasinoReducer = produce((draft, action) => {
    switch (action.type) {
        case SET_EQUIPMENT_FOR_OPERATOR_ID:
            draft.casinoId = action.id;
            break;
        case GET_EQUIPMENT_FOR_OPERATOR_SUCCESS:
            draft.casinoDetail = action.operator;
            break;
        case SET_CASINO_EQUIPMENT_MASTER_VALUE:
            draft.equipmentMasterValue = action.values;
            break;
        case SET_CASINO_EQUIPMENT_MASTER_ID:

            draft.equipmentMasterId = action.id;
            break;
        case GET_REQUEST_CASINO_EQUIPMENT_MASTER_SUCCESS:
            draft.equipmentMaster = action.casinoEquipments;
            break;
        case SET_EQUIPMENT_DETAIL_ID:
            draft.equipmentDetailId = action.id;
            break;
        case DELETE_EQUIPMENT_DETAIL_SUCCESS:
            draft.equipmentDetailStatus = action.equipmentDetail;
            break;
        case SET_CASINO_EQUIPMENT_MASTER_REDIRECT:
            draft.redirect = action.redirect;
            break;
        case GET_GAME_RULE_EQUIPMENT_MASTER_SUCCESS:
            draft.gameRuleEquipmentMaster = action.casinoEquipments;
            break;
        case SET_EQUIPMENT_DETAIL_VALUE:
            draft.equipmentDetailValue = action.values;
            break;
        default: break;
    }

}, EquipmentCasinoInitialState);

export default EquipmentCasinoReducer;