import { RequestCasinoLicenseList } from "Modules/License/Casino/ApplicationForm/License/List";
import { RequestCasinoLicenseCreate } from "Modules/License/Casino/ApplicationForm/License/Create";
import { RequestCasinoLicenseDetail } from "Modules/License/Casino/ApplicationForm/License/Detail";

export const RequestCasinoLicenseRoute =
{
    path: "request-license",
    children: [
        { path: "", element: <RequestCasinoLicenseList /> },
        { path: "create", element: <RequestCasinoLicenseCreate /> },
        { path: ":id", element: <RequestCasinoLicenseDetail /> }
    ],
};
